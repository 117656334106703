import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow2, Arrow4, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';
import icon5 from './assets/icon5.svg';
import icon6 from './assets/icon6.svg';

const Slide = styled.div`
    .box1 {
        border-radius: 50%;

        .number {
            position: absolute;
            left: 15px;
            top: -6px;
            font-size: 68px;
            line-height: 85px;
            font-style: italic;
            font-weight: 600;
            color: #df9933;
        }

        &.box1-5 {
            letter-spacing: -0.04em;
        }
    } 

    .box2 {
        padding-left: 38.5px;
        border-radius: 15px;
        border-width: 1px !important;

        > p + p,
        > p + ul,
        > ul > li + li {
            margin-top: 12px;
        }

        ul li {
            position: relative;
            padding-left: 11px;

            &::before {
                position: absolute;
                left: 0;
                top: -6px;
                content: ".";
               
                font-weight: 400;
            }

            ul {
                margin-left: 23px;
            }
        }

        &.box2-3 {
            padding-left: 23px;
            padding-top: 10px;
        }

        &.box2-5 {

        }
    }

    .box3 {
        align-items: center;
        justify-content: flex-start;
        background: #003087;
        color: #fff;

        img {
            width: 65px;
            margin-left: 17px;
        }

        p {
            margin-left: 2px;
            font-size: 16.5px;
            line-height: 16.9px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (

        <Slide className="centered" step={props.step}>
            <h1>Kompetenz &amp; Digitale Kommunikation bedeuten <strong>eine ideale<br />
                Partnerschaft zwischen Kunde und Kontaktlinsenspezialist</strong></h1>
            <h2>… über die eigentliche Anpassung hinaus</h2>
            <Container left={256} top={360} width={446} height={446} className="bg1 text-center flex-all-center color1 box1">
                <span className="number">1.</span>
                <p><strong>Einfacher<br />
                    und einheitlicher<br />
                    Prozess vor,<br />
                    während<br />
                    und nach der<br />
                    Anpassung</strong></p>
                <MoreBtn
                    className="x-left y-center"
                    active={state.overlay1 === 'overlay1'}
                    onClick={() => toggleOverlay('overlay1')} />

                <Image left={354} top={288} width={143} height={143} src={icon1} imageWidth={93} className="rounded border bg-white image-center" />
            </Container>

            <Container left={793} top={360} width={446} height={446} className="bg1 text-center flex-all-center color1 box1">
                <span className="number">2.</span>
                <p><strong>Termin-<br />
                    Verbindlichkeit</strong></p>
                <MoreBtn
                    className="x-left y-center"
                    active={state.overlay1 === 'overlay2'}
                    onClick={() => toggleOverlay('overlay2')} />

                <Image left={354} top={288} width={143} height={143} src={icon2} imageWidth={78} className="rounded border bg-white image-center" />
            </Container>

            <Container left={1330} top={360} width={446} height={446} className="bg1 text-center flex-all-center color1 box1">
                <span className="number">3.</span>
                <p><strong>Mitarbeiter-<br />
                    Kompetenz<br />
                    erhöhen</strong></p>
                <MoreBtn
                    className="x-left y-center"
                    active={state.overlay1 === 'overlay3'}
                    onClick={() => toggleOverlay('overlay3')} />

                <Image left={354} top={288} width={143} height={143} src={icon3} imageWidth={82} className="rounded border bg-white image-center" />
            </Container>

            <Container left={518} top={806} width={446} height={446} className="bg1 text-center flex-all-center color1 box1">
                <span className="number">4.</span>
                <p><strong>Bedenken und<br />
                    Ängsten<br />
                    begegnen </strong></p>
                <MoreBtn
                    className="x-left y-center"
                    active={state.overlay1 === 'overlay4'}
                    onClick={() => toggleOverlay('overlay4')} />

                <Image left={354} top={288} width={143} height={143} src={icon4} imageWidth={96} className="rounded border bg-white image-center" />
            </Container>

            <Container left={1056} top={806} width={446} height={446} className="bg1 text-center flex-all-center color1 box1 box1-5">
                <span className="number">5.</span>
                <p><strong>Kommunikation<br />
                    aufrecht erhalten –<br />
                    Nach-Kauf-<br />
                    Kommunikation</strong></p>
                <MoreBtn
                    className="x-left y-center"
                    active={state.overlay1 === 'overlay5'}
                    onClick={() => toggleOverlay('overlay5')} />

                <Image left={354} top={288} width={143} height={143} src={icon5} imageWidth={121} className="rounded border bg-white image-center" />
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                <Container left={256} top={327} width={1064} height={565} className="rounded flex-all-center color1 box2 box2-2">
                    <p>Einfacher, einheitlicher Prozess vor,<br />
                        während und nach der Anpassung</p>
                    <ul>
                        <li><p>Früher Start der Kommunikation über<br />
                            Webseiten Information, evtl. Chatfunktionen</p></li>
                        <li><p>Einbindung von Brille &amp; Kontaktlinse<br />
                            in einem Prozess</p></li>
                        <li><p><strong>Gut zu Wissen: Duale Versorgung steigert<br />
                            die Wahrnehmung von Kompetenz<sup>4</sup></strong></p></li>
                    </ul>
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="with-bg">
                <Container left={793} top={327} width={1039} height={472} className="rounded flex-all-center color1 box2 box2-2">
                    <p>Termin-Verbindlichkeit</p>
                    <ul>
                        <li><p>Nutzung eines digitalen Termin-Findungstool<br />
                            über die Webseite oder App</p></li>
                        <li><p><strong>Gut zu wissen: Termine jenseits von<br />
                            2 Wochen haben eine starke<br />
                            Abbrecher-Quote<sup>5</sup></strong></p></li>
                    </ul>
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay3'} className="with-bg">
                <Container left={916} top={583} width={930} height={610} className="rounded flex-all-center color1 box2 box2-3">
                    <p>Mitarbeiter Kompetenz erhöhen</p>
                    <ul>
                        <li><p>Nutzen Sie die Möglichkeit der digitalen Kommunikation
                            (Whats App, Social<br />
                            Media Präsenz) um persönliche Vor-<br />
                            Informationen anzubieten</p></li>
                        <li><p><strong>Gut zu wissen:<br />
                            Neue Kontaktlinsen-Träger machen<br />
                            ein „Vorcheck“ bei Mitarbeitern über<br />
                            ihre Erfahrung mit Kontaktlinsen<sup>1</sup></strong></p></li>
                    </ul>
                </Container>
                <Container left={1409} top={509} width={485} height={126} className="rounded flex box3">
                    <img src={icon6} />
                    <p>Nutzen Sie unsere
                        Online-Seminare</p>
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay4'} className="with-bg">
                <Container left={520} top={583} width={1321} height={550} className="rounded flex-all-center color1 box2 box2-4">
                    <p>Bedenken &amp; Ängsten begegnen</p>
                    <ul>
                        <li><p>Nutzung digitaler Medien für Auf- und Absetz-Training <a href="https://www.acuvue.de/handhabung" target="_blank">www.acuvue.de/handhabung</a></p></li>
                        <li><p>Sammeln Sie die häufigsten Fragen und Antworten
                            und<br />bauen sie daraus einen Blog / FAQ-Bereich /<br />
                            Newsletter-Serie</p></li>
                        <li><p><strong>Gut zu wissen: Handhabungsprobleme sind einer der häufigsten Ausstiegsgründe<sup>1</sup></strong></p></li>
                    </ul>
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay5'} className="with-bg">
                <Container left={194} top={44} width={1722} height={990} className="rounded flex-all-center color1 box2 box2-5">
                    <p>Kommunikation aufrecht erhalten – Nachkauf-Kommunikation</p>
                    <ul>
                        <li><p>Halten Sie den Kontakt per WhatsApp, Social Media, Hotlines</p></li>
                        <li><p>Verbindliche Check-up Termine mit Incentivierung (Kontaktlinsen-Pass<br />
                        mit Gutschein für Check-up) und Erinnerungs-Funktion</p></li>
                        <li><p>Bitten Sie den Kunden Sie weiter zu empfehlen, bedeutet „Ratings“<br />
                        zu geben <Arrow4 className="inline" /> Freunde werben Freunde über Social Media</p></li>
                       
                        <li><p>Newsletter mit relevanten Informationen entsprechend dem<br /> 
„Gewöhnungs-Status“ des Kunden an die Kontaktlinse</p>
                            <ul>
                            <li><p>Pflege – was Sie nicht vergessen sollten (z.B. Wasser und Kontaktlinse)</p></li>
                            <li><p>UV-Schutz</p></li>
                            <li><p>Hygiene</p></li>		
                            <li><p>Neue Produkte / Innovationen</p></li>
                            <li><p>Bildschirmarbeit</p></li>
                            <li><p>Urlaub</p></li>
                            </ul>
                        </li>
                        <li><p><strong>Gut zu wissen: Die ersten 2-3 Wochen sind entscheidend um den Kunden in<br />
                        der Kontaktlinse und für das Geschäft zu halten<sup>6</sup></strong></p></li>
                    </ul>
                </Container>
            </Overlay>
        </Slide>
    );
};
