import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'
import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'
import Slide9 from './slides/slide9'
import Slide10 from './slides/slide10'
import Slide11 from './slides/slide11'
import Slide12 from './slides/slide12'
import Slide13 from './slides/slide13'
import Slide14 from './slides/slide14'
import Slide16 from './slides/slide16'
import Slide17 from './slides/slide17'
import Slide18 from './slides/slide18'
import Slide19 from './slides/slide19'
import Slide20 from './slides/slide20'
import Slide21 from './slides/slide21'
import Slide22 from './slides/slide22'
import Slide23 from './slides/slide23'

import Slide24 from './slides/slide24'
import Slide25 from './slides/slide25'
import Slide26 from './slides/slide26'

import Slide27 from './slides/slide27'
import Slide28 from './slides/slide28'

import Slide29 from './slides/slide29'

const Theme = {
    color1: '#009DA8',
    bg1: '#D0F1F2',
    bg2: '#BFECEE',
}

export const Slides = {
    'Folder2Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder2Slide2': { name: Slide2, theme: Theme },
    'Folder2Slide3': { name: Slide3, theme: Theme },
    'Folder2Slide4': { name: Slide4, theme: Theme },
    'Folder2Slide5': { name: Slide5, theme: Theme },
    'Folder2Slide6': { name: Slide6, theme: Theme },
    'Folder2Slide7': { name: Slide7, theme: Theme },
    'Folder2Slide8': { name: Slide8, theme: Theme },
    'Folder2Slide9': { name: Slide9, theme: Theme },
    'Folder2Slide10': { name: Slide10, theme: Theme },
    'Folder2Slide11': { name: Slide11, theme: Theme },
    'Folder2Slide12': { name: Slide12, theme: Theme },
    'Folder2Slide13': { name: Slide13, theme: Theme, steps: 2 },
    'Folder2Slide14': { name: Slide14, theme: Theme, footer: 'transparent white' },
    'Folder2Slide16': { name: Slide16, theme: Theme },
    'Folder2Slide17': { name: Slide17, theme: Theme },
    'Folder2Slide18': { name: Slide18, theme: Theme },
    'Folder2Slide19': { name: Slide19, theme: Theme },
    'Folder2Slide20': { name: Slide20, theme: Theme },
    'Folder2Slide21': { name: Slide21, theme: Theme },
    'Folder2Slide22': { name: Slide22, theme: Theme },
    'Folder2Slide23': { name: Slide23, theme: Theme },
    'Folder2Slide24': { name: Slide24, theme: Theme, steps: 3  },
    'Folder2Slide25': { name: Slide25, theme: Theme },
    'Folder2Slide26': { name: Slide26, theme: Theme },

    'Folder2Slide27': { name: Slide27, theme: Theme },
    'Folder2Slide28': { name: Slide28, theme: Theme },

    'Folder2Slide29': { name: Slide29, theme: Theme },

    
}

export const Order = [
    { key: 0, component: 'Folder2Slide1' },
    { key: 1, component: 'Folder2Slide2' },
    { key: 2, component: 'Folder2Slide3' },
    { key: 3, component: 'Folder2Slide4' },
    { key: 4, component: 'Folder2Slide5' },
    { key: 5, component: 'Folder2Slide6' },

    { key: 24, component: 'Folder2Slide25' },
    { key: 25, component: 'Folder2Slide26' },

    { key: 6, component: 'Folder2Slide7' },
    { key: 7, component: 'Folder2Slide8' },
    //{ key: 24, component: 'Folder2Slide25' },
    { key: 23, component: 'Folder2Slide24' },
    { key: 8, component: 'Folder2Slide9' },
    { key: 9, component: 'Folder2Slide10' },
    { key: 10, component: 'Folder2Slide11' },

    { key: 26, component: 'Folder2Slide27' },
    { key: 27, component: 'Folder2Slide28' },

    { key: 11, component: 'Folder2Slide12' },
    { key: 12, component: 'Folder2Slide13' },

    { key: 28, component: 'Folder2Slide29' },

    { key: 13, component: 'Folder2Slide14' },
    { key: 15, component: 'Folder2Slide16' },
    { key: 16, component: 'Folder2Slide17' },
    { key: 17, component: 'Folder2Slide18' },
    { key: 18, component: 'Folder2Slide19' },
    { key: 19, component: 'Folder2Slide20' },
    { key: 20, component: 'Folder2Slide21' },
    { key: 21, component: 'Folder2Slide22' },
    { key: 22, component: 'Folder2Slide23' },
]