import React from 'react';
import styled, { keyframes, css } from 'styled-components'

const fadeIn = keyframes`
    0% {
        opacity: 0; 
    }
    100% {  
        opacity: 1;  
    }
`


const Overlay = styled.div`
    position: absolute;
    z-index: 200;
    left: 0;
    top: 0;
	display: none;
    opacity: 0; 
    animation-timing-function: ease-in-out;
 
    ${({ active }) => active && css`
        display: block;
        animation: ${fadeIn} 0.5s forwards;
    `}

    &.with-bg::before {
        display: block;
        position: fixed;
        width: 300vw;
        height: 300vh;
        left: -100vw;
        top: -100vh;
        opacity: 0.6;
        content: "";
    }

    &.with-bg.bg-white::before {
        background: #fff !important;
        opacity: 1;
    }
				
`
export default Overlay;