import React from 'react';
import styled, { css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

export const Arrow3Container = styled.svg`
    position: absolute;

    width: 16px;

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}
`

export const Arrow3 = (props) => {
    return (
        <Arrow3Container xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.17 64.35" left={props.left} top={props.top}>
            <polygon points="0 0 0 64.35 32.17 32.17 0 0 0 0" fill="#f49922"/>
        </Arrow3Container>
    );
};

export default Arrow3;