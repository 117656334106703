import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'
import { formatThousands } from 'components/helper'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';

import img1 from './assets/img1.png';

const Slide = styled.div`
    .box1 { 
        .count {
            position: absolute;
            left: 0;
            top: 102px;
            width: 100%;
            font-size: 41px;
            line-height: 46px;
            text-align: center;
        }

        p {
            position: absolute;
            left: 0;
            top: 144px;
            width: 100%;
            text-align: center;
        }

        img {
            position: absolute;
            left: 50%;
            top: 23px;
            transform: translateX(-50%);
        }

        &.box1-1 img {
            width: 81px;
        }

        &.box1-2 img {
            width: 62px;
        }

        &.box1-3 img {
            width: 69px;
            top: 26px;
        }

        &.box1-4 img {
            width: 56px;
            top: 26px;
        }
    }

    .box2 {
        p span {
            font-size: 40.6px;
            line-height: 46px;
        }

        &.box2-3 {
            p span {
                font-size: 26px;
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })
    const count1 = formatThousands(useCounter(0, 10000, props.active, props.preview))
    const count2 = useCounter(0, 103, props.active, props.preview, 1)
    const count3 = useCounter(0, 11, props.active, props.preview, 2)
    const count4 = useCounter(0, 100, props.active, props.preview, 3)

    return (
        <Slide className="centered" step={props.step}>
            <h1>Wir sorgen dafür, <strong>dass 60 Millionen Menschen besser sehen<br />
            können, durch …</strong></h1>
            <Container left={114} top={307} width={459} height={545} className="bg1 box1 box1-1 color1 fadeInFromTop">
                <Arrow width={384} className="container-top" />
                <img src={icon1} />
                <span className="count">{count1}</span>
                <p><strong>Mitarbeiter mit<br />
derselben<br />
Leidenschaft</strong></p>
            </Container>
            <Container left={599} top={307} width={459} height={545} className="bg1 box1 box1-2 color1 fadeInFromTop animate2">
                <Arrow width={384} className="container-top" />
                <img src={icon2} />
                <span className="count">{count2}</span>
                <p><strong>Länder mit<br />
Marktpräsenz</strong></p>
            </Container>
            <Container left={1084} top={307} width={459} height={545} className="bg1 box1 box1-3 color1 fadeInFromTop animate3">
                <Arrow width={384} className="container-top" />
                <img src={icon3} />
                <span className="count">{count3}</span>
                <p><strong>Produktions- und<br />F&amp;E-Standorte
</strong></p>
            </Container>
            <Container left={1570} top={307} width={364} height={545} className="bg1 box1 box1-4 color1 fadeInFromTop animate4">
                <Arrow width={284} className="container-top" />
                <img src={icon4} />
                <span className="count">{count4}</span>
                <p><strong>Bildungs-<br />
einrichtungen</strong></p>
            </Container>
            <div className="container-animate fadeIn animate5">
                <Container left={106} top={757} width={1842} height={119} className="rounded rounded-full color1 flex flex-all-center text-center">
                    <p>Unterstützt durch die Kompetenz vom <strong>Johnson &amp; Johnson Mutterkonzern</strong></p>
                </Container>
                <Arrow left={1054} top={838.2} width={100} className="rotate90" />
            </div>

            <div className="container-animate fadeInFromTop animate6">
            <Image src={img1} left={168} top={954} width={364} height={137} />
            <Container as="p" left={500} top={985} width={20} height={545} className="box2 box2-1 color1">
                <strong><sup>1</sup></strong>
            </Container>

            
            <Container left={599} top={949} width={459} height={545} className="box2 box2-2 color1 text-center">
                <p><strong><span>4</span><br />
                Innovationszentren<br />
                in 3 Ländern</strong></p>
            </Container>
            <Container left={1084} top={949} width={459} height={545} className="box2 box2-3 color1 text-center">
                <p><strong><span>GRÖSSTES</span><br />Unternehmen für<br /> 
chirurgische<br /> 
Instrumente weltweit<sup>2</sup></strong></p>
            </Container>
            <Container left={1570} top={949} width={364} height={545} className="box2 box2-4 color1 text-center">
                <p><strong><span>TOP 5</span><br />der weltweiten<br /> 
Pharma-<br /> 
unternehmen<sup>3</sup></strong></p>
            </Container>
            </div>
        </Slide>
    );
};
