import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import img1 from './assets/img1.svg';
import img2_1 from './assets/img2_1.png';
import img2_2 from './assets/img2_2.png';
import img2_3 from './assets/img2_3.png';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.png';

const Slide = styled.div`

    h1 {
       position: relative;
       
       .number {
            position: absolute;
            font-family: "AvenirNext";
            font-weight: 700;
            font-style: italic;
            font-size: 125px;
            line-height: 156px;
            top: -60px;
            left: -50px;
            z-index: -1;
       }
    }

    .box2 {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        font-size: 10.25px;
        line-height: 12px;
        font-family: "AvenirNext";

        span {
            display: inline-block;
            padding: 8px 0px;
        }
    }

    .box3 {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        font-size: 10.25px;
        line-height: 12px;
        font-family: "AvenirNext";
        font-weight: 700;

        span {
            display: inline-block;
            padding: 15px 0px;
        }
    }
    
    .box4 {
        font-family: "AvenirNext";

        .box4_1  {
            font-weight: 700;
        }

        .text {
            font-size: 10.5px;
            line-height: 12.5px;

            p {
                span {
                    letter-spacing: -0.25px;
                }
            }
        }

        .seperator {
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: 143px;
            height: 1.5px;
            background: #003087;
        }
    }

    .box5 { 
        padding-top: 5px;

        p {
            font-size: 18px;
            line-height: 25px;
        }
    }

    .img3 {
        background-size: contain;
    }

    .box6 {
        font-size: 12.5px;
        line-height: 15px;
        font-family: "AvenirNext";
        font-weight: 700;
        text-transform: uppercase;
    }

    .overlay1 {
        z-index: 300;

        .bg1 {
            background: #e6e6e7 !important;
        }

        .text {
            font-size: 10px;
            line-height: 11px;
            font-family: "AvenirNext";

            .number {
                display: inline-block;
                min-width: 14px;
            }
        }
    }
    
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><span className="number color3">1</span><strong>Kontaktlinsen Portfolio </strong></h1>

            <Container left={115} top={214} width={1817} className="box1 color1 text-center" as="p">
                Der weltweit führende Anbieter von Kontaktlinsen* mit innovativen Designs nach<br />
                dem Vorbild der natürlichen Augenfunktion für die individuellen Bedürfnisse Ihrer Kunden.
            </Container>

            <Image left={105} top={347} width={69} height={716} src={img1} />
            <Container left={105} top={347} width={69} height={716} className="box2 flex-all-center text-center color1">
                <p>bequem<span>|</span>frisch<span>|</span>gesund<span>|</span>Kundenzufriedenheit</p>
            </Container>

            <Container left={185} top={347} width={69} height={716} className="box3 flex-all-center text-center color1">
                <p><span>Austausch-Kontaktlinsen</span>
                    <span>Ein-Tages-Kontaktlinsen</span></p>
            </Container>

            <Container left={266} top={0} width={540} className="box4 fadeIn">
                <Container left={0} top={347} width={540} height={716} className="bg1">

                </Container>
                <Image left={42} top={482} width={454} height={459} src={img2_1} />
                <Container left={0} top={365} width={540} className="box4_1 text-center color1">
                    CLASSIC
                </Container>

                <Container left={0} top={412} width={540} className="text text-center color1">
                    <p>Bewährte Leistung**,***<br />
                        und gutes Preis-Leistungsverhältnis.</p>
                </Container>

                <Container left={0} top={650} width={540} className="text text-center color1">
                    <p>Bewährte Leistung**,*** mit ungeschlagenem<br />
                        Tragekomfort vs. Hydrogel-Eintageslinsen in 14<br />
                        <span>klinischen Studien veröffentlich auf ClinicalTrials.gov.<sup>+</sup></span>
                    </p>
                    <span className="seperator" />
                    <p>Gutes Preis-Leistungsverhältnis.</p>
                </Container>

                <Container left={0} top={950} width={540} className="text text-center color1">
                    <p>Exzellenter Tragekomfort über den<br />
                        gesamten Monat.<sup>1</sup>
                    </p>
                    <span className="seperator" />
                    <p>Gutes Preis-Leistungsverhältnis.</p>
                </Container>

            </Container>

            <Container left={854} top={0} width={540} className="box4 fadeIn animate2">
                <Container left={0} top={347} width={540} height={716} className="bg1">

                </Container>
                <Image left={42} top={482} width={454} height={459} src={img2_2} />
                <Container left={0} top={365} width={540} className="box4_1 text-center color1">
                    ADVANCED
                </Container>

                <Container left={0} top={412} width={540} className="text text-center color1">
                    <p>Ausgezeichnete Leistung,<sup>++2,3</sup> Komfort <sup>++</sup><br />
                        und Handhabung.<sup>3</sup></p>
                </Container>

                <Container left={0} top={650} width={540} className="text text-center color1">
                    <p><span>Ganztägiger Tragekomfort,<sup>4,5</sup> exzellente Handhabung <sup>6,7</sup></span><br />
                        und überlegene Sehqualität,<sup>^3,8</sup> auch bei den<br />
                        täglichen Anforderungen eines modernen, digitalen<br />
                        Lebensstils.<sup>9-12</sup>
                    </p>
                </Container>

                <Container left={0} top={950} width={540} className="text text-center color1">
                    <p>Ungeschlagener Tragekomfort in 28 klinischen<br />
                        Studien veröffentlich auf ClinicalTrials.gov,<sup>++</sup><br />
                        einfache Handhabung<sup>2</sup> und exzellente Sehqualität <sup>2</sup><br />
                        in einer 2-Wochen-Modalität.</p>
                </Container>

            </Container>


            <Container left={1442} top={0} width={540} className="box4 fadeIn animate3">
                <Container left={0} top={347} width={540} height={488} className="bg1">

                </Container>
                <Image left={42} top={508} width={454} height={158} src={img2_3} />
                <Container left={0} top={365} width={540} className="box4_1 text-center color1">
                    OPTIMUM
                </Container>

                <Container left={0} top={412} width={540} className="text text-center color1">
                    <p>Außergewöhnliche Leistung <sup>$&§13,14</sup> in einer Vielzahl<br />
                        von anspruchsvollen Umgebungen<sup>$&13,14</sup><br />
                        und sich ändernden Lichtverhältnissen.<sup>°&13,14</sup></p>
                </Container>

                <Container left={0} top={675} width={540} className="text text-center color1">
                    <p>Die ACUVUE® Kontaktlinse der nächsten Generati-<br />
                        on,<sup>15</sup> die außergewöhnllichen Tagesend-Komfort <sup>§13,14</sup><br />
                        und Sehqualität bietet,<sup>§&13,14</sup> mit blau-violettem<br />
                        Lichtfilter. <sup>°°15</sup>
                    </p>
                </Container>
            </Container>

            <Container left={1442} top={853} width={540} height={104} className="bg1 box5 fadeIn animate3">
                <p className="color1 text-center"><strong>Referenzen</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'} />
            </Container>

            <div className=" fadeIn animate4">
                <Container left={100} top={1110} width={1883} height={140} className="bg1" />
                <Image left={119} top={1139} width={1308} height={78} src={img3} className="img3" />

                <Container left={300} top={1138} width={270} className="box6 color1" as="p">
                    ACUVUE® Home
                    Delivery Service
                    für Ihre Kunden
                </Container>

                <Container left={656} top={1152} width={370} className="box6 color1" as="p">
                    24 h Bestellmöglichkeit
                    im Online Bestellsystem
                </Container>

                <Container left={1108} top={1138} width={370} className="box6 color1" as="p">
                    Kulanter und
                    unkomplizierter
                    Retourenprozess
                </Container>

                <Container left={1444} top={1138} width={512} className="box6 color1" as="p">
                    KOSTENLOSE ANPASSLinsen<br />
                    UND PROFESSIONELLE & TECHNISCHE
                    ONLINE-UNTERSTÜTZUNG
                </Container>

                <Image left={1780} top={1045} width={235} height={104} src={img4} className="img4 fadeIn animate5" />
            </div>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg">
                <Container left={113} top={240} width={1834} height={940} className="bg1">
                </Container>

                <Container left={140} top={274} width={1800} className="text">
                    <strong>+</strong> 14 clinical studies posted on ClinicalTrials.gov, a website maintained by the NIH, evaluated subjective comfort as a primary or secondary endpoint for 1-DAY ACUVUE® MOIST brand family<br />
                    (spherical, astigmatism, and multifocal) contact lenses vs. competitors’ products. Published trials include comparisons between etafilcon A and nelfilcon A, omafilcon A, hilafilcon B, ocufilcon D<br />
                    and nesofilcon<br />
                    A. Review conducted as of April 30, 2023.<br />
                    <strong>++</strong> ClinicalTrials.gov is a website maintained by the NIH. The 28 clinical studies evaluated subjective comfort as a primary or secondary endpoint for ACUVUE® OASYS Brand 2-week family<br />,
                    ACUVUE® OASYS 1-Day Brand family, and ACUVUE® OASYS MAX 1-Day Brand family of contact lenses, vs. competitors’ products. Review conducted as of April 30, 2023.<br />
                    <strong>*</strong> Source Euromonitor International Limited; Eyewear 2024 edition; value sales at rsp, all retail channels, 2022 data; “Acuvue family of brands” represents aggregated sales of the following brands:<br />
                    1-Day Acuvue, Acuvue Oasys, Acuvue Advance, Acuvue, and Acuvue2<br />
                    <strong>**</strong> ClinicalTrials.gov is a website maintained by the NIH. The 20 clinical studies evaluated objective and subjective vision as a primary or secondary endpoint for 1-DAY ACUVUE® MOIST Brand family (spherical, astigmatism, and multifocal) contact lenses vs. competitors’ products. Review<br />
                    conducted as of April 30, 2023.<br />
                    <strong>***</strong> ClinicalTrials.gov is a website maintained by the NIH. The 14 clinical studies evaluated subjective comfort as a primary or secondary endpoint for 1-DAY ACUVUE® MOIST Brand family (spherical,<br />
                    astigmatism, and multifocal) contact lenses vs. competitors’ products. Review conducted as of April 30, 2023.<br />
                    <strong>^</strong> Improved optical performance and vision compared to ACUVUE OASYS Brand Contact Lenses with HYDRACLEAR Plus Technology<br />
                    <strong>^^</strong> The presence of PVP within the lens and on its surface results in a design with low pervaporation which helps to enable comfort during digital device use.<br />
                    <strong>$</strong> n=378<br />
                    <strong>&</strong> n≥449<br />
                    <strong>§</strong> Im Vergleich zu ACUVUE OASYS 1-Day<br />
                    <strong>°</strong> n=172<br />
                    <strong>°°</strong> Es ist nicht erwiesen, dass die Filterung von HEV-Licht durch Kontaktlinsen dem Träger gesundheitliche Vorteile bringt, einschließlich, aber nicht beschränkt auf den Schutz der Netzhaut, den Schutz<br />
                    vor dem Fortschreiten des Grauen Stars, die Verringerung der Augenbelastung, die Verbesserung des Kontrasts, die Verbesserung der Sehschärfe, die Verringerung der Blendung, die<br />
                    Verbesserung des Sehens bei schwachem Licht oder die Verbesserung des zirkadianen Rhythmus/Schlafzyklus. Der Kontaktlinsenspezialist sollte für weitere Informationen konsultiert werden.<br />
                    <strong className="number">1.</strong>	JJV Data on File 2016. 30-day dispensing evaluation, monthly replacement DW study, n = 533 soft silicone hydrogel CL wearers in the U.S.<br />
                    <strong className="number">2.</strong>	Canavan K, Sulley A, Coles-Brennan C, et al. Multi-Center Clinical Evaluation of Lapsed Wearers Refitted with senofilcon A Contact Lenses. Optom Vis Sci 2014;91:e-abstract 145180<br />
                    <strong className="number">3.</strong>	JJV Data on File 2017. Optical Precision of ACUVUE OASYS Brand 1-Day Contact Lenses with HydraLuxe Technology<br />
                    <strong className="number">4.</strong>	JJV Data on File 2021. Similarities between Mucin and Poly(N-Vinyl Pyrrolidone) (PVP).<br />
                    <strong className="number">5.</strong>	JV Data on File 2021. HydraLuxe Technology Definition.<br />
                    <strong className="number">6.</strong>	JJV Data on File 2016. Single-masked, 2-visit, bilateral wear, single arm 1-week daily wear dispensing study of 162 US habitual toric soft contact lens wearers in both eyes, with a spherical
                    distance refraction of -1.50D to -4.00D (inclusive), cylinder correction of 0.75D to 1.50D after vertex correction (inclusive), and refractive cylinder axis within 180±15 degrees or 90±15 degrees.<br />
                    <strong className="number">7.</strong>	JJV Data on File 2016. 1-week dispensing evaluation, daily wear study, n=119 soft contact lens wearers in the United States.<br />
                    <strong className="number">8.</strong>	Cheng X, Moody K, Xu J, et al. Visual Performance of Silicone Hydrogel Daily Disposable Contact Lenses. Contact Lens Anterior Eye. 2018;41(S1):S70. https://doi.org/10.1016/j.clae.2018.03.093.<br />
                    <strong className="number">9.</strong>	JJV Data on File 2021. ACUVUE Brand Contact Lenses: PVP [poly(N-vinyl pyrrolidone)] and its Similarity to Mucin.<br />
                    <strong className="number">10.</strong>	JJV Data on File 2018. Material Properties: ACUVUE OASYS Brand Contact Lenses with HYDRACLEAR PLUS, ACUVUE OASYS with Transitions Light Intelligent Technology, and Other Reusable Contact Lenses.<br />
                    <strong className="number">11.</strong>	JJV Data on File 2021. Material Properties: 1-DAY ACUVUE MOIST, 1-DAY ACUVUE TruEye, and ACUVUE OASYS 1-Day with HydraLuxe Technology Brand Contact Lenses.<br />
                    <strong className="number">12.</strong>	JJV Data on File 2015. Minimal Frictional Energy/Sliding Work with ACUVUE OASYS 1-Day with HydraLuxe Technology Brand Contact Lenses.<br />
                    <strong className="number">13.</strong>	JJV Data on File 2022. Subjective Stand-Alone Claims for ACUVUE OASYS MAX 1-Day Contact Lenses - Exploratory Meta-analysis.<br />
                    <strong className="number">14.</strong>	JJV Data on File 2022. Subjective Stand-Alone Claims for ACUVUE OASYS MAX 1-Day MULTIFOCAL Contact Lenses - Exploratory Meta-analysis.<br />
                    <strong className="number">15.</strong>	JJV Data on File 2022. TearStable™ Technology Definition.
                </Container>


                <CloseBtn
                    left={1753} top={206}
                    bgColor={'#FFFFFF'}
                    onClick={() => {
                        toggleOverlay('overlay1')
                    }} />
            </Overlay>

        </Slide >
    );
};
