import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.svg';

const Slide = styled.div`
    h1 {
        letter-spacing: -0.04rem;
    }

    .circle1 {
        background: #009bae;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .img2 {
            width: 57px;
            height: auto;
        }

        .img3 {
            width: 74px;
            height: auto;
        }

        .img4 {
            width: 63px;
            height: auto;
        }
    }

    .box1 {
        padding-left: 61px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1>ACUVUE® OASYS 1-Day bietet <strong>außergewöhnlichen<br />
                    Tragekomfort bei der Nutzung digitaler Geräte<sup>25</sup></strong></h1>

                <Container as="p" left={100} top={292} width={1320} className="color1">
                    Studienteilnehmer, die digitale Geräte für mehr als 8h nutzten,<br />
                    verspürten mit ACUVUE® OASYS 1-Day …
                </Container>

                <Container left={100} top={454} width={190} height={190} className="circle1">
                    <img src={img2} className="img2" />
                </Container>

                <Container as="p" left={318} top={505} width={1050} className="color1">
                    … <strong>weniger trockene Augen am Ende des Tages</strong>,<br />
                    verglichen mit ihren üblichen Ein-Tageslinsen.<sup>±,†,26</sup>
                </Container>

                <Container left={100} top={669} width={190} height={190} className="circle1">
                <img src={img3} className="img3" />
                </Container>

                <Container as="p" left={318} top={723} width={1050} className="color1">
                    … <strong>überlegenen Tragekomfort</strong> gegenüber<br />
                    deren üblichen Ein-Tageslinsen.<sup>±,†,26</sup>
                </Container>

                <Container left={195} top={935} width={1171} height={261} className="bg1 color1 box1 flex-all-center">
                    <Arrow width={972} className="container-top" />
                    <p>A<strong>ußergewöhnlicher digitaler Tragekomfort<sup>25</sup></strong> dank<br />
                        einem Design, das den digitalen Anforderungen des<br />
                        modernen Alltags gerecht wird.<sup>f,27-30</sup></p>
                </Container>

                <Container left={100} top={973} width={190} height={190} className="circle1">
                <img src={img4} className="img4" />
                    </Container>
                
            </Slide>


        </>
    );
};
