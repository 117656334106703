import React, { useState } from 'react';
import styled from 'styled-components'
import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'
import { useCounter, useSlideState } from 'components/hooks'
import { formatThousands } from 'components/helper'
import img1 from './assets/img1.jpg';

const Slide = styled.div`
    .box1 .count {
        font-size: 50px;
        line-height: 60px;
    }

    .box2 .count { 
        font-size: 35px;
        line-height: 36px;
    }

    .box3 {
        align-items: center;
        justify-content: flex-start;

        .count {
            flex: 0 0 170px;
            text-align: right; 
            font-size: 62px;
            line-height: 78px;
        }

        p {
            margin-left: 21px;
        }
    }
`

const inititialState = {
    overlay1: false
}

const Folder2Slide6 = ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    const count1 = useCounter(0, 61000, props.active, props.preview)
    const count2 = useCounter(0, 526000, props.active, props.preview)
    const count3 = useCounter(0, 13000, props.active, props.preview)

    return (
        <Slide className="centered">
            <div>
                <h1>
                    <strong>Trotzdem noch hohe Aussteigerquote</strong> im<br></br>
                    Tageslinsenbereich
                </h1>

                <Image left={221} top={414} width={1685} height={454} src={img1} />

                <Container left={135} top={364} width={464} height={248} className="bg1 text-center box1 flex-all-center">
                    <span className="count">{formatThousands(count1)}</span>
                    <p className="small">jährliche Neuträger<br />
im Tageslinsenbereich<sup>13</sup></p>
                </Container>

                <Container left={800} top={720} width={448} height={150} className="rounded text-center box2 flex-all-center">
                    <span className="count">{formatThousands(count2)}</span>
                    <p className="small">Tageslinsenträger</p>
                </Container>

                <Container left={1455} top={916} width={462} height={282} className="bg1 text-center box1 flex-all-center">
                    <span className="count">{formatThousands(count3)}</span>
                    <p className="small">jährliche<br />
Gesamt-Aussteiger<br />
im Tageslinsenbereich<sup>13</sup></p>
                    <MoreBtn
                        className="x-left y-center"
                        active={state.overlay1 === 'overlay1'}
                        onClick={() => toggleOverlay('overlay1')} />
                </Container>

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                    <Container left={606} top={940} width={866} height={232} className="rounded rounded-full box3 flex">
                        <span className="count">2/10</span>
                        <p className="color1">angepasste<br />
                        Tageslinsenträger<br />
                        steigen wieder aus.</p>
                    </Container>
                </Overlay>
            </div>
        </Slide>
    );
};

export default Folder2Slide6;