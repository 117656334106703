import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
    .box1 {
       padding-left: 15px;
    }

    .box1 .number {
        position: absolute;
        display: block;
        left: 2px;
        top: -86px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .box1-2 .number {
        left: 16px;
    } 

    .box1-3 .number {
        left: 16px;
    } 

    .exclamation {
        display: block;
        position: absolute;
        left: 774px;
        top: 494px;
        width: 117px;
        font-size: 97.5px;
        line-height: 122px;
        color: #009ca6;
        font-style: italic;
        text-align: center;
    }

    .box3 {
        font-size: 30px;
        line-height: 32px;
    } 

    .overlay1 {
        .exclamation {
            color: #003087;
            left: 790px
        }

        .box4 p + p {
            margin-top: 20px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (


        <Slide className="centered">
            <h1>Wie nutzen Sie die Kampagne<br /> 
am <strong>erfolgreichsten?</strong></h1>

            <Container left={246} top={420} width={1540} height={172} className="bg1 box1 box1-1 color1 flex-all-center fadeInFromLeft">
                <span className="number number1">1.</span>
                <p><strong>Informieren &amp; trainieren Sie das Team zur Kampagne</strong><br /> 
(inkl. Einsatz &amp; Nutzen des Gutscheins)</p>
            </Container>

            <Container left={246} top={749} width={1540} height={110} className="bg1 box1 box1-2 color1 flex-all-center fadeInFromLeft animate2">
                <span className="number number1">2.</span>
                <p><strong>Platzieren Sie den Gutschein sichtbar im Geschäft.</strong></p>
            </Container>

            <Image left={1475} top={605} width={394} height={394} src={img1} className="fadeIn animate2" />

            <div className="container-animate fadeInFromLeft animate3">

                <Container left={246} top={1015} width={1540} height={172} className="bg1 box1 box1-3 color1 flex-all-center ">
                    <span className="number number1">3.</span>
                    <p><strong>Nutzen Sie weitere Kommunikations-Maßnahmen,</strong><br />
                    um für Kunden und Mitarbeiter sichtbar zu sein.</p>

                    
                    <MoreBtn
                        className="x-right y-center"
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'}
                    />
                </Container>
                <span className="exclamation">!</span>

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 overlay1-3 with-bg bg3">
                    <Container left={100} top={291} width={1688} height={895} className="box2 bg2 color1">
                    </Container>
                    <Image left={548} top={350} width={963} height={778} src={img2} />

                    <Container left={158} top={346} width={603} className="box3 color1" as="p">
                       <strong>Point of Sale:</strong>
                    </Container>

                    <Container left={158} top={418} width={603} className="color1 box4">
                    <p>1. Befestigen Sie<br /> 
den Schaufenster-<br />
Kleber</p> 

<p>2. Stellen Sie den<br /> 
Gehwegstopper/<br />
Poster und den<br /> 
L-Aufsteller gut<br /> 
sichtbar auf</p>

<p>3. Installieren<br /> 
Sie das Instore-<br />
Video</p>
                    </Container>

                 

                    <Container left={889} top={346} width={603} className="box3 color1" as="p">
                       <strong>Newsletter:</strong>
                    </Container>

                   
                    <Container left={1332} top={353} width={603} className="box3 color1" as="p">
                       <strong>Social Media:</strong>
                    </Container>

                    <Container left={889} top={418} width={603} className="color1" as="p">
                    4. Kontaktieren<br /> 
Sie Bestands- und<br /> 
Neukunden 

                    </Container>

                    <Container left={1332} top={426} width={603} className="color1" as="p">
                    5. Spielen Sie<br /> 
Social Media Post<br /> 
und Stories aus<br /> 

                    </Container>

                    <span className="exclamation">!</span>
                

                </Overlay>

            </div>




        </Slide>

    );
};
