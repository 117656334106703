import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';
import img1 from './assets/img1.svg';
import img2 from './assets/img2.jpg';


import img3 from './assets/img3.svg';




const Slide = styled.div`
    .box1 {
        padding-left: 20px;
        font-size: 28px;
        line-height: 31.5px;
    }

    .box1 .number {
        position: absolute;
        display: block;
        left: -5px;
        top: -78px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .overlay1-1,
    .overlay1-2 {
        .box2 {
            padding: 41px 10px 10px 41px;
        }

        .box2-1 {
            padding-left: 33px;
            padding-right: 0;
            padding-top: 30px;

            span {
                letter-spacing: -0.03rem;
            }
        }
    }

    .overlay1-1 .img1,
    .overlay1-2 .img2 {
        position: absolute;
        
    }

    .overlay1-1 .img1 {
        width: 49px;
        left: 580px;
        top: 516px;
    }



    .overlay1-2 {
        .box4 {
            position: relative;
            font-size: 53.4px;
            line-height: 66.75px;
            font-weight: 600;

            span {
                    position: absolute;
                    left: 0;
                    top: 104px;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 400;
                }
        }

        .box5 {
            .count {
                font-size: 53.4px;
                line-height: 66.75px;
                padding-top: 20px;

                .percent {
                    font-size: 28.8px;
                }
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 63, state.overlay1 === 'overlay2', props.preview)
    count1 = count1 / 10
    count1 = count1.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')
    
    const count2 = useCounter(0, 72, state.overlay1 === 'overlay2', props.preview)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})`, bottom: 0 }}></div>
            <Slide className="centered">
                <h1><strong>Warum diese Kampagne ein Erfolg wird?</strong></h1>

                <div className="container-animate fadeInFromLeft">
                    <Container left={167} top={352} width={906} height={141} className="bg1 box1 box1-1 color1 flex-all-center">
                        <span className="number number1">1</span>
                        <p><strong>Starke Aufmerksamkeit</strong></p>

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay1'}
                            onClick={() => toggleOverlay('overlay1')} />

                    </Container>


                </div>

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg bg3 overlay1 overlay1-1">
                    <Container left={1074} top={227} width={899} height={988} className="box2 box2-1 bg2 color1">
                        <p><span>Die neue Online-Kampagne von ACUVUE®</span><br />
                        erzielt dank Media-Investment und der<br />
                        zusätzlichen Unterstützung durch Influ-<br />
                        encer eine große Reichweite und hohe<br />
                        Aufmerksamkeit in der Zielgruppe.<br /><br />

Mit der Aufforderung „Jetzt unverbind-<br />
lich testen“ bringen wir die Zielgruppe<br />
in Ihr Geschäft.<br /><br />

Mit Hilfe der passenden Kampagnen-<br />
Werbemittel bietet ACUVUE® Ihnen die<br />
Möglichkeit die Kampagne bis an Ihren<br />
POS zu verlängern.</p>
                    </Container>

                    <img src={img1} className="img1" />

                    <Image left={1270} top={986} width={548} height={194} src={img2} />

                    <Container left={167} top={352} width={906} height={141} className="box1 box1-1 color1 flex-all-center text-center">
                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay1'}
                            onClick={() => toggleOverlay('overlay1')} />

                    </Container>

                </Overlay>

                <div className="container-animate fadeInFromLeft animate2">

                    <Container left={167} top={639} width={906} height={141} className="bg1 box1 color1 flex-all-center">
                        <span className="number number1">2</span>
                        <p><strong>Großes Umsatzpotenzial</strong></p>

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay2'}
                            onClick={() => toggleOverlay('overlay2')} />

                    </Container>



                </div>

                <Overlay active={state.overlay1 === 'overlay2'} className="with-bg bg3 overlay1 overlay1-2">
                    <Container left={1074} top={352} width={899} height={718} className="box2 bg2 color1">
                        
                    </Container>

                    <Container left={1449} top={364} width={514} height={350} className="text1 color1 flex-all-center"><p>… sind laut aktueller 
Studie <strong>aktiv an Kontakt-
linsen interessiert</strong> oder 
haben schon den ersten 
Kontakt gehabt.<sup>1</sup></p></Container>
                    <Container left={1449} top={695} width={514} height={372} className="text1 color1  flex-all-center"><p>… der Brillenträger 
sind weiterhin <strong>bereit<br /> 
für eine Kontaktlinsen-
anpassung.<sup>1</sup></strong></p></Container>


                    <Image left={1117} top={385} width={306} height={306} src={img3} />
                    <Container left={1117} top={385} width={306} height={306} className="flex-all-center text-center box4 color1">
                        {count1}<br />
                        <span>Mio</span>
                    </Container>

                    <CircleCount left={1117} top={729} width={306} percent={count2} color={'#003087'} />
                    <Container left={1117} top={729} width={306} height={306} className="flex-all-center text-center box5">
                        <span className="count">{count2}<span className="percent">%</span></span>
                    </Container>

                    <Container left={167} top={639} width={906} height={141} className="box1 color1 flex-all-center text-center">
                     
                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay2'}
                            onClick={() => toggleOverlay('overlay2')} />

                    </Container>


                </Overlay>

                <div className="container-animate fadeInFromLeft animate3">

                    <Container left={167} top={929} width={906} height={141} className="bg1 box1 color1 flex-all-center">
                        <span className="number number1">3</span>
                        <p><strong>Gezielte Botschaften</strong></p>

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay3'}
                            onClick={() => toggleOverlay('overlay3')} />
                    </Container>


                </div>


                <Overlay active={state.overlay1 === 'overlay3'} className="overlay1 overlay1-3 with-bg bg3">
                    <Container left={1074} top={493} width={899} height={631} className="box2 bg2 color1">
                    </Container>
                   
                    <Container left={1145} top={546} width={817} className="color1">
                        <p>Auf emotionale Weise wird verdeut-<br />
licht, dass ACUVUE® Kontaktlinsen<br /> 
zu Freiheit, Vertrauen und Flexibilität<br /> 
verhelfen, um das Leben in vollen<br /> 
Zügen genießen und jede neue<br /> 
Gelegenheit auskosten zu können.<br /><br /> 

Neuträgertypische Bedenken und<br />
Fragen begegnen wir mit hilfreichen,<br />
unterstützenden Werbemitteln wie<br />
z. B. der Handlingbroschüre.</p>
                    </Container>
                   

                    <Container left={167} top={929} width={906} height={141} className="box1 color1 flex-all-center text-center">
                      
                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay3'}
                            onClick={() => toggleOverlay('overlay3')} />
                    </Container>
                </Overlay>

            </Slide>
        </>
    );
};
