import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { getUsers, saveUser, deleteUser } from 'actions'

import styles from './Users.module.sass'
import { NavLink } from 'react-router-dom';

function makeid(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * 
 charactersLength)));
   }
   return result.join('');
}

const Index = ({ getUsers, saveUser, deleteUser, users }) => {
    const [activeUser, setActiveUser] = useState(null);

    useEffect(() => {
        getUsers();
    }, [])

    const handleChange = (name, value) => {
        setActiveUser({ ...activeUser, [name]: value })
    }

    const addUser = () => {
        const user = {
            id: null,
            name: '',
            email: '',
            passwordNew: makeid(8)
        }

        setActiveUser(user);
    }

    const postUser = () => {

        if (!activeUser.email || (!activeUser.password && !activeUser.passwordNew)) {
            return
        }

        saveUser(activeUser);
        setActiveUser(null)
    }

    const handleDelete = (id) => {
        deleteUser(id)
        setActiveUser(null)
    }

    const Form = () => (
        <div className="form">
            <form onSubmit={(event) => event.preventDefault()}>
                <div className="double">
                    <div>
                        <label>E-Mail</label>
                        <input type="email" value={activeUser?.email} onChange={e => handleChange('email', e.target.value)} />
                    </div>
                    <div>
                        <label>Name</label>
                        <input type="text" value={activeUser?.name} onChange={e => handleChange('name', e.target.value)} />
                    </div>
                </div>
                <div style={{marginTop: '15px'}}>
                    <label>Neues Passwort</label>
                    <input type="text" value={activeUser?.passwordNew} onChange={e => handleChange('passwordNew', e.target.value)} />
                </div>
                <div style={{marginTop: '15px'}}>
                    <label>Benutzer ist Admistrator</label>
                    <input type="checkbox" checked={activeUser?.rights_level===10} onChange={e => handleChange('rights_level', activeUser?.rights_level===10 ? null : 10)} />
                </div>

                

                <div className="buttons">
                    <button onClick={() => postUser()}>Speichern</button>
                    <span className="cancel" onClick={() => setActiveUser(null)}>Abbrechen</span>
                    {activeUser.id && 
                        <span className="delete" onClick={() => handleDelete(activeUser.id)}>Benutzer löschen</span>
                    }
                </div>
            </form>
        </div>
    )

    console.log(activeUser)

    if(!users?.length) {
        return null
    }

    return (
        <div className={styles.users}>
            <div className="center">
                <div style={{marginTop: '15px'}}>
                    <NavLink to="/">Zurück</NavLink>
                </div>
                <div className="title">
                    <h1>{users?.length} Benutzer</h1>
                    {(!activeUser && !activeUser?.id) &&
                        <button onClick={() => addUser()}>Neuer Benutzer</button>
                    }   
                </div>
                {(activeUser && !activeUser.id) &&
                    Form()
                }
                {users.map((item, index) => (
                    <div className="user">
                        {(activeUser?.id !== item.id) &&
                            <div className="head" onClick={() => setActiveUser(activeUser?.id !== item.id ? item : null)}>
                                <span><strong>{item.email}</strong>{item.rights_level==10 && <span className="small">Administrator</span>}</span><span>{item.name}</span>
                            </div>
                        }
                        {(activeUser?.id === item.id) &&
                            Form()
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { authentication } = state

    return {
        users: authentication.users
    }
}

const mapDispatchToProps = dispatch => ({
    getUsers: () => dispatch(getUsers()),
    saveUser: (user) => dispatch(saveUser(user)),
    deleteUser: (id) => dispatch(deleteUser(id))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)