import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn,
  Overlay,
  CloseBtn,
} from "components/parts";

import HeaderDots from "../../shared/HeaderDots";

import img1 from "./assets/img1.svg";
import img2 from "./assets/img2.svg";
import img3 from "./assets/img3.jpg";
import img4 from "./assets/img4.jpg";

const Slide = styled.div`
  inset: 0;
  width: 100%;
  height: 100%;
  font-family: "AvenirNext", sans-serif;

  .box1 {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 50px;
    font-size: 19px;
    line-height: 24px;
    text-transform: uppercase;
  }

  .box2 {
    line-height: 22px;
  }

  .copy {
    font-size: 9.5px;
  }

  .copy-2 {
    color: #232744;
    font-size: 8px;
    line-height: 10px;
  }
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide step={props.step}>
      <div className="centered">
        <h1 className="color2">
          ACUVUE® sorgt für <strong>unübertroffenen Tragekomfort</strong>
          <br />
          bei Kontaktlinsen*
        </h1>

        <HeaderDots top={268} color={"#051f4a"} />

        <Image
          src={img3}
          width={913}
          height={441}
          left={1035}
          top={325}
          className="fadeIn"
        />

        <Container
          left={1247}
          top={785}
          width={686}
          className="copy text-center color2 fadeIn"
        >
          Nur zu Illustrationszwecken
        </Container>

        <Container
          left={0}
          top={320}
          width={1036}
          height={136}
          className="box1 bg1 rounded-full flex flex-all-center relative color3 fadeIn"
        >
          <p>
            <strong>
              INTEGRIERTER BENETZUNGSWIRKSTOFF
              <br />
              FÜR FEUCHTIGKEIT<sup>7</sup>
            </strong>
          </p>
          <Image src={img1} width={38} height={80} left={964} top={28} />
        </Container>

        <Container
          left={100}
          top={490}
          width={767}
          className="box2 small color2 fadeIn"
        >
          Kontaktlinsen der Marke ACUVUE®
          <br />
          enthalten reines, hochmolekulares
          <br />
          Polyvinylpyrrolidon (PVP)<sup>8,9</sup> , das die Tränenfilmmuzine
          nachahmt und hilft,
          <br />
          die Hydratisierung/Feuchtigkeit der
          <br />
          Linse aufrechtzuerhalten.
          <sup>5</sup>
        </Container>

        <Image
          src={img4}
          width={442}
          height={248}
          left={1381}
          top={892}
          className="fadeIn animate2"
        />

        <Container
          left={1370}
          top={1148}
          width={462}
          className="copy text-center color2 fadeIn animate2"
        >
          Nur zu Illustrationszwecken
        </Container>

        <Container
          left={0}
          top={867}
          width={1036}
          height={100}
          className="box1 bg1 rounded-full flex flex-all-center relative color3 fadeIn animate2"
        >
          <p>
            <strong>
              superdünner rand<sup>7</sup> FÜR KOMFORT
            </strong>
          </p>
          <Image src={img2} width={38} height={80} left={964} top={10} />
        </Container>

        <Container
          left={100}
          top={1019}
          width={1067}
          className="box2 small color2  fadeIn animate2"
        >
          ACUVUE® Kontaktlinsen haben ein unsichtbares Randdesign,
          <br />
          sodass die{" "}
          <strong>
            Augenlider über sie gleiten, als wäre nichts da<sup>5</sup>
          </strong>
        </Container>

        <Container
          left={100}
          top={1154}
          width={1290}
          className="copy copy-2  fadeIn animate3"
        >
          *ACUVUE® Brand families of contact lenses with unbeaten in comfort
          claims: ACUVUE® OASYS (including daily disposable families), 1-DAY
          ACUVUE® MOIST (within the
          <br />
          category of hydrogel daily disposable), and ACUVUE® VITA®.(1,2,3) 1.
          JJV Data on File, 2024. Unbeaten in Comfort Claims for the ACUVUE®
          OASYS Brand Families of Contact
          <br />
          Lenses Using Clinical Trials Posted on ClinicalTrials.gov. (October
          31, 2024). 2. JJV Data on File, 2024. Unbeaten in Comfort Claims for
          the family of ACUVUE® 1-DAY MOIST
          <br />
          Brand Contact Lenses Using Clinical Trials Posted on
          ClinicalTrials.gov. (October 31, 2024) 3. JJV Data on File, 2024.
          Unbeaten in Comfort Claims for the family of ACUVUE® VITA Brand
          Contact Lenses Using Clinical Trials Posted on ClinicalTrials.gov.
          (October 31, 2024).
        </Container>
      </div>
      <div className="bg bg1" />
    </Slide>
  );
};
