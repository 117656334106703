import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from '../slide17/assets/img1.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.svg';

const Slide = styled.div`
     .box1 {
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        span,
        p {
            position: relative;
        }

        .count {
            font-size: 53px;
            line-height: 66px;
            letter-spacing: -0.05em;
        }

        p {
            position: absolute;
            left: 0;
            width: 100%;
            font-size: 18px;
            line-height: 20px;

            
            &.bottom {
                top: 102px;
            }
        }
          
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })


    let count1 = useCounter(0, 35, props.active, props.preview, 1)
    count1 = count1 / 10
    count1 = count1.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')

    let count2 = useCounter(0, 5, props.active, props.preview, 2)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered">
                <h1>Warum sich das <strong>Geschäft mit Kontaktlinsen</strong> lohnt? </h1>

                <Container left={317} top={256} width={890} height={170} className="rounded flex-all-center text-center color1">
                    <p><strong>Brillenträger*innen zu dualen Nutzern<br />
                        zu machen ist überaus lohnend!</strong></p>
                </Container>

                <Image src={img3} left={468} top={452} width={546} height={120} contain />

               
                <Arrow left={746} top={70} width={1115} className="rotate90" />

                <Image src={img4} left={110} top={683} width={1196} height={400} contain />

                <Container left={0} left={120} top={654} width={306} height={306} className="box1 flex-all-center text-center ">
                    <img src={img2} />
                    <span className="count">{count1}x</span>
                    <p className="bottom color1">mehr</p>
                </Container>

                <Container left={0} left={1137} top={926} width={306} height={306} className="box1 flex-all-center text-center ">
                    <img src={img2} />
                    <span className="count">{count2}x</span>
                    <p className="bottom color1">höher</p>
                </Container>

                <Container as="p" left={470} top={718} width={797} className="color1">
                <br />… Geld geben Ihre Kunden aus,<br />
                    wenn sie nicht nur Brillen, sondern<br />
                    auch Kontaktlinsen erwerben.<sup>4,5,6</sup>
                </Container>

                <Container as="p" left={184} top={990} width={928} className="color1 text-right">
                    … ist das Potenzial der Kundenbindung bei<br />
                    <strong>dualen Kunden</strong> als bei reinen Brillenträgern,<br />
                    da es mehr „Touchpoints“ zwischen Ihnen<br />
                    und Ihren Kunden gibt.<sup>6,7</sup>
                </Container>


            </Slide>
        </>

    );
};
