import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'
import { formatThousands } from 'components/helper'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'


const Slide = styled.div`
    .count {
        font-size: 62px;
        line-height: 77.5px;

        span {
            font-size: 33.5px;
        }
    }

    .box1 {
        align-items: center;
        justify-content: flex-start;

        .count {
            margin-top: 10px;
            width: 123px;
            margin-left: 36px;
        }
    }

    .box2 {
        padding-bottom: 28px;
        align-items: flex-end;
        justify-content: flex-start;

        .count {
            margin-left: 23px;
            width: 112px;
            margin-bottom: -23px; 
        }

        > span:not(.count) {
            position: absolute;
            left: 23px;
            top: 20px;
            font-weight: 600;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 47, props.active, props.preview)
    const count2 = useCounter(0, 22, props.active, props.preview)
    const count3 = useCounter(0, 17, props.active, props.preview)

    return (
        <Slide className="centered" step={props.step}>
            <h1>Fehlsichtige mit Astigmatismus stellen <strong>fast die Hälfte Ihrer<br />
            Kunden dar und sind eine riesige Chance</strong></h1>
            <Container left={451} top={302} width={1145} height={293} className="rounded rounded-full flex box1 color1">
                <span className="count">{count1}<span>%</span></span>
                <p>Fast die Hälfte der Kunden, die eine<br /> 
Sehkorrektion benötigen, <strong>haben<br /> 
einen Astigmatismus ≥ -0,75 dpt auf<br />
mindestens einem Auge.<sup>31</sup></strong></p>
            </Container>

            <div className="container-animate fadeInFromTop">

            <Container left={200} top={666} width={800} height={254} className="bg1 flex color1 box2 box2-1">
                <Arrow width={716} className="container-top" />
                <span>Nur</span>
                <span className="count">{count2}<span>%</span></span>
                <p><strong>der Kontaktlinsenträger<br /> 
tragen torische Linsen.<sup>32</sup></strong></p>
            </Container>
            <Container left={1053} top={666} width={800} height={254} className="bg1 flex color1 box2 box2-2">
                <Arrow width={716} className="container-top" />
                <span>Nur</span>
                <span className="count">{count3}<span>%</span></span>
                <p><strong>der Kunden mit<br />
Astigmatismus wissen<br />
von ihrer Besonderheit.<sup>33</sup></strong></p>
            </Container>

            </div>
            <div className="container-animate fadeInFromTop animate2">
            <Arrow left={1010} top={635} width={715} className="rotate90" />

            <Container left={100} top={1051} width={1848} className="text-center color1">
                <p>Sobald die Kunden wissen, dass sie einen Astigmatismus haben,<br /> 
<strong>sind Kontaktlinsenträger (+8/10) und potenzielle Träger (+9/10)<br /> 
stark an torischen Kontaktlinsen interessiert.<sup>34</sup></strong></p>
            </Container>
            </div>
        </Slide>
    );
};
