import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.png'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 45px;
        font-size: 37.5px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 9px;
        line-height: 12.5px;
    }

    .text2 {
        font-size: 25px;
        line-height: 32.5px;
    }

`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Wir präsentieren die multifokale Kontaktlinse,<br />
                    <strong>auf die Menschen mit Presbyopie gewartet haben</strong></h1>
                <Image left={370} top={465} width={1277} height={457} src={img1} />
                <Container left={746} top={883} width={544} as="p" className="text1 text-center">
                Produktabbildung nur zu Illustrationszwecken
                </Container> 

                <Container left={119} top={1023} width={1810} as="p" className="text2 text-center fadeIn">
                    <strong>Scharfe, klare Sicht <span className="color3">in jeder Entfernung</span> und bei allen<br />
                        Lichtverhältnissen – plus hoher Tagesend-Komfort</strong><sup>±°4</sup>
                </Container>

            </Slide>
        </>

    );
};

export default Folder2Slide1;