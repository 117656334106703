import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, CircleCount } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';

const Slide = styled.div`
      .count {
        font-size: 53.4px;
        line-height: 66.75;

        span {
            font-size: 28.8px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 80, props.active, props.preview)

    return (
        <Slide className="centered" step={props.step}>
            <h1>Ein Kunde, der <strong>Kontaktlinsen &amp; Brillen</strong> kauft, hat <strong>einen höheren<br />
                Kundenkapitalwert</strong> als ein Kunde, der nur Brillen trägt<sup>9</sup></h1>

            <Container left={809} top={504} width={430} height={460} className="bg1 flex-all-center text-center box3 color1">
                <Arrow width={350} className="container-bottom" bgColor={'#CFF2FB'} />
            </Container>

            <Image src={img1} left={361} top={638} width={297} height={112} contain />

            <Container left={185} top={763} width={650} height={180} className="rounded flex-all-center text-center color1">
                <p><strong>Nur<br />
                    Brillenträger</strong></p>
            </Container>

            <Container as="p" left={809} top={612} width={430} className="color1 text-center">
                <strong>…<br /> 
profitabler<br /> 
über<br /> 
die Dauer<br /> 
der Kunden-<br />
beziehung</strong>
            </Container>

            <CircleCount left={870} top={300} width={307} percent={count1} color={'#00a3e0'} />

            <Container left={870} top={300} width={307} height={307} className="flex-all-center text-center box2">
                    <span className="count color1">{count1}<span>%</span></span>
                </Container>

            <Container left={1213} top={536} width={650} height={180} className="rounded flex-all-center text-center color1">
                <p><strong>Kontaktlinsen-<br />
                    und Brillenträger
                </strong></p>
            </Container>

            <Image src={img2} left={1286} top={327} width={515} height={194} contain />

            <Image src={img3} left={1413} top={687} width={251} height={308} contain />

            <Container as="p" left={100} top={1076} width={1848} className="color1 text-center">
                Je länger Sie Ihre Kontaktlinsen- und Brillenkunden halten,<br />
                um so profitabler werden sie, da sie einen <strong>kontinuierlichen Cashflow</strong> liefern.
            </Container>

        </Slide>
    );
};
