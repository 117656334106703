import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from '../slide6/assets/icon1.svg'
import icon2 from '../slide6/assets/icon2.svg'
import icon3 from '../slide6/assets/icon3.svg'
import icon4 from '../slide6/assets/icon4.svg'
import icon5 from '../slide6/assets/icon5.svg'
import icon6 from '../slide6/assets/icon6.svg'

import img1 from './assets/img1.svg'

const Slide = styled.div`
    .line1 {
        position: absolute;
        left: 215px;
        top: 365px;
        width: 512px;
        height: 3.3px;
        background: #003087;
    }

    .line2 {
        position: absolute;
        left: 487px;
        top: 198px;
        width: 3.3px;
        height: 366px;
        background: #003087;
    }

    .box2 {
        align-items: center;

        .small {
            margin-top: 10px;
        }

        .tall {
            font-size: 27px;
            line-height: 33px;
        }
    }

    .box1 {
        align-items: center;
        justify-content: flex-start;

        .icon1 {
            margin-left: 23px;
            margin-right: 10px;
            width: 42px;
        }

        .icon2 {
            margin-left: 14px;
            width: 44px;
        }

        .icon3 {
            margin-left: 10px;
            margin-right: 4px;
            width: 48px;
        }

        .icon4 {
            margin-left: 14px;
            width: 44px;
        }

        .icon5 {
            margin-left: 21px;
            margin-right: 10px;
            width: 42px;
        }

        .icon6 {
            margin-left: 13px;
            margin-right: 4px;
            width: 46px;
        }
    }

    .circle {
        border-radius: 50%;
        color: #fff;
        padding-top: 9px;

        p {
            font-size: 17.5px;
        }

        span {
            font-size: 32.65px;
            line-height: 36.65px;
            font-weight: 600;
        }

        &.circle1 {
            background: #a7a9ac;
        }

        &.circle2 {
            background: #003087;
        }

        &.circle3 {
            background: #00a3e0;
        }

        &.circle4 {
            background: #000;
        }
    }

    .box3 {
        padding-left: 33px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Ideale Portfolio-Lösung:<br />
                <strong>Bedürfnisse spiegeln sich im Portfolio</strong></h1>

            <span className="line1" />
            <span className="line2" />

            <Container left={320} top={420} width={421} height={175} className="bg1 color1 flex-all-center text-center flex box2 color1 fadeIn">
                <img src={img1} width="146" />
                <p className="small">und Service System</p>
            </Container>

            <Container left={1280} top={420} width={421} height={124} className="bg1 color1 flex-all-center text-center flex box2 fadeIn animate2">
                <img src={img1} width="146" />
            </Container>

            <Container left={1280} top={912} width={421} height={175} className="bg1 color1 flex-all-center text-center flex box2 color1 fadeIn animate3">
                <img src={img1} width="146" />
                <p className="small">und ein Angebot</p>
            </Container>

            <Container left={215} top={912} width={421} height={124} className="bg1 color1 flex-all-center text-center flex box2 fadeIn animate4">
                <p className="tall">Eigenmarke</p>
                <Arrow width={316} className="container-bottom"  bgColor={'#FDE1C2'} />
            </Container>

            <Container left={201} top={1087} width={448} className="box2 color1 text-center fadeInFromTop animate4">
                <p className="tall"><strong>Preis-<br />
Positionierung!</strong></p>
            </Container>

            <Container left={710} top={485} width={222} height={222} className="circle circle1 flex-all-center text-center fadeIn animate">
                <p className="small"><strong>Bequem-<br />
                lichkeit</strong></p>
                <span>13%</span>
            </Container>

            <Container left={1056} top={437} width={269} height={269} className="circle circle2 flex-all-center text-center fadeIn animate2">
                <p className="small"><strong>Gesundheit /<br />
                Kompetenz</strong></p>
                <span>56%</span>
            </Container>

            <Container left={1063} top={764} width={247} height={247} className="circle circle3 flex-all-center text-center fadeIn animate3">
                <p className="small"><strong>Angebot</strong></p>
                <span>25%</span>
            </Container>

            <Container left={734} top={764} width={174} height={174} className="circle circle4 flex-all-center text-center fadeIn animate4">
                <p className="small"><strong>Preis</strong></p>
                <span>6%</span>
            </Container>



            <Container left={674} top={283} width={608} height={119} className="rounded flex box1 box1-1">
                <img src={icon1} className="icon1" />
                <p className="small">NICHT Preis- oder<br />
Angebotsorientiert</p>
                <img src={icon2} className="icon2" />
            </Container>

            <Container left={1443} top={676} width={382} height={119} className="rounded flex box1 box1-2">
                <img src={icon3} className="icon3" />
                <p className="small">Gesundheit/<br />
                Kompetenz</p>
            </Container>

            <Container left={672} top={1068} width={608} height={119} className="rounded flex box1 box1-3">
                <img src={icon5} className="icon5" />
                <p className="small">Preis- oder<br />
Angebotsorientiert</p>
                <img src={icon4} className="icon4" />
            </Container>

            <Container left={222} top={676} width={421} height={119} className="rounded flex box1 box1-4">
                <img src={icon6} className="icon6" />
                <p className="small">Bequemlichkeit</p>
            </Container>

            <MoreBtn
                left={116} top={932}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')}
                className="fadeIn animate4"
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                <Container left={157} top={875} width={884} height={202} className="rounded color1 flex-all-center box3">
                    <p><strong>Eigenmarke fängt die Preissensiblen<br />
                    ab – kein längerfristiger Effekt</strong></p>
                </Container>
            </Overlay>

        </Slide>
    );
};
