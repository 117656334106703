import React from "react";
import styled, { css } from "styled-components";

export const ArrowContainer = styled.div`
  position: absolute;
  left: ${(props) => props.left / 2}px;
  top: ${(props) => props.top / 2 + (props.width - 64) / 4}px;
  width: 18px;
  height: 32px;

  &.container-top {
    top: 0;
    left: 50%;
    transform: rotate(90deg) translate(-50%, 9px);
  }

  ${({ offset }) =>
    offset !== undefined &&
    css`
      margin-left: ${-offset / 2}px;
    `}

  &.container-bottom {
    bottom: -32px;
    left: 50%;
    transform: rotate(90deg) translate(-50%, 9px);
  }

  &.rotate90 {
    transform: rotate(90deg);
  }

  &.rotate180 {
    transform: rotate(180deg);
  }

  span {
    position: absolute;
    display: block;
    left: 0.5px;
    bottom: 25px;
    width: 1.8px;
    height: ${(props) => props.width / 4 - 8 + props.offset / 2}px;
    background: ${(props) =>
      props.lineColor !== undefined ? props.lineColor : "#293187"};
    border-radius: 0.9px;

    + span {
      bottom: inherit;
      height: ${(props) => props.width / 4 - 8 - props.offset / 2}px;
      top: 25px;
    }
  }
`;

const Arrow = React.memo((props) => {
  const offset = props.offset ? props.offset : 0;

  const styleArrow = props.arrowColor ? { fill: props.arrowColor } : {};

  return (
    <ArrowContainer
      left={props.left}
      top={props.top}
      width={props.width}
      className={props.className}
      lineColor={props.lineColor}
      offset={offset}
    >
      <svg viewBox="0 0 36.36 64.35" style={{ width: "18px" }}>
        <polyline
          points="4.19 0 36.36 32.17 4.19 64.35 4.19 51.35 23.32 32.17 4.19 12.87"
          fill={props.arrowColor ? props.arrowColor : '##00a4e4"'}
          className="fill"
          style={styleArrow}
        />

        <polyline
          points="3.62 14.77 20.61 32.17 3.5 49.57"
          fill="none"
          stroke={props.lineColor ? props.lineColor : "#293187"}
          strokeLinecap="round"
          strokeWidth="3.76"
        />
        <polyline
          points="0 50.56 18.39 32.17 0 13.79"
          fill={props.bgColor ? props.bgColor : "#fff"}
          className="bg"
        />
      </svg>
      <span />
      <span />
    </ArrowContainer>
  );
});

export default Arrow;
