import qs from "qs"
import axios from 'axios'

//const URL = `http://localhost/jannik/acuvue_app_v2/api/index.php`;
const URL = `https://acuvue-sales.jotpe.com/acuvue-sales-user/index.php`;


const fetchPage = async path => {
    console.log(path)
    const response = await fetch(`${URL}${path}`);
    const data = await response.json();
    if (response.status >= 400) {
        throw new Error(data.errors);
    }
    return data;
};

const fetchData = async authParams =>  {
    return await axios.request({
      method: 'post',
      url: URL,
      data: qs.stringify(authParams)
    });
}

export { fetchPage, fetchData };