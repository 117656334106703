import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';


const Slide = styled.div`
    .box1 {
       padding-left: 15px;
    }

    .box1 .number {
        position: absolute;
        display: block;
        left: 2px;
        top: -86px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .box1-2 .number {
        left: 16px;
    } 

    .box1-3 .number {
        left: 16px;
    } 

    .box1-3 .exclamation {
        position: absolute;
        left: 700px;
        top: -13px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    }

    .box2 .number {
        position: absolute;
        display: block;
        left: -64px;
        top: -10px;
        width: 60px;
        font-size: 56.5px;
        line-height: 71px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (


        <Slide className="centered">
            <h1>Wie nutzen Sie die Kampagne<br />
                am <strong>erfolgreichsten?</strong></h1>

            <Container left={246} top={420} width={1540} height={172} className="bg1 box1 box1-1 color1 flex-all-center fadeInFromLeft">
                <span className="number number1">1.</span>
                <p><strong>Informieren &amp; trainieren Sie das Team zur Kampagne</strong><br />
                    (inkl. Einsatz &amp; Nutzen des Gutscheins)</p>
            </Container>

            <Container left={246} top={749} width={1540} height={110} className="bg1 box1 box1-2 color1 flex-all-center fadeInFromLeft animate2">
                <span className="number number1">2.</span>
                <p><strong>Platzieren Sie den Gutschein sichtbar im Geschäft.</strong></p>
            </Container>

            <Image left={1475} top={605} width={394} height={394} src={img1} className="fadeIn animate2" />

            <div className="container-animate fadeInFromLeft animate3">

                <Container left={246} top={1015} width={1540} height={172} className="bg1 box1 box1-3 color1 flex-all-center ">
                    <span className="number number1">3.</span>
                    <p><strong>Nutzen Sie weitere Kommunikations-Maßnahmen,</strong><br />
                        um für Kunden und Mitarbeiter sichtbar zu sein.</p>

                    <span className="exclamation">!</span>
                    <MoreBtn
                        className="x-right y-center"
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'}
                    />
                </Container>

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 overlay1-3 with-bg">
                    <Container left={100} top={291} width={1688} height={895} className="box2 bg2 color1">
                    </Container>
                    <Image left={658} top={385} width={225} height={153} src={img2} />
                    <Image left={636} top={588} width={255} height={314} src={img3} />
                    <Image left={764} top={930} width={603} height={218} src={img4} />
                    <Image left={1456} top={339} width={285} height={199} src={img5} />

                    <Container left={158} top={346} width={603} className="box2 color1" as="p">
                        <strong>Point of Sale:</strong>
                    </Container>

                    <Container left={242} top={415} width={723} height={172} className="box2 color1">
                        <span className="number number1">1.</span>
                        <p>Befestigen Sie den<br />
                            Schaufenster-Kleber</p>
                    </Container>

                    <Container left={1030} top={415} width={445} height={172} className="box2 color1">
                        <span className="number number1">2.</span>
                        <p>Stellen Sie den<br />
                            Passanten-Störer auf</p>
                    </Container>

                    <Container left={158} top={599} width={603} className="box2 color1" as="p">
                        <strong>CRM System:</strong>
                    </Container>

                    <Container left={242} top={666} width={723} height={172} className="box2 color1">
                        <span className="number number1">1.</span>
                        <p>Schreiben Sie<br />
                            unzufriedene<br />
                            Wettbewerbsträger<br />
                            an (digit./postal.<br />
                            Mailings)</p>
                    </Container>

                    <Container left={1030} top={666} width={645} height={172} className="box2 color1">
                        <span className="number number1">2.</span>
                        <p>Schreiben Sie Ihre Brillenträger<br />
                            an, die eventuell woanders ihre<br />
                            Kontaktlinse kaufen</p>
                    </Container>

                    <Container left={158} top={990} width={603} className="box2 color1" as="p">
                        <strong>Social Media Kanäle:</strong>
                    </Container>

                    <Container left={242} top={1054} width={723} height={172} className="box2 color1">
                        <span className="number number1">1.</span>
                        <p>Spielen Sie Social Post<br />
                            und Stories aus
                        </p>
                    </Container>

                </Overlay>

            </div>




        </Slide>

    );
};
