import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
            <p>1. Hall &amp; Partners’ People Pulse, wöchentliche Daten, März 2020.</p>

            <p>2. appinio Studie 2020</p>

            <p>3. Euromonitor International Limited; Eyewear 2021 edition; value sales at rsp, all retail channels, 2019 data; “Acuvue family of brands” represents aggregated sales of the following brands: 1-day Acuvue, Acuvue Oasys, Acuvue Advance, Acuvue, and Acuvue2</p>

            <p>4. Umfragezeitraum: Dezember 2018 bis Januar 2019.</p>

            <p>5. Euromonitor International Limited; auf Grundlage der Forschung, die im August 2019 durchgeführt wurde; „Welt“ und „weltweit“ steht für Märkte, die 76 % des Verkaufs von Ein-Tages-Linsen (Einzelhandelsumsatz) 2018 ausmachten.</p>

            <p><strong>Wichtige Sicherheitsinformationen:</strong> ACUVUE® Kontaktlinsen sind für eine Sehkorrektion indiziert. Wie bei jeder Kontaktlinse können Augenprobleme, einschließlich Hornhautgeschwüre, auftreten. Bei einigen Trägern kann es zu leichten Irritationen, Juckreiz oder Unbehagen kommen. Kontaktlinsen sollten nicht bei Augeninfektionen oder anderen Augenkrankheiten verwendet werden.<br /> 
Vollständige Informationen, einschließlich Kontraindikationen und Vorsichtsmaßnahmen, entnehmen Sie bitte der Gebrauchsanweisung oder besuchen Sie unsere J&amp;J-Website jnjvisioncare.de/at</p>

<p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2 UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.<br />
UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken.<br />
UV- Transmission mit –1,00 Dioptrien Linse gemessen.</p>

<p>ACUVUE®, ACUVUE® OASYS 1-Day und HydraLuxe™ sind eingetragene Marken von Johnson &amp; Johnson Medical GmbH / Products GmbH.<br /> 
© Johnson &amp; Johnson Medical GmbH / Medical Products GmbH 2020 | PP2021MLT4232</p>
            </TextBox>
        </Slide>
    );
};
