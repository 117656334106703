import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux'
import { requestPassword, resetState } from 'actions'
import { Link } from 'react-router-dom';

const Scale = (dimensions) => {
    if (!dimensions) return 1

    const scaleX = dimensions.width / 1024
    const scaleY = (dimensions.height) / 724

    return scaleX < scaleY ? scaleX : scaleY
}

const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background: #00A5E3;
`

const Page = styled.div`
    position: absolute;
    transform: scale(${props => Scale(props.dimensions)}) translate(-50%, -50%);
    transform-origin: 0% 0%;
    width: 1024px;
    height: 639px;
    left: 50%;
    top: 50%;
    margin-top: -42px;
    display: flex;
    align-items: center;
    justify-content: center;     

    > div {
        margin-top: 50px;
        padding-top: 232px;
        display: flex;
        flex-direction: column;
        width: 615px;
        height: 615px;
        background: #fff;
        border-radius: 50%;
        align-items: center;
        border: 1px solid #003087;
    }

    h1 {
        margin: 0 0 30px 0;
        font-size: 26px;
        line-height: 35px;
        color: #003087;
        text-align: center;
        font-weight: 600;

        span {
            font-size: 56px;
            line-height: 56px;
            color: #00a3e0;
            text-transform: uppercase;
        }
    }


    input[type="email"],
    input[type="password"] {
        width: 200px;
    }

    .submit {
        cursor: pointer;
    }

    .link {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
    }

    p.info,
    p.error {
        margin-top: 15px;
        text-align: center;
    }
`

const Index = ({ requestPassword, authentication, resetState }) => {
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
        resetState();

        function handleResize() {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const [formData, setFormData] = useState({
        email: '',
        emailRepeat: ''
    });

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    return (
        <Background>
            <Page dimensions={dimensions}>
                <div>
                    <h1>
                        <span>Willkommen</span><br />
                            beim Digital Sales Folder von ACUVUE®
                        </h1>
                    <form>
                        {!authentication.isRequested ? (
                            <>
                                <div>
                                    <input placeHolder="Email" type="email" value={formData.email} onChange={e => handleChange('email', e.target.value)} />
                                    <span onClick={() => { requestPassword(formData.email) }} className="submit">Passwort zurücksetzen</span>
                                </div>
                                {authentication.error &&
                                    <p className="error" style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: authentication.error }}></p>
                                }
                            </>
                        ) : (
                                <div>
                                    <p className="info">Sie bekommen in Kürze eine E-Mail mit einem Bestätigungslink für Ihre Passwortänderung.</p>
                                </div>
                            )}
                        <Link to="/" className="link">Zum Login</Link>
                    </form>
                </div>
            </Page>
        </Background>
    );
};

const mapStateToProps = (state, props) => {
    const { authentication } = state

    return {
        authentication
    }
}

const mapDispatchToProps = dispatch => ({
    requestPassword: (email) => dispatch(requestPassword(email)),
    resetState: () => dispatch(resetState())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)