import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { saveFolder, saveFolderName } from "actions";

import Slide from "components/pages/folders/Slide";
import * as Folder1 from "components/pages/folders/folder1";
import * as Folder2 from "components/pages/folders/folder2";
import * as Folder3 from "components/pages/folders/folder3";
import * as Folder4 from "components/pages/folders/folder4";
import * as Folder5 from "components/pages/folders/folder5";
import * as Folder6 from "components/pages/folders/folder6";
import * as Folder7 from "components/pages/folders/folder7";
import * as Folder8 from "components/pages/folders/folder8";
import * as Folder9 from "components/pages/folders/folder9";
import * as Folder10 from "components/pages/folders/folder10";
import * as Folder11 from "components/pages/folders/folder11";
import * as Folder12 from "components/pages/folders/folder12";
import * as Folder13 from "components/pages/folders/folder13";
import * as Folder14 from "components/pages/folders/folder14";
import * as Folder15 from "components/pages/folders/folder15";
import * as Folder16 from "components/pages/folders/folder16";
import * as Folder17 from "components/pages/folders/folder17";
import * as Folder18 from "components/pages/folders/folder18";
import * as Folder19 from "components/pages/folders/folder19";
import Footer from "components/pages/folders/Footer";

import styles from "./Custom.module.sass";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";

const components = {
  ...Folder1.Slides,
  ...Folder2.Slides,
  ...Folder3.Slides,
  ...Folder4.Slides,
  ...Folder5.Slides,
  ...Folder6.Slides,
  ...Folder7.Slides,
  ...Folder8.Slides,
  ...Folder9.Slides,
  ...Folder10.Slides,
  ...Folder11.Slides,
  ...Folder12.Slides,
  ...Folder13.Slides,
  ...Folder14.Slides,
  ...Folder15.Slides,
  ...Folder16.Slides,
  ...Folder17.Slides,
  ...Folder18.Slides,
  ...Folder19.Slides,
};

const Index = ({ slides, saveFolder, saveFolderName, match, customTitle }) => {
  const [items, setItems] = useState([]);

  let title;

  const ref = useRef();

  switch (match.params.folder) {
    case "custom-a":
      title = "Folder A";
      break;
    case "custom-b":
      title = "Folder B";
      break;
    case "custom-c":
      title = "Folder C";
      break;
    case "custom-d":
      title = "Folder D";
      break;
    case "custom-e":
      title = "Folder E";
      break;
    case "custom-f":
      title = "Folder F";
      break;
  }

  if (customTitle) {
    title = customTitle;
  }

  const [editTitle, setEditTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(customTitle);

  useEffect(() => {
    setItems(slides);
  }, [slides]);

  useEffect(() => {
    setNewTitle(customTitle);
  }, [customTitle]);

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const result = swap(items, sourceIndex, targetIndex);

    console.log("saveFolder");
    saveFolder(match.params.folder, result);

    return setItems(result);
  }

  const removeSlide = (key) => {
    const itemsNew = items.filter((item) => item.key !== key);

    saveFolder(match.params.folder, itemsNew);

    return setItems(itemsNew);
  };

  const removeAllSlides = () => {
    const itemsNew = [];
    saveFolder(match.params.folder, itemsNew);

    return setItems(itemsNew);
  };

  const saveTitle = () => {
    saveFolderName(match.params.folder, newTitle);
    setEditTitle(false);
  };

  useEffect(() => {
    if (editTitle) {
      ref.current.focus();
    }
  }, [editTitle]);

  console.log(match);

  return (
    <>
      <div className={styles.custom}>
        <div className="center">
          <div className="title">
            {!editTitle ? (
              <>
                <h1>{title}</h1>
                <span className="empty" onClick={() => setEditTitle(true)}>
                  Name editieren
                </span>
                <span className="empty" onClick={() => removeAllSlides()}>
                  alle Seiten löschen
                </span>
              </>
            ) : (
              <>
                <input
                  type="text"
                  ref={ref}
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
                <span className="empty" onClick={() => saveTitle()}>
                  Speichern
                </span>
              </>
            )}
          </div>
          <GridContextProvider onChange={onChange}>
            <div className="container">
              <GridDropZone
                className="dropzone left"
                id="left"
                boxesPerRow={4}
                rowHeight={180}
                style={{ height: `${Math.ceil(items.length / 4) * 178}px` }}
              >
                {items.map((item, i) => {
                  console.log(item);

                  const Component = components[item.component];

                  console.log(Component);

                  return (
                    <GridItem key={item.key}>
                      <div className="grid-item">
                        <div>
                          <Slide theme={Component.theme} preview>
                            <Component.name index={i} step={1} preview />
                          </Slide>
                          <Footer variant={Component.footer} preview />
                        </div>
                        <span
                          className="remove"
                          onClick={() => removeSlide(item.key)}
                        >
                          <svg width="42" height="42" viewBox="0 0 82.79 82.79">
                            <line
                              x1="27.29"
                              y1="27.29"
                              x2="55.51"
                              y2="55.51"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeWidth="3.99"
                            />
                            <line
                              x1="55.51"
                              y1="27.29"
                              x2="27.28"
                              y2="55.51"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeWidth="3.99"
                            />
                          </svg>
                        </span>
                      </div>
                    </GridItem>
                  );
                })}
              </GridDropZone>
            </div>
          </GridContextProvider>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { folders } = state;

  const slides = folders?.folders[props.match.params.folder]
    ? folders.folders[props.match.params.folder]
    : [];
  const customTitle = folders?.foldersNames[props.match.params.folder]
    ? folders.foldersNames[props.match.params.folder]
    : null;

  console.log(props);
  console.log(slides);

  return {
    slides: slides,
    customTitle,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveFolder: (folder, slides) => dispatch(saveFolder(folder, slides)),
  saveFolderName: (folder, name) => dispatch(saveFolderName(folder, name)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
