import { authenticationConstants, customFoldersConstants } from '../constants';

const login = (email, password) => ({
    type: authenticationConstants.LOGIN_REQUEST,
    email,
    password
});

const logout = () => ({
    type: authenticationConstants.LOGOUT_REQUEST,
});

const validateLogin = () => ({
    type: authenticationConstants.VALIDATELOGIN_REQUEST,
});

const requestPassword = (email) => ({
    type: authenticationConstants.REQUESTPASSWORD_REQUEST,
    email
});

const changePassword = (email, password, hash) => ({
    type: authenticationConstants.CHANGEPASSWORD_REQUEST,
    email, 
    password, 
    hash
});

const getUsers = () => ({
    type: authenticationConstants.GETUSERS_REQUEST,
});

const saveUser = (user) => ({
    type: authenticationConstants.SAVEUSER_REQUEST,
    user
});

const deleteUser = (id) => ({
    type: authenticationConstants.DELETEUSER_REQUEST,
    id
});

const resetState = () => ({
    type: authenticationConstants.RESETSTATE_REQUEST
});

/* */

const getFolders = () => ({
    type: customFoldersConstants.GETFOLDERS_REQUEST,
});

const saveFolder = (folder, slides) => ({
    type: customFoldersConstants.SAVEFOLDER_REQUEST,
    folder,
    slides
});

const addSlide = (folder, slide) => ({
    type: customFoldersConstants.ADDSLIDE_REQUEST,
    folder,
    slide
});

/* */

const getFoldersNames = () => ({
    type: customFoldersConstants.GETFOLDERSNAMES_REQUEST,
});

const saveFolderName = (folder, foldername) => ({
    type: customFoldersConstants.SAVEFOLDERNAMES_REQUEST,
    folder,
    foldername
});


/* */

export {
    login,
    logout,
    validateLogin,
    requestPassword,
    changePassword,
    getUsers,
    saveUser,
    deleteUser,

    getFolders,
    saveFolder,
    addSlide,

    resetState,

    getFoldersNames,
    saveFolderName
}