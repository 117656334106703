import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.svg'
import img2 from './assets/img2.png'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 45px;
        font-size: 37.5px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .img1 {
        position: absolute;
        left: 41px;
        top: 152px;
        width: 146px;
    }

    .text1 {
        font-size: 25px;
        line-height: 32.5px;
    }

    .img2 {
        position: absolute;
        left: 491px;
        top: 113px;
        width: 500px;
    }

    .text2 {
        text-transform: uppercase;
        font-size: 20px;
        line-height: 22px;
    }

    .text3 {
        line-height: 27.5px;
    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Der <strong>MAX Multifocal</strong> Unterschied</h1>
                <img src={img1} className="img1" />


                <Container left={414} top={292} width={800} as="p" className="text1 fadeIn">
                    <strong className="color2">leistungsstarke<br />
                        Technologien</strong> in<br />
                    einem Produkt, um<br />
                    den Bedürfnissen<br />
                    presbyoper Kunden<br />
                    besser gerecht<br />
                    zu werden.<sup>*</sup>
                </Container>

                <div className="container-animate fadeIn animate2">

                    <img src={img2} className="img2" />

                    <Container left={777} top={692} width={637} as="p" className="text2 text-center">
                        <strong>PUPILLENOPTIMIERTES<br />
                            DESIGN</strong>
                    </Container>

                    <Container left={1440} top={471} width={530} as="p" className="text2 text-center">
                        <strong>TEARSTABLE<sup>™</sup><br />
                            TECHNOLOGY</strong>
                    </Container>

                    <Container left={1216} top={996} width={530} as="p" className="text2 text-center">
                        <strong>OPTiBlue<sup>™</sup><br />
                            Light Filter<sup>**</sup></strong>
                    </Container>

                </div>

                <div className="container-animate fadeInFromTop animate3">

                    <Arrow left={552} top={470} width={890} offset={58} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={0} top={980} width={1048} as="p" className="text3 text-center">
                        <strong>Scharfe, klare Sicht <span className="color3">in jeder Entfernung</span><br />
                            und bei allen Lichtverhältnissen – plus<br />
                            hoher Tagesend-Komfort.</strong><sup>±°5</sup>
                    </Container>

                </div>

            </Slide>
        </>

    );
};

export default Folder2Slide1;