import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.png'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 12px;
        line-height: 14.4px;
    }

    table {
        position: absolute;
        width: 679px;
        left: 176px;
        top: 442px;
        border-collapse: collapse;
        font-size: 12px;
        line-height: 14.4px;
    }

    th, td {
        padding: 0 18px;
        border: 1px solid #fff;
        
    }

    thead th {
        width: 225px;
        height: 34px;
        

        &:nth-child(1) {
            border: none;
        }

        &:nth-child(2),
        &:nth-child(3) {
            background: #3a8dde;
        }
    }

    tbody {
        th {
            height: 50px;
            background: rgba(10,124,193,0.7);
            text-align: left;
            font-weight: normal;
        }

        td {
            background: rgba(176,209,242,1);
            text-align: center;
            color: #1b365d;
        }
    }

`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1><strong>ACUVUE® OASYS MAX 1-Day</strong> Kontaktlinsen<br />
                    filtern 60 % des blau-violetten Lichts<sup>**8</sup> <strong>+</strong> bieten UV-Schutz<br />
                    der Klasse 1*, den man von <strong>ACUVUE® OASYS erwartet</strong><sup>§10</sup></h1>

                <Image left={108} top={442} width={1848} height={360} src={img1} />

                <Container left={121} top={393} width={298} as="p" className="text1 text_center"><strong>Nicht sichtbares Licht</strong></Container>
                <Container left={1203} top={393} width={250} as="p" className="text1 text_center"><strong>Sichtbares Licht</strong></Container>

                <Container left={117} top={727} width={214} as="p" className="text1 text-center">
                    <strong>UV-A/UV-B</strong><br />
                    280–380
                </Container>

                <Container left={371} top={727} width={232} as="p" className="text1 text-center">
                    <strong>HEV/Blau-violett</strong><br />
                    380–450
                </Container>



                <table className="fadeIn">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">% UV-Filterung<sup>*</sup></th>
                            <th scope="col">% Blau-Violett-Filterung<sup>*</sup></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"><strong>ACUVUE® OASYS MAX 1-Day Sphärisch & Multifokal</strong></th>
                            <td>UV-Schutz der Klasse 1:<br />
                                <strong>99,9 % UV-A, 100 % UV-B</strong> <sup>§10</sup></td>
                            <td><strong>60 %</strong><sup>**8</sup></td>
                        </tr>
                        <tr>
                            <th scope="row">Dailies Total1®<br />
                                Sphärisch & Multifokal<sup>¶</sup></th>
                            <td>Keine Angaben<sup>¶</sup></td>
                            <td>Keine Angaben<sup>¶</sup></td>
                        </tr>
                    </tbody>
                </table>


            </Slide>
        </>

    );
};

export default Folder2Slide1;