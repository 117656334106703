import React from "react";
import styled from "styled-components";

const CircularProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
`;

const Svg = styled.svg`
  transform: rotate(-90deg); /* Startpunkt bei 12 Uhr */
`;

const BackgroundCircle = styled.circle`
  fill: none;
  stroke: #e0e0e0; /* Hintergrundfarbe des Kreises */
  stroke-width: ${({ strokeWidth }) => strokeWidth}; /* Dünner Kreis */
`;

const ProgressCircle = styled.circle`
  fill: none;
  stroke: ${({ strokeColor }) =>
    strokeColor}; /* Farbe für die Prozentanzeige */
  stroke-width: ${({ strokeWidth }) => strokeWidth * 11}; /* Dickerer Kreis */

  stroke-dasharray: ${({ circumference }) => circumference};
  stroke-dashoffset: ${({ circumference, progress }) =>
    circumference - (progress / 100) * circumference};
  transition: stroke-dashoffset 0.3s ease;
`;

const CircularProgress = ({
  value = 50, // Aktueller Prozentwert
  size = 120, // Größe des Kreises
  strokeWidth = 1, // Breite des Hintergrundkreises
  strokeColor = "#051f4a", // Farbe des Fortschrittskreises
}) => {
  const radius = size / 2 - strokeWidth * 5.5; // Abstand für den Rand
  const circumference = 2 * Math.PI * radius; // Umfang des Kreises

  return (
    <CircularProgressContainer>
      <Svg width={size} height={size}>
        {/* Hintergrundkreis */}
        <BackgroundCircle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        {/* Fortschrittskreis */}
        <ProgressCircle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          strokeColor={strokeColor}
          circumference={circumference}
          progress={value}
        />
      </Svg>
    </CircularProgressContainer>
  );
};

export default CircularProgress;
