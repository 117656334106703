import React from 'react';
import styled,  { keyframes, css } from 'styled-components'

import { Container, Image } from 'components/parts'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.png';

const Title = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        position: relative;
        z-index: 10;
        font-size: 56px;
        line-height: 67px;
        margin-top: -15px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
    }
`

const Slide = styled.div`
    .box1 {
        transform: rotate(-8deg);
        font-size: 18.8px;
        line-height: 27px;
        font-weight: 600;

        li {
            position: relative;
            padding-left: 17px;

            + li {
                margin-top: 10px;
            }

            :before {
                position: absolute;
                left: 0px;
                top: 0;
                content:"·";
                font-size:40px;
                vertical-align:middle;
            }
        }
    }

    .animate1 {
        width: 100%;
        height: 100%;
        transform-origin: 90% 70%;
       
        ${({ preview }) => !preview && css`
            transform: scale(2.5);
            opacity: 0;
        `}
        
        ${({ active, preview }) => (active && !preview) && css`   
            animation: ${scaleTo1} 0.4s forwards ease-in;
            animation-delay: 0.6s;
        `}
    }
`

const scaleTo1 = keyframes`
    0% {
        transform: scale(2.5);
        opacity: 0;
    }
    100% {  
        transform: scale(1);
        opacity: 1;
    }
`

const Folder2Slide1 = (props) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})`, bottom: 0 }}></div>
            <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
                <div className="container-animate animate1">
                    <Image left={1378} top={648} width={496} height={475} src={img1} />
                    <Container left={1440} top={779} width={400} className="box1 color1">
                        <ul>
                            <li>Attraktive Marge</li>
                            <li>Starker Werterhalt</li>
                            <li>Attraktive<br />
    Kundengruppe</li>
                        </ul>
                    </Container> 
                </div>
            </Slide>
            <Title>
                <h1 style={{ color: '#fff' }}>Wirtschaft-<br />
lichkeit</h1>
            </Title>
        </>
    );
};

export default Folder2Slide1;