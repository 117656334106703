import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'
import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'
import Slide9 from './slides/slide9'
import Slide10 from './slides/slide10'

const Theme = {
    color1: '#00a98f',
    color2: '#003087',
    color3: '#00a98f',
    bg1: '#d8f2ef',
    bg2: '#ffffff',
}

export const Slides = {
    'Folder9Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder9Slide2': { name: Slide2, theme: Theme },
    'Folder9Slide3': { name: Slide3, theme: Theme },
    'Folder9Slide4': { name: Slide4, theme: Theme },
    'Folder9Slide5': { name: Slide5, theme: Theme },
    'Folder9Slide6': { name: Slide6, theme: Theme },
    'Folder9Slide7': { name: Slide7, theme: Theme },
    'Folder9Slide8': { name: Slide8, theme: Theme },
    'Folder9Slide9': { name: Slide9, theme: Theme },
    'Folder9Slide10': { name: Slide10, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder9Slide1' },
    { key: 1, component: 'Folder9Slide2' },
    { key: 2, component: 'Folder9Slide3' },
    { key: 3, component: 'Folder9Slide4' },
    { key: 4, component: 'Folder9Slide5' },
    { key: 5, component: 'Folder9Slide6' },
    { key: 6, component: 'Folder9Slide7' },
    { key: 7, component: 'Folder9Slide8' },
    { key: 8, component: 'Folder9Slide9' },
    { key: 9, component: 'Folder9Slide10' },
]