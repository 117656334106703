import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import img1 from './assets/arrow.svg'

const Slide = styled.div`
    .box1 {
        padding-left: 23px;
        border-radius: 30px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none !important;
    }

    .img1 {
        position: absolute;
        width: 285px;
        left: 52px;
        top: 161px;
    }

    .box2 {
        font-size: 18.5px;
        line-height: 21px;
        padding-left: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 17.5px;
        line-height: 21px;

        span {
            display: block;
            font-style: italic;
        }

        &.box2-1 span {
            font-size: 49px;
            line-height: 44px;
        }

        &.box2-2 span {
            font-size: 41px;
            line-height: 36px;
        }

        &.box2-3 span {
            font-size: 32px;
            line-height: 27px;
        }

        &.box2-4 span {
            font-size: 29px;
            line-height: 24px;
        }
    }

    .overlay1 p {
        color: #fff;

        span {
            display: block;
            font-size: 13.5px;
            line-height: 20px;
        }

        img {
            position: absolute;
            width: 55px;
            left: -65px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .box3 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Wie hat sich COVID-19 auf die <strong>Kategorie<br />
Kontaktlinse ausgewirkt?<sup>2</sup></strong></h1>

            <Container as="p" left={192} top={342} width={432} className="color1 text-center">
                <strong>Aktuelle Situation</strong>
            </Container>

            <Container as="p" left={669} top={342} className="color1">
                <strong>Einfluss von COVID-19</strong>
            </Container>

            <Container left={624} top={408} width={952} height={156} className="box1 rounded color1 flex-all-center">
                <p><strong>Motivation, Kontaktlinsen auszuprobieren,</strong><br />
hat während des Lockdowns <strong>abgenommen.</strong></p>
            </Container>

            <Container left={624} top={600} width={952} height={156} className="box1 rounded color1 flex-all-center">
                <p><strong>74%</strong> haben die <strong>Tragefrequenz erhöht</strong> oder
gleich gehalten, <strong>22%</strong> haben sie <strong>reduziert.</strong></p>
            </Container>

            <Container left={624} top={790} width={952} height={156} className="box1 rounded color1 flex-all-center">
                <p><strong>Kaum Neuanpassungen</strong><br />
während des Lockdowns</p>
            </Container>

            <Container left={624} top={980} width={952} height={156} className="box1 rounded color1 flex-all-center">
                <p><strong>2 %</strong> haben seit <strong>&gt;3 Monaten<br />
keine Linsen mehr getragen.</strong></p>
            </Container>


            <Container as="p" left={192} top={408} width={432} height={156} className="box2 box2-1 flex flex-all-center text-center color1 bg1">
                <strong><span>6,3 Mio.</span>
                Aktiv Interessierte</strong>
            </Container>

            <Container as="p" left={192} top={600} width={432} height={156} className="box2 box2-2 flex flex-all-center text-center color1 bg1">
                <strong><span>3,2 Mio.</span>
                bestehende Träger</strong>
            </Container>

            <Container as="p" left={192} top={790} width={432} height={156} className="box2 box2-3 flex flex-all-center text-center color1 bg1">
                <strong><span>0,55 Mio.</span>
                Neuträger</strong>
            </Container>

            <Container as="p" left={192} top={980} width={432} height={156} className="box2 box2-4 flex flex-all-center text-center color1 bg1">
                <strong><span>0,32 Mio.</span>
                Aussteiger</strong>
            </Container>

            <Arrow left={1615} top={476} width={590} arrowColor="#390075" lineColor="#390075" />

            <MoreBtn
                left={1712} top={730}
                onClick={() => toggleOverlay('overlay1')}
            />

            <Container as="p" left={1668} top={656} className="color1">
                <strong>Aussicht</strong>
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                <Container left={624} top={316} width={1241} height={880} className="bg2 box3">

                </Container>
                <Container as="p" left={750} top={444} width={906}>
                    Kaum noch nachweisbare Vorbehalte<br />
Kontaktlinsen auszuprobieren
                    <img src={img1} />
                </Container>
                <Container as="p" left={750} top={634} width={906}>
                    Von den 22% <strong>planen 78%</strong> zum alten<br />
Trageverhalten zurückzukehren
<img src={img1} />
                </Container>
                <Container as="p" left={750} top={806} width={906}>
                    <strong>72%</strong> der befragten Brillenträger wollen sich<br />
demnächst <strong>mit Kontaktlinsen versorgen</strong> –<br />
                    <span>Potenzielle Neuträger wollen einen Termin (innerhalb von 2 Wochen)</span>
                    <img src={img1} />
                </Container>
                <Container as="p" left={750} top={1038} width={906}>
                    <strong>98%</strong> der Träger <strong>tragen weiterhin Linsen</strong>
                    <img src={img1} />
                </Container>

                <Arrow left={1615} top={476} width={590} className="rotate180" bgColor="#9166AE" arrowColor="#390075" lineColor="#390075" />

                <Container as="p" left={1668} top={656} style={{ color: '#fff' }}>
                    <strong>Aussicht</strong>
                </Container>

                <CloseBtn
                    left={1722} top={740}
                    onClick={() => toggleOverlay('overlay1')}
                    color={'#FFFFFF'}
                />

            </Overlay>

        </Slide >
    );
};
