import React from 'react';
import styled from 'styled-components'

import bg from './assets/bg.jpg'

const Slide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: -7px;
        font-size: 56px;
        line-height: 52px;
        color: #fff;
        font-weight: 600;
        color: #fff;
    }

    h2 {
        border-top: 1px solid #fff;
        color: #fff;
        font-size: 33px;
        line-height: 36px;
        padding-top: 18px;
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <div>
                    <h1 style={{ color: '#fff' }}>Erlebe die Welt<br />
                        mit anderen Augen</h1>
                    <h2>Die große Mach-es-jetzt-Aktion</h2>
                </div>
            </Slide>
        </>

    );
};

export default Folder2Slide1;