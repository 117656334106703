import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    font-family: "AvenirNext";
    
    .list {
        display: flex;

        .symbol {
            flex: 0 0 18px;
        }

        + .list {
            margin-top: 2px;
        }
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
                <p className="list"><span className="symbol">+</span>	Ausgegangen wird von einer Kauffrequenz von 3,5 Jahren für den Kauf einer Gleitsichtbrille.</p>
                <p className="list"><span className="symbol">*</span>	Sample includes glasses wearers including Varifocal, Bifocal, Single-vision and reading.</p>
                <p className="list"><span className="symbol">#</span>	Das Ergebnis basiert darauf, wie häufig Teilnehmende die Antwort „Würde in den kommenden 12 Monaten definitiv/wahrscheinlich weiterhin Kontaktlinsen tragen“ gewählt haben.</p>
                <p className="list"><span className="symbol">‡</span>	Die Grafik dient nur der Illustration und zeigt, wie Ablagerungen auf einer Silikon-Hydrogel-Kontaktlinse wahrend eines Monats zunehmen können.
                    Die Menge und die Art der Ablagerungen variieren und sind u. a. abhängig von Faktoren wie physiologischen Gegebenheiten, Pflege, Linsenmaterial, Tragezeit und Umwelteinflüssen.</p>
                <p className="list"><span className="symbol">¶</span>	For more information email: info@vision-care.co.uk</p>
                <p className="list"><span className="symbol">**</span>	Bis zu 4 Linsen insgesamt.</p>
                <p className="list"><span className="symbol">^</span>	Compared to competitor’s designs, technology optimized for both the parameters of refractive error and add power.</p>
                <p className="list"><span className="symbol">º</span>	n = 172</p>
                <p className="list"><span className="symbol">¥</span>	n = 378</p>
                <p className="list"><span className="symbol">#</span>	Ausgenommen ACUVUE® 2</p>

                <p className="list" style={{ marginTop: '14px' }}><span className="symbol">1.</span>	JJV-Archivdaten 2019: Wachstumstreiber und internationale Analysen von unabhängigen Drittdaten aus  Deutschland.</p>
                <p className="list"><span className="symbol">2.</span>	Berechnung basierend auf Daten unabhängiger Dritter, Betrachtungszeitraum: Januar – Dezember 2021</p>
                <p className="list"><span className="symbol">3.</span>	MF Segmentation 2020 UK, Total Sample N=1500 45-64 year olds suffering from Presbyopia (n=1246 glasses only wearers, n=114 MF CL wearers).</p>
                <p className="list"><span className="symbol">4.</span>	JJVC Data on file 2016. MRM US Monthly CLW 30 day Experience (Kadence Survey).</p>
                <p className="list"><span className="symbol">5.</span>	JJVC Data on file 2016. MRM US-UK-DE Monthly Wearer Experience (Leger Survey).</p>
                <p className="list"><span className="symbol">6.</span>	Einmonatige Studie mit 287 gewohnheitsmäßigen Linsenträgern von Air Optix® Aqua und Biofinity®, bestehend aus täglichen Umfragen zur Messung des Komforts am Ende des Tages.</p>
                <p className="list"><span className="symbol">7.</span>	JJV Data on file 2022. CSM- ACUVUE PUPIL OPTIMIZED DESIGN Technology: JJVC contact lenses, design features, and associated benefits.</p>
                <p className="list"><span className="symbol">8.</span>	JJV Data on File 2015. 1-DAY ACUVUE® MOIST MULTIFOCAL Fit and Performance</p>
                <p className="list"><span className="symbol">9.</span>	JJV Data on File 2020. ACUVUE® OASYS MULTIFOCAL Fit and Performance Claims</p>
                <p className="list"><span className="symbol">10.</span>	JJV Data on File 2022: Master Brand Claims on Clinical Performance and Overall Material Properties for ACUVUE Brand Soft Contact Lenses.</p>
                <p className="list"><span className="symbol">11.</span>  JJV Data on File 2022. Subjective Stand-Alone Claims for ACUVUE® OASYS MAX 1-Day MULTIFOCAL Contact Lenses - Exploratory Meta-analysis.</p>
                <p className="list"><span className="symbol">12.</span>  JJV Data on File 2021. Dual Action Technology and “Cushion of Moisture” Description for 1-DAY ACUVUE® MOIST Brand Family of Contact Lenses.</p>
                <p className="list"><span className="symbol">13.</span>  JJV Data on File 2022: Stand-Alone Fit Success Claims for ACUVUE OASYS MAX 1-Day MULTIFOCAL Contact Lenses.</p>


                <p style={{ marginTop: '28px' }}>Kontaktlinsen der Marke ACUVUE® dienen zur Korrektion des Sehvermögens. Eine detaillierte Produktbeschreibung und Informationen zur Sicherheit entnehmen Sie der Gebrauchsanweisung
                    oder besuchen Sie die Johnson & Johnson-Website www.e-ifu.com/de.</p>

                <p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse-1- oder Klasse 2-UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen. UV-absorbierende
                    Kontaktlinsen sind KEIN Ersatz für UV-absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken. UV-Transmission mit –1,00-dpt-Linse gemessen.</p>

                <p>ACUVUE®, ACUVUE® OASYS MAX 1-Day MULTIFOCAL, ACUVUE® OASYS MULTIFOCAL und 1-DAY ACUVUE® MOIST MULTIFOCAL sind eingetragene Marken von Johnson & Johnson.<br />
                    © 2023 Johnson & Johnson Medical GmbH / Johnson & Johnson Medical Products GmbH | PP2023MLT6576</p>


            </TextBox>
        </Slide>
    );
};
