import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';
import img1 from './assets/img1.svg';

import img2 from './assets/img2.png';

const Slide = styled.div`
    .img1 {
        position: absolute;
        left: 50px;
        top: 227px;
        width: 817px;
    }

    .box1 {
        color: #fff;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1>Basiswissen in Kombination mit aktuellen<br />
                    Erkenntnissen als <strong>On-Demand-Seminare</strong></h1>

                <Container as="p" left={100} top={271} width={1500} className="small">
                    Nach erfogreicher Teilnahme erhalten alle Teilnehmer*innen ein Zertifikat.
                </Container>

                <img src={img1} className="img1" />

                <Image as="a" left={1562} top={42} width={505} height={288} src={img2} href="https://www.jnjvisioncare.de/online-seminare" target="_blank" />


                <Container as="p" left={712} top={572} width={400} className="box1 flex flex-all-center text-center">
                    <strong>Kommunikation</strong>
                </Container>
                <Container as="p" left={360} top={772} width={400} className="box1 flex flex-all-center text-center">
                    <strong>Presbyopie</strong>
                </Container>
                <Container as="p" left={905} top={772} width={400} className="box1 flex flex-all-center text-center">
                    <strong>Astigmatismus</strong>
                </Container>

                <Container as="p" left={152} top={972} width={440} className="box1 flex flex-all-center text-center">
                    <strong>Auswahl &amp; Upgrade</strong>
                </Container>
                <Container as="p" left={717} top={972} width={400} className="box1 flex flex-all-center text-center">
                    <strong>Pflegemittel</strong>
                </Container>
                <Container as="p" left={1262} top={972} width={400} className="box1 flex flex-all-center text-center">
                    <strong>Problemlösungen</strong>
                </Container>
            </Slide>
        </>
    );
};
