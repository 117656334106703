import { useState, useEffect, useRef } from 'react'

function useSlideState({inititialState, active}) {
    const [state, setState] = useState(inititialState)

    const toggleOverlay = (overlay) => {
        setState({ ...state, overlay1: state.overlay1 !== overlay ? overlay : null })
    }

    const updateState = (name, value) => {
        setState({ ...state, [name]: value})
    }

    useEffect(() => {
        console.log(active)

        if(!active && state.overlay1) setState({inititialState})
    }, [active])

    return {
        state,
        toggleOverlay,
        updateState
    }
}

function useCounter(start, end, active, preview, delayed=0, initialDelayed, intervalLength) {
    const [count, setCount] = useState(start)
    const steps = Math.ceil((end-start)/20)
    const interval = useRef();
    const timer = useRef();

    let delay = 600+ (delayed * 1000); 

    if(initialDelayed!==undefined) {
        delay = initialDelayed;
    }

    useEffect(() => {
        //if(!active && !interval.current) return

        if(active) {

            let testDelay = count === start ? delay : intervalLength!==undefined ? intervalLength : 50

            console.log('runn')
            interval.current = setInterval(() => {

                let countNew = count+steps
                
                if((start < end && countNew>end) || (start > end && countNew<end)) {
                    countNew = end
                    clearInterval(interval.current);
                    console.log('ended')
                }

                setCount(countNew)
            }, testDelay);
        } else {
            setCount(start)
        }
        
        return () => clearInterval(interval.current);
    }, [active, count]);

    if(preview) return end

    return count
}

export {
    useSlideState,
    useCounter
}