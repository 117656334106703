import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.jpg';

import img5 from '../slide2/assets/img1.svg';
import img6 from '../slide2/assets/img3.svg';

const Image1Animation = keyframes`
    0% {
        transform: scale(0.7) rotate(-10deg);
        opacity: 0;
    }
    100% {  
        transform: scale(100%) rotate(-10deg) ;
        opacity: 1;
    }
`

const Image2Animation = keyframes`
    0% {
        transform: scale(0.7) rotate(3deg);
        opacity: 0;
    }
    100% {  
        transform: scale(100%) rotate(3deg);
        opacity: 1;
    }
`

const Slide = styled.div`

    .box1 {
        padding-top: 32px;
    }

    .box1 img {
        width: 46px;
        margin-bottom: 24px;
    }
    
    .img1,
    .img2 {
        position: absolute;
        width: 244px;
        height: 323px;
        border: 1px solid #390075; 
    }

    .img1 {
        left: 432px;
        top: 260px;
        transform: rotate(-10deg);

        ${({ preview }) => !preview && css`
            opacity: 0;
        `}


        ${({ active, preview, step }) => (active && !preview) && css`
            animation: ${Image1Animation} 1s forwards;
            animation-delay: 0.6s;
        `}
    }

    .img2 {
        left: 664px;
        top: 290px;
        transform: rotate(3deg);

        ${({ preview }) => !preview && css`
            opacity: 0;
        `}


        ${({ active, preview, step }) => (active && !preview) && css`
            animation: ${Image2Animation} 1s forwards;
            animation-delay: 0.6s;
        `}
    }
    
    .overlay1 {
        overflow: hidden;
        padding-top: 61px;
        border: 0.5px solid #003087;
    }

    .overlay1 .img4 {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.4;
    }

    .overlay1 p {
        position: relative;
        z-index: 1;
    }

    .overlay1 .img5,
    .overlay1 .img6 {
        position: absolute;
        opacity: 0.3;
    }

    .overlay1 .img5 {
        width: 49px;
        left: 0;
        top: 42px;
    }

    .overlay1 .img6 {
        width: 44px;
        right: 0px;
        top: 178px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1><strong>„MeinOptiker-Kampagne“</strong> und die<br />
                <strong>„Große Kontaktlinsen-Aktion“</strong></h1>

            <Container left={185} top={321} width={570} height={744} className="box1 bg1 color1 text-center">
                <img src={img3} />
                <p>Die Chancen im<br />
                    Kontaktlinsengeschäft<br />
                    stehen trotz der<br />
                    anhaltenden COVID-<br />
                    Pandemie gut – und<br />
                    Johnson & Johnson Vision<br />
                    unterstützt Optiker<br />
                    dabei, dieses Potenzial<br />
                    erfolgreich zu<br />
                    nutzen. </p>
            </Container>

            <Container left={710} top={320} width={1232} className="color1 text-center">
                <p><strong>Ein sich ergänzendes, unschlagbares Duo –<br />
                    Kompetenz-Vermittlung und AKTION</strong></p>
            </Container>

            <Arrow left={1308} top={20} width={899} className="rotate90" />

            <img src={img2} className="img2" />
            <img src={img1} className="img1" />


            <MoreBtn
                left={1284} top={529}
                onClick={() => toggleOverlay('overlay1')}
                className="more1" 
                active={state.overlay1 === 'overlay1'}
                />

            <Overlay className="with-bg" active={state.overlay1 === 'overlay1'}>

                <Container left={846} top={569} width={952} height={640} className="overlay1 bg2 color1 text-center">
                    <img src={img4} className="img4" />
                    <p>Mit der „Mein Optiker-Kampagne”<br />
                        macht Johnson &amp; Johnson Vision<br />
                        Endverbraucher <strong>auf die Vorteile<br />
                            der persönlichen und kompetenten<br />
                            Beratung im stationären Fachhandel aufmerksam. </strong><br /><br />

                        Und <strong>stellt die zeitnahe Terminverfüg-<br />
                            barkeit</strong> gegenüber sonst häufig langer<br />
                        Wartezeiten <strong>in den Vordergrund.</strong></p>
                    <img src={img5} className="img5" />
                    <img src={img6} className="img6" />
                </Container>
            </Overlay>

        </Slide>
    );
};
