import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn,
  Overlay,
  CloseBtn,
} from "components/parts";

import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.jpg";

const Slide = styled.div`
  background: #0a7cc1;
  inset: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "AvenirNext", sans-serif;

  h1 {
    margin-top: 26px !important;
    color: #fff !important;
    text-align: center;

    .underline {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 2px;
        background-color: white;
      }
    }
  }

  .box1 {
    line-height: 30px;
    font-size: 20px;
  }

  .box2 {
    font-size: 17.5px;
    line-height: 25px;

    strong {
      font-size: 22.5px;
      text-transform: uppercase;
    }
  }

  .disrupter {
    background: #ff6a39;
    font-size: 12.3px;
    line-height: 18px;
    border: 1.4px solid #fff;
    transform: rotate(-16deg);
  }

  .copy-1 {
    font-size: 6px;
    line-height: 10px;
  }

  .copy-2 {
    font-size: 8px;
    line-height: 10px;
  }
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide step={props.step}>
      <div className="centered">
        <h1 className="color2">
          Profitieren Sie von{" "}
          <strong>
            95% durchschnittlicher
            <br />
            Erfolgsquote
          </strong>
          <sup>*1</sup> bei der <span className="underline">ersten</span>{" "}
          Anpassung
        </h1>

        <Container
          left={100}
          top={242}
          width={1850}
          className="box1 text-center"
        >
          <strong>ACUVUE® für Astigmatismus Kontaktlinsen bieten ...</strong>
        </Container>

        <Image src={img1} width={416} height={422} left={196} top={360} />

        <Container
          left={104}
          top={515}
          width={602}
          className="box2 text-center"
        >
          Außergewöhnliche
          <br />
          <strong>
            Sehqualität<sup>1</sup>
          </strong>
        </Container>

        <Image src={img1} width={416} height={422} left={802} top={360} />

        <Container
          left={717}
          top={515}
          width={602}
          className="box2 text-center"
        >
          Außergewöhnliche
          <br />
          <strong>
            Stabilität<sup>1</sup>
          </strong>
        </Container>

        <Image src={img1} width={416} height={422} left={1425} top={360} />

        <Container
          left={1340}
          top={515}
          width={602}
          className="box2 text-center"
        >
          Außergewöhnlicher
          <br />
          <strong>
            Komfort<sup>1</sup>
          </strong>
        </Container>

        <Container
          left={168}
          top={330}
          width={480}
          height={480}
          className="bg1 mask fadeOut"
        />

        <Container
          left={775}
          top={330}
          width={480}
          height={480}
          className="bg1 mask fadeOut animate2"
        />

        <Container
          left={1398}
          top={330}
          width={480}
          height={480}
          className="bg1 mask fadeOut animate3"
        />

        <Image src={img2} width={1361} height={381} left={372} top={848} />

        <Container
          left={221}
          top={826}
          width={214}
          height={214}
          className="scaleIn animate4"
        >
          <Container
            left={0}
            top={0}
            width={214}
            height={214}
            className="disrupter color2 flex flex-all-center text-center color3 rounded-full"
          >
            wieder <strong>vollumfänglich erhältlich</strong>
          </Container>
        </Container>

        <Container left={1492} top={1114} width={382} className="copy-1 color3">
          Produktabbildungen nur
          <br />
          zu Illustrationszwecken
        </Container>

        <Container left={100} top={1240} width={1290} className="copy-2 color3">
          * Criteria for first fit success: rotation ≤ 20 degrees, lens
          stability ≤ 5 degrees movement on blink; acceptable general fitting
          characteristics, no need for power or axis adjustment.
        </Container>
      </div>
    </Slide>
  );
};
