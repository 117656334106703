import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
                <p>1. JJV Data on File 2020. ACUVUE Brand - Eye-Inspired Innovations.</p>

                <p>2. Zertifikate werden nach Abschluss des eLearning-Programms (Module) über das Bestehen verschiedener Tests und Teilnahmebescheinigungen für die Mehrzahl der Online-Seminare angeboten.</p>


                <p><strong>Wichtige Sicherheitsinformationen:</strong> ACUVUE® Kontaktlinsen sind für eine Sehkorrektion indiziert. Wie bei jeder Kontaktlinse können Augenprobleme, einschließlich Hornhautgeschwüre, auftreten. Bei einigen Trägern kann es zu leichten Irritationen, Juckreiz oder Unbehagen kommen. Kontaktlinsen sollten nicht bei Augeninfektionen oder anderen Augenkrankheiten verwendet werden.
                    Vollständige Informationen, einschließlich Kontraindikationen und Vorsichtsmaßnahmen, entnehmen Sie bitte der Gebrauchsanweisung oder besuchen Sie unsere J&amp;J-Website jnjvisioncare.de/at</p>

                <p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2 UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.<br />
                    UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken.<br />
                    UV- Transmission mit –1,00 Dioptrien Linse gemessen.</p>

                <p>ACUVUE®, ACUVUE® OASYS 1-Day und HydraLuxe® sind eingetragene Marken von Johnson &amp; Johnson.<br />
                    © 2022 Johnson &amp; Johnson Medical GmbH / Johnson &amp; Johnson Medical Products GmbH</p><br />

            </TextBox>
        </Slide>
    );
};
