import "./App.sass";
import React from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";

import Login from "components/pages/login";
import Request from "components/pages/request";
import Change from "components/pages/change";
import Overview from "components/pages/overview";
import Folder from "components/pages/folders";
import Users from "components/pages/users";
import Custom from "components/pages/custom";
import CustomOverview from "components/pages/customOverview";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { sha256 } from "js-sha256";

import { validateLogin, getFolders, getFoldersNames } from "actions";

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? <Component {...props} /> : <Login />
      }
    />
  );
};

function App({
  authentication,
  validateLogin,
  getFolders,
  getFoldersNames,
  history,
  location,
}) {
  const [clientIdHashed, setClientIdHashed] = useState(null);

  useEffect(() => {
    if (authentication.isLoggedin) {
      validateLogin();
    }
  }, []);

  useEffect(() => {
    if (authentication.isLoggedin) {
      getFolders();
      getFoldersNames();
    }

    if (authentication.isLoggedin && process.env.REACT_APP_GA_PROPERTY) {
      const clientIdHashedNew = sha256(authentication.email);

      ReactGA.initialize(process.env.REACT_APP_GA_PROPERTY, {
        gaOptions: {
          siteSpeedSampleRate: 100,
          storage: "none",
          clientId: clientIdHashedNew,
          storeGac: false,
        },
      });

      setClientIdHashed(clientIdHashedNew);
    }
  }, [authentication]);

  useEffect(() => {
    if (
      clientIdHashed &&
      authentication.isLoggedin &&
      process.env.REACT_APP_GA_PROPERTY
    ) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  }, [authentication, location, clientIdHashed]);

  return (
    <div className="App">
      <Switch>
        <PrivateRoute
          authed={authentication.isLoggedin}
          path="/folders/:folder/:slideKey?"
          component={Folder}
        />
        <PrivateRoute
          authed={authentication.isLoggedin}
          path="/custom/:folder"
          component={Custom}
        />
        <PrivateRoute
          authed={authentication.isLoggedin}
          path="/custom"
          component={CustomOverview}
        />
        <PrivateRoute
          authed={authentication.isLoggedin}
          path="/users"
          component={Users}
        />
        <Route path="/request" component={Request} />
        <Route path="/change" component={Change} />
        <PrivateRoute
          authed={authentication.isLoggedin}
          path="/overview/:folder?"
          component={Overview}
        />
        <PrivateRoute
          authed={authentication.isLoggedin}
          path="/"
          component={Overview}
        />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { authentication } = state;
  return {
    authentication,
  };
};

const mapDispatchToProps = (dispatch) => ({
  validateLogin: () => dispatch(validateLogin()),
  getFolders: () => dispatch(getFolders()),
  getFoldersNames: () => dispatch(getFoldersNames()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
