import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow, MoreBtn, Overlay } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useSlideState, useCounter } from 'components/hooks'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.jpg'
import img2 from './assets/img2.jpg'
import img3 from './assets/img3.svg'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .img1 {
        border-color: #3a8dde !important;
    }

    .img3 {
        position: absolute;
        left: 444px;
        top: 343px;
        width: 137px;
        height: 137px;
    }

    .text1 {
        line-height: 27.5px;
    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

    .box1 {
        font-size: 27.5px;
        line-height: 35px;
    }
`
const inititialState = {
    overlay1: null
}

const Folder2Slide1 = ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Das Leben hat sich verändert<br />
                    <strong>und unsere Augen beeinflusst</strong></h1>

                <Image left={744} top={359} width={273} height={273} src={img1} className="img1 rounded border" />

                <Image left={1035} top={359} width={273} height={273} src={img2} className="img1 rounded border" />


                <Container left={36} top={438} width={672} as="p" className="text1 text-right">
                    Wir verbringen täglich viel<br />
                    Zeit an digitalen Geräten<sup>6</sup>
                </Container>

                <div className="container-animate fadeInFromTop">

                    <Arrow left={436} top={400} width={500} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={36} top={747} width={672} as="p" className="text1 text-right">
                        Man blinzelt weniger, das<br />
                        beeinflusst den Tränenfilm<br />
                        und <strong>wirkt sich negativ auf<br />
                            den Tragekomfort aus</strong>.<sup>3,4</sup>
                    </Container>

                </div>

                <Container left={1348} top={438} width={672} as="p" className="text1">
                    Blau-violettes Licht umgibt<br />
                    uns den ganzen Tag<sup>5</sup>
                </Container>

                <div className="container-animate fadeInFromTop">

                <Arrow left={1582} top={400} width={500} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                <Container left={1348} top={773} width={672} as="p" className="text1">
                    <strong> Die visuelle Qualität
                        wird beeinträchtigt<sup>5</sup></strong>
                </Container>

                </div>

                <img className="img3 fadeIn" src={img3} />

                <MoreBtn
                    left={242} top={1087}
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'}
                />

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                    <Container left={284} top={1040} width={1480} height={176} className="bg1 box1 color2 flex-all-center text-center">
                        Diese Symptome<br />
                        <strong>verschlimmern sich mit zunehmendem Alter</strong>
                    </Container>

                </Overlay>

            </Slide>
        </>

    );
};

export default Folder2Slide1;