import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'

const Theme = {
    color1: '#A8ABAE',
    bg1: '#F2F2F3',
    bg2: '#00a3e0',
}

export const Slides = {
    'Folder4Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder4Slide2': { name: Slide2, theme: Theme },
    'Folder4Slide3': { name: Slide3, theme: Theme },
    'Folder4Slide4': { name: Slide4, theme: Theme },
    'Folder4Slide5': { name: Slide5, theme: Theme, steps: 2},
    'Folder4Slide6': { name: Slide6, theme: Theme }
}

export const Order = [
    { key: 0, component: 'Folder4Slide1' },
    { key: 1, component: 'Folder4Slide2' },
    { key: 2, component: 'Folder4Slide3' },
    { key: 3, component: 'Folder4Slide4' },
    { key: 4, component: 'Folder4Slide5' },
    { key: 5, component: 'Folder4Slide6' },
]