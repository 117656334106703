import React from 'react';
import styled from 'styled-components'

import bg from './assets/bg.jpg' 

const Slide = styled.div`
    background: url(${bg});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin: 86px 0 0 0;
        z-index: 10;
        font-size: 32.5px;
        line-height: 39px;
        font-weight: 600;
    }

    h2 {
        margin: 10px 0 0 0;
        position: relative;
        z-index: 10;
        font-size: 40.5px;
        line-height: 48.5px;
        font-weight: 600;
        text-transform: uppercase;
    }

    p {
        margin-top: 50px;
        z-index: 10;
        position: relative;
        font-family: "NoeDisplay", sans-serif;
        font-size: 40.5px;
        line-height: 48.5px;
        font-weight: 800;
        color: #fff;
    }
`

const Folder2Slide1 = () => {
    return (
        <Slide className="bg full">
            <span className="color-overlay" />
            <h1 style={{color: '#fff'}}>Mythos<br /> 
Internet schadet meinem Geschäft –</h1>
            <h2 style={{color: '#fff'}}>Was kann ich vom internet lernen?</h2>
            <p>- Maßnahmen -</p>
        </Slide>
    );
};

export default Folder2Slide1;