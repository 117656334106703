import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState } from "components/hooks";

import {
  Arrow,
  Container,
  Image,
  MoreBtn,
  Overlay,
  TextBox,
} from "components/parts";

const Slide = styled.div`
  h1 {
    color: #0a7cc1;
  }

  h2 {
    margin: 20px 0 10px 0;
    font-size: 20px;
    line-height: 24px;
    color: #0a7cc1;
  }

  .list {
    display: flex;

    .symbol {
      flex: 0 0 18px;
    }
  }
`;
const inititialState = {
  overlay1: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide className="centered" step={props.step}>
      <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
      <TextBox>
        <p>
          <strong>Aktionszeitraum: 01.02. – 31.12.2024</strong> |
          Teilnahmeberechtigt ist jede natürliche Person, deren Wohnort sich in
          Deutschland oder Österreich befindet und die bei Teilnahme an der
          Aktion mind. 18 Jahre alt und unbeschränkt geschäftsfähig ist. Eine
          Teil-Rückerstattung erfolgt für jede zweite gekaufte Packung eines
          Produkts (Mindestkauf zwei Packungen) im selben teilnehmenden
          Fachhandelsgeschäft. Die Teilnahme ist ausschließlich durch Hochladen
          des jeweiligen Bons, eines Fotos des gekauften Produktes und der
          vollständigen Eingabe der Daten auf der Aktionsseite möglich.
          Aktionsprodukte und Teil-Rückerstattungsbeträge entnehmen Sie bitte
          den vollständigen Teilnahmebedingungen für Deutschland auf
          acuvue.de/alterssichtigkeit-geht-auch-anders und für Österreich auf
          acuvue.at/alterssichtigkeit-geht-auch-anders.{" "}
        </p>

        <p>* Insgesamt 4 Linsen.</p>

        <p>
          1. JJV-Archivdaten 2019: Wachstumstreiber und internationale Analysen
          von unabhängigen Drittdaten aus Deutschland.
          <br />
          2. JJV Data on File 2020. ACUVUE® OASYS MULTIFOCAL Fit and Performance
          Claims.
          <br />
          3. JJV Data on File 2022: Stand-Alone Fit Success Claims for ACUVUE®
          OASYS MAX 1-Day MULTIFOCAL Contact Lenses.
        </p>

        <p>
          <strong>Wichtige Sicherheitsinformationen:</strong> Kontaktlinsen der
          Marke ACUVUE® dienen zur Korrektion des Sehvermögens. Wie bei allen
          Kontaktlinsen können Probleme mit den Augen,
          <br />
          einschließlich Hornhautgeschwüre, auftreten. Bei manchen
          Kontaktlinsenträgern können leichte Reizungen, Jucken oder Unbehagen
          auftreten. Kontaktlinsen sollten nicht bei Augeninfektionen
          <br />
          oder anderen Augenerkrankungen oder bei systemischen Erkrankungen, die
          das Auge beeinträchtigen können, verwendet werden. Weitere
          Produktinformationen, einschließlich
          <br />
          Kontraindikationen, Vorsichtsmaßnahmen und Nebenwirkungen, entnehmen
          Sie der Gebrauchsanweisung auf der Johnson & Johnson-Website
          www.e-ifu.com.
        </p>

        <p>
          Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2
          UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor
          schädlicher UV-Strahlung zu schützen.
          <br />
          UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende
          und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht
          vollständig abdecken.
          <br />
          UV-Transmission mit –1,00 Dioptrien Linse gemessen.
        </p>

        <p>
          ACUVUE®, ACUVUE® OASYS MAX 1-Day MULTIFOCAL, ACUVUE® OASYS MULTIFOCAL
          und 1-DAY ACUVUE® MOIST MULTIFOCAL sind eingetragene Marken von
          Johnson & Johnson.
          <br />© 2024 Johnson & Johnson Medical GmbH / Medical Products GmbH |
          2024PP04117
        </p>
      </TextBox>
    </Slide>
  );
};
