import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

const Slide = styled.div`
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Bei Kunden mit Astigmatismus <strong>können selbst leichte Kopf- und<br />
            Augenbewegungen ihre Sehqualität beeinträchtigen</strong></h1>
            <Container left={343} top={330} width={1361} height={119} className="rounded rounded-full flex-all-center text-center color1">
                <p>Die Schwerkraft sowie Augen- und Kopfbewegungen<br />
destabilisieren die Sehqualität im Alltag und bei Aktivitäten.</p>
            </Container>

            <Image left={162} top={508} width={326} height={326} src={img1} className="border rounded" />
            <Image left={632} top={508} width={326} height={326} src={img2} className="border rounded" />
            <Image left={1086} top={508} width={326} height={326} src={img3} className="border rounded" />
            <Image left={1556} top={508} width={326} height={326} src={img4} className="border rounded" />

            <Container left={162} top={871} width={326} height={326} className="text-center color1">
                <p><strong>Auto fahren</strong></p>
            </Container>
            <Container left={632} top={871} width={326} height={326} className="text-center color1">
                <p><strong>Blinzeln</strong></p>
            </Container>
            <Container left={1086} top={871} width={326} height={326} className="text-center color1">
                <p><strong>Handwerken</strong></p>
            </Container>
            <Container left={1556} top={871} width={326} height={326} className="text-center color1">
                <p><strong>Sport</strong></p>
            </Container>

            
            <Container left={100} top={988} width={1848} height={196} className="bg1 flex-all-center text-center color1 fadeInFromTop">
                <Arrow width={839} className="container-top" />
                <p><strong>Sorgen Sie dafür, dass Ihre Kunden den ganzen Tag über eine stabile Sehqualität<br /> 
haben, damit sie das Tragen von Kontaktlinsen nicht aufgeben.</strong></p>
            </Container>
            
        </Slide>
    );
};
