import Slide1 from "./slides/slide1";
import Slide2 from "./slides/slide2";
import Slide3 from "./slides/slide3";
import Slide4 from "./slides/slide4";
import Slide5 from "./slides/slide5";
import Slide6 from "./slides/slide6";
import Slide7 from "./slides/slide7";
import Slide8 from "./slides/slide8";
import Slide9 from "./slides/slide9";
import Slide10 from "./slides/slide10";
import Slide11 from "./slides/slide11";
import Slide12 from "./slides/slide12";
import Slide13 from "./slides/slide13";
import Slide14 from "./slides/slide14";
import Slide15 from "./slides/slide15";
import Slide16 from "./slides/slide16";

const Theme = {
  color2: "#051f4a",
  color3: "#ffffff",
  color4: "#0a7cc1",
  bg1: "#0a7cc1",
  bg2: "#ffffff",
  bg3: "#051f4a",
};

export const Slides = {
  Folder19Slide1: { name: Slide1, theme: Theme, footer: "transparent white" },
  Folder19Slide2: { name: Slide2, theme: Theme },
  Folder19Slide3: { name: Slide3, theme: Theme },
  Folder19Slide4: { name: Slide4, theme: Theme },
  Folder19Slide5: { name: Slide5, theme: Theme },
  Folder19Slide6: { name: Slide6, theme: Theme, steps: 6 },
  Folder19Slide7: { name: Slide7, theme: Theme },
  Folder19Slide8: { name: Slide8, theme: Theme },
  Folder19Slide9: { name: Slide9, theme: Theme },
  Folder19Slide10: { name: Slide10, theme: Theme },
  Folder19Slide11: { name: Slide11, theme: Theme },
  Folder19Slide12: { name: Slide12, theme: Theme },
  Folder19Slide13: { name: Slide13, theme: Theme },
  Folder19Slide14: { name: Slide14, theme: Theme },
  Folder19Slide15: { name: Slide15, theme: Theme },
  Folder19Slide16: { name: Slide16, theme: Theme },
};

export const Order = [
  { key: 0, component: "Folder19Slide1" },
  { key: 1, component: "Folder19Slide2" },
  { key: 2, component: "Folder19Slide3" },
  { key: 3, component: "Folder19Slide4" },
  { key: 4, component: "Folder19Slide5" },
  { key: 5, component: "Folder19Slide6" },
  { key: 6, component: "Folder19Slide7" },
  { key: 7, component: "Folder19Slide8" },
  { key: 8, component: "Folder19Slide9" },
  { key: 9, component: "Folder19Slide10" },
  { key: 10, component: "Folder19Slide11" },
  { key: 11, component: "Folder19Slide12" },
  { key: 12, component: "Folder19Slide13" },
  { key: 13, component: "Folder19Slide14" },
  { key: 14, component: "Folder19Slide15" },
  { key: 15, component: "Folder19Slide16" },
];
