import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
     .list {
        display: flex;

        .symbol {
            flex: 0 0 6px;
            margin-right: 4px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
                <ol>
                    <li>Path to Purchase Study, 2018-2020, Including 12 Markets, including UK, France, Italy, Germany, Poland, Russia, Spain, Netherlands, Belgium, Norway, Denmark, Sweden.
                        Aktive Interessierte (Personen, die in den letzten 12 Monaten Schritte unternommen haben, um Kontaktlinsen zu erhalten), aber nicht zum Augenoptiker gegangen sind.</li>

                    <li>Die Bewertung der Leistung basierte auf Antworten von 243 Patienten zu den Punkten Sehqualität, Tragekomfort und Zufriedenheit, die über den Tag verteilt alle 2 Stunden auf einer 5-Punkte-Skala eingestuft wurden.Mathews K et al. Exploring variability in soft contact lens performance throughout the day. AAO poster presentation 2015.</li>

                    <li>Craig JP, Willcox MDP, Argüeso P, et al. The TFOS International Workshop on Contact Lens Discomfort: Report of the Contact Lens Interaction with the Tear Film Subcommittee.
                        Invest Ophthalmol Vis Sci. 2013, 54:TFOS123- TFOS156.</li>

                    <li>McCarey BE, Wilson LA. pH, osmolarity and temperature effects of the water content of hydrogel lenses. Cont lens Intra Lens J 1982, 8: 158-67.</li>

                    <li>Kojima T, Matsumoto Y, Ibrahim OM, Wakamatsu TH, Uchino M, Fukagawa K, Ogawa J, Dogru M, Negishi K, Tsubota K. Effect of controlled adverse chamber environment exposure on
                        tear functions in silicon hydrogel and hydrogel soft contact lens wearers. Invest Ophthalmol Vis Sci. 2011, 52:8811-7.</li>

                    <li>Nilsson SEG, Andersson L. Contact lens wear in dry environments. Acta Ophthalmol 64:221-225, 1986.</li>

                    <li>Craig JP, Willcox MDP, Argüeso P, et al. The TFOS International Workshop on Contact Lens Discomfort: Report of the Contact Lens Interaction with the Tear Film Subcommittee.
                        Invest Ophthalmol Vis Sci. 2013, 54:TFOS123- TFOS156.</li>

                    <li>Guillon, M., &amp; Maissa, C. (2008). Contact lens wear affects tear film evaporation. Eye &amp; contact lens, 34(6), 326- 330.</li>

                    <li>Kojima T, Matsumoto Y, Ibrahim OM, Wakamatsu TH, Uchino M, Fukagawa K, Ogawa J, Dogru M, Negishi K, Tsubota K. Effect of controlled adverse chamber environment exposure
                        on tear functions in silicon hydrogel and hydrogel soft contact lens wearers. Invest Ophthalmol Vis Sci. 2011, 52:8811-7.</li>

                    <li>Johnson BA et al. Inspired by the science of tears. Optometric Management 2015, Volume: 50, Special Edition: 13-16.</li>

                    <li>Craig JP, et al. TFOS CL &amp; TF subcommittee. IOVS 2013;TFOS123. 2. Patel S, et al. Effect of VDU on blink and tears. Optom Vis Sci. 1991;888</li>

                    <li>JJV Data on File 2018. Similarities between Mucin and Poly(N-Vinyl Pyrrolidone) (PVP).</li>

                    <li>JJV Data on File 2018. HydraLuxe® Technology Definition.</li>

                    <li>Brennan NA, Coles C. Supporting Data Linking Coefficient of Friction and Soft Contact Lens Comfort. Contact Lens Anterior Eye. 2013;36(S2):E10. doi: 10.1016/j.clae.2013.08.040.</li>

                    <li>JJV Data on File 2016. Design Enhancements and Resultant Benefits of ACUVUE® OASYS Brand Contact Lenses 1-Day with HydraLuxe® Technology for ASTIGMATISM.</li>

                    <li>JJV Data on File 2020. Coefficient of Friction of ACUVUE® OASYS Brand Contact Lenses (2-Week and Daily Disposable) Compared to the Human Cornea.</li>

                    <li>TLF= tränenartige Flüssigkeit, In einer vergleichenden in-vitro-Zeitverlaufsstudie wurde der Reibungskoeffizient als Funktion der Zykluszeit in TLF für bis zu 18 Stunden bestimmt.</li>

                    <li>Sterner O, Aeschlimann R, Zurcher S, et al. Friction Measurement on Contact Lenses in a Physiologically Relevant Environment: Effect of Testing Conditions on Friction. Invest.
                        Ophthalmol. Vis. Sci. 2016;57(13): 5383-5392. doi: 10.1167/iovs.16-19713.</li>

                    <li>JJV Data on File 2017. Optical Precision of ACUVUE® OASYS Brand 1-Day Contact Lenses with HydraLuxe® Technology.</li>

                    <li>Cheng X, Moody K, Xu J, et al. Visual Performance of Silicone Hydrogel Daily Disposable Contact Lenses. Contact Lens Anterior Eye. 2018;41(S1):S70. https://doi.org/10.1016/j.clae.2018.03.093.</li>

                    <li>JJV Data on File 2016. Statistical superiority on Comfort, Vision, and Handling composite scores achieved using the Contact Lens User Experience (CLUE) questionnaire, a validated patient-
                        reported outcomes (PRO) tool. In two randomized, single (patient) masked, crossover clinical studies (2015, n=119 (Vision &amp; Handling) and 2016, n=276 (Comfort)) patients were randomly
                        assigned ACUVUE® OASYS 1-Day with HydraLuxe® Technology or Dailies Total1 the first week and switched to wearing the other lens the second week. Lenses were worn as daily disposable on both eyes.</li>

                    <li>JJV Data on File 2015. 1-week dispensing evaluation, daily wear study, n=119 soft contact lens wearers in the United States.</li>

                    <li>In einer Markteinsch tzung mit 142 Augen rzten in Frankreich (als Kontaktlinsenanpasser bezeichnet), die 1453 Patienten (n=804 neue Tr ger, n=186 ehemalige Kontaktlinsenträger, n=463
                        bestehende Kontaktlinsentäger) versorgten. Data on file JJVC, 2019; In market assessment with ACUVUE® OASYS 1-Day with HydraLuxe®, with 142 ECPs fitting in France fitting 1453 new,
                        existing and lapsed soft CL wearers.</li>

                    <li>JJV Data on File 2019. In-market assessment with ACUVUE® OASYS 1-Day with HydraLuxe®, with 142 ECPs fitting in France, fitting 1,453 new, existing, and lapsed contact lens wearers.</li>

                    <li>JJV Data on File 2021. ACUVUE® OASYS Family Digital Device Claims (ACUVUE® OASYS, ACUVUE® OASYS 1-Day, and ACUVUE® OASYS with Transitions™ Light Intelligent Technology™).</li>

                    <li>JJV Data on File 2021. Dryness/Comfort Evaluation of ACUVUE® OASYS 1-Day Brand Contact Lenses vs. Habitual DailyDisposable Contact Lenses in a Study Population
                        including Digital Device Users, including Post-Hoc Sub-Group Analysis of Wearers that Use Digital Devices for Over Eight Hours.</li>

                    <li>JJV Data on File 2021. ACUVUE® Brand Contact Lenses: PVP [poly(N-vinyl pyrrolidone)] and its Similarity to Mucin.</li>

                    <li>JJV Data on File 2018. Material Properties: ACUVUE® OASYS Brand Contact Lenses with HYDRACLEAR® PLUS, ACUVUE® OASYS with Transitions™ Light Intelligent Technology™,
                        and Other Reusable Contact Lenses.</li>

                    <li>JJV Data on File 2021. Material Properties: 1-DAY ACUVUE® MOIST, 1-DAY ACUVUE® TruEye®, and ACUVUE® OASYS 1-Day with HydraLuxe® Technology Brand Contact Lenses.</li>

                    <li>JJV Data on File 2015. Minimal Frictional Energy/Sliding Work with ACUVUE® OASYS 1-Day with HydraLuxe® Technology Brand Contact Lenses.</li>

                    <li>Young G, Sulley A, Hunt C. Prevalence of astigmatism in relation to soft contact lens fitting. Eye Contact Lens. 201;37(1)20-25. Of the 47.4% of patients, nearly half exhibited
                        significant astigmatism in one eye only.</li>

                    <li>Internal analysis using independent 3rd party data 2019</li>

                    <li>Vision Needs Monitor 2019 (%), EMA 14 markets including UK, France, Italy, Germany, Russia, Poland, Spain, Netherlands, Belgium, Norway, Denmark, Sweden, Turkey, Switzerland.</li>

                    <li>Contact lens Patient Usage &amp; Attitude Study, UK, 2014; Total sample n=850 (n=500 CL wearers).</li>

                    <li>JJV Data on File 2021. ACUVUE® Brand Contact Lenses for ASTIGMATISM - Overall Fitting Success, Orientation Position, Rotational Stability, and Vision Performance.</li>

                    <li>JJV Data on File 2020. ACUVUE Brand Contact Lenses for ASTIGMATISM - Overall Fitting Success, Orientation Position, Rotational Stability, and Vision Performance.</li>

                    <li>JJV Data on File 2017. Toric lens thickness profile images and vertical prism measured in the optic zone.</li>

                    <li>Straker B, Olivares G, Sulley A and Ruston D. A new daily disposable toric contact lens for demanding days, Optician 2017, 253; 6605: 25-28.</li>

                    <li>Du Toit R, Ramke J, Brian G. Tolerance to prism induced by ready-made spectacles: setting and using a standard. Optom Vis Sci. Nov. 2007; 84(11): 1053–9.</li>

                    <li>JJV Data on File 2021. Proportion Astigmats Accommodated with ACUVUE® Brand Contact Lenses for ASTIGMATISM.</li>

                    <li>JJV Data on File 2020. ACUVUE Brand - Eye-Inspired Innovations.</li>

                    <li>JJV Data on File 2021. Material Properties: 1-DAY ACUVUE® MOIST, 1-DAY ACUVUE® TruEye® and ACUVUE® OASYS 1-Day with HydraLuxe® Technology Brand Contact Lenses.</li>

                    <li>Kwok LS, Kuznetsov VA, Ho A, Coroneo MT. Prevention of the Adverse Photic Effects of PeripheralLight-Focusing Using UV-Blocking Contact Lenses. Invest Opthalmol VIS Sci.
                        2003; 44: 1501-1507</li>
                </ol>

                 <p className="list"><span className="symbol">+</span> Using t-test on least-square means from the linear mixed model. Adjusted for multiplicity. JJV Data on File 2020.</p>

                 <p className="list"><span className="symbol">§</span> 30% is on average at 18cpd.</p>

                 <p className="list"><span className="symbol">#</span> JJV data on file 2016: Three-visit, non-dispensing study conducted in the US, n=35, visual acuity and contrast sensitivity results of current contact lens wearers testing
                ACUVUE® OASYS 1-Day Brand with HydraLuxe® Technology compared to ACUVUE® OASYS Brand Contact Lenses with HYDRACLEAR® Plus Technology 2-Week reusable contact lenses.</p>

                 <p className="list"><span className="symbol">¶</span> Among those with a preference.</p>

                 <p className="list"><span className="symbol">°</span> Vertical heterophoria possibly caused by prism dissociation due to the presence of induced optical prism is a relevant factor for practitioners to consider when fitting toric contact lenses for
                monocular astigmats or those requiring a mix of toric soft contact lens designs.(1)(2) Clinical studies have not been done to fully characterize the clinical effects of differences in base down prism among different contact lenses. (1) Jackson D, Bedell HE. Vertical heterophoria and susceptibility to visually induced motion sickness. Strabismus. 2012;20(1):17-23. (2) duToit R, Ramke J, Brian G. Tolerance to prism induced by readymade spectacles: setting and using a standard. Optom Vis Sci. 2007;84(11):1053-1059.</p>

                 <p className="list"><span className="symbol">**</span> Traditional prism and peri-ballast lens designs have a thicker area below the lower eyelid than ACUVUE® Brand Contact Lenses for ASTIGMATISM that can cause interaction and may
                cause unwanted rotation due to lateral movement of the lower lid during blinks</p>

                 <p className="list"><span className="symbol">±</span>  “Habitual daily disposable contact lenses” included 1-DAY ACUVUE® MOIST (37%), DAILIES® TOTAL1® (26.1%), DAILIES® AquaComfort PLUS® (23.9%), clariti® 1 day (6.5%), and
                MyDay (6.5%). ACUVUE® OASYS 1-Day performance measured against other habitual daily disposable contact lenses in aggregate. Results do not imply significance against any of
                the individual brands.</p>

                 <p className="list"><span className="symbol">†</span> JJV Data on File 2021. Post-hoc sub-group analysis of subjects responding ‘Yes’ to the question ‘Have you been able to use the study lenses for 8 or more hours with digital devices?’
                Sub-group n=80 of 92 subjects from the ‘Intent to Treat’ population of the dryness/comfort evaluation of ACUVUE® OASYS 1-Day Brand Contact Lenses vs. habitual daily disposable
                contact lenses in subjects with contact lens dryness symptoms.</p>

                 <p className="list"><span className="symbol"></span>The presence of PVP within the lens and on its surface results in a design with low pervaporation which helps to enable comfort during digital device use.</p>

                 <p className="list"><span className="symbol">^</span> Based on Visual Acuity using a standard eye-chart.</p>

                 <p className="list"><span className="symbol">¥</span>Based on high fit success rates, number of lenses required, and time to fit lenses during ACUVUE® Brand for ASTIGMATISM clinical studies.</p>

                 <p className="list"><span className="symbol">"</span>  Zylinder -2,25 dpt erhältlich im Leistungsbereich 0,00 dpt bis -6,00 dpt und Achsen 10°, 20°, 70°, 80°, 90°, 100°, 110°, 160°, 170°, 180°</p>

                 <p className="list"><span className="symbol">***</span> Verwendung des t-Tests auf den kleinsten Mittelwert aus dem linearen gemischten Modell. Bereinigt um Multiplizität. JJV Data on File, 2020.</p>

                 <p className="list"><span className="symbol">ƒ</span>Improved optical performance and vision compared to ACUVUE® OASYS Brand Contact Lenses with HYDRACLEAR® Plus Technology</p>

                 <p className="list"><span className="symbol">ff</span>n-market assessment among 142 Ophthalmologists in France (referenced as “contact lens fitters”) fitting 1,453 patients
                (n=804 Neophytes, n=186 Lapsed Wearers, n=463 Existing Contact Lens Wearers)</p>

                 <p><strong>Wichtige Sicherheitsinformationen:</strong> ACUVUE® Kontaktlinsen sind für eine Sehkorrektion indiziert. Wie bei jeder Kontaktlinse können Augenprobleme, einschließlich Hornhautgeschwüre, auftreten. Bei einigen Trägern kann es zu leichten Irritationen, Juckreiz oder Unbehagen kommen. Kontaktlinsen sollten nicht bei Augeninfektionen oder anderen Augenkrankheiten verwendet werden.<br />
                Vollständige Informationen, einschließlich Kontraindikationen und Vorsichtsmaßnahmen, entnehmen Sie bitte der Gebrauchsanweisung oder besuchen Sie unsere J&J-Website jnjvisioncare.de/at</p>

                 <p>* Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2 UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.<br />
                UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken.<br />
                UV-Transmission mit –1,00 Dioptrien Linse gemessen.</p>

                 <p>ACUVUE®, ACUVUE® OASYS 1-Day und HydraLuxe® sind eingetragene Marken von Johnson &amp; Johnson.<br />
                © 2022 Johnson &amp; Johnson Medical GmbH / Johnson &amp; Johnson Medical Products GmbH | PPXXX</p>

            </TextBox>
        </Slide>
    );
};
