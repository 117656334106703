import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';

import li from './assets/li.svg';

const Slide = styled.div`

    h2 {
        margin-top: 5px;
        font-size: 17.5px;
        line-height: 21px;
    }

    .box1 {
        padding-top: 15px;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
    }

    .box2 {
        padding-left: 31px;
        padding-top: 17px;
        
        ul {
            font-size: 17.5px;
            line-height: 32px;

            li {
                padding-left: 45px;
                background: url(${li});
                background-repeat: no-repeat;
                background-size: 31px 25px;
                background-position: 0px 5px;
            }
        }

        &.box2-4,
        &.box2-5 {
            padding-top: 10px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Unsere Themen</strong> im Überblick</h1>
            <h2>Nutzen Sie unsere Online-Seminare zur Unterstützung Ihrer Botschaft.</h2>
            <Image left={116} top={277} width={560} height={949} src={img1} />
            <Image left={980} top={325} width={791} height={862} src={img2} />

            <Image as="a" left={1558} top={-33} width={505} height={288} src={img3} href="https://jnj.sharepoint.com/:x:/r/teams/AbdurchsDACH249/Shared%20Documents/General/OnlineSeminareTool/WEBINAR%20SELECTION%20TOOL-GER-V1.0-build090221b.xlsx?d=w3de4630889974227932fa88284849190&csf=1&web=1&e=MOjmWZ" target="_blank" />

            <Container as="p" left={256} top={277} width={417} height={184} className="box1 flex flex-all-center text-center">
                <strong>Auswahl &amp; Upgrade</strong>
                <MoreBtn
                    className="x-right"
                    active={state.overlay1 === 'overlay1'}
                    onClick={() => toggleOverlay('overlay1')} />
            </Container>

            <Container as="p" left={116} top={430} width={417} height={184} className="box1 flex flex-all-center text-center">
                <strong>Kommunikation</strong>
                <MoreBtn
                    className="x-right"
                    active={state.overlay1 === 'overlay2'}
                    onClick={() => toggleOverlay('overlay2')} />
            </Container>

            <Container as="p" left={256} top={583} width={417} height={184} className="box1 flex flex-all-center text-center">
                <strong>Problemlösungen</strong>
                <MoreBtn
                    className="x-right"
                    active={state.overlay1 === 'overlay3'}
                    onClick={() => toggleOverlay('overlay3')} />
            </Container>

            <Container as="p" left={116} top={737} width={417} height={184} className="box1 flex flex-all-center text-center">
                <strong>Astigmatismus</strong>
                <MoreBtn
                    className="x-right"
                    active={state.overlay1 === 'overlay4'}
                    onClick={() => toggleOverlay('overlay4')} />
            </Container>

            <Container as="p" left={116} top={889} width={417} height={184} className="box1 flex flex-all-center text-center">
                <strong>Presbyopie</strong>
                <MoreBtn
                    className="x-right"
                    active={state.overlay1 === 'overlay5'}
                    onClick={() => toggleOverlay('overlay5')} />
            </Container>

            <Container as="p" left={256} top={1042} width={417} height={184} className="box1 flex flex-all-center text-center">
                <strong>Pflegemittel</strong>
                <MoreBtn
                    className="x-right"
                    active={state.overlay1 === 'overlay6'}
                    onClick={() => toggleOverlay('overlay6')} />
            </Container>

            <Arrow left={802} top={306} width={922} />


            <Container as="p" left={1193} top={242} className="color1">
                <strong>Astigmatismus</strong>
            </Container>
            <Container as="p" left={1535} top={242} className="color1">
                <strong>Neuanpassungen</strong>
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                <Container left={669} top={327} width={888} height={413} className="rounded box2">
                    <ul>
                        <li>Materialeigenschaften &amp; Designs</li>
                        <li>Silikon-Hydrogel vs. Hydrogel</li>
                        <li>Warum zuerst Tageslinsen (Erlebnis Monat)</li>
                        <li>Einfacherer (kürzerer) Anpassprozess</li>
                        <li>Bedeutung des Reibungskoeffizienten</li>
                    </ul>
                </Container>
            </Overlay>
            <Overlay active={state.overlay1 === 'overlay2'} className="with-bg">
                <Container left={532} top={479} width={1180} height={400} className="rounded box2">
                    <ul>
                        <li>Gesprächseinstieg</li>
                        <li>Aufwertung</li>
                        <li>Verwendung von Tageslinsen bei der Brillenauswahl</li>
                        <li>Kontaktlinsen-Mythen</li>
                        <li>Nachbetreuung von Kontaktlinsenträgern (Kommunikation)</li>
                    </ul>
                </Container>
            </Overlay>
            <Overlay active={state.overlay1 === 'overlay3'} className="with-bg">
                <Container left={670} top={630} width={850} height={450} className="rounded box2">
                    <ul>
                        <li>Methodik zur Problemlösung</li>
                        <li>Materialeigenschaften nutzen</li>
                        <li>Austauschhäufigkeit</li>
                        <li>Fallstudien / Beispiele</li>
                        <li>Nachbetreuung</li>
                        <li>Vermeidung von Prozess-Abbrechern</li>
                    </ul>
                </Container>
            </Overlay>
            <Overlay active={state.overlay1 === 'overlay4'} className="with-bg">
                <Container left={531} top={782} width={1040} height={450} className="rounded box2">
                    <ul>
                        <li>Bedeutung der Korrektion von Astigmatismus</li>
                        <li>Lidschlag-Stabilisations-Design</li>
                        <li>Wichtigkeit der prismenfreien optischen Zone</li>
                        <li>Anpassprozess (einschließlich HSA-Umrechnung)</li>
                        <li>Problemvermeidung</li>
                        <li>Verwendung des Astigmatismus-Rechners (DEMO)</li>
                    </ul>
                </Container>
            </Overlay>
            <Overlay active={state.overlay1 === 'overlay5'} className="with-bg">
                <Container left={531} top={707} width={1040} height={570} className="rounded box2 box2-4">
                    <ul>
                        <li>Bedeutung der Korrektion von Presbyopie</li>
                        <li>Pupillenoptimiertes Design</li>
                        <li>4 einfache Schritte für &gt;90% Erfolg</li>
                        <li>Anpassprozess</li>
                        <li>Silikon-Hydrogel vs. Hydrogel</li>
                        <li>Wie man eine Multifokal-Praxis aufbaut</li>
                        <li>Fallstudien</li>
                        <li>Verwendung des Multifokal-Rechners (DEMO)</li>
                    </ul>
                </Container>
            </Overlay>
            <Overlay active={state.overlay1 === 'overlay6'} className="with-bg">
                <Container left={674} top={896} width={840} height={381} className="rounded box2 box2-5">
                    <ul>
                        <li>Wichtige Grundlagen der KL-Pflege</li>
                        <li>ACUVUE® Revitalens</li>
                        <li>Warum Ein-Tageslinsen</li>
                        <li>Pflegemittel-Interaktion</li>
                        <li>Sicherheit des Trägers</li>
                    </ul>
                </Container>
            </Overlay>

        </Slide>
    );
};
