import React from 'react';
import styled from 'styled-components'
import { Container } from 'components/parts';
import bg from './assets/bg.jpg'

const Slide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: 110px;
        font-size: 43.5px;
        line-height: 51px;
        color: #fff;
        font-weight: 600;
        color: #fff;
    }

    .text1 {
        font-size: 9px;
        line-height: 12.5px;
        color: #fff;
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <div>
                    <h1 style={{ color: '#fff' }}>ACUVUE<sup>®</sup> OASYS MAX 1-Day<br />
<span className="color3">MULTIFOCAL</span></h1>
                </div>
                <Container left={595} top={1168} width={858} as="p" className="text1 text-center">
                Nur zu Illustrationszwecken
                </Container>
            </Slide>
        </>

    );
};

export default Folder2Slide1;