import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { useDrag, useDrop } from "react-dnd";

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'
import { usePreview } from 'react-dnd-preview'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';

const Slide = styled.div`
    h2 {
        font-size: 15px;
        line-height: 18px;
    }

    .column {
        position: absolute;
        left: 40px;
        height: max-content;       
        width: 160px;
        min-height: 100px;
       
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        &.column-1 {
            left: 42px;
            top: 180px;
        }

        &.column-2 {
            top: 360px;
        }

        &.column-3 {
            top: 500px;
        }
    }

    .row {
        position: absolute;
        left: 207px;
        top: 174px;
        width: 552px;
        height: 56px;
        min-height: auto;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        
    }

    .row-2 {
        top: 234px;
        height: 50px; 
    }

    .row-3 {
        top: 283px;
        height: 48px; 
    }

    .row-4 {
        height: 48px;
        top: 328px; 
    }

    .row-5 {
        top: 374px;
        height: 69px; 
    }

    .row-6 {
        top: 446px;
        height: 87px;
        padding: 10px 0; 
    }

    .dragable {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Chrome/Safari/Opera */
        -khtml-user-select: none;
        /* Konqueror */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;


        &.brand,
        &.ownbrand {
            border-radius: 7.5px;
            background-color: #CBCCCE;
            width: 107px;
            height: 32px;
            margin: 6px;
            display: flex;
            font-size: 14.6px;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            color: #fff;
        }

        &.ownbrand {
            background: #A8ABAE;
        }

        &.acuvue {
            background-size: contain;
            background-repeat: no-repeat;
        }

        &.acuvue1 {
            width: 74px;
            height: 42px;
            background-image: url(${img2});
        }

        &.acuvue2 {
            width: 74px;
            height: 45px;
            background-image: url(${img3});
        }

        &.acuvue3 {
            margin-top: 2px;
            margin-bottom: 2px;
            width: 113px;
            height: 37px;
            background-image: url(${img4});
        }

        &.acuvue4 {
            margin-top: 2px;
            margin-bottom: 2px;
            width: 115px;
            height: 45px;
            background-image: url(${img5});
        }
    }

    .img1 {
        position: absolute;
        left: 207px;
        top: 178px;
        width: 552px;
    }

    .line1,
    .line2 {
        position: absolute;
        display: block;
        width: 108px;
        height: 1px;
        left: 47px;
        top: 356px;
        background: #003087;
    }

    .line2 {
        top: 497px;
    }
`

const MyPreview = () => {
  const {display, itemType, item, style} = usePreview()
  if (!display) {
    return null
  }
  
  let classNames = (item.dragType === 'brand' || item.dragType === 'ownbrand') ? item.dragType : `acuvue ${item.dragType}`;

  let name = (item.dragType === 'brand' || item.dragType === 'ownbrand') ? item.name : ''

  return <div class={'dragable ' + classNames} style={style}>{name}</div>
}

const COLUMN_NAMES = {
    ACUVUE: 'ACUVUE',
    BRAND: 'BRAND',
    OWNBRAND: 'OWNBRAND',
    COMPETENCE1: 'COMPETENCE1',
    COMPETENCE2: 'COMPETENCE2',
    MIDDLE1: 'MIDDLE1',
    MIDDLE2: 'MIDDLE2',
    PRICE1: 'PRICE1',
    PRICE2: 'PRICE2',
}

const MovableItem = ({ name, dragType, index, currentColumnName, moveCardHandler, setItems }) => {
    const changeItemColumn = (currentItem, columnName) => {
        setItems((prevState) => {
            return prevState.map(e => {
                return {
                    ...e,
                    column: e.name === currentItem.name ? columnName : e.column,
                }
            })
        });
    }

    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'Our first type',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCardHandler(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { index, name, dragType, currentColumnName, type: 'Our first type'  },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();

            if (dropResult) {
                const { name } = dropResult;
                const { DO_IT, IN_PROGRESS, AWAITING_REVIEW, DONE } = COLUMN_NAMES;
                changeItemColumn(item, name);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.4 : 1;

    drag(drop(ref));

    let classNames = (dragType === 'brand' || dragType === 'ownbrand') ? dragType : `acuvue ${dragType}`;
    let showName = (dragType === 'brand' || dragType === 'ownbrand') ? name : ''

    return (
        <div ref={ref} className={'dragable' + ' ' + classNames} style={{ opacity }}>
           {showName}
        </div>
    )
}

const Column = ({ children, className, title }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'Our first type',
        drop: () => ({ name: title }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        // Override monitor.canDrop() function
        canDrop: (item) => {
            return true
        },
    });

    const getStyle = () => {
        if (isOver) {
            return {backgroundColor: 'rgba(255, 255, 255, 0.6)'}
        } else {
            return {};
        }
    };

    return (
        <div ref={drop} className={className} style={getStyle()}>
            {children}
        </div>
    )
}

const inititialState = {
    tasks: [
        { id: 1, name: 'Item 1', dragType: 'acuvue1', column: COLUMN_NAMES.ACUVUE },
        { id: 2, name: 'Item 2', dragType: 'acuvue2', column: COLUMN_NAMES.ACUVUE },
        { id: 3, name: 'Item 3', dragType: 'acuvue3', column: COLUMN_NAMES.ACUVUE },
        { id: 4, name: 'Item 4', dragType: 'acuvue4', column: COLUMN_NAMES.ACUVUE },
        { id: 5, name: 'Marke 1', dragType: 'brand', column: COLUMN_NAMES.BRAND },
        { id: 6, name: 'Marke 2', dragType: 'brand', column: COLUMN_NAMES.BRAND },
        { id: 7, name: 'Marke 3', dragType: 'brand', column: COLUMN_NAMES.BRAND },
        { id: 8, name: 'Eigenmarke 1', dragType: 'ownbrand', column: COLUMN_NAMES.OWNBRAND },
        { id: 9, name: 'Eigenmarke 2', dragType: 'ownbrand', column: COLUMN_NAMES.OWNBRAND },
        { id: 10, name: 'Eigenmarke 3', dragType: 'ownbrand', column: COLUMN_NAMES.OWNBRAND },
    ]
}

var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

const Index = ({...props}) => {
    const { state, updateState } = useSlideState({inititialState, active: props.active })

    const [items, setItems] = useState(state.tasks);

    /*
    const items = state.tasks;

    const setItems = (test) => {
        updateState('tasks', test);
    }
    */

   useEffect(() => {
        if(!props.active) {
            setItems(inititialState.tasks)
        }
    }, [props.active])

    console.log(items)

    const moveCardHandler = (dragIndex, hoverIndex) => {
        const dragItem = items[dragIndex];

        if (dragItem) {
            setItems((prevState => {
                const coppiedStateArray = [...prevState];

                // remove item by "hoverIndex" and put "dragItem" instead
                const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

                // remove item by "dragIndex" and put "prevItem" instead
                coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

                return coppiedStateArray;
            }));
        }
    };

    const returnItemsForColumn = (columnName) => {
        return items
            .filter((item) => item.column === columnName)
            .map((item, index) => (
                <MovableItem key={item.id}
                    name={item.name}
                    dragType={item.dragType}
                    currentColumnName={item.column}
                    setItems={setItems}
                    index={index}
                    moveCardHandler={moveCardHandler}
                />
            ))
    }

    const { ACUVUE, BRAND, OWNBRAND, COMPETENCE1, COMPETENCE2, MIDDLE1, MIDDLE2, PRICE1, PRICE2 } = COLUMN_NAMES;

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Wie könnte denn ein Portfolio konkret aussehen?</strong></h1>
            <h2 className="color1"><strong>(Tafel für Drag and Drop)</strong></h2>

            <Container left={1643} top={445} className="color1">
                <p><strong>Kompetenz</strong></p>
            </Container>

            <Container left={1643} top={872} className="color1">
                <p><strong>Preis</strong></p>
            </Container>

            <span className="line1" />
            <span className="line2" />


            <Arrow left={1590} top={361} width={212} />
            <Arrow left={1590} top={586} width={159} />
            <Arrow left={1576} top={742} width={300} offset={-14} />
            <img src={img1} className="img1" />

            <Column title={ACUVUE} className='column column-1'>
                {returnItemsForColumn(ACUVUE)}
            </Column>
            <Column title={BRAND} className='column column-2'>
                {returnItemsForColumn(BRAND)}
            </Column>
            <Column title={OWNBRAND} className='column column-3'>
                {returnItemsForColumn(OWNBRAND)}
            </Column>
            <Column title={COMPETENCE1} className='column row row-1'>
                {returnItemsForColumn(COMPETENCE1)}
            </Column>
            <Column title={COMPETENCE2} className='column row row-2'>
                {returnItemsForColumn(COMPETENCE2)}
            </Column>
            <Column title={MIDDLE1} className='column row row-3'>
                {returnItemsForColumn(MIDDLE1)}
            </Column>
            <Column title={MIDDLE2} className='column row row-4'>
                {returnItemsForColumn(MIDDLE2)}
            </Column>
            <Column title={PRICE1} className='column row row-5'>
                {returnItemsForColumn(PRICE1)}
            </Column>
            <Column title={PRICE2} className='column row row-6'>
                {returnItemsForColumn(PRICE2)}
            </Column>
            {supportsTouch && 
                <MyPreview />
            }
        </Slide>
    );
};

export default Index;