import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState } from "components/hooks";

import {
  Arrow,
  Container,
  Image,
  MoreBtn,
  Overlay,
  TextBox,
} from "components/parts";

const Slide = styled.div`
  h1 {
    color: #051f4a;
  }

  h2 {
    margin: 20px 0 10px 0;
    font-size: 20px;
    line-height: 24px;
    color: #0a7cc1;
  }

  .list {
    display: flex;

    .symbol {
      flex: 0 0 18px;
    }
  }
`;
const inititialState = {
  overlay1: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide className="centered" step={props.step}>
      <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
      <TextBox>
        <p>
          <strong>Wichtige Sicherheitsinformationen:</strong> Kontaktlinsen der
          Marke ACUVUE® dienen zur Korrektion des Sehvermögens. Wie bei allen
          Kontaktlinsen können Probleme mit den Augen, einschließlich
          Hornhautgeschwüre, auftreten. Bei manchen Kontaktlinsenträgern können
          leichte Reizungen, Jucken oder Unbehagen auftreten. Kontaktlinsen
          sollten nicht bei Augeninfektionen oder anderen Augenerkrankungen oder
          bei systemischen Erkrankungen, die das Auge beeinträchtigen können,
          verwendet werden. Ausführliche Informationen zum Produkt
          einschließlich Kontraindikationen, Vorsichtsmaßnahmen und
          Nebenwirkungen, entnehmen Sie bitte der Gebrauchsanweisung auf der
          Johnson & Johnson Website www.e-ifu.com. Für weitere Informationen
          besuchen Sie unsere Johnson & Johnson Vision Website
          www.jnjvisioncare.de / www.jnjvisioncare.at.
        </p>

        <p>
          Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2
          UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor
          schädlicher UV-Strahlung zu schützen. UV absorbierende Kontaktlinsen
          sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen, da
          sie das Auge und seine Umgebung nicht vollständig abdecken.
          UV-Transmission mit –1,00 Dioptrien Linse gemessen.
        </p>

        <p>
          1. Data on file 2024, ACUVUE® Brand Contact Lenses for ASTIGMATISM
          overall fitting success, orientation position, rotational stability
          and vision performance.
          <br />
          2. Young G, Sully A, Hunt C. Prevalence of astigmatism in relation to
          soft contact lens fitting. Eye Contact Lens. 2011;37(1):20-25.
          <br />
          3. Cox SM et al, Efficacy of toric contact lenses in fitting and
          patient-reported outcomes in contact lens wearers. Eye Contact Lens.
          2018;44 Suppl 1:S296-S299.
          <br />
          4. Sulley A, Young G, Hunt C. Factors in the success of new contact
          lens wearers. Cont Lens Anterior Eye 2017;40(1):15-24.
          <br />
          5.JJV Data on File 2022: Master Brand Claims on Clinical Performance
          and Overall Material Properties for ACUVUE Brand Soft Contact Lenses.
          <br />
          6. Zikos GA, Kang SS, Ciuffreda KJ, et al. Rotational stability of
          toric soft contact lenses during natural viewing conditions. Optom Vis
          Sci. 2007 Nov;84(11):1039-1045.
          <br />
          7. JJV data on file 2024: ACUVUE® Brand - EYE-INSPIRED™ INNOVATIONS
          <br />
          8. Sterner O, Karageorgaki C, Zürcher M, et al. Reducing Friction in
          the Eye: A Comparative Study of Lubrication by Surface-Anchored
          Synthetic and Natural Ocular Mucin Analogues. ACS Appl. Mater.
          Interfaces. 2017;9(23):20150–20160. doi: 10.1021/acsami.6b16425.
          <br />
          9. JJV Data on File 2018. Similarities between Mucin and Poly(N-Vinyl
          Pyrrolidone) (PVP).
          <br />
          10. Young G, et al. Prevalence of astigmatism in relation to soft
          contact lens fitting. Eye & Contact Lens. 2011;37(1):20-25.
          <br />
          11. Sulley A, Hawke R, Osbron Lorenz K, et al. Resultant vertical
          prism in toric soft contact lenses. Cont Lens Anterior Eye.
          2015;38:253–257.
          <br />
          12. JJV Data on File 2016. Design Enhancements and Resultant Benefits
          of ACUVUE® OASYS Brand Contact Lenses 1-Day with HydraLuxe® Technology
          for ASTIGMATISM.
        </p>

        <p>
          Die hier verwendeten Marken Dritter sind Eigentum ihrer jeweiligen
          Inhaber.
        </p>

        <p>
          ACUVUE®, 1-DAY ACUVUE® MOIST for ASTIGMATISM, ACUVUE® OASYS for
          ASTIGMATISM, ACUVUE® OASYS 1-Day for ASTIGMATISM, ACUVUE® VITA® for
          ASTIGMATISM, HYDRACLEAR®, HydraLuxe®, HydraMaxTM und LACREON® sind
          eingetragene Marken von Johnson & Johnson. © 2024 Johnson & Johnson
          Medical GmbH / Medical Products GmbH | 2024PP19627
        </p>
      </TextBox>
    </Slide>
  );
};
