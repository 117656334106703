import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState } from "components/hooks";

import {
  Arrow,
  Container,
  Image,
  MoreBtn,
  Overlay,
  PlayBtn,
} from "components/parts";

import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.jpg";
import img3 from "./assets/img3.jpg";

const Slide = styled.div`
  > .box2 {
    padding-top: 35px;

    .number {
      position: absolute;
      display: block;
      left: 12px;
      top: -51px;
      font-size: 64px;
      line-height: 80px;
      font-style: italic;
      color: #ffb900;
    }

    &.box2-1 .number {
      left: 2px;
    }
  }

  .overlay1::before {
    background: #051f4a !important;
  }

  .overlay1 {
    .number {
      font-size: 64px;
      line-height: 80px;
      font-style: italic;
      color: #ffb900;
    }

    .play {
      width: 76px;
      height: 76px;

      .stroke {
        fill: #fff;
      }
    }
  }

  .overlay1-1 {
    .box1 {
      border-radius: 15px;
      border-color: #051f4a;
    }

    .box2 {
      font-size: 15px;
      line-height: 17px;
    }

    .img1 {
      position: absolute;
      width: 860px;
      left: 82px;
      top: 240px;
    }

    .info {
      font-weight: 600;
      font-size: 15px;
      line-height: 17px;
    }
  }

  .overlay1-2 {
    .box1 {
      border-radius: 15px;
      border-color: #051f4a;
      background: #fff;
      background-image: url(${img2});
      background-size: contain;
      background-position: center bottom;
      background-repeat: no-repeat;
    }

    .info {
      font-weight: 600;
      font-size: 15px;
      line-height: 17px;
    }

    .box2 {
      color: #fff;
      text-shadow: 0px 2px 7px rgba(0, 0, 0, 0.8);
    }
  }

  .overlay1-3 {
    .box1 {
      border-radius: 15px;
      border-color: #051f4a;
      background: #fff;
    }

    .info {
      font-weight: 600;
      font-size: 15px;
      line-height: 17px;
    }

    > .plus {
      position: absolute;
      color: #ffb900;
      font-style: italic;
      font-weight: 600;
      font-size: 64px;
      line-height: 80px;

      &.plus1 {
        left: 355px;
        top: 218px;
      }

      &.plus2 {
        left: 645px;
        top: 218px;
      }
    }
  }

  .link1 {
    position: absolute;
    z-index: 110;
    display: block;
    left: 390px;
    top: 320px;
    width: 250px;
    height: 170px;
  }
`;
const inititialState = {
  overlay1: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide className="centered" step={props.step}>
      <h1 className="color2">
        So gewinnen Sie die{" "}
        <strong>
          presbyopen Kunden als
          <br />
          duale Verwender
        </strong>{" "}
        für sich
      </h1>

      <Container
        left={390}
        top={372}
        width={1270}
        height={259}
        className="bg1 box1 color2 flex-all-center text-center"
      >
        <Arrow width={1065} className="container-bottom" bgColor={"#fff4dd"} />
        <p>
          Erklären Sie Ihren Kunden, wie eine Kombination
          <br />
          aus <strong>Brille und Kontaktlinse (duale Verwendung)</strong>
          <br />
          ihre Bedürfnisse noch besser erfüllen kann
        </p>
      </Container>

      <Container
        left={113}
        top={778}
        width={580}
        height={360}
        className="bg2 box2 box2-1 color2 text-center fadeInFromTop"
      >
        <span className="number color3">1</span>
        <p>
          <strong>
            Schaffen Sie Aufmerksamkeit für das Thema multifokale Kontaktlinsen
          </strong>
        </p>
      </Container>

      <Container
        left={732}
        top={778}
        width={580}
        height={360}
        className="bg2 box2 box2-2 color2 text-center fadeInFromTop animate2"
      >
        <span className="number color3">2</span>
        <p>
          <strong>
            Vermitteln Sie die
            <br />
            Vorteile von multifokalen Kontaktlinsen
          </strong>
        </p>
      </Container>

      <Container
        left={1352}
        top={778}
        width={580}
        height={360}
        className="bg2 box2 box2-3 color2 text-center fadeInFromTop animate3"
      >
        <span className="number color3">3</span>
        <p>
          <strong>
            Begleiten Sie Ihre
            <br />
            Kunden bei den ersten
            <br />
            Schritten mit multifokalen Kontaktlinsen
          </strong>
        </p>
      </Container>

      <MoreBtn
        className="fadeInFromTop"
        left={371}
        top={1098}
        active={state.overlay1 === "overlay1"}
        onClick={() => toggleOverlay("overlay1")}
      />

      <MoreBtn
        className="fadeInFromTop animate2"
        left={990}
        top={1098}
        active={state.overlay1 === "overlay2"}
        onClick={() => toggleOverlay("overlay2")}
      />

      <MoreBtn
        className="fadeInFromTop animate3"
        left={1610}
        top={1098}
        active={state.overlay1 === "overlay3"}
        onClick={() => toggleOverlay("overlay3")}
      />

      <Overlay
        active={state.overlay1 === "overlay1"}
        className="overlay1 overlay1-1 with-bg"
      >
        <Container
          left={113}
          top={266}
          width={1819}
          height={873}
          className="box1 border rounded"
        ></Container>

        <img src={img1} className="img1" />

        <Container
          as="p"
          left={154}
          top={270}
          width={100}
          className="number color3"
        >
          1
        </Container>

        <Container as="p" left={250} top={304} width={1850} className="color2">
          Finden Sie den{" "}
          <strong>optimalen Moment bei der Kundenberatung</strong> um auf
          multifokale
          <br />
          Kontaktlinsen und ein mögliches <strong>Probetragen</strong>{" "}
          aufmerksam zu machen.
        </Container>

        <Container
          as="p"
          left={250}
          top={415}
          width={1850}
          className=" box2 color2"
        >
          <strong>
            Nutzen Sie unsere Kampagnen-Werbemittel um Aufmerksamkeit für
            multifokale Kontaktlinsen zu schaffen
          </strong>
        </Container>

        <Container
          as="p"
          left={540}
          top={517}
          width={455}
          className="info color2"
        >
          Sichtbarkeit im Aussenbereich:
          <br />
          Poster, Fensterkleber
        </Container>

        <Container
          as="p"
          left={1059}
          top={517}
          width={600}
          className="info color2"
        >
          Sichtbarkeit im Innenbereich: Brillentablett Aufsteller, Aktionsflyer,
          Instore Video
        </Container>

        <Container
          as="p"
          left={1280}
          top={713}
          width={518}
          className="info color2"
        >
          Einladung: Mailing + Newsletter
        </Container>

        <Container
          as="p"
          left={1311}
          top={1037}
          width={622}
          className="info color2"
        >
          Neukundengewinnung: Social Media
        </Container>
      </Overlay>

      <Overlay
        active={state.overlay1 === "overlay2"}
        className="overlay1 overlay1-2 with-bg"
      >
        <Container
          left={113}
          top={266}
          width={1819}
          height={873}
          className="box1"
        />

        <Container
          as="p"
          left={144}
          top={280}
          width={100}
          className="number color3"
        >
          2
        </Container>

        <Container as="p" left={250} top={304} width={1850} className="color2">
          Um die Vorteile von multifokalen Kontaktlinsen gut aufzeigen zu
          können,
          <br />
          sprechen Sie am besten konkret die individuellen Wünsche und
          Bedürfnisse des
          <br />
          einzelnen Kunden an.
        </Container>

        <Container
          as="p"
          left={773}
          top={474}
          width={600}
          className="info color2"
        >
          Zeigen Sie auf, wann eine Brille Sinn macht und bei welchen
          Gelegenheiten Kontaktlinsen das Leben erleichtern.
        </Container>

        <Container
          as="p"
          left={896}
          top={979}
          width={430}
          className="info color2"
        >
          Die Tischvorlage dient zur visuellen Unterstützung
          <br />
          ihres Gespräches
        </Container>

        <Container
          as="p"
          left={1382}
          top={979}
          width={560}
          className="info color2"
        >
          Nutzen Sie den Aktionsflyer
          <br />
          (bis zu 30€ Cashback) um zum
          <br />
          Probetragen, Erstkauf und natürlich
          <br />
          auch Nachkauf zu animieren
        </Container>

        <Container
          as="p"
          left={125}
          top={863}
          width={582}
          className="box2 text-center"
        >
          <strong>
            Wichtig ist
            <br />
            Ihre persönliche
            <br />
            Beratung
          </strong>
        </Container>
      </Overlay>

      <Overlay
        active={state.overlay1 === "overlay3"}
        className="overlay1 overlay1-3 with-bg"
      >
        <Container
          left={113}
          top={266}
          width={1819}
          height={873}
          className="box1"
        />

        <Container
          as="p"
          left={144}
          top={280}
          width={100}
          className="number color3"
        >
          3
        </Container>

        <Image left={290} top={616} width={1572} height={432} src={img3} />

        <Container as="p" left={250} top={304} width={1850} className="color2">
          Zeigen Sie Ihrem Kunden wie multifokale Kontaktlinsen funktionieren
          und <br />
          begleiten Sie ihn bei den ersten Schritten (z. B. mit Tipps zur
          Handhabung)
        </Container>

        <Container
          as="p"
          left={58}
          top={466}
          width={760}
          className="info color2 text-center"
        >
          Eine gute Unterstützung
          <br />
          ist unsere Endverbraucher-
          <br />
          Broschüre
        </Container>

        <Container
          as="p"
          left={713}
          top={466}
          width={650}
          className="info color2 text-center"
        >
          Weisen Sie auf die
          <br />
          Handhabungsvideos auf der
          <br />
          ACUVUE®-Webseite hin
        </Container>

        <Container
          as="p"
          left={1325}
          top={466}
          width={650}
          className="info color2 text-center"
        >
          Machen Sie auf die Aktion
          <br />
          aufmerksam: Ihre Kunden können
          <br />
          bis zu 30€ Cashback erhalten
        </Container>

        <span className="plus plus1">+</span>
        <span className="plus plus2">+</span>

        <PlayBtn left={994} top={756} className="play" />

        <a
          href="https://www.acuvue.de/kontaktlinsen-pflege/kontaktlinsen-aufsetzen"
          target="_blank"
          className="link1"
        ></a>
      </Overlay>
    </Slide>
  );
};
