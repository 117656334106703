import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';

import img3 from './assets/img3.svg';
import img4 from './assets/img4.svg';
import img5 from './assets/img5.svg';

const Slide = styled.div`
    
    .box1 {
        overflow: hidden;
    
        &.box1-1 {
            > span {
                bottom: 0;
                border-top-left-radius: 11px;
                border-top-right-radius: 11px;

                ${({ preview }) => !preview && css`
                    transform: translateY(100%);
                `}
            }
        }

        &.box1-2 {
            > span {
                top: 0;
                border-bottom-left-radius: 11px;
                border-bottom-right-radius: 11px;

                ${({ preview }) => !preview && css`
                    transform: translateY(-100%);
                `}

                
            }
        }

        > span {
            display: block;
            position: absolute;
            width: 22px;
            background: black;
            transition: all 0.3s ease-out;

            > span {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 22px;
                transition: all 0.3s ease-out;
            }

            &.bar2 {
                left: 316px;
                height: 127px;

                > span {
                    height: 49px;
                    height: 10px;       
                }
            }

            &.bar3 {
                left: 338px;
                height: 60px;
            }

            &.bar5 {
                left: 413px;
                height: 49px;
            }

            &.bar6 {
                left: 435px;
                height: 13px;
            }

            &.bar8 {
                left: 511px;
                height: 49px;
            }

            &.bar9 {
                left: 533px;
                height: 28px;
            }

            &.bar11 {
                left: 609px;
                height: 49px;
            }

            &.bar12 {
                left: 631px;
                height: 43px;
            }

            &.bar14 {
                left: 708px;
                height: 127px;

                > span {
                    height: 49px;
                    height: 10px;
                }
            }

            &.bar15 {
                left: 730px;
                height: 78px;
            }

            /* */
            
            &.bar1 {
                left: 294px;
                height: 80px;
            }

            &.bar4 {
                left: 391px;
                height: 55px;
            }

            &.bar7 {
                left: 489px;
                height: 36px;
            }

            &.bar10 {
                left: 587px;
                height: 18px;
            }

            &.bar13 {
                left: 686px;
                height: 52px;
            }

            &.bar1,
            &.bar4,
            &.bar7,
            &.bar10,
            &.bar13 {
                background: #00C0EB;
            }

            &.bar2,
            &.bar14 {
                background: #6CACE4;
            }

            &.bar2 span,
            &.bar5,
            &.bar8,
            &.bar11,
            &.bar14 span {
                background: #636363;
            }

            &.bar3,
            &.bar6,
            &.bar9,
            &.bar12,
            &.bar15 {
                background: #003087;
            }

            /* */

            &.bar1 {
                ${({ active, step }) => active && step >= 1 && css`
                   transform: translateY(calc(-100% + 43px));
                `}
            }

            &.bar2 {
                ${({ active, step }) => active && step >= 1 && css`
                   transform: translateY(calc(100% - 88px));
                `}

                span {
                    ${({ active, step }) => active && step >= 3 && css`
                        height: 49px;
                    `}
                }
            }

            &.bar3 {
                ${({ active, step }) => active && step >= 1 && css`
                    transform: translateY(calc(100% - 39px));
                `}
            }

            &.bar13 {
                ${({ active, step }) => active && step >= 2 && css`
                   transform: translateY(calc(-100% + 52px));
                `}
            }

            &.bar14 {
                ${({ active, step }) => active && step >= 2 && css`
                   transform: translateY(calc(100% - 88px));
                `}

                span {
                    ${({ active, step }) => active && step >= 3 && css`
                        height: 49px;
                    `}
                }
            }

            &.bar15 {
                ${({ active, step }) => active && step >= 2 && css`
                    transform: translateY(calc(100% - 39px));
                `}
            }

            &.bar1,
            &.bar2,
            &.bar3 {
                transition-delay: 0.3s;

                ${({ active, step }) => active && step >= 2 && css`
                    transition-delay: 0s;
                `}
            }

            &.bar1,
            &.bar2,
            &.bar3,
            &.bar4,
            &.bar5,
            &.bar6,
            &.bar7,
            &.bar8,
            &.bar9,
            &.bar10,
            &.bar11,
            &.bar12,
            &.bar13,
            &.bar14,
            &.bar15 {
                ${({ active, step }) => active && step >= 3 && css`
                    transform: none;
                `}
            }

        }

    }

    .img1 {
        transform: translateY(0);
    }

    .box2 {
        line-height: 18.5px;

        &.box2-1 {
            padding-left: 40px;
        }

        &.box2-2 {
            padding-left: 30px;
        }

        &.box2-3 {
            padding-left: 34px;
        }
    }

    .box3 {
        font-size: 14px;
        line-height: 22px;
    }

    .box4 {
        font-size: 10px;
        line-height: 12px;
    }

`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} preview={props.preview} active={props.active}>
            <h1>Warum sich das <strong>Geschäft mit Kontaktlinsen</strong> lohnt? </h1>

            <Container left={324} top={239} width={1400} height={627} className="bg1 flex-all-center text-center box3 color1">
                <Arrow width={1102} className="container-bottom" bgColor={'#CFF2FB'} />
            </Container>

            <Container left={0} top={278} width={2028} height={276} className="box1 box1-1">
                <span className="bar2"><span /></span>
                <span className="bar3"></span>

                <span className="bar5"></span>
                <span className="bar6"></span>

                <span className="bar8"></span>
                <span className="bar9"></span>

                <span className="bar11"></span>
                <span className="bar12"></span>

                <span className="bar14"><span /></span>
                <span className="bar15"></span>
            </Container>

            <Container left={0} top={555} width={2028} height={200} className="box1 box1-2">
                <span className="bar1"></span>
                <span className="bar4"></span>
                <span className="bar7"></span>
                <span className="bar10"></span>
                <span className="bar13"></span>
            </Container>

            <Image src={img1} left={528} top={282} width={1030} height={278} contain className="img1" />

            <Image src={img2} left={457} top={787} width={885} height={32} contain />

            <Container as="p" left={507} top={786} width={300} className="color1 small">
                Kosten
            </Container>

            <Container as="p" left={683} top={786} width={300} className="color1 small">
                Umsatz Brille
            </Container>

            <Container as="p" left={683} top={786} width={300} className="color1 small">
                Umsatz Brille
            </Container>

            <Container as="p" left={960} top={786} width={400} className="color1 small">
                Umsatz Kontaktlinse
            </Container>

            <Container as="p" left={1360} top={786} width={300} className="color1 small">
                Netto Erlöse
            </Container>

            <Container as="p" left={394} top={275} width={150} className="color1 small">
                <strong>Euro €</strong>
            </Container>

            <Container as="p" left={1582} top={536} width={150} className="color1 small">
                <strong>Zeit</strong>
            </Container>

            <Container as="p" left={1533} top={575} width={150} className="color1 box3">
                ca. 3 Jahre
            </Container>

            <Container as="p" left={1740} top={845} width={150} className="box4">
                8)
            </Container>


            <Container left={139} top={960} width={586} height={220} className="box2 box2-1 rounded rounded-full flex-all-center text-center color1 small">
                <p>Personen, die sowohl<br />
                    Brille als auch Kontakt-<br />
                    linsen tragen, sind bis<br />
                    zu 80% profitabler
                </p>
            </Container>

            <Container left={742} top={960} width={586} height={220} className="box2 box2-2 rounded rounded-full flex-all-center text-center color1 small">
                <p>Kontaktlinsen-<br />
                    plus Brillen-Träger<br />
                    sind loyaler </p>
            </Container>

            <Container left={1345} top={960} width={586} height={220} className="box2 box2-3 rounded rounded-full flex-all-center text-center color1 small">
                <p>Hohe Weiterempfehlungs-<br />
                    rate von langfristig<br />
                    zufriedenen Kontakt-<br />
                    linsenträgern</p>
            </Container>

            <Image src={img3} left={170} top={1026} width={100} height={88} contain />
            <Image src={img4} left={805} top={1030} width={99} height={88} contain />
            <Image src={img5} left={1370} top={1015} width={122} height={112} contain />

        </Slide>
    );
};
