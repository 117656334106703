import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

const Slide = styled.div`
    .box1 {
        border-radius: 158.5px;
        color: #fff;
    }

    .box2 {
        border-radius: 15px;
        color: #fff;

        span {
            font-size: 16px;
            line-height: 23px;
        }
    }

    .box3 { 
        padding-left: 65px;

        .number {
            position: absolute;
            left: -21px;
            top: -10px;
            width: 73.5px;
            height: 73.5px;
            border-radius: 50%;
            color: #fff;
            background: #003087;;
            font-size: 62px;
            line-height: 76.5px;
            text-align: center;
            font-style: italic;
            padding-right: 2px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Die <strong>Themen unserer Online-Seminare im Überblick</strong></h1>

            <Container left={142} top={304} width={500} height={113} className="box3 rounded rounded-full flex-all-center">
                    <span className="number">1</span>
                    <p className="color1"><strong>Praxis Einblicke</strong></p>
            </Container>


            <Container left={221} top={451} width={317} height={317} className="box1 bg2 flex-all-center text-center">
                <p><strong>Jeden<br />
2. Mittwoch<br />
19:00 Uhr</strong></p>
            </Container>

            <Container left={557} top={451} width={317} height={317} className="box1 bg2 flex-all-center text-center">
                <p><strong>Monats-<br />
thema</strong></p>
            </Container>

            <Container left={893} top={451} width={317} height={317} className="box1 bg2 flex-all-center text-center">
                <p><strong>Für alle<br />
Ihren<br />
praktischen<br />
Fragen</strong></p>
            </Container>

            <Container left={1228} top={451} width={317} height={317} className="box1 bg2 flex-all-center text-center">
                <p><strong>Panel<br />
von sehr<br />
erfahrenen<br />
Anpassern</strong></p>
            </Container>

            <Container left={1564} top={451} width={317} height={317} className="box1 bg2 flex-all-center text-center">
                <p><strong>Aus der<br />
Praxis<br />
für die<br />
Praxis</strong></p>
            </Container>

            

            <div className="container-animate fadeInFromTop">
            <Arrow left={1032} top={-3} width={1652} className="rotate90" />
            <Container left={142} top={851} width={500} height={113} className="box3 rounded rounded-full flex-all-center">
                    <span className="number">2</span>
                    <p className="color1"><strong>Sonderthemen</strong></p>
            </Container>

            <Container left={221} top={996} width={518} height={144} className="box2 bg2 flex-all-center text-center">
                <p><strong>Kinder &amp; Kontaktlinsen</strong></p>
            </Container>

            <Container left={794} top={996} width={518} height={144} className="box2 bg2 flex-all-center text-center">
                <p><strong>Internet<br /> 
<span>Chancen &amp; Herausforderungen</span>
</strong></p>
            </Container>

            <Container left={1368} top={996} width={518} height={144} className="box2 bg2 flex-all-center text-center">
                <p><strong>Linsen-Handhabung<br /> 
                <span>Tipps für einen idealen Einstieg</span></strong></p>
            </Container>
            </div>

        </Slide>
    );
};
