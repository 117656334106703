import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.svg';
import img5 from './assets/img5.jpg';


const Slide = styled.div`
    .box1 {
       padding-left: 15px;
    }

    .box1 .number {
        position: absolute;
        display: block;
        left: 2px;
        top: -86px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .box1-2 .number {
        left: 16px;
    } 

    .box1-3 .number {
        left: 16px;
    } 


    .overlay1 {
        .box3 {
            color: #007DC5;
            font-size: 30px;
        }

        .box4 {
            .number {
                position: absolute;
                left: -66px;
                width: 46px;
                height: 46px;
                border-radius: 99999px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "AvenirNext";
                font-weight: 700;
                background: #007DC5;
                color: #fff;
                font-size: 21px;

            }
        }

        .box5 {
            font-size: 10px;
        }
    }

    .overlay2 {
        .box2 {
            background-color: #fff;
            background-image: url(${img5});
            background-size: 744px 409px;
            background-repeat: no-repeat;
            background-position: 28px 34px;
        }

        .box3 {
            font-size: 30px;
            line-height: 32px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (


        <Slide className="centered">
            <h1>Wie nutzen Sie die Kampagne am <strong>erfolgreichsten?</strong></h1>

            <div className="container-animate fadeInFromLeft">

                <Container left={246} top={420} width={1540} height={150} className="bg1 box1 box1-1 color1 flex-all-center ">
                    <span className="number number1">1.</span>
                    <p><strong>Informieren & trainieren Sie das Team zur Kampagne</strong><br />
                        (Testträger Mechanismus & Einsatz des Aktionsflyers)</p>

                    <MoreBtn
                        className="x-right y-center"
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'}
                    />



                </Container>



            </div>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg bg3">
                <Container left={191} top={239} width={1596} height={941} className="box2 bg2 color1">

                </Container>

                <Container left={247} top={277} width={1300} height={96} className="color1 box3">
                Der <strong>Testträger-Mechanismus</strong> in Kürze
                </Container>

                <Image left={250} top={423} width={351} height={704} src={img2} />
                <Image left={1015} top={407} width={665} height={152} src={img3} />

                <Image left={998} top={996} width={131} height={165} src={img4} />

                <Container left={814} top={419} width={890} height={96} className="color1 box4 flex-all-center" as="p">
                    <span className="number number1">1</span>
                    Kauf von 2x
                </Container>

                <Container left={1311} top={419} width={890} height={96} className="color1 box4 flex-all-center" as="p">
                    oder
                </Container>

                <Container left={814} top={572} width={890} height={96} className="color1 box4 flex-all-center" as="p">
                    <span className="number number1">2</span>
                    QR-Code scannen, anmelden und<br />
                    Kassenbeleg + Produktfoto hochladen
                </Container>

                <Container left={814} top={724} width={890} height={96} className="color1 box4 flex-all-center" as="p">
                    <span className="number number1">3</span>
                    30 Tage testen
                </Container>

                <Container left={814} top={877} width={890} height={96} className="color1 box4 flex-all-center">
                    <span className="number number1">4</span>
                    <p>Fragen zum <strong>MAX</strong> Seherlebnis beantworten +
                    Rückerstattungs-Code erhalten</p>
                </Container>

                <Container left={814} top={1030} width={890} height={96} className="color1 box4 flex-all-center" as="p">
                    <span className="number number1">5</span>
                    Geld zurück
                </Container>

                <Container left={1442} top={1132} width={376} className="label text-center color1 box5" as="span">
                Nur zu Illustrationszwecken
                </Container>


                <Container left={246} top={420} width={1540} height={150} className="flex-all-center ">
                    <MoreBtn
                        className="x-right y-center"
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'}
                    />
                </Container>


            </Overlay>



            <Container left={246} top={728} width={1334} height={110} className="bg1 box1 box1-2 color1 flex-all-center fadeInFromLeft animate2">
                <span className="number number1">2.</span>
                <p><strong>Platzieren Sie den Aktionsflyer gut sichtbar.</strong></p>
            </Container>

            <Image left={1343} top={584} width={395} height={396} src={img1} className="fadeIn animate2" />

            <div className="container-animate fadeInFromLeft animate3">

                <Container left={246} top={1015} width={1540} height={150} className="bg1 box1 box1-3 color1 flex-all-center ">
                    <span className="number number1">3.</span>
                    <p><strong>Nutzen Sie die weiteren Kommunikations-Maßnahmen,</strong><br />
                        um auf die Testträger-Kampagne aufmerksam zu machen.</p>


                    <MoreBtn
                        className="x-right y-center"
                        onClick={() => toggleOverlay('overlay2')}
                        active={state.overlay1 === 'overlay2'}
                    />
                </Container>


                <Overlay active={state.overlay1 === 'overlay2'} className="overlay2 with-bg bg3">
                    <Container left={191} top={239} width={1596} height={941} className="box2 bg2 color1">

                    </Container>


                    <Container left={256} top={786} width={402} className="box3 color1 text-center" as="p">
                        <strong>Point of Sale:</strong>
                    </Container>

                    <Container left={256} top={858} width={402} className="color1 box4 text-center" as="p">
                        Befestigen Sie<br />
                        den Fensterkleber<br />
                        und hängen/stellen<br />
                        Sie das Poster<br />
                        gut sichtbar auf
                    </Container>

                    <Container left={787} top={360} width={433} className="box3 color1 text-center" as="p">
                        <strong>Social Media:</strong>
                    </Container>

                    <Container left={787} top={433} width={433} className="color1 text-center" as="p">
                        Spielen Sie<br />
                        Social Media Post<br />
                        und Stories aus<br />
                        und benutzen Sie<br />
                        Online-Banner

                    </Container>


                    <Container left={1336} top={786} width={374} className="box3 color1 text-center" as="p">
                        <strong>Newsletter:</strong>
                    </Container>




                    <Container left={1336} top={858} width={374} className="color1 text-center" as="p">
                        Kontaktieren<br />
                        Sie Bestands- und
                        Neukunden

                    </Container>



                </Overlay>

            </div>




        </Slide>

    );
};
