import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'
import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'
import Slide9 from './slides/slide9'
import Slide10 from './slides/slide10'
import Slide11 from './slides/slide11'
import Slide12 from './slides/slide12'
import Slide13 from './slides/slide13'
import Slide14 from './slides/slide14'
import Slide15 from './slides/slide15'
import Slide16 from './slides/slide16'
import Slide17 from './slides/slide17'
import Slide18 from './slides/slide18'
import Slide21 from './slides/slide21'
import Slide23 from './slides/slide23'
import Slide24 from './slides/slide24'
import Slide25 from './slides/slide25'
import Slide26 from './slides/slide26'
import Slide27 from './slides/slide27'
import Slide28 from './slides/slide28'
import Slide29 from './slides/slide29'
import Slide30 from './slides/slide30'

const Theme = {
    color1: '#df9933',
    bg1: '#FDE1C2',
    bg2: '#FEF0E0',
}

export const Slides = {
    'Folder5Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder5Slide2': { name: Slide2, theme: Theme, footer: 'transparent' },
    'Folder5Slide3': { name: Slide3, theme: Theme },
    'Folder5Slide4': { name: Slide4, theme: Theme, steps: 4 },
    'Folder5Slide5': { name: Slide5, theme: Theme, footer: 'transparent' },
    'Folder5Slide6': { name: Slide6, theme: Theme, steps: 4 },
    'Folder5Slide7': { name: Slide7, theme: Theme },
    'Folder5Slide8': { name: Slide8, theme: Theme, steps: 2, footer: 'transparent' },
    'Folder5Slide9': { name: Slide9, theme: Theme },
    'Folder5Slide10': { name: Slide10, theme: Theme },
    'Folder5Slide11': { name: Slide11, theme: Theme },
    'Folder5Slide12': { name: Slide12, theme: Theme, footer: 'transparent' },
    'Folder5Slide13': { name: Slide13, theme: Theme },
    'Folder5Slide14': { name: Slide14, theme: Theme },
    'Folder5Slide15': { name: Slide15, theme: Theme, footer: 'transparent' },
    'Folder5Slide16': { name: Slide16, theme: Theme },
    'Folder5Slide17': { name: Slide17, theme: Theme, steps: 2 },
    'Folder5Slide18': { name: Slide18, theme: Theme },
    'Folder5Slide21': { name: Slide21, theme: Theme },
    'Folder5Slide23': { name: Slide23, theme: Theme },
    'Folder5Slide24': { name: Slide24, theme: Theme, steps: 2 },
    'Folder5Slide25': { name: Slide25, theme: Theme },
    'Folder5Slide26': { name: Slide26, theme: Theme },
    'Folder5Slide27': { name: Slide27, theme: Theme },
    'Folder5Slide28': { name: Slide28, theme: Theme },
    'Folder5Slide29': { name: Slide29, theme: Theme },
    'Folder5Slide30': { name: Slide30, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder5Slide1' },
    { key: 1, component: 'Folder5Slide2' },
    { key: 2, component: 'Folder5Slide3' },
    { key: 3, component: 'Folder5Slide4' },
    { key: 4, component: 'Folder5Slide5' },
    { key: 5, component: 'Folder5Slide6' },
    { key: 6, component: 'Folder5Slide7' },
    { key: 7, component: 'Folder5Slide8' },
    { key: 8, component: 'Folder5Slide9' },
    { key: 9, component: 'Folder5Slide10' },
    { key: 10, component: 'Folder5Slide11' },
    { key: 11, component: 'Folder5Slide12' },
    { key: 12, component: 'Folder5Slide13' },
    { key: 13, component: 'Folder5Slide14' },
    { key: 14, component: 'Folder5Slide15' },
    { key: 15, component: 'Folder5Slide16' },
    { key: 16, component: 'Folder5Slide17' },
    { key: 17, component: 'Folder5Slide18' },
    { key: 18, component: 'Folder5Slide6' },
    { key: 19, component: 'Folder5Slide10' },
    { key: 20, component: 'Folder5Slide21' },
    { key: 21, component: 'Folder5Slide6' },
    { key: 22, component: 'Folder5Slide23' },
    { key: 23, component: 'Folder5Slide24' },
    { key: 24, component: 'Folder5Slide25' },
    { key: 25, component: 'Folder5Slide26' },
    { key: 26, component: 'Folder5Slide27' },
    { key: 27, component: 'Folder5Slide28' },
    { key: 28, component: 'Folder5Slide29' },
    { key: 29, component: 'Folder5Slide30' }
]