import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import {  useCounter } from 'components/hooks'

import bg from './assets/bg.jpg'


const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 45px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        line-height: 30px;
    }

    .text2 {
        line-height: 27.5px;
    }

    .count1 {
        font-size: 138.5px;
        line-height: 1;
        letter-spacing: -0.7rem;
    }

    
    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

`

const Folder2Slide1 = ({ ...props }) => {

    let count = useCounter(0, 75, props.active, props.preview, 0)

    return (
        <>
            <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Ihre Kunden <strong>erwarten<br />
                    mehr von ihren Kontaktlinsen</strong></h1>
                <Container left={538} top={414} width={970} as="p" className="text1 text-center color2">
                    Laut Studie mit 470 Kontaktlinsenträgern
                </Container>

                <div className="container-animate fadeInFromTop">

                <Arrow left={1004} top={116} width={824} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                <Container  left={740} top={583} width={565} className="count1 text-center">
                {count}%
                </Container>

                <Container left={658} top={861} width={730} as="p" className="text2 text-center">
                    stimmen zu, dass sie<br />
                    <strong>mehr von ihren Kontaktlinsen<br />
                    erwarten, wie z. B. höheren<br />
                    Tragekomfort und bessere<br />
                    Sehqualität<sup>7</sup></strong>
                </Container>

                </div>

            </Slide>
        </>

    );
};

export default Folder2Slide1;