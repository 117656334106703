import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CloseBtn, CircleCount, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';
import img2 from './assets/img1.jpg';
import img3 from './assets/img2.svg';

const Slide = styled.div`
   .box1 {
       padding-left: 19px;
    }

    .box2 {
        padding-left: 27px;
        
        p + p {
            margin-top: 13px;
        }

        p + p + p {
            margin-top: 26px;
        }
    }

    .box3 {
        padding-left: 39px;
        padding-top: 28px;

        h3 {
            font-size: 30px;
            line-height: 37.5px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        h3 + p {
            margin-top: 12px;
        }

        h3 + p+ p {
            margin-top: 17px;
        }
        
    }

    .box4 {
        padding-left: 32px;
    }

    .image1 {
        position: absolute;
        left: 223px;
        top: 208px;
        z-index: 2;
    }

    .image1 img {
        position: absolute;
        left: 25px;
        top: 15px;
        width: 277px;
    }

    .image1 p.text {
        position: absolute;
        width: 400px;
        left: 314px;
        font-size: 15.4px;
        line-height: 25.2px;
    }

    .image1 .text3,
    .image1 .text4,
    .image1 .text5,
    .image1 .text6,
    .image1 .text7,
    .image1 .text8 {
        color: #00a3e0;
    }

    .image1 .text1 {
        top: 19px;
        color: #003087;
    }

    .image1 .text2 {
        top: 42px;
        font-weight: 600;
        color: #009ca6;
    }

    .image1 .text3 {
        top: 137px;
    }

    .image1 .text4 {
        top: 170px;
    }

    .image1 .text5 {
        top: 186px;
    }

    .image1 .text6 {
        top: 203px;
    }

    .image1 .text7 {
        top: 232px;
    }

    .image1 .text8 {
        top: 264px;
    }

    .image1 p.label {
        position: absolute;
        font-size: 14px;
        font-weight: 300;
    }

    .image1 p.label1 {
        top: 49px;
        left: 27px;
    }

    .image1 p.label2 {
        font-size: 12.5px;
        width: 400px;;
        left: 314px;
        top: 298px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })



    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered" step={props.step}>
                <Image left={133} top={86} width={748} height={586} src={img2} />

                <Container left={750} top={238} width={488} height={119} className="rounded box1 rounded-full flex-all-center">
                    <p>prismenfreie Zone</p>
                </Container>

                <Container left={750} top={385} width={488} height={137} className="rounded box1 rounded-full flex-all-center">
                    <p>Lidschlag-<br />
Stabilisations-Zonen</p>
                </Container>

                <Container left={176} top={636} width={1078} height={510} className="rounded box2 flex-all-center text color1">
                    <p><strong>Klare, stabile Sehqualität:</strong><br />
Sehschärfe so gut wie eine Brille<sup>^35</sup></p>
                    <p><strong>Geschwindigkeit:</strong> Die Linse stabilisiert sich<br />
und richtet sich innerhalb <strong>weniger Minuten</strong><br />
nach dem Aufsetzen aus und passt sich bei<br />
jedem Lidschlag auf natürliche Weise neu an<sup>35</sup></p>
                    <p><strong>Kein vertikales Prisma</strong> in der optischen Zone<sup>°37</sup></p>
                </Container>

                <MoreBtn
                    left={1212} top={1026}
                    onClick={() => toggleOverlay('overlay1')}
                />

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1" className="with-bg bg-white">
                    <Container left={100} top={100} width={1848} height={1120} className="bg2 box3">
                        <h3 className="color1">ACUVUE® bietet das einzige torische Kontaktlinsen-<br />
sortiment ohne Vertikalprisma in der optischen Zone<sup>°35</sup></h3>
                        <p className="small">Aus einer Verdickung innerhalb der optischen Zone einer Kontaktlinse geht ein Vertikalprisma hervor<br />
                        und kann bei monokular astigmatisch Fehlsichtigen zu Störungen des Binokularsehens führen.</p>
                        <p className="color1"><strong>Vertikalprisma in der optischen Zone einiger torischer Silikon-Hydrogel<br />
Ein-Tages-Kontaktlinsen<sup>38</sup></strong></p>

                        <div className="image1">
                            <img src={img3} />
                            <p className="text text1">0,00∆ Sphärische Kontaktlinse</p>
                            <p className="text text2">0,02∆ ACUVUE OASYS® 1-Day for ASTIGMATISM</p>
                            <p className="text text3">0,52∆ AIR OPTIX® FOR ASTIGMATISM</p>
                            <p className="text text4">0,73∆ MyDay® Toric</p>
                            <p className="text text5">0,75∆ Clariti® 1-Day Toric + AVAIRA Toric</p>
                            <p className="text text6">0,77∆ Biofinity® Toric</p>
                            <p className="text text7">0,96∆ PureVision® 2 for Astigmatism</p>
                            <p className="text text8">1,15∆ PureVision® Toric</p>
                            <p className="label label1">Sichtlinie</p>
                            <p className="label label2">Grafik nur zur Veranschaulichung!</p>
                        </div>
                    </Container>

                    <Container left={172} top={874} width={708} height={300} className="rounded box4 flex-all-center color1">
                        <p><strong>Ein vertikales Prisma von<br />
                        größer als 0,5∆ kann zu<br /> 
Ermüdungserscheinungen<br />
und Kopfschmerzen führen.<sup>39</sup></strong></p>
                    </Container>

                    <CloseBtn
                        left={1855} top={127}

                        onClick={() => toggleOverlay('overlay1')}
                        className="more1" />

                </Overlay>

            </Slide>
        </>
    );
};
