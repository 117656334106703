import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
                <ol><li>1. Path to Purchase Studie 2018 und 2020, JJV Data on File; Switcher-Studie, 2017, JJV Data on File</li>

                    <li>2. JJV Growth Levers 2019 Studie, Präferenzen bei KL-Neuträgern, 3135 Studien-Teilnehmer</li>
                    <li>3. P2P Studie 2018/2019, Zahlen für DE</li>
                    <li>4. Dual User Studie Brille &amp; Kontaktlinse, Qualitative Betrachtung von Kundenbedürfnissen durch Tiefeninterviews, Rheingold Institut, 2019, JJV Data on File</li>
                    <li>5. Considerers-Studie Nov 2020, JJV Data on File 2020</li>
                    <li>6. Maxwell Maltz, aus „Psycho-Kybernetik”, 1960</li>

                    <li>7. Shopper Segmentation Study Germany by MillwardBrown Healthcare 2018 mit &gt; 2000 Studienteilnehmern</li>

                    <li>8. Survey conducted from 4/19 – 5/19. Sample comprised of 853 US Eye Doctors (686 optometrists &amp; 167 ophthalmologists)</li>
                </ol>

                <p><strong>Wichtige Sicherheitsinformationen:</strong> ACUVUE® Kontaktlinsen sind für eine Sehkorrektion indiziert. Wie bei jeder Kontaktlinse können Augenprobleme, einschließlich Hornhautgeschwüre, auftreten. Bei einigen Trägern kann es zu leichten Irritationen, Juckreiz oder Unbehagen kommen. Kontaktlinsen sollten nicht bei Augeninfektionen oder anderen Augenkrankheiten verwendet werden.
                Vollständige Informationen, einschließlich Kontraindikationen und Vorsichtsmaßnahmen, entnehmen Sie bitte der Gebrauchsanweisung oder besuchen Sie unsere J&amp;J-Website jnjvisioncare.de/at</p>

                <p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2 UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.
                UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken.
                UV- Transmission mit –1,00 Dioptrien Linse gemessen.</p>

                <p>ACUVUE®, ACUVUE® OASYS 1-Day, 1-DAY ACUVUE® MOIST, ACUVUE® VITA® und HydraLuxe® sind eingetragene Marken von Johnson &amp; Johnson.
                © 2022 Johnson &amp; Johnson Medical GmbH / Johnson &amp; Johnson Medical Products GmbH | PP2020AMB4832</p>

            </TextBox>
        </Slide>
    );
};
