import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'

import bg from '../slide10/assets/bg.jpg'
import img1 from './assets/img1.png'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 45px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        margin: 0;

        span {
            display: block;
            line-height: 45px;

            &:nth-child(1) {
                font-size: 22px;
                line-height: 34px;
            }

            &:nth-child(2) {
                font-size: 42px;
                line-height: 45px;
            }
        }
    }

    .text2 {
        font-size: 9px;
        line-height: 12.5px;
    }

    .text3 {
        font-size: 17.5px;
        line-height: 1;
    }

    .text4,
    .text5,
    .text6 {
        line-height: 27.5px;

        &.text5 {
            text-transform: uppercase;
        }
    }

    .plus {
        font-size: 33px;
        line-height: 1;
    }


    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Ihre Kunden brauchen <strong>MAX Leistung</strong></h1>
               

                <Container left={0} top={314} width={2048} as="h2" className="text1 text-center">
                    <span className="color2">Wir stellen vor: Die aktuell beste ACUVUE® Linse</span>
                    <span><strong>ACUVUE® OASYS MAX 1-Day</strong></span>
                </Container>

                <div className="container-animate fadeInFromTop">

                    <Arrow left={1008} top={-40} width={1224} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Image left={115} top={635} width={905} height={615} src={img1} />

                    <Container left={138} top={1028} width={756} as="p" className="text2 text-center">
                        Nur zu Illustrationszwecken
                    </Container>

                    <Container left={234} top={1064} width={564} as="p" className="text3 color3 text-center">
                        <strong>Auch in Multifokal erhältlich</strong>
                    </Container>

                    <Container left={968} top={649} width={998} as="p" className="text4 text-center">
                        <strong>Eine noch nie da gewesene Kombination<br />
                            aus 2 neuen Technologien<sup>8</sup></strong>
                    </Container>

                    <Container left={1060} top={807} width={384} as="p" className="text5 text-center">
                        <strong>TearStable<sup>™</sup><br />
                            Technology</strong>
                    </Container>

                    <Container left={1418} top={824} width={79} as="span" className="plus color2 text-center">
                        <strong>+</strong>
                    </Container>


                    <Container left={1484} top={807} width={335} as="p" className="text5 text-center">
                        <strong>OptiBlue<sup>™</sup><br />
                            light Filter<sup>**</sup></strong>
                    </Container>

                    <Container left={1053} top={1046} width={832} as="p" className="text6 text-center">
                        <strong>MAX</strong> Tragekomfort. <strong>MAX</strong> Sehqualität.<sup>*8</sup>
                    </Container>

                </div>

            </Slide>
        </>

    );
};

export default Folder2Slide1;