import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';

const Slide = styled.div`
    .box1-1 {
        padding-left: 126px;
    }

    .box1-2 {
        padding-left: 134px;
    }

    .box1-3 {
        padding-left: 132px;
    }

    .count {
        font-size: 53.4px;
        line-height: 66.75;

        span {
            font-size: 28.8px;
        }
    }

    .box2 {
        overflow: hidden;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 59, props.active, props.preview)
    const count2 = useCounter(0, 64, props.active, props.preview)
    const count3 = useCounter(0, 58, props.active, props.preview)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1>Einige Menschen haben <strong>Bedenken<br />
bezüglich Kontaktlinsen<sup>1</sup></strong></h1>
                <Container left={523} top={337} width={952} height={165} className="rounded flex-all-center box1 box1-1 color1">
                    <p>machen sich Sorgen wegen<br />
des Auf- und Absetzens.</p>
                </Container>
                <Container left={333} top={667} width={886} height={165} className="rounded flex-all-center box1 box1-2 color1">
                    <p>sorgen sich, dass sie<br /> 
unangenehm zu tragen sind.</p>
                </Container>
                <Container left={180} top={1004} width={976} height={165} className="rounded flex-all-center box1 box1-3 color1">
                    <p>sorgen sich um Augen-Irritationen.</p>
                </Container>
                <CircleCount left={430} top={260} width={315} percent={count1} color={'#df9933'} />
                <CircleCount left={256} top={593} width={315} percent={count2} color={'#c20016'} />
                <CircleCount left={100} top={925} width={315} percent={count3} color={'#330072'} />

                <Container left={430} top={260} width={315} height={315} className="flex-all-center text-center box2">
                    <span className="count">{count1}<span>%</span></span>
                </Container>
                <Container left={256} top={593} width={315} height={315} className="flex-all-center text-center box2">
                    <span className="count">{count2}<span>%</span></span>
                </Container>
                <Container left={100} top={925} width={315} height={315} className="flex-all-center text-center box2">
                    <span className="count">{count3}<span>%</span></span>
                </Container>
            </Slide>
        </>
    );
};
