import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'

import bg from './assets/bg.jpg'


const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 71px;
        text-align: center;
        color: #fff;
    }

    .text1, .text3 {
        line-height: 27.5px;

        .count {
            font-size: 42.5px;
            line-height: 46px;
            color: #fff;
        }
    }

    .text2 {
        font-size: 25px;
        line-height: 26.5px;
        text-transform: uppercase;
    }

    .text3 {
        ul {
            margin-top: 10px;
            padding-left: 20px;
        
            li {
                position: relative;
            }

            li::before {
                position: absolute;
                left: -22px;
                top: -2px;
                content:"·";
                font-size:65px;
                vertical-align:middle;
                
            }
        }
    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Das Leben verlangt <strong>mehr von unseren Augen</strong></h1>
                <Container left={164} top={443} width={580} as="p" className="text1">
                    Erwachsene verbringen täglich mehr als 13 Std.<br />
                    Zeit an digitalen Geräten: Anstieg seit 2019 um<br />
                    <span className="count">35%<sup>2</sup></span>
                </Container>
                <div className="container-animate fadeInFromLeft">
                    <Arrow left={1081} top={288} width={590} className="" arrowColor={'#3a8dde'} lineColor={'#ffffff'} offset={-220} />
                    <Container left={1324} top={500} width={580} as="p" className="text2 text-right">
                        <strong>Auswirkungen<br />
                            auf unsere Augen</strong>
                    </Container>

                    <Container left={1390} top={660} width={580} className="text3">
                        <p><span className="count">60%</span> blinzelt man weniger beim Benutzen digitaler Bildschirme!</p>
                        <ul>
                            <li>das beeinflusst den Tränenfilm </li>
                            <li>Negative Auswirkung
                                auf den Tragekomfort.<sup>3,4</sup></li>
                        </ul>
                    </Container>
                </div>
            </Slide>
        </>

    );
};

export default Folder2Slide1;