import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';

const Slide = styled.div`
    
    .box1 {
        padding: 50px 10px 10px 12px;
    }
    
    .box1 .number {
        position: absolute;
        display: block;
        left: -10px;
        top: -68px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Warum die <strong>„MeinOptiker-Kampagne“?</strong></h1>

            <Container left={1597} top={370} width={344} height={763} className="box1 bg1 color1 fadeInFromLeft animate4">
                <span className="number">5</span>
                <p><strong>Die Erwartung</strong> zeitnah <strong>einen Termin</strong> zur
                    Beratung <strong>zu
                        erhalten</strong> – nämlich inner-
                    halb von<br />
                    2 Wochen.<sup>3</sup>
                </p>
                <Arrow left={340} top={85} width={600} className="" bgColor={'#CFF2FB'} />
                <Image left={114} top={559} width={110} height={113} src={img3} />
            </Container>

            <Container left={1224} top={370} width={344} height={763} className="box1 bg1 color1 fadeInFromLeft animate3">
                <span className="number">4</span>
                <p><strong>Das Bedürf-<br />nis</strong> von der Mehrheit der neuen KL-Träger <strong>das beste Produkt</strong> für die Augen <strong>zu erhalten.</strong><sup>3</sup>
                </p>
                <Arrow left={340} top={85} width={600} className="" bgColor={'#CFF2FB'} />
                <Image left={85} top={573} width={172} height={99} src={img2} />
            </Container>

            <Container left={850} top={370} width={344} height={763} className="box1 bg1 color1 fadeInFromLeft animate2">
                <span className="number">3</span>
                <p><strong>Die Suche</strong><br /> des
                    potenziellen Kontaktlinsen-
                    Trägers <strong>nach Kompetenz</strong> beim Optiker.<sup>1</sup>
                </p>
                <Arrow left={340} top={85} width={600} className="" bgColor={'#CFF2FB'} />
                <Image left={117} top={566} width={99} height={106} src={img1} />
            </Container>

            <Container left={476} top={370} width={344} height={763} className="box1 bg1 color1 fadeInFromLeft">
                <span className="number">2</span>
                <p><strong>Die weiterhin
                    hohe Anzahl
                    an Menschen,</strong> die sich eine
                    Kontaktlinse,
                    einen Check-
                    Up, einen
                    Wechsel des
                    Produktes
                    wünschen<br />
                    und keine
                    Beratung
                    erhalten.<sup>1, 2</sup></p>
                <Arrow left={340} top={85} width={600} className="" bgColor={'#CFF2FB'} />
            </Container>

            <Container left={100} top={370} width={344} height={763} className="box1 bg1 color1">
                <span className="number">1</span>
                <p><strong>Reduzierung
                    der Anpass-Kapazität</strong><br />
                    von vielen
                    Beteiligten<br />
                    im Markt.<br /><br />

                    Der stationäre<br />
                    Fachhandel<br />
                    kann so
                    profitieren.</p>
                <Arrow left={340} top={85} width={600} className="" bgColor={'#CFF2FB'} />
            </Container>

        </Slide>
    );
};
