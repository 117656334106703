import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';

const Slide = styled.div`
    .box1 {
        background: #76D8CD;
        border-radius: 15px;
        color: #fff;
    }

    .img1 {
        border: 2px solid #003087;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Für Ihre Mitarbeiter: <strong>Startklar mit Kontaktlinsen-Programm</strong></h1>

            <Container as="p" left={100} top={211} width={1820} className="small">
                Kompetenz in der Beratung rund um gutes Sehen trägt zu einer guten Kundenbindung bei.
            </Container>

            <Container left={341} top={345} width={1420} height={170} className="rounded rounded-full flex-all-center text-center">
                <p className="color1"><strong>interaktives und kostenloses<br />
                    Startklar mit Kontaktlinsen-Programm</strong></p>
            </Container>

            <MoreBtn
                left={282} top={387}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')}
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg">
                <Container left={324} top={257} width={1409} height={461} className="rounded">
                </Container>

                <Container as="p" left={1050} top={304} width={670} className="small color1 text-center">
                Wenn Sie Ihre Kund*innen<br /> 
emphatisch, professionell und<br /> 
kompetent zum Thema Kontaktlinse<br /> 
hinführen, kann ein leichter Einstieg<br /> 
in die Kategorie gelingen.<br /><br />

<strong>Was ist das Startklar mit Kontakt-<br />
linsen-Programm, was bringt es<br /> 
Ihnen und für wen ist es gemacht?</strong> 
                </Container>

                <Image src={img1} left={392} top={312} width={643} height={354} className="img1" />

            </Overlay>

            <Container as="p" left={100} top={578} width={1848} className="text-center">
                <strong className="color1">Es besteht aus 3 Modulen, Videos und PDF´s zum Download</strong><br />
                (Nach Abschluss des Kurses erhalten alle Teilnehmer*innen ein Teilnahme-Zertifikat)
            </Container>

            <Container as="p" left={204} top={836} width={520} height={150} className="box1 flex-all-center text-center">
                <strong>Motivation<br />
                    verstehen</strong>
            </Container>

            <Container as="p" left={764} top={836} width={520} height={150} className="box1 flex-all-center text-center">
                <strong>Kompetente<br />
                    Gespräche führen</strong>
            </Container>

            <Container as="p" left={1324} top={836} width={520} height={150} className="box1 flex-all-center text-center">
                <strong>Handhabung und<br />
                    Pflege erklären können</strong>
            </Container>

            <Container as="p" left={100} top={1065} width={1848} className="color1 text-center">
                <strong>Steigern Sie jetzt die Kompetenz Ihres Teams<br />
                    mit unserem Startklar mit Kontaktlinsen-Programm!</strong>
            </Container>

        </Slide>
    );
};
