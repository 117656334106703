import React from 'react';
import styled, { keyframes, css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

const breatheAnimation = keyframes`
    0% {
        transform: scale(1)
    }
    60% {
        transform: scale(1.2)
    }
    100% {  
        transform: scale(1)
    }
`

export const PlayBtnContainer = styled.svg`
    position: absolute;
    z-index: 100;
	width: 42px;
	height: 42px;
	cursor: pointer;

    &:not(.active) {
        animation: ${breatheAnimation} 2s infinite;
    }
 
    &.active {
        z-index: 300;
    }

    &.x-left {
        left: -21px
    }

    &.x-right {
        right: -21px
    }

    &.x-center {
        right: calc(50% - 21px)
    }

    &.y-bottom {
        bottom: -21px
    }

    &.y-center {
        top: calc(50% - 21px)
    }

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}
`

const StarBtn = (props) => {
    const onClick = (event) => {
        event.stopPropagation();
        props.onClick();
    }

    return (
        <PlayBtnContainer width="42" height="42" viewBox="0 0 82.79 82.79" className={props.className + (props.active ? ' active' : '')} style={props.style} left={props.left} top={props.top} onClick={onClick}>
            <defs>
                <clipPath id="b">
                    <rect y="0" width="82.79" height="82.79" fill="none" />
                </clipPath>
            </defs>
            <g clip-path="url(#b)">
                <path d="m41.4,81.65c22.23,0,40.26-18.02,40.26-40.26S63.63,1.14,41.4,1.14,1.14,19.16,1.14,41.4s18.02,40.26,40.26,40.26" fill="#fff" />
                <path d="m41.4,81.65c22.23,0,40.26-18.02,40.26-40.26S63.63,1.14,41.4,1.14,1.14,19.16,1.14,41.4s18.02,40.26,40.26,40.26Z" fill="none" stroke="#008de1" stroke-width="2.28" />
                <path d="m41.4,76.17c19.2,0,34.77-15.57,34.77-34.77S60.6,6.63,41.4,6.63,6.63,22.19,6.63,41.4s15.57,34.77,34.77,34.77" fill="#008de1" />
                <path d="m52.51,44.89l6.89,21.2-18.05-13.1-17.96,13.1,6.89-21.2-17.87-13.01h22.1l6.84-21.07,6.93,21.07h22.1l-17.87,13.01Zm19.36-16.05c-1.65-3.98-4.05-7.54-7.2-10.69-3.21-3.15-6.8-5.55-10.76-7.2-3.96-1.65-8.15-2.48-12.56-2.48s-8.57.83-12.56,2.5c-3.99,1.67-7.55,4.06-10.67,7.18s-5.55,6.72-7.2,10.69c-1.65,3.98-2.48,8.16-2.48,12.54s.83,8.61,2.48,12.58c1.65,3.98,4.05,7.54,7.2,10.69,3.12,3.12,6.69,5.51,10.69,7.18,4.01,1.67,8.22,2.5,12.63,2.5s8.6-.83,12.56-2.5c3.96-1.67,7.52-4.06,10.67-7.18,3.15-3.15,5.55-6.71,7.2-10.69,1.65-3.98,2.48-8.17,2.48-12.58s-.83-8.56-2.48-12.54" fill="#fff" />
            </g>
        </PlayBtnContainer>
    );
};

export default StarBtn;