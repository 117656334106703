import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState } from "components/hooks";

import {
  Arrow,
  Container,
  CloseBtn,
  Image,
  MoreBtn,
  Overlay,
} from "components/parts";

import img1 from "./assets/img1.png";
import img3 from "./assets/img3.jpg";

const Slide = styled.div`
  .box1 {
    padding-left: 15px;
  }

  .box1 .number {
    position: absolute;
    display: block;
    left: 2px;
    top: -86px;
    font-size: 97.5px;
    line-height: 122px;
    font-style: italic;
    text-align: right;
    color: #ffb900;
  }

  .box1-2 .number {
    left: 16px;
  }

  .box1-3 .number {
    left: 16px;
  }

  .box1-3 .exclamation {
    position: absolute;
    left: 850px;
    top: -13px;
    font-size: 97.5px;
    line-height: 122px;
    font-style: italic;
    text-align: right;
  }

  .box2 {
    background: #fff;
  }

  .box2 .number {
    position: absolute;
    display: block;
    left: -64px;
    top: -10px;
    width: 60px;
    font-size: 56.5px;
    line-height: 71px;
    color: #003087;
    font-style: italic;
    text-align: right;
  }

  .overlay1 {
    > .box1 {
      border-radius: 15px;
      background: #fff;
      border-width: 1px;
    }

    &::before {
      background: #051f4a !important;
    }
  }
`;
const inititialState = {
  overlay1: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide className="centered">
      <h1 className="color2">
        Wie nutzen Sie{" "}
        <strong>
          die Multifokal-Kampagne
          <br />
          am erfolgreichsten?
        </strong>
      </h1>

      <Container
        left={105}
        top={440}
        width={1540}
        height={172}
        className="bg2 box1 box1-1 color2 flex-all-center fadeInFromLeft"
      >
        <span className="number number1 color2">1.</span>
        <p>
          <strong>Informieren & trainieren Sie das Team zur Kampagne </strong>
          <br />
          (inkl. Einsatz & Nutzen des Aktionsflyers)
        </p>
      </Container>

      <Container
        left={105}
        top={727}
        width={1540}
        height={172}
        className="bg2 box1 box1-2 color2 flex-all-center fadeInFromLeft animate2"
      >
        <span className="number number1 color2">2.</span>
        <p>
          Platzieren Sie <strong>die Werbemitte</strong>l gut sichtbar im Innen-
          und Außen-
          <br />
          bereich des Geschäfts und geben Sie die Flyer beim Brillenkauf mit.
        </p>
      </Container>

      <Image
        left={1495}
        top={641}
        width={347}
        height={347}
        src={img1}
        className="fadeIn animate2"
      />

      <div className="container-animate fadeInFromLeft animate3">
        <Container
          left={105}
          top={1015}
          width={1833}
          height={172}
          className="bg2 box1 box1-3 color2 flex-all-center "
        >
          <span className="number number1 color2">3.</span>
          <p>
            <strong>Nutzen Sie die weiteren Kommunikations-Maßnahmen,</strong>
            <br />
            um für Kunden und Mitarbeiter sichtbar zu sein.
          </p>

          <span className="exclamation color2">!</span>
          <MoreBtn
            className="x-right y-center"
            onClick={() => toggleOverlay("overlay1")}
            active={state.overlay1 === "overlay1"}
          />
        </Container>

        <Overlay
          active={state.overlay1 === "overlay1"}
          className="overlay1 with-bg"
        >
          <Container
            left={100}
            top={291}
            width={1840}
            height={895}
            className="box1 color2 rounded border"
          ></Container>

          <Image left={652} top={326} width={1242} height={842} src={img3} />

          <Container as="p" left={158} top={346} width={525} className="color2">
            <strong>Point of Sale:</strong>
          </Container>

          <Container
            as="p"
            left={1077}
            top={346}
            width={700}
            className="color2"
          >
            <strong>Dig. und post. Mailings:</strong>
          </Container>

          <Container
            as="p"
            left={1077}
            top={882}
            width={700}
            className="color2"
          >
            <strong>Social Media/Website:</strong>
          </Container>

          <Container left={217} top={418} width={525} className="color2">
            <Arrow
              left={-60}
              top={8}
              width={64}
              className=""
              arrowColor={"#ffb900"}
            />
            <p>
              Hängen Sie Poster <br />
              und Fensterkleber
              <br />
              prominent auf
            </p>
          </Container>

          <Container left={217} top={604} width={525} className="color2">
            <Arrow
              left={-60}
              top={8}
              width={64}
              className=""
              arrowColor={"#ffb900"}
            />
            <p>
              <span style={{ letterSpacing: "-0.035rem" }}>
                Stellen Sie die Broschüre
              </span>
              <br />
              und Aktionsflyer gut
              <br />
              erreichbar auf
            </p>
          </Container>

          <Container left={217} top={795} width={525} className="color2">
            <Arrow
              left={-60}
              top={8}
              width={64}
              className=""
              arrowColor={"#ffb900"}
            />
            <p>
              Platzieren Sie die
              <br />
              Innendekoration auf
              <br />
              kundenzugänglichen Brillentabletts
            </p>
          </Container>

          <Container left={217} top={1038} width={525} className="color2">
            <Arrow
              left={-60}
              top={8}
              width={64}
              className=""
              arrowColor={"#ffb900"}
            />
            <p>
              Nutzen Sie das
              <br />
              Instore Video
            </p>
          </Container>

          <Container left={1138} top={418} width={600} className="color2">
            <Arrow
              left={-60}
              top={8}
              width={64}
              className=""
              arrowColor={"#ffb900"}
            />
            <p>
              Schreiben Sie
              <br />
              Linsenträger ab
              <br />
              40+ und Gleitsicht-
              <br />
              brillenträger an
            </p>
          </Container>

          <Container left={1138} top={951} width={600} className="color2">
            <Arrow
              left={-60}
              top={8}
              width={64}
              className=""
              arrowColor={"#ffb900"}
            />
            <p>
              Spielen Sie Social
              <br />
              Posts, Stories und
              <br />
              Videos aus
            </p>
          </Container>
        </Overlay>
      </div>
    </Slide>
  );
};
