import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg'
import icon1 from './assets/icon1.svg'
import icon2 from './assets/icon2.svg'
import icon3 from './assets/icon3.svg'
import icon4 from './assets/icon4.svg'
import icon5 from './assets/icon5.svg'
import icon6 from './assets/icon6.svg'

import img2 from './assets/img2.svg'
import img3 from './assets/img3.svg'
import img4 from './assets/img4.svg'

const Slide = styled.div`
    .line1 {
        position: absolute;
        left: 215px;
        top: 393px;
        width: 512px;
        height: 3.3px;
        background: #003087;
    }

    .line2 {
        position: absolute;
        left: 487px;
        top: 198px;
        width: 3.3px;
        height: 366px;
        background: #003087;
    }

    .box1 {
        align-items: center;
        justify-content: flex-start;

        .icon1 {
            margin-left: 23px;
            margin-right: 10px;
            width: 42px;
        }

        .icon2 {
            margin-left: 14px;
            width: 44px;
        }

        .icon3 {
            margin-left: 10px;
            margin-right: 4px;
            width: 48px;
        }

        .icon4 {
            margin-left: 14px;
            width: 44px;
        }

        .icon5 {
            margin-left: 21px;
            margin-right: 10px;
            width: 42px;
        }

        .icon6 {
            margin-left: 13px;
            margin-right: 4px;
            width: 46px;
        }
    }

    .circle {
        border-radius: 50%;
        color: #fff;
        padding-top: 9px;

        p {
            font-size: 17.5px;
        }

        span {
            font-size: 32.65px;
            line-height: 36.65px;
            font-weight: 600;
        }

        &.circle1 {
            background: #a7a9ac;
        }

        &.circle2 {
            background: #003087;
        }

        &.circle3 {
            background: #00a3e0;
        }

        &.circle4 {
            background: #000;

            p {
                margin-top: -5px;
            }
        }
    }

    .step2,
    .step3,
    .step4 {
        opacity: 0;
    }

    .step3,
    .step4 {
        .count {
            margin-top: 6px;
            font-size: 57px;
            line-height: 71px;
            color: #003087;

            span {
                font-size: 31px;
            }
        }
    } 
    
    .step2 {
        ${({ step }) => step >= 2 && css`
            opacity: 1;
        `}
    }

    .step3 {
        ${({ step }) => step >= 3 && css`
            opacity: 1;
        `}
    }

    .step4 {
        ${({ step }) => step >= 4 && css`
            opacity: 1;
        `}
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Was bewegt einen Kontaktlinsenträger,</strong> wenn dieser über<br />
            den Kauf seiner Kontaktlinsen nachdenkt?<sup>2</sup></h1>

            <span className="line1" />
            <span className="line2" />

            <Image left={1270} top={265} width={326} height={326} src={img1} className="rounded border" />

            <div className="step2">
                <Image left={1007} top={417} width={366} height={714} src={img2} />
            </div>

            <Container left={710} top={538} width={222} height={222} className="circle circle1 flex-all-center text-center">
                <p className="small"><strong>Bequem-<br/>
                lichkeit</strong></p>
                <span>13%</span>
            </Container>

            <Container left={1056} top={491} width={269} height={269} className="circle circle2 flex-all-center text-center">
                <p className="small"><strong>Gesundheit /<br/>
                Kompetenz</strong></p>
                <span>56%</span>
            </Container>

            <Container left={1069} top={818} width={247} height={247} className="circle circle3 flex-all-center text-center">
                <p className="small"><strong>Angebot</strong></p>
                <span>25%</span>
            </Container>

            <Container left={734} top={818} width={174} height={174} className="circle circle4 flex-all-center text-center">
                <p className="small"><strong>Preis</strong></p>
                <span>6%</span>
            </Container>

            <div className="step3">
                <Image left={1007} top={417} width={366} height={714} src={img3} />
                <Container left={1060} top={587} width={260} height={260} className="flex-all-center text-center">
                    <span className="count">81<span>%</span></span>
                </Container> 
            </div>

            <div className="step4">
                <Image left={662} top={417} width={712} height={714} src={img4} />
                <Container left={1062} top={468} width={260} height={260} className="flex-all-center text-center">
                    <span className="count">94<span>%</span></span>
                </Container> 
            </div>

            <Container left={672} top={290} width={608} height={119} className="rounded flex box1 box1-1">
                <img src={icon1} className="icon1" />
                <p className="small">NICHT Preis- oder<br />
Angebotsorientiert</p>
                <img src={icon2} className="icon2" />
            </Container>

            <Container left={1443} top={729} width={382} height={119} className="rounded flex box1 box1-2">
                <img src={icon3} className="icon3" />
                <p className="small">Gesundheit/<br/>
                Kompetenz</p>
            </Container>

            <Container left={672} top={1121} width={608} height={119} className="rounded flex box1 box1-3">
                <img src={icon5} className="icon5" />
                <p className="small">Preis- oder<br /> 
Angebotsorientiert</p>
                <img src={icon4} className="icon4" />
            </Container>

            <Container left={222} top={729} width={421} height={119} className="rounded flex box1 box1-4">
                <img src={icon6} className="icon6" />
                <p className="small">Bequemlichkeit</p>
            </Container>
            
            

        </Slide>
    );
};
