import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';
import icon1 from '../slide8/assets/icon1.svg';
import icon2 from '../slide8/assets/icon2.svg';

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';

import img4 from '../slide4/assets/img2.png';

import li from '../slide6/assets/li.svg';

import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import img8 from './assets/img8.png';

import img9 from '../slide8/assets/img2.png';

const Slide = styled.div`
    .box1 {
        background: #76D8CD;
        border-radius: 50%;
        padding-top: 55px;
    }

    .icon1,
    .icon2 {
        position: absolute;
        left: 294px;
        top: 186px;
        width: 92px;
    }

    .icon2 {
        left: 470px;
    }

    .icon3 {
        position: absolute;
        left: 655px;
        top: 192px;
        width: 68px;
    }

    .box2 {
        font-weight: 600;
        color: #fff;

        > * {
            display: block;
        }

        span {
            font-size: 10px;
            line-height: 12px;
        }

        strong {
            font-size: 32.8px;
            line-height: 29.8px;
            font-weight: 600;
        }
    }

    .plus {
        position: absolute;
        top: 245px;
        font-size: 50px;
        line-height: 50px;
        font-weight: 600;
        color: #009C81;

        &.plus1 {
            left: 409px; 
        } 

        &.plus2 {
            left: 585px; 
        } 
    }

    .box3 { 
        padding-left: 65px;

        .number {
            position: absolute;
            left: -21px;
            top: -10px;
            width: 73.5px;
            height: 73.5px;
            border-radius: 50%;
            color: #fff;
            background: #009C81;
            font-size: 62px;
            line-height: 76.5px;
            text-align: center;
            font-style: italic;
            padding-right: 2px;
        }

        &.box3-5 p {
            letter-spacing: -0.03em;
        }
    }

    .box4 {
        position: absolute;
        left: -44px;
        top: 190px
    }

    .overlay1 {
        .box5 {
            padding-top: 6px;
            border-radius: 15px;
            background: #76D8CE;
            color: #fff;
        }
    }

    .overlay2 {  
            
        ul {
            margin-top: 157px;
            margin-left: 75px;

            font-size: 17.5px;
            line-height: 32px;

            li {
                padding-left: 45px;
                background: url(${li});
                background-repeat: no-repeat;
                background-size: 31px 25px;
                background-position: 0px 5px;
            }
        }
    
    }

    .overlay4 {
        .image {
            border-color: #000 !important;
            border-width: 1.2px  !important;
        }

        .text1 {
            font-size: 17.5px;
            line-height: 19px;
            text-align: center;
        }
    }

    .overlay5 {
        .box6 {
            font-weight: 600;

            span {
                font-size: 43.5px;
                line-height: 43.5px;
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const history = useHistory();

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1>Unsere Unterstützung<br />
                    <strong>für Ihren Geschäftserfolg</strong></h1>

                <Container left={142} top={332} width={794} height={113} className="box3 rounded rounded-full flex-all-center">
                    <span className="number">1</span>
                    <p className="color1"><strong>Produktinnovationen</strong></p>
                    <MoreBtn
                        className="x-right y-center"
                        active={state.overlay1 === 'overlay1'}
                        onClick={() => toggleOverlay('overlay1')}
                    />
                </Container>

                <Container left={142} top={511} width={794} height={113} className="box3 rounded rounded-full flex-all-center">
                    <span className="number">2</span>
                    <p className="color1"><strong>Unser Kundenservice für Sie</strong></p>
                    <MoreBtn
                        className="x-right y-center"
                        active={state.overlay1 === 'overlay2'}
                        onClick={() => toggleOverlay('overlay2')}
                    />
                </Container>

                <Container left={142} top={691} width={794} height={113} className="box3 rounded rounded-full flex-all-center">
                    <span className="number">3</span>
                    <p className="color1"><strong>Fortbildungsprogramme</strong></p>
                    <MoreBtn
                        className="x-right y-center"
                        active={state.overlay1 === 'overlay3'}
                        onClick={() => toggleOverlay('overlay3')}
                    />
                </Container>

                <Container left={142} top={870} width={794} height={113} className="box3 rounded rounded-full flex-all-center">
                    <span className="number">4</span>
                    <p className="color1"><strong>Verkaufsfördernde Konzepte</strong></p>
                    <MoreBtn
                        className="x-right y-center"
                        active={state.overlay1 === 'overlay4'}
                        onClick={() => toggleOverlay('overlay4')}
                    />
                </Container>

                <Container left={142} top={1050} width={794} height={113} className="box3 box3-5 rounded rounded-full flex-all-center">
                    <span className="number">5</span>
                    <p className="color1"><strong>Attraktives Konditionensystem</strong></p>
                    <MoreBtn
                        className="x-right y-center"
                        active={state.overlay1 === 'overlay5'}
                        onClick={() => toggleOverlay('overlay5')}
                    />
                </Container>

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg">
                    <Container left={169} top={371} width={1531} height={800} className="rounded">
                    </Container>
                    <Container as="p" left={169} top={445} width={1531} className="small text-center">
                    Jedes Auge ist anders. Darum verfügen alle ACUVUE® Kontaktlinsen über<br /> 
unsere unterschiedlichen technologischen EYE-INSPIRED™ Innovations<sup>1</sup>. Damit können<br /> 
Sie den individuellen Bedürfnissen der Augen Ihrer Kunden Rechnung tragen.
                    </Container>

                    <Container as="p" left={169} top={617} width={1531} className="color1 text-center">
                        <strong>Das ACUVUE® Produktsortiment für jedes Kundenbedürfnis</strong>
                    </Container>

                    <Arrow left={920} top={121} width={1204} className="rotate90" />

                    <Container left={203} top={765} width={350} height={365} className="box5 text-center">
                        <p><strong>Ein-Tageslinsen</strong></p>
                    </Container>
                    <Container left={572} top={765} width={350} height={365} className="box5 text-center">
                        <p><strong>2-Wochenlinsen</strong></p>
                    </Container>
                    <Container left={942} top={765} width={350} height={365} className="box5 text-center">
                        <p><strong>Monatslinsen</strong></p>
                    </Container>
                    <Container left={1312} top={765} width={350} height={365} className="box5 text-center">
                        <p><strong>Linsen-/Augen-<br />
                            Pflegemittel</strong></p>
                    </Container>

                    <Image left={219} top={866} width={316} height={245} src={img5} />
                    <Image left={588} top={854} width={335} height={277} src={img6} />
                    <Image left={966} top={884} width={303} height={211} src={img7} />
                    <Image left={1337} top={909} width={320} height={192} src={img8} />

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay2'} className="overlay2 with-bg">
                    <Container left={270} top={551} width={1330} height={689} className="rounded">
                        <ul className="small">
                            <li>24 h Bestellmöglichkeit und großzügige Retourenregelung</li>
                            <li>Home Delivery Service für Ihre Kunden</li>
                            <li>Kostenlose Anpasslinsen &amp; Anpass-Hotline</li>
                            <li>ACUVUE® Zufriedenheitsgarantie</li>
                            <li>Online Bestellsystem</li>
                        </ul>
                    </Container>

                    <Container as="p" left={270} top={648} width={1330} className="small text-center">
                        Wir als Ihr zuverlässsiger Partner bieten Ihnen u. a. folgende Services,<br />
                        damit Sie Ihre Kunden bestmöglichst versorgen können.
                    </Container>

                    <Arrow left={920} top={201} width={1204} className="rotate90" />



                    <MoreBtn
                        left={1165} top={916}
                        onClick={() => { history.push('/folders/unsere-unterstuetzung/2') }}
                    />


                </Overlay>


                <Overlay active={state.overlay1 === 'overlay3'} className="overlay3 with-bg">
                    <Container left={272} top={731} width={1320} height={544} className="rounded">
                    </Container>
                    <Container as="p" left={272} top={828} width={1320} className="small text-center">
                        Wir bieten Ihnen Maßnahmen an, u.a. On-Demand oder Live Schulungen,<br />
                        die Ihnen und Ihrem Team eine flexible Weiterbildung ermöglicht.<br />
                        Zusätzlich stellen wir objektive und produktneutrale Kundeninformationen<br />
                        für Ihre nachhaltige Beratung bereit.
                    </Container>

                    <Arrow left={920} top={551} width={1035} className="rotate90" />

                    <Image left={491} top={1112} width={893} height={128} src={img4} />

                    <MoreBtn
                        left={1402} top={1009}
                        onClick={() => { history.push('/folders/unsere-unterstuetzung/3') }}
                    />


                </Overlay>

                <Overlay active={state.overlay1 === 'overlay4'} className="overlay4 with-bg">
                    <Container left={345} top={250} width={1180} height={705} className="rounded">

                    </Container>
                    <Container as="p" left={345} top={315} width={1180} className="small text-center">
                        Wir unterstützen Sie mit verkaufsfördernden Konzepten und Ideen<br />
                        sowie deren Anpassung und Implementierung in Ihrem Geschäft.
                    </Container>

                    <Container as="p" left={345} top={447} width={1180} className="color1 text-center">
                        <strong>Kampagnen- und Marketing-Bausteine</strong>
                    </Container>

                    <Arrow left={920} top={68} width={935} className="rotate90" />

                    <Image left={485} top={573} width={201} height={201} src={img1} className="image border rounded" />
                    <Image left={831} top={573} width={201} height={201} src={img2} className="image border rounded" />
                    <Image left={1179} top={573} width={201} height={201} src={img3} className="image border rounded" />

                    <Container as="p" left={385} top={794} width={401} className="text1"><strong>Meine Augen.<br />
                        Mein Optiker.</strong></Container>
                    <Container as="p" left={731} top={794} width={401} className="text1"><strong>Kontaktlinsen-<br />
                        Aktion</strong></Container>
                    <Container as="p" left={1079} top={794} width={401} className="text1"><strong>Multifokal<br />
                        Kampagne</strong></Container>

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay5'} className="overlay5 with-bg">
                    <Container left={275} top={410} width={1320} height={728} className="rounded">

                    </Container>
                    <Container as="p" left={345} top={458} width={1180} className="small text-center">
                        Der Anpassrabatt stellt Sie als anpassenden Partner in den Fokus.<br />
                        Der Umsatzrabatt fördert nochmals Ihren Gewinn.
                    </Container>

                    <Container as="p" left={345} top={590} width={1180} className="color1 text-center">
                        <strong>Unsere attraktiven Konditionen 2021</strong>
                    </Container>

                    <Arrow left={920} top={228} width={935} className="rotate90" />

                    <MoreBtn
                        left={1402} top={635}
                        onClick={() => { history.push('/folders/unsere-unterstuetzung/7') }}
                    />

                    <Image left={339} top={710} width={1185} height={382} src={img9} contain />

                    <Container as="p" left={339} top={890} width={309} className="box6 small color1 text-center">
                        <span>0-11%</span><br />Umsatz-<br />
                        rabatt
                    </Container>

                    <Container as="p" left={630} top={890} width={309} className="box6 small color1 text-center">
                        <span>9%</span><br />Anpass-<br />
                        rabatt
                    </Container>

                    <Container as="p" left={920} top={890} width={309} className="box6 small color1 text-center">
                        <span>5%</span><br />Partner-<br />
                        rabatt
                    </Container>

                    <Container as="p" left={1211} top={890} width={309} className="box6 small color1 text-center">
                        <span>5%</span><br />Wachstums-<br />
                        rabatt
                    </Container>



                </Overlay>

            </Slide>
        </>
    );
};
