import React from 'react';
import styled, { keyframes, css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

const breatheAnimation = keyframes`
    0% {
        transform: scale(1)
    }
    60% {
        transform: scale(1.2)
    }
    100% {  
        transform: scale(1)
    }

`

export const BtnContainer = styled.span`
    position: absolute;
    z-index: 100;
	width: 42px;
	height: 42px;

    &.active {
        z-index: 300;
    }

    &.x-left {
        left: -21px
    }

    &.x-right {
        right: -21px
    }

    &.x-center {
        right: calc(50% - 21px)
    }

    &.y-bottom {
        bottom: -21px
    }

    &.y-center {
        top: calc(50% - 21px)
    }

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}
`;

export const PlayBtnContainer = styled.svg`
	cursor: pointer;

    &:not(.active) {
        animation: ${breatheAnimation} 2s infinite;
    }
 
   
`

const MoreBtn = (props) => {
    const onClick = (event) => {
        event.stopPropagation();
        props.onClick();
    }

    return (
        <BtnContainer className={props.className + (props.active ? ' active' : '')} style={props.style} left={props.left} top={props.top} >
            <PlayBtnContainer width="42" height="42" viewBox="0 0 82.79 82.79" onClick={onClick} className={(props.active ? ' active' : '')}>

                <g>
                    <path stroke="#00a4e4" className="stroke" d="M41.4,81.65A40.26,40.26,0,1,0,1.14,41.4,40.25,40.25,0,0,0,41.4,81.65Z" transform="translate(0 0)" fill="none" strokeWidth="2.28" />
                    <path fill="#00a4e4" className="fill" d="M41.4,76.16A34.77,34.77,0,1,0,6.63,41.4,34.77,34.77,0,0,0,41.4,76.16" transform="translate(0 0)" />
                    <polygon points="29.36 22.33 29.36 60.46 61.24 41.4 29.36 22.33" fill="#fff" style={{ opacity: props.active ? 0 : 1 }} />
                    <line x1="27.29" y1="27.29" x2="55.51" y2="55.51" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3.99" style={{ opacity: props.active ? 1 : 0 }} />
                    <line x1="55.51" y1="27.29" x2="27.28" y2="55.51" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3.99" style={{ opacity: props.active ? 1 : 0 }} />
                </g>
            </PlayBtnContainer>
        </BtnContainer>
    );
};

export default MoreBtn;