import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';

const Slide = styled.div`
   .box3 {
       background: #FEF0E0 !important;
   }
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Unabhängig davon, <strong>wie die Linse auf das Auge gesetzt wurde …</strong></h1>
            <Container left={343} top={287} width={1362} height={119} className="rounded rounded-full box1 color1 flex-all-center text-center">
               <p><strong>… wie oft geblinzelt wurde; in welche Richtung der Kunde<br>
               </br>schaut; ob er steht, liegt oder seinen Kopf neigt …</strong></p>
                
            </Container>
            <Container left={284} top={460} width={1478} height={297} className="bg1 flex-all-center text-center color1 box2 fadeInFromTop">
                <Arrow width={1390} className="container-top" />
                <p><strong>… ACUVUE® OASYS 1-Day for ASTIGMATISM mit<br /> 
Lidschlag-Stabilisations-Design</strong> trägt nachweislich dazu bei,<br /> 
den ganzen Tag über eine klare, stabile Sehqualität zu gewährleisten,<br />
selbst bei starken Kopf- und Augenbewegungen.<sup>35</sup></p>
            </Container>
            <div className="container-animate fadeInFromTop animate2">
            <Container left={284} top={806} width={1478} height={390} className="bg1 flex-all-center text-center color1 box3">
                <Arrow width={1390} className="container-top" />
                <p>Das <strong>Lidschlag-Stabilisations-Design</strong> sorgt dafür, dass sich die<br />  
Kontaktlinse mit jedem Blinzeln schnell und natürlich auf dem<br />  
Auge ausrichten kann.<sup>35</sup><br /> 
<strong>Die vier einzigartigen Stabilisierungszonen der Linse<br /> 
interagieren mit dem Augenlid, um die Kontaktlinse in der<br />  
korrekten Position zu halten.<sup>15</sup></strong></p>
            </Container>
            <Image left={5} top={740} width={388} height={373} src={img1} />
            </div>
        </Slide>
    );
};
