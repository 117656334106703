import React, { useEffect, useMemo, useState } from "react";
import styled, { keyframes, css } from "styled-components";

import {
  Arrow,
  Arrow2,
  Container,
  Image,
  MoreBtn,
  Overlay,
} from "components/parts";

import { ArrowContainer } from "components/parts/Arrow";
import { Arrow2Container } from "components/parts/Arrow2";
import { MoreBtnContainer } from "components/parts/MoreBtn";
import { PlayBtnContainer } from "components/parts/PlayBtn";
import { TouchBtnContainer } from "components/parts/TouchBtn";

const FontColor1 = "#003087";

const Scale = (dimensions) => {
  if (!dimensions) return 1;

  const scaleX = dimensions.width / 1024;
  const scaleY = dimensions.height / 724;

  return scaleX < scaleY ? scaleX : scaleY;
};

const StyledSlide = styled.div`
  h1 {
    margin: 50px 50px 0 50px;
  }

  h2 {
    margin: 15px 50px 0 50px;
  }

  h1,
  .count,
  .color1 {
    color: ${FontColor1};
  }

  .color2 {
    color: ${(props) => (props.theme.color2 ? props.theme.color2 : "#303030")};
  }

  .color3 {
    color: ${(props) => (props.theme.color3 ? props.theme.color3 : "inherit")};
  }

  .color4 {
    color: ${(props) => (props.theme.color4 ? props.theme.color4 : "inherit")};
  }

  .count {
    font-weight: 600;
  }

  .centered {
    position: absolute;
    transform: scale(${(props) => Scale(props.dimensions)})
      translate(-50%, -50%);
    transform-origin: 0% 0%;
    width: 1024px;
    height: 639px;
    left: 50%;
    top: 50%;
    margin-top: -42px;
  }

  > .bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 85px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &.right {
      background-size: contain;
      background-position: right top;
    }

    &.left {
      background-size: contain;
      background-position: left top;
    }

    &.full {
      bottom: 0;
    }
  }

  ${ArrowContainer},
  ${Arrow2Container},
    ${MoreBtnContainer},
    ${PlayBtnContainer},
    ${TouchBtnContainer} {
    .fill {
      fill: ${(props) => props.theme.color1};
    }

    .stroke {
      stroke: ${(props) => props.theme.color1};
    }
  }

  ${Container} {
    &.bg1 {
      background: ${(props) => props.theme.bg1};
    }

    &.bg2 {
      background: ${(props) => props.theme.bg2};
    }

    &.bg3 {
      background: ${(props) => (props.theme.bg3 ? props.theme.bg3 : "#00C9EE")};
    }

    &.rounded {
      border: 1.8px ${FontColor1} solid;
    }

    &.flex {
      display: flex;
    }
  }

  ${Image} {
    &.border {
      border-color: ${FontColor1};
    }
  }

  ${Overlay} {
    &.with-bg::before {
      background: ${(props) => props.theme.color1};
    }

    &.with-bg.bg3::before {
      background: ${(props) => props.theme.bg3};
    }
  }

  .color-overlay {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: ${(props) => props.theme.color1};
  }

  a {
    color: #4f5cd6;
  }

  .container-animate {
    position: absolute;
    left: 0;
    top: 0;
  }

  .fadeIn {
    ${({ preview }) =>
      !preview &&
      css`
        opacity: 0;
      `}

    ${({ active, preview }) =>
      active &&
      !preview &&
      css`
        animation: ${fadeIn} 1s forwards ease-out;
        animation-delay: 0.6s;
      `}
  }

  .fadeOut {
    ${({ preview }) =>
      preview &&
      css`
        opacity: 0;
      `}

    ${({ active, preview }) =>
      active &&
      !preview &&
      css`
        animation: ${fadeOut} 1s forwards ease-out;
        animation-delay: 0.6s;
      `}
  }

  .fadeInFromTop {
    ${({ preview }) =>
      !preview &&
      css`
        opacity: 0;
      `}

    ${({ active, preview }) =>
      active &&
      !preview &&
      css`
        animation: ${fadeInFromTop} 1s forwards ease-out;
        animation-delay: 0.6s;
      `}
  }

  .fadeInFromLeft {
    ${({ preview }) =>
      !preview &&
      css`
        opacity: 0;
      `}

    ${({ active, preview }) =>
      active &&
      !preview &&
      css`
        animation: ${fadeInFromLeft} 1s forwards ease-out;
        animation-delay: 0.6s;
      `}
  }

  .fadeInFromRight {
    ${({ preview }) =>
      !preview &&
      css`
        opacity: 0;
      `}

    ${({ active, preview }) =>
      active &&
      !preview &&
      css`
        animation: ${fadeInFromRight} 1s forwards ease-out;
        animation-delay: 0.6s;
      `}
  }

  .scaleIn {
    ${({ preview }) =>
      !preview &&
      css`
        transform: scale(0);
      `}

    ${({ active, preview }) =>
      active &&
      !preview &&
      css`
        animation: ${scaleIn} 0.6s forwards ease-out;
        animation-delay: 0.6s;
      `}
  }

  .breatheAnimation {
    ${({ active, preview }) =>
      active &&
      !preview &&
      css`
        animation: ${breatheAnimation} 2s infinite;
      `}
  }

  .animateNoDelay {
    animation-delay: 0s;
  }

  .animate2 {
    animation-delay: 1.6s !important;

    &.animateNoDelay {
      animation-delay: 1s !important;
    }
  }

  .animate3 {
    animation-delay: 2.6s !important;
  }

  .animate4 {
    animation-delay: 3.6s !important;
  }

  .animate5 {
    animation-delay: 4.6s !important;
  }

  .animate6 {
    animation-delay: 5.6s !important;
  }
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {  
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {  
        opacity: 0;
    }
`;

const fadeInFromTop = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {  
        opacity: 1;
        transform: translateY(0);
    }
`;

const fadeInFromLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {  
        opacity: 1;
        transform: translateX(0);
    }
`;

const fadeInFromRight = keyframes`
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {  
        opacity: 1;
        transform: translateX(0);
    }
`;

const scaleIn = keyframes`
    0% {
        transform: scale(0);
    }
    70% {  
        transform: scale(1.1);
    }

    100% {  
        transform: scale(1);
    }
`;

const breatheAnimation = keyframes`
    0% {
        transform: scale(1)
    }
    60% {
        transform: scale(1.2)
    }
    100% {  
        transform: scale(1)
    }
`;

const Slide = ({ ...props }) => {
  console.log("render slide");

  return (
    <StyledSlide
      style={props.style}
      dimensions={props.dimensions}
      active={props.active}
      preview={props.preview}
      theme={props.theme}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      {props.children}
    </StyledSlide>
  );
};

function areEqual(prevProps, nextProps) {
  console.log(prevProps);

  if (prevProps.dimensions !== nextProps.dimensions) {
    return false;
  }

  if (prevProps.active !== nextProps.active) {
    return false;
  }

  if (prevProps.step !== nextProps.step) {
    return false;
  }

  if (prevProps.folder !== nextProps.folder) {
    return false;
  }

  if (prevProps.folders !== nextProps.folders) {
    return false;
  }

  return false;
}

export default Slide;
//export default React.memo(Slide, areEqual);
