import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';
import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';
import icon5 from './assets/icon5.svg';
import icon6 from './assets/icon6.svg';
import icon7 from './assets/icon7.svg';
import icon8 from './assets/icon8.svg';
import icon9 from './assets/icon9.svg';

const Slide = styled.div`
    .box1 {
        padding-left: 100px;
    }


    .box3 {
        color: #fff;

        ul {
            list-style: disc;
            padding-left: 50px;
        }
    }

    .box4 {
        align-items: center;
        justify-content: flex-start;

        img {
            width: 65px;
            margin-left: 17px;
        }

        p {
            margin-left: 2px;
            font-size: 16.5px;
            line-height: 16.9px;
        }
    }

    .number {
        position: absolute;
        background: #00A4E3;
        color: #fff;
        border-radius: 50%;
        font-weight: 600;
        text-align: center;
    }

    .number1 {
        left: -1.8px;
        top: -1.8px;
        width: 83px;
        height: 83px;
        font-size: 62px;
        line-height: 83px;
    }

    .number3,
    .number4 {
        height: 40px;
        width: 40px;
        line-height: 42px;
        font-size: 30px;
    }

    .number3 {
        left: 86.5px;
        top: 64px;
    }

    .number4 {
        left: 494px;
        top: 64px;
    }
`
const inititialState = {
    overlay1: 'overlay1'
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})`, bottom: 0 }}></div>
            <Slide className="centered">
                <h1>Wie erhöhen Sie in der heutigen Zeit<br />
die Wahrnehmung Ihrer Kompetenz und<br />
                    <strong>schaffen Vertrauen und Sicherheit<br />
beim potenziellen Träger?</strong></h1>

                <Container left={100} top={518} width={795} height={166} className="rounded box1 color1 flex-all-center">
                    <span className="number number1">1</span>
                    <p><strong>Fachliche Kompetenz</strong><br /> 
betonen / stärken</p>
                </Container>

                <Container left={100} top={846} width={795} height={166} className="rounded box1 color1 flex-all-center">
                    <span className="number number1">2</span>
                    <p>Den Kunden <strong>über den<br /> 
Prozess hinweg begleiten</strong></p>
                </Container>

                <Arrow left={982} top={518} width={494} />

                <MoreBtn
                    left={1064} top={728}

                    onClick={() => toggleOverlay('overlay1')}
                    className="more1" />

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg bg-white">
                    <Container left={100} top={100} width={1848} height={1120} className="bg3" />

                    <Image left={150} top={242} width={126} height={126} src={icon1} imageWidth={77} className="rounded bg-white image-center" />
                    <Container left={298} top={242} width={660} className="box3">
                        <p><strong>Bedürfnisorientiert<br />
anpassen &amp; kommunizieren</strong><br />
(Shopper &amp; Produkt)</p>
                    </Container>

                    <Image left={150} top={407} width={126} height={126} src={icon2} imageWidth={102} className="rounded bg-white image-center" />
                    <Container left={298} top={407} width={660} className="box3">
                        <p><strong>Astigmatische &amp; multifokale<br />
                        Anpassung</strong> verstärkt<br />
berücksichtigen</p>
                    </Container>

                    <Image left={150} top={573} width={126} height={126} src={icon3} imageWidth={71} className="rounded bg-white image-center" />
                    <Container left={298} top={595} width={660} className="box3">
                        <p><strong>Mitarbeiter Kompetenz stärken </strong></p>
                    </Container>

                    <Image left={966} top={242} width={126} height={126} src={icon4} imageWidth={81} className="rounded bg-white image-center" />
                    <Container left={1108} top={264} width={830} className="box3">
                        <p><strong>Einfacher und einheitlicher Prozess vor, während und nach der Anpassung</strong></p>
                    </Container>

                    <Image left={966} top={407} width={126} height={126} src={icon5} imageWidth={87} className="rounded bg-white image-center" />
                    <Container left={1108} top={451} width={830} className="box3">
                        <p><strong>Terminverbindlichkeit</strong></p>
                    </Container>

                    <Image left={966} top={573} width={126} height={126} src={icon6} imageWidth={77} className="rounded bg-white image-center" />
                    <Container left={1108} top={595} width={830} className="box3">
                        <p><strong>Bedenken &amp; Ängsten begegnen</strong></p>
                    </Container>

                    <Image left={966} top={845} width={126} height={126} src={icon7} imageWidth={86} className="rounded bg-white image-center" />
                    <Container left={1108} top={871} width={830} className="box3">
                        <p><strong>Kommunikation aufrecht erhalten –<br />
Nach-Kauf-Kommunikation</strong></p>
                        <ul>
                            <li>Sofort-Kauf</li>
                            <li>Newsletter</li>
                            <li>Check-up Termin<br />
mit Incentivierung</li>
                        </ul>
                    </Container>

                    <Container left={296} top={690} width={510} height={126} className="rounded flex box4 color1">
                        <img src={icon8} />
                        <p>Nutzen Sie unsere
Online-Seminare</p>
<a href="https://www.jnjvisioncare.de/online-seminare" target="_blank" className="clickArea" />
                    </Container>

                    <Container left={1108} top={690} width={550} height={126} className="rounded flex box4 color1">
                        <img src={icon9} />
                        <p>Hier geht es zum Auf-<br /> 
und Absetz-Training</p>
                        <a href="https://www.acuvue.de/kontaktlinsen-pflege/kontaktlinsen-aufsetzen" target="_blank" className="clickArea" />
                    </Container>

                    <span className="number number3">1</span>
                    <span className="number number4">2</span>

                    <CloseBtn
                        left={1853} top={127}

                        onClick={() => toggleOverlay('overlay1')}
                        className="more1" />

                </Overlay>

            </Slide>
        </>
    );
};
