import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

const Slide = styled.div`
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Was hat sich für uns alle geändert <strong>seit Beginn der<br />
COVID-19-Pandemie?</strong></h1>

            <Image left={160} top={294} width={326} height={326} src={img1} className="border rounded" />
            <Image left={632} top={294} width={326} height={326} src={img2} className="border rounded" />
            <Image left={1102} top={294} width={326} height={326} src={img3} className="border rounded" />
            <Image left={1572} top={294} width={326} height={326} src={img4} className="border rounded" />

            <Container left={116} top={594} width={419} height={113} className="rounded rounded-full color1 flex-all-center text-center">
                <p><strong>Lebensstil</strong></p>
            </Container>

            <Container left={585} top={594} width={419} height={113} className="rounded rounded-full color1 flex-all-center text-center">
                <p><strong>Prioritäten</strong></p>
            </Container>

            <Container left={1055} top={594} width={419} height={113} className="rounded rounded-full color1 flex-all-center text-center">
                <p><strong>Einkaufen</strong></p>
            </Container>

            <Container left={1525} top={594} width={419} height={113} className="rounded rounded-full color1 flex-all-center text-center">
                <p><strong>Beziehungen</strong></p>
            </Container>

            <div className="container-animate fadeInFromTop">

            <Arrow left={1002} top={500} width={584} className="rotate90" />

            <Container left={279} top={850} width={1490} height={130} className="rounded rounded-full color1 flex-all-center text-center">
                <p>Die <strong>Unterstützung lokaler Unternehmen</strong> scheint<br />
                    <strong>immer wichtiger zu werden.</strong></p>
            </Container>
            </div>

            <Container left={279} top={1029} width={1490} height={160} className="bg1 color1 flex-all-center text-center fadeInFromTop animate2" >
                <Arrow width={584} className="container-top" />
                <p><strong>61%</strong> der Befragten glauben, dass sie <strong>lokale Unternehmen unterstützen</strong><br />
                müssen, und <strong>56%</strong> halten <strong>die Gemeinschaft für wichtig<sup>1</sup></strong></p>
            </Container>

        </Slide>
    );
};
