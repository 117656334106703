import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
   
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} active={props.active}>
            <h1>Kontaktlinsen beeinflussen den <strong>Tränenfilm</strong></h1>

            <Container as="p" left={146} top={373} width={1170} className="text-center color1">
            <strong>Der Tränenfilm:<br />  
So viel mehr als einfach nur Wasser.</strong><br />  
Der Tränenfilm ist das natürliche Schmiermittel des<br />  
Auges. Er ist ein komplexes System und besteht aus<br />  
mehreren Schichten und Komponenten.<sup>3-9</sup>
            </Container>

            <Container left={146} top={712} width={1170} height={261} className="bg1 flex-all-center text-center color1 fadeInFromTop">
                <Arrow width={1010} className="container-top" />
                <p>Die Aufrechterhaltung eines stabilen Tränenfilms<br /> 
ist <strong>entscheidend für den Tragekomfort und<br /> 
die Sehqualität!</strong></p>
            </Container>

            
            <Image left={1376} top={266} width={880} height={880} src={img2} />
            <Image left={1192} top={824} width={244} height={355} src={img1} className="fadeIn" />
        </Slide>
    );
};
