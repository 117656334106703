import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.svg';

const graphAnimation = keyframes`
    0% {
        width: 0;
    }
    100% {  
        width: 557.3px;
    }
`

const Slide = styled.div`
    .box1 {
        padding-left: 25px;
    }

    .box2 {
        padding-left: 29px;
    }

    .graph {
        overflow: hidden;
        width: 0;

        ${({ preview }) => preview && css`
            width: 557.3px;
        `}
        
        ${({ active }) => active && css`
            animation: ${graphAnimation} 2s forwards;
            animation-delay: 0.3s;
        `}
    }

    .graph img {
        position: absolute;
        left: 0;
        top: 0;
        width: 557.3px;
    }

    .percent {
        font-size: 27px;
        line-height: 34px;
        font-weight: 600;
        font-style: italic;
    }

    .percent1,
    .percent4 {
        color: #00a3e0;
    }

    .percent2,
    .percent5 {
        color: #a7a9ac;
    }

    .percent3,
    .percent6 {
        color: #009ca6;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(70, 36, props.active, props.preview)
    const count2 = useCounter(59, 28, props.active, props.preview)
    const count3 = useCounter(43, 20, props.active, props.preview)

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1>Was wollen Kunden? <strong>Langanhaltender<br /> 
Tragekomfort und Sehqualität</strong></h1>
            
            <Container left={475.3} top={582} width={1114.6} height={550.8} className="graph graph2">
                <img src={img3} />
            </Container>
            <Container left={475.3} top={582} width={1114.6} height={550.8} className="graph graph1">
                <img src={img4} />
            </Container>
            <Container left={475.3} top={582} width={1114.6} height={550.8} className="graph graph3">
                <img src={img2} />
            </Container>
            <Image left={145.6} top={289} width={1756.8} height={923.8} src={img1} />

            <Container left={145} top={289} width={382} height={119} className="flex-all-center box1">
                <p className="small color2"><strong>% Top Box</strong><br />
(5-Punkteskala)</p>
            </Container>
           
            <Container left={426} top={454} width={101} height={101} className="flex-all-center text-center small">80%</Container>
            <Container left={426} top={618} width={101} height={101} className="flex-all-center text-center small">60%</Container>
            <Container left={426} top={782} width={101} height={101} className="flex-all-center text-center small">40%</Container>
            <Container left={426} top={946} width={101} height={101} className="flex-all-center text-center small">20%</Container>

            <Container left={562} top={1084} width={101} height={101} className="flex-all-center text-center small">4h</Container>
            <Container left={726} top={1084} width={101} height={101} className="flex-all-center text-center small">6h</Container>
            <Container left={890} top={1084} width={101} height={101} className="flex-all-center text-center small">8h</Container>
            <Container left={1054} top={1084} width={101} height={101} className="flex-all-center text-center small">10h</Container>
            <Container left={1218} top={1084} width={101} height={101} className="flex-all-center text-center small">12h</Container>
            <Container left={1382} top={1084} width={101} height={101} className="flex-all-center text-center small">14h</Container>

            <Container left={1540} top={1051} width={361} height={160} className="flex-all-center box2">
                <p className="small color2"><strong>Tragedauer<br />
Kontaktlinsen</strong><br />
(in Stunden)</p>
            </Container>

            <Container left={1594} top={859} width={250} className="flex-all-center color1 small"><p><strong>Sehqualität</strong></p></Container>
            <Container left={1594} top={932} width={250} className="flex-all-center color1 small"><p><strong>Zufriedenheit</strong></p></Container>
            <Container left={1594} top={978} width={250} className="flex-all-center color1 small"><p><strong>Tragekomfort</strong></p></Container>

            <Container left={554} top={540} width={120} className="flex-all-center percent percent1">70%</Container>
            <Container left={554} top={646} width={120} className="flex-all-center percent percent2">59%</Container>
            <Container left={554} top={750} width={120} className="flex-all-center percent percent3">43%</Container>

            <Container left={1444} top={806} width={120} className="flex-all-center percent percent4">{count1}%</Container>
            <Container left={1444} top={885} width={120} className="flex-all-center percent percent5">{count2}%</Container>
            <Container left={1444} top={1006} width={120} className="flex-all-center percent percent6">{count3}%</Container>
            
        </Slide>
    );
};
