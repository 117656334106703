import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'
import { customFoldersConstants } from '../constants';
import { fetchData } from '../api';

const getState = state => state.authentication;

export function* handleSave(payload) {

    try {

        const items = yield select(getState);

        let payloadNew = {
            action: 'saveFolder',
            email: items.email,
            token: items.token,
            folder: payload.folder,
            slides: payload.slides
        }

        console.log(payloadNew)

        let { data } = yield call(fetchData, payloadNew);

        yield put({ type: customFoldersConstants.SAVEFOLDER_SUCCESS, folder: payload.folder, slides: payload.slides  })

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }

}

export function* handleGetFolders(payload) {

    try {

        const items = yield select(getState);

        let payloadNew = {
            action: 'getFolders',
            email: items.email,
            token: items.token,
        }

        let { data } = yield call(fetchData, payloadNew);

        yield put({ type: customFoldersConstants.GETFOLDERS_SUCCESS, folders: data.success.data })

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }
}

/* */

export function* handleSaveName(payload) {

    try {

        const items = yield select(getState);

        let payloadNew = {
            action: 'saveFolderName',
            email: items.email,
            token: items.token,
            folder: payload.folder,
            foldername: payload.foldername
        }

        console.log(payloadNew)

        let { data } = yield call(fetchData, payloadNew);

        yield put({ type: customFoldersConstants.SAVEFOLDERNAMES_SUCCESS, folder: payload.folder, foldername: payload.foldername  })

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }

}

export function* handleGetFoldersNames(payload) {

    try {

        const items = yield select(getState);

        let payloadNew = {
            action: 'getFoldersNames',
            email: items.email,
            token: items.token,
        }

        let { data } = yield call(fetchData, payloadNew);

        yield put({ type: customFoldersConstants.GETFOLDERSNAMES_SUCCESS, folders: data.success.data })

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }

}

export default function* watchConfigLoad() {
    yield takeEvery(customFoldersConstants.SAVEFOLDER_REQUEST, handleSave);
    yield takeEvery(customFoldersConstants.GETFOLDERS_REQUEST, handleGetFolders);

    yield takeEvery(customFoldersConstants.SAVEFOLDERNAMES_REQUEST, handleSaveName);
    yield takeEvery(customFoldersConstants.GETFOLDERSNAMES_REQUEST, handleGetFoldersNames);
}