import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    h1 {
       color: #0a7cc1; 
    }

    h2 {
        margin: 20px 0 10px 0;
        font-size: 20px;
        line-height: 24px;
        color: #0a7cc1;
    }

    .list {
        display: flex;

        .symbol {
            flex: 0 0 18px;
        }
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
                <p className="list"><span className="symbol">*</span> Im Vergleich zu ACUVUE® OASYS 1-Day</p>

                <p className="list"><span className="symbol">**</span> Ein Nutzen der Filterung von HEV-Licht durch Kontaktlinsen für die systemische und/oder die Augengesundheit des Anwenders ist nicht nachgewiesen.<br />
                    Für weitere Informationen wenden Sie sich an den Kontaktlinsenspezialisten.</p>

                <p className="list"><span className="symbol">***</span> Auch signifikant niedriger als bei ACUVUE® OASYS 1-Day.</p>

                <p className="list"><span className="symbol">#</span> Im Vergleich zu DAILIES TOTAL1®, My Day® und ULTRA® ONE DAY. Auch signifikant niedriger als bei ACUVUE® OASYS 1-Day.</p>

                <p className="list"><span className="symbol">†</span> Der Anteil der Träger, die Tränenfilm-Aufrisszeiten von ≥ 10 Sekunden erreichten, war höher als bei ACUVUE® OASYS 1-Day</p>

                <p className="list"><span className="symbol">^</span> Bezogen auf den öffentlich zugänglichen Kenntnisstand vom Mai 2022</p>

                <p className="list"><span className="symbol">§</span> Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2 UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.<br />
                    UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken.<br />
                    UV-Transmission mit –1,00 Dioptrien Linse gemessen.</p>

                <p className="list"><span className="symbol">‡</span> Helps protect against transmission of harmful UV radiation to the cornea and into the eye.</p>

                <p className="list"><span className="symbol">+</span> Bezogen auf den öffentlich zugänglichen Kenntnisstand vom Mai 2022</p>

                <p className="list"><span className="symbol">¥</span> DAILIES Total1® for ASTIGMATISM</p>

                <p className="list"><span className="symbol">¶</span> Informationen von der Wettbewerber-Website im Juli 2022</p>

                <p className="list"><span className="symbol">±</span>  n = 172</p>

                <p className="list"><span className="symbol">°</span>  n = 378</p>

                <p className="list"><span className="symbol">ff</span> Im Vergleich zum Wettbewerber-Design; technologisch optimiert für sowohl Fehlsichtigkeit als auch Addition.</p>
                <p className="list"><span className="symbol">'</span> 35% stimmen voll zu, 45% stimmen für ACUVUE® OASYS MAX 1-Day</p>

                <p className="list"><span className="symbol">"</span> 45% exzellent, 35% sehr gut</p>

                <p className="list"><span className="symbol">¯</span> 41% exzellent, 34% sehr gut</p>

                <p className="list"><span className="symbol"><sup>@</sup></span>  8/10 Träger empfinden die Handhabung der Kontaktlinsen als außerordentlich gut.</p>


                <p className="list"><span className="symbol">¢</span> Meta-analysis involving 6 studies (n=418). </p>

                <p className="list"><span className="symbol">≈</span> Randomized control trial (n=340). </p>

                <p className="list"><span className="symbol">×</span> Meta-analysis involving 6 studies (n=789).</p>

                <p className="list"><span className="symbol">£</span>Meta-analysis involving 6 studies (n=645).</p>

                <p className="list"><span className="symbol">@</span> Taken from Alcon website date 11/01/2023 www.myalcon.com/uk/professional/contact-lenses/daily/dailies-total1/</p>

                <p className="list"><span className="symbol">≠</span>Versus publicly available information for standard daily use contact lenses as of July 2022.</p>

                <p className="list"><span className="symbol">****</span> In DT1 Astigmatism product 33% blue light filter from Alcon website date 11/01/2023 www.myalcon.com/uk/professional/contact-lenses/ daily/dailies-total1/.</p>

                <p className="list"><span className="symbol">*****</span> In DT1 Astigmatism product from Alcon website date 11/01/2023 www.myalcon.com/uk/professional/contact-lenses/daily/dailies-total1/.</p>



                <p className="list"><span className="symbol">† †</span> n=449.</p>

                <p className="list"><span className="symbol">§ §</span> n=593.</p>












                <p className="list"><span className="symbol">1.</span>  JJV Data on File. CSM Subjective Responses ACUVUE® OASYS MAX 1-Day Contact Lenses- Retrospective Meta-analysis</p>

                <p className="list"><span className="symbol">2.</span>  COVID-19: Screen Time spikes to over 13 hours per day according to Eyesafe Nielsen estimates, published 3/28/20, https://eyesafe.com/covid-19-screen-time-spike-to-over-13-hours-per-day/</p>

                <p className="list"><span className="symbol">3.</span>  Tsubota K, Nakamori K. Dry eyes and video display terminals. N Engl J Med. 1993;328(8):584. doi: 10.1056/NEJM199302253280817.</p>

                <p className="list"><span className="symbol">4.</span>  Patel S, Henderson R, Bradley L, et al. Effect of visual display unit use on blink rate and tear stability. Optom Vis Sci 1991;68(11):888-892. doi: 10.1097/00006324-199111000-00010.</p>

                <p className="list"><span className="symbol">5.</span>  JV Data on File 2022. Blue-Violet Filter Utilized in ACUVUE® OASYS MAX 1-Day Contact Lenses.</p>

                <p className="list"><span className="symbol">6.</span>  Eyesafe estimate based upon Nielsen Q3 2019 Total Audience Report</p>

                <p className="list"><span className="symbol">7.</span>  JJV data on file, survey fielded to 468 contact lens wearing patients in the US in April 2022</p>

                <p className="list"><span className="symbol">8.</span>  JJV Data on File 2022. TearStable™ Technology Definition</p>

                <p className="list"><span className="symbol">9.</span>  JJV Data on File 2022. Effect on Tear Film and Evaluation of Visual Artifacts of ACUVUE® OASYS MAX 1-Day Family with TearStable™ Technology.</p>

                <p className="list"><span className="symbol">10.</span>  JJV Data on File 2022. Material Properties: 1-DAY ACUVUE® MOIST, 1-DAY ACUVUE® TruEye®, ACUVUE® OASYS 1-Day with HydraLuxe™ Technology and ACUVUE® OASYS MAX 1-Day with TearStable Technology Brand Contact Lenses and other daily disposable contact lens brands.</p>

                <p className="list"><span className="symbol">11.</span>  JJV Data on File. Transmittance Characteristics Memo for Commercial Lenses.</p>

                <p className="list"><span className="symbol">12.</span>  JJV Data on File 2022. Blue-Violet Filter Utilized in ACUVUE® OASYS MAX 1-Day Contact Lenses.</p>

                <p className="list"><span className="symbol">13.</span>  JJV Data on File, 2022. CSM Subjective Responses ACUVUE® OASYS MAX 1-Day Contact Lenses- Retrospective Meta-analysis.</p>

                <p className="list"><span className="symbol">14.</span>  JJV Data on File. CSM Subjective Responses ACUVUE® OASYS MAX 1-Day Contact Lenses- Retrospective Meta-analysis.</p>

                <p className="list"><span className="symbol">15.</span>  JJV Data on file 2022. CSM- ACUVUE® PUPIL OPTIMIZED DESIGN Technology: JJVC contact lenses, design features, and associated benefits.</p>

                <p className="list"><span className="symbol">16.</span>  JJV Data on File 2022: Stand-Alone Fit Success Claims for ACUVUE® OASYS MAX 1-Day MULTIFOCAL Contact Lenses</p>

                <p className="list"><span className="symbol">17.</span>  JJV Data on File 2022. ACUVUE® PUPIL OPTIMIZED DESIGN Technology: JJVC Contact Lenses, Design Features, and Associated Benefits.​</p>

                <p className="list"><span className="symbol">18.</span>  JJV Data on File 2022. Subjective Stand-Alone Claims for ACUVUE® OASYS MAX 1-Day MULTIFOCAL Contact Lenses - Exploratory Meta-analysis.</p>

                <p className="list"><span className="symbol">19.</span>  JJV Data on File 2022. Comparative Subjective Claims for ACUVUE® OASYS MAX 1-Day lens vs DAILIES TOTAL1® and Additional Stand-Alone Claims.</p>

                <p className="list"><span className="symbol">20.</span>  Archivierte JJV-Daten 2022. Wirkung auf den Tränenfilm und Bewertung visueller Artefakte mit der ACUVUE® OASYS MAX 1-Day-Produktreihe.</p>

                <p className="list"><span className="symbol">21.</span>  Archivierte JJV-Daten. CSM Subjektive Reaktionen ACUVUE® OASYS MAX 1-Day-Kontaktlinsen – Retrospektive Metaanalyse.</p>

                <h2><strong>MAX</strong> Tragekomfort, <strong>MAX</strong> Sehqualität im Vergleich zu ACUVUE® OASYS 1-Day </h2>

                <p>Ein Nutzen der Filterung von HEV-Licht durch Kontaktlinsen für die systemische und/oder die Augengesundheit des Anwenders<br />
                    ist nicht nachgewiesen. Für weitere Informationen wenden Sie sich an den Kontaktlinsenspezialisten.</p>

                <p><strong>Wichtige Sicherheitsinformationen:</strong> Kontaktlinsen der Marke ACUVUE® dienen zur Korrektion des Sehvermögens. Wie bei allen Kontaktlinsen können Probleme mit den Augen,<br />
                    einschließlich Hornhautgeschwüre, auftreten. Bei manchen Kontaktlinsenträgern können leichte Reizungen, Jucken oder Unbehagen auftreten. Kontaktlinsen sollten nicht bei<br />
                    Augeninfektionen oder anderen Augenerkrankungen oder bei systemischen Erkrankungen, die das Auge beeinträchtigen können, verwendet werden. Vollständige Informationen,<br />
                    einschließlich Kontraindikationen, Vorsichtsmaßnahmen und Nebenwirkungen, entnehmen Sie der Gebrauchsanweisung oder besuchen Sie unsere J&J-Website jnjvisioncare.de/at.</p>

                <p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse-1- oder Klasse 2-UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.<br />
                    UV-absorbierende Kontaktlinsen sind KEIN Ersatz für UV-absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken. UV-Transmission
                    mit –1,00-dpt-Linse gemessen.</p>

                <p>Alle hier genannten Fremdmarken sind Eigentum des jeweiligen Markeninhabers.</p>

                <p>ACUVUE®, ACUVUE® OASYS MAX 1-Day, ACUVUE® OASYS MAX 1-Day MULTIFOCAL, TearStable™ und OptiBlue™ sind eingetragene Marken von Johnson & Johnson.<br />
                    © Johnson & Johnson Medical GmbH / Medical Products GmbH 2023</p>
            </TextBox>
        </Slide>
    );
};
