import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
    .box1 {
        font-size: 10px;
        line-height: 12px;
    }

    .box2 {
        background: #009ca6;
        border-radius: 6px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Wie könnte <strong>schärfer, heller, klarer</strong> aussehen?</h1>

            <Container as="p" left={437} top={270} width={382} height={119} className="rounded rounded-full flex-all-center text-center small">
                Ausgangssituation
            </Container>

            <Container as="p" left={1155} top={270} width={526} height={119} className="rounded rounded-full flex-all-center text-center small">
                <strong> Verbesserter Kontrast mit<br />
                    ACUVUE® OASYS 1-Day</strong>
            </Container>

            <Container left={241} top={493} width={774} height={550} className="box2" />
            <Container left={1031} top={493} width={774} height={550} className="box2" />

            <Image src={img1} left={246} top={498} width={764} height={540} />
            <Image src={img2} left={1036} top={498} width={764} height={540} />

            <Container as="p" left={787} top={1102} width={472} className="text-center box1">
                Für Illustrationszwecke
            </Container>

        </Slide>
    );
};
