import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';


const Slide = styled.div`

    .box2 {
        ul {
            list-style: disc;
            margin: 0;
            
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
                width: fit-content;
            }
        }
        
    }
    
    .box3 {
        padding-top: 15px;

        > p {
            position: relative;
            z-index: 201;
        }
    }

    .box3 .number {
        position: absolute;
        display: block;
        left: 14px;
        top: -48px;
        font-size: 64px;
        line-height: 80px;
        color: #003087;
        font-style: italic;
        text-align: left;
    } 

    .overlay1 {
        z-index: 200;

        .box4 {
            background-size: cover;
        }
    }

    .overlay1-1 .box4 {
        background-image: url(${img1});
    }

    .overlay1-2 .box4 {
        background-image: url(${img2});
    }

    .overlay1-3 .box4 {
        background-image: url(${img3});
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Neue Kontaktlinsenkunden</strong> für das Optiker Geschäft</h1>

            <Container left={433} top={317} width={1184} height={159} className="rounded color1 flex-all-center text-center" as="p">
                <strong>Attraktive Kommunikation der Kompetenz<br />
                    für den POS, CRM und Social Media Kanäle</strong>
            </Container>

            <Container left={433} top={531} width={1184} height={290} className="box2 bg1 color1 flex-all-center text text-center fadeInFromTop">
                <Arrow width={1064} className="container-top" />
                <p><strong>Zielgruppe:</strong></p>
                <ul>
                    <li>Bestehende Träger aus anderen Kanälen</li>
                    <li>Unzufriedene Träger</li>
                    <li>an Kontaktlinsen interessierte Kunden</li>
                </ul>
            </Container>


            <Container left={190} top={978} width={537} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate2">
                <span className="number">1</span>
                <p><strong>POS</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'} />
            </Container>

            <Container left={755} top={978} width={537} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate3">
                <span className="number">2</span>
                <p><strong>Newsletter</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay2')}
                    active={state.overlay1 === 'overlay2'} />
            </Container>

            <Container left={1320} top={978} width={537} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate4">
                <span className="number">3</span>
                <p><strong>Online/SocialMedia</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay3')}
                    active={state.overlay1 === 'overlay3'} />
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 overlay1-1 with-bg">
                <Container left={190} top={317} width={537} height={793} className="box4 bg2 color1">
                </Container>
               

                <Container left={190} top={978} width={537} height={132} className="box3 box5 color1 text-center">
                    <p><strong>POS</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'} />
                </Container>


            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="overlay1 overlay1-2 with-bg">
                <Container left={755} top={317} width={537} height={793} className="box4 bg2 color1">
                </Container>
                

                <Container left={755} top={978} width={537} height={132} className="box3 box5 color1 text-center">
                    <p><strong>Newsletter</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay2')}
                        active={state.overlay1 === 'overlay2'} />
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay3'} className="overlay1 overlay1-3 with-bg">
                <Container left={1320} top={317} width={537} height={793} className="box4 bg2 color1">
                </Container>
                

                <Container left={1320} top={978} width={537} height={132} className="box3 box5 color1 text-center">
                    <p><strong>Online/SocialMedia</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay3')}
                        active={state.overlay1 === 'overlay3'} />
                </Container>

            </Overlay>

        </Slide>
    );
};
