import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
    .box1 {
        padding-left: 89px;
    }

    .box2 h3 {
        margin-top: 12px;
        font-size: 40px;
        line-height: 56px;
        font-weight: 600;
        text-align: center;
    }

    .box3 {
        .count {
           display: flex;
           margin-top: 52px;
           margin-left: 26px;
           width: 100px;
           height: 52px;
           font-size: 36px;
           line-height: 36px;
           justify-content: flex-end;

           span:nth-child(2) {
               font-size: 19px;
               align-self: center;
           }

           span:nth-child(3) {
               align-self: flex-end;
               
           }
       }
    }

    .box2 ul {
        margin-left: 22px;
        margin-top: 10px;
        padding-left: 20px;
        
        li {
            position: relative;

            + li {
                margin-top: 17px;
            }
        }

        li::before {
            position: absolute;
            left: -20px;
            top: 0;
            content:"·";
            font-size:60px;
            vertical-align:middle;
            
        }
    }

    .box2-4 ul {
        margin-left: 75px;
        padding-left: 0;

        li {
            text-indent: 20px;
        }
    } 

    .container-animate {
        ${({ step }) => step > 1 && css`
            display: none;
        `}
    }

    .box2-3,
    .box2-4 {
        animation-play-state: paused;

        ${({ step }) => step !== 1 && css`
            animation-play-state: running;
        `}

        ${({ preview }) => preview && css`
            display: none;
        `}
    }

   

`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 9, props.active, props.preview)
    const count2 = useCounter(0, 8, props.active, props.preview)

    return (

        <Slide className="centered" step={props.step} preview={props.preview}>
            <h1>Die Kunden sind mit <strong>der Sehqualität, dem Tragekomfort und der<br />
            Handhabung von ACUVUE® OASYS 1-Day sehr zufrieden</strong></h1>

            <Container left={513} top={325} width={1049} height={243} className="rounded rounded-full flex-all-center box1 color1">
                <p>Eine Marktstudie in Frankreich brachte<br />
für die <strong>ACUVUE® OASYS 1-Day</strong> folgende<br />
Ergebnisse:<sup>ff,23</sup></p>
            </Container>

            <Image left={128} top={356} width={534} height={175} src={img1} />

            <div className="container-animate fadeInFromTop">

            <Container left={201} top={625} width={800} height={517} className="bg1 box2 box2-1">
                <Arrow width={716} className="container-top" />
                <h3 className="color1">Neue Träger</h3>
            </Container>

            <Container left={1055} top={625} width={800} height={517} className="bg1 box2 box2-2">
                <Arrow width={716} className="container-top" />
                <h3 className="color1">Wieder-Einsteiger</h3>
            </Container>

            <CircleCount left={448} top={778} width={315} percent={count1*10} color={'#df9933'} />
            <CircleCount left={1303} top={778} width={315} percent={count1*10} color={'#c20016'} />

            <Container left={448} top={778} width={315} percent={count1} className="box3">
                <span className="count">
                    <span>+{count1}</span>
                    <span>/</span>
                    <span>10</span>
                </span>
            </Container>

            <Container left={1303} top={778} width={315} percent={count1} className="box3">
                <span className="count">
                    <span>+{count2}</span>
                    <span>/</span>
                    <span>10</span>
                </span>
            </Container>

            </div>

            <Container left={201} top={625} width={800} height={630} className="bg1 box2 box2-3 color1 fadeInFromTop animateNoDelay">
                <Arrow width={716} className="container-top" />
                <h3>Neue Träger</h3>
                <ul>
                    <li>Zufrieden <strong>mit dem<br /> 
Tragekomfort am Ende<br />
des Tages<sup>24</sup></strong></li>
                    <li>Zufrieden <strong>mit der<br /> 
Sehqualität am Ende<br /> 
des Tages<sup>24</sup></strong></li>
                </ul>
            </Container>

            <Container left={1055} top={625} width={800} height={630} className="bg1 box2 box2-4 color1 fadeInFromTop animateNoDelay">
                <Arrow width={716} className="container-top" />
                <h3>Wieder-Einsteiger</h3>
                <ul>
                    <li><strong>Wollten die Linsen</strong><br /> 
weiterhin verwenden<sup>24</sup></li>
                    <li>Stimmen ganz oder teilweise<br />
zu, dass der <strong>Tragekomfort den<br />
ganzen Tag über gut war<sup>24</sup></strong></li>
                    <li>Stimmen ganz oder<br /> 
teilweise zu, dass die Linse<br /> 
<strong>leicht in der Handhabung war<sup>24</sup></strong></li>
                </ul>
            </Container>

            <Image left={865} top={777} width={316} height={316} src={img2} className="rounded border border-white image2 fadeInFromTop" />

        </Slide>
    );
};
