import React, { useState } from 'react';
import styled from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'
import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';
import img1 from './assets/img1.jpg';

const Slide = styled.div`
    .count {
        font-size: 62px;
        height: 20px;

        span {
            font-size: 33.5px
        }
    }

   .box1 {
        align-items: center;
        justify-content: flex-start;

        img {
            margin-left: 18px;
            width: 76px;
        }

        .count {
            flex: 0 0 125px;
            text-align: right;
        }

        p {
            margin-left: 19px
        }
    }

    .box2 {
        padding-top: 127px;
    }

    .box3 {
        align-items: center;
        justify-content: flex-start;

        img {
            margin-left: 16px;
            width: 105px;
        }

        .count {
            margin-left: 16px;
        }
    }
`
const inititialState = {
    overlay1: false
}

const Folder2Slide2 = ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    const count1 = useCounter(0, 51, props.active, props.preview)
    const count2 = useCounter(0, 40, props.active, props.preview)
    const count3 = useCounter(0, 10, props.active, props.preview)
    const count4 = useCounter(0, 50, props.active, props.preview)

    return (
        <Slide className="centered">
            <h1>Die Ausstiegsquote aus der <strong>Kategorie Kontaktlinse<br />
                ist nach wie vor sehr hoch</strong><sup>11,12</sup></h1>
            <h2>Die Gründe für einen Ausstieg aus der Kategorie Kontaktlinse sind vielseitig:</h2>

            <Container left={190} top={416} width={795} height={165} className="rounded flex box1">
                <img src={icon1} />
                <span className="count">{count1}<span>%</span></span>
                <p className="color1">fehlender Tragekomfort<sup>11</sup></p>
            </Container>

            <Container left={190} top={623} width={795} height={165} className="rounded flex box1">
                <img src={icon2} />
                <span className="count">{count2}<span>%</span></span>
                <p className="color1">komplizierte Handhabung<sup>11</sup></p>
            </Container>

            <Container left={190} top={831} width={795} height={165} className="rounded flex box1">
                <img src={icon3} />
                <span className="count">{count3}<span>%</span></span>
                <p className="color1">schlechte Sehqualität<sup>11</sup></p>
            </Container>

            <div className="container-animate fadeInFromLeft">

                <Arrow left={1134} top={414} width={584} />

                <Container left={1214} top={435} width={673} height={542} className="bg1 color1 box2">
                    <p className="text-center"><strong>Jeder Zweite<br />
                        Kontaktlinsen-Neuträger</strong><br />
                        spricht Sie, als ihren<br />
                        Kontaktlinsenspezialisten,<br />
                        nicht auf diese Probleme an.</p>
                   
                </Container>

                <Container left={1280} top={492} width={540} height={166} className="rounded flex box3">
                    <img src={icon4} />
                    <span className="count">{count4}<span>%</span></span>
                </Container>
            </div>

            <MoreBtn
                        className="fadeInFromLeft"
                        left={1845} top={936}
                        active={state.overlay1 === 'overlay1'}
                        onClick={() => toggleOverlay('overlay1')} />

            <Overlay className="with-bg" active={state.overlay1 === 'overlay1'}>
                <Image left={540} top={369} width={805} height={805} src={img1} className="rounded border border-white" style={{borderWidth: '2.5px'}}/>
                <Container left={967} top={777} width={921} height={402} className="rounded flex-all-center color1 text text-center">
                    <p><strong>Entscheidender Erfolgsfaktor für die<br />
                        Kundenbindung:</strong></p>
                    <p>Stellen Sie die Zufriedenheit und die<br />
                        Möglichkeit einer guten, schnellen<br />
                        Kommunikation <strong>vom ersten Kontakt<br />
                        an sicher.</strong></p>
                </Container>
            </Overlay>
        </Slide>
    );
};

export default Folder2Slide2;