import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Arrow2, Arrow3, Container, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';
import img1 from './assets/img1.png';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
    > .box1 {
        padding-left: 178px;

        > img {
            position: absolute;
            left: 0;
            top: 0;
            width: auto;
            height: 100%;
        }

        .box2  {
            .count {
                margin-top: -20px;
                font-size: 54px;
                line-height: 66px;
            }

            p {
                position: absolute;
                left: 0;
                width: 100%;
                font-size: 18px;
                line-height: 20px;

                &.top {
                    top: 30px;
                }

                &.bottom {
                    top: 92px;
                }
            }

            &.box2-1 .count {
                letter-spacing: -0.25rem;
            } 
        }
    }

    > .box2 {
        padding-top: 25px;
    }

    > .box3 {
        padding-top: 25px;
    }

    .overlay1 .box1 {
        padding: 20px 0 0 42px;

        p + p {
            margin-top: 16px;
        }
    }

    .overlay2 .box1 {
        background-image: url(${img2});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right top;

        padding: 20px 0 0 42px;

        p + p {
            margin-top: 16px;
        }
    }
        
     
`
const inititialState = {
    overlay1: null
}


export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count = useCounter(0, 87, props.active, props.preview, 1)
    count = count / 10
    count = count.toFixed(1).toLocaleString('de-DE')

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1>Warum diese Kampagne <strong>ein Erfolg</strong> wird</h1>

                <Container left={108} top={237} width={1106} height={307} className="box1 flex-all-center">
                    <img src={img1} />
                    <Container left={0} top={0} width={307} height={307} className="box2 flex-all-center text-center ">

                        <span className="count">{count}</span>
                        <p className="bottom color1">Mio.</p>
                    </Container>
                    <p className="color1">… der über 45-Jährigen<br />
                        <strong>sind offen und interessiert<br />
                            an Kontaktlinsen</strong><sup>1</sup></p>
                </Container>

                <Container left={129} top={587} width={960} height={130} className="box2 bg1 text-center color1 fadeInFromTop animate2">
                    <Arrow width={800} className="container-top" />
                    <p>Das ungenutzte Potenzial ist riesig!</p>
                </Container>

                <Container left={129} top={767} width={960} height={450} className="box3 bg1 text-center color2 fadeInFromTop animate3">
                    <Arrow width={800} className="container-top" />
                    <p>Genau dieses Potenzial nutzt die<br />
                        ACUVUE® MULTIFOCAL Kampagne<br />
                        <strong>„So geht das neue Sehen”</strong><br /><br />

                        <strong>Ziel:</strong> Awareness-Steigerung für<br />
                        das Thema Presbyopie verbunden<br />
                        mit dem Call-to-Action:<br />
                        Jetzt unverbindlich Probetragen!</p>
                </Container>

                <MoreBtn
                    className="fadeInFromTop animate2"
                    left={1035} top={613}
                    active={state.overlay1 === 'overlay1'}
                    onClick={() => toggleOverlay('overlay1')} />

                <MoreBtn
                    className="fadeInFromTop animate3"
                    left={1035} top={839}
                    active={state.overlay1 === 'overlay2'}
                    onClick={() => toggleOverlay('overlay2')} />

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg overlay1 overlay1-1">
                    <Container left={129} top={559} width={948} height={659} className="box1 bg2 color1 border">
                        <p>Besonders die “duale Verwendung”<br />
                            und die Drop-Out-Minimierung<br />
                            treiben die multifokale Profitabilität.</p>

                        <p><strong>Die presbyope Zielgruppe …</strong></p>

                        <p>… ist besonders attraktiv und profitiert
                            durch die Vorzüge von multifokalen
                            Kontaktlinsen</p>

                        <p>… hat vielfältige Bedürnisse, von denen
                            einige nicht ausschließlich durch eine
                            Gleitsichtbrille befriedigt werden können</p>
                    </Container>

                    <Arrow left={160} top={876} width={64} className="" arrowColor={'#00a98f'} />
                    <Arrow left={160} top={1066} width={64} className="" arrowColor={'#00a98f'} />

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay2'} className="with-bg overlay2 overlay2-1">
                    <Container left={129} top={559} width={948} height={659} className="box1 bg2 color1 border">
                        <p><strong>Die Kampagne …</strong></p>

                        <p>… greift die typischen (presbyopen)
                            Momente auf und schafft Aufmerk-<br />
                            samkeit für das Thema Presbyopie</p>

                        <p>… visualisiert die Vorteile von<br />
                            multifokalen Kontaktlinsen und </p>

                        <p>… zeigt, wie man mit multifokalen
                            Kontaktlinsen diese Momente voll<br />
                            und ganz genießen kann</p>
                    </Container>

                    <Arrow left={160} top={701} width={64} className="" arrowColor={'#00a98f'} />
                    <Arrow left={160} top={875} width={64} className="" arrowColor={'#00a98f'} />
                    <Arrow left={160} top={997} width={64} className="" arrowColor={'#00a98f'} />

                </Overlay>



            </Slide>
        </>
    );
};
