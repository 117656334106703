import React from 'react';

const Logo = () => {
    return (
        <svg viewBox="0 0 292 52.59">
            <path d="M75.89,48.42c4.52,0,10.44-.8,13.84-4.38l.11.1v6.17a56.69,56.69,0,0,1-14.25,2.28c-13.84-.06-27.23-8.08-27.23-25.37,0-13,10.28-25.78,27.55-25.78A42.5,42.5,0,0,1,88.5,3.71v5l-.13.1C85.14,6,79.63,5,75,5,64,5,55.18,13.32,55.18,26.49c0,15.69,9.56,21.83,20.71,21.93" transform="translate(0 0)"  />
            <polygon points="253.92 47.15 253.92 27.4 275.08 27.4 275.08 23.59 253.92 23.59 253.92 6.5 277.7 6.5 277.7 2.58 247.67 2.58 247.67 51.44 279.19 51.44 279.19 47.15 253.92 47.15"  />
            <path d="M133.91,2.58V33.66c0,9.08-4.44,15.21-14.74,15.21-11,0-13.24-7.66-13.24-14.8V2.58H99.47V34.4c0,10.78,7.5,18.19,19.73,18.19s19.2-8.44,19.2-19.06V2.58Z" transform="translate(0 0)"  />
            <polygon points="185.94 2.58 169.56 44.11 152.77 2.58 145.62 2.58 165.9 51.66 170.65 51.66 190.69 2.58 185.94 2.58"  />
            <path d="M40,51.45h7.3L25.06,2.23H17.5v.38c2.26,1.48,2.43,3.53,1.07,7L0,51.45H5.13L11.32,36.7H33.7ZM12.92,32.91l9.5-22.63,9.66,22.63Z" transform="translate(0 0)"  />
            <path d="M232.07,2.58V33.66c0,9.08-4.45,15.21-14.74,15.21-11,0-13.24-7.66-13.24-14.8V2.58h-6.47V34.4c0,10.78,7.51,18.19,19.73,18.19s19.21-8.44,19.21-19.06V2.58Z" transform="translate(0 0)"  />
            <path d="M292,4.79a4.79,4.79,0,0,0-9.58,0,4.72,4.72,0,0,0,4.82,4.81A4.66,4.66,0,0,0,292,4.79m-.56,0a4.23,4.23,0,1,1-8.45,0A4.09,4.09,0,0,1,287.17.51a4.17,4.17,0,0,1,4.27,4.28m-3.39.34a1.45,1.45,0,0,0,1.17-1.41,1.4,1.4,0,0,0-1.59-1.46h-2.24V7.2h.72v-2h1.2l1.21,2h.78Zm.41-1.4a.74.74,0,0,1-.83.81h-1.52V2.92h1.53a.74.74,0,0,1,.82.81" transform="translate(0 0)"  />
        </svg>
    );
};

export default Logo;