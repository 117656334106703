import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn,
  Overlay,
  CloseBtn,
} from "components/parts";

import HeaderDots from "../../shared/HeaderDots";

import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.jpg";
import img3 from "./assets/img3.jpg";
import img4 from "./assets/img4.jpg";
import img5 from "./assets/img5.svg";

const Slide = styled.div`
  background: #0a7cc1;
  inset: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "AvenirNext", sans-serif;

  h1 {
    color: #fff !important;
    margin-top: 26px;
  }

  .box1 {
    line-height: 22px;
  }

  .box2 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 240px;
  }
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide step={props.step}>
      <div className="centered">
        <h1 className="color2">
          Das Leben besteht nicht darin,{" "}
          <strong>nur geradeaus zu schauen</strong>
        </h1>

        <HeaderDots top={198} />

        <Container left={100} top={240} width={1900} className="small">
          Bei Kunden mit torischen Kontaktlinsen kann es im Laufe des Tages zu
          Sehstörungen aufgrund
          <br />
          von Kopf- und Augenbewegungen kommen.<sup>6</sup>
        </Container>

        <Image
          src={img1}
          width={418}
          height={497}
          left={100}
          top={419}
          className="fadeIn"
        />
        <Image
          src={img2}
          width={418}
          height={497}
          left={577}
          top={419}
          className="fadeIn animate2"
        />
        <Image
          src={img3}
          width={418}
          height={497}
          left={1052}
          top={419}
          className="fadeIn animate3"
        />
        <Image
          src={img4}
          width={418}
          height={497}
          left={1528}
          top={419}
          className="fadeIn animate4"
        />

        <Container
          left={494}
          top={1026}
          width={1554}
          height={206}
          className="box2 bg3 rounded-full flex flex-all-center relative fadeInFromRight animate5"
        >
          <Image src={img5} width={206} height={112} left={104} top={44} />
          <p>
            <strong>
              Kunden benötigen eine gleichbleibende,
              <br /> klare und stabile Sicht
            </strong>
          </p>
        </Container>
      </div>
    </Slide>
  );
};
