import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.png'


const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 71px;
        text-align: center;
        color: #fff;
    }

    .text1, .text3 {
        line-height: 27.5px;
    }

    .text2 {
        font-size: 25px;
        line-height: 26.5px;
        text-transform: uppercase;
    }

    .text1 {
        img {
            position: absolute;
            z-index: -1;
            left: -40%;
            top: -80%;
            width: 130%;
        }
    }


    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Das Leben verlangt <strong>mehr von unseren Augen</strong></h1>

                <Container left={164} top={443} width={580} as="p" className="text1">
                    Blau-violettes Licht<br />
                    von Bildschirmen,<br />
                    LED´s und der Sonne<br />
                    umgibt uns den<br />
                    ganzen Tag!<sup>5</sup>
                    <img src={img1} />
                </Container>
                <div className="container-animate fadeInFromLeft">
                    <Arrow left={1221} top={431} width={310} className="" arrowColor={'#3a8dde'} lineColor={'#ffffff'} offset={-80} />

                    <Container left={1324} top={500} width={580} as="p" className="text2 text-right">
                        <strong>Auswirkungen<br />
                            auf unsere Augen</strong>
                    </Container>

                    <Container left={1390} top={660} width={580} className="text3">
                        <p>Blau-violettes Licht kann<br />
                            die <strong>visuelle Qualität beeinträchtigen</strong><sup>5</sup></p>
                    </Container>

                </div>
            </Slide>
        </>

    );
};

export default Folder2Slide1;