import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';

import img2 from '../slide2/assets/img1.svg';
import img3 from '../slide2/assets/img3.svg';

import img4 from './assets/img4.jpg';


const Slide = styled.div`

    .box1 {
       background-image: url(${img1});
       background-size: cover;
       border-radius: 15px;
    }

    .img2 {
        position: absolute;
        width: 50px;
        left: 96px;
        top: 188px;
        opacity: 0.3;
    }

    .img3 {
        position: absolute;
        width: 44px;
        opacity: 0.3;
        top: 269px;
        left: 883px;
    }

    
    .overlay1 {
        overflow: hidden;
        border: 0.5px solid #003087;
    }


    .box4 {
        font-size: 30px;
        line-height: 32px;
    }

`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1><strong>Eine Kampagne</strong> am Point of Sale und in den sozialen Medien</h1>

            <Container left={191} top={273} width={1665} height={440} className="box1 color1 flex-all-center text-center">
                <p>Mit der „Mein Optiker-Kampagne” macht Johnson & Johnson Vision<br />
                    Endverbraucher <strong>auf die Vorteile der persönlichen und kompetenten<br />
                        Beratung im stationären Fachhandel aufmerksam.</strong><br /><br />

                    Und <strong>stellt die zeitnahe Terminverfügbarkeit</strong> gegenüber sonst häufig<br />
                    langer Wartezeiten <strong>in den Vordergrund.</strong></p>
            </Container>

            <img src={img2} className="img2" />
            <img src={img3} className="img3" />


            <div className="container-animate fadeInFromTop">

                <Container left={408} top={789} width={1232} className="color1 text-center">
                    <p><strong>Kompetenz-Vermittlung und Termin-Vergabe</strong></p>
                </Container>

                <Arrow left={998} top={180} width={1428} className="rotate90" />

            </div>

            <div className="container-animate fadeIn animate2">

                <Container left={191} top={960} width={1665} height={190} className="box3 bg1 color1 text-center flex-all-center">

                    <p>Die Kontaktlinsenspezialisten erhalten neben <strong>Postern, Fensterklebern</strong><br />
                        auch eine <strong>Toolbox</strong> mit <strong>Newsletter, Banner</strong> und <strong>Social Media Postings</strong>,<br />
                        die sie über ihre eigenen Accounts ausspielen können.</p>
                </Container>

                <MoreBtn
                    left={1815} top={1013}
                    onClick={() => toggleOverlay('overlay1')}
                    className="more1"
                    active={state.overlay1 === 'overlay1'}
                />

                <Overlay className="with-bg" active={state.overlay1 === 'overlay1'}>


                    <Container left={191} top={209} width={1666} height={941} className="overlay1 bg2 color1 text-center" />

                    <Image left={298} top={372} width={1488} height={776} src={img4} />

                    <Container left={191} top={253} width={1666} as="p" className="box4 color1 text-center">
                        <strong>Wie nutzen Sie die Kampagne am erfolgreichsten?</strong>
                    </Container>

                    <Container left={222} top={854} width={574} as="p" className="box4 color1 text-center">
                        <strong>Point of Sale:</strong>
                    </Container>

                    <Container left={222} top={926} width={574} as="p" className="color1 text-center">
                        Befestigen Sie den<br />
                        Fensterkleber und stellen<br />
                        Sie den Passantenstopper<br />
                        gut sichtbar auf
                    </Container>

                    <Container left={838} top={458} width={433} as="p" className="box4 color1  text-center">
                        <strong>Social Media:</strong>
                    </Container>

                    <Container left={838} top={531} width={433} as="p" className="color1  text-center">
                        Spielen Sie<br />
                        Social Media Post<br />
                        und Stories aus
                    </Container>

                    <Container left={1383} top={854} width={382} as="p" className="box4 color1  text-center">
                        <strong>Newsletter:</strong>
                    </Container>

                    <Container left={1383} top={926} width={382} as="p" className="color1  text-center">
                        Kontaktieren<br />
                        Sie Bestands- und<br />
                        Neukunden
                    </Container>

                </Overlay>

            </div>

        </Slide>
    );
};
