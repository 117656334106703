import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.png';

const Slide = styled.div`

    .box1 {
        font-family: "AvenirNext";
        font-weight: 700;
        font-size: 26.5px;
        line-height: 32px;

        .percent {
            font-size: 60px;
            line-height: 60px;
        }

        .small {
            font-size: 15px;
            line-height: 18px;
            margin-top: 15px;

        }
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Preisanpassung</strong></h1>

            <Image left={75} top={248} width={1130} height={1127} src={img1} className="img1" />

            <Container left={1085} top={207} width={606} height={606} className="scaleIn">
                <Image left={0} top={0} width={606} height={606} src={img2} className="img2" />

                <Container left={0} top={0} width={606} height={606} className="flex flex-all-center text-center box1 color1">
                    <p>
                        <span className="percent">5%</span><br />
                        Preisanpassung
                    </p>
                    <p className="small">exkl. ACUVUE® OASYS MAX<br />1-Day-Familie</p>
                </Container>
            </Container>
        </Slide>
    );
};
