
import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'

const Theme = {
    color1: '#008ee2',
    color2: '#003087',
    bg1: '#CFF2FB',
    bg2: '#ffffff',

    bg3: '#003087',
}

export const Slides = {
    'Folder14Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder14Slide2': { name: Slide2, theme: Theme },
    'Folder14Slide3': { name: Slide3, theme: Theme },
    'Folder14Slide4': { name: Slide4, theme: Theme },
    'Folder14Slide5': { name: Slide5, theme: Theme },
    'Folder14Slide6': { name: Slide6, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder14Slide1' },
    { key: 1, component: 'Folder14Slide2' },
    { key: 2, component: 'Folder14Slide3' },
    { key: 3, component: 'Folder14Slide4' },
    { key: 4, component: 'Folder14Slide5' },
    { key: 5, component: 'Folder14Slide6' },
]