import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from './assets/icon1.svg';

const Slide = styled.div`
   .box1 {
       .number {
           position: absolute;
           display: block;
           width: 100px;
           left: -80px;
           top: -35px;
           font-size: 97.5px;
           line-height: 122px;
           color: #009ca6;
           font-style: italic;
           text-align: right;
       }
    }

    .box2 {
        align-items: center;
        justify-content: flex-start;

        img {
            margin-left: 26px;
            margin-top: -4px;
            width: 86.25px;
        }

        .count {
            margin-left: 14px;
            margin-top: 12px;
            font-size: 62px;
            line-height: 77.5px;

            span {
                font-size: 33.5px;
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Lassen Sie uns gemeinsam das <strong>Wachstum im<br />
Kontaktlinsen-Geschäft wieder verstärken,</strong> indem …</h1>
            <Container left={145} top={340} width={782} height={731} className="bg1">
            </Container>
            <Container left={1139} top={340} width={764} height={731} className="bg1">
            </Container>

            <Container left={296} top={408} width={575} height={160} className="rounded flex-all-center text-center box1 color1">
                <span className="number">1.</span>
                <p><strong>… neue Träger<br />
akquiriert werden.</strong></p>
            </Container>

            <Container left={1306} top={408} width={575} height={160} className="rounded flex-all-center text-center box1 color1">
                <span className="number">2.</span>
                <p><strong>… aktuelle Träger<br />
zufrieden gestellt<br />
werden.</strong></p>
            </Container>

            <Container left={230} top={650} width={615} height={189} className="rounded rounded-full flex box2">
                <img src={icon1} />
                <span className="count">6,3<span> Mio</span></span>
            </Container>

            <Arrow left={518} top={268} width={700} className="rotate90" bgColor={'#D0F1F2'} />
            <Arrow left={1512} top={285} width={666} className="rotate90" bgColor={'#D0F1F2'} />

            <Container left={246} top={862} width={539} className="text-center color1">
                <p>Menschen erwägen<br />
<strong>aktiv die Nutzung von<br />
Kontaktlinsen.<sup>1</sup></strong></p>
            </Container>

            <Container left={1256} top={666} width={552} className="text-center color1">
                <p><strong>Die Erfüllung der<br /> 
Kundenbedürfnisse ist<br />
entscheidend</strong> für die<br /> 
Verringerung potenzieller<br />
Abbrecher<sup>1</sup></p>
            </Container>

            
        </Slide>
    );
};
