import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState } from "components/hooks";

import {
  Arrow,
  Container,
  CloseBtn,
  Image,
  MoreBtn,
  Overlay,
} from "components/parts";

import img1 from "./assets/img1.png";
import img2 from "./assets/img2.svg";

const Slide = styled.div`
  > .box1 {
    padding-left: 15px;
  }

  > .box2 {
    font-size: 12.8px;
    line-height: 17px;

    .number {
      position: absolute;
      display: block;
      left: 10px;
      top: -40px;
      font-size: 64px;
      line-height: 80px;
      font-style: italic;
      text-align: right;
      color: #ffb900;
    }
  }

  .overlay1 {
    > .box1 {
      border-radius: 15px;
      border-color: #051f4a;
      border-width: 1px;
    }

    &::before {
      background: #051f4a !important;
    }
  }

  .overlay1 {
    .img1 {
      position: absolute;
      width: 741px;
      left: 126px;
      top: 135px;
    }

    .box2 {
      font-size: 11px;
      line-height: 15px;
    }
  }

  .overlay2 {
    > .box1 {
      border-radius: 15px;
      border-color: #051f4a;
      border-width: 1px;
    }

    .box2 {
      font-size: 30px;
    }

    .box3 {
      font-size: 16.5px;
      line-height: 23px;
    }

    .img2 {
      position: absolute;
      width: 496px;
      left: 177px;
      top: 379px;
    }
  }
`;
const inititialState = {
  overlay1: null,
  overlay2: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide className="centered">
      <h1 className="color2">
        ACUVUE® <strong>Anpass-Leitfaden</strong>
      </h1>

      <Container
        left={390}
        top={228}
        width={1270}
        height={171}
        className="bg1 box1 color2 flex-all-center"
      >
        <Arrow width={1065} className="container-bottom" bgColor={"#fff4dd"} />
        <p className="text-center">
          Bei 94% der ACUVUE®-Träger tritt der Anpasserfolg
          <br />
          mit nur 4 oder weniger Linsen ein<sup>*2,3</sup>
        </p>
      </Container>

      <Container
        left={138}
        top={477}
        width={1740}
        height={218}
        className="bg2 box2 box2-1 color2 flex-all-center fadeInFromLeft"
      >
        <span className="number number1 color2">1</span>
        <p className="text-center">
          <strong>Bestimmen Sie das beste sphärische Glas (BSG):</strong>{" "}
          Starten Sie stets mit einer aktuellen Refraktionsbestimmung.
          <br />
          Ermitteln Sie dabei die stärkstmögliche Pluskorrektur. Stellen Sie
          sicher, dass der Zylinder &lt;1,00 dpt ist. <br />
          Das Sehen jedes Kunden ist individuell. Bei Werten über +/–4,00 dpt
          ist eine HSA-Korrektur erforderlich.
        </p>
      </Container>

      <Container
        left={138}
        top={738}
        width={1740}
        height={218}
        className="bg2 box2 box2-2 color2 flex-all-center fadeInFromLeft animate2"
      >
        <span className="number number1 color2">2</span>
        <p className="text-center">
          <strong>Bestimmen Sie das sensorisch dominante Auge:</strong>{" "}
          Bestimmen Sie das dominante Auge mithilfe eines Nebelglases +1,00 dpt.
          <br />
          (falls kein klares Ergebnis, verstärken Sie auf +1.50 dpt). Dies wird
          anstelle von Peilmethoden empfohlen.
        </p>
      </Container>

      <Container
        left={138}
        top={994}
        width={1740}
        height={218}
        className="bg2 box2 box2-3 color2 flex-all-center fadeInFromLeft animate3"
      >
        <span className="number number1 color2">3</span>
        <p className="text-center">
          <strong>
            {" "}
            Bestimmen Sie die multifokale bedarfsgerechte ADD basierend auf den
            Anforderungen des Kunden:
          </strong>{" "}
          Bestimmen Sie nun mit dem
          <br />
          BSG im Testbild die niedrigste ADD, die erforderlich ist, um eine gute
          Nahsicht zu erreichen. <strong>Top-Tipp:</strong> Unabhängig von
          früheren Stärken
          <br />
          und Alter sollten Sie sich dem niedrigstmöglichen Additionswert
          nähern, beginnend mit +1,25 dpt (maximale LOW Addition). Wenn dies
          <br />
          nicht ausreicht, fahren Sie mit +1,75 dpt (maximale MID Addition)
          fort. Verwenden Sie alltägliche Dinge (Smartphone, Zeitschriften usw.)
        </p>
      </Container>

      <MoreBtn
        left={1836}
        top={1092}
        onClick={() => toggleOverlay("overlay1")}
        active={state.overlay1 === "overlay1"}
        className="fadeInFromLeft animate3"
      />

      <Overlay
        active={state.overlay1 === "overlay1"}
        className="overlay1 overlay1-1 with-bg"
      >
        <Container
          left={138}
          top={228}
          width={1740}
          height={984}
          className="box1 rounded border"
        />

        <img src={img1} className="img1" />

        <Container
          as="p"
          left={252}
          top={1086}
          width={1700}
          className="color2 box2"
        >
          Bis zu 4 Linsen insgesamt. Der Kunde bekommt die optimalen
          Refraktionswerte in die Messbrille eingesetzt und betrachtet
          <br />
          unter binokularen Bedingungen die Sehprobentafel. Ein Nebelglas (+1,00
          dpt) wird alternierend vor beide Augen gehalten.
          <br />
          Das Nebelglas befindet sich dann vor dem dominanten Auge, wenn der
          Kunde die stärkere Unschärfe als unangenehmer empfindet.
        </Container>

        <MoreBtn
          left={1688}
          top={1092}
          onClick={() =>
            updateState(
              "overlay2",
              state.overlay2 !== "overlay2-1" ? "overlay2-1" : null
            )
          }
          active={state.overlay2 === "overlay2-1"}
          exclamation
        />

        <Overlay
          active={state.overlay2 === "overlay2-1"}
          className="overlay2 overlay1-2"
        >
          <Container
            left={238}
            top={252}
            width={1496}
            height={940}
            className="box1 bg1 rounded border"
          />

          <Container
            as="p"
            left={140}
            top={352}
            width={1690}
            height={218}
            className="box2 text-center color2"
          >
            <strong>CHECK UP</strong>
          </Container>

          <Container
            as="p"
            left={140}
            top={458}
            width={1690}
            height={218}
            className="text-center color2"
          >
            Wenn ein zuvor erfolgreicher ACUVUE® Multifokal-
            <br />
            Kontaktlinsenträger zu einem Check Up zurückkehrt und
            <br />
            über Veränderungen der Fern- oder Nahsicht berichtet:
          </Container>

          <Arrow
            width={1065}
            left={980}
            top={160}
            className="rotate90"
            bgColor={"#fff4dd"}
            lineColor={"#051f4a"}
          />

          <img src={img2} className="img2" />

          <Container
            as="p"
            left={484}
            top={761}
            width={300}
            className="box3 color2"
          >
            Keine Über- Refraktion über aktuelle Linse
          </Container>

          <Container
            as="p"
            left={887}
            top={761}
            width={400}
            className="box3 color2"
          >
            Nicht sofort die
            <br />
            Optimierungs-
            <br />
            tabelle verwenden
          </Container>

          <Container
            as="p"
            left={1327}
            top={761}
            width={400}
            className="box3 color2"
          >
            Wählen Sie
            <br />
            nicht sofort eine
            <br />
            höhere Addition
          </Container>

          <Container
            as="p"
            left={482}
            top={940}
            width={1180}
            className="box3 color2"
          >
            Kontaktlinsen absetzen und erneut mit Punkt 1 (BSG) und anschließend
            Punkt 3 (ADD) die idealen neuen Parameter bestimmen
          </Container>
        </Overlay>
      </Overlay>
    </Slide>
  );
};
