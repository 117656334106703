
import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'
import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'
import Slide9 from './slides/slide9'
import Slide10 from './slides/slide10'
import Slide11 from './slides/slide11'
import Slide12 from './slides/slide12'
import Slide13 from './slides/slide13'
import Slide14 from './slides/slide14'
import Slide15 from './slides/slide15'
import Slide16 from './slides/slide16'
import Slide17 from './slides/slide17'
import Slide18 from './slides/slide18'
import Slide19 from './slides/slide19'
import Slide20 from './slides/slide20'

const Theme = {
    color1: '#3a8dde',
    color2: '#3a8dde',
    color3: '#fbe126',

    bg1: '#C5E6F4'
}

export const Slides = {
    'Folder12Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder12Slide2': { name: Slide2, theme: Theme },
    'Folder12Slide3': { name: Slide3, theme: Theme, steps: 2 },
    'Folder12Slide4': { name: Slide4, theme: Theme },
    'Folder12Slide5': { name: Slide5, theme: Theme },
    'Folder12Slide6': { name: Slide6, theme: Theme },
    'Folder12Slide7': { name: Slide7, theme: Theme },
    'Folder12Slide8': { name: Slide8, theme: Theme },
    'Folder12Slide9': { name: Slide9, theme: Theme },
    'Folder12Slide10': { name: Slide10, theme: Theme },
    'Folder12Slide11': { name: Slide11, theme: Theme, steps: 2 },
    'Folder12Slide12': { name: Slide12, theme: Theme },
    'Folder12Slide13': { name: Slide13, theme: Theme },
    'Folder12Slide14': { name: Slide14, theme: Theme },
    'Folder12Slide15': { name: Slide15, theme: Theme, steps: 2 },
    'Folder12Slide16': { name: Slide16, theme: Theme },
    'Folder12Slide17': { name: Slide17, theme: Theme },
    'Folder12Slide18': { name: Slide18, theme: Theme },
    'Folder12Slide19': { name: Slide19, theme: Theme },
    'Folder12Slide20': { name: Slide20, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder12Slide1' },
    { key: 1, component: 'Folder12Slide2' },
    { key: 2, component: 'Folder12Slide3' },
    { key: 3, component: 'Folder12Slide4' },
    { key: 4, component: 'Folder12Slide5' },
    { key: 5, component: 'Folder12Slide6' },
    { key: 6, component: 'Folder12Slide7' },
    { key: 7, component: 'Folder12Slide8' },
    { key: 8, component: 'Folder12Slide9' },
    { key: 9, component: 'Folder12Slide10' },
    { key: 10, component: 'Folder12Slide11' },
    { key: 19, component: 'Folder12Slide20' },
    { key: 11, component: 'Folder12Slide12' },
    { key: 12, component: 'Folder12Slide13' },
    { key: 13, component: 'Folder12Slide14' },
    { key: 14, component: 'Folder12Slide15' },
    { key: 15, component: 'Folder12Slide16' },
    { key: 16, component: 'Folder12Slide17' },
    { key: 17, component: 'Folder12Slide18' },
    { key: 18, component: 'Folder12Slide19' },
]