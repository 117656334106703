import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow2, Arrow3, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';

const Slide = styled.div`
`
const inititialState = {
    overlay1: false
}


export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})`, bottom: '85px' }}></div>
            <Slide className="centered" step={props.step}>
                <h1>Wie entsteht <strong>nachhaltige Kundenbindung?</strong></h1>
                
                <Container left={476} top={390} width={419} height={113} className="rounded rounded-full text-center flex-all-center color1">
                    <p><strong>Kompetenz</strong></p>
                </Container>

                <Arrow left={669} top={373} width={342} className="rotate90 fadeIn" />

                <Container left={476} top={578} width={419} height={113} className="rounded rounded-full text-center flex-all-center color1 fadeIn">
                    <p><strong>Vertrauen</strong></p>
                </Container>

                <Container left={476} top={731} width={419} height={138} className="bg1 text-center flex-all-center color1 fadeInFromTop animate2">
                    <Arrow width={342} className="container-top" />
                    <p><strong>Kundenbindung</strong></p>
                </Container>
            
            </Slide>
        </>
    );
};
