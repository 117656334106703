import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    .list {
        display: flex;

        .symbol {
            flex: 0 0 12px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
            <p className="list"><span className="symbol">1.</span> Der Partnerrabatt wird als Direktrabatt gewährt. Ende Juni und Ende September 2022 wird die Nettoumsatzentwicklung überprüft und bei einer negativen Entwicklung 
der Partnerrabatt angepasst.</p>

<p className="list"><span className="symbol">2.</span> Interne Analyse Marktdaten Dritter, Januar 2022</p>

<p className="list"><span className="symbol">3.</span> Shopper Segmentation Study, 2019, JJVC with 2001 cl wearers</p>

<p className="list"><span className="symbol">4.</span> Survey conducted from 4/19 – 5/19. Sample comprised of 853 US Eye Doctors (686 optometrists & 167 ophthalmologists)</p>

<p className="list"><span className="symbol">*</span>  Interne Analyse von Markdaten Dritter in Bezug auf das gesamte Jahr 2021</p>

<p><strong>Wichtige Sicherheitsinformationen:</strong> ACUVUE® Kontaktlinsen sind für eine Sehkorrektion indiziert. Wie bei jeder Kontaktlinse können Augenprobleme, einschließlich Hornhautgeschwüre, auftreten. Bei einigen Trägern kann es zu leichten Irritationen, Juckreiz oder Unbehagen kommen. Kontaktlinsen sollten nicht bei Augeninfektionen oder anderen Augenkrankheiten verwendet werden. 
Vollständige Informationen, einschließlich Kontraindikationen und Vorsichtsmaßnahmen, entnehmen Sie bitte der Gebrauchsanweisung oder besuchen Sie unsere J&amp;J-Website jnjvisioncare.de/at</p>

<p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2 UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.<br />
UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken.<br /> 
UV- Transmission mit –1,00 Dioptrien Linse gemessen.</p>

<p>ACUVUE®, ACUVUE® OASYS 1-Day und HydraLuxe® sind eingetragene Marken von Johnson &amp; Johnson.<br /> 
© 2022 Johnson &amp; Johnson Medical GmbH / Johnson &amp; Johnson Medical Products GmbH</p>
            </TextBox>
        </Slide>
    );
};
