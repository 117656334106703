import React from 'react';
import styled, { css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

const Button = styled.svg`
    position: absolute;
    z-index: 200;
	width: 33.5px;
	height: 33.5px;
	cursor: pointer;

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}
`

const CloseBtn = (props) => {
    const onClick = (event) => {
        event.stopPropagation();
        props.onClick();
    }

    const color = props.color ? props.color : '#003087'

    return (  
        <Button viewBox="0 0 67 67" left={props.left} top={props.top} onClick={onClick} style={props.style}>
            <defs><clipPath id="a"><rect width="67" height="67" fill="none"/></clipPath></defs>
            <g clipPath="url(#a)">
            {props.bgColor &&
            <circle cx="33.5" cy="33.5" r="32" fill={props.bgColor} />
            }
            <circle cx="33.5" cy="33.5" r="32" fill="none" stroke={color} strokeWidth="3"/>
            
            </g>
            
            <line x1="19.39" y1="19.39" x2="47.61" y2="47.61" fill="none" stroke={color} strokeLinecap="round" strokeWidth="3.99"/>
            <line x1="47.61" y1="19.39" x2="19.39" y2="47.61" fill="none" stroke={color} strokeLinecap="round" strokeWidth="3.99"/>
        </Button>
    );
};

export default CloseBtn;