import React from 'react';
import styled from 'styled-components'

import bg from './assets/bg.jpg' 

const Slide = styled.div`
    background: url(${bg});
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: 2px;
        font-size: 47.7px;
        line-height: 57.2px;
        color: #fff;
        text-transform: uppercase;
  
        color: #fff;
    }
`

const Folder2Slide1 = () => {
    return (
        <Slide className="bg full">
            <h1 style={{color: '#fff'}}><strong>ACUVUE® OASYS 1-Day</strong><br />
Familie</h1>
        </Slide>
    );
};

export default Folder2Slide1;