import React, { useState } from 'react';
import styled from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'
import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';
import img2 from './assets/img1.jpg';
import img3 from './assets/img2.jpg';
import img4 from './assets/img3.jpg';

const Slide = styled.div`
    .box1 {
        align-items: center;
        justify-content: flex-start;

        img {
            margin-left: 40px;
            width: 91.5px;
        }

        p {
            margin-left: 13px;
        }
    }

    .box2 {
        padding-top: 27px;

        > p {
            margin-bottom: 3px;
        }

        .count {
            margin-left: 30px;
            font-size: 75px;
            line-height: 93px;

            span {
               font-size: 40px;

                sup {
                   vertical-align: text-top;
                   line-height: 20px;
                    font-size: 12px;
                } 
            }
        }
    }

    .overlay1 {
        ${Image} {
            border: 4px solid #fff;
            border-radius: 4px;
        }
    }

    .box4 {
        font-size: 10px;
        line-height: 12px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ active, preview, step }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: active })
    const count1 = useCounter(0, 30, active, preview)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }} />
            <Slide className="centered" step={step}>
                <h1>
                    <strong>ACUVUE® OASYS 1-Day with HydraLuxe®</strong><br />
                    sorgt für eine hohe Sehqualität
                </h1>

                <Container left={100} top={342} width={1244} height={382} className="rounded box1 rounded-full flex color1">
                    <img src={img2} />
                    <p>
                        Die patentierte und vom Tränenfilm<br />
                        inspirierte Technologie<sup>ƒ,19</sup> <strong>HydraLuxe®</strong> sorgt<br />
                        für eine <strong>überragende Sehqualität<sup>ƒ,19,20</sup></strong>, eine<br />
                        <strong>verbesserte Kontrastempfindlichkeit<sup>ƒ,19,20</sup></strong><br />
                        und macht dadurch feine Details schärfer,<br />
                        heller und klarer.
                    </p>
                </Container>

                <div className="container-animate fadeInFromTop">
                    <Container left={270} top={803} width={903} height={378} className="bg1 color1 box2 text-center">
                        <Arrow width={838} className="container-top" />
                        <p>
                            <strong>Verbessert die<br />
                            Kontrastempfindlichkeit um bis zu</strong>
                        </p>
                        <span className="count">{count1}<span>%<sup>§#20</sup></span></span>
                    </Container>
                    
                </div>

                
            </Slide>
        </>
    );
};
