import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn,
  Overlay,
  CloseBtn,
} from "components/parts";

import HeaderDots from "../../shared/HeaderDots";

import img1 from "./assets/img1.png";
import img2 from "./assets/img2.png";

const Slide = styled.div`
  inset: 0;
  width: 100%;
  height: 100%;
  font-family: "AvenirNext", sans-serif;

  .box1 {
    line-height: 22px;
  }

  .copy {
    font-size: 9.5px;
  }

  .bg {
    background: #0a7cc1;
    z-index: -1;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 43%;
  }

  .disrupter {
    background: #ff6a39;
    font-size: 12.3px;
    line-height: 18px;
    border: 1.4px solid #fff;
    transform: rotate(-16deg);
  }
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide step={props.step}>
      <div className="centered">
        <h1 className="color2">
          ACUVUE® Kontaktlinsen für Astigmatismus werden
          <br />
          ständig weiterentwickelt,{" "}
          <strong>
            um den Bedürfnissen der
            <br />
            Kunden gerecht zu werden
          </strong>
        </h1>

        <HeaderDots top={356} color={"#051f4a"} />

        <Container
          left={100}
          top={404}
          width={1900}
          className="box1 small color2 fadeIn"
        >
          <strong>Sehqualität:</strong> ACUVUE® Kontaktlinsen sind so
          konzipiert, dass sie eine deutlich bessere Sehschärfe ermöglichen als
          sphärische Äquivalente<sup>1</sup>. Außerdem bieten sie klare, stabile
          Sicht dank Lidschlag-Stabilisations-Design.<sup>1</sup>
        </Container>

        <Container
          left={100}
          top={514}
          width={1900}
          className="box1 small color2 fadeIn"
        >
          <strong>Tragekomfort:</strong> ACUVUE® Kontaktlinsen für Astigmatismus
          bieten exzellenten Tragekomfort<sup>1</sup> dank integriertem
          Benetzungsswirkstoff und Invisible Edge Design.
        </Container>

        <Image src={img1} width={656} height={457} left={356} top={706} />
        <Image src={img2} width={650} height={460} left={1037} top={706} />

        <Container
          left={420}
          top={640}
          width={214}
          height={214}
          className="scaleIn animate2"
        >
          <Container
            left={0}
            top={0}
            width={214}
            height={214}
            className="disrupter color2 flex flex-all-center text-center color3 rounded-full"
          >
            wieder <strong>vollumfänglich erhältlich</strong>
          </Container>
        </Container>

        <Container
          left={575}
          top={1162}
          width={885}
          className="copy color3 text-center fadeIn animate2"
        >
          Produktabbildungen nur zu Illustrationszwecken
        </Container>
      </div>
      <div className="bg bg1" />
    </Slide>
  );
};
