import React from 'react';
import styled, { css } from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useCounter } from 'components/hooks'

import bg from './assets/bg.jpg'
import bg2 from './assets/bg2.jpg'
import img1 from './assets/img1.png'

const Background = styled.div`
    ${({ active }) => active === false && css`
        opacity: 0;
    `}

    transition: all 1s ease-out;
`

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 45px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 9px;
        line-height: 12.5px;
    }

    .text2 {
        line-height: 27.5px;
    }

    .text3 {
        font-size: 27.5px;
        line-height: 30px;

        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}
    }

    .text4 {
        line-height: 27.5px;

        .count {
            font-size: 35px;
            color: #fff;
        }
    }

    .container-animate {
        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}
    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

    
`

const Folder2Slide1 = ({ ...props }) => {

    const count1_1 = useCounter(0, 94, props.active && props.step >= 2, props.preview, 1)
    const count1_2 = useCounter(0, 80, props.active && props.step >= 2, props.preview, 1)

    const count2_1 = useCounter(0, 90, props.active && props.step >= 2, props.preview, 1)
    
    return (
        <>
            <Background className="bg" style={{ backgroundImage: `url(${bg})` }}></Background>
            <Background className="bg" style={{ backgroundImage: `url(${bg2})` }} active={props.step >= 2}></Background>
            <Slide className="centered" step={props.step}>
                <h1><strong>Unsere aktuell<br /> 
leistungsstärkste<br /> 
Multifokallinse</strong></h1>
                <Image left={636} top={640} width={768} height={456} src={img1} />
                <Container left={744} top={900} width={544} as="p" className="text1 text-center">
                    Nur zu Illustrationszwecken
                </Container>

                <Container left={475} top={467} width={1090} as="p" className="text2 text-center">
                Nachgewiesene Leistung in<br /> 
klinischen Studien°<sup>18</sup>
                </Container>

                <Container left={22} top={441} width={593} as="p" className="text3 text-right fadeIn">
                <strong>Scharfe, klare<br /> 
Sicht</strong><sup>±18</sup>
                </Container>

                <div className="container-animate fadeIn animate2">

                    <Arrow left={320} top={370} width={508} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={0} top={667} width={593} as="p" className="text4 text-right">
                    <span className="count">{count1_1} %</span> erleben klare<br />
Sehqualität an digitalen<br />
Geräten°<sup>18</sup><br /><br />

<span className="count">{count1_2} %</span> erleben klare<br />
Sehqualität im Nah-, Fern-<br /> 
und Zwischenbereich°<sup>18</sup>
                    </Container>

                </div>

                <Container left={1450} top={420} width={593} as="p" className="text3 fadeIn">
                Für ganztägigen<br /> 
<strong>Tragekomfort</strong>°<sup>18</sup>
                </Container>

                <div className="container-animate fadeIn animate2">

                <Arrow left={1690} top={370} width={508} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                <Container left={1450} top={667} width={593} as="p" className="text4">
                <span className="count">{count2_1} %</span> erleben<br /> 
Tagesend-Komfort°<sup>18</sup>
                </Container>

                </div>


            </Slide>
        </>

    );
};

export default Folder2Slide1;