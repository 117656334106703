import React from 'react';
import styled from 'styled-components'

import bg from './assets/bg.jpg' 

const Slide = styled.div`
    background: url(${bg});
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: -7px;
        font-size: 56px;
        line-height: 67px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        color: #fff;
    }
`

const Folder2Slide1 = () => {
    return (
        <Slide className="bg full">
            <h1 style={{color: '#fff'}}>Covid-19</h1>
        </Slide>
    );
};

export default Folder2Slide1;