import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Dropdown from './Dropdown'
import styles from './Footer.module.sass'
import Logo from './Logo'

const Home = ({style}) => (
    <svg width="24.5" height="20" style={style} viewBox="0 0 49.44 40.39">
        <polygon points="21.89 27.42 21.89 38.89 9.32 38.89 9.32 21.84 1.5 21.84 24.72 1.5 24.73 1.5 47.95 21.84 40.12 21.84 40.12 38.89 27.55 38.89 27.55 27.42 21.89 27.42" fill="none" className="stroke" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/></svg>
)

const Arrow = ({style}) => (
    <svg width="9" style={style} viewBox="0 0 17.98 31.71"><defs><clipPath id="a" transform="translate(0 0)"><rect width="17.98" height="31.71" fill="none"/></clipPath></defs><g clipPath="url(#a)"><polyline points="16.48 1.5 2.12 15.86 16.48 30.21" fill="none" strokeLinecap="round" strokeWidth="3"/></g></svg>
)

const Menu = ({style}) => (
    <svg width="17.5" viewBox="0 0 35.45 19.52"><line x1="1.5" y1="1.5" x2="33.95" y2="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/><line x1="1.5" y1="9.76" x2="33.95" y2="9.76" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/><line x1="1.5" y1="18.02" x2="33.95" y2="18.02" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/></svg>
)

const Footer = ({...props}) => {
    const [ showNavigation, setShowNavigation ] = useState(false)
    
    const history = useHistory()
    const location = useLocation()

    const classes = [styles.footer]
    props.variant && classes.push(props.variant)

    const isFolder = location.pathname.indexOf('/folders/') > -1


    return (
        <div className={classes.join(' ')}>
            <div className={'navigation' + (showNavigation ? ' active' : '')}>
                <div>
                    <div className="slides">
                        {props.children}
                    </div>
                </div>
            </div>
            <span className="logo"><Logo /></span>
            {isFolder && 
                <Dropdown currentSlide={!props.preview ? props.currentSlide : null} />
            }
            <ul className="buttons">
                {isFolder && 
                    <li><span className="prev" onClick={() => props.goBack()}><Arrow style={{marginLeft: '-2px'}} /></span></li>
                }
                <li><span className="next" onClick={() => history.push('/')}><Home style={{marginTop: '-2px'}} /></span></li>
                {isFolder &&
                    <>
                    <li><span className="next" onClick={() => setShowNavigation(!showNavigation)}><Menu /></span></li>
                    <li><span className="next" onClick={() => props.goForward()}><Arrow style={{marginLeft: '2px', transform: 'rotate(-180deg)'}} /></span></li>
                    </>
                }
            </ul>
        </div>
    );
};

export default Footer;