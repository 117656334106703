import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';

const Slide = styled.div`
     overflow: hidden;

    .box1 {
        padding-left: 89px;
    }

    .box2 {
        .count {
            position: absolute;
            left: 0;
            top: 58px;
            width: 100%;
            font-size: 75px;
            line-height: 94px;

            span {
                padding: 0 6px;
                font-size: 22px;
            }
        }

        &.box2-1 {
            padding-top: 30px;
        }

        &.box2-2 {
            padding-top: 20px;
        }
    }

    .box3 {
        padding-top: 5px;

        .count {
            font-size: 46px;
            line-height: 58;

            span {
                font-size: 25px;
            }
        }

        p {
            position: absolute;
            left: 48px;
            top: 30px;
            font-size: 15.75px;
            line-height: 18px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 66, props.active, props.preview)
    const count2 = useCounter(0, 80, props.active, props.preview)

    return (

        <Slide className="centered" step={props.step}>
            <h1>Kontaktlinsenträger bevorzugen <strong>ACUVUE® OASYS 1-Day with<br />
            HydraLuxe®-Technologie</strong> gegenüber DAILIES TOTAL1®</h1>

            <Container left={513} top={302} width={1049} height={243} className="rounded rounded-full flex-all-center box1 color1">
                <p>In einer klinischen Studie <strong>schnitt<br />
ACUVUE® OASYS 1-Day with HydraLuxe®<br />
Technologie bei folgenden Punkten<br />
besser ab</strong> als DAILIES TOTAL1®<sup>21</sup></p>
            </Container>

            <Image left={128} top={334} width={534} height={175} src={img1} />

            <div className="container-animate fadeInFromTop">

            <Container left={536} top={594} width={424} height={299} className="bg1 box2 box2-1 text-center color1">
                <Arrow width={346} className="container-top" />
                <p><strong>Gesamturteil<sup>¶22</sup></strong></p>
                <span className="count">2<span>zu</span>1</span>
            </Container>

            <Container left={1118} top={594} width={424} height={397} className="bg1 box2 box2-2 text-center color1">
                <Arrow width={346} className="container-top" />
                <p><strong>Leichtigkeit<br />
beim Abnehmen<sup>¶22</sup></strong></p>
                <span className="count">5<span>zu</span>1</span>
            </Container>

            <CircleCount left={618} top={867} width={268} percent={count1} color={'#df9933'} />
            <CircleCount left={1196} top={867} width={268} percent={count2} color={'#df9933'} />

            <Container left={618} top={867} width={268} height={268} className="flex-all-center text-center box3">
                <span className="count">{count1}<span>%</span></span>
            </Container>

            <Container left={1196} top={867} width={268} height={268} className="flex-all-center text-center box3 color1">
                <p>Über</p>
                <span className="count">{count2}<span>%</span></span>
            </Container>

            <Container left={486} top={1145} width={524} height={299} className="text-center color1">
                <p>bevorzugen<br />
                    <strong>ACUVUE® OASYS 1-Day</strong></p>
            </Container>

            <Container left={1068} top={1145} width={524} height={299} className="text-center color1">
                <p>bevorzugen<br />
                    <strong>ACUVUE® OASYS 1-Day</strong></p>
            </Container>

            </div>

        </Slide>
    );
};
