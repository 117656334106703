import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import temp from './assets/temp.png';

import img4 from '../slide2/assets/img3.svg';


const Slide = styled.div`
    .img1 {
        position: absolute;
        left: 80px;
        top: 191px;
        width: 919px;
        height: auto;
        
    }

    .temp {
        position: absolute;
        left: 0;
        top: 0;
        width: 1024px;
    }

    .label1 {
        
        font-size: 16.5px;
        line-height: 19px;

        span {
            font-size: 10px;
        }
    }

    .label2 {
        font-size: 11px;
        line-height: 26px;
        font-weight: 300;
    }

    .label3 {
        font-weight: 600;
        font-size: 21px;
        line-height: 27px;
        text-align: right;
    }

    .overlay1 { 
        .box1 {
            background: #fff;
            border: 1px solid #003087;
            border-radius: 15px;
        }
    }

   
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Stabiles Preissystem: <strong>Großteil des Portfolios ohne<br />
                Erhöhung der Listpreise</strong></h1>
           
            <img src={img1} className="img1" />

            <Container as="p" left={223} top={426} width={176} className="small color1 text-center label1">
                <strong>Bestseller</strong>
            </Container>

            <Container as="p" left={461} top={426} width={78} className="small color1 text-center label1">
                <strong>Box</strong>
            </Container>

            <Container as="p" left={574} top={426} width={182} className="small color1 text-center label1">
                <strong>Listpreise<br />
                    <span>ab 01/2022</span></strong>
            </Container>

            <Container as="p" left={839} top={426} width={180} className="small color1 text-center label1">
                <strong>Erhöhung<br />
                    <span>vs. Vorjahr</span></strong>
            </Container>

            <Container as="p" left={1132} top={426} width={176} className="small color1 text-center label1">
                <strong>Bestseller</strong>
            </Container>

            <Container as="p" left={1336} top={426} width={78} className="small color1 text-center label1">
                <strong>Box</strong>
            </Container>

            <Container as="p" left={1450} top={426} width={182} className="small color1 text-center label1">
                <strong>Listpreise<br />
                    <span>ab 01/2022</span></strong>
            </Container>

            <Container as="p" left={1715} top={426} width={180} className="small color1 text-center label1">
                <strong>Erhöhung<br />
                    <span>vs. Vorjahr</span></strong>
            </Container>

            <Container as="p" left={461} top={535} width={78} className="text-center label2">
                30er<br />
                90er
            </Container>
            <Container as="p" left={574} top={535} width={182} className="text-center label2">
                23,20€<br />
                62,64€
            </Container>

            <Container as="p" left={461} top={680} width={78} className="text-center label2">
                30er<br />
                90er
            </Container>
            <Container as="p" left={574} top={680} width={182} className="text-center label2">
                25,52€<br />
                68,90€
            </Container>

            <Container as="p" left={461} top={826} width={78} className="text-center label2">
                30er<br />
                90er
            </Container>
            <Container as="p" left={574} top={826} width={182} className="text-center label2">
                18,58€<br />
                53,36€
            </Container>

            <Container as="p" left={461} top={970} width={78} className="text-center label2">
                30er<br />
                90er
            </Container>
            <Container as="p" left={574} top={970} width={182} className="text-center label2">
                22,39€<br />
                60,48€
            </Container>


            <Container as="p" left={1336} top={535} width={78} className="text-center label2">
                6er<br />
                12er
            </Container>
            <Container as="p" left={1450} top={535} width={182} className="text-center label2">
                19,97€<br />
                35,95€
            </Container>

            <Container as="p" left={1336} top={680} width={78} className="text-center label2">
                6er<br />
                12er
            </Container>
            <Container as="p" left={1450} top={680} width={182} className="text-center label2">
                28,52€<br />
                51,34€
            </Container>

            <Container as="p" left={1336} top={855} width={78} className="text-center label2">
                6er
            </Container>
            <Container as="p" left={1450} top={855} width={182} className="text-center label2">
                24,21€
            </Container>

            <Container as="p" left={1336} top={997} width={78} className="text-center label2">
                6er
            </Container>
            <Container as="p" left={1450} top={997} width={182} className="text-center label2">
                29,91€
            </Container>

            <Container as="p" left={789} top={537} width={182} className="label3 color1">
                0%<br />
                0%
            </Container>

            <Container as="p" left={789} top={682} width={182} className="label3 color1">
                0%<br />
                0%
            </Container>

            <Container as="p" left={789} top={828} width={182} className="label3 color1">
                0%<br />
                0%
            </Container>

            <Container as="p" left={789} top={972} width={182} className="label3 color1">
                +2%<br />
                +2%
            </Container>

            <Container as="p" left={1665} top={537} width={182} className="label3 color1">
                0%<br />
                0%
            </Container>

            <Container as="p" left={1665} top={682} width={182} className="label3 color1">
                +2%<br />
                +2%
            </Container>

            <Container as="p" left={1665} top={855} width={182} className="label3 color1">
                0%
            </Container>

            <Container as="p" left={1665} top={997} width={182} className="label3 color1">
                0%
            </Container>

            <Image src={img2} left={188} top={546} width={244} height={532} />
            <Image src={img3} left={1139} top={539} width={165} height={534} />

            {!state.overlay1 &&
                <Image src={img4}  left={1769} top={306} width={75} height={44} />
            }

            <MoreBtn
                left={1765} top={230}
                onClick={() => toggleOverlay('overlay1')}
                active={state.overlay1 === 'overlay1'}
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg" onClick={() => toggleOverlay('overlay1')}>

                <Container left={100} top={273} width={1840} height={895} className="box1">
                </Container>

                <img src={img1} className="img1" />

                <Container as="p" left={161} top={306} width={427} className="small color1">
                    <strong>Listpreise Österreich</strong>
                </Container>


                <Container as="p" left={223} top={426} width={176} className="small color1 text-center label1">
                    <strong>Bestseller</strong>
                </Container>

                <Container as="p" left={461} top={426} width={78} className="small color1 text-center label1">
                    <strong>Box</strong>
                </Container>

                <Container as="p" left={574} top={426} width={182} className="small color1 text-center label1">
                    <strong>Listpreise<br />
                        <span>ab 01/2022</span></strong>
                </Container>

                <Container as="p" left={839} top={426} width={180} className="small color1 text-center label1">
                    <strong>Erhöhung<br />
                        <span>vs. Vorjahr</span></strong>
                </Container>

                <Container as="p" left={1132} top={426} width={176} className="small color1 text-center label1">
                    <strong>Bestseller</strong>
                </Container>

                <Container as="p" left={1336} top={426} width={78} className="small color1 text-center label1">
                    <strong>Box</strong>
                </Container>

                <Container as="p" left={1450} top={426} width={182} className="small color1 text-center label1">
                    <strong>Listpreise<br />
                        <span>ab 01/2022</span></strong>
                </Container>

                <Container as="p" left={1715} top={426} width={180} className="small color1 text-center label1">
                    <strong>Erhöhung<br />
                        <span>vs. Vorjahr</span></strong>
                </Container>

                <Container as="p" left={461} top={535} width={78} className="text-center label2">
                    30er<br />
                    90er
                </Container>
                <Container as="p" left={574} top={535} width={182} className="text-center label2">
                    23,20€<br />
                    62,64€
                </Container>

                <Container as="p" left={461} top={680} width={78} className="text-center label2">
                    30er<br />
                    90er
                </Container>
                <Container as="p" left={574} top={680} width={182} className="text-center label2">
                    25,52€<br />
                    68,90€
                </Container>

                <Container as="p" left={461} top={826} width={78} className="text-center label2">
                    30er<br />
                    90er
                </Container>
                <Container as="p" left={574} top={826} width={182} className="text-center label2">
                    18,63€<br />
                    50,27€
                </Container>

                <Container as="p" left={461} top={970} width={78} className="text-center label2">
                    30er<br />
                    90er
                </Container>
                <Container as="p" left={574} top={970} width={182} className="text-center label2">
                    22,45€<br />
                    60,61€
                </Container>


                <Container as="p" left={1336} top={535} width={78} className="text-center label2">
                    6er<br />
                    12er
                </Container>
                <Container as="p" left={1450} top={535} width={182} className="text-center label2">
                    20,02€<br />
                    36,03€
                </Container>

                <Container as="p" left={1336} top={680} width={78} className="text-center label2">
                    6er<br />
                    12er
                </Container>
                <Container as="p" left={1450} top={680} width={182} className="text-center label2">
                    28,58€<br />
                    51,45€
                </Container>

                <Container as="p" left={1336} top={855} width={78} className="text-center label2">
                    6er
                </Container>
                <Container as="p" left={1450} top={855} width={182} className="text-center label2">
                    24,21€
                </Container>

                <Container as="p" left={1336} top={997} width={78} className="text-center label2">
                    6er
                </Container>
                <Container as="p" left={1450} top={997} width={182} className="text-center label2">
                    29,91€
                </Container>

                <Container as="p" left={789} top={537} width={182} className="label3 color1">
                    0%<br />
                    0%
                </Container>

                <Container as="p" left={789} top={682} width={182} className="label3 color1">
                    0%<br />
                    0%
                </Container>

                <Container as="p" left={789} top={828} width={182} className="label3 color1">
                    0%<br />
                    0%
                </Container>

                <Container as="p" left={789} top={972} width={182} className="label3 color1">
                    +2%<br />
                    +2%
                </Container>

                <Container as="p" left={1665} top={537} width={182} className="label3 color1">
                    0%<br />
                    0%
                </Container>

                <Container as="p" left={1665} top={682} width={182} className="label3 color1">
                    +2%<br />
                    +2%
                </Container>

                <Container as="p" left={1665} top={855} width={182} className="label3 color1">
                    0%
                </Container>

                <Container as="p" left={1665} top={997} width={182} className="label3 color1">
                    0%
                </Container>

                <Image src={img2} left={188} top={546} width={244} height={532} />
                <Image src={img3} left={1139} top={539} width={165} height={534} />
            </Overlay>

        </Slide>
    );
};
