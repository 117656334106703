import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';

const Slide = styled.div`
    .box1 {
       padding-left: 15px;
    }

    .box1 .number {
        position: absolute;
        display: block;
        left: 2px;
        top: -86px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .box1-2 .number {
        left: 16px;
    } 

    .box1-3 .number {
        left: 16px;
    } 

    .exclamation {
        display: block;
        position: absolute;
        left: 774px;
        top: 494px;
        width: 117px;
        font-size: 97.5px;
        line-height: 122px;
        color: #3a8dde;
        font-style: italic;
        text-align: center;
    }

    .box3 {
        font-size: 30px;
        line-height: 32px;
    } 

    .overlay1 {
        .box2 {
            overflow: hidden;
        }

        .box4 p + p {
            margin-top: 20px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (


        <Slide className="centered">
            <h1>Wie nutzen Sie die Kampagne<br /> 
am <strong>erfolgreichsten?</strong></h1>

            <Container left={246} top={420} width={1540} height={150} className="bg1 box1 box1-1 color1 flex-all-center fadeInFromLeft">
                <span className="number number1">1.</span>
                <p><strong>Informieren & trainieren Sie das Team<br /> 
zur Kampagne</strong></p>
            </Container>

            <Container left={246} top={726} width={1540} height={150} className="bg1 box1 box1-2 color1 flex-all-center fadeInFromLeft animate2">
                <span className="number number1">2.</span>
                <p><strong>Platzieren Sie vor allem die Endverbraucher-Broschüre</strong><br /> 
inkl. Zufriedenheitsgarantie gut sichtbar im Geschäft.</p>
            </Container>

            <Image left={1475} top={595} width={395} height={396} src={img1} className="fadeIn animate2" />

            <div className="container-animate fadeInFromLeft animate3">

                <Container left={246} top={1015} width={1540} height={150} className="bg1 box1 box1-3 color1 flex-all-center ">
                    <span className="number number1">3.</span>
                    <p><strong>Nutzen Sie weitere Kommunikations-Maßnahmen,</strong><br /> 
um für Kunden und Mitarbeiter sichtbar zu sein.</p>

                    
                    <MoreBtn
                        className="x-right y-center"
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'}
                    />
                </Container>
                <span className="exclamation">!</span>

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 overlay1-3 with-bg bg3">
                    <Container left={100} top={291} width={1688} height={895} className="box2 bg2 color1">
                    <Image left={0} top={0} width={800} height={489} src={img2} />
                    <Image left={1271} top={34} width={328} height={832} src={img3} />
                    </Container>
                   

                    <Container left={152} top={824} width={750} className="box3 color1" as="p">
                       <strong>Point of Sale:</strong>
                    </Container>

                    <Container left={152} top={896} width={750} className="color1 box4" as="p">
                    Befestigen Sie den Schaufenster-
kleber, stellen Sie die Print-Materialien 
gut sichtbar auf und dekorieren Sie 
Ihr Schaufenster mit den Maxi-Pack 
und Aufstellern
                    </Container>

                    <Container left={960} top={424} width={440} className="box3 color1" as="p">
                       <strong>Social Media:</strong>
                    </Container>

                    <Container left={960} top={496} width={440} className="color1" as="p">
                    Spielen Sie 
Social Media Post 
und Stories aus 

                    </Container>
                 

                    <Container left={960} top={824} width={460} className="box3 color1" as="p">
                       <strong>Newsletter:</strong>
                    </Container>

                   
                   

                    <Container left={960} top={896} width={460} className="color1" as="p">
                    Kontaktieren 
Sie Bestands- und 
Neukunden

                    </Container>

                   

                </Overlay>

            </div>




        </Slide>

    );
};
