import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.png';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.png';

const Slide = styled.div`
    .box1 {
        padding-left: 178px;

        > img {
            position: absolute;
            left: 0;
            top: 0;
            width: auto;
            height: 100%;
        }

        .box2  {
            .count {
                font-size: 54px;
                line-height: 66px;
            }

            p {
                position: absolute;
                left: 0;
                width: 100%;
                font-size: 18px;
                line-height: 20px;

                &.top {
                    top: 30px;
                }

                &.bottom {
                    top: 102px;
                }
            }

            &.box2-1 .count {
                letter-spacing: -0.25rem;
            } 
        }


    }

    .box3 {
        overflow: hidden;
        padding-top: 26px;
        background: #c2beba;
        border-radius: 15px;

        .text1 {
            font-size: 28px;
            line-height: 32px;
            color: #2ca88f;
            margin-bottom: 32px;
        }

        p {
            position: relative;
        }

        img {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
        }
    }

    .box4 {
        img {
            position: absolute;
            width: 371px;
            left: -30px;
            top: 12px;
        }
    }

    .box5 {
        font-size: 18px;
        line-height: 27.5px;
    }

    .box6 {
        font-size: 27.5px;
        line-height: 31.5px;
    }
  
`

const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 204, props.active, props.preview)
    count1 = count1 / 10
    count1 = count1.toLocaleString('de-DE')

    let count2 = useCounter(0, 87, props.active, props.preview, 1)
    count2 = count2 / 10
    count2 = count2.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')

    let count3 = useCounter(0, 6, props.active, props.preview, 2)
    count3 = count3 / 10
    count3 = count3.toLocaleString('de-DE')

    return (
        <Slide className="centered" step={props.step}>
            <h1>Presbyope Kunden bieten eine <strong>große Wachstumschance</strong><br />
                für Ihr Geschäft</h1>
            <Container left={270} top={287} width={1106} height={307} className="box1 flex-all-center">
                <img src={img1} />
                <Container left={0} top={0} width={307} height={307} className="box2 box2-1 flex-all-center text-center ">
                    <p className="top color1">Über</p>
                    <span className="count">{count1}</span>
                    <p className="bottom color1">Mio.</p>
                </Container>
                <p className="color1">… der über 45-Jährigen<br />
                    <strong>benötigen eine Sehkorrektion<sup>1</sup></strong></p>
            </Container>

            <Container left={270} top={571} width={1106} height={307} className="box1 flex-all-center fadeIn animate2">
                <img src={img2} />
                <Container left={0} top={0} width={307} height={307} className="box2 flex-all-center text-center ">

                    <span className="count">{count2}</span>
                    <p className="bottom color1">Mio.</p>
                </Container>
                <p className="color1">… der über 45-Jährigen<br />
                    <strong>sind offen und interessiert<br />
                    an Kontaktlinsen</strong><sup>1</sup></p>
            </Container>

            <Container left={270} top={858} width={1106} height={307} className="box1 flex-all-center fadeIn animate3">
                <img src={img3} />
                <Container left={0} top={0} width={307} height={307} className="box2 flex-all-center text-center ">
                    <p className="top color1">Nur</p>
                    <span className="count">{count3}</span>
                    <p className="bottom color1">Mio.</p>
                </Container>
                <p className="color1">… der über 45-Jährigen,<br />
                    <strong>die eine Sehkorrektion<br />
                    benötigen, tragen multifokale<br />
                    Kontaktlinsen</strong><sup>1</sup></p>
            </Container>

            <Arrow left={1400} top={390} width={690} className="" arrowColor={'#00a98f'} />

            <Container left={1466} top={342} width={518} height={785} className="box3 text-center">
                <img src={img4} />
                <p className="text1"><strong>Nutzen Sie das<br />
                    Potenzial:</strong></p>
                <p className="color1">Es gibt mehr<br />
                    Menschen mit Bedarf<br />
                    an Sehkorrektion als<br />
                    Kontaktlinsenträger</p>
            </Container>
            
            <MoreBtn
                left={1692}
                top={1088}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')} />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg overlay1 overlay1-1">
                <Container left={1294} top={342} width={690} height={785} className="box4 bg2 color1 border">
                <img src={img5} />
                </Container>

                <Container as="p" left={1530} top={361} width={690} className="color3 box5">
                Beginn Alterssichtigkeit
                </Container>

                <Container as="p" left={1511} top={600} width={474} className="color1 box6 text-center">
                <strong>Das<br /> 
ungenutzte<br /> 
Potenzial<br /> 
ist riesig!</strong>
</Container>

            </Overlay>

        </Slide>
    );
};
