import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img9 from './assets/img1.svg';

const Slide = styled.div`
    .box1 {
        border-radius: 158.5px;
        color: #fff;
        font-size: 18.5px;
        line-height: 21px;
    }

    .box2 {
        padding-left: 11px;
        border-radius: 15px;
        color: #fff;
        line-height: 23.7px;
        font-size: 15px;

        strong {
            font-size: 18.5px;
        }
    }

    .box3 { 
        padding-left: 65px;

        .number {
            position: absolute;
            left: -21px;
            top: -10px;
            width: 73.5px;
            height: 73.5px;
            border-radius: 50%;
            color: #fff;
            background: #009C81;
            font-size: 62px;
            line-height: 76.5px;
            text-align: center;
            font-style: italic;
            padding-right: 2px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Live Seminare:</strong> Praxis-Einblick & Themenauswahl</h1>

            

            <Container left={247} top={331} width={290} height={290} className="box1 bg2 flex-all-center text-center">
                <p><strong>Jeden<br />
                    2. Mittwoch<br />
                    19:00 Uhr</strong></p>
            </Container>

            <Container left={567} top={331} width={290} height={290} className="box1 bg2 flex-all-center text-center">
                <p><strong>Offen für<br />
                    Ihre<br />
                    Fragen</strong></p>
            </Container>

            <Container left={887} top={331} width={290} height={290} className="box1 bg2 flex-all-center text-center">
                <p><strong>Anpassfälle<br />
                    live<br />
                    diskutiert</strong></p>
            </Container>

            <Container left={1207} top={331} width={290} height={290} className="box1 bg2 flex-all-center text-center">
                <p><strong>Austausch<br />
                    mit sehr<br />
                    erfahrenen<br />
                    Anpassern</strong></p>
            </Container>

            <Container left={1527} top={331} width={290} height={290} className="box1 bg2 flex-all-center text-center">
                <p><strong>Aus der<br />
                    Praxis<br />
                    für die<br />
                    Praxis</strong></p>
            </Container>

            <Container left={142} top={236} width={500} height={113} className="box3 rounded rounded-full flex-all-center">
                    <span className="number">1</span>
                    <p className="color1"><strong>Praxis Einblicke</strong></p>
            </Container>



            <div className="container-animate fadeInFromTop">

                <Container left={142} top={653} width={1688} height={113} className="box3 rounded rounded-full flex-all-center">
                    <span className="number">2</span>
                    <p className="color1"><strong>Live-Seminare</strong></p>
                </Container>

                <Container as="p" left={588} top={691} width={1400} className="small">
                Nach erfogreicher Teilnahme erhalten alle Teilnehmer*innen ein Zertifikat.
                </Container>

                <Container left={220} top={803} width={1610} height={70} className="box2 bg2 flex-all-center">
                    <p><strong>Presbyopie &amp; Kontaktlinsen –</strong>  1. Erfolg mit Design & Anpassung 2. Kommunikation & Fehlerminimierung</p>
                </Container>

                <Container left={220} top={888} width={1610} height={70} className="box2 bg2 flex-all-center">
                    <p><strong>Einfach besser? –</strong>  1. Start mit glücklichen Kund*innen 2. Kommunikation & Upgrading</p>
                </Container>

                <Container left={220} top={973} width={1610} height={70} className="box2 bg2 flex-all-center">
                    <p><strong>Praxis-Einblick –</strong>  Anpassfälle live diskutiert</p>
                </Container>

                <Container left={220} top={1058} width={1610} height={70} className="box2 bg2 flex-all-center">
                    <p><strong>Weiche kontaktlinsen 2.0 –</strong>  1. Es hat sich viel verändert 2. Erkenntnisse für Ihren Erfolg</p>
                </Container>

                <Container left={220} top={1144} width={1610} height={70} className="box2 bg2 flex-all-center">
                    <p><strong>Sicht auf Sehen  –</strong>  Kontaktlinsen als Geschäftskonzept</p>
                </Container>

                <Image as="a" left={1580} top={918} width={490} height={247} src={img9} href="https://www.jnjvisioncare.de/online-seminare" target="_blank" />
            </div>

        </Slide>
    );
};
