import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'
import { formatThousands } from 'components/helper'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';

const Slide = styled.div`
    .box1 {
        align-items: center;
        justify-content: flex-start;
        padding-left: 30px;

        .count {
            margin-top: 3px;
            margin-right: 13px;
            font-size: 62px;
            line-height: 77.5px;
        }

        &.box1-2 .count {
            width: 110px;
        } 

        &.box1-3 .count {
            width: 255px;
        }   
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })
    const count1 = useCounter(0, 130, props.active, props.preview)
    const count2 = formatThousands(useCounter(0, 130000, props.active, props.preview));

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }} />
            <Slide className="centered" step={props.step}>
                <h1>Johnson &amp; Johnson: <strong>Für ein längeres,<br />
glücklicheres Leben für Menschen weltweit</strong></h1>
                <Container left={160} top={346} width={935} height={166} className="rounded rounded-full color1 flex box1 box1-1">
                    <p><strong>Führender Hersteller</strong> von Konsumgütern,<br />
                    Medizinprodukten und Arzneimitteln</p>
                </Container>
                <Container left={160} top={553} width={935} height={166} className="rounded rounded-full color1 flex box1 box1-2">
                    <span className="count">{count1}</span>
                    <p><strong>Jahre Erfahrung in der<br />
Gesundheitsversorgung</strong></p>
                </Container>
                <Container left={160} top={760} width={935} height={166} className="rounded rounded-full color1 flex box1 box1-3">
                    <span className="count">{count2}</span>
                    <p><strong>Mitarbeiter in<br />
60 Ländern</strong></p>
                </Container>
                <Container left={160} top={967} width={935} height={166} className="rounded rounded-full color1 flex box1 box1-4">
                    <p><strong>Werteorientierte Unternehmensführung</strong></p>
                </Container>
            </Slide>
        </>
    );
};
