import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow2, Arrow3, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';

const Slide = styled.div`
`
const inititialState = {
    overlay1: false
}


export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1><strong>ACUVUE® und Kundenbindung<sup>7</sup></strong></h1>

                <Container left={238} top={323} width={803} height={205} className="rounded rounded-full flex-all-center text-center color1">
                    <p><strong>ACUVUE® Träger zeigen<br /> 
eine hohe Loyalität sowohl zu Ihrem<br />
Geschäft als auch zur Marke …</strong></p>
                </Container>

                <Container left={263} top={582} width={760} height={340} className="bg1 flex-all-center text-center color1 fadeInFromTop">
                    <Arrow width={640} className="container-top" />
                    <p><strong>… und tendieren somit weniger<br />
                    dazu Einkaufstätte oder Marke<br />
zu wechseln – im  Vergleich<br />
zu anderen Kontaktlinsen-<br />
Produkten.<sup>8</sup></strong></p>
                </Container> 
                
            </Slide>
        </>
    );
};
