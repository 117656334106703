import React from "react";
import styled, { css } from "styled-components";
import { calculateDimensionProperty } from "components/helper";

const StyledSvg = styled.svg`
  position: absolute;

  width: 953px;

  ${({ top }) =>
    top !== undefined &&
    css`
      top: ${calculateDimensionProperty(top)};
    `}

  color: ${(props) => (props.color ? props.color : "#ffff")};
`;

const HeaderDots = (props) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1906 3.76"
      {...props}
    >
      <defs>
        <clipPath id="clippath">
          <rect y="0" width="1906" height="3.76" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#clippath)">
        <line
          x1="13.13"
          y1="1.88"
          x2="1898.49"
          y2="1.88"
          fill="none"
          stroke="currentColor"
          strokeDasharray="0 11.26"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.76"
        />
        <path
          d="M1904.12,1.88h0M1.88,1.88h0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.76"
        />
      </g>
    </StyledSvg>
  );
};

export default HeaderDots;
