import React from 'react';
import styled, { css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

const Container = styled.div`
    position: absolute;

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}

    ${({ width }) => width !== undefined && css`
        width: ${calculateDimensionProperty(width)};
    `}

    ${({ height }) => height !== undefined && css`
        height: ${calculateDimensionProperty(height)};
    `}

    &.bg1,
    &.bg2,
    &.bg3 {
        border-radius: 15px;
    }
    
    &.rounded {
        border-radius: 44px;
		background: #fff;
    }

    &.rounded-full {
        ${({ height }) => height !== undefined && css`
            border-radius: ${height/4}px;
        `}
    }

    &.flex-all-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    &.text-center {
        text-align: center;
    }

    &.text-right {
        text-align: right;
    }

    &.border {
        border: 1px solid #003087;
    }
   
`
/*
const BoxBg = ({...props}) => {
    return (
        <div>
            
        </div>
    );
};
*/

export default Container;