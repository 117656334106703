import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'
import { formatThousands } from 'components/helper'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from './assets/icon1.svg';

const Slide = styled.div`
    .box1 {
        align-items: center;
        justify-content: flex-start;

        img {
            margin-left: 28px;
            width: 86.25px;
        }

        p {
            margin-left: 17px;
        }
    }

    .box2 {
        align-items: center;
        justify-content: flex-start;
        padding-top: 10px;

        .count {
            display: block;
            margin-left: 11px;
            margin-top: 5px;
            position: relative;
            flex: 0 0 313px;
            font-size: 88.5px;
            line-height: 100px;
            text-align: right;

            > span {
                position: absolute;
                font-size: 21px;
                line-height: 23.7px;
                left: 9px;
                top: -20px;
            }
        }

        p {
            margin-left: 14px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    const count1 = useCounter(0, 14000, props.active, props.preview)

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Ein Hauptgrund für den abnehmenden<br /> 
Tragekomfort</strong> ist der Grad der Reibung<sup>14</sup></h1>
            <Container left={433} top={383} width={1184} height={246} className="rounded rounded-full flex box1 color1">
                <img src={icon1} />
                <p>Kontaktlinsen beeinflussen den Tränenfilm,<br /> 
was ebenfalls zu einer erhöhten Reibung mit<br />
der Augenoberfläche führen kann.</p>
            </Container>
            <Container left={274} top={681} width={1494} height={344} className="bg1 color1 flex box2 fadeInFromTop">
                <Arrow width={1179} className="container-top" />
                <span className="count"><span>Bei fast</span>{formatThousands(count1)}</span>
                <p><strong>Lidschlägen pro Tag kann sich<br />
                die Reibung zwischen Linsen und Augenlidern negativ
auf den<br /> 
Tragekomfort auswirken.<sup>15</sup></strong></p>
            </Container>
        </Slide>
    );
};
