import React from 'react';

const Diagram = () => {
    return (
        <div className="img1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1364.74 905.33" className="svg1"  width="682.35" height="452.66">

                <rect x="247.81" y="609.58" width="1035.95" height="72.79" fill="#f5f5f6" />
                <rect x="247.81" y="465.52" width="1035.95" height="72.79" fill="#f5f5f6" />
                <rect x="247.81" y="320.81" width="1035.95" height="72.79" fill="#f5f5f6" />
                <rect x="247.81" y="175.98" width="1035.95" height="72.79" fill="#f5f5f6" />

            </svg>
            <div className="bars">
                <div fill="#009ca7" className="bar bar1" />
                <div fill="#e2000f" className="bar bar2" />
                <div fill="#009ca7" className="bar bar3 animate2" />
                <div fill="#e2000f" className="bar bar4 animate2" />
                <div fill="#009ca7" className="bar bar5 animate3" />
                <div fill="#e2000f" className="bar bar6 animate3" />
                <div fill="#009ca7" className="bar bar7 animate4" />
                <div fill="#e2000f" className="bar bar8 animate4" />
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1364.74 905.33" className="svg2"   width="682.35" height="452.66">
                <defs>
                    <clipPath id="a" transform="translate(0 0)"><rect width="1364.74" height="905.33" fill="none" /></clipPath>
                </defs>
                <line x1="249.7" y1="753.6" x2="1282.47" y2="753.6" fill="none" stroke="#002c8a" strokeLinecap="round" strokeWidth="3.76" />
                
                    <path d="M991.18,850.94a52.5,52.5,0,0,0-51-52.47v0H644.37a52.5,52.5,0,1,0,0,105H940.13v0a52.5,52.5,0,0,0,51-52.47" transform="translate(0 0)" fill="#fff" />
                    <path d="M991.18,850.94a52.5,52.5,0,0,0-51-52.47v0H644.37a52.5,52.5,0,1,0,0,105H940.13v0A52.5,52.5,0,0,0,991.18,850.94Z" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeWidth="3.76" />
                <line x1="247.81" y1="828.59" x2="247.81" y2="73.53" fill="none" stroke="#002c8a" strokeLinecap="round" strokeWidth="3.76" />
                    <path d="M247.81,821.06a7.53,7.53,0,1,0,7.53,7.53,7.52,7.52,0,0,0-7.53-7.53" transform="translate(0 0)" fill="#002c8a" />
                    <path d="M247.81,66a7.53,7.53,0,1,0,7.53,7.53A7.52,7.52,0,0,0,247.81,66" transform="translate(0 0)" fill="#002c8a" />
                <line x1="543.53" y1="740.5" x2="543.53" y2="109.26" fill="none" stroke="#002c8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.76" strokeDasharray="0 11.37" />
                <path d="M543.53,103.57h0m0,648.31h0" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.76" />
                <line x1="789.64" y1="740.5" x2="789.64" y2="109.26" fill="none" stroke="#002c8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.76" strokeDasharray="0 11.37" />
                <path d="M789.64,103.57h0m0,648.31h0" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.76" />
                <line x1="1035.76" y1="741.83" x2="1035.76" y2="110.58" fill="none" stroke="#002c8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.76" strokeDasharray="0 11.37" />
                <path d="M1035.76,104.9h0m0,648.3h0" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.76" />
                <line x1="1281.88" y1="753.2" x2="1281.88" y2="104.9" fill="none" stroke="#002c8a" strokeLinecap="round" strokeWidth="3.76" />

               
                    <path d="M1290,753.61a7.53,7.53,0,1,0-7.53,7.52,7.53,7.53,0,0,0,7.53-7.52" transform="translate(0 0)" fill="#002c8a" />
                <line x1="297.41" y1="610.47" x2="249.7" y2="610.47" fill="none" stroke="#002c8a" strokeLinecap="round" strokeWidth="3.76" />
                <line x1="297.41" y1="465.64" x2="249.7" y2="465.64" fill="none" stroke="#002c8a" strokeLinecap="round" strokeWidth="3.76" />
                <line x1="297.41" y1="320.81" x2="249.7" y2="320.81" fill="none" stroke="#002c8a" strokeLinecap="round" strokeWidth="3.76" />
                <line x1="297.41" y1="175.98" x2="249.7" y2="175.98" fill="none" stroke="#002c8a" strokeLinecap="round" strokeWidth="3.76" />
                
                    <path d="M322.32,859.21A42.22,42.22,0,0,0,281.25,817v0H44.12a42.24,42.24,0,1,0,0,84.47H281.25v0A42.22,42.22,0,0,0,322.32,859.21Z" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeWidth="3.76" /><path d="M322.32,44.12A42.22,42.22,0,0,0,281.25,1.91v0H44.12a42.24,42.24,0,1,0,0,84.47H281.25v0A42.22,42.22,0,0,0,322.32,44.12Z" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeWidth="3.76" />
                    <path d="M412.21,99.37A48.75,48.75,0,1,1,461,50.62a48.75,48.75,0,0,1-48.74,48.75" transform="translate(0 0)" fill="#fff" />
                    <path d="M412.21,99.37A48.75,48.75,0,1,1,461,50.62,48.75,48.75,0,0,1,412.21,99.37Z" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeWidth="3.76" />
                    <path d="M666.59,99.37a48.75,48.75,0,1,1,48.74-48.75,48.75,48.75,0,0,1-48.74,48.75" transform="translate(0 0)" fill="#fff" />
                    <path d="M666.59,99.37a48.75,48.75,0,1,1,48.74-48.75A48.75,48.75,0,0,1,666.59,99.37Z" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeWidth="3.76" />
                    <path d="M908,99.37a48.75,48.75,0,1,1,48.74-48.75A48.75,48.75,0,0,1,908,99.37" transform="translate(0 0)" fill="#fff" />
                    <path d="M908,99.37a48.75,48.75,0,1,1,48.74-48.75A48.75,48.75,0,0,1,908,99.37Z" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeWidth="3.76" />
                    <path d="M1164,99.37a48.75,48.75,0,1,1,48.74-48.75A48.75,48.75,0,0,1,1164,99.37" transform="translate(0 0)" fill="#fff" />
                    <path d="M1164,99.37a48.75,48.75,0,1,1,48.74-48.75A48.75,48.75,0,0,1,1164,99.37Z" transform="translate(0 0)" fill="none" stroke="#002c8a" strokeWidth="3.76" />
                <line x1="1200" y1="193.88" x2="1200" y2="536.12" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="1191.53" y1="193.88" x2="1208.47" y2="193.88" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="1191.53" y1="536.12" x2="1208.47" y2="536.12" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="944" y1="193.64" x2="944" y2="632.12" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="935.53" y1="193.64" x2="952.47" y2="193.64" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="935.53" y1="632.12" x2="952.47" y2="632.12" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="702.59" y1="347.88" x2="702.59" y2="716.12" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="694.12" y1="347.88" x2="711.06" y2="347.88" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="694.12" y1="716.12" x2="711.06" y2="716.12" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="1128" y1="687.27" x2="1128" y2="726.12" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="1119.53" y1="687.27" x2="1136.47" y2="687.27" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="1119.53" y1="726.12" x2="1136.47" y2="726.12" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="1348.93" y1="706.98" x2="1348.93" y2="745.83" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="1340.46" y1="706.98" x2="1357.4" y2="706.98" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="1340.46" y1="745.83" x2="1357.4" y2="745.83" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="872" y1="698.58" x2="872" y2="737.42" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="863.53" y1="698.58" x2="880.47" y2="698.58" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="863.53" y1="737.42" x2="880.47" y2="737.42" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="631.58" y1="682.88" x2="631.58" y2="732.54" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="623.11" y1="682.88" x2="640.04" y2="682.88" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="623.11" y1="732.54" x2="640.04" y2="732.54" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="450.47" y1="718.49" x2="450.47" y2="727.59" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="442" y1="718.49" x2="458.94" y2="718.49" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="442" y1="727.59" x2="458.94" y2="727.59" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="378" y1="706.09" x2="378" y2="724.15" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="369.53" y1="706.09" x2="386.47" y2="706.09" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <line x1="369.53" y1="724.15" x2="386.47" y2="724.15" fill="none" stroke="#1a1a18" strokeWidth="3.76" />
                <path d="M1340.37,290.51a24.38,24.38,0,1,1,24.37-24.37,24.37,24.37,0,0,1-24.37,24.37" transform="translate(0 0)" fill="#009ca7" />
                <path d="M1340.37,152.31a24.37,24.37,0,1,1,24.37-24.37,24.37,24.37,0,0,1-24.37,24.37" transform="translate(0 0)" fill="#e2000f" />

            </svg>
        </div>
    );
};

export default Diagram;

