import React from 'react';
import styled from 'styled-components'
import { Image, Container } from 'components/parts';


import bg from './assets/bg.jpg'

import img1 from './assets/img1.png'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;

    h1 {
        font-size: 54px;
        line-height: 66px;
        color: #3a8dde;
    }
    
    h2 {
        color: #fff
    }

    .text1 {
        font-size: 19px;
        font-weight: 700;
        color: #f5d654;
    }

    .text2 {
        font-size: 9px;
        line-height: 12.5px;
        color: #fff
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Das Leben verlangt<br />
                    <strong>mehr von unseren Augen</strong></h1>
                <h2><strong>MAX</strong> Tragekomfort. <strong>MAX</strong> Sehqualität.<sup>*1</sup></h2>
                <Image left={940} top={653} width={1033} height={517} src={img1} />
                <Container left={1146} top={1037} width={640} as="p" className="text2 text-center">
                    Nur zu Illustrationszwecken
                </Container>
                <Container left={1146} top={1070} width={640} as="p" className="text1 text-center">
                    Auch in Multifokal erhältlich
                </Container>
            </Slide>
        </>

    );
};

export default Folder2Slide1;