import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.png';

const pathAnimation1 = keyframes`
    0% {
        width: 0;
    }
    100% {  
        width: 751px; 
    }
`

const pathAnimation2 = keyframes`
    0% {
        width: 0;
    }
    100% {  
        width: 378px; 
    }
`

const Slide = styled.div`
    .box1 {
        z-index: 201;

        span {
            display: block;
            margin-top: 10px;
        }
    }

    .more2 {
        z-index: 202;
    }

    .line {
        position: absolute;
        z-index: 201;
        background: #2CA88F;
        height: 3px;

        &.line1 {
            width: 751px;
            top: 190px;
            right: 60px;

            ${({ preview }) => !preview && css`
                width: 0; 
            `}

            ${({ active, preview }) => (active && !preview) && css`   
                animation: ${pathAnimation1} 1s forwards linear;
                animation-delay: 0s
            `}
        }

        &.line2 {
            width: 378px;
            top: 275px;
            right: 360px;

            ${({ preview }) => !preview && css`
                width: 0; 
            `}

            ${({ active, preview }) => (active && !preview) && css`   
                animation: ${pathAnimation2} 1s forwards linear;
                animation-delay: 0.6s
            `}
        }

       

        span {
            position: absolute;
            top: -4.5px;
            height: 12px;
            width: 12px;
            border-radius: 50%;
            background: #2CA88F;
        }
    }

    .box2 {
        padding-top: 32px;

        .text1 {
            font-size: 27.5px;
            line-height: 32px;
            margin-bottom: 52px;
        }
    }

    .box4 {
        padding: 86px 0 0 16px;

        ul li {
            position: relative;
            padding-left: 12px;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                content: '•';
            }
        }

        ul + p {
            margin-top: 16px;
        }
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" active={props.active} preview={props.preview}>
            <h1><strong>Die Alterssichtigen von heute</strong> – eine attraktive Zielgruppe<br />
                mit vielfältigen Bedürfnissen </h1>

            <Image left={-158} top={276} width={943} height={943} src={img1} />

            <MoreBtn
                className="more2"
                left={1900} top={340}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')} />

            <MoreBtn
                className="more2"
                left={1308} top={510}
                active={state.overlay1 === 'overlay2'}
                onClick={() => toggleOverlay('overlay2')} />

            <Container as="p" left={1439} top={329} width={623} height={522} className="box1 color1 box1-1 fadeIn">
                <strong>Jonas, 48 Jahre</strong><br />
                <span>Fotograf</span>
            </Container>

            <Container as="p" left={852} top={499} width={623} height={522} className="box1 color1 box1-1 fadeIn animate2">
                <strong>Melanie, 41 Jahre</strong>
                <span>Verkaufsleiterin</span>
            </Container>

            <div className="line line1">
                <span></span>
            </div>

            <div className="line line2">
                <span></span>
            </div>

            <Container left={850} top={710} width={1000} height={447} className="box2 bg1 text-center">
                <p className="text1 color3"><strong>Mit wem haben wir es zu tun?</strong></p>
                <p className="text2 color1"><strong>Hier liegt Ihre Chance:</strong><br />
                    Entdecken und verstehen Sie die<br />
                    Bedürfnisse Ihrer Kunden neu!</p>
            </Container>

            <Arrow left={1332} top={454} width={890} className="rotate90" bgColor={'#d8f2ef'} arrowColor={'#00a98f'} />

            <MoreBtn
                left={1308} top={1116}
                active={state.overlay1 === 'overlay3'}
                onClick={() => toggleOverlay('overlay3')} />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg overlay1">

                <Container left={1414} top={294} width={528} height={863} className="box4 bg2 color1 border">

                    <p><strong>Ist: </strong></p>
                    <ul>
                        <li>leidenschaftlicher
                            Kletterer</li>
                        <li>beruflich viel
                            unterwegs</li>
                        <li>mit den Kindern aktiv</li></ul>

                    <p><strong>Wünscht sich: </strong></p>
                    <ul><li>freie und scharfe Sicht beim Fotografieren</li>
                        <li>keine Einschränkung beim Klettern durch seine Brille</li></ul>
                </Container>


            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="with-bg overlay1">

                <Container left={821} top={463} width={528} height={694} className="box4 bg2 color1 border">

                    <p><strong>Ist:</strong></p>
                    <ul>
                        <li>sportlich</li>
                        <li>gern in der Natur</li>
                        <li>viel mit Freunden
                            unterwegs</li>
                    </ul>
                    <p><strong>Wünscht sich: </strong></p>
                    <ul>
                        <li>attraktives Aussehen</li>
                        <li>uneingeschränkte<br />
                            Sicht beim Sport</li>
                    </ul>
                </Container>


            </Overlay>

            <Overlay active={state.overlay1 === 'overlay3'} className="with-bg overlay1">
                <Container left={850} top={710} width={1000} height={447} className="bg2 color1 border">

                </Container>

                <Container as="p" left={881} top={771} width={1000} height={447} className="box3 color1">
                    <strong>Entscheidender Erfolgsfaktor<br />
                        für die Kundenbindung: </strong><br /><br />

                    Stellen Sie mit einer guten<br />
                    Kommunikation die Bedürfnisse Ihrer<br />
                    Kunden sicher und beraten Sie diese<br />
                    individuell und ganzheitlich darauf.
                </Container>

                <Image left={1489} top={485} width={451} height={451} src={img2} />

            </Overlay>

        </Slide>
    );
};
