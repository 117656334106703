import React from "react";
import styled, { css } from "styled-components";
import { calculateDimensionProperty } from "components/helper";

const Image = styled.div`
  position: absolute;
  background: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;

  ${({ imageWidth }) =>
    imageWidth !== undefined &&
    css`
      background-size: ${imageWidth / 2}px auto;
    `}

  ${({ left }) =>
    left !== undefined &&
    css`
      left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) =>
    top !== undefined &&
    css`
      top: ${calculateDimensionProperty(top)};
    `}

    ${({ width }) =>
    width !== undefined &&
    css`
      width: ${calculateDimensionProperty(width)};
    `}

    ${({ height }) =>
    height !== undefined &&
    css`
      height: ${calculateDimensionProperty(height)};
    `}

    &.y-center {
    top: 50%;
    transform: translateY(-50%);
  }

  &.rounded {
    border-radius: 50%;
  }

  &.bg-white {
    background-color: #fff;
  }

  &.border {
    border: 1.8px solid #fff;

    &.border-white {
      border-color: #fff !important;
    }
  }

  &.image-center {
    background-position: center center;
  }

  &.contain {
    background-size: contain;
  }
`;
/*
const BoxBg = ({...props}) => {
    return (
        <div>
            
        </div>
    );
};
*/

export default Image;
