import React from 'react';
import styled, { css } from 'styled-components'

import bg from '../slide2/assets/bg.jpg'

const Slide = styled.div`
    background: url(${bg});
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        position: relative;
        margin: 86px 0 0 0;
        z-index: 10;
        font-size: 32.5px;
        line-height: 39px;
        font-weight: 600;
    }

    h2 {
        margin: 10px 0 0 0;
        position: relative;
        z-index: 10;
        font-size: 40.5px;
        line-height: 48.5px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .step1,
    .step2 {
        display: none;
    }

    .step1 {
        h1 {
            margin-top: 10px;
        }

        ${({ step }) => step === 1 && css`
            display: block;
        `}
    }

    .step2 {
        ${({ step }) => step === 2 && css`
            display: block;
        `}
    }
`

const Folder2Slide1 = (props) => {
    return (
        <Slide className="bg full" step={props.step}>
            <span className="color-overlay" />
            <div className="step1">
                <h1 style={{ color: '#fff' }}>Kompetenz:</h1>
                <h2 style={{ color: '#fff' }}>Fachliche und kommunikative<br />
Fähigkeiten</h2>
            </div>
            <div className="step2">
                <h2 style={{ color: '#fff' }}>Wahrnehmung der<br />
fachlichen Kompetenz</h2>

            </div>
        </Slide>
    );
};

export default Folder2Slide1;