import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';

const Slide = styled.div`
    

    .count {
        font-size: 53.4px;
        line-height: 66.75;

        span {
            font-size: 28.8px;
        }
    }

    .box2 {
        overflow: hidden;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 30, props.active, props.preview)
    const count2 = useCounter(0, 92, props.active, props.preview)
    const count3 = useCounter(0, 70, props.active, props.preview)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1>Halten Sie Ihre <strong>bestehenden Linsenträger<br /> 
ab 40+</strong> in der Kategorie</h1>
                <Container as="p" left={545} top={308} width={952} height={307} className="flex-all-center box1 box1-1 color1">
                <strong>… der aktuellen<br /> 
Kontaktlinsenträger<br />
sind 40 oder älter.<sup>#1</sup></strong>
                </Container>
                <Container as="p" left={110} top={585} width={600} height={307} className="flex-all-center box1 box1-2 color1 text-right">
               <strong> … gehen davon aus,<br /> 
weiterhin Kontaktlinsen<br />
 zu tragen.<sup>#1</sup></strong>
                </Container>
                <Container as="p" left={545} top={868} width={976} height={307} className="flex-all-center box1 box1-3 color1">
                <strong>… der Linsenträger ab 45<br />
geben das Tragen von<br /> 
Linsen mit beginnender<br /> 
Alterssichtigkeit auf.<sup>#1</sup></strong>
                </Container>
                <CircleCount left={185} top={308} width={307} percent={count1} color={'#2ca88f'} />
                <CircleCount left={773} top={585} width={307} percent={count2} color={'#2ca88f'} />
                <CircleCount left={185} top={868} width={307} percent={count3} color={'#2ca88f'} />

                <Container left={185} top={308} width={307} height={307} className="flex-all-center text-center box2">
                    <span className="count color3">{count1}<span>%</span></span>
                </Container>
                <Container left={773} top={585} width={307} height={307} className="flex-all-center text-center box2">
                    <span className="count color3">{count2}<span>%</span></span>
                </Container>
                <Container left={185} top={868} width={307} height={307} className="flex-all-center text-center box2">
                    <span className="count color3">{count3}<span>%</span></span>
                </Container>
            </Slide>
        </>
    );
};
