import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, TouchBtn, Overlay, CloseBtn } from 'components/parts'

import Diagram from './assets/Diagram';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.svg';
import img5 from './assets/img5.svg';

const barAnimation = keyframes`
    0% {
        transform: scaleY(0) translate3d(0, 0, 100px);
        opacity: 1;
    }
    100% {  
        transform: scaleY(100%) translate3d(0, 0, 100px);
        opacity: 1;
    }
`

const lineAnimation = keyframes`
    0% {
        width: 0px;
    }
    100% {  
       width: 775px;
    }
`

const fadeAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {  
        opacity: 1;
    }
`

const Slide = styled.div`
    .img1 {
        position: absolute;
        left: 0;
        top: 0;

        > img {
            position: absolute;
            width: 805px;
            left: 82px;
            top: 155px;
        }

        .bars {
            position: absolute;
            left: 0;
            top: 211px;
            height: 338px;
        }

        .bar {
            position: absolute;
            bottom: 0px;
            width: 24px;
            transform-origin: left bottom;

            
            
            

            &.bar1,
            &.bar3,
            &.bar5,
            &.bar7,
            &.bar9,
            &.bar11,
            &.bar13,
            &.bar15 {
                background: #c7e3f9;

                ${({ active, preview }) => (active && !preview) && css`
                    opacity: 0;
                    animation: ${barAnimation} 2s forwards;
                    animation-delay: 0.6s;
                `}

                
            }

            &.bar2,
            &.bar4,
            &.bar6,
            &.bar8,
            &.bar10,
            &.bar12,
            &.bar14,
            &.bar16 {
                background: #00a3e0;

                ${({ preview }) => !preview && css`
                    opacity: 0;
                `}


                ${({ active, preview, step }) => (active && !preview && step > 1) && css`
                    animation: ${barAnimation} 2s forwards;
                    animation-delay: 0.6s;
                `}
            }

            &.bar1 {
                left: 119px;
                width: 69px;
                height: 12px;
            }

            &.bar2 {
                left: 188px;
                width: 69px;
                height: 12px;
            }

            &.bar3 {
                left: 293px;
                height: 43px;
            }

            &.bar4 {
                left: 317px;
                height: 108px;
            }

            &.bar5 {
                left: 380px;
                height: 19.5px;
            }

            &.bar6 {
                left: 404px;
                height: 19.5px;
            }

            &.bar7 {
                left: 467px;
                height: 19.5px;
            }

            &.bar8 {
                left: 491px;
                height: 108px;
            }

            &.bar9 {
                left: 554px;
                height: 13.5px;
            }

            &.bar10 {
                left: 578px;
                height: 146px;
            }

            &.bar11 {
                left: 640px;
                height: 21.5px;
            }

            &.bar12 {
                left: 664px;
                height: 338px;
            }

            &.bar13 {
                left: 727px;
                height: 29px;
            }

            &.bar14 {
                left: 751px;
                height: 48.5px;
            }

            &.bar15 {
                left: 814px;
                height: 29px;
            }

            &.bar16 {
                left: 838px;
                height: 48.5px;
            }

            &.animate2 {
                animation-delay: 1s !important;
            }

            &.animate3 {
                animation-delay: 1.4s !important;
            }

            &.animate4 {
                animation-delay: 1.8s !important;
            }

            &.animate5 {
                animation-delay: 2.2s !important;
            }

            &.animate6 {
                animation-delay: 2.6s !important;
            }

            &.animate7 {
                animation-delay: 3s !important;
            }

            &.animate8 {
                animation-delay: 3.4s !important;
            }
            
        }
    }

    .legend1 {
        font-size: 17.5px;
        line-height: 21px;
    }

    .legend1-1 {
        font-size: 17.5px;
        line-height: 21px;
        color: #c20016;
        font-weight: 600;

        ${({ preview }) => !preview && css`
            opacity: 0;
        `}

        ${({ active, preview, step }) => (active && !preview && step > 2) && css`
            animation: ${fadeAnimation} 2s forwards;
            animation-delay: 0.6s;
        `}
    }

    .legend2 {
        font-size: 13.5px;
        line-height: 14.5px;
        font-weight: 500;
        color: #303030;
    }

    .legend3 {
        font-size: 13.5px;
        line-height: 14.5px;
        font-weight: 500;
        color: #303030;
    }

    .step3 {
        position: absolute;
        left: 87px;
        top: 525px;
        height: 10px;
        width: 775px;

        > div 
        img {
            width: 775px;
        }

        > div {
            position: absolute;
            overflow: hidden;
            left: 0;
            top: 0;
            height: 30px;

            ${({ preview }) => !preview && css`
                width: 0px;
            `}

            ${({ active, preview, step }) => (active && !preview && step > 2) && css`
                animation: ${lineAnimation} 2s forwards;
                animation-delay: 0.6s;
            `}


            
            img {
                height: 5px;
            }
        }
    }

    .box2 {
        padding-left: 50px;
    }

    .img4 {
        position: absolute;
        width: 56px;
        left: 878px;
        top: 224px;
    }

    .overlay2 {
        .img2 {
            position: absolute;
            left: 73px;
            top: 124px;
            width: 685px;
        }

        .img3 {
            position: absolute;
            left: 318px;
            top: 361px;
            width: 338px;
        }

        .text1 {
            font-size: 23.5px;
            line-height: 35px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1>Reibungskoeffizient (CoF) bei 0 und 18 Stunden<br />
                <strong>während einer Kontaktlinsen-Trage-Simulation<sup>18</sup></strong></h1>
            <Diagram />
            <Container left={48} top={310} width={100} as="p" className="flex-all-center text-right legend1">0,4</Container>
            <Container left={48} top={503} width={100} as="p" className="flex-all-center text-right legend1">0,3</Container>
            <Container left={48} top={696} width={100} as="p" className="flex-all-center text-right legend1">0,2</Container>
            <Container left={48} top={890} width={100} as="p" className="flex-all-center text-right legend1">0,1</Container>
            <Container left={48} top={1098} width={100} as="p" className="flex-all-center text-right legend1">0,0</Container>

            <Container left={48} top={1061} width={100} as="p" className="flex-all-center text-right legend1 legend1-1">0,015</Container>
            

            <Container left={216} top={1129} width={322} as="p" className="flex-all-center text-center legend2">
                ACUVUE® OASYS<br />
                1-Day
            </Container>

            <Container left={536} top={1129} width={200} as="p" className="flex-all-center text-center legend2">
                clariti®<br />
                1day
            </Container>

            <Container left={710} top={1129} width={200} as="p" className="flex-all-center text-center legend2">
                MyDay®
            </Container>

            <Container left={882} top={1129} width={200} as="p" className="flex-all-center text-center legend2">
                Proclear®<br />
                1Day
            </Container>

            <Container left={1060} top={1129} width={200} as="p" className="flex-all-center text-center legend2">
                DAILIES<br />
                TOTAL 1®
            </Container>

            <Container left={1230} top={1129} width={200} as="p" className="flex-all-center text-center legend2">
                Precision 1
            </Container>

            <Container left={1405} top={1129} width={200} as="p" className="flex-all-center text-center legend2">
                DAILIES®<br />
                AquaComfort<br />
                Plus®
            </Container>

            <Container left={1578} top={1129} width={200} as="p" className="flex-all-center text-center legend2">
                BioTrue®<br />
                ONEday
            </Container>

            <img src={img4} className="img4" />

            <Container left={1756} top={516} width={250} as="p" className="flex-all-center legend3">
                0 Stunden
            </Container>

            <span className="circle circle2" />
            <Container left={1756} top={634} width={250} as="p" className="flex-all-center legend3">
                18 Stunden
            </Container>

            <span className="circle circle2" />
            <Container left={1756} top={736} width={350} as="p" className=" legend3">
                <strong>0,015:</strong> Natürlicher<br />
                Reibungskoeffizient<br />
                der Hornhaut
            </Container>

            <div className="step3">
                <div>
                    <img src={img5} />
                </div>
            </div>

            <MoreBtn
                left={1812} top={1053}
                onClick={() => toggleOverlay('overlay1')}
                active={state.overlay1 === 'overlay1'}
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg" onClick={() => toggleOverlay('overlay1')}>
                <Container left={560} top={974} width={1295} height={250} className="box2 rounded rounded-full flex-all-center color1 box4">
                    <p><strong>Zur Zeit liegen keine offiziell akzeptierten Standards<br />
                        zur Messung des Reibungskoeffizienten (CoF) vor.</strong><br />
                        Alle Test wurden durch ein unabhängiges Unternehmen durchgeführt (Surface Solutions).</p>
                </Container>
            </Overlay>

            <TouchBtn
                left={335} top={943}
                onClick={() => toggleOverlay('overlay2')}
            />

            <Overlay active={state.overlay1 === 'overlay2'} className="overlay2 with-bg" onClick={() => toggleOverlay('overlay1')}>
                <img src={img2} className="img2" />
                <Container left={510} top={418} width={990} as="p" className="text1 color1 text-center">
                    Der Reibungskoeffizient<br />
                    von ACUVUE® OASYS 1-Day<br />
                    <strong>ist vergleichbar mit dem der<br />
                        natürlichen Hornhaut</strong> des Auges.<sup>16+</sup>
                </Container>
                <img src={img3} className="img3" />
                <CloseBtn
                    left={1312} top={361}
                    bgColor="#FFF"
                    onClick={() => toggleOverlay('overlay2')} />
            </Overlay>

        </Slide>
    );
};
