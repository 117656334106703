import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn,
  Overlay,
  CloseBtn,
} from "components/parts";

import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.png";

import HeaderDots from "../../shared/HeaderDots";

const Slide = styled.div`
  background: #0a7cc1;
  inset: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "AvenirNext", sans-serif;

  h1 {
    margin-top: 26px;
    color: #fff !important;
  }

  .mask {
    border-radius: 0 !important;
  }

  .copy-2 {
    font-size: 8px;
    line-height: 10px;
  }
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide step={props.step}>
      <div className="centered">
        <h1>
          Nur ACUVUE® hat ein <strong>Lidschlag-Stabilisations-Design</strong>
          <br />
          um den Bedürfnissen aller Kunden gerecht zu werden<sup>1</sup>
        </h1>

        <HeaderDots top={268} />

        <Image
          src={img1}
          width={1375}
          height={230}
          left={541}
          top={289}
          className="contain"
        />

        <Image
          src={img2}
          width={1874}
          height={671}
          left={87}
          top={526}
          className="contain"
        />

        <Container
          left={818}
          top={289}
          width={378}
          height={898}
          className="bg1 mask fadeOut"
        />

        <Container
          left={1196}
          top={289}
          width={378}
          height={898}
          className="bg1 mask fadeOut animate2"
        />

        <Container
          left={1574}
          top={289}
          width={378}
          height={898}
          className="bg1 mask fadeOut animate3"
        />

        <Container left={100} top={1202} width={1850} className="copy-2 color3">
          * Vertical heterophoria possibly caused by prism dissociation due to
          the presence of induced optical prism is a relevant factor for
          practitioners to consider when fitting toric contact lenses for
          monocular astigmats or those requiring a mix of toric soft contact
          lens designs e.g., mixed prescriptions.(1,2) Clinical studies have not
          been done to fully characterize the clinical effects of differences in
          base down prism among different contact lenses. 1. Jackson D et al.
          Vertical heterophoria and susceptibility to visually induced motion
          sickness. Strabismus. 2012;20(1):17-23. 2. Du Toit R et al. Tolerance
          to prism induced by readymade spectacles: setting and using a
          standard. OVS 2007;84(11):1053-9.
        </Container>
      </div>
    </Slide>
  );
};
