import React, { useState } from 'react';

import styled from 'styled-components';
import { useSlideState } from 'components/hooks'
import { Arrow, Container, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'
import { useHistory } from 'react-router-dom';

import img1 from './assets/img1.png';
import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';

import Slide8 from '../slide8'
import Slide9 from '../slide9'
import Slide11 from '../slide11'

const Slide = styled.div`
    > div > .box3 {
        padding-left: 200px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    console.log('render slide')

    const history = useHistory();

    return (
        <Slide className="centered">
            <h1>Daher auch bei Tageslinsen: <strong>Produkteigenschaften und<br />
Bedürfnis zusammenbringen</strong></h1>
            <Container left={328} top={340} width={1392} height={119} className="rounded color1 flex-all-center text-center">
                <p className="center"><strong>Eine präzise Bedürfnisanalyse auf Ebene des Produktes und<br />
des Shopper Typen ist wichtig!</strong></p>
                <MoreBtn
                    className="x-right y-center"
                    onClick={() => { toggleOverlay('overlay1')}} />
            </Container>

            <div className="container-animate fadeInFromTop">
            <Container left={530} top={530} width={988} height={380} className="bg1 color1 flex-all-center text text-center">
                <Arrow width={424} className="container-top" />
                <p><strong>Produkt</strong></p>
                <p>Eine Bedürfnisnalyse über die gewünschte<br />
Tragehäufigkeit hinaus ist wichtig.</p>
                <p><strong>Und es sollte immer mit der best-<br />
möglichen Kontaktlinse gestartet werden.</strong></p>
                <MoreBtn
                    className="x-right y-center"
                    style={{marginTop: '-34px'}}
                    onClick={() => { toggleOverlay('overlay2') }} />
                <MoreBtn
                    className="x-right y-center"
                    style={{marginTop: '34px'}}
                    onClick={() => { toggleOverlay('overlay3') }} />
            </Container>
            <Image left={1587} top={591} width={121} height={121} src={icon1} imageWidth={80} className="rounded border image-center" />
            <Image left={1587} top={728} width={121} height={121} src={icon2} imageWidth={66} className="rounded border image-center" />
            </div>
            <div className="container-animate fadeInFromTop animate2">
            <Container left={304} top={986} width={1446} height={200} className="bg1 color1 flex-all-center box3">
                <Arrow width={424} className="container-top" />
                <p><strong>ACUVUE® OASYS 1-Day sollte die erste Linse<br />
sein, die ein Träger probiert.</strong></p>
                <MoreBtn
                    className="x-right y-center"
                    onClick={() => history.push('/folders/familie') } />
            </Container>
            <Image  left={319} top={1012} width={363} height={126} src={img1} />
            </div>

            <Overlay className="overlay1 with-bg" active={state.overlay1 === 'overlay1'}>
                <Container left={10} top={10} width={2024} height={1254} className="rounded">
                </Container>
                <Slide11 active={state.overlay1 === 'overlay1'} />
                <CloseBtn
                        left={1935} top={47}
                        style={{zIndex: 190}}
                        onClick={() => toggleOverlay('overlay1')}
                        className="more1 test" />
            </Overlay>

            <Overlay className="overlay2 with-bg" active={state.overlay1 === 'overlay2'}>
                <Container left={10} top={10} width={2024} height={1254} className="rounded">
                </Container>
                <Slide8 active={state.overlay1 === 'overlay2'} />
                <CloseBtn
                        left={1935} top={47}
                        style={{zIndex: 190}}
                        onClick={() => toggleOverlay('overlay2')}
                        className="more1" />
            </Overlay>

            <Overlay className="overlay3 with-bg" active={state.overlay1 === 'overlay3'}>
                <Container left={10} top={10} width={2024} height={1254} className="rounded">
                </Container>
                <Slide9 active={state.overlay1 === 'overlay3'} />
                <CloseBtn
                        left={1935} top={47}
                        style={{zIndex: 190}}
                        onClick={() => toggleOverlay('overlay3')}
                        className="more1" />
            </Overlay>

        </Slide>
    );
};

