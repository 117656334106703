import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.png';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';

import stoerer from './assets/stoerer.svg';

const Slide = styled.div`
    .box1 .number {
        position: absolute;
        display: block;
        width: 70px;
        text-align: right;
        left: -61px;
        top: -14px;
        font-size: 65px;
        line-height: 65px;
        color: #390075;
        font-style: italic;
        text-align: right;
    } 

    .box2 {
        border-radius: 50%;
        color: #fff;
    }

    .img3 {
        position: absolute;
        left: 88.5px;
        width: 856px;

        &.img3-1 {
            top: 313px;
        }

        &.img3-2 {
            top: 585px;
        }
    }

    .box3 {
        overflow: hidden;

        .img4 {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
        }
    }

    .box4 {
        padding-top: 33px;
        padding-left: 33px;
    }

    .box5 {
        border-radius: 50%;
        background: #002C8B;
        color: #fff;
        text-transform: uppercase;

        font-size: 44px;
        line-height: 44px;
        padding-top: 6px;

        span {
            margin-top: -12px;
            display: block;
            font-size: 22.5px;
            line-height: 44px;
        }
    }

    .box6 {
        color: #fff;
        font-size: 30px;
        line-height: 37.5px;
        font-weight: 600;

        span {
            display: inline-block;
            width: 17px;
        }
    }

    .box7 {
        background: #E4DAEB;
    }

    .stoerer {
        position: absolute;
        width: 203px;
        height: 160px;
        background: url(${stoerer});
        background-size: contain;
        background-repeat: no-repeat;
        font-size: 17px;
        line-height: 20.5px;
        padding-top: 23px;

        a {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        p {
            text-align: center;
            transform: rotate(6deg);
        }

        &.stoerer1 {
            left: 860px;
            top: 133px;
        }

        &.stoerer2 {
            left: 534px;
            top: 440px;
        }
    }
`
const inititialState = {
    overlay1: 'overlay1'
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>ACUVUE® startet Verbraucherkampagnen zur Wiederbelebung<br />
            der Kategorie Kontaktlinse</strong></h1>
            <h2 className="small">Unser Ziel: Gewinnung und Bindung von neuen Kundinnen und Kunden zur Wiederbelebung<br />
der Kategorie bis Dezember 2021.</h2>
            <Container left={196} top={396} width={1018} height={119} className="rounded rounded-full color1 flex-all-center text-center box1">
                <span className="number">3.</span>
                <p><strong>Begeistern Sie Ihre Kunden für Kontaktlinsen</strong></p>
            </Container>

            <Container left={100} top={561} width={1848} height={676} className="bg1">
                <Arrow width={1713} className="container-top" />
            </Container>

            <Container left={279} top={690} width={344} height={344} className="bg2 box2 flex-all-center text-center" >
                <p style={{marginTop: '-37px'}}><strong>#DeinLeben<br />
DeinMoment<br />
Kampagne</strong></p>
            </Container>
            <Container left={852} top={690} width={344} height={344} className="bg2 box2 flex-all-center text-center">
                <p><strong>Gewinnen</strong></p>
            </Container>
            <Container left={1425} top={690} width={344} height={344} className="bg2 box2 flex-all-center text-center">
                <p  style={{marginTop: '-7px'}}><strong>Geld-Zurück-<br />
Garantie auf<br />
alle ACUVUE®<br />
Produkte</strong></p>
            </Container>

            <Image left={313} top={916} width={275} height={195} src={img1} />
            <Image left={1392} top={967} width={403} height={133} src={img2} />

            <img src={img3} className="img3 img3-1" />
            <img src={img3} className="img3 img3-2" />

            <Container as="p" left={551} top={618} width={944} height={43} className="box7 text-center color1">
                <p><strong>Customer Journey von Kontaktlinsenkunden</strong></p>
            </Container>

            <Container as="p" left={273} top={1140} width={321} height={84} className="box7 text-center flex-all-center color1">
                <p><strong>Interessenten</strong></p>
            </Container>

            <Container as="p" left={816} top={1140} width={415} height={84} className="box7 text-center flex-all-center color1">
                <p><strong>Neuanpassungen</strong></p>
            </Container>

            <Container as="p" left={1343} top={1140} width={499} height={84} className="box7 text-center flex-all-center color1">
                <p><strong>Kundenabwanderung<br /> 
verringern</strong></p>
            </Container>

            <div className="stoerer stoerer1">
            <a href="#"></a>
            <p className="color1"><strong>Marketing-<br />
Materialien</strong> zu<br /> 
Ihrer Verwendung<br /> 
finden Sie bei<br />
<strong>MyAccount</strong></p>
            </div>

            <MoreBtn
                left={274} top={1064}
                onClick={() => toggleOverlay('overlay1')}
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg bg-white">
                <Container left={100} top={100} width={1848} height={1120} className="bg2 box3">
                    <img src={img4} className="img4" />
                </Container>

                <Container left={172} top={194} width={1715} className="box6">
                    <p><strong>#DeinLebenDeinMoment 2021<br />
  <span></span>20% Aktion</strong></p>
                </Container>

                <Container left={172} top={370} width={945} height={773} className="rounded color1 box4">
                    <p>Diese große Kampagne ist ein<br /> 
Eye-Catcher in zahlreichen Optiker-<br />
geschäften. ACUVUE® OASYS 1-Day<br /> 
macht mit der <strong>20% Aktion</strong> nicht nur<br /> 
auf sich, sondern auch auf die Optiker<br /> 
aufmerksam. Die Kunden können beim<br /> 
Linsenkauf im Geschäft 20% sparen.<br /><br />

<strong>Dieses Angebot kommt nicht nur bei<br /> 
den Optikern, sondern auch bei den<br />
Kunden gut an:</strong> Die überproportionale<br /> 
Einsendung von Gutscheinen und das<br /> 
positive Feedback auf die Social Media<br />
Postings beweisen es.</p>
                </Container>
                <Image left={1005} top={301} width={538} height={177} src={img5} />

                <Container left={1069} top={414} width={245} height={245} className="box5 flex-all-center text-center">
                    <p><strong>20%<br />
                    <span>Sparen</span>
                    </strong></p>
                </Container>

                <div className="stoerer stoerer2">
            <a href="#"></a>
            <p className="color1"><strong>Marketing-<br />
Materialien</strong> zu<br /> 
Ihrer Verwendung<br /> 
finden Sie bei<br />
<strong>MyAccount</strong></p>
            </div>

                <CloseBtn
                        left={1855} top={127}
                        onClick={() => toggleOverlay('overlay1')}
                        color={'#A8ABAE'}
                    />

            </Overlay>

            

        </Slide>
    );
};
