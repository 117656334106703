import React from 'react';
import styled from 'styled-components'

import bg from './assets/bg.jpg'

const Slide = styled.div`
    background: url(${bg});
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: 290px;
        font-size: 53px;
        line-height: 56px;
        color: #fff;

        strong {
            font-weight: 600;
        }

        > * {
            display: block;
        }

        span {
            margin-top: 10px;
        }
    }
`

const Folder2Slide1 = () => {
    return (
        <Slide className="bg full">
            <h1><strong>Mein Optiker<br />
                2023</strong>
                <span>Kampagne</span>
            </h1>
        </Slide>
    );
};

export default Folder2Slide1;