import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';

const Slide = styled.div`
    .box1 {
        padding-left: 94px;
    }


    .box2 {
        padding-left: 32px;

        p + p {
            margin-top: 11px;
        }
    }

   

    .number {
        position: absolute;
        background: #df9933;
        color: #fff;
        border-radius: 50%;
        font-weight: 600;
        text-align: center;
    }

    .number1 {
        left: -1.8px;
        top: -1.8px;
        width: 83px;
        height: 83px;
        font-size: 62px;
        line-height: 83px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})`, bottom: 0 }}></div>
            <Slide className="centered">
                <h1><strong>Zusammenfassung</strong></h1>

                <Container left={100} top={352} width={855} height={166} className="rounded box1 color1 flex-all-center">
                    <span className="number number1">1</span>
                    <p><strong>Echte Kundenbindung findet<br />nicht über Eigenmarke statt</strong></p>

                    <MoreBtn
                        className="x-right y-center"
                        active={state.overlay1 === 'overlay1'}
                        onClick={() => toggleOverlay('overlay1')} />

                </Container>

                <Container left={100} top={587} width={855} height={166} className="rounded box1 color1 flex-all-center">
                    <span className="number number1">2</span>
                    <p><strong>Echte Kundenbindung findet<br />
durch Bedürfniserfüllung statt.</strong></p>

                    <MoreBtn
                        className="x-right y-center"
                        active={state.overlay1 === 'overlay2'}
                        onClick={() => toggleOverlay('overlay2')} />

                </Container>

                <Container left={100} top={822} width={855} height={166} className="rounded box1 color1 flex-all-center">
                    <span className="number number1">3</span>
                    <p><strong>„Kompetenz“-Umsetzung<br />
in der Praxis</strong></p>

                    <MoreBtn
                        className="x-right y-center"
                        active={state.overlay1 === 'overlay3'}
                        onClick={() => toggleOverlay('overlay3')} />
                </Container>

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                    <Container left={955} top={338} width={645} height={200} className="rounded color1 flex-all-center box2 text">
                        <p><strong>Internetverfügbarkeit</strong></p>
                        <p><strong>Alte/wechselnde Produkte</strong></p>
                    </Container>
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay2'} className="with-bg">
                    <Container left={955} top={542} width={517} height={294} className="rounded color1 flex-all-center box2 text">
                        <p><strong>Bedürfniserfüllung<br /> 
= Kompetenz<br />
= Vertrauen<br /> 
= Kundenbindung</strong></p>
                    </Container>
                </Overlay>


                <Overlay active={state.overlay1 === 'overlay3'} className="with-bg">
                    <Container left={955} top={744} width={865} height={322} className="rounded color1 flex-all-center box2 text">
                        <p><strong>Portfolio mit ACUVUE® (Kompetenz)<br />und Eigenmarke (Preis)</strong></p>
                        <p><strong>Einfache und einheitliche Prozesse<br />
                        an Kundenbedürfnissen orientiert</strong></p>
                    </Container>
                </Overlay>


            </Slide>
        </>
    );
};
