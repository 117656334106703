import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay, PlayBtn } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

import img5 from './assets/img5.gif';
import img6 from './assets/img6.gif';

const Slide = styled.div`
    .img3 {
        position: absolute;
        left: 395px;
        top: 356px;
        width: 238px;
        border: 0.5px solid #003087; 
    }

    .overlay1 {

        .box1 {
            background: #9CE2E5 !important;
        }

        .box2 { 
            ul {
                margin-top: 10px;
                padding-left: 13px;
            }
            
            li {
                position: relative;
            }

            li + li {
                margin-top: 7px;
            }

            li::before {
                position: absolute;
                left: -15px;
                top: 0;
                content:"·";
                font-size:45px;
                vertical-align:middle;
                
            }
        }

        .box3 {
            line-height: 27px;
        }

        .line1 {
            display: block;
            position: absolute;
            z-index: 120;
            left: 406px;
            top: 85px;
            width: 2px;
            height: 490px;
            border-radius: 1px;
            background: #fff;
        }
    } 

    .overlay2 {
        video {
            position: absolute;
            left: 30px;
            top: 30px;
        }
    }

    .overlay3 {
        .box4 {
            font-size: 30px;
            line-height: 37.5px;
        }

        .img5,
        .img6 {
            position: absolute;
            top: 243px;
            width: 353px;
            height: 254px;
            border: 4px solid #fff;
        }

        .img5 {
            left: 102px;
        }

        .img6 {
            left: 578px;
        }

        .label {
            position: absolute;
            left: 947px;
            top: 92px;
            height: 400px;
            font-size: 10px;
            line-height: 12px;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            
            
        }
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    useEffect(() => {

        var mediaElement = document.getElementById("video");
        if (mediaElement) {
            if (state.overlay1 === 'overlay2') {
                mediaElement.play();
            } else {
                mediaElement.pause();
                mediaElement.currentTime = 0;
            }
        }


    }, [state.overlay1])

    return (
        <Slide className="centered" step={props.step}>
            <h1>Während traditionelle Prismen- und Peri-Ballast-Systeme <strong>in<br />
                stärkerem Maße von der Schwerkraft beeinflusst werden …</strong></h1>

            <Container left={327} top={301} width={1394} height={178} className="rounded rounded-full flex-all-center text-center color1">
                <p>... nutzt das <strong>Lidschlag-Stabilisations-Design von ACUVUE®</strong><br />
                    die Kraft natürlicher Lidbewegungen zur Ausrichtung und<br />
                    Stabilisierung der Kontaktlinse<sup>35</sup></p>
            </Container>

            <div className="container-animate fadeInFromTop">

                <Container left={285} top={536} width={1487} height={664} className="bg1 text-center color1">
                    <Arrow width={1318} className="container-top" />
                </Container>

                <Image left={374} top={656} width={364} height={382} src={img1} />
                <Image left={1317} top={656} width={364} height={382} src={img2} />

                <img src={img3} className="img3" onClick={() => {
                    toggleOverlay('overlay2')
                }
                } />

                <PlayBtn
                    left={987} top={805}
                    onClick={() => toggleOverlay('overlay2')} />

                <Container left={285} top={589} width={1487} className="color1 text-center">
                    <p><strong>Linsenstabilisierung</strong></p>
                </Container>

                <Container left={324} top={1068} width={464} className="color1 text-center">
                    <p><strong>Lidschlag-<br />
                        Stabilisations-Design</strong></p>
                </Container>
                <Container left={1267} top={1068} width={464} className="color1 text-center">
                    <p><strong>Prismen-Ballast-<br />
                        Design</strong></p>
                </Container>
                <Container left={794} top={1068} width={464} className="color1 text-center">
                    <p><strong>Film</strong></p>
                </Container>

                <MoreBtn
                    left={1730} top={582}
                    onClick={() => toggleOverlay('overlay1')}
                />

                <MoreBtn
                    left={246} top={582}
                    onClick={() => toggleOverlay('overlay3')}
                />

            </div>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                <Container left={100} top={100} width={1848} height={1120} className="bg2 box1">

                </Container>

                <Image left={241} top={216} width={1648} height={444} src={img4} />

                <Container left={157} top={680} width={610} height={119} className="rounded rounded-full flex-all-center text-center color1 box3">
                    <p><strong>Lidschlag-Stabilisations-<br />Design</strong></p>
                </Container>

                <Container left={849} top={680} width={540} height={119} className="rounded rounded-full flex-all-center text-center color1 box3">
                    <p><strong>Herkömmliche<br />
                        Prismen-Ballast-Linsen</strong></p>
                </Container>

                <Container left={1425} top={680} width={480} height={119} className="rounded rounded-full flex-all-center text-center color1 box3">
                    <p><strong>Herkömmliche<br />
                        Peri-Ballast-Linsen</strong></p>
                </Container>


                <Container left={186} top={838} width={600} className="box2 color1">
                    <ul className="small">
                        <li>nutzt die Kraft der natürlichen<br />Lidbewegungen<sup>35</sup></li>
                        <li>Horizontal und vertikal<br />symmetrisch<sup>°35</sup></li>
                        <li>Minimaler Kontakt mit dem<br />Unterlid<sup>**35</sup></li>
                    </ul>
                </Container>

                <Container left={1024} top={838} width={800} className="box2 color1">
                    <ul className="small">
                        <li>Stärker von der Schwerkraft beeinflusst<sup>35</sup></li>
                        <li>Nur vertikale Symmetrie</li>
                        <li>Unterlid interagiert mit dem dickeren Linsenbereich<sup>35</sup></li>
                    </ul>
                </Container>

                <span className="line1" />

                <CloseBtn
                    left={1855} top={127}
                    onClick={() => toggleOverlay('overlay1')} />

            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="overlay2">
                <Container left={100} top={100} width={1848} height={1120} className="bg2 box2">
                    <video id="video" width="863" height="499" controls>
                        <source src="/media/JJV_AO1D_Toric_Video_D_220426.mp4" type="video/mp4" />
                    </video>
                </Container>
                <CloseBtn
                    left={1855} top={127}
                    bgColor={'#D0F1F2'}
                    onClick={() => {
                        toggleOverlay('overlay2')
                    }} />
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay3'} className="overlay3">
                <Container left={100} top={100} width={1848} height={1120} className="bg2 box1">

                </Container>

                <Container as="p" left={424} top={170} width={1200} className="text-center color1 box4">
                    <strong>Der Unterschied</strong>
                </Container>

                <Container left={424} top={301} width={1200} height={133} className="rounded rounded-full flex-all-center text-center color1 box3">
                    <p>Geringe Verdrehung mit 4 Stabilisationszonen<br />
                        und daher stabile Sicht bei Blickbewegungen.<sup>36</sup></p>
                </Container>

                <img src={img5} className="img5" />
                <img src={img6} className="img6" />

                <Container as="p" left={290} top={1068} width={530} className="text-center color1">
                    <strong>Lidschlag-<br />
Stabilisations-Design</strong>
                </Container>

                <Container as="p" left={1276} top={1068} width={446} className="text-center color1">
                    <strong>Prismen-Ballast-
Design</strong>
                </Container>

                <p class="label">Für Illustrationszwecke</p>

                <CloseBtn
                    left={1855} top={127}
                    onClick={() => toggleOverlay('overlay3')} />

            </Overlay>

        </Slide>
    );
};
