import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';

const Slide = styled.div`
    .label {
        font-size: 15px;
        line-height: 16px;

        &.label2 {
            font-weight: 600;
            transform: rotate(-10deg);
        }
    }

    .overlay {
        .box1 {
            background: #fff;
            border: 1px solid #003087;
            border-radius: 15px;
        }

        .box1-1 {
            background-image: url(${img2});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left bottom;
            padding-top: 20px;

            span {
                letter-spacing: -0.015rem;
            }
        }

        .box1-2 {
            background-image: url(${img3});
            background-size: 214px 288px;
            background-repeat: no-repeat;
            background-position: left 11px;
            padding-left: 202px;
            padding-top: 46px;
        }
    }

    .box2 {
        font-size: 10px;
        line-height: 12px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Die Interaktion zwischen <strong>Kontaktlinse &amp; Tränenfilm</strong></h1>
            <Container as="p" left={71} top={269} width={1340} className="text-center color1">
                Einfluss der Kontaktlinse<br />
                auf die Tränenfilmstruktur bei normalem Gebrauch
            </Container>

            <Arrow left={724} top={-220} width={1280} className="rotate90" />

            <Image left={100} top={266} width={1948} height={882} src={img1} />

            <Container as="p" left={100} top={482} width={400} className="color1 label">
                Lipid-<br />
                Schicht
            </Container>

            <Container as="p" left={100} top={632} width={400} className="color1 label">
                Wässrige<br />
                Schicht
            </Container>

            <Container as="p" left={100} top={784} width={400} className="color1 label">
                Muzin-<br />
                Schicht
            </Container>

            <Container as="p" left={100} top={947} width={400} className="color1 label">
                Cornea
            </Container>

            <Container as="p" left={1052} top={718} width={400} className="color1 label">
                Linse
            </Container>

            <Container as="p" left={279} top={996} width={382} className="color1 label label2">
            Natürliches Auge
            </Container>

            <Container as="p" left={707} top={996} width={382} className="color1 label label2">
            Linse bei normalem<br />
Gebrauch
            </Container>

            <MoreBtn
                left={536} top={910}
                onClick={() => toggleOverlay('overlay1')}
                active={state.overlay1 === 'overlay1'}
            />

            <MoreBtn
                left={987} top={910}
                onClick={() => toggleOverlay('overlay2')}
                active={state.overlay1 === 'overlay2'}
            />

<Container as="p" left={1646} top={1219} width={340} className="box2">
            Bilder nur zu Illustrationszwecken.
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay overlay1 with-bg">
                <Container left={88} top={475} width={492} height={618} className="text-center color1 box1 box1-1">
                    <p>Muzine sind kritische<br /> 
Bestandteile der<br /> 
<span>natürlichen Tränenfilm-</span><br />
stabilität.<sup>10</sup></p>
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="overlay overlay2 with-bg">
                <Container left={88} top={475} width={942} height={618} className="text-center color1 box1 box1-2">
                    <p>Eine Kontaktlinse<br /> 
kann die membran-<br />
gebundenen Muzine<br /> 
von den frei<br /> 
schwimmenden<br /> 
Muzinen im Tränenfilm<br />
trennen, wodurch<br /> 
eine Instabilität<br /> 
entsteht.</p>
                </Container>
            </Overlay>

        </Slide>
    );
};
