import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    h1 {
       color: #0a7cc1; 
    }

    h2 {
        margin: 20px 0 10px 0;
        font-size: 20px;
        line-height: 24px;
        color: #0a7cc1;
    }

    .list {
        display: flex;

        .symbol {
            flex: 0 0 18px;
        }
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
                <p className="list"><span className="symbol">^</span> n=172 ± n=378</p>

                <p className="list"><span className="symbol">1.</span>  Considerer Studie 2021</p>

                <p className="list"><span className="symbol">2.</span>  COVID-19: Screen Time spikes to over 13 hours per day according to Eyesafe Nielsen estimates, published 3/28/20, https://eyesafe.com/covid-19-screen-time-spike-to-over-13-hours-per-day/</p>

                <p className="list"><span className="symbol">3.</span>  JJV data on file, survey fielded to 468 contact lens wearing patients in the US in April 2022</p>

                <p className="list"><span className="symbol">4.</span> Eyesafe estimate based upon Nielsen Q3 2019 Total Audience Report.</p> 

                <p className="list"><span className="symbol">5.</span> Tsubota K, Nakamori K. Dry eyes and video display terminals. N Engl J Med. 1993;328(8):584. doi: 10.1056/NEJM199302253280817.</p> 

                <p className="list"><span className="symbol">6.</span> JJV Data on File 2022. TearStable™ Technology Definition.</p>
               
                <h2><strong>MAX</strong> Tragekomfort, <strong>MAX</strong> Sehqualität im Vergleich zu ACUVUE® OASYS 1-Day</h2>

                <p>Ein Nutzen der Filterung von HEV-Licht durch Kontaktlinsen für die systemische und/oder die Augengesundheit des Anwenders
ist nicht nachgewiesen. Für weitere Informationen wenden Sie sich an den Kontaktlinsenspezialisten.</p>

<p>Wichtige Sicherheitsinformationen: Kontaktlinsen der Marke ACUVUE® dienen zur Korrektion des Sehvermögens. Wie bei allen Kontaktlinsen können Probleme mit den Augen, 
einschließlich Hornhautgeschwüre, auftreten. Bei manchen Kontaktlinsenträgern können leichte Reizungen, Jucken oder Unbehagen auftreten. Kontaktlinsen sollten nicht bei 
Augeninfektionen oder anderen Augenerkrankungen oder bei systemischen Erkrankungen, die das Auge beeinträchtigen können, verwendet werden. Vollständige Informationen, 
einschließlich Kontraindikationen, Vorsichtsmaßnahmen und Nebenwirkungen, entnehmen Sie der Gebrauchsanweisung oder besuchen Sie unsere J&J-Website jnjvisioncare.de/at.</p>

<p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse-1- oder Klasse 2-UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen. 
UV-absorbierende Kontaktlinsen sind KEIN Ersatz für UV-absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken. UV-Transmission 
mit –1,00-dpt-Linse gemessen.</p>

<p>Alle hier genannten Fremdmarken sind Eigentum des jeweiligen Markeninhabers.</p>

<p>ACUVUE®, ACUVUE® OASYS MAX 1-Day, ACUVUE® OASYS MAX 1-Day MULTIFOCAL, TearStable™ und OptiBlue™ sind eingetragene Marken von Johnson & Johnson. 
© Johnson & Johnson Medical GmbH / Medical Products GmbH 2022</p>
            </TextBox>
        </Slide>
    );
};
