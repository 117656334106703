import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import img1 from './assets/img1.png';


import img4 from './assets/img4.jpg';
import img5 from './assets/img5.svg';
import img6 from './assets/img6.svg';
import img7 from './assets/img7.svg';

import img8 from './assets/img8.svg';
import img9 from './assets/img9.svg';


const animation1 = keyframes`
    0% {
       width: 0;
    }
    100% {  
        width: 469px;
    }
`
const animation2 = keyframes`
    0% {
       height: 0;
    }
    100% {  
        height: 103px;
    }
`

const animation3 = keyframes`
    0% {
       height: 0;
    }
    100% {  
        height: 81px;
    }
`

const animation4 = keyframes`
    0% {
       opacity: 0;
    }
    100% {  
        opacity: 1;
    }
`

const animation5 = keyframes`
    0% {
       width: 0;
    }
    100% {  
        width: 275px;
    }
`


const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    
    .box1 {
        background: #E0F5F1;
    }

    .circle1 {
        background: #88d7ce;
        border-radius: 999999px;
    }

    .box2 {
        font-size: 10px;
        line-height: 11px;
    }

    .box3,
    .box5,
    .box6,
    .box7,
    .box8 {
        font-size: 18.5px;
        line-height: 24px;

        &.box3-1 {
            letter-spacing: -0.02rem;
        }
    }

    .box6,
    .box7 {
        opacity: 0;

        &.animate {
            animation: ${animation4} 1s forwards ease-out;
            animation-delay: 0.8s;
        }  
    }

    .box4 {
        font-size: 30px;
        line-height: 37.5px;
    }

    .img4 {
        position: absolute;
        left: 342px;
        top: 217px;
        width: 515px;
        border-radius: 15px;
    }

    .img-container1 {
        position: absolute;
        overflow: hidden;
        left: 365px;
        width: 0px;
        height: 100%;

        .img5 {
            position: absolute;
            left: 0;
            top: 316px;
            width: 468px;
        }

        .img6 {
            position: absolute;
            right: 0;
            top: 325px;
            width: 469px;
        }

        &.animate {
            animation: ${animation1} 1s forwards ease-out;
        }
    }

    .overlay1 {
        height: 100%;
    }

    .img-container2,
    .img-container3 {
        position: absolute;
        overflow: hidden;
        
        img {
            position: absolute;
            width: 10px;
            left: 0;
            top: 0;
        }

        &.img-container2 {
            left: 387px;
            bottom: 310px;
            width: 100px;
            height: 0;

            &.animate {
                animation: ${animation2} 1s forwards ease-out;
            }

        }

        &.img-container3 {
            left: 591px;
            bottom: 330px;
            width: 100px;
            height: 0;

            &.animate {
                animation: ${animation3} 1s forwards ease-out;
            }
        }
    }

    .img8 {
        position: absolute;
        width: 293px;
        left: 564px;
        top: 379px;
    }
    
    .img-container4 {
        position: absolute;
        overflow: hidden;
        left: 581px;
        top: 388px;
        width: 0;
        height: 400px;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 275px;
        }

        &.animate {
            animation: ${animation5} 1s forwards ease-out;
            animation-delay: 1s;
        }
    }

    .box9 {
        font-size: 10px;
        line-height: 10.5px;
        color: #000;
    }

    .container-animate.step2 {
        opacity: 0;

        &.animate {
            animation: ${animation4} 1s forwards ease-out;
        
        }  
    }

    .container-animate.step3 {
        opacity: 0;

        &.animate {
            animation: ${animation4} 1s forwards ease-out;
            animation-delay: 2s;
        }  
    }


   
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const [stepOverlay, setStepOverlay] = useState(0)


    useEffect(() => {
        if (state.overlay1 === 'overlay1') {
            setStepOverlay(0)
        }

    }, [state])

    const clickOverlay = () => {

        const stepOverlayNew = stepOverlay + 1;

        if (stepOverlayNew > 2) stepOverlayNew = 2;

        setStepOverlay(stepOverlayNew)
    }

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Das ACUVUE® MULTIFOCAL Portfolio</strong> – innovative Kontaktlinsen mit pupillenoptimiertem Design</h1>

            <Container left={-300} top={624} width={2648} height={362} className="box1">

            </Container>

            <Image left={329} top={420} width={1472} height={571} src={img1} />

            <Container as="p" left={131} top={375} width={270} height={270} className="color1 circle1 flex-all-center text-center">
                <strong> 2-Wochen-<br />
                    Austausch</strong>
            </Container>

            <MoreBtn
                left={224} top={593}
                className=""

                onClick={() => toggleOverlay('overlay1')} />

            <Container as="p" left={1229} top={236} width={270} height={270} className="color1 circle1 flex-all-center text-center">
                <strong>Täglicher<br />
                    Austausch</strong>
            </Container>

            <Container as="p" left={1651} top={337} width={270} height={270} className="color1 circle1 flex-all-center text-center">
                <strong>Täglicher<br />
                    Austausch</strong>
            </Container>



            <div className="container-animate fadeInFromLeft">
                <Container as="p" left={228} top={856} width={500} className="color1"><strong>Identisches Design</strong></Container>
                <Arrow left={176} top={846} width={64} className="" arrowColor={'#00a98f'} bgColor={'#E0F5F1'} />
            </div>

            <div className="container-animate fadeInFromLeft animate2">
                <Container as="p" left={712} top={856} width={500} className="color1"><strong>Identische Parameter</strong></Container>
                <Arrow left={660} top={846} width={64} className="" arrowColor={'#00a98f'} bgColor={'#E0F5F1'} />
            </div>

            <div className="container-animate fadeInFromLeft animate3">
                <Container as="p" left={1235} top={856} width={700} className="color1"><strong>Identischer Anpassungsprozess</strong></Container>
                <Arrow left={1183} top={846} width={64} className="" arrowColor={'#00a98f'} bgColor={'#E0F5F1'} />
            </div>

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg overlay1" onClick={() => clickOverlay()}>
                <Container left={75} top={100} width={1898} height={1103} className="box3 bg2 color1 border">

                </Container>

                <Container as="p" left={147} top={163} width={1740} className="box4 color1">
                    Vorteile eines <strong>kürzeren</strong> Austausch-Rhythmus
                </Container>

                <Container as="p" left={147} top={256} width={1740} className="box5 color1">
                    In einer Umfrage von Monatslinsenträgern anderer Marken sind ab Woche 3 und 4<br />
                    <strong>50% der Kontaktlinsenträger unzufrieden mit dem Tragekomfort.<sup>4,5</sup></strong>
                </Container>

                <Container left={300} top={393} width={1451} height={300} className="bg1 color1 text-center  flex-all-center">

                </Container>



                <Container left={337} top={393} width={1451} height={300} className="bg1 color1 flex-all-center box3 box3-1">
                    <p>Im Verlauf eines<br />
                        Monats können<br />
                        sich <strong><span>Ablagerungen</span><br />
                            auf der Kontakt-<br />
                            linse</strong> bilden.<sup>‡</sup></p>
                </Container>





                <img src={img4} className="img4" />

                <div className={'img-container1' + (state.overlay1 === 'overlay1' ? ' animate' : '')}>
                    <img src={img5} className="img5" />
                    <img src={img6} className="img6" />
                </div>

                <div className={'img-container2' + (stepOverlay >= 1 ? ' animate' : '')}>
                    <img src={img7} />
                </div>

                <div className={'img-container3' + (stepOverlay >= 1 ? ' animate' : '')}>
                    <img src={img7} />
                </div>

                <Container as="p" left={806} top={455} className={'color1 box6' + (stepOverlay >= 1 ? ' animate' : '')}>
                    <strong>täglich</strong>
                </Container>

                <Container as="p" left={1212} top={455} width={600} className={'color1 box7' + (stepOverlay >= 1 ? ' animate' : '')}>
                    <strong>2x monatlich</strong>
                </Container>

                <div className={'container-animate step2' + (stepOverlay >= 2 ? ' animate' : '')}>

                    <Container left={300} top={723} width={1451} height={300} className="bg1 color1 text-center">

                    </Container>

                    <Container left={337} top={723} width={1451} height={300} className="bg1 color1 flex-all-center box3">
                        <p><strong>Abnehmender<br />
                            Tragekomfort<br />
                            am Ende des Tages</strong><br />
                            bei führenden<br />
                            Monatslinsenmarken<sup>¶6</sup></p>
                    </Container>

                    <Container as="p" left={806} top={806} width={600} className="color1 box8">
                        <strong>Komfort in Top 2</strong>
                    </Container>

                    <img src={img8} className="img8" />

                    <div className={'img-container4' + (stepOverlay >= 2 ? ' animate' : '')}>
                        <img src={img9} />
                    </div>

                    <Container as="p" left={806} top={852} width={290} className="color1 box9">
                        <strong>Top 2:</strong><br />
                        „sehr komfortabel“ oder<br />
                        „äußerst komfortabel“<br />
                        auf einer 7-Punkte-Skala.
                    </Container>

                </div>

                <div className={'container-animate step3 ' + (stepOverlay >= 2 ? ' animate' : '')}>

                    <Container as="p" left={300} top={1120} width={1471} height={300} className="color1">
                        <strong>Unzufriedenheit in Woche 3 und 4</strong> kann die Zahl der Aussteiger erhöhen.
                    </Container>

                    <Arrow left={1009} top={354} width={1448} className="rotate90" arrowColor={'#00a98f'} />
                </div>


                <CloseBtn
                    left={1934} top={67}
                    onClick={() => toggleOverlay('overlay1')}
                    bgColor={'#ffffff'}
                    color={'#788a86'}
                />

            </Overlay >

        </Slide >
    );
};
