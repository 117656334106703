import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow4, Container, Image, MoreBtn, Overlay } from 'components/parts'

import table from './assets/table.svg';
import icon1 from '../slide24/assets/icon1.svg';
import icon2 from '../slide24/assets/icon2.svg';
import icon3 from '../slide24/assets/icon3.svg';
import icon4 from '../slide24/assets/icon4.svg';

import logo1 from './assets/logo1.svg';
import logo2 from './assets/logo2.png';

import img1 from './assets/img1.png';

const barAnimation = keyframes`
    0% {
        transform: scaleX(0) translate3d(0, 0, 100px);
        opacity: 1;
    }
    100% {  
        transform: scaleX(100%) translate3d(0, 0, 100px);
        opacity: 1;
    }
`

const Slide = styled.div`

    .legend {
        padding-top: 15px;
        padding-right: 13px;
        font-size: 13.75px;
        line-height: 16.5px;
        text-align: right;
        font-weight: 600;

        .label2 {
            margin-top: 32px;
        }

        .logo1 {
            width: 103px;
            margin-top: 60px;
        }

        .logo2 {
            margin-top: 31px;
            width: 107px;
        }

        .label3 {
            margin-top: 30px;
        }

        .label4 {
            margin-top: 38px;
        }
    }

    .box1 {
        z-index: 10;
        align-items: center;
        justify-content: flex-start;

        p {
            color: #000;
            opacity: 0.8;
        }
    }

    .box1-1 img {
       margin-left: 17px;
       margin-right: 7px;
    }

    .box1-2 img {
       margin-left: 12px;
       margin-right: 7px;
    }

    .box1-3 {
        img {
            margin-left: 12px;
            margin-right: 7px;
        }

        p {
            font-size: 15px;
            line-height: 18px;
        }
    }

    .box1-4 img {
       margin-left: 13px;
       margin-right: 7px;
    }

    .bars {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        transform: translate3d(0, 0, 100px);
    }

    .test {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        transform: translate3d(0, 0, 200px);
    }

    .bar {
        position: absolute;
        background: #003087;
        height: 41.5px;
        width: 0;
        transform-origin: left top;

        ${({ preview }) => !preview && css`
           opacity: 0;
        `}
        
        ${({ active, preview }) => (active && !preview) && css`
            animation: ${barAnimation} 2s forwards;
            animation-delay: 0.6s;
        `}
        
    }

    .bar-c1 { left: 236.5px; }
    .bar-c2 { left: 444.5px; }
    .bar-c3 { left: 648.5px; }
    .bar-c4 { left: 860px; }

    .bar-r1 { top: 260.5px; }
    .bar-r2 { top: 326px; }
    .bar-r3 { top: 391px; }
    .bar-r4 { top: 455.5px; }
    .bar-r5 { top: 520.5px; }

    .bar1 { width: 53px }
    .bar2 { width: 92px }
    .bar3 { width: 78px }
    .bar4 { width: 121.5px }
    .bar5 { width: 74px }

    .bar6 { width: 62.5px }
    .bar7 { width: 61px }
    .bar8 { width: 52.5px }
    .bar9 { width: 39px }
    .bar10 { width: 54.5px }

    .bar11 { width: 33px }
    .bar12 { width: 19.5px }
    .bar13 { width: 31px }
    .bar14 { width: 21.5px }
    .bar15 { width: 29px }

    .bar16 { width: 47px }
    .bar17 { width: 23.5px }
    .bar18 { width: 33px }
    .bar19 { width: 7.5px }
    .bar20 { width: 37px }

    .value {
        z-index: 10;
        position: absolute;
        font-weight: 600;
        font-size: 17.5px;
        line-height: 41.5px;
        color: #303030;
        transform: scaleX(100%);
    }

    .value.bar-c1 {
        margin-left: 90px;
    }

    .value.bar-c2 {
        margin-left: 95px;
    }

    .value.bar-c3 {
        margin-left: 95px;
    }

    .value.bar-c4 {
        margin-left: 45px;
    }

    .value4 {
        color: #fff;
    }

    .arrow1,
    .arrow5,
    .arrow6,
    .arrow7 {
        transform: rotate(90deg);
        z-index: 11;
    }

    .arrow2,
    .arrow3,
    .arrow4,
    .arrow8 {
        transform: rotate(-90deg);
        z-index: 11;
    }

    .box2 {
        padding-left: 70px;
    }

    .img1 {
        z-index: 12;
    }

    .top {
        transform: translate3d(0, 0, 300px);
    }
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1>Im Fachhandel kaufen <strong>besonders viel Kompetenz- und<br /> 
Gesundheitsorientierte Kontaktlinsenträger<sup>4</sup></strong></h1>
            <h2>Die entsprechende Bedürfniserfüllung ist wichtig</h2>

            <Image left={100} top={356} width={1848} height={787} src={table} />

            <Container left={100} top={356} width={258} height={788} className="legend">
                <p>Letzter<br /> 
Kaufort von<br />
Kontaktlinsen<sup>7</sup></p>
                <p className="label2">Internet</p>

                <img src={logo1} className="logo1" />
                <img src={logo2} className="logo2" />

                <p className="label3">Traditioneller
Fachhandel</p>
                <p className="label4">Rest</p>
            </Container>

            <Container left={399.26} top={356.58} width={421.63} height={119.22} className="rounded flex box1 box1-1">
                <img src={icon1} width="59" />
                <p className="small"><strong>Gesundheit/ Kompetenz</strong></p>
            </Container>

            <Container left={841.63} top={356.58} width={351.63} height={119.22} className="rounded flex box1 box1-2">
                <img src={icon2} width="63" />
                <p className="small"><strong>Angebot</strong></p>
            </Container>

            <Container left={1214} top={356.58} width={421.63} height={119.22} className="rounded flex box1 box1-3">
                <img src={icon3} width="62" />
                <p className="small"><strong>Bequemlichkeit</strong></p>
            </Container>

            <Container left={1656.36} top={356.58} width={291.63} height={119.22} className="rounded flex box1 box1-4">
                <img src={icon4} width="56" />
                <p className="small"><strong>Preis</strong></p>
            </Container>
            
            <div className="bars">
                <span className="bar bar1 bar-c1 bar-r1" />
                <span className="bar bar2 bar-c1 bar-r2" />
                <span className="bar bar3 bar-c1 bar-r3" />
                <span className="bar bar4 bar-c1 bar-r4" />
                <span className="bar bar5 bar-c1 bar-r5" />

                <span className="bar bar6 bar-c2 bar-r1" />
                <span className="bar bar7 bar-c2 bar-r2" />
                <span className="bar bar8 bar-c2 bar-r3" />
                <span className="bar bar9 bar-c2 bar-r4" />
                <span className="bar bar10 bar-c2 bar-r5" />

                <span className="bar bar11 bar-c3 bar-r1" />
                <span className="bar bar12 bar-c3 bar-r2" />
                <span className="bar bar13 bar-c3 bar-r3" />
                <span className="bar bar14 bar-c3 bar-r4" />
                <span className="bar bar15 bar-c3 bar-r5" />

                <span className="bar bar16 bar-c4 bar-r1" />
                <span className="bar bar17 bar-c4 bar-r2" />
                <span className="bar bar18 bar-c4 bar-r3" />
                <span className="bar bar19 bar-c4 bar-r4" />
                <span className="bar bar20 bar-c4 bar-r5" />
            </div>
            <div className="test">

            <span className="value value1 bar-c1 bar-r1">27%</span>
            <span className="value value2 bar-c1 bar-r2">47%</span>
            <span className="value value3 bar-c1 bar-r3">40%</span>
            <span className="value value4 bar-c1 bar-r4">65%</span>
            <span className="value value5 bar-c1 bar-r5">38%</span>

            <span className="value value6 bar-c2 bar-r1">32%</span>
            <span className="value value7 bar-c2 bar-r2">31%</span>
            <span className="value value8 bar-c2 bar-r3">27%</span>
            <span className="value value9 bar-c2 bar-r4">20%</span>
            <span className="value value10 bar-c2 bar-r5">28%</span>

            <span className="value value11 bar-c3 bar-r1">17%</span>
            <span className="value value12 bar-c3 bar-r2">10%</span>
            <span className="value value13 bar-c3 bar-r3">16%</span>
            <span className="value value14 bar-c3 bar-r4">11%</span>
            <span className="value value15 bar-c3 bar-r5">15%</span>

            <span className="value value16 bar-c4 bar-r1">24%</span>
            <span className="value value17 bar-c4 bar-r2">12%</span>
            <span className="value value18 bar-c4 bar-r3">17%</span>
            <span className="value value19 bar-c4 bar-r4">4%</span>
            <span className="value value20 bar-c4 bar-r5">19%</span>

            <Arrow4 left={552} top={536} className="arrow1" color={'#E3000F'} />
            <Arrow4 left={552} top={668} className="arrow2" color={'#009b81'} />
            <Arrow4 left={552} top={927} className="arrow3" color={'#009b81'} />
            <Arrow4 left={1740} top={536} className="arrow4" color={'#009b81'} />
            <Arrow4 left={1740} top={668} className="arrow5" color={'#E3000F'} />
            <Arrow4 left={1740} top={927} className="arrow6" color={'#E3000F'} />

            <Image left={609} top={663} width={140} height={53} src={img1} className="img1" />
            <Image left={609} top={923} width={140} height={53} src={img1} className="img1" />

            </div>
           
            <MoreBtn
                left={1864} top={156}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')}
                className="top"
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="top with-bg">
                <Container left={1185} top={174} width={747} height={214} className="rounded flex-all-center box2 text color1">
                    <p><strong>Signifikanter Unterschied<br />
zur Gesamtmenge bei<br /> 
95% Konfidenz</strong></p>
                </Container>

                <Arrow4 left={1265} top={268} className="arrow7" color={'#E3000F'} />
            <Arrow4 left={1217} top={236} className="arrow8" color={'#009b81'} />

            </Overlay>
            
            
        </Slide>
    );
};
