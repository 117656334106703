import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.svg';
import img5 from './assets/img5.svg';
import img6 from './assets/img6.svg';
import img7 from './assets/img7.svg';

const Slide = styled.div`

    h1 {
       position: relative;
       
       .number {
            position: absolute;
            font-family: "AvenirNext";
            font-weight: 700;
            font-style: italic;
            font-size: 125px;
            line-height: 156px;
            top: -60px;
            left: -50px;
            z-index: -1;
       }
    }
    
    .box2 {
        padding: 0;
        
        p {
            font-weight: 600;
        }

        .number {
            position: absolute;
            display: block;
            left: -10px;
            top: -46px;
            font-family: "AvenirNext";
            font-weight: 700;
            font-size: 64px;
            line-height: 80px;
            font-style: italic;
            text-align: right;
        }

        img {
            position: absolute;
        }

        &.box2-1 img {
            left: 100%;
            top: 50%;
            width: 67px;
        }

        &.box2-2 img {
            left: 100%;
            top: 40%;
            width: 81px;
        }

        &.box2-3 img {
            left: 100%;
            top: -95%;
            width: 118px;
        }

        &.box2-4 img {
            right: 100%;
            top: 50%;
            width: 72px;
        }

        &.box2-5 img {
            right: 100%;
            top: 40%;
            width: 81px;
        }

        &.box2-6 img {
            right: 100%;
            top: -93%;
            width: 117px;
        }
    }

    .box3 {
        border-radius: 99999px;

        p {
            transform: rotate(6deg);
            font-size: 14px;
            line-height: 17px;
        }

        text-decoration: none;
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><span className="number color3">2</span><strong>Unterstützung in Strategie, Training & Fortbildung </strong></h1>

            <Container left={131} top={214} width={1786} className="box1 color1 text-center" as="p">
                Neben den Strategie- und Beratungsgesprächen mit unseren ACUVUE® Account<br />
                Managern fördern Videos, Live und On-Demand Online-Seminare, durchgeführt von<br />
                unserer Professional Education Abteilung, Ihre Expertise. Wir ermöglichen lebenslanges<br />
                Lernen für alle, für die die Gesundheit anderer an erster Stelle steht.
            </Container>

            <Image left={776} top={507} width={504} height={506} src={img1} />

            <Container left={235} top={581} width={430} height={120} className="box2 box2-1 bg1 flex-all-center text-center">
                <span className="number color1">1</span>
                <p className="color1">Online-Kurse</p>
                <img src={img2} />
            </Container>

            <Container left={235} top={817} width={430} height={120} className="box2 box2-2 bg1 flex-all-center text-center fadeIn">
                <span className="number color1">2</span>
                <p className="color1">Online-Seminare</p>
                <img src={img3} />
            </Container>

            <Container left={235} top={1060} width={430} height={120} className="box2 box2-3 bg1 flex-all-center text-center fadeIn animate2">
                <span className="number color1">3</span>
                <p className="color1">On-Demand-
                    Seminare</p>
                <img src={img4} />
            </Container>


            <Container left={1400} top={581} width={430} height={120} className="box2 box2-4 bg1 flex-all-center text-center fadeIn animate3">
                <span className="number color1">4</span>
                <p className="color1">ePED</p>
                <img src={img5} />
            </Container>

            <Container left={1400} top={817} width={430} height={120} className="box2 box2-5 bg1 flex-all-center text-center fadeIn animate4">
                <span className="number color1">5</span>
                <p className="color1">Anpassrechner & Digitale Beratung</p>
                <img src={img6} />
            </Container>

            <Container left={1400} top={1060} width={430} height={120} className="box2 box2-6 bg1 flex-all-center text-center fadeIn animate5">
                <span className="number color1">6</span>
                <p className="color1">1-Minute-<br />
                    Expert Talks</p>
                <img src={img7} />
            </Container>

            <Container left={779} top={901} width={223} height={223} className="breatheAnimation" style={{ animationDelay: '0.6s' }}>
                <Container as="a" href="https://www.jnjvisioncare.de/online-seminare" target="_blank" left={0} top={0} width={223} height={223} className="box3 bg1 color1 flex-all-center text-center scaleIn animate6">
                    <p>Direkt Ihr<br />
                        <strong>Online-Seminar</strong><br />
                        buchen </p>
                </Container>
            </Container>

        </Slide >
    );
};
