import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Arrow, Container, MoreBtn, Overlay, Image } from 'components/parts'

import img1 from './assets/bg.jpg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

const Slide = styled.div`
    .box1 {
        border-radius: 36px;
        background: #00a3e2;

        a {
            font-weight: 600;
            text-decoration: none;
            color: #fff;
            padding-left: 18px;
        }
        
    }
`
const inititialState = {
    overlay1: false
}

export default ({...props}) => {
    const [state, setState] = useState(inititialState)

    console.log(props.step)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})`, bottom: 0 }}></div>
            <Slide className="centered" step={props.step}>
                <h1><strong><strong>Bedürfnis-Erfüllung</strong> in Bezug<br /> 
auf das Produkt</strong></h1>
                <Container as="p" left={100} top={279} width={1800} className="small">
                Finden Sie mit unseren neuen Online-Tool gemeinsam mit Ihrem<br /> 
Kunden die für ihn optimale Linse
                </Container>

                <Image src={img4} left={100} top={424} width={1066} height={794} classname="img3" />
                
                <Container as="p" left={377} top={403} width={1010} height={72} className="box1 flex-all-center" style={{display: 'none'}}>
                    <a href="https://www.acuvue-aktion.de/beduerfnisanalyse" target="_blank" rel="noreferrer">www.acuvue-aktion.de/beduerfnisanalyse</a>
                </Container>

                <Image src={img2} left={1308} top={400} width={161} height={86}  style={{display: 'none'}} />

                

            </Slide>
        </>
    );
};
