
import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'
import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'
import Slide9 from './slides/slide9'

const Theme = {
    color1: '#003087',
    color2: '#003087',
    bg1: '#CFF2FB',
    bg2: '#ffffff',

    bg3: '#003087',
}

export const Slides = {
    'Folder16Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder16Slide2': { name: Slide2, theme: Theme },
    'Folder16Slide3': { name: Slide3, theme: Theme },
    'Folder16Slide4': { name: Slide4, theme: Theme },
    'Folder16Slide5': { name: Slide5, theme: Theme },
    'Folder16Slide6': { name: Slide6, theme: Theme },
    'Folder16Slide7': { name: Slide7, theme: Theme },
    'Folder16Slide8': { name: Slide8, theme: Theme },
    'Folder16Slide9': { name: Slide9, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder16Slide1' },
    { key: 1, component: 'Folder16Slide2' },
    { key: 2, component: 'Folder16Slide3' },
    { key: 3, component: 'Folder16Slide4' },
    { key: 4, component: 'Folder16Slide5' },
    { key: 5, component: 'Folder16Slide6' },
    { key: 6, component: 'Folder16Slide7' },
    { key: 7, component: 'Folder16Slide8' },
    { key: 8, component: 'Folder16Slide9' },
]