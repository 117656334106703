import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Arrow, Container, MoreBtn, Overlay, Image } from 'components/parts'

import img1 from './assets/bg.jpg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.jpg';

const Slide = styled.div`
    .box1 {
        border-radius: 36px;
        background: #00a3e2;

        a {
            font-weight: 600;
            text-decoration: none;
            color: #fff;
            padding-left: 18px;
        }
        
    }
`
const inititialState = {
    overlay1: false
}

export default ({...props}) => {
    const [state, setState] = useState(inititialState)

    console.log(props.step)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})`, bottom: 0 }}></div>
            <Slide className="centered" step={props.step}>
                <h1><strong>Bedürfnisgerechte Beratung verstärkt<br /> 
Kompetenz-Wahrnehmung und Loyalität</strong></h1>
                <Container as="p" left={100} top={279} width={1800} className="small">
                Eine bedürfnisgerechte Analyse gemeinsam mit Ihrem<br />
Kunden ist wichtig, um die für ihn optimale Linse zu finden.
                </Container>

                <Image src={img3} left={100} top={424} width={1066} height={794} classname="img3" />
                
              

               

            </Slide>
        </>
    );
};
