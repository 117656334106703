import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import img1 from '../slide5/assets/img3.svg';

import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';

import img4 from './assets/img4.svg';
import img5 from './assets/img5.svg';

import img6 from './assets/img6.png';
import img7 from './assets/img7.png';

import img8_1 from './assets/img8_1.jpg';
import img8_2 from './assets/img8_2.jpg';
import img8_3 from './assets/img8_3.jpg';

import img9 from './assets/img9.jpg';

import play2 from './assets/play2.svg';

const Slide = styled.div`
    
    .step2 {
        display: none;
    }

    .step1 {
        ${({ step }) => step !== 1 && css`
            display: none;
        `}
    }

    .step2 {
        ${({ step }) => step === 2 && css`
            display: block;
        `}
    }


    .box1 {
        font-size: 53.4px;
        line-height: 66.75px;
        font-weight: 600;

        span {
                position: absolute;
                left: 0;
                top: 104px;
                width: 100%;
                text-align: center;
                font-size: 18px;
                line-height: 20px;
                font-weight: 400;
            }
    }

    .box2 {
        .count {
            font-size: 53.4px;
            line-height: 66.75px;
            padding-top: 20px;

            .percent {
                font-size: 28.8px;
            }
        }
    }

    .box3 {
        padding-top: 24px;
    }

    .step2 {
        .img2_1,
        .img2_2 {
            position: absolute;
            left: 144px;
            top: 153px;
            width: 336px;
            height: 84px;
        }

        .img2_2 {
            left: 544px;
        }

        .img3 {
            position: absolute;
            width: 35px;
            left: 494px;
            top: 177px
        }

        .img4_1,
        .img4_2 {
            position: absolute;
            left: 141px;
            top: 150px;
            width: 342px;
            height: 187px;
        }

        .img4_2 {
            left: 541px
        }

        .img5_1,
        .img5_2 {
            position: absolute;
            left: 141px;
            top: 355px;
            width: 342px;
            height: 99px;
        }

        .img5_2 {
            left: 541px
        }

        .box4 {
            p + p {
                margin-top: 10px;
            }
        }

        .img6 {
            position: absolute;
            left: 240px;
            top: 249px;
            width: 143px;
        }

        .img7 {
            position: absolute;
            left: 669px;
            top: 249px;
            width: 86px;
        }

        .box5 {
            font-size: 14.5px;
            line-height: 16px;
        }

        .box6 {
            line-height: 23.5px;
        }
    }

    .overlay1 {
        .play {
            position: absolute;
            top:508px;
            width: 61px;
            height: 61px;
        }
       
        .play1 {
            left: 278px;
        }

        .play2 {
            left: 483px;
        }

        .play3 {
            left: 686px;
        }

        #video1,
        #video2,
        #video3 {
            position: absolute;
            top: 278px;
            display: none;

            &.active {
                display: block;
            }
        }

        #video1 {
            left: 230px;
        }

        #video2 {
            left: 435px;
        }

        #video3 {
            left: 638px;
        }

    }
`
const inititialState = {
    overlay1: null,
    video: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 63, props.active, props.preview)
    count1 = count1 / 10
    count1 = count1.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')

    const count2 = useCounter(0, 72, props.active, props.preview, 1)

    useEffect(() => {

        if (state.overlay1 !== 'overlay1') {
            updateState('video', null);
        }

    }, [state.overlay1])

    useEffect(() => {

        [1,2,3].forEach(id => {

            var mediaElement = document.getElementById(`video${id}`);
            if (mediaElement) {
                if (state.video === `video${id}`) {
                    mediaElement.play();
                } else {
                    mediaElement.pause();
                    mediaElement.currentTime = 0;
                }
            }
        })

    }, [state.video])

    return (
        <Slide className="centered" step={props.step}>
            <h1>Warum die Kampagne „Erlebe die Welt mit anderen Augen“<br />
                <strong>ein Erfolg</strong> wird</h1>
            <div className="step1">
                <Image left={108} top={276} width={306} height={306} src={img1} />
                <Container left={108} top={276} width={306} height={306} className="flex-all-center text-center box1 color1">
                    {count1}<br />
                    <span>Mio</span>
                </Container>

                <Container left={465} top={364} width={748} className="color1" as="p">
                    … Menschen sind<br />
                    <strong>aktiv an Kontaktlinsen<br />
                        interessiert<sup>1</sup></strong>
                </Container>

                <div className="container-animate fadeInFromTop animate2">

                    <Container left={1363} top={340} width={527} className="color1" as="p">
                        … der Brillenträger<br />
                        sind weiterhin <strong>bereit<br />
                            für eine Kontaktlinsen-<br />
                            anpassung.<sup>1</sup></strong>
                    </Container>

                    <CircleCount left={1006} top={276} width={306} percent={count2} color={'#003087'} />
                    <Container left={1006} top={276} width={306} height={306} className="flex-all-center text-center box2">
                        <span className="count">{count2}<span className="percent">%</span></span>
                    </Container>

                </div>

                <div className="container-animate fadeInFromTop animate3">
                    <Container left={128} top={625} width={1792} height={465} className="bg1 color1 box3 text text-center">
                        <Arrow width={994} className="container-top" />
                        <p><strong>Nutzen Sie das riesige Potential: </strong><br />
                            Das Herzstück ist eine groß angelegte, von ACUVUE® initiierte<br />
                            Digital- und Social-Media-Kampagne.<br /><br />

                            Ergänzt wird diese erstmals von Influencer/innen die insbesondere<br />
                            die junge Zielgruppe zw. 18–25 Jahren über Instagram und TikTok<br />
                            auf das Thema Kontaktlinsen aufmerksam machen.</p>
                    </Container>
                </div>
            </div>

            <div className="step2">

                <div className="container-animate fadeIn">

                    <img class="img4 img4_1" src={img4} />
                    <img class="img4 img4_2" src={img4} />

                    <img class="img6" src={img6} />
                    <img class="img7" src={img7} />

                    <Container as="p" left={288} top={594} width={672} height={188} className="box5 color1 text-center">
                        Social Media, Google Search-,<br />
                        Discovery- und Display Ads
                    </Container>

                </div>




                <img class="img2 img2_1" src={img2} />

                <Container as="p" left={288} top={300} width={672} height={188} className="color1 flex-all-center text text-center">
                    <strong>ACUVUE®<br />
                        Online-Marketing</strong>
                </Container>


                <img class="img2 img2_2" src={img2} />

                <Container as="p" left={1088} top={300} width={672} height={188} className="color1 flex-all-center text text-center">
                    <strong>Influencer<br />
                        Marketing</strong>
                </Container>

                <img class="img3" src={img3} />

                <div className="container-animate fadeIn animate2">

                    <img class="img5 img5_1" src={img5} />
                    <img class="img5 img5_2" src={img5} />

                    <Container as="p" left={288} top={726} width={672} height={188} className="color1 flex-all-center text text-center">
                        <strong>Reichweite von<br />
                            ca. 7 Mio. Nutzern<br />
                            13,5 Mio. Impressionen</strong>
                    </Container>

                    <Container as="p" left={1088} top={726} width={672} height={188} className="color1 flex-all-center text text-center">
                        <strong>Reichweite von<br /> 
> 600.000 Followern</strong>
                    </Container>

                </div>

                <img class="img2 img2_2" src={img2} />

                <div className="container-animate fadeInFromTop animate3">

                    <Container left={284} top={952} width={1480} height={250} className="bg1 box4 color1 flex-all-center text text-center ">
                        <Arrow width={1330} className="container-top" />
                        <p>Aktivierung zum unverbindlichen Probetragen.</p>

                        <p><strong>Ihr Potential:<br />
                            Verlängern Sie die Kampagne bis in ihr Geschäft!</strong></p>
                    </Container>

                </div>

                {(state.overlay1 !== 'overlay1') &&
                    <MoreBtn
                        left={229} top={348}
                        onClick={() => toggleOverlay('overlay1')}
                    />
                }

                {(state.overlay1 !== 'overlay2') &&
                    <MoreBtn
                        left={1734} top={348}
                        onClick={() => toggleOverlay('overlay2')}
                    />
                }

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg bg3 overlay1">
                    <Container left={279} top={299} width={1496} height={902} className="bg2 color1 box4">

                    </Container>

                    <Container as="p" left={279} top={371} width={1496} className="color1 text text-center">
                        <strong>Hier ein paar Beispiele,<br />
                            die dem Endverbraucher ausgespielt werden:</strong>
                    </Container>

                    {(state.video !== 'video1') ? (
                        <>
                            <Image left={460} top={554} width={333} height={571} src={img8_1} />
                            <img src={play2} className="play play1" onClick={() => updateState('video', 'video1')} />
                        </>
                    ) : (
                        <></>
                    )}

                    {(state.video !== 'video2') ? (
                        <>
                            <Image left={870} top={554} width={333} height={571} src={img8_2} />
                            <img src={play2} className="play play2" onClick={() => updateState('video', 'video2')} />
                        </>
                    ) : (
                        <></>
                    )}

                    {(state.video !== 'video3') ? (
                        <>
                            <Image left={1276} top={554} width={333} height={571} src={img8_3} />
                            <img src={play2} className="play play3" onClick={() => updateState('video', 'video3')} />
                        </>
                    ) : (
                        <></>
                    )}

                    <video id="video1" width="155" height="274" loop playsInline className={state.video === 'video1' ? 'active' : ''}>
                        <source src="/media/JJV_GoSee2022_1080x1920_Awareness_HERO.mp4" type="video/mp4" />
                    </video>

                    <video id="video2" width="155" height="274" loop playsInline className={state.video === 'video2' ? 'active' : ''}>
                        <source src="/media/JJV_GoSee2022_1080x1920_Conversion_B2.mp4" type="video/mp4" />
                    </video>

                    <video id="video3" width="155" height="274" loop playsInline className={state.video === 'video3' ? 'active' : ''}>
                        <source src="/media/JJV_GoSee2022_1080x1920_Consideration_B2-FESTIVAL_Statisch.mp4" type="video/mp4" />
                    </video>







                    <CloseBtn
                        left={237} top={356}
                        onClick={() => toggleOverlay('overlay1')}
                        bgColor="#fff"
                    />

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay2'} className="with-bg bg3 overlay1">
                    <Container left={279} top={299} width={1496} height={902} className="bg2 color1 box4">

                    </Container>



                    <Image left={390} top={332} width={1263} height={840} src={img9} />


                    <CloseBtn
                        left={1742} top={356}
                        onClick={() => toggleOverlay('overlay2')}
                        bgColor="#fff"
                    />

                </Overlay>

            </div>

        </Slide>
    );
};
