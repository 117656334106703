import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import li from './assets/li.svg';
import bg from './assets/bg.jpg'

const Slide = styled.div`
    .box1 .number {
        position: absolute;
        display: block;
        width: 70px;
        text-align: right;
        left: -56px;
        top: -23px;
        font-size: 65px;
        line-height: 65px;
        color: #390075;
        font-style: italic;
        text-align: right;
    } 

    .box2 { 
        ul {
            position: absolute;
            top: 95px;

            li {
                position: relative;
                padding-left: 42px;
                font-weight: 600;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -12.5px;
                    display: block;
                    width: 31px;
                    height: 25px;
                    background: url(${li});
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: "";
                }

                + li {
                    margin-top: 15px;
                }
            }

            &.list1 {
                left: 30px;
            }

            &.list2 {
                left: 395px;
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})` }} />
            <Slide className="centered" step={props.step}>
                <h1><strong>ACUVUE® bietet Ihnen und Ihrem Team<br />
umfassende Unterstützung</strong></h1>
                <h2 className="small">Wir bieten Ihnen eine Vielzahl <strong>professioneller Support-Programme<br /> 
und Schulungen.</strong></h2>
                <Container left={266} top={458} width={435} height={170} className="rounded rounded-full color1 flex-all-center text-center box1">
                    <span className="number">2.</span>
                    <p><strong>Professionelle<br />
Unterstützung</strong></p>
                </Container>
                <div className="container-animate fadeInFromTop">
                <Container left={233} top={672} width={1491} height={487} className="bg1 color1 box2">
                    <ul className="list1">
                        <li>Kostenübersicht</li>
                        <li>Erstanpassung &amp; Wechsel<br /> 
von Brille auf Kontaktlinsen</li>
                        <li>COVID-19-Support</li>
                    </ul>
                    <ul className="list2">
                        <li>Kursmodule für Optiker</li>
                        <li>Fachkundige Unterstützung<br />
                        von Kontaktlinsenexperten</li>
                        <li>COVID-19-Support</li>
                    </ul>
                </Container>
                
                <Container as="p" left={260} top={719} width={691} height={113} className="rounded color1 flex-all-center text-center">
                    <strong>Für Kontaktlinsenspezialisten</strong>
                </Container>

                <Container as="p" left={987} top={719} width={691} height={113} className="rounded color1 flex-all-center text-center">
                    <strong>Für Support-Mitarbeiter</strong>
                </Container>  

                <Arrow left={470} top={463} width={447} className="rotate90" />
                </div>
            </Slide>
        </>
    );
};
