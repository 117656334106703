import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.jpg';

import img1 from '../slide2/assets/img1.svg';
import img2 from '../slide2/assets/img3.svg';

const Slide = styled.div`
    .box1 {
        padding-right: 20px;
        font-size: 28px;
        line-height: 31.5px;

        &.box1-1 {
            letter-spacing: -0.035rem;
        }
    }

    .box1 .number {
        position: absolute;
        display: block;
        left: -5px;
        top: -78px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .overlay1-1,
    .overlay1-2 {
        .box2 {
            padding: 41px 10px 10px 41px;
        }
    }

    .overlay1-1 .img1,
    .overlay1-2 .img2 {
        position: absolute;
        
    }

    .overlay1-1 .label {
        position: absolute;
        z-index: 2;
        font-size: 7.2px;
        line-height: 8.5px;
        left: 855px;
        top: 505px;
        width: 200px;
        font-family: "AvenirNext";
        font-weight: 400;
    }

    .overlay1-1 .img1 {
        z-index: 1;
        width: 46px;
        left: 615px;
        top: 422px;
    }

    .overlay1-2 .img2 {
        width: 138px;
        left: 824px;
        top: 267px;
        opacity: 0.1;
    }

    .overlay1-3 {
        .box4 {
            position: relative;
            font-size: 53.4px;
            line-height: 66.75px;
            font-weight: 600;

            span {
                    position: absolute;
                    left: 0;
                    top: 104px;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 400;
                }
        }

        .box5 {
            .count {
                font-size: 53.4px;
                line-height: 66.75px;
                padding-top: 20px;

                .percent {
                    font-size: 28.8px;
                }

                .over {
                    position: absolute;
                    left: 0;
                    top: 38px;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count2 = useCounter(0, 80, state.overlay1 === 'overlay3', props.preview)
    const count3 = useCounter(0, 50, state.overlay1 === 'overlay3', props.preview)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})`, bottom: 0 }}></div>
            <Slide className="centered">
                <h1><strong>Warum diese Kampagne ein Erfolg wird?</strong></h1>

                <div className="container-animate fadeInFromLeft">
                    <Container left={167} top={352} width={906} height={141} className="bg1 box1 box1-1 color1 flex-all-center text-center">
                        <span className="number number1">1</span>
                        <p><strong>Kommunikation von Kompetenz</strong></p>

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay1'}
                            onClick={() => toggleOverlay('overlay1')} />

                    </Container>


                </div>

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg overlay1 overlay1-1">
                    <Container left={1074} top={227} width={899} height={957} className="box2 bg2 color1">
                        <p>Aufbauend auf dem Erfolg vorange-<br />
gangener “MeinOptiker-Kampagnen”,<br />
nutzen wir den zentralen und wichtigs-<br />
ten Punkt für den potenziellen Träger<br />
bei der Kontaktlinsen-Anpassung:<br /><br /> 

<strong>Die Kompetenz der Fachpersonen<br /> 
und die exzellente Qualität der<br /> 
ACUVUE® Produkte.</strong>
</p>
                    </Container>

                    <img src={img1} className="img1" />

                    <Image left={1128} top={784} width={717} height={399} src={img4} />

                    <span className="label">Produktabbildung nur<br /> 
zu Illustrationszwecken</span>

                    <Container left={167} top={352} width={906} height={141} className="box1 box1-1 color1 flex-all-center text-center">
                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay1'}
                            onClick={() => toggleOverlay('overlay1')} />

                    </Container>

                </Overlay>

                <div className="container-animate fadeInFromLeft animate2">

                    <Container left={167} top={639} width={906} height={141} className="bg1 box1 color1 flex-all-center text-center">
                        <span className="number number1">2</span>
                        <p><strong>Nutzung des Momentums</strong></p>

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay2'}
                            onClick={() => toggleOverlay('overlay2')} />

                    </Container>



                </div>

                <Overlay active={state.overlay1 === 'overlay2'} className="with-bg overlay1 overlay1-2">
                    <Container left={1074} top={227} width={899} height={592} className="box2 bg2 color1">
                        <p>Die Kampagnentonalität trifft<br /> 
<strong>den Puls der Zeit</strong>. Niemand möchte<br />
lange auf einen Beratungs- oder<br /> 
Check-up Termin warten.<br /><br /> 

<strong>Unsere Kampagne zielt genau<br /> 
auf die „Kompetenz sofort“ ab<br /> 
und spricht so das Bedürfnis der<br />
Träger an.</strong></p>
                    </Container>

                    <img src={img2} className="img2" />

                    <Container left={167} top={639} width={906} height={141} className="box1 color1 flex-all-center text-center">
                     
                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay2'}
                            onClick={() => toggleOverlay('overlay2')} />

                    </Container>


                </Overlay>

                <div className="container-animate fadeInFromLeft animate3">

                    <Container left={167} top={929} width={906} height={141} className="bg1 box1 color1 flex-all-center text-center">
                        <span className="number number1">3</span>
                        <p><strong>Großes Umsatzpotenzial</strong></p>

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay3'}
                            onClick={() => toggleOverlay('overlay3')} />
                    </Container>


                </div>


                <Overlay active={state.overlay1 === 'overlay3'} className="overlay1 overlay1-3 with-bg">
                    <Container left={1074} top={227} width={899} height={957} className="box2 bg2 color1">
                    </Container>
                    <Container left={1381} top={288} width={748} className="text1 color1" as="p">Laut einer aktuellen Studie<br /> 
sind <strong>6,3 Millionen aktiv<br /> 
interessiert</strong> oder haben<br /> 
schon den ersten Kontakt<br /> 
zu Kontaktlinsen gehabt. </Container>
                    <Container left={1110} top={589} width={748} className="text1 color1" as="p">Sowohl diese Menschen als<br /> 
auch die, die einen Check up<br /> 
benötigen oder unzufrieden<br /> 
mit ihrem derzeitigen Produkt<br /> 
sind, <strong>erwarten zu über 80%<br /> 
Kompetenz in der Beratung<sup>4</sup></strong> …</Container>
                    <Container left={1547} top={989} width={748} className="text1 color1" as="p">… und zu <strong>über 50%<br /> 
einen zeitnahen<br /> 
Termin.<sup>3</sup></strong></Container>

                    <Image left={1024} top={253} width={306} height={306} src={img3} />
                    <Container left={1024} top={253} width={306} height={306} className="flex-all-center text-center box4 color1">
                        6,3<br />
                        <span>Mio</span>
                    </Container>

                    <CircleCount left={1714} top={563} width={306} percent={count2} color={'#003087'} />
                    <Container left={1714} top={563} width={306} height={306} className="flex-all-center text-center box5">
                        <span className="count"><span className="over">Über</span>{count2}<span className="percent">%</span></span>
                    </Container>

                    <CircleCount left={1186} top={916} width={306} percent={count3} color={'#003087'} />
                    <Container left={1186} top={916} width={306} height={306} className="flex-all-center text-center box5">
                        <span className="count"><span className="over">Über</span>{count3}<span className="percent">%</span></span>
                    </Container>

                    <Container left={167} top={929} width={906} height={141} className="box1 color1 flex-all-center text-center">
                      
                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay3'}
                            onClick={() => toggleOverlay('overlay3')} />
                    </Container>
                </Overlay>

            </Slide>
        </>
    );
};
