import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.svg';
import img5 from './assets/img5.svg';
import img6 from './assets/img6.svg';
import img7 from './assets/img7.jpg';
import img8 from './assets/img8.jpg';
import img9 from './assets/img9.jpg';
import img10 from './assets/img10.jpg';
import img11 from './assets/img11.jpg';
import img12 from './assets/img12.svg';

const Slide = styled.div`
    font-family: "AvenirNext";

    .box1 {
        .bg {
            position: absolute;
            left: 50px;
            bottom: 0;
            width: 212px;
            height: 93px;
        }
    }

    .box2 {
        .number {
            position: absolute;
            left: 29px;
            top: 7px;
            display: flex;
            font-weight: 600;

            .big {
                font-size: 53px;
                line-height: 53px;
            }

            .small {
                font-size: 21px;
                line-height: 21px;
            }

            .center {
                margin-top: 14px;
            }

            .bottom {
                position: relative;
                margin-left: 4px;
                margin-top: 26px;

                img {
                    position: absolute;
                    width: 37px;
                    left: -12px;
                    top: -7px
                }
            }
        }

        .text1 {
            position: absolute;
            left: 138px;
            top: 30px;
        }

        .text2 {
            position: absolute;
            left: 188px;
            top: 59px;
        }
    }

    .overlay1 { 
        p.tall {
            font-size: 30px;
            line-height: 37.5px;
        }

        .legend {
            font-size: 15px;
            line-height: 18.5px;
        }

        .info {
            font-size: 10px;
            line-height: 11px;
        }

        &.overlay1-1 {
            .legend {
                font-size: 21.2px;
                line-height: 24.2px;

                &.legend2 strong {
                    letter-spacing: -0.025rem;
                }
            }

            .img1 {
                position: absolute;
                z-index: 1;
                left: 122px;
                top: 190px;
                width: 306px;
            }

            .img2 {
                position: absolute;
                z-index: 1;
                left: 67px;
                top: 270px;
                width: 597px;
            }

            .box3 {
                font-size: 13px;
                line-height: 17.5px;

                p {
                    position: absolute;
                    
                    &.text1,
                    &.text2,
                    &.text3,
                    &.text4,
                    &.text5,
                    &.text6 {
                        width: 100px;
                    }

                    &.text1 {
                        left: -46px;
                        top: 21px;
                        text-align: right;
                    }

                    &.text2 {
                        left: -46px;
                        top: 55px;
                        text-align: right;
                    }

                    &.text3,
                    &.text4 {
                        font-size: 15px;
                        font-weight: 600;
                    }

                    &.text3 {
                        left: 172px;
                        top: 21px;
                        text-align: center;
                    }

                    &.text4 {
                        left: 172px;
                        top: 55px;
                        text-align: center;
                    }

                    &.text5 {
                        left: 376px;
                        top: 21px;
                    }

                    &.text6 {
                        left: 376px;
                        top: 55px;
                    }


                    &.text7 {
                        left: 460px;
                        top: 10px
                    }
                }

                .exclamation {
                    position: absolute;
                    right: 32px;
                    top: -13px;
                    font-size: 97.5px;
                    line-height: 122px;
                    font-style: italic;
                    text-align: right;
                }
            }

            .count {
                z-index: 1;
                font-size: 53px;
                line-height: 66px;

                span {
                    font-size: 28.5px;
                }

                &.count1 {
                    letter-spacing: -0.15rem;
                }
            }
        }

        &.overlay1-2 {
            .img1 {
                position: absolute;
                width: 201px;
                left: 84px;
                top: 192px;
            }

            .img2 {
                position: absolute;
                width: 286px;
                left: 608px;
                top: 180px;
            }

            .legend {
                font-size: 21.2px;
                line-height: 24.2px;
            }
        }

        &.overlay1-3 {
            .img1 {
                position: absolute;
                width: 945px;
                left: 40px;
                top: 187px;
            }

            .legend {
                font-size: 21px;
                line-height: 24px;
            }
        }

        &.overlay1-4 {
            .img1 {
                background-size: contain;
            }
        }
    }
   
`
const inititialState = {
    overlay1: 'overlay4'
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Das ACUVUE® MULTIFOCAL Portfolio</strong> – die einzigen<br />
                Kontaktlinsen mit 100% pupillenoptimierten Parametern<sup>7</sup></h1>

            <div className="container-animate">
                <Container left={186} top={399} width={623} height={336} className="box1 box1-1 color1 text-center">
                    <p><strong>Pupillenoptimiertes<br />
                        Design</strong></p>
                    <img src={img1} className="bg" />

                </Container>
                <Image left={463} top={515} width={102} height={150} src={img2} />

                <MoreBtn
                    left={457} top={695}
                    onClick={() => toggleOverlay('overlay1')} />

            </div>

            <div className="container-animate fadeIn">
                <Container left={707} top={399} width={623} height={336} className="box1 box1-1 color1 text-center">
                    <p><strong>Hybrides<br />
                        Rückflächendesign</strong></p>
                    <img src={img1} className="bg" />

                </Container>
                <Image left={954} top={515} width={104} height={150} src={img3} />

                <MoreBtn
                    left={978} top={695}

                    onClick={() => toggleOverlay('overlay2')} />

            </div>

            <div className="container-animate fadeIn animate2">
                <Container left={1230} top={399} width={623} height={336} className="box1 box1-1 color1 text-center">
                    <p><strong>Integrierte<br />
                        Benetzungstechnologien</strong></p>
                    <img src={img1} className="bg" />

                </Container>
                <Image left={1506} top={515} width={77} height={154} src={img4} />

                <MoreBtn
                    left={1501} top={695}

                    onClick={() => toggleOverlay('overlay3')} />

            </div>

            <Container left={375} top={909} width={1300} height={220} className="box2 bg1 color1 flex-all-center color1 fadeIn animate3">
                <span className="number">
                    <span className="small center">≥</span>
                    <span className="big">94</span>
                    <span className="small bottom">%<img src={img5} /></span>
                </span>
                <p className="text1">Anpasserfolg bei Verwendung des Leitfadens<sup>**8,9,13</sup></p>
                <p className="text2"><strong>Einfach. Schnell. Sicher.</strong></p>
            </Container>

            <MoreBtn
                className="fadeIn animate3"
                left={978} top={1087}
                active={state.overlay1 === 'overlay4'}
                onClick={() => toggleOverlay('overlay4')} />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg overlay1 overlay1-1">
                <Container left={75} top={100} width={1898} height={1103} className="box3 bg2 color1 border">

                </Container>

                <img src={img6} className="img1" />
                <img src={img7} className="img2" />

                <Container left={147} top={163} width={1900} className="color1">
                    <p className="tall"> <strong>ACUVUE® pupillenoptimiertes Design</strong> – Klare, scharfe<br />
                        und zuverlässige Sicht in allen Entfernungen<sup>7</sup></p>
                </Container>

                <Container left={115} top={337} width={1817} height={180} className="box3 bg1 color1 flex-all-center color1">
                    <span class="exclamation color3">!</span>
                    <p className="text1">Myop</p>
                    <p className="text2">Jünger</p>
                    <p className="text3">Refraktion</p>
                    <p className="text4">Alter</p>
                    <p className="text5">Hyperop</p>
                    <p className="text6">Älter</p>
                    <p className="text7"><strong>Die Pupillengröße variiert je nach Alter und Fehlsichtigkeit.</strong><br />
                        Dementsprechend sollte für die bestmögliche Sehqualität<br />
                        das optische Design darauf angepasst und das Stärkenprofil<br />
                        gleichmäßig über die Pupille verteilt sein.</p>
                </Container>

                <Container as="p" left={1355} top={606} width={700} className="color1 legend">
                    … der 183 Parameter<br />
                    <strong>von ACUVUE® MULTIFOCAL</strong><br />
                    sind nach Alter und Fehl-<br />
                    sichtigkeit pupillenoptimiert<sup>^7</sup>
                </Container>

                <Container as="p" left={1355} top={948} width={700} className="color1 legend legend2">
                    … der Parameter<br />
                    <strong>des führenden Wettbewerbers</strong><br />
                    sind optimiert<sup>^7</sup>
                </Container>

                <Container as="p" left={1022} top={542} width={306} height={306} className="count count1 color1 flex-all-center text-center">
                    <p>100<span>%</span></p>
                </Container>

                <Container as="p" left={1022} top={860} width={306} height={306} className="count color1 flex-all-center text-center">
                    <p>&lt;2<span>%</span></p>
                </Container>

                <CloseBtn
                    left={1934} top={67}
                    onClick={() => toggleOverlay('overlay1')}
                    bgColor={'#ffffff'}
                    color={'#2ca88f'}
                />

            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="with-bg overlay1 overlay1-2">
                <Container left={75} top={100} width={1898} height={1103} className="box3 bg2 color1 border">

                </Container>

                <img src={img8} className="img1" />
                <img src={img9} className="img2" />

                <Container left={147} top={163} width={1900} className="color1">
                    <p className="tall"><strong>ACUVUE® hybrides Rückflächendesign</strong> – für eine noch<br />
                        höhere Passgenauigkeit beim presbyopen Auge<sup>7</sup></p>
                </Container>

                <Container as="p" left={612} top={489} width={600} className="color1 legend">
                    <strong>Sphärische Peripherie</strong><br />
                    unterstützt die mittige<br />
                    Ausrichtung<sup>7</sup>
                </Container>

                <Container as="p" left={612} top={704} width={700} className="color1 legend">
                    <strong>Asphärisches Zentrum</strong><br />
                    ahmt die natürliche Form<br />
                    der Hornhaut nach, um die<br />
                    Integrität der komplexen<br />
                    Vorderflächenoptik zu<br />
                    erhalten<sup>7</sup>
                </Container>

                <Container as="p" left={1120} top={995} width={771} className="color1 legend text-center">
                    Sorgt für eine<br />
                    optimale Zentrierung<br />
                    der Linse.
                </Container>

                <CloseBtn
                    left={1934} top={67}
                    onClick={() => toggleOverlay('overlay2')}
                    bgColor={'#ffffff'}
                    color={'#2ca88f'}
                />

            </Overlay>

            <Overlay active={state.overlay1 === 'overlay3'} className="with-bg overlay1 overlay1-3">
                <Container left={75} top={100} width={1898} height={1103} className="box3 bg2 color1 border">

                </Container>

                <img src={img10} className="img1" />

                <Container left={147} top={163} width={1900} className="color1">
                    <p className="tall"><strong>ACUVUE® Kontaktlinsen mit integriertem Benetzungs-<br />
                        wirkstoff</strong> – für außergewöhnlichen, ganztägigen Tragekomfort<sup>#10</sup></p>
                </Container>



                <Container as="p" left={170} top={812} width={556} className="color1 legend text-center">
                    <strong>1-Tages-Gleitsichtlinse</strong><br />
                    Hoher Tagesend-Komfort<br />
                    und scharfe, klare Sicht in<br />
                    jeder Entfernung und allen<br />
                    Lichtverhältnissen.<sup>°¥11</sup>
                </Container>

                <Container as="p" left={760} top={812} width={540} className="color1 legend text-center">
                    <strong>2-Wochen-Gleitsichtlinse</strong><br />
                    Ganztägiger Tragekomfort<br />
                    dank HYDRACLEAR® PLUS<br />
                    Technologie.<sup>9</sup>
                </Container>

                <Container as="p" left={1317} top={812} width={588} className="color1 legend text-center">
                    <strong>1-Tages-Gleitsichtlinse</strong><br />
                    Ein mehr als 20 Stunden<br />
                    anhaltendes Feuchtigkeits-<br />
                    kissen dank integriertem<br />
                    Benetzungswirkstoff.<sup>12</sup>
                </Container>

                <CloseBtn
                    left={1934} top={67}
                    onClick={() => toggleOverlay('overlay3')}
                    bgColor={'#ffffff'}
                    color={'#2ca88f'}
                />

            </Overlay>

            <Overlay active={state.overlay1 === 'overlay4'} className="with-bg overlay1 overlay1-4">
                <Container left={375} top={812} width={1299} height={317} className="box4 bg2 color1 border">

                </Container>

                <Container as="p" left={338} top={900} width={565} className="color1 text-right">
                    Nutzen Sie unseren<br />
                    <strong>Leitfaden</strong> oder<br />
                    <strong>Multifokal-Rechner</strong> …
                </Container>

                <Container as="p" left={1134} top={900} width={700} className="color1">
                    … für eine schnelle,<br />
                    einfache und erfolgreiche<br />
                    Kontaktlinsenanpassung
                </Container>

                <Image left={926} top={837} width={188} height={268} src={img11} className="img1" />
                <Image left={817} top={1032} width={73} height={75} src={img12} />

            </Overlay>

        </Slide>
    );
};
