import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.png';

const Slide = styled.div`

    h1 {
       position: relative;
       
       .number {
            position: absolute;
            font-family: "AvenirNext";
            font-weight: 700;
            font-style: italic;
            font-size: 125px;
            line-height: 156px;
            top: -60px;
            left: -50px;
            z-index: -1;
       }
    }
    
    .box2 {
        padding: 0;
        font-size: 18px;
        line-height: 21px;

        .img {
            background-size: contain;
        }
     
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><span className="number color3">3</span><strong>Marketing Unterstützung</strong></h1>

            <Container left={131} top={214} width={1786} className="box1 color1 text-center" as="p">
                Zur Trägergewinnung & Verkaufsförderung unterstützen wir Sie mit<br />
                verkaufsfördernden Multi-Media Kampagnen, Konzepten und Ideen sowie deren<br />
                Anpassung und Implementierung in Ihrem Geschäft.
            </Container>

            <Container left={100} top={0} className="box2">
                <Image left={33} top={445} width={356} height={467} src={img1} className="img" />
                <Container left={0} top={964} width={430} height={205} className="box2 bg1 flex-all-center text-center">

                    <p className="color1">Reichweitenstarke<br />
                        Multi-Media<br />
                        Kampagnen</p>
                </Container>

            </Container>

            <Container left={576} top={0} className="box2 fadeIn">
                <Image left={33} top={445} width={356} height={467} src={img2} className="img" />
                <Container left={0} top={964} width={430} height={205} className="box2 bg1 flex-all-center text-center">

                    <p className="color1">Attraktive<br />
                        POS-Mechaniken</p>
                </Container>

            </Container>

            <Container left={1048} top={0} className="box2  fadeIn animate2">
                <Image left={33} top={445} width={356} height={467} src={img3} className="img" />
                <Container left={0} top={964} width={430} height={205} className="box2 bg1 flex-all-center text-center">

                    <p className="color1">Themenspezifische<br />
                        Materialien für<br />
                        Ihre Kunden</p>
                </Container>

            </Container>

            <Container left={1522} top={0} className="box2  fadeIn animate3">
                <Image left={33} top={445} width={356} height={467} src={img4} className="img" />
                <Container left={0} top={964} width={430} height={205} className="box2 bg1 flex-all-center text-center">

                    <Image left={38} top={44} width={352} height={116} src={img5} />
                </Container>

            </Container>



        </Slide>
    );
};
