import React, { useState } from 'react';
import styled from 'styled-components';

import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, CloseBtn, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';
import arrowIcon from './assets/arrow.svg';
import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';
import icon5 from './assets/icon5.svg';
import icon6 from './assets/icon6.svg';

const Slide = styled.div`
    .box1 {
        padding-top: 24px;
    }
    
    > .overlay1 {
        .number {
            position: absolute;
            left: -38px;
            top: 50%;
            transform: translateY(-50%);
            font-style: italic;
            font-weight: 600;
            font-size: 37px;
        }

        .box2 {
            padding-left: 30px;
            line-height: 27.5px;

            ul li {
                padding-left: 37px;
                background-image: url(${arrowIcon});
                background-size: 27px auto;
                background-repeat: no-repeat;
            }
        }

        .box2-6 {
            padding-top: 22px;
        }
    }
`

const inititialState = {
    overlay1: null
}


const Folder2Slide3 = ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered">
                <h1><strong>Kontaktlinsenaussteiger:</strong><br />
Was steckt kommunikativ dahinter?</h1>

                <Container left={173} top={317} width={882} className="text-center">
                    <p className="color1">“Am Abend haben meine<br />
Kontaktlinsen so gejuckt, ich habe es<br />
dann nicht mehr ausgehalten …<br />
Ich dachte, das wäre normal.”</p>
                    <p style={{ marginTop: '10px' }}>Anne, Yogini</p>
                </Container>

                <Container left={173} top={619} width={882} height={514} className="bg1 text-center color1 box1 fadeInFromTop">
                    <Arrow width={806} className="container-top" />
                    <div className="text">
                    <p><strong>Lösung:</strong></p>
                    <p>Direkt das beste, optimale<br />
                        Produkt anbieten.</p>
                    <p>Eine <strong>offene Kommunikation</strong> mit dem<br />
                        Träger führen und auf die <strong>Option<br /> des Produktwechsels</strong> aufmerksam<br /> machen.</p>
                    </div>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay1')} />
                </Container>

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                    <Container left={100} top={100} width={1848} height={1120} className="bg2">

                    </Container>
                    <Container left={197} top={177} width={786} height={214} className="rounded rounded-full color1 flex-all-center box2">
                        <span className="number">1.</span>
                        <p><strong>Nicht</strong> die optimale<br />
Kontaktlinse angepasst<br />
bekommen</p>
                    </Container>
                    <Container left={197} top={445} width={786} height={214} className="rounded rounded-full color1 flex-all-center box2">
                        <span className="number">3.</span>
                        <p>Versuch der Akzeptanz</p>
                        <ul>
                            <li><strong>Frustration</strong></li>
                        </ul>
                    </Container>

                    <Container left={197} top={713} width={786} height={429} className="rounded color1 flex-all-center box2">
                        <span className="number">5.</span>
                        <p><strong>Suchen nach Lösung</strong></p>
                        <ul>
                            <li>anderes Produkt /<br />
		anderer Optiker /<br />
		Internetkauf
</li>
                            <li><strong>OHNE Absprache mit<br /> 
eigenem Optiker</strong></li>
                        </ul>
                    </Container>

                    <Container left={1103} top={177} width={786} height={214} className="rounded rounded-full color1 flex-all-center box2">
                        <span className="number">2.</span>
                        <p><strong>Geringer Tragekomfort</strong></p>
                        <ul>
                            <li style={{letterSpacing: '-0.053em'}}>kein Kontaktlinsenerlebnis</li>
                            <li>keine Freiheit</li>
                        </ul>
                    </Container>
                    <Container left={1103} top={445} width={786} height={214} className="rounded rounded-full color1 flex-all-center box2">
                        <span className="number">4.</span>
                        <p>Ausweichverhalten</p>
                        <ul>
                            <li><strong>noch <strong>mehr Frustration</strong></strong></li>
                        </ul>
                    </Container>

                    <Container left={1103} top={713} width={786} height={429} className="rounded color1 box2 box2-6">
                        <span className="number">6.</span>
                        <p><strong>Kein Nachkauf beim eigenen Optiker und womöglich Selbstanpassung</strong></p>
                       
                    </Container>

                    <Image left={797} top={211} width={143} height={143} src={icon1} imageWidth={117} className="rounded border image-center" />
                    <Image left={797} top={481} width={143} height={143} src={icon3} imageWidth={108} className="rounded border image-center" />
                    <Image left={797} top={772} width={143} height={143} src={icon5} imageWidth={119} className="rounded border image-center" />

                    <Image left={1706} top={211} width={143} height={143} src={icon2} imageWidth={103} className="rounded border image-center" />
                    <Image left={1706} top={481} width={143} height={143} src={icon4} imageWidth={108} className="rounded border image-center" />
                    <Image left={1706} top={954} width={143} height={143} src={icon6} imageWidth={94} className="rounded border image-center" />

                    <CloseBtn
                        left={1855} top={127}

                        onClick={() => toggleOverlay('overlay1')}
                        className="more1" />

                </Overlay>
            </Slide>
        </>
    );
};

export default Folder2Slide3;