import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';

import img_temp from './temp.png';


const Slide = styled.div`
    background: url(${img_temp});
    background-size: cover;
    background: none;

    h1 {
       position: relative;
       
       .number {
            position: absolute;
            font-family: "AvenirNext";
            font-weight: 700;
            font-style: italic;
            font-size: 125px;
            line-height: 156px;
            top: -60px;
            left: -48px;
            z-index: -1;
       }
    }

    .box1 {
        font-family: "AvenirNext";
        font-size: 18px;
        line-height: 19px;
        font-weight: 700;
    }

   .box2 {
        font-family: "AvenirNext";
        font-size: 19px;
        line-height: 24px;

        &.box2-1 {
           

            span {
                position: absolute;
                display: block;
                background: #003087;
                height: 32px;
                width: 2px;
                left: 98px;
                top: -40px;
            }
        }
   }

   .box3 {
        font-family: "AvenirNext";
        font-size: 10px;
        line-height: 11.5px;
    }
`
const inititialState = {
    overlay1: 'overlay3'
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 63, state.overlay1 === 'overlay2', props.preview)
    count1 = count1 / 10
    count1 = count1.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')

    const count2 = useCounter(0, 72, state.overlay1 === 'overlay2', props.preview)

    return (
        <>
            <div className="bg left" style={{ backgroundImage: `url(${bg})`, bottom: '85px' }}></div>
            <Slide className="centered">
                <h1><span className="number color3">4</span><strong>Bedingungen</strong> für den Erhalt des Partnerrabatts</h1>

                <Arrow left={786} top={338} width={770} offset={-105} arrowColor={'#007DC5'} className="fadeInFromLeft" />

                <div className="container-animate fadeIn animate2">
                    <Image left={1057} top={284} width={252} height={321} src={img1} />

                    <Container left={968} top={640} width={430} height={150} as="p" className="box1 color1 bg1 flex-all-center text-center">
                        Systembasierte
                        Geschäftsdaten
                    </Container>
                </div>

                <div className="container-animate fadeIn animate3">
                    <Image left={1531} top={284} width={252} height={321} src={img2} />

                    <Container left={1442} top={640} width={430} height={150} as="p" className="box1 color1 bg1 flex-all-center text-center">
                        Beteiligung an
                        Marketing Aktivitäten
                    </Container>
                </div>


                <div className="container-animate fadeIn animate4">

                    <Container left={985} top={853} width={1006} className="box2 box2-1 color1" as="p">
                        <span />
                        <strong>Anteil Kontaktlinse</strong> am Gesamt-Geschäftsumsatz*
                    </Container>


                    <Container left={985} top={934} width={1006} className="box2 color1" as="p">
                        <strong>Anteil ACUVUE®</strong> am gesamten Kontaktlinsenumsatz
                    </Container>


                    <Container left={985} top={1015} width={1006} className="box2 color1" as="p">
                        <strong>Neuanpassungen</strong> (Anzahl pro Woche)
                    </Container>


                    <Container left={985} top={1097} width={1006} className="box2 color1" as="p">
                        <strong>Kontaktlinsen-Termine**</strong> (Anzahl pro Woche)
                    </Container>


                    <Container left={985} top={1230} width={900} className="color1 box3" as="p">
                        * Kontaktlinsengeschäft mit weichen Linsen. ** Neuanpassungen und Nachkontrollen.
                    </Container>

                </div>

            </Slide>
        </>
    );
};
