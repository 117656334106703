import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';

const Slide = styled.div`
    .img1 {
        position: absolute;
        left: 61px;
        top: 152px;
        width: 829px;
    }

    .img2 {
        position: absolute;
        left: 61px;
        top: 152px;
        width: 829px;
    }

    .box1 {
        .small {
            font-size: 10px;
        }
    }

    .box2 {
        font-size: 11.5px;
        line-height: 28px;

        &.large {
            padding-top: 90px;
            font-weight: 500;
            font-size: 73px;
            line-height: 127px;
            color: #fff;
        }
    }

    .box4 {
        span {
            color: #003087;
            font-weight: 600;
            font-size: 30px;
            line-height: 24px;
        }
    }

    .overlay1 { 
        .box2 {
            line-height: 34px;

            &.large {
                padding-top: 114px;
            }

        }

        .box3 {
            background: #fff;
            border: 1px solid #003087;
            border-radius: 15px;
        }
    }

`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 37, props.active, props.preview)

    return (
        <Slide className="centered" step={props.step}>
            <h1>Attraktive Marge: Konditionensystem <strong>unterstützt anpassenden<br />
                Fachhandel überproportional</strong></h1>
            <img src={img1} className="img1" />
            <Container as="p" left={151} top={374} width={111} className="small color1 text-center box1 box1-1">
                <strong>Level</strong>
            </Container>
            <Container as="p" left={262} top={374} width={300} className="small color1 text-center box1 box1-1">
                <strong>Jahresumsatz<br />
                    <span className="small">Euro</span></strong>
            </Container>

            <Container as="p" left={562} top={374} width={180} className="small color1 text-center box1 box1-1">
                <strong>Umsatz-<br />
                    rabatt</strong>
            </Container>

            <Container as="p" left={742} top={374} width={249} className="small color1 text-center box1 box1-1">
                <strong>Anpass-<br />
                    rabatt</strong>
            </Container>

            <Container as="p" left={991} top={374} width={249} className="small color1 text-center box1 box1-1">
                <strong>Partner-<br />
                    rabatt<sup>1</sup></strong>
            </Container>

            <Container as="p" left={1240} top={374} width={249} className="small color1 text-center box1 box1-1">
                <strong>Wachstums-<br />
                    rabatt</strong>
            </Container>

            <Container as="p" left={1489} top={374} width={249} className="small color1 text-center box1 box1-1">
                <strong>Möglicher
                    Gesamtrabatt</strong>
            </Container>

            <Container as="p" left={151} top={502} width={111} className="text-center box2 box2-1">
                1<br />
                2<br />
                3<br />
                4<br />
                5<br />
                6<br />
                7<br />
                8<br />
                9<br />
                10
            </Container>
            <Container as="p" left={262} top={502} width={300} className="text-center box2 box2-1">
                1.000 bis &lt; 2.500<br />
                2.500 bis &lt; 6.000<br />
                6.000 bis &lt; 10.000<br />
                10.000 bis &lt; 15.000<br />
                15.000 bis &lt; 20.000<br />
                20.000 bis &lt; 30.000<br />
                30.000 bis &lt; 50.000<br />
                50.000 bis &lt; 100.000<br />
                100.000 bis &lt; 500.000<br />
                ab 500.000
            </Container>

            <Container as="p" left={562} top={502} width={180} className="text-center box2 box2-1">
                0%<br />
                3%<br />
                5%<br />
                6%<br />
                7%<br />
                8%<br />
                9%<br />
                10%<br />
                10,5%<br />
                11%
            </Container>

            <Container as="p" left={742} top={502} width={249} className="text-center box2 box2-1 large">
                9%
            </Container>

            <Container as="p" left={991} top={502} width={249} className="text-center box2 box2-1 large">
                5%
            </Container>

            <Container as="p" left={1240} top={502} width={249} className="text-center box2 box2-1 large">
                5%
            </Container>

            <Container as="p" left={1489} top={502} width={249} className="text-center box2 box2-1">
                19%<br />
                22%<br />
                24%<br />
                25%<br />
                26%<br />
                27%<br />
                28%<br />
                29%<br />
                29,5%<br />
                30%
            </Container>

            <Container as="p" left={20} top={1154} width={2008} className="text-center box4 text-center">
                Mindestens <span>30%</span> und bis zu <span>47%</span> der Konditionen beziehen sich auf die Anpassleistung.
            </Container>

            {!state.overlay1 &&
                <Image src={img3}  left={1810} top={317} width={75} height={44} />
            }
            <MoreBtn
                left={1807} top={241}
                onClick={() => toggleOverlay('overlay1')}
                active={state.overlay1 === 'overlay1'}
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1" onClick={() => toggleOverlay('overlay1')}>

            <Container left={100} top={282} width={1748} height={826} className="box3">
            </Container>

                <img src={img2} className="img2" />
                <Container as="p" left={151} top={374} width={111} className="small color1 text-center box1 box1-1">
                    <strong>Level</strong>
                </Container>
                <Container as="p" left={262} top={374} width={300} className="small color1 text-center box1 box1-1">
                    <strong>Jahresumsatz<br />
                        <span className="small">Euro</span></strong>
                </Container>

                <Container as="p" left={562} top={374} width={180} className="small color1 text-center box1 box1-1">
                    <strong>Umsatz-<br />
                        rabatt</strong>
                </Container>

                <Container as="p" left={742} top={374} width={249} className="small color1 text-center box1 box1-1">
                    <strong>Anpass-<br />
                        rabatt</strong>
                </Container>

                <Container as="p" left={991} top={374} width={249} className="small color1 text-center box1 box1-1">
                    <strong>Partner-<br />
                        rabatt<sup>1</sup></strong>
                </Container>

                <Container as="p" left={1240} top={374} width={249} className="small color1 text-center box1 box1-1">
                    <strong>Wachstums-<br />
                        rabatt</strong>
                </Container>

                <Container as="p" left={1489} top={374} width={249} className="small color1 text-center box1 box1-1">
                    <strong>Möglicher
                        Gesamtrabatt</strong>
                </Container>

                <Container as="p" left={151} top={502} width={111} className="text-center box2 box2-1">
                    1<br />
                    2<br />
                    3<br />
                    4<br />
                    5<br />
                    6<br />
                    7

                </Container>
                <Container as="p" left={262} top={502} width={300} className="text-center box2 box2-1">
                    1.000 bis &lt; 2.000<br />
                    2.000 bis &lt; 4.000<br />
                    4.000 bis &lt; 8.000<br />
                    8.000 bis &lt; 17.000<br />
                    17.000 bis &lt; 35.000<br />
                    35.000 bis &lt; 70.000<br />
                    ab 70.000

                </Container>

                <Container as="p" left={562} top={502} width={180} className="text-center box2 box2-1">
                    0%<br />
                    2%<br />
                    4%<br />
                    6%<br />
                    8%<br />
                    10%<br />
                    11%

                </Container>

                <Container as="p" left={742} top={502} width={249} className="text-center box2 box2-1 large">
                    9%
                </Container>

                <Container as="p" left={991} top={502} width={249} className="text-center box2 box2-1 large">
                    5%
                </Container>

                <Container as="p" left={1240} top={502} width={249} className="text-center box2 box2-1 large">
                    5%
                </Container>

                <Container as="p" left={1489} top={502} width={249} className="text-center box2 box2-1">
                    19%<br />
                    21%<br />
                    23%<br />
                    25%<br />
                    27%<br />
                    29%<br />
                    30%
                </Container>

                <Container as="p" left={151} top={1035} width={400} className="color1 small">
                <strong>Österreich</strong>
                </Container>

            </Overlay>

        </Slide>
    );
};
