import React, { useState, useEffect, createRef } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { saveFolder } from 'actions'

import styles from './Dropdown.module.sass'

const Dropdown = ({ currentSlide, saveFolder, customFolders }) => {
	const [active, setActive] = useState(false)
	const menu = createRef()

	const handleOutsideClick = (event) => {
		if (menu.current && !menu.current.contains(event.target)) {
			setActive(false)
		}
	}

	const handleSelect = (folder) => {
		setActive(false)
		//onSelect(value)

		let test = customFolders.folders[folder] ? [...customFolders.folders[folder]] : []

		console.log(test)

		test.push(
			{
				key: new Date().getTime(),
				component: currentSlide.component
			}
		)

		console.log(test)

		saveFolder(folder, test)

	}

	useEffect(() => {
		document[active ? 'addEventListener' : 'removeEventListener']('click', handleOutsideClick)
		return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
	})

	return (
		<div className={styles.dropdown}>
			<span onClick={() => setActive(!active)} className="button open"><span />
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.07 202.03" width="20px" height="20px">
					<path d="M142.39,118.36a24.48,24.48,0,1,0,24.48,24.48h0a24.5,24.5,0,0,0-24.48-24.48m0,61.46a37,37,0,1,1,37-37,37,37,0,0,1-37,37h0m-12.56,50.42H152.4L157,209.37a6.25,6.25,0,0,1,4.23-4.63,64.38,64.38,0,0,0,10-4,6.25,6.25,0,0,1,6.33.38L195.31,213l16.28-15.62-12.15-17.65a6.25,6.25,0,0,1-.36-6.48,66.34,66.34,0,0,0,4.8-11.72,6.21,6.21,0,0,1,4.65-4.31l21.7-4.76V129.89l-22.11-3.62a6.31,6.31,0,0,1-4.91-4.18,63,63,0,0,0-5-11.07,6.22,6.22,0,0,1,.28-6.6l12.72-18.73L194.82,70.18l-18,12.25a5.2,5.2,0,0,1-.61.63,6.26,6.26,0,0,1-6.82,1,61.73,61.73,0,0,0-7.88-3,6.28,6.28,0,0,1-4.36-5.77L152.4,53.26H129.83l-3.62,22.35H126c-.57,2.34-2,4.87-4,5.52a62.08,62.08,0,0,0-8.11,3.09,6.25,6.25,0,0,1-6.91-1,4.89,4.89,0,0,1-.5-.52l-17.32-13L72.84,85.31l11.59,18.94a4.69,4.69,0,0,1,.4.56,6.26,6.26,0,0,1,.26,6.54,64.71,64.71,0,0,0-4.77,10.73,6.28,6.28,0,0,1-4.93,4.16l-22.18,3.54v22.57l21.71,4.74a6.28,6.28,0,0,1,4.66,4.32,63.38,63.38,0,0,0,5,12.19,6.26,6.26,0,0,1-.15,6.2L73.09,198.62l16.38,15.52,16.61-12.63a6.24,6.24,0,0,1,6.59-.62,65.46,65.46,0,0,0,9.46,3.78,6.27,6.27,0,0,1,4.3,5Zm23.92,12.51H128.39a10.8,10.8,0,0,1-10.69-9.06l-3-18.31c-1.36-.49-2.7-1-4-1.59L96,225a11.14,11.14,0,0,1-14-.76L63.56,206.82a10.92,10.92,0,0,1-1.82-13.45l10.24-17a73,73,0,0,1-3.21-7.77l-19.56-4.31A10.76,10.76,0,0,1,40.7,153.7V128.34a10.78,10.78,0,0,1,9.08-10.68l19.91-3.25c.79-2,1.67-4,2.63-5.89L61.37,90.61A10.88,10.88,0,0,1,63.1,77.16L81.43,59.6a11,11,0,0,1,14-.85l16.66,12.52c.78-.31,1.56-.61,2.36-.9l3.24-20.58a10.77,10.77,0,0,1,10.68-9.07h25.36a10.89,10.89,0,0,1,10.58,8.52L169,70.36c.72.26,1.43.52,2.14.8L189,59.09a11,11,0,0,1,13.53,1.1l18.42,17.43a10.78,10.78,0,0,1,1.5,14L211,108.39c1,2,1.93,4.08,2.75,6.17l19.92,3.26a10.78,10.78,0,0,1,9.08,10.68v25.36a10.87,10.87,0,0,1-8.56,10.49l-19.53,4.27q-1.26,3.57-2.85,7l10.87,15.8a10.81,10.81,0,0,1-1.42,14L203,223a11.07,11.07,0,0,1-13.51,1.11l-16-10.69c-1.64.74-3.32,1.43-5.07,2.06l-4.11,18.76a10.92,10.92,0,0,1-10.59,8.5" transform="translate(-40.7 -40.72)" fill="#003087" />
				</svg>
			</span>
			{active &&
				<div ref={menu}>
					<h6>Seite hinzufügen zu:</h6>
					<span className="button add" onClick={() => handleSelect('custom-a')}>{ customFolders.foldersNames['custom-a'] ? customFolders.foldersNames['custom-a'] : 'Folder A'}</span>
					<span className="button add" onClick={() => handleSelect('custom-b')}>{ customFolders.foldersNames['custom-b'] ? customFolders.foldersNames['custom-b'] : 'Folder B'}</span>
					<span className="button add" onClick={() => handleSelect('custom-c')}>{ customFolders.foldersNames['custom-c'] ? customFolders.foldersNames['custom-c'] : 'Folder C'}</span>
					<span className="button add" onClick={() => handleSelect('custom-d')}>{ customFolders.foldersNames['custom-d'] ? customFolders.foldersNames['custom-d'] : 'Folder D'}</span>
					<span className="button add" onClick={() => handleSelect('custom-e')}>{ customFolders.foldersNames['custom-e'] ? customFolders.foldersNames['custom-e'] : 'Folder E'}</span>
					<span className="button add" onClick={() => handleSelect('custom-f')}>{ customFolders.foldersNames['custom-f'] ? customFolders.foldersNames['custom-f'] : 'Folder F'}</span>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.17 64.35">
						<polygon points="0 0 0 64.35 32.17 32.17 0 0 0 0" fill="#002C8A" />
					</svg>
				</div>
			}
		</div>
	);
};

const mapStateToProps = (state, props) => {
	const { folders } = state

	return {
		customFolders: folders
	}
}

const mapDispatchToProps = dispatch => ({
	saveFolder: (folder, slides) => dispatch(saveFolder(folder, slides)),
})


export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Dropdown))