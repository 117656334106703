import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

import img5 from './assets/img5.svg';
import img6 from './assets/img6.svg';

const Slide = styled.div`
    
    .box3 {
        padding-top: 15px;

        > p {
            position: relative;
            z-index: 201;
        }
    }

    .overlay1 {
        z-index: 200
    }

    .box3 .number {
        position: absolute;
        display: block;
        left: 0px;
        top: -48px;
        font-size: 64px;
        line-height: 80px;
        color: #003087;
        font-style: italic;
        text-align: left;
    } 

    .img5 {
        position: absolute;
        left: 173px;
        top: 151px;
        width: 684px;
    }

    .img6 {
        position: absolute;
        left: 167.5px;
        top: 148px;
        width: 696px;
    }

    .box4 {
        font-size: 28px;
        line-height: 31.5px;
        color: #9ce2e5;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Neue Kontaktlinsenkunden</strong> für<br />
                das Optiker Geschäft</h1>

            <img src={img6} className="img6" />

            <img src={img5} className="img5" />

            <Container left={433} top={340} width={1184} className="color1 flex-all-center text-center" as="p">
                <strong>Attraktive Kommunikation für Neuträger<br />
                    für den POS, CRM und Social Media Kanäle</strong>
            </Container>

            <Container left={378} top={506} width={1184} className="box4" as="p">
                <strong>INKLUSIVE</strong>
            </Container>

            <Container left={433} top={512} width={1184} className="color1 flex-all-center text-center" as="p">
                <strong>motivierende Cashback-Aktion</strong>
            </Container>

            <Container left={335} top={664} width={1392} height={185} className="bg1 color1 flex-all-center text text-center fadeInFromTop">
                <Arrow width={1284} className="container-top" />
                <p>Die Kampagne richtet sich insbesondere an Neuträger, die dazu<br />
                    ermuntert werden, ACUVUE® OASYS 1-Day auszuprobieren. </p>
            </Container>


            <Container left={113} top={978} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate2">
                <span className="number">1</span>
                <p><strong>POS</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'} />
            </Container>

            <Container left={577} top={978} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate3">
                <span className="number">2</span>
                <p><strong>30 € Cashback</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay2')}
                    active={state.overlay1 === 'overlay2'} />
            </Container>

            <Container left={1041} top={978} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate4">
                <span className="number">3</span>
                <p><strong>Newsletter</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay3')}
                    active={state.overlay1 === 'overlay3'} />
            </Container>

            <Container left={1505} top={978} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate5">
                <span className="number">4</span>
                <p><strong>Social Media</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay4')}
                    active={state.overlay1 === 'overlay4'} />
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 overlay1-3 with-bg bg3">
                <Container left={113} top={317} width={444} height={793} className="box2 bg2 color1">
                </Container>
                <Image left={142} top={346} width={387} height={648} src={img1} />

                <Container left={113} top={978} width={444} height={132} className="box3 color1 text-center">
                    <p><strong>POS</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'} />
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="overlay1 overlay1-3 with-bg bg3">
                <Container left={577} top={317} width={444} height={793} className="box2 bg2 color1">
                </Container>
                
                <Image left={678} top={408} width={251} height={536} src={img4} />

                <Container left={577} top={978} width={444} height={132} className="box3 color1 text-center">
                    <p><strong>30 € Cashback</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay2')}
                        active={state.overlay1 === 'overlay2'} />
                </Container>
            </Overlay>

         


            <Overlay active={state.overlay1 === 'overlay3'} className="overlay1 overlay1-3 with-bg bg3">
                <Container left={1041} top={317} width={444} height={793} className="box2 bg2 color1">
                </Container>
                
                <Image left={1081} top={342} width={367} height={652} src={img2} />

                <Container left={1041} top={978} width={444} height={132} className="box3 color1 text-center">
                    <p><strong>Newsletter</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay3')}
                        active={state.overlay1 === 'overlay3'} />
                </Container>

            </Overlay>
            
            <Overlay active={state.overlay1 === 'overlay4'} className="overlay1 overlay1-3 with-bg bg3">
                <Container left={1505} top={317} width={444} height={793} className="box2 bg2 color1">
                </Container>
                
                <Image left={1613} top={355} width={221} height={627} src={img3} />

                <Container left={1505} top={978} width={444} height={132} className="box3 color1 text-center">
                    <p><strong>Social Media</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay4')}
                        active={state.overlay1 === 'overlay4'} />
                </Container>

            </Overlay>
            

        </Slide>
    );
};
