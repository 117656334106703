import React from "react";
import styled, { keyframes, css } from "styled-components";
import { calculateDimensionProperty } from "components/helper";

const breatheAnimation = keyframes`
    0% {
        transform: scale(1)
    }
    60% {
        transform: scale(1.2)
    }
    100% {  
        transform: scale(1)
    }
`;

export const BtnContainer = styled.span`
  position: absolute;
  z-index: 100;
  width: 42px;
  height: 42px;

  &.active {
    z-index: 300;
  }

  &.x-left {
    left: -21px;
  }

  &.x-right {
    right: -21px;
  }

  &.x-center {
    right: calc(50% - 21px);
  }

  &.y-bottom {
    bottom: -21px;
  }

  &.y-center {
    top: calc(50% - 21px);
  }

  ${({ left }) =>
    left !== undefined &&
    css`
      left: ${calculateDimensionProperty(left)};
    `}

  ${({ top }) =>
    top !== undefined &&
    css`
      top: ${calculateDimensionProperty(top)};
    `}
`;

export const MoreBtn2Container = styled.svg`
  position: absolute;
  z-index: 100;
  width: 42px;
  height: 42px;
  cursor: pointer;

  &:not(.active) {
    animation: ${breatheAnimation} 2s infinite;
  }

  image {
    overflow: visible;
    width: 60px;
    height: 60px;
    transform: translate(15px, 18px);
    mix-blend-mode: multiply;
    opacity: 0.3;
  }
`;

const MoreBtn2 = (props) => {
  const onClick = (event) => {
    event.stopPropagation();
    props.onClick();
  };

  return (
    <BtnContainer
      className={props.className + (props.active ? " active" : "")}
      style={props.style}
      left={props.left}
      top={props.top}
    >
      <MoreBtn2Container
        width="42"
        height="42"
        viewBox="0 0 82.79 82.79"
        className={props.active ? " active" : ""}
        onClick={onClick}
      >
        <g>
          <path
            fill={props.fill ?? "#001848"}
            className="fill"
            d="M41.4,76.16A34.77,34.77,0,1,0,6.63,41.4,34.77,34.77,0,0,0,41.4,76.16"
            transform="translate(0 0)"
          />
          <path
            stroke="#fff"
            d="M41.4,76.16A34.77,34.77,0,1,0,6.63,41.4,34.77,34.77,0,0,0,41.4,76.16"
            transform="translate(0 0)"
            fill="none"
            strokeWidth="4"
          />

          {!props.exclamation && (
            <>
              <path
                d="M41.4,45.9a4.5,4.5,0,1,0-4.5-4.5,4.51,4.51,0,0,0,4.5,4.5"
                transform="translate(0 0)"
                fill="#fff"
                style={{ opacity: props.active ? 0 : 1 }}
              />
              <path
                d="M29.67,45.9a4.5,4.5,0,1,0-4.5-4.5,4.51,4.51,0,0,0,4.5,4.5"
                transform="translate(0 0)"
                fill="#fff"
                style={{ opacity: props.active ? 0 : 1 }}
              />
              <path
                d="M53.13,45.9a4.5,4.5,0,1,0-4.5-4.5,4.51,4.51,0,0,0,4.5,4.5"
                transform="translate(0 0)"
                fill="#fff"
                style={{ opacity: props.active ? 0 : 1 }}
              />
            </>
          )}
          {props.exclamation && !props.active && (
            <path
              d="M9.15,12.95H.62v25.9h8.53V12.95ZM9.39,2.83c-.26-.57-.61-1.07-1.04-1.48-.43-.42-.95-.74-1.56-.99C6.18.12,5.55,0,4.89,0,4.19,0,3.55.13,2.96.39c-.59.26-1.11.6-1.56,1.01-.45.42-.8.91-1.04,1.48-.24.57-.36,1.17-.36,1.79,0,1.28.47,2.38,1.4,3.3.94.92,2.1,1.38,3.48,1.38.66,0,1.29-.12,1.9-.36.61-.24,1.13-.57,1.56-.99.43-.42.78-.91,1.04-1.48.26-.57.39-1.19.39-1.85s-.13-1.27-.39-1.85"
              fill="#fff"
              transform="translate(36 19)"
            />
          )}

          <line
            x1="27.29"
            y1="27.29"
            x2="55.51"
            y2="55.51"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3.99"
            style={{ opacity: props.active ? 1 : 0 }}
          />
          <line
            x1="55.51"
            y1="27.29"
            x2="27.28"
            y2="55.51"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3.99"
            style={{ opacity: props.active ? 1 : 0 }}
          />
        </g>
      </MoreBtn2Container>
    </BtnContainer>
  );
};

export default MoreBtn2;
