import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';

const Slide = styled.div`
    h1 {
        position: relative;
    }

    .box1 {
        z-index: 10;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 76px;
            margin-left: 19px;
            margin-top: 14px;
        }

        .count {
            flex: 0 0 138px;
            margin-top: 20px;
            margin-left: 10px;

            span:nth-child(1) {
                display: inline-block;
                font-size: 62px;
                line-height: 77px;
                width: 77px;
                text-align: right;
            }

            span:nth-child(2) {
                font-size: 33.5px;
            }
        }

        p {
            margin-left: 2px;
        }
    }

    .box2 {
        padding-top: 122px;
    }

    .box3 {
        img {
            width: 72px;
            margin-left: 36px;
        }

        .count {
            flex: 0 0 138px;
            margin-top: 3px;
            margin-left: 15px;

            span:nth-child(1) {
                display: inline-block;
                font-size: 62px;
                line-height: 77px;
                width: 77px;
                text-align: right;
            }

            span:nth-child(2) {
                font-size: 33.5px;
            }
        }
    }

    .box4 img {
        position: absolute;
        left: -30px;
        top: -8px;
        width: 35px;
    }

    .box5 {
        padding-top: 16px;
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    const count1 = useCounter(0, 25, props.active, props.preview)
    const count2 = useCounter(0, 9, props.active, props.preview)
    const count3 = useCounter(0, 27, props.active, props.preview)
    const count4 = useCounter(0, 35, props.active, props.preview)

    console.log(props.step)

    return (
        <Slide>
            <h1><strong>Vergleich der Auswirkung auf die Umwelt</strong></h1>
            <Container left={142} top={242} width={837} height={207} className="rounded rounded-full flex color1 box1">
                <img src={icon1} />
                <span className="count"><span>{count1}</span><span>mg</span></span>
                <p><strong>Kunststoff<br/>
Ein-Tageslinsen</strong> Einzelpaar</p>
            </Container>
            <Container left={142} top={490} width={837} height={207} className="rounded rounded-full flex color1 box1">
                <img src={icon1} />
                <span className="count"><span>{count2}</span><span>g</span></span>
                <p><strong>Kunststoff<br/>
Ein-Tageslinsen</strong> Jahresbedarf
</p>
            </Container>
            <Container left={142} top={737} width={837} height={207} className="rounded rounded-full flex color1 box1">
                <img src={icon1} />
                <span className="count"><span>{count3}</span><span>g</span></span>
                <p><strong>Kunststoff<br/>
Ein-Tageslinsen</strong> 3-Jahresbedarf
</p>
            </Container>

            <div className="container-animate fadeInFromLeft">
            <Arrow left={1026} top={697} width={270} />

            <Container left={1119} top={641} width={786} height={379} className="bg1 color1 text-center box2">
                <p><strong>Kunststoff Brille</strong><br />
Wiederkauf-Rhythmus &lt; 3 Jahre</p>
            </Container>

            <Container left={1256} top={688} width={509} height={165} className="rounded rounded-full flex color1 box3">
                <img src={icon2} />
                <span className="count"><span>{count4}</span><span>g</span></span>
            </Container>
            </div>
            
            <div className="container-animate fadeIn animate2">
            <MoreBtn
                left={1502} top={1095}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')} 
                className="more1" />

            <Container left={565} top={1072} width={924} height={200} className="text-center color1 box4">
                <img src={icon3} />
                <p>Studien zeigen, dass es <strong>kaum Unterschiede<br />
zwischen Tages- und Austauschlinsen<br /> 
gibt und deren Auswirkung auf die Umwelt.<sup>15</sup></strong></p>
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg overlay1">
                <Container left={1128} top={814} width={845} height={331} className="rounded rounded-full color1 text-center box5">
                    <p>Die <strong>fachgerechte und<br /> 
recyclingfähige Entsorgung</strong> der<br /> 
Kontaktlinsen im Hausmüll und<br /> 
deren Verpackung im gelben Sack<br />
ist unbedingt zu beachten.</p>
                </Container>
                <Image left={1007} top={894} width={199} height={199} src={icon4} imageWidth={122} className="rounded border bg-white image-center" />
            </Overlay>
            </div>

          
    
        </Slide>
    );
};
