export const formatThousands = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const calculateDimensionProperty = (value) => {
    if (typeof value === 'number') {
        return `${value / 2}px`;
    }

    if (typeof value === 'string') {
        return value;
    }

    return 'inherit';
}

