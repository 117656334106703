import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow2, Arrow3, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from './assets/icon1.svg'
import icon2 from './assets/icon2.svg'
import icon3 from './assets/icon3.svg'
import img1 from './assets/img1.png'

const Slide = styled.div`
   
`
const inititialState = {
    overlay1: false
}


export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Bedürfnis-Erkennung: <strong>Jeder Verbraucher hat mehrere,<br />
unterschiedlich stark ausgeprägte Bedürfnisse</strong></h1>
            <Container left={341} top={293} width={1364} height={270} className="rounded text-center flex-all-center color1">
                <p><strong>Werden die stärkeren Motive und Bedürfnisse</strong><br />
(hier Kompetenz in Form von z.B. bedürfnisgerechter Beratung)<br /> 
<strong>nicht erfüllt, treten die zunächst nicht so starken Motive</strong><br /> 
(z.B. Preis & Bequemlichkeit) <strong>prominent hervor.</strong></p>
            </Container>

            <Arrow left={1010} top={-50} width={1393} className="rotate90" />

            <Container left={212} top={912} width={480} height={197} className="rounded rounded-full text-center flex-all-center color1 fadeIn">
                <p><strong>Keine 
Bedürfnisgerechte
Beratung</strong></p>
            </Container>

            <Container left={783} top={912} width={480} height={197} className="rounded rounded-full text-center flex-all-center color1 fadeIn animate2">
                <p><strong>Preis tritt als<br />Bedürfnis stark<br />hervor</strong></p>
            </Container>

            <Container left={1356} top={912} width={480} height={197} className="rounded rounded-full text-center flex-all-center color1 fadeIn animate3">
                <p><strong>Einfachste Weg<br />zum günstigsten<br />Preis: Internet
</strong></p>
            </Container>

            <Image left={349} top={778} width={209} height={103} src={icon1} className="fadeIn" />
            <Image left={971} top={764} width={106} height={116} src={icon2} className="fadeIn animate2" />
            <Image left={1514} top={762} width={149} height={118} src={icon3} className="fadeIn animate3" />
            <Image left={360} top={730} width={183} height={222} src={img1} className="fadeIn" />

            <Arrow2 left={662} top={937} className="fadeIn animate2" />
            <Arrow2 left={1235} top={937} className="fadeIn animate3" />

        </Slide>
    );
};
