import React from 'react';
import styled, { css } from 'styled-components'
import { Image, Container, Arrow, MoreBtn, Overlay } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useSlideState } from 'components/hooks'

import bg from './assets/bg.jpg'
import bg2 from './assets/bg2.jpg'
import bg3 from './assets/bg3.jpg'
import img1 from './assets/img1.png'
import img2 from './assets/img2.png'

const Background = styled.div`
    ${({ active }) => active === false && css`
        opacity: 0;
    `}

    transition: all 1s ease-out;
`

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        line-height: 27.5px;
    }

    .overlay1,
    .overlay2 {
        > .box1 {
            border: 1.5px solid #3a8dde;
            border-radius: 15px;
            background: #fff;
        }

        .text1 {
            font-size: 25px;
            line-height: 26.5px;
            text-transform: uppercase;
        }

        .text2 {
            font-size: 25px;
            line-height: 30px;
            color: #1b365d;
        }

        .img1 {
            position: absolute;
            left: 240px;
            top: 170px;
            width: 525px;
        }

        .img2 {
            position: absolute;
            left: 197px;
            top: 236px;
            width: 588px;
        }
    }

    .step2 {
        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}
    }

    .step3 {
        animation-play-state: paused;

        ${({ step }) => step >= 3 && css`
            animation-play-state: running;
        `}
    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }
`

const inititialState = {
    overlay1: null
}


const Folder2Slide1 = ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <Background className="bg" style={{ backgroundImage: `url(${bg})` }}></Background>
            <Background className="bg" style={{ backgroundImage: `url(${bg2})` }} active={props.step >= 2}></Background>
            <Background className="bg" style={{ backgroundImage: `url(${bg3})` }} active={props.step >= 3 || props.preview}></Background>
            <Slide className="centered" step={props.step}>
                <h1>Für alternde Augen sind die<br />
                    intensiven Anforderungen der heutigen<br />
                    Zeit <strong>besonders herausfordernd</strong></h1>

                <div className="container-animate fadeInFromLeft">

                    <Arrow left={58} top={875} width={202} offset={-20} arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={164} top={887} width={730} as="p" className="text1">
                        Die Akkommodations-<br />
                        fähigkeit lässt nach,<br />
                        die Nahsicht<br />
                        verschlechtert sich
                    </Container>

                </div>


                <Arrow left={1214} top={425} width={308} offset={-70} arrowColor={'#3a8dde'} lineColor={'#ffffff'} className="fadeInFromLeft step2" />

                <Container left={1380} top={482} width={680} as="p" className="text1 fadeInFromLeft step2">
                    Die Aufrisszeit des Tränen-<br />
                    films ist bei 50-Jährigen<br />
                    bereits um <strong>50 % verkürzt</strong><sup>1</sup>,<br />
                    was zu Augentrockenheit<br />
                    und verschwommenem<br />
                    Sehen führen kann
                </Container>

                <MoreBtn
                    left={1899} top={744}
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'}
                    className="fadeIn step2"
                />

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />
                    <Container left={203} top={147} width={1650} className="text1 text-center color2">
                        <strong>Die Tränenfilmstabilität lässt mit dem Alter nach</strong>
                    </Container>
                    <Container left={148} top={236} width={1750} className="text2 text-center">
                        Bei 50-Jährigen ist die Tränenfilmaufrisszeit bereits um 50 % verkürzt<sup>1</sup>
                    </Container>

                    <img src={img1} className="img1" />

                </Overlay>

                <Arrow left={1235} top={848} width={258} offset={-50} arrowColor={'#3a8dde'} lineColor={'#ffffff'} className="fadeInFromLeft step3" />

                <Container left={1380} top={891} width={680} as="p" className="text1 fadeInFromLeft step3">
                    Die Lichtstreuung ist bei<br />
                    60-Jährigen <strong>verdoppelt</strong><br />
                    und geht mit vermehrten<br />
                    visuellen Beeinträchti-<br />
                    gungen einher<sup>2</sup>
                </Container>

                <MoreBtn
                    left={1899} top={1094}
                    onClick={() => toggleOverlay('overlay2')}
                    active={state.overlay1 === 'overlay2'}
                    className="fadeIn step3"
                />

                <Overlay active={state.overlay1 === 'overlay2'} className="overlay2">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />
                    <Container left={203} top={147} width={1650} className="text1 text-center color2">
                        <strong>Die Lichtstreuung nimmt mit dem Alter zu</strong>
                    </Container>
                    <Container left={148} top={236} width={1750} className="text2 text-center">
                        Aufgrund von Veränderungen in der Augenlinse ist<br />
                        die Lichtstreuung bei 60-Jährigen um das Zweifache und<br />
                        bei 70-Jährigen um das Dreifache erhöht.<sup>2</sup>
                    </Container>

                    <img src={img2} className="img2" />

                </Overlay>



            </Slide>
        </>

    );
};

export default Folder2Slide1;