import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow2, Arrow3, Container, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import img1 from './assets/bg.jpg';

import img5 from './assets/img1.svg';

const Slide = styled.div`
    .box2 {
        font-weight: 600;
        padding-top: 8px;
        padding-left: 5px;

        li + li {
            margin-top: 25px;
        }
    }

    .box3 {
        padding-left: 30px;
    }

    .overlay4 {

        .img5 {
            position: absolute;
            left: 108.5px;
            top: 190px;
            width: 811px;
        }

        .box4 {
            font-weight: 600;
            color: #fff;
        }

        h2 {
            position: absolute;
            margin: 0;
            width: 850px;
            left: 86px;
            top: 80.5px;
            font-size: 30px;
            line-height: 37.5px;
            color: #003087;

            strong {
                font-weight: 600;
            }
        }
}
`
const inititialState = {
    overlay1: false
}


export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1><strong>Kundenbindung durch Kompetenz –</strong><br /> 
Konkrete Maßnahmen</h1>
                <Container left={100} top={318} width={1071} height={247} className="rounded rounded-full color1 flex-all-center text-center">
                    <p><strong>Konzentration auf die Kunden mit dem<br />
                    Bedürfnis „Kompetenz/Gesundheit“</strong><br />
Wie funktioniert eine Kontaktlinsen-Prozess<br />
ohne Eigenmarke?</p>
                </Container>
                <Container left={153} top={618} width={924} height={556} className="bg1 color1 box2 flex-all-center fadeInFromTop">
                    <Arrow width={803} className="container-top" />
                    <ol>
                        <li>Stringentes nach Bedürfnissen<br /> 
ausgerichtetes Produktportfolio</li>
                        <li>Klarer, knapper/kurzer Prozess
</li>
                        <li>Handhabungs-Training</li>
                        <li>Service „Verkaufen“ und<br /> 
Trennung Produkt &amp; Service</li>
                    </ol>
                </Container> 
                <MoreBtn
                    left={943} top={692}
                    active={state.overlay1 === 'overlay1'}
                    onClick={() => toggleOverlay('overlay1')}
                    className="fadeInFromTop" 
                     />
                 <MoreBtn
                    left={943} top={817}
                    active={state.overlay1 === 'overlay2'}
                    onClick={() => toggleOverlay('overlay2')}
                    className="fadeInFromTop"  
                     />
                 <MoreBtn
                    left={943} top={921}
                    active={state.overlay1 === 'overlay3'}
                    onClick={() => toggleOverlay('overlay3')}
                    className="fadeInFromTop"  
                     />
                 <MoreBtn
                    left={943} top={1036}
                    active={state.overlay1 === 'overlay4'}
                    onClick={() => toggleOverlay('overlay4')} 
                    className="fadeInFromTop" 
                     />

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                    <Container left={986} top={611} width={968} height={402} className="rounded color1 flex-all-center box3">
                        <p><strong>ACUVUE® und Eigenmarke</strong><br /> 
in Kombination mit einer <strong>Bedürfnisanalyse</strong><br />
(über Frage nach Tragezeit hinaus)<br />
und  <strong>eindeutiger Produktempfehlung,</strong><br /> 
mit <strong>dem besten passenden Produkt<br /> 
startend</strong> (Top Down)</p>
                    </Container>
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay2'} className="with-bg">
                    <Container left={986} top={698} width={814} height={319} className="rounded color1 flex-all-center box3">
                        <p><strong>Den Träger effizient durch den<br />
Anpass- und Gewöhnungsprozess<br />
führen, aber ihm Zeit lassen, seine<br />
Bedürfnisse zu äußern.</strong></p>
                    </Container>
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay3'} className="with-bg">
                    <Container left={986} top={783} width={814} height={359} className="rounded color1 flex-all-center box3">
                        <p><strong>Nach der Anpassung trainieren,<br />
                        aber auch und während der<br />
nächsten 2 -3 Wochen<br />
Unterstützung anbieten<br />
<a href="https://www.acuvue.de/handhabung" target="_blank">www.acuvue.de/handhabung</a></strong></p>
                    </Container>
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay4'} className="overlay4">
                    <Container left={100} top={100} width={1848} height={1120} className="bg1 box3">
                    </Container>

                    <img src={img5} className="img5" />

                    <h2>Exkurs: <strong>Überbrückung der Diskrepanz von<br /> 
Online und Offline Preis</strong>
                    </h2>

            

                    <Container left={217} top={804} width={306} className="text-center box4">
                        Produkt
                    </Container>
                    <Container left={870} top={804} width={306} className="text-center box4">
                        Produkt
                    </Container>
                    <Container left={1533} top={804} width={306} className="text-center box4">
                        Produkt
                    </Container>
                    <Container left={1533} top={635} width={306} className="text-center box4">
                        Handling
                    </Container>
                    <Container left={1533} top={461} width={306} className="text-center box4">
                    Dienst-<br />
leistung
                    </Container>

                    <Container left={117} top={998} width={506} className="text-center color1">
                        <p><strong>Internet-Preise</strong></p>
                    </Container>
                    <Container left={770} top={998} width={506} className="text-center color1">
                        <p><strong>Fachhandels-Preis</strong></p>
                    </Container>
                    <Container left={1433} top={998} width={506} className="text-center color1">
                        <p><strong>Zukunft?</strong></p>
                    </Container>

                    <Container left={542} top={728} width={350}>
                        <p className="small">In den Augen des<br />
                        preissensiblen<br />
                        Konsumenten ist<br />
                        dies der Maßstab</p>
                    </Container>

                    <CloseBtn
                        left={1855} top={127}

                        onClick={() => toggleOverlay('overlay4')}
                    />
                </Overlay>
            </Slide>
        </>
    );
};
