import React from 'react';
import styled, { keyframes, css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

export const TextBoxContainer = styled.div`
    position: absolute;
    left: 50px;
    top: 112px;
    width: 924px;
    height: 500px;
    overflow: auto;

    font-size: 10px;
    line-height: 12px;

    * + * {
        margin-top: 10px;
    }

    ol {
        padding-left: 18px;
    }
`

const TextBox = (props) => {
    return (  
        <TextBoxContainer>
           {props.children}
        </TextBoxContainer>
    );
};

export default TextBox;