import React from 'react';
import styled, { css } from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useCounter } from 'components/hooks'

import bg from './assets/bg.jpg'
import bg2 from './assets/bg2.jpg'
import img1 from './assets/img1.png'

const Background = styled.div`
    ${({ active }) => active === false && css`
        opacity: 0;
    `}

    transition: all 1s ease-out;
`

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 9px;
        line-height: 12.5px;
    }

    .text2 {
        line-height: 27.5px;
    }

    .text3 {
        font-size: 27.5px;
        line-height: 38px;
        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}
    }

    .text4 {
        line-height: 27.5px;

        .count {
            font-size: 35px;
            color: #fff;
        }
    }

    .container-animate {
        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}


    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

    
`

const Folder2Slide1 = ({ ...props }) => {

    const count1_1 = useCounter(0, 90, props.active && props.step >= 2, props.preview, 1)
    const count1_2 = useCounter(0, 86, props.active && props.step >= 2, props.preview, 1)

    
    const count2_1 = useCounter(0, 100, props.active && props.step >= 2, props.preview, 1)
    const count2_2 = useCounter(0, 95, props.active && props.step >= 2, props.preview, 1)

    return (
        <>
            <Background className="bg" style={{ backgroundImage: `url(${bg})` }}></Background>
            <Background className="bg" style={{ backgroundImage: `url(${bg2})` }} active={props.step >= 2}></Background>
            <Slide className="centered" step={props.step}>
                <h1><strong>Die aktuell beste Linse<br />
                    von ACUVUE<sup>®</sup></strong></h1>
                <Image left={636} top={641} width={769} height={454} src={img1} />
                <Container left={744} top={900} width={544} as="p" className="text1 text-center">
                    Nur zu Illustrationszwecken
                </Container>

                <Container left={475} top={312} width={1090} as="p" className="text2 text-center">
                    Nachgewiesene Leistung in<br />
                    klinischen Studien<sup>13</sup>
                </Container>

                <Container left={22} top={420} width={593} as="p" className="text3 text-right fadeIn">
                    <strong>MAX Tragekomfort</strong><sup>*14</sup>
                </Container>

                <div className="container-animate fadeIn animate2">

                    <Arrow left={320} top={280} width={508} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={0} top={582} width={593} as="p" className="text4 text-right">
                        <span className="count">{count1_1}%</span> erleben<br />
                        Tagesend-Komfort<sup>14</sup><br /><br />

                        <span className="count">{count1_2}%</span> erleben ein<br />
                        reduziertes Gefühl<br />
                        müder Augen bei<br />
                        Nutzung digitaler<br />
                        Geräten<sup>14</sup>
                    </Container>

                </div>

                <Container left={1450} top={420} width={593} as="p" className="text3 fadeIn">
                    <strong>MAX Sehqualität</strong><sup>*14</sup>
                </Container>

                <div className="container-animate fadeIn animate2">

                <Arrow left={1690} top={280} width={508} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                <Container left={1450} top={582} width={593} as="p" className="text4">
                Nahezu <span className="count">{count2_1}%</span><br />
                berichten über klare,<br />
                stabile Sehqualität<sup>14</sup><br /><br />

Mehr als <span className="count">{count2_2}%</span><br /> 
erleben klare Sehqualität<br /> 
bei Nutzung digitaler<br /> 
Geräten<sup>14</sup>
                </Container>

                </div>


            </Slide>
        </>

    );
};

export default Folder2Slide1;