import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.jpg';



const Slide = styled.div`
    .box1 {
       padding-left: 15px;
    }

    .box1 .number {
        position: absolute;
        display: block;
        left: 2px;
        top: -86px;
        font-size: 97.5px;
        line-height: 122px;
        font-style: italic;
        text-align: right;
    } 

    .box1-2 .number {
        left: 16px;
    } 

    .box1-3 .number {
        left: 16px;
    } 

    .box1-3 .exclamation {
        position: absolute;
        left: 700px;
        top: -13px;
        font-size: 97.5px;
        line-height: 122px;
        font-style: italic;
        text-align: right;
    }

    .box2 .number {
        position: absolute;
        display: block;
        left: -64px;
        top: -10px;
        width: 60px;
        font-size: 56.5px;
        line-height: 71px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .overlay1 {
        .img2 {
            position: absolute;
            left: 460px;
            top: 174px;
            height: 393px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (


        <Slide className="centered">
            <h1>Wie nutzen Sie die <strong>Multifokal-Kampagne</strong><br />
                am <strong>erfolgreichsten?</strong></h1>

            <Container left={246} top={440} width={1540} height={172} className="bg1 box1 box1-1 color1 flex-all-center fadeInFromLeft">
                <span className="number number1 color3">1.</span>
                <p><strong>Informieren &amp; trainieren Sie das Team zur Kampagne</strong><br />
                    (inkl. Einsatz &amp; Nutzen des Gutscheins)</p>
            </Container>

            <Container left={246} top={727} width={1540} height={172} className="bg1 box1 box1-2 color1 flex-all-center fadeInFromLeft animate2">
                <span className="number number1 color3">2.</span>
                <p>Platzieren Sie den <strong>Gutschein zusammen mit der<br />
                    Endverbraucherbroschüre</strong> sichtbar im Geschäft.</p>
            </Container>

            <Image left={1495} top={641} width={347} height={347} src={img1} className="fadeIn animate2" />

            <div className="container-animate fadeInFromLeft animate3">

                <Container left={246} top={1015} width={1540} height={172} className="bg1 box1 box1-3 color1 flex-all-center ">
                    <span className="number number1 color3">3.</span>
                    <p><strong>Nutzen Sie die weiteren Kommunikations-Maßnahmen,</strong><br />
                        um für Kunden und Mitarbeiter sichtbar zu sein.</p>

                    <span className="exclamation color3">!</span>
                    <MoreBtn
                        className="x-right y-center"
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'}
                    />
                </Container>

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg">
                    <Container left={100} top={291} width={1688} height={895} className="box2 bg2 color1">
                    </Container>

                    <Image left={611} top={340} width={1110} height={786} src={img3}  />

                    <img src={img2} className="img2" />

                    <Container as="p" left={158} top={346} width={525} className="color1">
                        <strong>Point of Sale:</strong>
                    </Container>

                    <Container as="p" left={963} top={346} width={700} className="color1">
                        <strong>Dig. und post. Mailings:</strong>
                    </Container>

                    <Container as="p" left={963} top={682} width={700} className="color1">
                        <strong>Social Media Kanäle/Website:</strong>
                    </Container>


                    <Container left={217} top={418} width={525} className="color1">
                        <Arrow left={-60} top={8} width={64} className="" arrowColor={'#00a98f'} />
                        <p>Hängen Sie die<br />Poster prominent<br />auf</p>
                    </Container>

                    <Container left={217} top={604} width={525} className="color1">
                        <Arrow left={-60} top={8} width={64} className="" arrowColor={'#00a98f'} />
                        <p><span style={{letterSpacing: '-0.035rem'}}>Stellen Sie die Broschüre</span><br />
                        und Gutschein gut<br /> 
erreichbar auf</p>
                    </Container>

                    <Container left={217} top={795} width={525} className="color1">
                        <Arrow left={-60} top={8} width={64} className="" arrowColor={'#00a98f'} />
                        <p>Befestigen Sie die<br /> 
Wobbler auffällig am<br />
Gleitsichtbrillen-Regal</p>
                    </Container>

                    <Container left={217} top={998} width={525} className="color1">
                        <Arrow left={-60} top={8} width={64} className="" arrowColor={'#00a98f'} />
                        <p>Nutzen Sie das<br /> 
Multifokal Instore<br /> 
Video</p>
                    </Container>

                    <Container left={1024} top={418} width={600} className="color1">
                        <Arrow left={-60} top={8} width={64} className="" arrowColor={'#00a98f'} />
                        <p>Schreiben Sie Linsen-<br />
träger ab 40+ und<br /> 
Gleitsichtbrillenträger an</p>
                    </Container>

                    <Container left={1024} top={752} width={600} className="color1">
                        <Arrow left={-60} top={8} width={64} className="" arrowColor={'#00a98f'} />
                        <p>Spielen Sie Social Posts,<br /> 
Stories, Banner und Videos<br /> 
aus</p>
                    </Container>



                </Overlay >

            </div >




        </Slide >

    );
};
