import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from './assets/icon1.svg';
import bg1 from './assets/bg1.svg';
import bg2 from './assets/bg2.svg';
import img1 from './assets/img1.jpg';

const Slide = styled.div`
    h1 {
        position: relative;
    }

    .box1 {
        align-items: center;
        justify-content: flex-start;

        img {
            width: 54px;
            margin-left: 27px;
        }

        p {
            margin-left: 16.5px;
        }
    }

    .box2 {
        .bg,
        p,
        .count {
            position: absolute;
        }

        p {
            left: 0;
            width: 100%;
            font-size: 19.5px;
            line-height: 22px;
            text-align: center;
        }

        .count {
            left: 0;
            width: 100%;
            top: 95px;
            width: 100%;
            font-size: 58px;
            line-height: 72px;
            text-align: center; 

            span {
                font-size: 31px;
            }
        }

        .legend {
            left: 0;
            width: 100%;
            top:160px;
        }
    }

    .box2-1,
    .box2-2 {
        .bg {
            width: 172px;
            left: 18px;
            top: 22px;
        }

        .title {
            padding-right: 10px;
            top: 37px;
        }
    }

    .box2-3,
    .box2-4 {
        .bg {
            width: 144.5px;
            left: 40px;
            top: -43px;
        }

        .title {
            padding-right: 20px;
            top: 37px;
        }
    }

    .box3 {
        align-items: center;
        justify-content: flex-start;

        .count {
            margin-top: 8px;
            margin-left: 108px;
            font-size: 58px;
            line-height: 72.5px;

            span {  
                font-size: 31px;
            }
        }

        p {         
            margin-left: 10px;
        }
    }
`

const inititialState = {
    overlay1: false
}

const formatNumber = (number) => {
    return (number / 100).toLocaleString('de-DE', { minimumFractionDigits: 2 })
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = formatNumber(useCounter(0, 214, props.active, props.preview))
    const count2 = formatNumber(useCounter(0, 180, props.active, props.preview, 1))
    const count3 = formatNumber(useCounter(0, 110, props.active, props.preview, 2))
    const count4 = formatNumber(useCounter(0, 91, props.active, props.preview, 3))

    return (
        <Slide>
            <h1><strong>Preis-Vergleich</strong></h1>

            <Arrow left={1000} top={-170} width={885} className="rotate90" />

            <Container left={465} top={342} width={1117} height={234} className="rounded rounded-full box1 color1 flex">
                <img src={icon1} />
                <p>Moderne Tageslinsen kosten heute<br />
häufig weniger als 1,23 € mehr am Tag als<br />
eine Monatslinse und passen zu jedem<br />
Kundenbedürfnis.</p>

               
            </Container>

            <Container left={101} top={680} width={422} height={393} className="bg1 box2 box2-1 color1 fadeInFromTop">
                <Arrow width={422} className="container-top" />
                <img src={bg1} className="bg" />
                <p className="title">Tageslinse<br />
Premium</p>
                <span className="count">{count1}<span>€</span></span>
                <p className="legend">am Tag<sup>14</sup></p>
            </Container>

            <Container left={576} top={680} width={422} height={393} className="bg1 box2 box2-2 color1 fadeInFromTop animate2">
                <Arrow width={422} className="container-top" />
                <img src={bg1} className="bg" />
                <p className="title">Tageslinse<br />
Normal</p>
                <span className="count">{count2}<span>€</span></span>
                <p className="legend">am Tag<sup>14</sup></p>
            </Container>

            <Container left={1050} top={680} width={422} height={393} className="bg1 box2 box2-3 color1 fadeInFromTop animate3">
                <Arrow width={422} className="container-top" />
                <img src={bg2} className="bg" />
                <p className="title">14-Tages-<br />
linse</p>
                <span className="count">{count3}<span>€</span></span>
                <p className="legend">am Tag<sup>14</sup></p>
            </Container>

            <Container left={1526} top={680} width={422} height={393} className="bg1 box2 box2-4 color1 fadeInFromTop animate4">
                <Arrow width={422} className="container-top" />
                <img src={bg2} className="bg" />
                <p className="title">Monats-<br />
linse</p>
                <span className="count">{count4}<span>€</span></span>
                <p className="legend">am Tag<sup>14</sup></p>
            </Container>

            <MoreBtn
                    left={1541} top={411}
                    active={state.overlay1 === 'overlay1'}
                    onClick={() => toggleOverlay('overlay1')}
                />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                <Container left={1234} top={247} width={697} height={206} className="rounded rounded-full color1 flex box3">
                    <span className="count">1<span>X</span></span>
                    <p>Portion frisches<br />
                    Obst am Tag</p>
                </Container>
                <Image left={1234} top={247} width={206} height={206} src={img1} className="rounded border" />
            </Overlay>
        </Slide>
    );
};
