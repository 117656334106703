import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, PlayBtn } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
    .box1 {
        opacity: 0.8;
    }

    .btn1 {
        display: block;
        position: absolute;
        z-index: 150;
        width: 40px;
        height: 40px;
        left: 491px;
        top: 381px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Simulation der <strong>Kontrastleistung</strong> von ACUVUE® OASYS 1-Day</h1>

            <Container as="p" left={201} top={294} width={1640} className="text-center color1">
                <strong>Mit dem Antippen der Simulation werden die Kreise beweglich:</strong><br />
                Erleben Sie die 2 Szenen dank dem erhöhtem Kontrast mit ACUVUE® OASYS 1-Day
            </Container>

            <Container left={100} top={460} width={1848} height={682} className="bg1">

            </Container>

            <Container as="p" left={160} top={489} width={849} className="text-center small box1">
                <strong>bei hellem Licht</strong>
            </Container>

            <Container as="p" left={1038} top={489} width={849} className="text-center small box1">
                <strong>bei dunklem Licht</strong>
            </Container>

            <Image src={img1} left={160} top={550} width={850} height={530} />
            <Image src={img2} left={1038} top={550} width={850} height={530} />

            <Arrow left={566} top={222} width={688} className="rotate90" bgColor={'#D0F1F2'} />
            <Arrow left={1444} top={222} width={688} className="rotate90" bgColor={'#D0F1F2'} />

            <PlayBtn left={982} top={762} className="play" />

            <a href="http://www.digifileexchange.com/JandJ/OA1D/interactive/2020_0325/us/index.html" target="_blank" className="btn1"></a>

        </Slide>
    );
};
