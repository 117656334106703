import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Arrow, Container, MoreBtn, Overlay } from 'components/parts'

const Slide = styled.div`
    > .boxes {
        display: flex;
        flex-wrap: wrap;
        
        > div {
            flex: 0 0 152px;
            position: relative;
            height: 133px;
            padding: 0 10px;
            margin-left: 20px;
            margin-bottom: 16px;
            
            p + p {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                transform: translateY(50%);
                opacity: 0;

                ${({ step }) => step !== 1 && css`
                    opacity: 1;
                `}
            }   
        }
    }
`
const inititialState = {
    overlay1: false
}

const boxes = [
    { text: 'Ich arbeite viel am PC/Tablet' },
    { text: 'Ich sehe gerne Serien oder Videos' },
    { text: 'Ich möchte überwiegend Kontaktlinsen tragen' },
    { text: 'Ich will es unkompliziert' },
    { text: 'Ich wünsche mir lange Tragezeiten' },
    { text: 'Gesundheit ist mir wichtig' },
    { text: 'Ich möchte Kontaktlinsen für die Freizeit' },
    { text: 'Meine Augen reagieren sensibel' },
    { text: 'Ich treibe häufig und gerne Sport' },
    { text: 'Ich fahre viel Auto' },
    { text: 'Ich habe lange Arbeitstage' },
    { text: 'Ich bin oft<br />mit dem Smartphone zugange' },
]

export default ({...props}) => {
    const [state, setState] = useState(inititialState)

    console.log(props.step)

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Bedürfnis-Erfüllung in Bezug auf das Produkt</strong></h1>
            <h2>Welche Kontaktlinse ist für den Kunden die Richtige</h2>
            <Container left={354} top={319} width={1400}  className="boxes">
                {boxes.map((box, i) => (
                    <Container className="bg1 flex-all-center text-center small" key={i}>
                        <p dangerouslySetInnerHTML={{__html: box.text}} />
                        <p className="color1"><strong>Tageslinse</strong></p>
                    </Container>
                ))}
            </Container>
        </Slide>
    );
};
