import React from 'react';
import styled, { css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

export const Arrow4Container = styled.svg`
   
    ${({ left, top }) => (left !== undefined || top !== undefined) && css`
        position: absolute;
    `}

    width: 27px;

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}

    &.inline {
        margin-left: 2px;
        margin-top: -10px;
        position: relative;
        top: 7px;
    }
`

export const Arrow4 = (props) => {

    let color = props.color ? props.color : '#f49922'

    return (
        <Arrow4Container xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 54.6 53.44" left={props.left} top={props.top} className={props.className}>
            <path d="M53.6,24.48,21.62.37c-1.37-1-3.1.22-3.1,2.24v7.1h-16A2.57,2.57,0,0,0,0,12.28V41.16a2.57,2.57,0,0,0,2.56,2.57h16v7.1c0,2,1.73,3.27,3.1,2.24L53.6,29a3,3,0,0,0,0-4.48" transform="translate(0 0)" fill={color} />
        </Arrow4Container>
    );
};

export default Arrow4;