import React, { useEffect } from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow, MoreBtn, PlayBtn, Overlay, InfoBtn, CloseBtn } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useSlideState, useCounter } from 'components/hooks'

import bg from './assets/bg.jpg'
import overlay1Bg from './assets/overlay1-bg.jpg'
import overlay3Bg from './assets/overlay3-bg.jpg'
import overlay4Bg from './assets/overlay4-bg.jpg'
import overlay6Bg from './assets/overlay6-bg.jpg'

import img1_1 from './assets/img1_1.svg'
import img4_1 from './assets/img4_1.svg'
import img6_1 from './assets/img6_1.svg'

import overlay9_img1 from './assets/overlay9_img1.jpg'
import overlay9_img2 from './assets/overlay9_img2.jpg'
import overlay9_img3 from './assets/overlay9_img3.jpg'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 45px;
        text-align: center;
        color: #fff;
    }

    > .text1 {
        font-size: 25px;
        line-height: 26.5px;
    }

    > .line {
        display: block;
        position: absolute;
        height: 1.2px;
        background: #fff;

        &.line1 {
            left: 87px;
            top: 255px;
            width: 358px;
        }

        &.line2 {
            left: 650px;
            top: 255px;
            width: 278px;
        }
    }

    > .text2 {
        font-size: 20px;
        line-height: 20px;

        > span {
           font-size: 16px;
        }
    }

    > .text3 {
        font-size: 16px;
        line-height: 22.5px;
    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

    .overlay1,
    .overlay1-1,
    .overlay2,
    .overlay3,
    .overlay4,
    .overlay4-1,
    .overlay5,
    .overlay6,
    .overlay7,
    .overlay8,
    .overlay9,
    .overlay9_1,
    .overlay9_2,
    .overlay9_3  {
        > .box1 {
            border: 1.5px solid #3a8dde;
            border-radius: 15px;
        }
    }

    .overlay1 {
        > .box1 {
            background: url(${overlay1Bg});
            background-size: cover;
        }

        > .text1 {
            line-height: 27.5px;
        }
    }

    .overlay1-1 {
        > .box1 {
            background: #fff;
        }

        > .text1 {
            font-size: 25px;
            line-height: 26.5px;
        }

        > .text2 {
            font-size: 25px;
            line-height: 30px;
            color: #1b365d;
        }

        > .text3 {
            font-size: 12.5px;
            line-height: 16px;
            color: #1b365d;

            h3 {
                margin-bottom: 19.5px;
                font-weight: 700;
                font-size: 17.5px;
                line-height: 19.5px;
            }

            ul {
                margin-top: 16px;
                padding-left: 16px;
            
                li {
                    position: relative;
                }

                li::before {
                    position: absolute;
                    left: -16px;
                    top: -2px;
                    content:"·";
                    vertical-align:middle;
                }

                li + li {
                    margin-top: 10px;
                }
            }
        }

        > .text4 {
            font-size: 9px;
            line-height: 11px;
            color: #1b365d;
        }

        > .img1_1 {
            position: absolute;
            left: 421px;
            top: 250px;
            width: 477px;
        }
    }

    .overlay2,
    .overlay5,
    .overlay7,
    .overlay8,
    .overlay9,
    .overlay9_1,
    .overlay9_2,
    .overlay9_3 {
        > .box1 {
            background: #13174a;
        }
    }

    .overlay3 {
        > .box1 {
            background: #E6E6E6;
            background-image: url(${overlay3Bg});
            background-size: cover;
        }

        > .text1 {
            font-size: 25px;
            line-height: 26.5px;
            text-transform: uppercase;
            color: #3a8dde;
        }

        > .text2 {
            color: #1b365d;
            
            p {
                line-height: 27.5px;
            }

            span {
                font-size: 15px;
                line-height: 20px;
            }
        }

        > .text3 {
            line-height: 27.5px;
            color: #1b365d;
        }

        > .text4 {
            font-size: 15px;
            line-height: 20px;
            color: #1b365d;
        }

        .text5 {
            font-size: 15px;
            line-height: 20px;
            color: #1b365d;
            transform: rotate(25deg);

            span {
                display: block;
                font-size: 10.5px;
                line-height: 12.5px;
                color: #000000;
            }
        }

        .text6 {
            font-size: 15px;
            line-height: 20px;
            color: #1b365d;
        }

        .text7 {
            font-size: 9px;
            line-height: 12.5px;
            color: #000000;
        }

        ${ArrowContainer} {
            span {
                background: #3a8dde;
            }
        }
    }

    .overlay4 {
        > .box1 {
            background: url(${overlay4Bg});
            background-size: cover;
        }

        > .text1 {
            line-height: 27.5px;
        }

        > .text2 {
            font-size: 18.5px;
            line-height: 25px;
        }
    }

    .overlay4-1 {
        > .box1 {
            background: #fff;
        }

        > .text1 {
            font-size: 25px;
            line-height: 26.5px;
        }

        > .text2 {
            font-size: 25px;
            line-height: 30px;
            color: #1b365d;
        }

        > .text3 {
            font-size: 12.5px;
            line-height: 16px;
            color: #1b365d;

            h3 {
                margin-bottom: 19.5px;
                font-weight: 700;
                font-size: 17.5px;
                line-height: 19.5px;
            }

            ul {
                margin-top: 16px;
                padding-left: 16px;
            
                li {
                    position: relative;
                }

                li::before {
                    position: absolute;
                    left: -16px;
                    top: -2px;
                    content:"·";
                    vertical-align:middle;
                }

                li + li {
                    margin-top: 10px;
                }
            }
        }

        > .text4 {
            font-size: 15px;
            line-height: 18px;
        }

        > .img4_1 {
            position: absolute;
            left: 419px;
            top: 262px;
            width: 434px;
        }
    }

    .overlay2,
    .overlay5,
    .overlay7,
    .overlay8,
    .overlay9_1,
    .overlay9_2,
    .overlay9_3  {
        video {
            position: absolute;
            left: 82px;
            top: 80px;
            width: 858px;
            height: 480px;
        }
    }

    .overlay6 {
        > .box1 {
            background: url(${overlay6Bg});
            background-size: cover;
        }

        > .text1 {
            line-height: 27.5px;
        }

        > .text2 {
            font-size: 12px;
            line-height: 14.5px;
        }

        > .img6_1 {
            position: absolute;
            left: 92px;
            top: 472px;
            width: 23px;
        }
    }

    .overlay9 {
        > .img1,
        > .img2,
        > .img3 {
            position: absolute;
            width: 245px;
            height: 165px;
            top: 190px;
        }

        .img1 {
            left: 106px;
        }

        .img2 {
            left: 387px;
        }

        .img3 {
            left: 669px;
        }

        > .text1 {
            font-size: 34.5px;
            line-height: 1;
        }

        > .text4 {
            font-size: 18.5px;
            line-height: 25px;
            letter-spacing: -0.05em;
        }
    }
    
`
const inititialState = {
    overlay1: null,
    overlay2: null
}

const Folder2Slide1 = ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })

    useEffect(() => {

        var mediaElement = document.getElementById("video");
        if (mediaElement) {
            if (state.overlay1 === 'overlay5') {
                mediaElement.play();
            } else {
                mediaElement.pause();
                mediaElement.currentTime = 0;
            }
        }

        let video2 = document.getElementById("video2");
        if (video2) {
            if (state.overlay1 === 'overlay2') {
                video2.play();

            } else {
                video2.pause();
                video2.currentTime = 0;
            }
        }

        let video7 = document.getElementById("video7");
        if (video7) {

            if (state.overlay1 === 'overlay7') {
                video7.play();
            } else {
                video7.pause();
                video7.currentTime = 0;
            }
        }

        let video8 = document.getElementById("video8");
        if (video8) {

            if (state.overlay1 === 'overlay8') {
                video8.play();

            } else {
                video8.pause();
                video8.currentTime = 0;
            }
        }

    }, [state.overlay1])

    useEffect(() => {
        let video91 = document.getElementById("video91");
        if (video91) {

            if (state.overlay2 === 'overlay9-1') {
                video91.play();

                console.log(state.overlay2)

            } else {
                video91.pause();
                video91.currentTime = 0;
            }
        }

        let video92 = document.getElementById("video92");
        if (video92) {

            if (state.overlay2 === 'overlay9-2') {
                video92.play();

                console.log(state.overlay2)

            } else {
                video92.pause();
                video92.currentTime = 0;
            }
        }

        let video93 = document.getElementById("video93");
        if (video93) {

            if (state.overlay2 === 'overlay9-3') {
                video93.play();

                console.log(state.overlay2)

            } else {
                video93.pause();
                video93.currentTime = 0;
            }
        }


    }, [state.overlay2])

    return (
        <>
            <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Der <strong>MAX</strong> Unterschied</h1>

                <Container left={174} top={318} width={684} as="p" className="text1">
                    <strong>TEARSTABLE<sup>™</sup><br />
                        TECHNOLOGY</strong><br />
                    Für <strong>MAX</strong> Tragekomfort<sup>*8</sup>
                </Container>

                <span className="line line1" />

                <MoreBtn
                    left={57} top={470}
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'}
                />

                <PlayBtn
                    left={57} top={605}
                    onClick={() => toggleOverlay('overlay2')}
                    active={state.overlay1 === 'overlay2'}
                    className="fadeIn animate3"
                />

                <MoreBtn
                    left={57} top={737}
                    onClick={() => toggleOverlay('overlay3')}
                    active={state.overlay1 === 'overlay3'}
                    className="fadeIn animate3"
                />

                <PlayBtn
                    left={57} top={863}
                    onClick={() => toggleOverlay('overlay7')}
                    active={state.overlay1 === 'overlay7'}
                    className="fadeIn animate3"
                />


                <Container left={174} top={627} width={683} as="p" className="text2 fadeIn animate3">
                    Mode of Action Video
                </Container>

                <Container left={174} top={758} width={683} as="p" className="text2 fadeIn animate3">
                    Neuer Herstellungsprozess
                </Container>

                <Container left={174} top={862} width={617} as="p" className="text2 fadeIn animate3">
                    <strong>ACUVUE® Expert</strong><br />
                    <span>1-Minute Technology Talk</span>
                </Container>

                <Container left={1242} top={318} width={617} as="p" className="text1 text-right fadeIn animate2">
                    <strong>OPTIBLUE<sup>™</sup><br />
                        LIGHT FILTER<sup>**</sup></strong><br />
                    Für <strong>MAX</strong> Sehqualität<sup>*8</sup>
                </Container>

                <Container left={1242} top={605} width={617} as="p" className="text2 text-right  fadeIn animate4">
                    <strong>Filter Demonstration</strong><br />
                    <span>OptiBlue™Light Filter</span>
                </Container>

                <Container left={1242} top={736} width={617} as="p" className="text2 text-right  fadeIn animate4">
                    <strong>ACUVUE® Expert</strong><br />
                    <span>1-Minute Technology Talk</span>
                </Container>

                <Container left={1242} top={862} width={617} as="p" className="text2 text-right  fadeIn animate4">
                    <strong>Filter Demonstration</strong><br />
                    <span>Beispiele aus dem täglichen Leben</span>
                </Container>

                <Container left={1042} top={1037} width={817} as="p" className="text3 text-right  fadeIn animate5">
                    <strong>ACUVUE® OASYS MAX 1-Day</strong><br />
                    hat eine einzigartige blau-grüne Tönung<br />
                    aufgrund der Kombination des Blau-Violett-<br />
                    Lichtfilters und der Handhabungstönung<sup>8</sup>
                </Container>

                <span className="line line2 fadeIn animate2" />

                <MoreBtn
                    left={1899} top={470}
                    onClick={() => toggleOverlay('overlay4')}
                    active={state.overlay1 === 'overlay4'}
                    className="fadeIn animate2"
                />

                <PlayBtn
                    left={1899} top={605}
                    onClick={() => toggleOverlay('overlay5')}
                    active={state.overlay1 === 'overlay5'}
                    className="fadeIn animate4"
                />

                <PlayBtn
                    left={1899} top={737}
                    onClick={() => toggleOverlay('overlay8')}
                    active={state.overlay1 === 'overlay8'}
                    className=" fadeIn animate4"
                />

                <PlayBtn
                    left={1899} top={863}
                    onClick={() => {

                        if (state.overlay2) {
                            updateState('overlay2', null)
                        } else {
                            toggleOverlay('overlay9')

                        }

                    }}
                    active={state.overlay1 === 'overlay9'}
                    className="fadeIn animate4"
                />

                <MoreBtn
                    left={1899} top={1016}
                    onClick={() => toggleOverlay('overlay6')}
                    active={state.overlay1 === 'overlay6'}
                    className="fadeIn  animate5"
                />

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />
                    <Container left={255} top={230} width={740} as="p" className="text1">
                        <strong>TEARSTABLE™<br />
                            TECHNOLOGY</strong><br />
                        Für <strong>hohen</strong> Tagesend-Komfort<sup>*8</sup>
                    </Container>

                    <Arrow left={562} top={134} width={648} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={255} top={495} width={740} as="p" className="text1">
                        <strong>Optimierte Verteilung des<br />
                            Benetzungswirkstoffs</strong> in der<br />
                        gesamten Linse und Oberfläche<sup>8-10</sup><br /><br />

                        <strong>Längere Tränenfilmstabilität</strong><sup>8-10</sup><br /><br />

                        <strong>2x niedrigere Verdunstungsrate<br />
                            im Vergleich zu</strong> DAILIES Total1®,<br />
                        MyDay® und ULTRA® ONE DAY<sup>***8,10</sup>
                    </Container>


                    <InfoBtn
                        left={146} top={889}
                        onClick={() => updateState('overlay2', state.overlay2 !== 'overlay1-1' ? 'overlay1-1' : null)}
                        active={state.overlay2 === 'overlay1-1'}
                    />

                    <Overlay active={state.overlay2 === 'overlay1-1'} className="overlay1-1">

                        <Container left={187} top={140} width={1712} height={1000} className="box1" />

                        <Container left={407} top={186} width={1263} as="p" className="text1 text-center color2">
                            <strong>ACUVUE® OASYS MAX 1-Day</strong>
                        </Container>

                        <Container left={254} top={276} width={1570} as="p" className="text2 text-center">
                            Längere Tränenfilmstabilität durch niedrigere Verdunstungsrate<sup>#8-10</sup><br />
                            <strong className="color2">2x niedriger</strong> als bei DAILIES TOTAL1®, My Day® und<br />
                            ULTRA® ONE DAY<sup>#8,10</sup>
                        </Container>

                        <Container left={310} top={493} width={500} className="text3">
                            <h3><strong className="color2">TEARSTABLE<sup>™</sup><br />
                                TECHNOLOGY</strong></h3>
                            <p>Unser erstes und einziges Linsenmaterial, das auf Verlängerung der Tränenfilm-Stabilität ausgelegt ist<sup>10</sup></p>

                            <ul><li>Modernstes Herstellungsverfahren<sup>8</sup></li>
                                <li>Optimierte Verteilung des Benetzungs-wirkstoffs in der gesamten Linse und Oberfläche<sup>8-10</sup></li>
                                <li>1,6x höhere Wahrscheinlichkeit<br />
                                    für längere Tränenfilm-Aufrisszeit<sup>†10</sup></li></ul>
                        </Container>

                        <Container left={310} top={1066} width={1500} as="p" className="text4">
                            Adjustierte Mittelwerte der Pervaporationsrate bei 40 % relativer Luftfeuchtigkeit (RH) für ACUVUE® OASYS MAX 1-Day, DAILIES Total1® und ULTRA® ONE DAY.

                        </Container>

                        <img src={img1_1} className="img1_1" />

                    </Overlay>

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay2'} className="overlay2">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />


                    <video id="video2" width="863" height="499" controls>
                        <source src="/media/ACU_MAX_MOA_Family_Longform_VO_720.mp4" type="video/mp4" />
                    </video>
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay3'} className="overlay3">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />

                    <Container left={139} top={148} width={1800} as="p" className="text1 text-center">
                        <strong>Unser  fortschrittlichster Herstellungsprozess<br />
                            und -kontrolle</strong>
                    </Container>

                    <Container left={208} top={358} width={1100} className="text2">
                        <p>Neuer, optimierter Polymerizations-Prozess<br />
                            mit doppelseitig angeordneten LED<br /> <br />

                            Optimierte Benetzungswirkstoff (PVP)-<br />
                            Verteilung in der Linse einschließlich der<br />
                            Verteilung auf der Oberfläche<sup>1,2,3</sup></p>
                        <span>(PVP = Polyvinyl pyrrolidone, unser einzigartiger hoch-<br />
                            molekularer Benetzungswirkstoff  der membrangebundenen<br />
                            Mucinen nachempfunden ist<sup>4,5</sup>)</span>
                    </Container>

                    <Arrow left={624} top={460} width={863} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#3a8dde'} />

                    <Container left={208} top={920} width={1100} as="p" className="text3">
                        <strong>Erlaubt die Integration der TearStable™<br />
                            Technology und dem OptiBlue™ Light Filter über<br />
                            die gesamte ACUVUE® OASYS MAX 1-Day<br />
                            Produktfamilie.<sup>6</sup></strong>
                    </Container>

                    <Container left={1171} top={293} width={286} as="p" className="text4 text-center">Alter Prozess</Container>
                    <Container left={1544} top={293} width={228} as="p" className="text4 text-center">Neuer Prozess</Container>
                    <Container left={1373} top={435} width={228} as="p" className="text4 text-center"><strong>vs.</strong></Container>

                    <Container left={1040} top={668} width={627} height={231} as="p" className="text5 text-center">
                        Cure Light
                        <span>(Licht zur<br />
                            Polymerization)</span>
                    </Container>

                    <Container left={1346} top={1017} width={627} height={231} as="p" className="text5 text-center">
                        Cure Light
                        <span>(Licht zur<br />
                            Polymerization)</span>
                    </Container>

                    <Container left={1355} top={884} width={374} as="p" className="text6 text-center">
                        <strong>Lichtdämpfung</strong>
                    </Container>

                    <Container left={1078} top={1126} width={858} as="p" className="text7 text-center">
                        Nur zu Illustrationszwecken
                    </Container>

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay4'} className="overlay4">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />

                    <Container left={984} top={230} width={803} as="p" className="text1 text-right">
                        <strong>OPTIBLUE<sup>™</sup><br />
                            LIGHT FILTER</strong><sup>*</sup><br />
                        Für <strong>MAX</strong> Sehqualität<sup>*8</sup><br />
                    </Container>

                    <Arrow left={1444} top={134} width={648} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={984} top={501} width={803} as="p" className="text1 text-right">
                        <strong>Der stärkste Blau-Violett-<br />
                            Lichtfilter auf dem Markt</strong><sup>*^8,11</sup><br /><br />

                        <strong>Reduziert:</strong><br />
                        <strong>- Streulicht um 20 %</strong><sup>**8,9</sup><br />
                        <strong>- Halos um 30%</strong><sup>**8,9</sup><br />
                        <strong>- Strahlenkränze um 23%</strong><sup>**8,9</sup>

                    </Container>

                    <Container left={984} top={1013} width={900} as="p" className="text2 text-right">
                        <strong>ACUVUE® OASYS MAX 1-Day bietet Klasse-1-<br />
                            UV-Schutz: 99,9% UV-A und 100% UV-B.</strong><sup>§‡10</sup>
                    </Container>

                    <InfoBtn
                        left={1808} top={509}
                        onClick={() => updateState('overlay2', state.overlay2 !== 'overlay4-1' ? 'overlay4-1' : null)}
                        active={state.overlay2 === 'overlay4-1'}
                    />

                    <Overlay active={state.overlay2 === 'overlay4-1'} className="overlay4-1">

                        <Container left={140} top={140} width={1712} height={1000} className="box1" />

                        <Container left={407} top={186} width={1263} as="p" className="text1 text-center color2">
                            <strong>ACUVUE® OASYS MAX 1-Day</strong>
                        </Container>

                        <Container left={333} top={276} width={1400} as="p" className="text2 text-center">
                            <strong className="color2">Der stärkste</strong> Blau-Violett-Filter auf dem Markt<sup>+**8,10,11</sup>
                        </Container>

                        <Container left={243} top={445} width={500} className="text3">
                            <h3 className="color2">OPTIBLUE<sup>™</sup><br />
                                LIGHT FILTER</h3>
                            <p> Minimierung der Lichtstreuung zur<br />
                                Verbesserung der Sehqualität, der<br />
                                Fernsicht<br />
                                und des Sehkomforts.<sup>12</sup></p>

                            <ul>
                                <li>Blau-violettes Licht umgibt uns überall.<sup>12</sup></li>
                                <li>Das kurzwellige blau-violette Licht streut stärker und wird von digitalen Geräten, LEDs und insbesondere der Sonne ausgestrahlt.<sup>12</sup></li>
                                <li>Streulicht kann Blendung, Halos und
                                    verminderten Kontrast verursachen.<sup>12</sup></li>
                            </ul>
                        </Container>

                        <Container left={966} top={944} width={740} as="p" className="text4 color2 text-center">
                            <strong>% Filterung von Blau-Violett-Licht, 380–450 nm</strong>
                        </Container>

                        <img src={img4_1} className="img4_1" />


                    </Overlay>

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay5'} className="overlay5">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />

                    <video id="video" width="863" height="499" controls>
                        <source src="/media/PP2022MLT6563_ACUVUE_OASYS_MAX_1-Day_OptiBlue_Technology_Demo.mp4" type="video/mp4" />
                    </video>

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay6'} className="overlay6">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />

                    <Container left={255} top={193} width={710} as="p" className="text1">
                        ACUVUE® OASYS MAX 1-Day<br />
                        mit <strong>einzigartiger blau-grüner<br />
                            Handhabungstönung</strong><sup>8</sup>
                    </Container>

                    <Arrow left={562} top={114} width={648} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={255} top={485} width={730} as="p" className="text1">
                        Einzigartiges Aussehen aufgrund<br />
                        der Kombination eines blau-<br />
                        violetten / hoch-energetischen<br />
                        sichtbaren HEV-Lichtfilters und<br />
                        blauer Tönung<sup>8</sup>.<br /><br />

                        Der kosmetische Effekt auf das<br />
                        Auge ist ähnlich wie bei einer<br />
                        klaren Linse mit blauer Tönung,<br />
                        welche <strong>das natürliche Aussehen<br />
                            des Auges am Rand der Iris<br />
                            minimal  verändern könnte.</strong>
                    </Container>

                    <Container left={1123} top={620} width={210} as="p" className="text2 text-center">
                        Light Blue
                    </Container>
                    <Container left={1517} top={620} width={210} as="p" className="text2 text-center">
                        Light Blue
                    </Container>

                    <Container left={1123} top={851} width={210} as="p" className="text2 text-center">
                        LMedium Blue
                    </Container>
                    <Container left={1517} top={851} width={210} as="p" className="text2 text-center">
                        Medium Blue
                    </Container>


                    <Container left={1123} top={1085} width={210} as="p" className="text2 text-center">
                        Medium Brown
                    </Container>
                    <Container left={1517} top={1085} width={210} as="p" className="text2 text-center">
                        Medium Brown
                    </Container>

                    <img src={img6_1} className="img6_1" />

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay7'} className="overlay7">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />


                    <video id="video7" width="863" height="499" controls>
                        <source src="/media/b250350d-c13e-4189-9a4f-2b15aa8614f9.mp4" type="video/mp4" />
                    </video>
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay8'} className="overlay7">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />


                    <video id="video8" width="863" height="499" controls>
                        <source src="/media/b243a5d8-fe7e-4038-bbcf-ed116d44234f.mp4" type="video/mp4" />
                    </video>
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay9'} className="overlay9">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />

                    <Container left={100} top={171} width={1840} as="p" className="text1 text-center color2">
                        <strong>Das Leben verlangt mehr von unseren Augen</strong>
                    </Container>

                    <Container left={255} top={269} width={1600} as="p" className="text2">
                        Erleben Sie die Wirkung des OptiBlue™ Light Filters für MAX Sehqualität<sup>*1</sup>
                    </Container>

                    <img
                        className="img1"
                        src={overlay9_img1}
                        onClick={() => updateState('overlay2', 'overlay9-1')} />

                    <img
                        className="img2"
                        src={overlay9_img2}
                        onClick={() => updateState('overlay2', 'overlay9-2')} />

                    <img
                        className="img3"
                        src={overlay9_img3}
                        onClick={() => updateState('overlay2', 'overlay9-3')} />

                    <Arrow left={442} top={650} width={368} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />
                    <Arrow left={1007} top={650} width={368} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />
                    <Arrow left={1572} top={650} width={368} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={212} top={742} width={490} as="p" className="text3 text-center">
                        <strong>TAGSÜBER</strong>
                    </Container>

                    <Container left={777} top={742} width={490} as="p" className="text3 text-center">
                        <strong>DIGITAL</strong>
                    </Container>

                    <Container left={1336} top={742} width={490} as="p" className="text3 text-center">
                        <strong>NACHTS</strong>
                    </Container>

                    <Container left={212} top={877} width={490} as="p" className="text4 text-center">
                        Visuelle Erholung<br />
                        24 % schnellere Erholung<br />
                        des Sehvermögens bei<br />
                        hellem Licht.<sup>*20</sup>

                    </Container>

                    <Container left={677} top={877} width={690} as="p" className="text4 text-center">
                        Reduziertes Gefühl müder Augen<br />
                        86 % der Träger berichten von<br />
                        einer Verringerung des Gefühls<br />
                        müder Augen bei Verwendung eines<br />
                        digitalen Geräts.<sup>††21</sup>
                    </Container>

                    <Container left={1336} top={877} width={490} as="p" className="text4 text-center">
                        Komfortable Nachtfahrten<br />
                        &gt; 90 % der Träger geben an,<br />
                        mühelos sehen zu können,<br />
                        wenn sie nachts fahren.<sup>§§21</sup>

                    </Container>


                    <Overlay active={state.overlay2 === 'overlay9-1'} className="overlay9_1">
                        <Container left={100} top={100} width={1840} height={1080} className="box1" />

                        <video id="video91" width="863" height="499" controls>
                            <source src="/media/2e296534-68a7-44eb-a61c-f1b6001bfb69.mp4" type="video/mp4" />
                        </video>
                    </Overlay>

                    <Overlay active={state.overlay2 === 'overlay9-2'} className="overlay9_2">
                        <Container left={100} top={100} width={1840} height={1080} className="box1" />

                        <video id="video92" width="863" height="499" controls>
                            <source src="/media/dcac598a-5a18-444f-8691-caad5eeaf76a.mp4" type="video/mp4" />
                        </video>
                    </Overlay>

                    <Overlay active={state.overlay2 === 'overlay9-3'} className="overlay9_3">
                        <Container left={100} top={100} width={1840} height={1080} className="box1" />

                        <video id="video93" width="863" height="499" controls>
                            <source src="/media/88b8eee0-e0a5-4d4b-980f-9213121c2e32.mp4" type="video/mp4" />
                        </video>
                    </Overlay>

                </Overlay>


            </Slide>
        </>

    );
};

export default Folder2Slide1;