import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import logo1 from './assets/logo1.svg';
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
   .circle1 {
        position: absolute;
        left: 143px;
        top: 102.5px;
        width: 163px;
        height: 163px;
        border-radius: 50%;
        background: #002C8B;
    }

    .logo1 {
        position: absolute;
        left: 152px;
        top: 158px;
        width: 146px;
    }

    .box1 .number {
        position: absolute;
        display: block;
        width: 70px;
        text-align: right;
        left: -56px;
        top: -23px;
        font-size: 65px;
        line-height: 65px;
        color: #390075;
        font-style: italic;
        text-align: right;
    } 

    .box2 {
        padding-top: 25px;
    } 
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Aktionsplan:</strong> sichern &amp; erweitern Sie Ihr Kontaktlinsengeschäft</h1>
            

            <div className="circle1"></div>
            <img src={logo1} className="logo1" />
            <Image left={860} top={205} width={326} height={326} src={img1} className="border rounded" />
            <Image left={1452} top={205} width={326} height={326} src={img2} className="border rounded" />

            <Container left={233} top={414} width={435} height={170} className="rounded rounded-full color1 flex flex-all-center text-center box1">
                <span className="number">1.</span>
                <p><strong>Kontaktlinsen<br />
Portfolio</strong></p>
            </Container>
            <Container left={806} top={414} width={435} height={170} className="rounded rounded-full color1 flex flex-all-center text-center box1">
                <span className="number">2.</span>
                <p><strong>Professionelle<br />
Unterstützung</strong></p>
            </Container>
            <Container left={1399} top={414} width={435} height={170} className="rounded rounded-full color1 flex flex-all-center text-center box1">
                <span className="number">3.</span>
                <p><strong>Begeistern Sie<br /> 
Ihre Kunden für<br /> 
Kontaktlinsen</strong></p>
            </Container>

            <Container left={190} top={628} width={521} height={593} className="bg1 box2 color1 text-center">
                <Arrow width={447} className="container-top" />
                
            </Container>

            <Container left={763} top={628} width={521} height={593} className="bg1 box2 color1 text-center">
                <Arrow width={447} className="container-top" />
               
            </Container>

            <Container left={1355} top={628} width={521} height={593} className="bg1 box2 color1 text-center">
                <Arrow width={447} className="container-top" />
                
            </Container>

        </Slide>
    );
};
