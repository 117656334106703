import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import logo1 from './assets/logo1.svg';
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
   .circle1 {
        position: absolute;
        left: 143px;
        top: 172.5px;
        width: 163px;
        height: 163px;
        border-radius: 50%;
        background: #002C8B;
    }

    .logo1 {
        position: absolute;
        left: 152px;
        top: 228px;
        width: 146px;
    }

    .box1 .number {
        position: absolute;
        display: block;
        width: 70px;
        text-align: right;
        left: -56px;
        top: -23px;
        font-size: 65px;
        line-height: 65px;
        color: #390075;
        font-style: italic;
        text-align: right;
    } 

    .box2 {
        padding-top: 25px;
    } 
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Unser gemeinsames Ziel: Schutz &amp; Wachstum der <strong>Kategorie<br /> 
Kontaktlinse für nachhaltigen Erfolg und Kundenzufriedenheit</strong></h1>
            <h2>3 Möglichkeiten, wie ACUVUE® Sie in der Praxis unterstützt:</h2>

            <div className="circle1"></div>
            <img src={logo1} className="logo1" />
            <Image left={860} top={345} width={326} height={326} src={img1} className="border rounded" />
            <Image left={1452} top={345} width={326} height={326} src={img2} className="border rounded" />

            <Container left={233} top={554} width={435} height={170} className="rounded rounded-full color1 flex flex-all-center text-center box1">
                <span className="number">1.</span>
                <p><strong>Kontaktlinsen<br />
Portfolio</strong></p>
            </Container>
            <Container left={806} top={554} width={435} height={170} className="rounded rounded-full color1 flex flex-all-center text-center box1">
                <span className="number">2.</span>
                <p><strong>Professionelle<br />
Unterstützung</strong></p>
            </Container>
            <Container left={1399} top={554} width={435} height={170} className="rounded rounded-full color1 flex flex-all-center text-center box1">
                <span className="number">3.</span>
                <p><strong>Zielgerichtete<br />Kampagnen</strong></p>
            </Container>

            <Container left={190} top={768} width={521} height={367} className="bg1 box2 color1 text-center fadeInFromTop">
                <Arrow width={447} className="container-top" />
                <p>ACUVUE® - die von Optikern <strong>am meisten getragene
Kontaktlinsenmarke</strong><br />
in Deutschland.
<sup>3</sup></p>
            </Container>

            <Container left={763} top={768} width={521} height={367} className="bg1 box2 color1 text-center fadeInFromTop animate2">
                <Arrow width={447} className="container-top" />
                <p>Unsere Training-Teams<br />
arbeiten <strong>Kunden-<br />
orientiert und<br />
unterstützen Sie bei<br />
Ihrer Fortbildung und<br />
die Ihres Teams.</strong></p>
            </Container>

            <Container left={1355} top={768} width={521} height={367} className="bg1 box2 color1 text-center fadeInFromTop animate3">
                <Arrow width={447} className="container-top" />
                <p>Werben Sie mit<br />
<strong>starken und kreativen<br /> 
Kampagnen, um Ihre Kunden für<br />
Kontaktlinsen zu<br /> 
begeistern.</strong></p>
            </Container>

        </Slide>
    );
};
