import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'

const Theme = {
    color1: '#00a98f',
    color2: '#003087',
    color3: '#00a98f',
    bg1: '#d8f2ef',
    bg2: '#ffffff',
}

export const Slides = {
    'Folder10Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder10Slide2': { name: Slide2, theme: Theme },
    'Folder10Slide3': { name: Slide3, theme: Theme },
    'Folder10Slide4': { name: Slide4, theme: Theme },
    'Folder10Slide5': { name: Slide5, theme: Theme },
    'Folder10Slide6': { name: Slide6, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder10Slide1' },
    { key: 1, component: 'Folder10Slide2' },
    { key: 2, component: 'Folder10Slide3' },
    { key: 3, component: 'Folder10Slide4' },
    { key: 4, component: 'Folder10Slide5' },
    { key: 5, component: 'Folder10Slide6' },
]