import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';

import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';
import icon5 from './assets/icon5.svg';
import icon6 from './assets/icon6.svg';

import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.svg';

const Slide = styled.div`
    .box2 {
        padding-top: 28px;
        padding-right: 60px;
    }

    .box3 { 
        ul {
            margin-top: 10px;
            padding-left: 13px;
        }
        
        li {
            position: relative;
        }

        li::before {
            position: absolute;
            left: -15px;
            top: 0;
            content:"·";
            font-size:45px;
            vertical-align:middle;
            
        }
    }

    .box4 > div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 120px;
        margin-left: 96px;
        width: 700px;

        > div {    
            position: relative;
            flex: 0 0 337px;
            margin-bottom: 21px;
            height: 66px;
            padding-left: 57px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            font-weight: 500;
            text-transform: uppercase;

            > img {
                position: absolute;
                height: 65.5px;
                left: 0;
                top: 0;
            }

            .icon4 {
                height: 75px;
                top: -10px;
            }

            h3 {
                font-weight: 500;
                font-size: 19.5px;
                line-height: 23.5px;
                color: #002C8B;     
            }

            p {
                font-size: 14px;
                line-height: 16px;
                color: #00A5E3;
            }
        }
    }

    .img6 {
        position: absolute;
        width: 37px;
        left: 509px;
        top: 367px;
    }

    .overlay1 {
        .img4 {
            position: absolute;
            left: 36px;
            top: 10px;
            width: 754px;
        }
    }
    

    .overlay2 {
        .img5 {
            position: absolute;
            width: 275px;
            left: 370px;
            top: 70px;
        }

        .img3 {
            position: absolute;
            width: 726px;
            left: 150px;
            top: 268px;
        }

        .title {
            font-size: 17px;
            line-height: 20px;
            color: #244081;
        }

        .text1,
        .text2 {
            font-size: 13px;
            line-height: 15px;
        }

        .text3 {
            font-size: 12.5px;
            line-height: 15px;
        }

        .labels {
            position: absolute;
            left: 0;
            top: 0;
        }

        .label {
            position: absolute;
            font-size: 11.4px;
            line-height: 13.7px;
        }
        
        .label1 {
            left: 159px;
            top: 289px;
            width: 100px;
            transform: rotate(8deg);
            transform-origin: left top;
        }

        .label2 {
            left: 269px;
            top: 337px;
        }

        .label3 {
            left: 335px;
            top: 327px;
            width: 100px;
            transform: rotate(55deg);
            transform-origin: left top;
        }

        .label4 {
            left: 463px;
            top: 257px;
        }

        .label5 {
            left: 462px;
            top: 280px;
            font-size: 17px;
            line-height: 20px;
            color: #244081;
            font-weight: 600;
        }

        .label6 {
            left: 486px;
            top: 312px;
        }

        .label7 {
            left: 378px;
            top: 336px;
            width: 266px;
            text-align: center;
            font-size: 13px;
            line-height: 15px;
        }

        .label8 {
            left: 484px;
            top: 387px;
        }

        .label9 {
            left: 487px;
            top: 410px;
        }

        .label10 {
            left: 689px;
            top: 337px;
        }

        .label11 {
            left: 807px;
            top: 298px;
            width: 100px;
            transform: rotate(-8deg);
            transform-origin: left top;
        }

        .box5 {
            position: absolute;
            left: 68px;
            top: 65px;
            text-transform: uppercase;
            padding-left: 43px;
            width: 250px;
            padding-top: 15px;
            font-weight: 500;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 37px;
            }

            h3 {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #002C8B;     
            }

            p {
                font-size: 10.5px;
                line-height: 14.5px;
                color: #00A5E3;
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Die Produktfamilie ACUVUE® OASYS 1-Day</strong> bietet ein<br />
komplettes Sortiment an Kontaktlinsen …</h1>
            <Container left={343} top={287} width={1362} height={119} className="rounded rounded-full text-center flex-all-center color1">
                <p><strong>…um Ihren Kunden hervorragenden Tragekomfort,<br />
beste Sehqualität und einfache Handhabung zu bieten.</strong></p>
            </Container>

            <div class="container-animate fadeInFromTop">
                <Container left={285} top={460} width={1478} height={351} className="bg1 text-center color1 box2">
                    <Arrow width={1390} className="container-top" />
                    <p>ACUVUE® OASYS 1-Day with HydraLuxe® Kontaktlinsen<br />
    sind für <strong>fast 96% der sphärischen und astigmatisch<br />
    fehlsichtigen Augen geeignet.<sup>40</sup></strong></p>
                </Container>

                <Image left={370} top={680} width={504} height={165} src={img1} />
                <Image left={1121} top={680} width={504} height={165} src={img2} />
               
            </div>

            <MoreBtn
                className="fadeInFromTop"
                left={1784} top={458}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')}
            />

            <MoreBtn
                className="fadeInFromTop"
                left={974} top={768}
                onClick={() => toggleOverlay('overlay2')}
            />

            <img src={img6} class="fadeInFromTop img6" />
            
            <div class="container-animate fadeIn animate2">
            <Arrow left={608} top={602} width={567} className="rotate90" />
            <Arrow left={1360} top={601} width={567} className="rotate90" />
            
            </div>

            <div class="container-animate fadeInFromTop animate3">
               

                <Container left={337} top={926} width={600} className="box3">
                    <p className="color1"><strong>Myopie und Hyperopie</strong></p>
                    <ul className="small">
                        <li>Von -12,00 dpt bis +8,00 dpt</li>
                        <li>2 Basiskurven: 8,5 mm &amp; 9,0 mm</li>
                        <li>Durchmesser: 14,3 mm</li>
                    </ul>
                </Container>

                <Container left={1095} top={926} width={892} className="box3">
                    <p className="color1"><strong>Astigmatismus</strong></p>
                    <ul className="small">
                        <li>Von -9,00 dpt bis +4,00 dpt</li>
                        <li>1 Basiskurve: 8,5 mm</li>
                        <li>Durchmesser: 14,3 mm</li>
                        <li>4 Zylinder: -0,75 dpt, -1,25 dpt,<br />-1,75 dpt, –2,25 dpt"</li>
                        <li>2.260 Parameter</li>
                    </ul>
                </Container>
            </div>

           

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg">
                <Container left={194} top={449} width={1660} height={785} className="rounded box4">
                    <img src={img4} class="img4" />
                    <div>
                        <div className>
                            <img src={icon1} />
                            <h3>Integrierter Benetzungswirkstoff</h3>
                            <p>Für mehr Feuchtigkeit</p>
                        </div>
                        <div>
                            <img src={icon4} className="icon4" />
                            <h3>UV-Schutz</h3>
                            <p>Für Augengesundheit</p>
                        </div>
                        <div>
                            <img src={icon3} />
                            <h3>Superdünner Rand</h3>
                            <p>Für höchsten Tragekomfort</p>
                        </div>
                        <div>
                            <img src={icon5} />
                            <h3>Lidschlag-Stabilisations-deSign</h3>
                            <p>Für Astigmatismus</p>
                        </div>
                        <div>
                            <img src={icon2} />
                            <h3>Antioxidantien</h3>
                            <p>Für mehr Komfort</p>
                        </div>
                        <div>
                            <img src={icon6} />
                            <h3>pupillen optimiert</h3>
                            <p>FÜR multifokale sicht</p>
                        </div>
                        
                     
                        
                    </div>
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="with-bg bg-white overlay2">
                <Container left={100} top={100} width={1848} height={1120} className="bg2 box3">
                </Container>

                <Container left={208} top={421} width={1620} height={615} className="rounded"></Container>

                <img src={img5} class="img5" />

                <Container left={100} top={289} width={1840} as="p" className="color1 text-center"><strong>
                <span style={{color: '#009ca6'}}>ACUVUE® OASYS 1-Day Kontaktlinsen</span> bieten UV-Schutz der Klasse 1<br /> 
und schützen vor 99,9 % der UV-B- und 96 % der UV-A-Strahlen.<sup>*42</sup>
                </strong></Container>

                <img src={img3} className="img3" />

                <span className="label label1">UV-Strahlen</span>
                <span className="label label2">Augenlinse</span>
                <span className="label label3">UV-Strahlen</span>
                <span className="label label4">Sonnenbrillenglas</span>

                <span className="label label5">Kontaktlinse</span>
                <span className="label label6">Hornhaut</span>

                <span className="label label7">Die Hornhaut bricht und intensiviert<br />
                seitlich einfallende UV-Strahlen<br /> 
um das bis zu 20-Fache.<sup>42</sup></span>

                <span className="label label8">Bindehaut</span>
                <span className="label label9">Netzhaut</span>

                <span className="label label10">Augenlinse</span>

                <span className="label label11">UV-Strahlen</span>
                
                <Container left={460} top={465} width={300} as="p" className="title">
                <strong>Mit Sonnenbrille</strong>
                </Container>

                <Container left={1157} top={465} width={600} as="p" className="title">
                <strong>Mit Sonnenbrille UND Kontaktlinse</strong>
                </Container>

                <Container left={309} top={881} width={572} as="p" className="text-center text1">
                Sonnenbrillen bieten eventuell keinen vollkommenen Schutz vor UV-Strahlung, da seitlich einfallendes Licht um die Gläser herum zum Auge gelangen kann.
                </Container>

                <Container left={1171} top={881} width={544} as="p" className="text-center text2">
                <strong>Kontaktlinsen mit UV-Schutz helfen, die Hornhaut und das Augeninnere vor einfallender UV-Strahlung zu schützen.</strong>
                </Container>

                <Container left={100} top={1091} width={1848} as="p" className="text-center text3">
                Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2 UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor<br/> schädlicher UV-Strahlung zu schützen.UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen,<br/> 
                da sie das Auge und seine Umgebung nicht vollständig abdecken. UV-Transmission mit –1,00 Dioptrien Linse gemessen.
                </Container>

                <div className="box5">
                    <img src={icon4} className="icon4" />
                    <h3>UV-Schutz</h3>
                    <p>Für Augengesundheit</p>
                </div>

                <CloseBtn
                        left={1855} top={127}

                        onClick={() => toggleOverlay('overlay2')}
                        className="more1" />
            </Overlay>

        </Slide>
    );
};
