import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn,
  Overlay,
  CloseBtn,
} from "components/parts";

import HeaderDots from "../../shared/HeaderDots";

import checkmark from "./assets/checkmark.svg";
import arrow from "./assets/arrow.svg";

const Slide = styled.div`
  background: #0a7cc1;
  inset: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "AvenirNext", sans-serif;

  h1 {
    margin-top: 26px;
    color: #fff !important;
  }

  .box1 {
    &::before {
      position: absolute;
      left: -37px;
      top: -5px;
      display: block;
      width: 26px;
      height: 26px;
      background: url(${checkmark});
      content: "";
    }

    div {
      display: flex;
      align-items: center;
      line-height: 22.5px;
      align-items: flex-start;

      &::before {
        content: "...\\00a0";
      }
    }
  }

  .box2 {
    border-radius: 46px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 80px;
    background: #ff7641;
    line-height: 28px;
  }

  .box3 {
    padding-left: 240px;
    border-radius: 14.5px;
  }

  .box3-1 {
    font-size: 16px;
    line-height: 20.5px;

    &::before {
      position: absolute;
      left: -44px;
      top: -0px;
      display: block;
      width: 18px;
      height: 24px;
      background: url(${arrow});
      content: "";
    }
  }

  .copy-2 {
    font-size: 8px;
    line-height: 10px;
  }
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide step={props.step}>
      <div className="centered">
        <h1>
          <strong>Noch mehr Vorteile:</strong>
          <br />
          ACUVUE® Kontaktlinsen für Astigmatismus …
        </h1>

        <HeaderDots top={268} />

        <Container left={196} top={344} width={782} className="box1 fadeIn">
          <div>
            <p>
              haben eine durchschnittliche Erfolgsquote bei der ersten Anpassung
              von 95 %.<sup>*1</sup>
            </p>
          </div>
        </Container>

        <Container
          left={196}
          top={520}
          width={782}
          className="box1 fadeIn animate2"
        >
          <div>
            <p>
              stabilisieren und orientieren sich innerhalb von Minuten nach dem
              Aufsetzen.<sup>1</sup>
            </p>
          </div>
        </Container>

        <Container
          left={196}
          top={700}
          width={782}
          className="box1 fadeIn animate3"
        >
          <div>
            <p>
              sind <strong>schnell</strong> anzupassen.
            </p>
          </div>
        </Container>

        <Container
          left={196}
          top={784}
          width={782}
          className="box1 fadeIn animate4"
        >
          <div>
            <p>
              und genauso <strong>einfach</strong> anzupassen
              <br />
              wie sphärische ACUVUE® Kontaktlinsen.<sup>1</sup>
            </p>
          </div>
        </Container>

        <Container
          left={1059}
          top={344}
          width={782}
          className="box1 fadeIn animate5"
        >
          <div>
            <p>
              bieten täglich ein problemloses Aufsetzen dank ihres symme-
              <br />
              trischen Designs.<sup>12</sup>
            </p>
          </div>
        </Container>

        <Container
          left={1059}
          top={520}
          width={782}
          className="box1 fadeIn animate6"
        >
          <div>
            <p>
              können nicht verkehrt herum aufgesetzt werden.<sup>12</sup>
            </p>
          </div>
        </Container>

        <Container
          left={1014}
          top={686}
          width={1574}
          height={206}
          className="box2 flex flex-all-center relative"
        >
          <p>
            <strong>9 von 10 Kontaktlinsenspezialisten </strong>
            <br />
            bestätigen die{" "}
            <strong>
              einfache Anpassung<sup>1</sup>
            </strong>
          </p>
        </Container>

        <div className="container-animate">
          <Container
            left={100}
            top={987}
            width={1848}
            height={230}
            className="box3 bg3  flex flex-all-center relative"
          />

          <Container
            left={282}
            top={1042}
            width={721}
            height={172}
            className="box3-1"
          >
            Einfache & schnelle Anpassung bedeutet
            <br />
            eine <strong>Zeitersparnis für Sie</strong> und ein{" "}
            <strong>
              erfolgreiches Kontaktlinsen-Erlebnis für Ihren Kunden.
            </strong>
          </Container>

          <Container
            left={1142}
            top={1042}
            width={761}
            height={172}
            className="box3-1"
          >
            Das Üben der Handhabung ist{" "}
            <strong>einfacher für Ihren Kunden</strong>, und sorgt für ein{" "}
            <strong>Plus an Zufriedenheit</strong>
            <br />– und Ihnen <strong>spart es wertvolle Zeit.</strong>
          </Container>
        </div>

        <Container left={100} top={1240} width={1290} className="copy-2 color3">
          * Criteria for first fit success: rotation ≤ 20 degrees, lens
          stability ≤ 5 degrees movement on blink; acceptable general fitting
          characteristics, no need for power or axis adjustment.
        </Container>
      </div>
    </Slide>
  );
};
