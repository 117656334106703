import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';

const Slide = styled.div`
    .label1 {
        font-weight: 600;
        font-size: 15px;
        line-height: 12.7px;
        text-align: center;
        color: #fff;
    }

    .label2 {
        text-align: center;
        font-weight: 600;
        color: #a7a9ac;
    }

    .label3 {
        text-align: center;
        font-size: 11px;
        line-height: 12.7px;
        font-weight: 600;
    }

    .img2 {
        position: absolute;
        left: 838px;
        top: 503px;
        width: 24.5px;
        height: auto;
    }

    .label4 {
        font-size: 17.5px;
        line-height: 21px;
        font-weight: 600;
        color: #303030;
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Starker Werterhalt: <strong>Geringe Abwanderung von ACUVUE®<br />
                Trägern ins Internet</strong></h1>

            <Container as="p" left={100} top={271} width={1848} className="small">
                Horizontaler Anteil der Hersteller im <strong>Segment der weichen Tageslinsen</strong> (SOFT Daily)* in Bezug<br />
                auf den Fachhandel und Internet.
            </Container>

            <Container left={240} top={394} width={370} height={850} className="bg1" />
            <Container left={754} top={394} width={370} height={850} className="bg1" />
            <Container left={1233} top={394} width={370} height={850} className="bg1" />

            <Container as="p" left={240} top={412} width={370} height={850} className="label2 small">Total<sup>2</sup></Container>
            <Container as="p" left={754} top={412} width={370} height={850} className="label2 small">Fachhandel<sup>2</sup></Container>
            <Container as="p" left={1233} top={412} width={370} height={850} className="label2 small">Online<sup>2</sup></Container>

            <Container as="p" left={284} top={470} width={123} className="label3">Jan.–Dez.<br />2020</Container>
            <Container as="p" left={441} top={470} width={123} className="label3">Jan.–Dez.<br />2021</Container>

            <Container as="p" left={798} top={470} width={123} className="label3">Jan.–Dez.<br />2020</Container>
            <Container as="p" left={955} top={470} width={123} className="label3">Jan.–Dez.<br />2021</Container>

            <Container as="p" left={1277} top={470} width={123} className="label3">Jan.–Dez.<br />2020</Container>
            <Container as="p" left={1434} top={470} width={123} className="label3">Jan.–Dez.<br />2021</Container>

            <Image src={img1}  left={284} top={529} width={1273} height={690} />

            <Container as="p" left={284} top={611} width={123} className="label1">26,4%</Container>
            <Container as="p" left={284} top={838} width={123} className="label1">49,9%</Container>
            <Container as="p" left={284} top={1140} width={123} className="label1">23,7%</Container>

            <Container as="p" left={441} top={611} width={123} className="label1">27,7%</Container>
            <Container as="p" left={441} top={838} width={123} className="label1">47,1%</Container>
            <Container as="p" left={441} top={1140} width={123} className="label1">25,2%</Container>


            <Container as="p" left={798} top={611} width={123} className="label1">26,4%</Container>
            <Container as="p" left={798} top={838} width={123} className="label1">39,5%</Container>
            <Container as="p" left={798} top={1140} width={123} className="label1">34,1%</Container>

            <Container as="p" left={955} top={611} width={123} className="label1">26,7%</Container>
            <Container as="p" left={955} top={838} width={123} className="label1">35,9%</Container>
            <Container as="p" left={955} top={1140} width={123} className="label1">37,4%</Container>


            <Container as="p" left={1277} top={611} width={123} className="label1">26,5%</Container>
            <Container as="p" left={1277} top={838} width={123} className="label1">57,7%</Container>
            <Container as="p" left={1277} top={1140} width={123} className="label1">15,8%</Container>

            <Container as="p" left={1434} top={611} width={123} className="label1">28,3%</Container>
            <Container as="p" left={1434} top={838} width={123} className="label1">54,8%</Container>
            <Container as="p" left={1434} top={1140} width={123} className="label1">16,9%</Container>

            <img src={img2} className="img2" />

            <Container as="p" left={1742} top={1010} width={200} className="label4">JJV</Container>
            <Container as="p" left={1742} top={1089} width={200} className="label4">Alcon</Container>
            <Container as="p" left={1742} top={1168} width={200} className="label4">Andere</Container>

        </Slide>
    );
};
