import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow2, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.jpg';

const Slide = styled.div`
    .img1 {
        position: absolute;
        left: 28px;
        top: 112px;
        width: 224px;
    }

    .img2 {
        position: absolute;
        width: 785px;
        left: 215px;
        top: 210px;
    }

    .img3 {
        position: absolute;
        width: 142.5px;
        left: 275px;
        top: 206px;
    }

    .img4 {
        position: absolute;
        width: 737px;
        left: 255px;
        top: 206px;
    }

    .box1 {
        padding-left: 27px;
    }

    .step1,
    .step2 {
        opacity: 0;
    }

    .step1 {
        ${({ step }) => step === 1 && css`
            opacity: 1;
        `}
    }

    .step2 {
        ${({ step }) => step === 2 && css`
            opacity: 1;
        `}
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Technische Bindung,</strong> die nicht funktioniert …</h1>
            <img src={img1} className="img1" />
            <div className="step1">
                <img src={img2} className="img2" />
                <Arrow2 left={386} top={393} />
                <img src={img3} className="img3 fadeIn" />
            </div>
            <div className="step2">
                <img src={img4} className="img4" />
                <Container left={159} top={630} width={448} height={222} className="bg2 box1 flex-all-center color1">
                <p><strong>Zwei KLICKS zum Markenprodukt</strong></p>
                </Container> 
                <Arrow left={156} top={663} width={158} />
            </div>
        </Slide>
    );
};
