import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow2, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';

const Slide = styled.div`
    .box1 .count {
        font-size: 62px;
        line-height: 77.5px;

        span {
            font-size: 33px;
        }
    }

    .box2 {
        padding-left: 246px;
    }

    .box3 {
        padding-top: 3px;
    }

    .box4 {
        background: #fff;
    }
`
const inititialState = {
    overlay1: false
}


export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Das Potenzial durch Fokussierung <strong>auf<br />
            Kompetenz-Orientierte</strong></h1>
            <Container left={323} top={290} width={1392} height={119} className="rounded rounded-full text-center flex-all-center color1">
                <p><strong>19,6 Millionen Menschen</strong> ziehen Kontaktlinsen in Erwägung.<sup>3</sup></p>
            </Container>
            <div className="container-animate fadeInFromTop">
            <Arrow left={1006} top={-222} width={1392} className="rotate90" />

            <Container left={323} top={511} width={1392} height={119} className="rounded rounded-full text-center flex-all-center color1">
                <p><strong>6,3 Millionen Menschen sind AKTIV</strong> an Kontaktlinsen interessiert.<sup>3</sup></p>
            </Container>
            </div>

            <div className="container-animate fadeInFromTop animate2">
            <Arrow left={1006} top={-2} width={1392} className="rotate90" />

            <Container left={840} top={719} width={369} height={165} className="rounded box1 text-center box3">
                <span className="count">56<span>%</span></span>
            </Container>
            </div>
            <div className="container-animate fadeIn animate3">
            <Container left={323} top={897} width={1392} height={304} className="bg1 flex-all-center color1 box2">
                <Arrow width={1292} className="container-top" />
                <p><strong>potenzielle<br /> 
Kontaktlinsenträger sind<br /> 
KOMPETENZ/GESUNDHEIT<br /> 
motiviert.</strong></p>
            </Container>

            <Container left={411} top={965} width={380} height={165} className="rounded box1 text-center box3">
                <span className="count">3,5<span>Mio.</span></span>
            </Container>

            <Image left={1519} top={884} width={324} height={324} src={img1} className="rounded" />

            <Arrow2 left={1401} top={974} />

            <Container left={1419} top={1172} width={520} height={53} className="box4 text-center flex-all-center color1">
                <p><strong>Kontaktlinsenspezialist </strong></p>
            </Container> 
            </div>
        </Slide>
    );
};
