import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'
import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'
import Slide9 from './slides/slide9'
import Slide10 from './slides/slide10'
import Slide11 from './slides/slide11'
import Slide12 from './slides/slide12'
import Slide13 from './slides/slide13'
import Slide14 from './slides/slide14'
import Slide15 from './slides/slide15'
import Slide16 from './slides/slide16'

import Slide17 from './slides/slide17'
import Slide18 from './slides/slide18'
import Slide19 from './slides/slide19'
import Slide20 from './slides/slide20'
import Slide21 from './slides/slide21'

import Slide22 from './slides/slide22'

const Theme = {
    color1: '#00a3e0',
    bg1: '#CFF2FB',
    bg2: '#00a3e0',
}


export const Slides = {
    'Folder3Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder3Slide2': { name: Slide2, theme: Theme },
    'Folder3Slide3': { name: Slide3, theme: Theme },
    'Folder3Slide4': { name: Slide4, theme: Theme, steps: 2 },
    'Folder3Slide5': { name: Slide5, theme: Theme },
    'Folder3Slide6': { name: Slide6, theme: Theme },
    'Folder3Slide7': { name: Slide7, theme: Theme },
    'Folder3Slide8': { name: Slide8, theme: Theme },
    'Folder3Slide9': { name: Slide9, theme: Theme },
    'Folder3Slide10': { name: Slide10, theme: Theme, steps: 2 },
    'Folder3Slide11': { name: Slide11, theme: Theme },
    'Folder3Slide12': { name: Slide12, theme: Theme, steps: 2 },
    'Folder3Slide13': { name: Slide13, theme: Theme, steps: 2 },
    'Folder3Slide14': { name: Slide14, theme: Theme },
    'Folder3Slide15': { name: Slide15, theme: Theme },
    'Folder3Slide16': { name: Slide16, theme: Theme },

    'Folder3Slide17': { name: Slide17, theme: Theme },
    'Folder3Slide18': { name: Slide18, theme: Theme },
    'Folder3Slide19': { name: Slide19, theme: Theme, steps: 3  },
    'Folder3Slide20': { name: Slide20, theme: Theme },
    'Folder3Slide21': { name: Slide21, theme: Theme },

    'Folder3Slide22': { name: Slide22, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder3Slide1' },

    { key: 16, component: 'Folder3Slide17' },
    { key: 17, component: 'Folder3Slide18' },
    { key: 18, component: 'Folder3Slide19' },
    { key: 19, component: 'Folder3Slide20' },
    { key: 20, component: 'Folder3Slide21' },

    { key: 1, component: 'Folder3Slide2' },
    { key: 2, component: 'Folder3Slide3' },
    { key: 3, component: 'Folder3Slide4' },
    { key: 4, component: 'Folder3Slide5' },
    { key: 5, component: 'Folder3Slide6' },
    { key: 6, component: 'Folder3Slide7' },
    
    { key: 21, component: 'Folder3Slide22' },

    { key: 11, component: 'Folder3Slide12' },

    { key: 12, component: 'Folder3Slide13' },
    { key: 13, component: 'Folder3Slide14' },
    { key: 14, component: 'Folder3Slide15' },
    { key: 15, component: 'Folder3Slide16' },
]