export const authenticationConstants = {
    LOGIN_REQUEST: 'AUTHENTICATION_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTHENTICATION_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTHENTICATION_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'AUTHENTICATION_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTHENTICATION_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTHENTICATION_LOGOUT_FAILURE',

    VALIDATELOGIN_REQUEST: 'AUTHENTICATION_VALIDATELOGIN_REQUEST',
    VALIDATELOGIN_SUCCESS: 'AUTHENTICATION_VALIDATELOGIN_SUCCESS',
    VALIDATELOGIN_FAILURE: 'AUTHENTICATION_VALIDATELOGIN_FAILURE',

    REQUESTPASSWORD_REQUEST: 'AUTHENTICATION_REQUESTPASSWORD_REQUEST',
    REQUESTPASSWORD_SUCCESS: 'AUTHENTICATION_REQUESTPASSWORD_SUCCESS',
    REQUESTPASSWORD_FAILURE: 'AUTHENTICATION_REQUESTPASSWORD_FAILURE',

    CHANGEPASSWORD_REQUEST: 'AUTHENTICATION_CHANGEPASSWORD_REQUEST',
    CHANGEPASSWORD_SUCCESS: 'AUTHENTICATION_CHANGEPASSWORD_SUCCESS',
    CHANGEPASSWORD_FAILURE: 'AUTHENTICATION_CHANGEPASSWORD_FAILURE',

    GETUSERS_REQUEST: 'AUTHENTICATION_GETUSERS_REQUEST',
    GETUSERS_SUCCESS: 'AUTHENTICATION_GETUSERS_SUCCESS',
    GETUSERS_FAILURE: 'AUTHENTICATION_GETUSERS_FAILURE',

    SAVEUSER_REQUEST: 'AUTHENTICATION_SAVEUSER_REQUEST',
    SAVEUSER_SUCCESS: 'AUTHENTICATION_SAVEUSER_SUCCESS',
    SAVEUSER_FAILURE: 'AUTHENTICATION_SAVEUSER_FAILURE',

    DELETEUSER_REQUEST: 'AUTHENTICATION_DELETEUSER_REQUEST',
    DELETEUSER_SUCCESS: 'AUTHENTICATION_DELETEUSER_SUCCESS',
    DELETEUSER_FAILURE: 'AUTHENTICATION_DELETEUSER_FAILURE',

    RESETSTATE_REQUEST: 'AUTHENTICATION_RESETSTATE_REQUEST',
    RESETSTATE_SUCCESS: 'AUTHENTICATION_RESETSTATE_SUCCESS',
    RESETSTATE_FAILURE: 'AUTHENTICATION_RESETSTATE_FAILURE',
};
