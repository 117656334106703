import React from 'react';
import styled, { css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

const Button = styled.svg`
    position: absolute;
    z-index: 200;
	width: 33.5px;
	height: 33.5px;
	cursor: pointer;

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}
`

const NextBtn = (props) => {
    const onClick = (event) => {
        event.stopPropagation();
        props.onClick();
    }

    const color = props.color ? props.color : '#003087'

    return (
        <Button viewBox="0 0 67 67" left={props.left} top={props.top} onClick={onClick} style={props.style}>

            <polyline points="28.42 19.14 42.78 33.5 28.42 47.86" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3" />

            <circle cx="33.5" cy="33.5" r="32" fill="none" stroke="#fff" stroke-width="3" />

        </Button>
    );
};

export default NextBtn;