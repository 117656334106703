import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, CloseBtn, NextBtn } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import li from '../slide5/assets/li.svg';
import img6 from './assets/img6.svg';
import img7 from './assets/img7.svg';

import img8 from './assets/img8.jpg';
import img9 from './assets/img9.png';
import img10 from './assets/img10.png';

import img11 from './assets/img11.svg';
import img12 from './assets/img12.png';
import img13 from './assets/img13.png';
import img14 from './assets/img14.png';

import img15 from '../slide9/assets/img2.png';

const Slide = styled.div`
    .box1 {
        padding-top: 8px;
        background: #B69ACA !important;
        color: #fff;
    }

    .box2 .number {
        position: absolute;
        display: block;
        width: 70px;
        text-align: right;
        left: -74px;
        top: -18px;
        font-size: 65px;
        line-height: 65px;
        color: #390075;
        font-style: italic;
        text-align: right;
    } 

    .img1 {
        position: absolute;
        left: 312px;
        top: 198px;
        width: 400px;
    }

    .box3 ul {
        li {
            float: left;
            font-weight: 600;
            padding-left: 31px;
            background: url(${li});
            background-repeat: no-repeat;
            background-size: 31px 25px;
            background-position: 0px 0px;
            line-height: 30px;

            + li {
                margin-left: 22px;
            }
        }
    }

    .button {
        position: absolute;
        display: block;
        left: 313px;
        top: 505px;
        width: 465px;
        height: 62px;
        border: 1.1px solid #390075;
        border-radius: 15px;

        > span {
            position: absolute;
            display: flex;
            left: 4px;
            right: 4px;
            top: 4px;
            bottom: 4px;
            background: #390075;
            text-align: center;
            justify-content: center;
            color: #fff;
            font-weight: 600;
            border-radius: 11px;
            padding-top: 2px;

        }
    }

    .box4 {
        overflow: hidden;

        > .slides {
            &,
            .slide {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            & {
                transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            }

            .slide > div {

                position: absolute;
                left: -50px;
                right: -50px;
                bottom: -50px;
                top: -50px;
    
            }

            > .slide + div {
                left: 100%;
            }
        }

        h3 {
            font-size: 30px;
            line-height: 37.5px;
            color: #fff;

            strong {
                font-weight: 600;
            }
        }

        .img6 {
            position: absolute;
            left: 164px;
            top: 250px;
            width: 679px;
        }

        .img7 {
            position: absolute;
            left: 113px;
            top: 205.5px;
            width: 798px;
        }

        .white {
            color: #fff;
        }

        .circle {
            font-size: 14px;
            line-height: 17px;
        }

        .circle-tall {
            font-size: 10.6px;
            line-height: 12.7px;

            strong {
                font-size: 25px;
                line-height: 30px;
                font-weight: 600;
            }
        }
    }
   
`
const inititialState = {
    overlay1: null,
    overlay1Slide: 0
}

export default ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Ein umfassendes ACUVUE® Portfolio – <strong>für die individuellen<br />
            Bedürfnisse Ihrer Kunden</strong></h1>

            <Container left={100} top={364} width={1848} height={794} className="bg1">
                <Arrow width={1766} className="container-top" offset={680} />
                <Arrow width={1766} className="container-bottom" bgColor={'#E4DAEB'} />
            </Container>

            <Container as="p" left={222} top={294} width={686} height={406} className="box2 color1">
                <span className="number">1.</span>
                <strong>Kontaktlinsen Portfolio</strong>
            </Container>

            <img src={img1} className="img1" />

            <Container as="p" left={100} top={487} width={1848} className="small text-center">
                Der weltweit führende Anbieter von Kontaktlinsen mit innovativen Designs<br />
nach dem Vorbild der natürlichen Augenfunktion.
            </Container>

            <Container left={136} top={586} width={448} height={406} className="bg2 box1 text-center">
                <p><strong>Sphärisch</strong></p>
            </Container>

            <Container left={617} top={586} width={448} height={406} className="bg2 box1 text-center">
                <p><strong>Torisch</strong></p>
            </Container>

            <Container left={1100} top={586} width={448} height={406} className="bg2 box1 text-center">
                <p><strong>Multifokal</strong></p>
            </Container>

            <Container left={1582} top={586} width={330} height={406} className="bg2 box1 text-center">
                <p><strong>Pflegemittel</strong></p>
            </Container>

            <Container as="p" left={136} top={840} width={448} height={406} className="small text-center" style={{fontSize: '15px', lineHeight: '18px'}}>
            Die von Optikern <strong>am meisten getragene Kontaktlinsen- marke</strong> in Deutschland<sup>3</sup>
            </Container>

            <Container as="p" left={1100} top={798} width={448} height={406} className="small text-center">
                Die weltweit<br />
meistverkauften<br />
multifokalen<br />
Ein-Tages-Linsen<sup>5</sup>
            </Container>

            <Image left={196} top={664} width={321} height={161} src={img2} />
            <Image left={642} top={706} width={396} height={199} src={img3} />

            <Image left={1200} top={684} width={243} height={95} src={img4} />
            <Image left={1618} top={674} width={256} height={266} src={img5} />


            <MoreBtn
                left={966} top={601}
                onClick={() => toggleOverlay('overlay1')}
            />

            <span className="button">
                <span>ACUVUE® Astigmatismus- und<br />
Multifokal-Rechner</span>
            </span>

            <Container left={193} top={1190} width={1600} className="color1 box3">
                <ul>
                    <li>Home Delivery Service</li>
                    <li>Anpasslinsen</li>
                    <li>Geld-Zurück-Garantie</li>
                </ul>
            </Container>

            <Image left={1682} top={1180} width={175} height={58} src={img15} />

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg bg-white">
                <Container left={100} top={100} width={1848} height={1120} className="bg2 box4">
                    <div className="slides" style={{ transform: `translateX(-${state.overlay1Slide * 100}%)` }}>
                        <div className="slide slide1">
                            <div>
                                <Container as="h3" left={172} top={194}>
                                    <strong>Das ACUVUE® EYE-INSPIRED™ Design<br />
für Astigmatismus:</strong> im gesamten Sortiment verfügbar
                                </Container>
                                <img src={img6} className="img6" />

                                <Image left={170} top={378} width={326} height={326} src={img8} className="border rounded" />

                                <Image left={497} top={590} width={642} height={287} src={img9} />
                                <Image left={1060} top={946} width={448} height={147} src={img10} />

                                <Image left={1564} top={840} width={323} height={323} src={img11} />

                                <Container as="p" left={1564} top={840} width={323} height={323} className="flex-all-center text-center color1 circle-tall">
                                Mit nur<br />
                                <strong>2 Klicks</strong>
                                die passenden<br />
Probelinsen finden.
                                </Container>

                            </div>
                        </div>
                        <div className="slide slide2">
                            <div>
                                <Container as="h3" left={172} top={161}>
                                    Mit dem <strong>ACUVUE® Astigmatismus-Rechner<br />
mit nur 2 Klicks zum Anpasserfolg</strong>
                                </Container>
                                <img src={img7} className="img7" />

                                <Container as="p" left={231} top={352} className="white small">
                                    Der ACUVUE® Astigmatismus-Rechner<br />
findet die passende torische Probelinse<br />
für Ihre Kunden.
                                </Container>
                                <Container as="p" left={1256} top={605} className="white small">
                                    Geben Sie die Refraktionswerte<br />
                                Ihrer Kunden ein.
                                </Container>
                                <Container as="p" left={310} top={991} className="white small">
                                    Sie erhalten eine<br />
Übersicht aller geeigneten<br />
ACUVUE® for ASTIGMATISM<br />
Kontaktlinsen.
                                </Container>
                                <Container as="p" left={1038} top={1047} className="white small">
                                    Sie erhalten eine<br />
Probelinsenempfehlung<br />
für Ihre Kunden.
                                </Container>

                                <Image left={875} top={457} width={355} height={231} src={img12} />
                                <Image left={272} top={742} width={355} height={231} src={img13} />
                                <Image left={1000} top={793} width={355} height={231} src={img14} />

                                <Image left={670} top={494} width={159} height={159} src={img11} />
                                <Image left={670} top={831} width={159} height={159} src={img11} />

                                <Image left={1564} top={750} width={323} height={323} src={img11} />

                                <Container as="p" left={670} top={494} width={159} height={159} className="flex-all-center text-center color1 circle">
                                    <strong>1. Klick</strong>
                                </Container>
                                <Container as="p" left={670} top={831} width={159} height={159} className="flex-all-center text-center color1 circle">
                                <strong>2. Klick</strong>
                                </Container>
                                <Container as="p" left={1564} top={750} width={323} height={323} className="flex-all-center text-center color1 circle-tall">
                                Mit nur<br />
                                <strong>2 Klicks</strong>
                                die passenden<br />
Probelinsen finden.
                                </Container>

                            </div>
                        </div>
                    </div>
                </Container>

                <NextBtn
                    left={1736} top={127}
                    onClick={() => updateState('overlay1Slide', state.overlay1Slide === 0 ? 1 : 0)}
                    color={'#FFFFFF'}
                    style={{transform: `rotate(${state.overlay1Slide === 1 ? 180 : 0}deg)`}}
                />

                <CloseBtn
                    left={1855} top={127}
                    onClick={() => toggleOverlay('overlay1')}
                    color={'#FFFFFF'}
                />
            </Overlay>

        </Slide>
    );
};
