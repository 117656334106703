import React, { useState } from 'react'
import styled from 'styled-components'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';

const Slide = styled.div`
    .box1 {
        padding-top: 23px;
    }
}
`

const Folder2Slide5 = () => {
    return (
        <Slide className="centered">
            <div>
                <h1>Tageslinse: <strong>höchste Kundenzufriedenheit</strong></h1>
                <Container width={420} height={114} left={190} top={276} className="rounded flex-all-center color1 text-center">
                    <p><strong>Kein</strong> Aufwand</p>
                </Container>
                <Container width={420} height={114} left={814} top={276} className="rounded flex-all-center color1 text-center">
                    <p><strong>Gesundheit &amp;<br />
Hygiene</strong></p>
                </Container>
                <Container width={420} height={114} left={1436} top={276} className="rounded flex-all-center color1 text-center">
                    <p><strong>Flexibilität</strong></p>
                </Container>

                <Container left={100} top={432} width={600} height={561} className="bg1 color1 text-center box1 fadeInFromTop">

                    <Arrow width={424} className="container-top" />
                    <div className="text">
                        <p>Morgens eine frische<br />
                    Kontaktlinse aufsetzen<br />
                    und Abends einfach<br />
                    wegwerfen.
                    </p>
                        <p><strong>Keine Pflege,<br />
                    keine Reinigung,<br />
                    kein zusätzlicher<br />
                    Aufwand.
                    </strong></p>
                    </div>
                    
                </Container>

                <Container left={724} top={432} width={600} height={561} className="bg1 color1 text-center box1 fadeInFromTop animate2">

                    <Arrow width={424} className="container-top" />
                    <div className="text">
                        <p>Die Kontaktlinse kommt<br />
                    jeden Morgen frisch aus<br />
                    dem verschlossenen<br />
Blister.</p>
                        <p><strong>Keine<br />
Verunreinigung oder<br />
Infektionsquellen auf<br />
der Kontaktlinse.</strong></p>
                    </div>
                    
                </Container>

                <Container left={1348} top={432} width={600} height={561} className="bg1 color1 text-center box1 fadeInFromTop animate3">

                    <Arrow width={424} className="container-top" />
                    <div className="text">
                        <p>Monats- und Wochen-<br />
linsen müssen 30 bzw.<br />
14 Tage nach dem<br />
Öffnen entsorgt werden.<br />
Egal wie oft diese<br />
getragen wurde. </p>
                        <p><strong>Der Träger zahlt nur für<br />
                    die Kontaktlinse, wenn<br />
                    er sie auch wirklich trägt.</strong></p>
                    </div>
                    
                </Container>

                <Image left={259} top={974} width={275} height={275} src={img1} className="border rounded fadeIn animate2" />
                <Image left={889} top={974} width={275} height={275} src={img2} className="border rounded fadeIn animate3" />
                <Image left={1504} top={974} width={275} height={275} src={img3} className="border rounded fadeIn animate4" />
            </div>
        </Slide>
    );
};

export default Folder2Slide5;