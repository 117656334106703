import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import table1 from './assets/table1.svg';
import table1_2 from './assets/table1_2.svg';
import table2 from './assets/table2.svg';

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';


const Slide = styled.div`

    .img1 {
        background-size: contain;
    }

    .box1 {
        font-size: 18px;
        line-height: 25px;
        padding-top: 14px;
    }

    .overlay1 { 
        z-index: 300;

        > .bg1 {
            background: #a6d9ed;
        }

        .table1 { 
            font-family: "AvenirNext";
            font-weight: 700;
            font-size: 12px;
            line-height: 14.5px;
            color: #fff;


            > .bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 381px;

                &.small {
                    height: 313px;;
                }
            }

            table {
                position: relative;
            }

            thead {
                tr {
                    td {
                        height: 106px;
                        vertical-align: top;
                        padding-top: 40px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        height: 32.5px;
                    }
                }
            }

            tr {
                td {
                    text-align: center;

                    &:nth-child(1) {
                        width: 70px;
                    }
                    &:nth-child(2) {
                        width: 155px;
                    }
                    &:nth-child(3) {
                        width: 136px;
                    }
                    &:nth-child(4) {
                        width: 124px;
                    }
                    &:nth-child(5) {
                        width: 124px;
                    }

                    &:nth-child(6) {
                        width: 124px;
                    }

                    &:nth-child(7) {
                        width: 134px;
                    }

                    &.large {
                        position: relative;
                        color: #9bd3ec;

                        > span {
                            position: absolute;
                            left: 0;
                            width: 100%;
                            top: -5px;
                            left: 0;
                            font-size: 45px;
                            line-height: 45px;

                            > span {
                                position: absolute;
                                left: 0;
                                top: -20px;
                                width: 100%;
                                font-size: 9px;
                                line-height: 16px;
                            }
                        }
                    }
                }
            }
        }

        .table2 { 
            font-family: "AvenirNext";
            font-weight: 700;
            font-size: 12px;
            line-height: 14.5px;
            color: #fff;

            > .bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 173px;
            }

            table {
                position: relative;
                font-size: 32.5px;
                line-height: 40px;

                tr {
                    td {
                        height: 56px;
                        width: 290px;
                        text-align: center;
                    }
                }
            }
        }
    }
    
`
const inititialState = {
    overlay1: null,
    overlay2: null
}

export default ({ ...props }) => {
    const { state, updateState, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Konditionenmodell</strong> für 2024</h1>

            <Image left={524} top={334} width={396} height={395} src={img1} className="img1" />
            <Image left={1125} top={334} width={396} height={395} src={img2} className="img1" />

            <Container left={500} top={704} width={444} height={132} className="bg1 box1">
                <p className="color1 text-center"><strong>Deutschland</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'} />
            </Container>

            <Container left={1104} top={704} width={444} height={132} className="bg1 box1">
                <p className="color1 text-center"><strong>Österreich</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay2')}
                    active={state.overlay1 === 'overlay2'} />
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg">
                <Container left={113} top={240} width={1834} height={940} className="bg1">
                </Container>

                <Container left={145} top={316} width={1757} height={761} className="table1">
                    <img src={table1} className="bg" />
                    <table>
                        <thead>
                            <tr>
                                <td>Level</td>
                                <td>Jahresumsatz<br />
                                    (Euro)</td>
                                <td>Umsatzrabatt</td>
                                <td>Anpassrabatt</td>
                                <td>Partnerrabatt</td>
                                <td>Wachstumsrabatt</td>
                                <td>Maximaler Rabatt<br />
                                    je Stufe </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>2.500 bis &lt; 6.000</td>
                                <td>3,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>22,00%</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>6.001 bis &lt; 10.000</td>
                                <td>5,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>24,00%</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>10.001 bis &lt; 15.000</td>
                                <td>7,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>26,00%</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>15.001 bis &lt; 20.000</td>
                                <td>8,0%</td>
                                <td className="large color3"><span>7,5%</span></td>
                                <td className="large color3"><span>5,0%</span></td>
                                <td className="large color3"><span><span>bis zu</span>6,5%</span></td>
                                <td>27,00%</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>20.001 bis &lt; 30.000</td>
                                <td>9,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>28,00%</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>30.001 bis &lt; 50.000</td>
                                <td>10,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>29,00%</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>50.001 bis &lt; 100.000</td>
                                <td>10,5%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>29,50%</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>ab 100.001</td>
                                <td>11,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>30,00%</td>
                            </tr>
                        </tbody>
                    </table>
                </Container>
                <MoreBtn
                    left={1458} top={850}
                    onClick={() => updateState('overlay2', state.overlay2 !== 'overlay1-1' ? 'overlay1-1' : null)}
                    active={state.overlay2 === 'overlay1-1'} />

                <CloseBtn
                    left={1753} top={206}
                    bgColor={'#FFFFFF'}
                    onClick={() => {
                        toggleOverlay('overlay1')
                    }} />
            </Overlay>

            <Overlay active={state.overlay2 === 'overlay1-1'} className="overlay1 with-bg">
                <Container left={352} top={386} width={1343} height={669} className="bg1">
                </Container>

                <Container left={450} top={548} width={1160} height={347} className="table2">
                    <img src={table2} className="bg" />
                    <table>
                        <thead>
                            <tr>
                                <td>Wachstum</td>
                                <td>Rabatt</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ab 8,0%</td>
                                <td>3,5%</td>
                            </tr>
                            <tr>
                                <td>ab 12,0%</td>
                                <td>6,5%</td>
                            </tr>
                        </tbody>
                    </table>
                </Container>

                <CloseBtn
                    left={1563} top={353}
                    bgColor={'#FFFFFF'}
                    onClick={() => updateState('overlay2', state.overlay2 !== 'overlay1-1' ? 'overlay1-1' : null)}
                />
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="overlay1 with-bg">
                <Container left={113} top={240} width={1834} height={940} className="bg1">
                </Container>

                <Container left={145} top={384} width={1757} height={626} className="table1">
                    <img src={table1_2} className="bg small" />
                    <table>
                        <thead>
                            <tr>
                                <td>Level</td>
                                <td>Jahresumsatz<br />
                                    (Euro)</td>
                                <td>Umsatzrabatt</td>
                                <td>Anpassrabatt</td>
                                <td>Partnerrabatt</td>
                                <td>Wachstumsrabatt</td>
                                <td>Maximaler Rabatt<br />
                                    je Stufe </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>2.000 bis &lt; 4.000</td>
                                <td>2,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>21,00%</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>4.001 bis &lt; 8.000</td>
                                <td>4,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>23,00%</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>8.001 bis &lt; 16.000</td>
                                <td>6,0%</td>
                                <td className="large color3"><span>7,5%</span></td>
                                <td className="large color3"><span>5,0%</span></td>
                                <td className="large color3"><span><span>bis zu</span>6,5%</span></td>
                                <td>25,00%</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>16.001 bis &lt; 35.000</td>
                                <td>8,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>27,00%</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>35.001 bis &lt; 70.000</td>
                                <td>10,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>29,00%</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>ab 70.000</td>
                                <td>11,0%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>30,00%</td>
                            </tr>

                        </tbody>
                    </table>
                </Container>
                <MoreBtn
                    left={1458} top={850}
                    onClick={() => updateState('overlay2', state.overlay2 !== 'overlay2-2' ? 'overlay2-2' : null)}
                    active={state.overlay2 === 'overlay1-2'} />

                <CloseBtn
                    left={1753} top={206}
                    bgColor={'#FFFFFF'}
                    onClick={() => {
                        toggleOverlay('overlay2')
                    }} />
            </Overlay>

            <Overlay active={state.overlay2 === 'overlay2-2'} className="overlay1 with-bg">
                <Container left={352} top={386} width={1343} height={669} className="bg1">
                </Container>

                <Container left={450} top={548} width={1160} height={347} className="table2">
                    <img src={table2} className="bg" />
                    <table>
                        <thead>
                            <tr>
                                <td>Wachstum</td>
                                <td>Rabatt</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>ab 8,0%</td>
                                <td>3,5%</td>
                            </tr>
                            <tr>
                                <td>ab 12,0%</td>
                                <td>6,5%</td>
                            </tr>
                        </tbody>
                    </table>
                </Container>

                <CloseBtn
                    left={1563} top={353}
                    bgColor={'#FFFFFF'}
                    onClick={() => updateState('overlay2', state.overlay2 !== 'overlay2-2' ? 'overlay2-2' : null)}
                />
            </Overlay>

        </Slide>
    );
};
