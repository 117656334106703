import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn,
  Overlay,
  CloseBtn,
} from "components/parts";

import img1 from "./assets/img1.svg";
import img2 from "./assets/img2.svg";
import img3 from "./assets/img3.svg";
import img4 from "./assets/img4.jpg";
import img5 from "./assets/img5.jpg";

import HeaderDots from "../../shared/HeaderDots";

const Slide = styled.div`
  background: #0a7cc1;
  inset: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "AvenirNext", sans-serif;

  h1 {
    margin-top: 26px;
    color: #fff !important;
  }

  .box1 {
    padding-left: 240px;
    border-radius: 14.5px;
  }

  .box1-1 {
    font-size: 16px;
    line-height: 20.5px;

    strong {
      font-size: 18px;
      line-height: 21.5px;
    }
  }

  .box1-2 {
    font-size: 12.5px;
    line-height: 15.5px;

    strong {
      font-size: 15px;
      line-height: 18px;
    }
  }

  .box1-3 {
    font-size: 13px;
    line-height: 16.5px;

    strong {
      display: block;
      font-size: 16px;
      line-height: 19px;
    }

    ul {
      list-style: disc;
      margin: 0;
      margin-top: 2px;
      margin-left: 22px;
    }

    li {
      position: relative;
    }
  }

  .box2 {
    font-size: 21.5px;
    line-height: 31.5px;
  }

  .copy-1 {
    font-size: 10px;
    line-height: 11.5px;
  }

  .copy-2 {
    font-size: 8px;
    line-height: 10px;
  }
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide step={props.step}>
      <div className="centered">
        <h1>
          ACUVUE® für Astigmatismus
          <br />
          <strong>Ein-Tages-Kontaktlinsen</strong>
        </h1>

        <HeaderDots top={268} />

        <div className="container-animate">
          <Container
            left={100}
            top={319}
            width={1848}
            height={291}
            className="box1 bg3 flex flex-all-center relative"
          />

          <Image
            src={img1}
            width={40}
            height={85}
            left={621}
            top={359}
            className="contain"
          />
          <Image
            src={img2}
            width={40}
            height={85}
            left={621}
            top={486}
            className="contain"
          />
          <Image
            src={img3}
            width={42}
            height={82}
            left={1258}
            top={361}
            className="contain"
          />

          <Container
            left={164}
            top={413}
            width={360}
            className="box1-1 text-center"
          >
            <strong>Gleiche Technologien:</strong>
          </Container>

          <Container left={695} top={359} width={474} className="box1-2">
            <strong>
              INTEGRIERTER BENETZUNGSWIRKSTOFF<sup>7</sup>
            </strong>
            <br />
            Für Feuchtigkeit
          </Container>

          <Container left={695} top={499} width={474} className="box1-2">
            <strong>
              UNSICHTBARER RAND<sup>7</sup>
            </strong>
            <br />
            Für Komfort
          </Container>

          <Container left={1330} top={359} width={591} className="box1-3">
            <strong>Lidschlag-Stabilisations-Design:</strong>
            <ul>
              <li>
                Vier Stabilisierungszonen<sup>1</sup>
              </li>
              <li>
                Prismenfreie optische Zone<sup>*1</sup>
              </li>
              <li>
                Minimale untere Lidkanteninteraktion<sup>‡1</sup>
              </li>
              <li>
                Horizontale und vertikale Symmetrie<sup>1</sup>
              </li>
            </ul>
          </Container>
        </div>

        <div className="container-animate">
          <Container
            left={130}
            top={670}
            width={830}
            className="box2 text-center"
          >
            <strong>
              ACUVUE® OASYS 1-Day
              <br />
              for <span className="text-uppercase">Astigmatism</span>
            </strong>
          </Container>

          <Image src={img4} width={702} height={266} left={189} top={835} />

          <Container
            left={331}
            top={1101}
            width={434}
            className="copy-1 text-center"
          >
            Produktabbilung nur zu Illustrationszwecken
          </Container>
        </div>

        <div className="container-animate">
          <Container
            left={1070}
            top={670}
            width={788}
            className="box2 text-center"
          >
            <strong>
              1-DAY ACUVUE® MOIST
              <br />
              for <span className="text-uppercase">Astigmatism</span>
            </strong>
          </Container>

          <Image src={img5} width={665} height={261} left={1127} top={837} />

          <Container
            left={1244}
            top={1101}
            width={434}
            className="copy-1 text-center"
          >
            Produktabbilung nur zu Illustrationszwecken
          </Container>
        </div>

        <Container left={100} top={1182} width={1780} className="copy-2 color3">
          ‡ unwanted rotation due to lateral movement of the lower lid during
          blinks. * Vertical heterophoria possibly caused by prism dissociation
          due to the presence of induced optical prism is a relevant factor for
          practitioners to consider when fitting toric contact lenses for
          monocular astigmats or those requiring a mix of toric soft contact
          lens designs e.g., mixed prescriptions.(1,2) Clinical studies have not
          been done to fully characterize the clinical effects of differences in
          base down prism among different contact lenses. 1. Jackson D et al.
          Vertical heterophoria and susceptibility to visually induced motion
          sickness. Strabismus. 2012;20(1):17-23. 2. Du Toit R et al. Tolerance
          to prism induced by readymade spectacles: setting and using a
          standard. OVS 2007;84(11):1053-9.
        </Container>
      </div>
    </Slide>
  );
};
