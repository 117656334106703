import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
    
    .box1 {
        padding-top: 30px;
        font-size: 28px;
        line-height: 31.5px;
    }
    
    .box1 .number {
        position: absolute;
        display: block;
        left: -5px;
        top: -78px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .overlay1 {
        padding-top: 28px;

        .text1 {
            margin-bottom: 10px;
            font-size: 30px;
            line-height: 37.5px;
        }

        .text1,
        .text2 {
            position: relative;
            z-index: 10;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Eine Kampagne mit zwei Teilen</strong> am Point of Sale<br />
                und in den sozialen Medien</h1>

            <Container left={183} top={452} width={763} height={141} className="box1 bg1 color1 text-center fadeInFromTop">
                <span className="number">1</span>
                <p className="tall"><strong>Kompetenz und Termin</strong></p>
                <Arrow left={362} top={-164} width={640} className="rotate90" bgColor={'#CFF2FB'} />
            </Container>

            <Container left={1077} top={452} width={763} height={141} className="box1 bg1 color1 text-center fadeInFromTop animate3">
                <span className="number">2</span>
                <p className="tall"><strong>Kontaktlinsen-Aktion</strong></p>
                <Arrow left={362} top={-164} width={640} className="rotate90" bgColor={'#CFF2FB'} />
            </Container>

            <Container as="p" left={163} top={716} width={803} className="color1 text-center fadeInFromTop animate2">
                Die Kontaktlinsenspezialisten<br />
                erhalten dafür neben <strong>Postern</strong>,<br />
                <strong>Fensterklebern</strong> auch eine <strong>Toolbox</strong><br />
                mit <strong>Onlinebanner</strong>, <strong>Social Media Posts</strong> und <strong>Storys</strong>, die sie über ihre eigenen Accounts ausspielen können.

            </Container>

            <Container as="p" left={1057} top={716} width={803} className="color1 text-center fadeInFromTop animate4">
                Die begleitende Cashback-Aktion<br />
                leitet Passanten oder Social Media<br />
                Publikum zum Kontaktlinsenspezialisten,<br />
                wo sie sich beraten und ACUVUE®
                Kontaktlinsen anpassen lassen können –<br />
                mit einem <strong>Cash-Back von 30 €</strong>.

            </Container>

            <MoreBtn
                left={523} top={1115}
                onClick={() => toggleOverlay('overlay1')}
                className="more1 fadeInFromTop animate2"
                active={state.overlay1 === 'overlay1'} />

            <MoreBtn
                left={1424} top={1115}
                onClick={() => toggleOverlay('overlay2')}
                className="more1 fadeInFromTop animate4"
                active={state.overlay1 === 'overlay2'} />

            <Overlay className="with-bg" active={state.overlay1 === 'overlay1'}>

                <Container left={183} top={286} width={1670} height={870} className="overlay1 bg2 color1 text-center">
                    <p className="text1"><strong>„MeinOptiker-Kampagne“</strong></p>
                    <p className="text2">Poster – Fensterkleber – Onlinebanner – Social Media Postings</p>
                </Container>

                <Image left={220} top={550} width={1593} height={557} src={img1} />

            </Overlay>

            <Overlay className="with-bg" active={state.overlay1 === 'overlay2'}>

                <Container left={183} top={286} width={1670} height={870} className="overlay1 bg2 color1 text-center">
                    <p className="text1"><strong>„Große Kontaktlinsen-Aktion“</strong></p>
                    <p className="text2">Poster – Fensterkleber – Gutschein – postal./digit. Mailings – Onlinebanner –<br /> 
Social Media Postings – Instore Video</p>
                </Container>

                <Image left={226} top={545} width={1593} height={557} src={img2} />

            </Overlay>

        </Slide>
    );
};
