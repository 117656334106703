import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow, MoreBtn, PlayBtn, Overlay, InfoBtn } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useSlideState } from 'components/hooks'

import bg from './assets/bg.jpg'
import overlay1Bg from './assets/overlay1-bg.jpg'
import overlay3Bg from './assets/overlay3-bg.jpg'
import overlay4Bg from './assets/overlay4-bg.jpg'
import overlay6Bg from './assets/overlay6-bg.jpg'
import overlay6_1_1Bg from './assets/overlay6-1-1-bg.jpg'

import img1_1 from './assets/img1_1.svg'
import img4_1 from './assets/img4_1.svg'

import img6_1 from './assets/img6_1.jpg'
import img6_2 from './assets/img6_2.svg'
import img6_3 from './assets/img6_3.svg'


import Dragger from './Dragger';

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 45px;
        text-align: center;
        color: #fff;
    }

    > .text1 {
        font-size: 25px;
        line-height: 26.5px;
        text-transform: uppercase;
    }

    .line {
        display: block;
        position: absolute;
        height: 1.2px;
        background: #fff;

    }

    > .line {
        &.line1 {
            left: 87px;
            top: 280px;
            width: 358px;
        }

        &.line2 {
            left: 650px;
            top: 280px;
            width: 278px;
        }

        &.line3 {
            left: 545px;
            top: 520px;
            width: 384px;
        }
    }

    > .text2 {
        font-size: 20px;
        line-height: 20px;
    }

    > .text3 {
        font-size: 16px;
        line-height: 22.5px;
    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

    .overlay1,
    .overlay1-1,
    .overlay2,
    .overlay3,
    .overlay4,
    .overlay4-1,
    .overlay5,
    .overlay6,
    .overlay6-1,
    .overlay6-1-1 {
        > .box1 {
            border: 1.5px solid #3a8dde;
            border-radius: 15px;
        }
    }

    .overlay1 {
        > .box1 {
            background: url(${overlay1Bg});
            background-size: cover;
        }

        > .text1 {
            line-height: 27.5px;
        }

        > .text3 {
            font-size: 12.5px;
            line-height: 16px;
        }

        > .line1 {
            left: 586px;
            top: 382px;
            width: 383px;
        }
    }

    .overlay1-1 {
        > .box1 {
            background: #fff;
        }

        > .text1 {
            font-size: 25px;
            line-height: 26.5px;
        }

        > .text2 {
            font-size: 25px;
            line-height: 30px;
            color: #1b365d;
        }

        > .text3 {
            font-size: 12.5px;
            line-height: 16px;
            color: #1b365d;

            h3 {
                margin-bottom: 19.5px;
                font-weight: 700;
                font-size: 17.5px;
                line-height: 19.5px;
            }

            ul {
                margin-top: 16px;
                padding-left: 16px;
            
                li {
                    position: relative;
                }

                li::before {
                    position: absolute;
                    left: -16px;
                    top: -2px;
                    content:"·";
                    vertical-align:middle;
                }

                li + li {
                    margin-top: 10px;
                }
            }
        }

        > .text4 {
            font-size: 9px;
            line-height: 11px;
            color: #1b365d;
        }

        > .img1_1 {
            position: absolute;
            left: 421px;
            top: 250px;
            width: 477px;
        }
    }

    .overlay2,
    .overlay5 {
        > .box1 {
            background: #13174a;
        }
    }

    .overlay3 {
        > .box1 {
            background: #E6E6E6;
            background-image: url(${overlay3Bg});
            background-size: cover;
        }

        > .text1 {
            font-size: 25px;
            line-height: 26.5px;
            text-transform: uppercase;
            color: #3a8dde;
        }

        > .text2 {
            color: #1b365d;
            
            p {
                line-height: 27.5px;
            }

            span {
                font-size: 15px;
                line-height: 20px;
            }
        }

        > .text3 {
            line-height: 27.5px;
            color: #1b365d;
        }

        > .text4 {
            font-size: 15px;
            line-height: 20px;
            color: #1b365d;
        }

        .text5 {
            font-size: 15px;
            line-height: 20px;
            color: #1b365d;
            transform: rotate(25deg);

            span {
                display: block;
                font-size: 10.5px;
                line-height: 12.5px;
                color: #000000;
            }
        }

        .text6 {
            font-size: 15px;
            line-height: 20px;
            color: #1b365d;
        }

        .text7 {
            font-size: 9px;
            line-height: 12.5px;
            color: #000000;
        }

        ${ArrowContainer} {
            span {
                background: #3a8dde;
            }
        }
    }

    .overlay4 {
        > .box1 {
            background: url(${overlay4Bg});
            background-size: cover;
        }

        > .text1 {
            line-height: 27.5px;
        }

        > .text2 {
            line-height: 27.5px;
        }

        > .line1 {
            left: 51px;
            top: 311px;
            width: 222px;
        }
    }

    .overlay4-1 {
        > .box1 {
            background: #fff;
        }

        > .text1 {
            font-size: 25px;
            line-height: 26.5px;
        }

        > .text2 {
            font-size: 25px;
            line-height: 30px;
            color: #1b365d;
        }

        > .text3 {
            font-size: 12.5px;
            line-height: 16px;
            color: #1b365d;

            h3 {
                margin-bottom: 19.5px;
                font-weight: 700;
                font-size: 17.5px;
                line-height: 19.5px;
            }

            ul {
                margin-top: 16px;
                padding-left: 16px;
            
                li {
                    position: relative;
                }

                li::before {
                    position: absolute;
                    left: -16px;
                    top: -2px;
                    content:"·";
                    vertical-align:middle;
                }

                li + li {
                    margin-top: 10px;
                }
            }
        }

        > .text4 {
            font-size: 15px;
            line-height: 18px;
        }

        > .img4_1 {
            position: absolute;
            left: 419px;
            top: 262px;
            width: 434px;
        }
    }

    .overlay2, 
    .overlay5 {
        video {
            position: absolute;
            left: 82px;
            top: 80px;
            width: 858px;
            height: 480px;
        }
    }

    .overlay6 {
        > .box1 {
            background: url(${overlay6Bg});
            background-size: cover;
        }

        > .text1 {
            line-height: 27.5px;
        }
    }

    .overlay6-1 {
        > .box1 {
            overflow: hidden;
            background: #fff;

            > .box2 {
                background: #CAE8F4;
            }
        }

        > .img1 {
            position: absolute;
            width: 606px;
            left: 104px;
            top: 216px;
        }

        > .text1 {
            font-size: 25px;
            line-height: 30px;
        }

        > .text2 {
            font-size:  11.5px;
            line-height: 14px;
            color: #0a7cc1;
        }

        > .text3 {
            font-size:  11.5px;
            line-height: 13px;
            color: #0a7cc1;

            .count {
                display: block;
                font-size: 36px;
                line-height: 43px;
                color: #0a7cc1;
            }
        }

        > .text4 {
            font-size:  10.5px;
            line-height: 12.5px;
            font-family: "Graphik", sans-serif;
            font-weight: 500;
            color: #000000;
        }

        > .text5 {
            font-size: 21px;
            line-height: 25px;
            color: #3a8dde;

            strong {
                color: #00306F;
            }
        }

        > .text6 {
            font-size: 10.5px;
            line-height: 12.5px;
            color: #000000;

            img {
                position: absolute;
                width: 23px;
                left: -28px;
                top: -5px;
            }
        }
    }

    .overlay6-1-1 {
        > .box1 {
            overflow: hidden;
            background: url(${overlay6_1_1Bg});
            background-size: cover;
        }
        
        > .text1 {
            font-size: 25px;
            line-height: 30px;
        }

        > .text2 {
            font-size: 15.5px;
            line-height: 18.5px;
            color: #000000;
        }

        > .text3 {
            font-size: 10.5px;
            line-height: 12.5px;
            color: #000000;
        }

        > .text4 {
            font-family: "Graphik", sans-serif;
            font-weight: 500;
            color: #000000;
            font-size: 7.5px;
            line-height: 8.5px;
        }

        > .text5 {
            font-size: 10.3px;
            line-height: 13.5px;

        }

        > .text6 {
            font-size: 10.5px;
            line-height: 12.5px;
            color: #000000;

            img {
                position: absolute;
                width: 23px;
                left: -28px;
                top: -5px;
            }
        }

        .noselect {
            -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
            }

        > .dragger {
            position: absolute;
            left: 280px;
            top: 499px;
            

            img {
                width: 38px;
            }
        }
    }

    
`
const inititialState = {
    overlay1: 'overlay3',
    overlay2: null,
    overlay3: null
}

const Folder2Slide1 = ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })

    const [dragX, setDragX] = useState(280);



    useEffect(() => {

        let video1 = document.getElementById("video");
        if (video1) {
            if (state.overlay1 === 'overlay5') {
                video1.play();
            } else {
                video1.pause();
                video1.currentTime = 0;
            }
        }

        let video2 = document.getElementById("video2");
        if (video2) {
            if (state.overlay1 === 'overlay2') {
                video2.play();
                
            } else {
                video2.pause();
                video2.currentTime = 0;
            }
        }


    }, [state.overlay1])


    return (
        <>
            <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">



                <h1>Der <strong>MAX Multifocal</strong> Unterschied</h1>

                <Container left={174} top={421} width={684} as="p" className="text1">
                    <strong>TEARSTABLE<sup>™</sup><br />
                        TECHNOLOGY</strong>
                </Container>

                <span className="line line1" />

                <MoreBtn
                    left={57} top={520}
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'}
                />

                <PlayBtn
                    left={57} top={639}
                    onClick={() => toggleOverlay('overlay2')}
                    active={state.overlay1 === 'overlay2'}
                />

                <MoreBtn
                    left={57} top={750}
                    onClick={() => toggleOverlay('overlay3')}
                    active={state.overlay1 === 'overlay3'}
                />

                <Container left={174} top={655} width={683} as="p" className="text2">
                    Mode of Action Video
                </Container>

                <Container left={174} top={772} width={683} as="p" className="text2">
                    Neuer Herstellungsprozess
                </Container>

                <Container left={1242} top={421} width={617} as="p" className="text1 text-right fadeIn">
                    <strong>OPTIBLUE<sup>™</sup><br />
                        LIGHT FILTER<sup>**</sup></strong>
                </Container>

                <Container left={1242} top={638} width={617} as="p" className="text2 text-right fadeIn">
                    OPTIBLUE<sup>™</sup> Light<br />
                    Filter Demonstration
                </Container>

                <Container left={1042} top={899} width={817} as="p" className="text1 text-right fadeIn animate2">
                    <strong>Pupillenoptimiertes<br />
                        Design</strong>
                </Container>

                <span className="line line3 fadeIn animate2" />

                <span className="line line2 fadeIn" />

                <MoreBtn
                    left={1899} top={520}
                    onClick={() => toggleOverlay('overlay4')}
                    active={state.overlay1 === 'overlay4'}
                    className="fadeIn"
                />

                <PlayBtn
                    left={1899} top={639}
                    onClick={() => toggleOverlay('overlay5')}
                    active={state.overlay1 === 'overlay5'}
                    className="fadeIn"
                />

                <MoreBtn
                    left={1899} top={996}
                    onClick={() => toggleOverlay('overlay6')}
                    active={state.overlay1 === 'overlay6'}
                    className="fadeIn  animate2"
                />

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />
                    <Container left={255} top={230} width={740} as="p" className="text1">
                        <strong>TEARSTABLE<sup>™</sup> TECHNOLOGY</strong><br />
                        Für <strong>hohen</strong> Tagesend-Komfort<sup>*8</sup>
                    </Container>

                    <Arrow left={562} top={78} width={648} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={255} top={435} width={740} as="p" className="text1">
                        <strong>Optimierte Verteilung des Benetzungswirkstoffs</strong> in der<br />
                        gesamten Linse und Oberfläche<sup>6-8</sup><br /><br />

                        <strong>Längere Tränenfilmstabilität</strong><sup>†6-8</sup><br /><br />

                        <strong>2x niedrigere Verdunstungsrate<br />
                            im Vergleich zu</strong> DAILIES Total1®,<br />
                        MyDay® und ULTRA® ONE DAY<sup>***6,8</sup>
                    </Container>

                    <Container left={255} top={998} width={740} as="p" className="text3">
                        Für weitere Fragen zur Studienmethodik wenden<br />
                        Sie sich bitte an service@acuvue.de/at.
                    </Container>

                    <span className="line line1" />

                    <Container left={1113} top={797} width={724} as="p" className="text1 text-right">
                        Unterstützt auch die optische<br />
                        Qualität des Tränenfilms<sup>7</sup> –<br />
                        dies ist besonders relevant für<br />
                        Menschen mit Presbyopie, da die<br />
                        Tränenfilmstabilität bis zum 50.<br />
                        Lebensjahr um 50 % abnimmt.<sup>1</sup>
                    </Container>

                    <InfoBtn
                        left={146} top={829}
                        onClick={() => updateState('overlay2', state.overlay2 !== 'overlay1-1' ? 'overlay1-1' : null)}
                        active={state.overlay2 === 'overlay1-1'}
                    />

                    <Overlay active={state.overlay2 === 'overlay1-1'} className="overlay1-1">

                        <Container left={187} top={140} width={1712} height={1000} className="box1" />

                        <Container left={407} top={186} width={1263} as="p" className="text1 text-center color2">
                            <strong>ACUVUE® OASYS MAX 1-Day</strong>
                        </Container>

                        <Container left={254} top={276} width={1570} as="p" className="text2 text-center">
                            Längere Tränenfilmstabilität durch niedrigere Verdunstungsrate<sup>^6-8</sup><br />
                            <strong className="color2">2x niedriger</strong> als bei DAILIES TOTAL1®, My Day® und<br />
                            ULTRA® ONE DAY<sup>^6,8</sup>
                        </Container>

                        <Container left={310} top={493} width={500} className="text3">
                            <h3><strong className="color2">TEARSTABLE<sup>™</sup><br />
                                TECHNOLOGY</strong></h3>
                            <p>Unser erstes und einziges Linsenmaterial, das auf Verlängerung der Tränenfilm-Stabilität ausgelegt ist<sup>8</sup></p>

                            <ul><li>Modernstes Herstellungsverfahren<sup>6</sup></li>
                                <li>Optimierte Verteilung des Benetzungs-wirkstoffs in der gesamten Linse und Oberfläche<sup>6-8</sup></li>
                                <li>1,6x höhere Wahrscheinlichkeit<br />
                                    für längere Tränenfilm-Aufrisszeit<sup>†8</sup></li></ul>
                        </Container>

                        <Container left={310} top={1066} width={1500} as="p" className="text4">
                            Adjustierte Mittelwerte der Pervaporationsrate bei 40 % relativer Luftfeuchtigkeit (RH) für ACUVUE® OASYS MAX 1-Day, DAILIES Total1® und ULTRA® ONE DAY.

                        </Container>

                        <img src={img1_1} className="img1_1" />

                    </Overlay>

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay2'} className="overlay2">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />

                    <video id="video2" width="863" height="499" controls>
                        <source src="/media/ACU_MAX_MOA_Family_Longform_VO_720.mp4" type="video/mp4" />
                    </video>
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay3'} className="overlay3">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />

                    <Container left={139} top={148} width={1800} as="p" className="text1 text-center">
                        <strong>Unser  fortschrittlichster Herstellungsprozess<br />
                            und -kontrolle</strong>
                    </Container>

                    <Container left={208} top={358} width={1100} className="text2">
                        <p>Neuer, optimierter Polymerizations-Prozess<br />
                            mit doppelseitig angeordneten LED<br /> <br />

                            Optimierte Benetzungswirkstoff (PVP)-<br />
                            Verteilung in der Linse einschließlich der<br />
                            Verteilung auf der Oberfläche<sup>1,2,3</sup></p>
                        <span>(PVP = Polyvinyl pyrrolidone, unser einzigartiger hoch-<br />
                            molekularer Benetzungswirkstoff  der membrangebundenen<br />
                            Mucinen nachempfunden ist<sup>4,5</sup>)</span>
                    </Container>

                    <Arrow left={624} top={460} width={863} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#3a8dde'} />

                    <Container left={208} top={920} width={1100} as="p" className="text3">
                        <strong>Erlaubt die Integration der TearStable™<br />
                            Technology und dem OptiBlue™ Light Filter über<br />
                            die gesamte ACUVUE® OASYS MAX 1-Day<br />
                            Produktfamilie.<sup>6</sup></strong>
                    </Container>

                    <Container left={1171} top={293} width={286} as="p" className="text4 text-center">Alter Prozess</Container>
                    <Container left={1544} top={293} width={228} as="p" className="text4 text-center">Neuer Prozess</Container>
                    <Container left={1373} top={435} width={228} as="p" className="text4 text-center"><strong>vs.</strong></Container>

                    <Container left={1040} top={668} width={627} height={231} as="p" className="text5 text-center">
                        Cure Light
                        <span>(Licht zur<br />
                            Polymerization)</span>
                    </Container>

                    <Container left={1346} top={1017} width={627} height={231} as="p" className="text5 text-center">
                        Cure Light
                        <span>(Licht zur<br />
                            Polymerization)</span>
                    </Container>

                    <Container left={1355} top={884} width={374} as="p" className="text6 text-center">
                        <strong>Lichtdämpfung</strong>
                    </Container>

                    <Container left={1078} top={1126} width={858} as="p" className="text7 text-center">
                        Nur zu Illustrationszwecken
                    </Container>
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay4'} className="overlay4">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />

                    <Container left={984} top={230} width={803} as="p" className="text1 text-right">
                        <strong> OPTIBLUE™ LIGHT FILTER<br />
                            Für klare Sehqualität</strong> bei<br />
                        allen Lichtverhältnissen <sup>±5</sup>
                    </Container>

                    <Arrow left={1526} top={234} width={487} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={984} top={523} width={803} as="p" className="text1 text-right">
                        <strong>60 % Filterung von Blau-<br />
                            Violett-Licht</strong> – der stärkste<br />
                        Filter auf dem Markt <sup>¶**6</sup><br /><br />

                        <strong>Reduziert Streulicht</strong><sup>#7</sup><br /><br />

                        <strong>Reduziert Lichtphänomene<br />
                            (Halos, Starbursts)</strong><sup>†3</sup>
                    </Container>

                    <Container left={177} top={663} width={490} as="p" className="text2">
                        Kann Sehqualität<br />
                        und Komfort<br />
                        verbessern, speziell<br />
                        bei Menschen mit<br />
                        Presbyopie, da die<br />
                        Lichtstreuung sich bis<br />
                        zum 60. Lebensjahr<br />
                        verdoppelt.<sup>2,9</sup>
                    </Container>

                    <span className="line line1"></span>

                    <InfoBtn
                        left={1808} top={509}
                        onClick={() => updateState('overlay2', state.overlay2 !== 'overlay4-1' ? 'overlay4-1' : null)}
                        active={state.overlay2 === 'overlay4-1'}
                    />

                    <Overlay active={state.overlay2 === 'overlay4-1'} className="overlay4-1">

                        <Container left={140} top={140} width={1712} height={1000} className="box1" />

                        <Container left={407} top={186} width={1263} as="p" className="text1 text-center color2">
                            <strong>ACUVUE® OASYS MAX 1-Day</strong>
                        </Container>

                        <Container left={333} top={276} width={1400} as="p" className="text2 text-center">
                            <strong className="color2">Der stärkste</strong> Blau-Violett-Filter auf dem Markt<sup>**6,8,10</sup>
                        </Container>

                        <Container left={243} top={445} width={500} className="text3">
                            <h3 className="color2">OPTIBLUE<sup>™</sup><br />
                                LIGHT FILTER</h3>
                            <p> Minimierung der Lichtstreuung zur<br />
                                Verbesserung der Sehqualität, der<br />
                                Fernsicht<br />
                                und des Sehkomforts.<sup>9</sup></p>

                            <ul>
                                <li>Blau-violettes Licht umgibt uns überall.<sup>9</sup></li>
                                <li>Das kurzwellige blau-violette Licht streut stärker und wird von digitalen Geräten, LEDs und insbesondere der Sonne ausgestrahlt.<sup>9</sup></li>
                                <li>Streulicht kann Blendung, Halos und
                                    verminderten Kontrast verursachen.<sup>9</sup></li>
                            </ul>
                        </Container>

                        <Container left={966} top={944} width={740} as="p" className="text4 color2 text-center">
                            <strong>% Filterung von Blau-Violett-Licht, 380–450 nm</strong>
                        </Container>

                        <img src={img4_1} className="img4_1" />


                    </Overlay>

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay5'} className="overlay5">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />

                    <video id="video" width="863" height="499" controls>
                        <source src="/media/PP2022MLT6563_ACUVUE_OASYS_MAX_1-Day_OptiBlue_Technology_Demo.mp4" type="video/mp4" />
                    </video>

                </Overlay>

                <Overlay active={state.overlay1 === 'overlay6'} className="overlay6">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />
                    <Container left={680} top={190} width={1108} as="p" className="text1 text-right">
                        <strong>PUPILLENOPTIMIERTES DESIGN</strong><br />
                        Für das Sehen in <strong>jeder Entfernung</strong><sup>11</sup>
                    </Container>

                    <Arrow left={1452} top={34} width={648} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={905} top={397} width={887} as="p" className="text1 text-right">
                        Nur ACUVUE® bietet die<br />
                        Optimierung von <strong>100 %</strong> der<br />
                        Parameter für variierende<br />
                        Pupillengrößen je nach Alter<br />
                        und Fehlsichtigkeit<sup>'11</sup><br /><br />

                        <strong>Dasselbe einzigartige<br />
                            optische Design</strong> bei allen<br />
                        ACUVUE® Multifocal<br />
                        Kontaktlinsen<sup>11</sup><br /><br />

                        <strong>92 %</strong> aller Träger passte <strong>das<br />
                            erste Paar beim ersten Termin</strong><sup>12</sup><br />
                    </Container>


                    <InfoBtn
                        left={1808} top={202}
                        onClick={() => updateState('overlay2', state.overlay2 !== 'overlay6-1' ? 'overlay6-1' : null)}
                        active={state.overlay2 === 'overlay6-1'}
                    />

                    <Overlay active={state.overlay2 === 'overlay6-1'} className="overlay6-1">

                        <Container left={137} top={140} width={1712} height={1000} className="box1">

                            <Container left={0} top={726} width={1712} height={314} className="box2" />


                        </Container>

                        <img src={img6_1} className="img1" />


                        <Container left={198} top={203} width={1590} as="p" className="text1 text-center color2">
                            Was ist das <strong>pupillenoptimierte Design?</strong>
                        </Container>

                        <Container left={230} top={359} width={148} as="p" className="text2 text-center">
                            Jünger/<br />
                            Myop
                        </Container>

                        <Container left={1258} top={359} width={148} as="p" className="text2 text-center">
                            Älter/<br />
                            Hyperop
                        </Container>

                        <Container left={1443} top={450} width={305} as="p" className="text3">
                            <span className="count">100 %</span>
                            ACUVUE® MULTIFOCAL<br />
                            183 Parameter optimiert<sup>'13</sup>
                        </Container>

                        <Container left={1443} top={639} width={405} as="p" className="text3">
                            <span className="count">&lt;2 %</span>
                            optimierte Parameter beim<br />
                            führenden Wettbewerber<sup>'13</sup>
                        </Container>

                        <Container left={207} top={820} width={1412} as="p" className="text4">
                            Iris-/Pupillen-Diagramme nur zu Illustrationszwecken
                        </Container>

                        <Container left={272} top={907} width={1424} as="p" className="text5 text-center">
                            Ausgelegt auf <strong>klare, scharfe, zuverlässige visuelle Leistung<sup>12</sup></strong><br />
                            im ganzen Spektrum der Fehlsichtigkeit und in jedem Alter
                        </Container>

                        <Container left={1303} top={1074} width={300} as="p" className="text6">
                            <img src={img6_2} />
                            Pupillenoptimiert
                        </Container>

                        <Container left={1563} top={1074} width={300} as="p" className="text6">
                            <img src={img6_3} />
                            Nicht pupillenoptimiert
                        </Container>

                        <InfoBtn
                            left={1746} top={672}
                            onClick={() => updateState('overlay3', state.overlay3 !== 'overlay6-1-1' ? 'overlay6-1-1' : null)}
                            active={state.overlay3 === 'overlay6-1-1'}
                        />

                        <Overlay active={state.overlay3 === 'overlay6-1-1'} className="overlay6-1-1">
                            <Container left={137} top={140} width={1650} height={1000} className="box1">
                            </Container>

                            <Container left={198} top={203} width={1590} as="p" className="text-center text1 color2">
                                <strong>Warum das pupillenoptimierte Design so wichtig ist</strong>
                            </Container>

                            <Container left={530} top={315} width={148} as="p" className="text2 text-center">
                                Ferne
                            </Container>

                            <Container left={838} top={315} width={300} as="p" className="text2 text-center">
                                Zwischenbereich
                            </Container>

                            <Container left={1319} top={315} width={90} as="p" className="text2 text-center">
                                Nähe
                            </Container>



                            <Container left={403} top={786} width={131} as="p" className="text3 text-center">
                                Jünger/<br />
                                Myop
                            </Container>


                            <Container left={1453} top={786} width={131} as="p" className="text3 text-center">
                                Älter/<br />
                                Hyperop
                            </Container>

                            <Container left={166} top={940} width={330} as="p" className="text4 noselect">
                                Iris-/Pupillen-Diagramme nur zu<br />
                                Illustrationszwecken. Variation der<br />
                                Pupillengröße durch Alter und Refraktion<br />
                                bedingt, nicht durch Lichtverhältnisse.<br />
                                Die Darstellung ist eine Simulation<br />
                                unterschiedlicher Seheindrücke,<br />
                                um die Vorteile der Anpassung des<br />
                                Optikprofils an die Pupillengrösse zu<br />
                                veranschaulichen.
                            </Container>

                            <Container left={654} top={940} width={150} as="p" className="text5 text-center">
                                <strong>Ergebnis</strong>
                            </Container>

                            <Container left={1254} top={1074} width={300} as="p" className="text6">
                                <img src={img6_2} />
                                Pupillenoptimiert
                            </Container>

                            <Container left={1513} top={1074} width={300} as="p" className="text6">
                                <img src={img6_3} />
                                Nicht pupillenoptimiert
                            </Container>

                            <Dragger />

                        </Overlay>

                    </Overlay>

                </Overlay>


            </Slide>
        </>

    );
};

export default Folder2Slide1;