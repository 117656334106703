import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useSlideState } from "components/hooks";

import {
  Arrow,
  Container,
  Image,
  MoreBtn,
  Overlay,
  PlayBtn,
} from "components/parts";

import img1 from "./assets/img1.svg";
import img2 from "./assets/img2.svg";
import img3 from "./assets/img3.svg";

const Slide = styled.div`
  > .box1 {
    p + p {
      margin-top: 10px;
    }
  }

  > .img1 {
    position: absolute;
    left: 65px;
    top: 386px;
    width: 890px;
  }

  > .box4 {
    font-size: 10px;
    line-height: 12px;
  }

  > table {
    position: absolute;
    left: 65px;
    top: 386px;
    width: 890px;
    font-size: 17px;
    line-height: 1;

    tr td {
      height: 31px;

      &:nth-child(1) {
        width: 198px;
        padding-left: 10px;
      }

      &:nth-child(2) {
        width: 169px;
        padding-left: 15px;
      }

      &:nth-child(3) {
        width: 170px;
        padding-left: 15px;
      }

      &:nth-child(4) {
        width: 170px;
        padding-left: 15px;
      }

      &:nth-child(5) {
        padding-left: 20px;
      }
    }

    .head td {
      height: 101px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;

      &:nth-child(5) {
        color: #ffb900;
      }
    }

    .footer td {
      color: #051f4a;
      font-weight: 600;

      &:nth-child(5) {
        font-size: 24px;
      }
    }
  }

  > .img2 {
    position: absolute;
    left: 900px;
    top: 516px;
    width: 85px;
  }

  .overlay1 {
    &::before {
      background: #051f4a !important;
    }

    > .box1 {
      border-radius: 15px;
      border-color: #051f4a;
      background: #fff;
    }

    > .box2 {
      font-size: 15.5px;
      line-height: 22px;
    }

    > .box3 {
      font-size: 13.5px;
      line-height: 15.5px;
    }

    .img3 {
      position: absolute;
      left: 72px;
      top: 202px;
      width: 711px;
    }

    > table {
      position: absolute;
      left: 72px;
      top: 202px;
      width: 711px;
      font-size: 13.5px;
      line-height: 1;

      tr td {
        height: 31px;

        &:nth-child(1) {
          width: 156px;
          padding-left: 6px;
        }

        &:nth-child(2) {
          width: 135px;
          padding-left: 20px;
        }

        &:nth-child(3) {
          width: 135px;
          padding-left: 20px;
        }

        &:nth-child(4) {
          width: 135px;
          padding-left: 20px;
        }

        &:nth-child(5) {
          padding-left: 20px;
        }
      }

      .head td {
        height: 41px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;

        &:nth-child(5) {
          color: #ffb900;
        }
      }

      tr:nth-child(7) td {
        color: #051f4a;
        font-weight: 600;
      }

      tr:nth-child(8),
      tr:nth-child(9),
      tr:nth-child(10) {
        td:nth-child(5) {
          color: #051f4a;
          font-weight: 600;
        }
      }

      .footer td {
        color: #051f4a;
        font-weight: 600;

        &:nth-child(5) {
          font-size: 19px;
        }
      }
    }
  }
`;

const inititialState = {
  overlay1: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide
      className="centered"
      step={props.step}
      active={props.active}
      preview={props.preview}
    >
      <h1 className="color2">
        Warum sich ein dualer Träger für jeden Optiker lohnt:
        <br />
        <strong>Umsatzplus über 3 Jahre</strong>
      </h1>

      <Container
        left={209}
        top={294}
        width={1630}
        height={340}
        className="bg1 box1 color2 flex-all-center"
      >
        <Arrow width={1065} className="container-bottom" bgColor={"#fff4dd"} />
        <p className="text-center">
          <strong>Gewinnen Sie einen loyaleren Kunden mit Umsatzplus:</strong>
          <br />
          Im folgenden Rechenbeispiel verleihen wir einem Ihrer Kunden einen
          <br />
          Geldwert, wenn Sie ihm zusätzlich zu einer Gleitsichtbrille eine
          Tageslinse
          <br />
          anbieten und ihn zum Dual-Träger machen.
        </p>
        <p className="text-center">
          Ihr Kunde hat die Wahl: Heute Kontaktlinse – morgen Brille.
        </p>
      </Container>

      <Container
        left={103}
        top={736}
        width={1840}
        height={364}
        className="bg2 box2 color2 flex-all-center fadeInFromTop"
      ></Container>

      <img src={img1} className="img1 fadeInFromTop" />

      <table className="fadeInFromTop">
        <tr className="head">
          <td></td>
          <td>BrillenTRÄGER</td>
          <td>Brille</td>
          <td>Linse</td>
          <td>DUAL-TRÄGER</td>
        </tr>
        <tr className="footer">
          <td>SUMME</td>
          <td>650,00 €</td>
          <td>650,00 €</td>
          <td>552,00 €</td>
          <td>1.202,00 €</td>
        </tr>
      </table>

      <img src={img2} className="img2 fadeInFromTop" />

      <MoreBtn
        left={1861}
        top={1060}
        active={state.overlay1 === "overlay1"}
        onClick={() => toggleOverlay("overlay1")}
        className="fadeInFromTop"
      />

      <Container
        as="p"
        left={12}
        top={1200}
        width={2035}
        className="box4 text-center"
      >
        Für dieses Rechenbeispiel wird ein Verkaufspreis von 1000 € für eine
        Gleitsichtbrille zugrunde gelegt.
        <br />
        Ein UVP von 50 € für eine ACUVUE® OASYS MAX 1-Day 30er Packung und ein
        Kontaktlinsen-Trageverhalten von 3 Tagen pro Woche.
      </Container>

      <Overlay
        active={state.overlay1 === "overlay1"}
        className="with-bg overlay1 overlay1-1"
      >
        <Container
          left={100}
          top={245}
          width={1819}
          height={947}
          className="box1 color2 border"
        />

        <Container
          as="p"
          left={145}
          top={284}
          width={1422}
          className="box2 text-center color2"
        >
          <strong>
            Nutzen Sie das deutliche Umsatzplus und machen Sie Ihre Kunden
            <br />
            gleichzeitig zu treuen und glücklichen Trägern.
          </strong>
        </Container>

        <img src={img3} className="img3" />

        <table>
          <tr className="head">
            <td></td>
            <td>BrillenTRÄGER</td>
            <td>Brille</td>
            <td>Linse</td>
            <td>DUAL-TRÄGER</td>
          </tr>
          <tr>
            <td>UVP* (netto)</td>
            <td>1.000,00 €</td>
            <td>1.000,00 €</td>
            <td>50,00 €</td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span style={{ letterSpacing: "-0.01rem" }}>
                Anzahl Verkäufe im Jahr
              </span>
            </td>
            <td>1</td>
            <td>1</td>
            <td>8</td>
            <td></td>
          </tr>
          <tr>
            <td>Umsatz (netto) im Jahr</td>
            <td>1.000,00 €</td>
            <td>1.000,00 €</td>
            <td>400,00 €</td>
            <td>1.400,00 €</td>
          </tr>
          <tr>
            <td>Wareneinsatz in €</td>
            <td>350,00 €</td>
            <td>350,00 €</td>
            <td>216,00 €</td>
            <td>566,00 €</td>
          </tr>
          <tr>
            <td>Rohertrag in €</td>
            <td>650,00 €</td>
            <td>650,00 €</td>
            <td>184,00 €</td>
            <td>834,00 €</td>
          </tr>
          <tr>
            <td colSpan="5">Rohertrag über 3 Jahre</td>
          </tr>
          <tr>
            <td>1. Jahr</td>
            <td>650,00 €</td>
            <td>650,00 €</td>
            <td>184,00 €</td>
            <td>834,00 €</td>
          </tr>
          <tr>
            <td>2. Jahr</td>
            <td>–</td>
            <td>–</td>
            <td>184,00 €</td>
            <td>184,00 €</td>
          </tr>
          <tr>
            <td>3. Jahr</td>
            <td>–</td>
            <td>–</td>
            <td>184,00 €</td>
            <td>184,00 €</td>
          </tr>
          <tr className="footer">
            <td>SUMME</td>
            <td>650,00 €</td>
            <td>650,00 €</td>
            <td>552,00 €</td>
            <td>1.202,00 €</td>
          </tr>
        </table>

        <Container
          as="p"
          left={1583}
          top={1086}
          width={252}
          className="box3 color2"
        >
          <strong>
            vs. 650€
            <br />
            (Mono-Träger)
          </strong>
        </Container>
      </Overlay>
    </Slide>
  );
};
