import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, Image, MoreBtn, Overlay } from 'components/parts'

//import diagram from './assets/diagram.svg';
import Diagram from './assets/Diagram';

const barAnimation = keyframes`
    0% {
        transform: scaleY(0) translate3d(0, 0, 100px);
        opacity: 1;
    }
    100% {  
        transform: scaleY(100%) translate3d(0, 0, 100px);
        opacity: 1;
    }
`

const Slide = styled.div`
    .img1 {
        position: absolute;
        left: 50px;
        top: 168px;
        width: 682.35px;
        height: 452.66px;
        
        > svg,
        .bars {
            position: absolute;
            left: 0;
            top: 0;
            width: 682.35px;
            height: 452.66px;
        }

        .svg1 {
            transform: translate3d(0, 0, 50px);
        }

        .bars {
            position: absolute;
            transform: translate3d(0, 0, 100px);
        }

        .svg2 {
            transform: translate3d(0, 0, 200px);
        }
    } 

    .legend {
        transform: translate3d(0, 0, 300px);
    }

    .overlay1 {
        transform: translate3d(0, 0, 400px);
    }

    .bar {
        position: absolute;
        bottom: 77px;
        transform-origin: left bottom;
        width: 25px;

        ${({ preview }) => !preview && css`
          opacity: 0;
        `}
        
        ${({ active, preview }) => (active && !preview) && css`
            animation: ${barAnimation} 2s forwards;
            animation-delay: 0.6s;
        `}

        &.bar1,
        &.bar3,
        &.bar5,
        &.bar7 {
            background: #009ca7;
        }

        &.bar2,
        &.bar4,
        &.bar6,
        &.bar8 {
            background: #e2000f;
        }

        &.bar1 {
            left: 177px;
            height: 16.8px;
        }

        &.bar2 {
            left: 213px;
            height: 14.315px;
        }

        &.bar3 {
            left: 303.3px;
            height: 21.3px;
        }

        &.bar4 {
            left: 339.3px;
            height: 109.8px;
        }

        &.bar5 {
            left: 424px;
            height: 16.8px;
        }

        &.bar6 {
            left: 460px;
            height: 168.8px;
        }

        &.bar7 {
            left: 552px;
            height: 21.3px;
        }

        &.bar8 {
            left: 588px;
            height: 193.8px;
        }
    }

    .box2 {
        text-align: right;
    }

    .box3 {
        font-size: 8.5px;
        line-height: 10.2px;
    }

    .box4 {
        padding-left: 120px;
    }

    .box5 {
        .count {
            font-size: 53.4px;
            line-height: 66.75;

            span {
                font-size: 28.8px;
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 93, state.overlay1 === 'overlay1', props.preview)

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1>ACUVUE® OASYS 1-Day hat einen Reibungskoeffizienten (CoF),<br />
                <strong>der dem des natürlichen Auges ähnelt<sup>16***</sup></strong></h1>
            <Diagram />
            <Container left={100} top={336} width={324} height={88} className="flex-all-center text-center legend">
                <p className="small color2">Hohe Reibung</p>
            </Container>
            <Container left={461} top={336} width={101} height={101} className="flex-all-center text-center legend">
                <p className="small">0h</p>
            </Container>
            <Container left={716} top={336} width={101} height={101} className="flex-all-center text-center legend">
                <p className="small">6h</p>
            </Container>
            <Container left={957} top={336} width={101} height={101} className="flex-all-center text-center legend">
                <p className="small">14h</p>
            </Container>
            <Container left={1213} top={336} width={101} height={101} className="flex-all-center text-center legend">
                <p className="small">18h</p>
            </Container>

            <Container left={204} top={497} width={130} height={28} className="flex-all-center text-center box2 legend">
                <p className="small">0,200</p>
            </Container>
            <Container left={204} top={642} width={130} height={28} className="flex-all-center text-center box2 legend">
                <p className="small">0,150</p>
            </Container>
            <Container left={204} top={786} width={130} height={28} className="flex-all-center text-center box2 legend">
                <p className="small">0,100</p>
            </Container>
            <Container left={204} top={931} width={130} height={28} className="flex-all-center text-center box2 legend">
                <p className="small">0,050</p>
            </Container>
            <Container left={204} top={1075} width={130} height={28} className="flex-all-center text-center box2 legend">
                <p className="small">0,000</p>
            </Container>

            <Container left={100} top={1151} width={324} height={88} className="flex-all-center text-center legend">
                <p className="small color2">Niedrige Reibung</p>
            </Container>

            <Container left={690} top={1132} width={403} height={109} className="flex-all-center text-center legend">
                <p className="small color2"><strong>Zykluszeit*<br />
TLF= Tear Like Fluid<sup>17</sup></strong></p>
            </Container>

            <Container left={1416} top={506} width={600} className="legend">
                <p className="small color2"><strong>DAILIES TOTAL1®</strong></p>
            </Container>

            <Container left={1416} top={644} width={600}  className="legend">
                <p className="small color2"><strong>ACUVUE® OASYS 1-Day<br />
with HydraLuxe® Technologie</strong></p>
            </Container>

            <Container left={1484} top={1035} width={500} className="box3 color2">
            ***
            </Container>

            <Container left={1484} top={1170} width={500} className="box3 color2">
                *Zykluszeit ist ein Ausdruck für die immer<br />
                wiederkehrenden Messungen über die Zeit
            </Container>

            <MoreBtn
                left={1416} top={740}
                onClick={() => toggleOverlay('overlay1')}
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 with-bg"  onClick={() => toggleOverlay('overlay1')}>
                <Container left={1026} top={794} width={922} height={203} className="rounded rounded-full flex-all-center color1 box4">
                    <p><strong>Niedrigerer CoF als<br />
DAILIES TOTAL1® nach 18<br />
Stunden simulierten Tragens<sup>18</sup></strong></p>
                </Container>
                <CircleCount left={922} top={736} width={315} percent={count1} color={'#df9933'} />
                <Container left={922} top={736} width={315} height={315} className="flex-all-center text-center box5">
                <span className="count">{count1}<span>%</span></span>
                </Container>
            </Overlay>

        </Slide>
    );
};
