import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'

import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'
import Slide9 from './slides/slide9'

import Slide10 from './slides/slide10'

const Theme = {
    color1: '#009C81',
    bg1: '#D0F0ED',
    bg2: '#00C4B4',
}

export const Slides = {
    'Folder6Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder6Slide2': { name: Slide2, theme: Theme },
    'Folder6Slide3': { name: Slide3, theme: Theme },
    'Folder6Slide4': { name: Slide4, theme: Theme },
    'Folder6Slide5': { name: Slide5, theme: Theme },
    'Folder6Slide6': { name: Slide6, theme: Theme },
    'Folder6Slide7': { name: Slide7, theme: Theme },
    'Folder6Slide8': { name: Slide8, theme: Theme },
    'Folder6Slide9': { name: Slide9, theme: Theme },
    'Folder6Slide10': { name: Slide10, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder6Slide1' },
    { key: 1, component: 'Folder6Slide2' },
    { key: 2, component: 'Folder6Slide3' },
    { key: 3, component: 'Folder6Slide4' },
    { key: 4, component: 'Folder6Slide5' },
    { key: 5, component: 'Folder6Slide6' },
    { key: 6, component: 'Folder6Slide7' },
    { key: 9, component: 'Folder6Slide10' },
    { key: 7, component: 'Folder6Slide8' },
    { key: 8, component: 'Folder6Slide9' },
]