import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

const Slide = styled.div`
    .box1 {
        align-items: center;
        justify-content: flex-start;

       .count {
           display: flex;
           margin-top: 5px;
           margin-left: 36px;
           width: 96.5px;
           height: 100px;
           font-size: 70px;
           line-height: 70px;

           span:nth-child(2) {
               font-size: 37.5px;
               align-self: center;
           }

           span:nth-child(3) {
               align-self: flex-end;
               
           }
       }

       p {
           margin-left: 23px;
       }
    }
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Was wollen Kunden? <strong>Langanhaltender<br />
Tragekomfort und Sehqualität, aber …</strong></h1>
            <Container left={417} top={356} width={1212} height={271} className="rounded rounded-full flex box1 color1">
                <span className="count">
                    <span>2</span>
                    <span>/</span>
                    <span>3</span>
                </span>
                <p>… fast zwei Drittel der Kontaktlinsenträger<br /> 
bemerken, dass die Leistung ihrer Kontakt-<br />
linsen in Bezug auf <strong>Tragekomfort und Seh-<br />
qualität im Laufe des Tages abnimmt.<sup>2</sup></strong></p>
            </Container>
            <Container left={572} top={717} width={903} height={365} className="bg1 flex-all-center text-center color1 fadeInFromTop">
                <Arrow width={838} className="container-top" />
                <p>Bisher kann die <strong>Leistung</strong> von<br /> 
Kontaktlinsen <strong>nicht über den ganzen<br />
Tag gehalten werden</strong> und die Träger<br />
werden in ihren Erwartungen<br /> 
<strong>enttäuscht.<sup>2</sup></strong></p>
            </Container>
        </Slide>
    );
};
