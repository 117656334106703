import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Container } from 'components/parts';
import dragger from './assets/dragger.png'
import useDrag from 'components/hooks/useDrag';

const StyledDragger = styled.div`
    position: absolute;
    left: 285px;
    top: 502px;
   

    .dragable {
        position: absolute;
        display: block;
        top: 0;
        width: 28px;
        height: 20px;
        cursor: pointer;
        
        span {
            display: block;
            position: absolute;
            left: -4px;
            top: -4px;
            width: 38px;
            height: 31px;
            background: url(${dragger});
            background-size: cover;
            touch-action: none;
            pointer-events: none;
        }
    }

    .text {
        position: absolute;
        left: 133px;
        top: -40px;
        font-size: 15.5px;
        line-height: 15px;
        width: 500px;
    }
`

const xMax = 392;

const texts = [
    `Sicht in der Ferne gut, im Zwischenbereich<br />
    und in der Nähe nicht optimal.`,
    `Sicht in der Ferne gut, im Zwischenbereich<br />
    und in der Nähe nicht optimal.`,
    `Sicht in der Ferne gut, im Zwischenbereich<br />
    und in der Nähe nicht optimal.`,
    `Klare Sehqualität in der Ferne,<br /> 
    im Zwischenbereich und in der Nähe`,
    `Sicht in der Nähe gut, im Zwischenbereich<br />
    und in der Ferne nicht optimal.`,
    `Sicht in der Nähe gut, im Zwischenbereich<br />
    und in der Ferne nicht optimal.`,
    `Sicht in der Nähe gut, im Zwischenbereich<br />
    und in der Ferne nicht optimal.`,
]

const Test = () => {
    
    
    const [x, setX] = useState(0);
    
    const [touching, setTouching] = useState({
        isTouching: false,
        touchXstart: null,
        touchX: null
    })

    const {
        dragging,
        onDragStart,
        onDragMove,
        onDragEnd
    } = useDrag({
        
        onEndCallback: (movement) => onMoveEnd(movement)
    });

    const onMoveEnd = useCallback((movement) => {
        let xNew = x + movement.x

        console.log(xNew)

        if (xNew > xMax) xNew = xMax;
        if (xNew < 0) xNew = 0;

        setX(xNew)
    }, [x])

    

    const handleTouchStart = (e) => {
        setTouching({
            ...touching,
            touchXstart: e.touches[0].clientX,
            touchYstart: e.touches[0].clientY
        })
    }

    const handleTouchMove = (e) => {
        let touchX = e.touches[0].clientX - touching.touchXstart;
        let touchY = e.touches[0].clientY - touching.touchYstart;

        if ((Math.abs(touchX) > 10) && (Math.abs(touchX) > Math.abs(touchY))) {
            setTouching({
                ...touching,
                isTouching: true,
                touchX
            })
        }
    }

    const handleTouchEnd = (e) => {
        setTouching({
            ...touching,
            isTouching: false
        })

        let xNew = x + touching.touchX

        if (xNew > xMax) xNew = xMax;
        if (xNew < 0) xNew = 0;

        setX(xNew)
    }

   

    console.log(dragging)


    let xCurrent = x

    if (touching.isTouching && touching.touchX) {
        xCurrent += touching.touchX
    }

    if (dragging.isDragging && dragging.x) {
        xCurrent += dragging.x
    }

    if (xCurrent > xMax) xCurrent = xMax;
    if (xCurrent < 0) xCurrent = 0;

    let textStep = 0;

    if (xCurrent > 379) {
        textStep = 6
    } else if (xCurrent > 324) {
        textStep = 5
    } else if (xCurrent > 258) {
        textStep = 4
    } else if (xCurrent > 199) {
        textStep = 3
    } else if (xCurrent > 130) {
        textStep = 2
    } else if (xCurrent > 61) {
        textStep = 1
    }

    
    return (
        <>
            <StyledDragger>
                <span
                    style={{ left: `${xCurrent}px` }}
                    className="dragable"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    onMouseDown={onDragStart}
                    onMouseMove={dragging.isDragging ? onDragMove : null}
                    onMouseUp={dragging.isDragging ? onDragEnd : null}
                    onMouseLeave={dragging.isDragging ? onDragEnd : null}
                >
                   <span />
                </span>
                <div className="text color2" dangerouslySetInnerHTML={{ __html: texts[textStep] }}>

                </div>
            </StyledDragger>
            <Container left={452} top={674} width={292} as="p" className="text6 text-center" style={{ display: textStep === 0 ? 'block' : 'none' }}>
                Größere Pupille bei fixem<br />
                Zentrum-Nähe-Design
            </Container>
            <Container left={583} top={674} width={292} as="p" className="text6 text-center" style={{ display: textStep === 1 ? 'block' : 'none' }}>
            Größere Pupille bei fixem<br />
Zentrum-Nähe-Design
            </Container>
            <Container left={712} top={674} width={292} as="p" className="text6 text-center" style={{ display: textStep === 2 ? 'block' : 'none' }}>
            Größere Pupille bei fixem<br />
Zentrum-Nähe-Design
            </Container>
            <Container left={843} top={674} width={292} as="p" className="text6 text-center" style={{ display: textStep === 3 ? 'block' : 'none' }}>
            Passende Pupille bei fixem<br />
Zentrum-Nähe-Design
            </Container>
            <Container left={976} top={674} width={292} as="p" className="text6 text-center" style={{ display: textStep === 4 ? 'block' : 'none' }}>
            Kleinere Pupille bei fixem<br />
Zentrum-Nähe-Design
            </Container>
            <Container left={1107} top={674} width={292} as="p" className="text6 text-center" style={{ display: textStep === 5 ? 'block' : 'none' }}>
            Kleinere Pupille bei fixem<br />
Zentrum-Nähe-Design
            </Container>
            <Container left={1237} top={674} width={292} as="p" className="text6 text-center" style={{ display: textStep === 6 ? 'block' : 'none' }}>
            Kleinere Pupille bei fixem<br />
Zentrum-Nähe-Design
            </Container>
        </>
    );
};

export default Test;