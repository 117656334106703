import React from "react";
import styled from "styled-components";

import { Image, Container } from "components/parts";

import bg from "./assets/bg.jpg";
import img1 from "./assets/img1.jpg";

const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "AvenirNext", sans-serif;

  h1 {
    color: #0a7cc1 !important;
    margin-top: 35px;
    font-size: 40px;
    line-height: 49px;
  }

  .btn1 {
    text-decoration: none;
    font-size: 16.5px;
    line-height: 20px;
    font-weight: bold;
    border-radius: 25px;
    text-transform: uppercase;
  }

  .bg {
    position: absolute;
    background: url(${bg});
    background-size: cover;
    inset: 0 0 85px 0;
  }
`;

export default ({ ...props }) => {
  return (
    <Slide className="bg full">
      <div className="bg" />
      <div className="centered">
        <h1>
          Testen Sie jetzt
          <br />
          den <strong>digitalen Anpass-Rechner</strong> für
          <br />
          <strong>torische ACUVUE® Kontaktlinsen</strong>
        </h1>
        <Image src={img1} width={830} height={607} left={609} top={437} />
        <Container
          as="a"
          href="https://www.jnjvisioncare.de/astigmatismus-kalkulator"
          target="_blank"
          width={600}
          height={110}
          left={724}
          top={1098}
          className="btn1 color3 bg1 flex flex-all-center"
        >
          ACUVUE®
          <br />
          ASTIGMATISMUS RECHNER&nbsp;&nbsp;&nbsp;&gt;
        </Container>
      </div>
    </Slide>
  );
};
