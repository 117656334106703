import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useSlideState } from "components/hooks";

import {
  Arrow,
  Container,
  Image,
  MoreBtn,
  Overlay,
  PlayBtn,
} from "components/parts";

import img1 from "./assets/img1.jpg";

const Slide = styled.div`
  .box2 {
    font-size: 30px;
    line-height: 40px;
  }
`;

const inititialState = {
  overlay1: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide
      className="centered"
      step={props.step}
      active={props.active}
      preview={props.preview}
    >
      <Container
        left={119}
        top={116}
        width={1814}
        height={747}
        className="bg2 box1 color2 flex-all-center"
      >
        <Arrow width={1085} className="container-bottom" bgColor={"#d9dee5"} />
      </Container>

      <Image left={164} top={162} width={1716} height={658} src={img1} />

      <Container
        left={119}
        top={947}
        width={1814}
        height={230}
        className="bg1 box2 color2 flex-all-center text-center fadeInFromTop"
      >
        <p>
          <strong>
            Multifokale Dual-Träger lohnen sich für Sie und Ihr Geschäft!
          </strong>
          <br />
          Und ab jetzt auch für Ihre Kunden:
        </p>
      </Container>
    </Slide>
  );
};
