import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn,
  Overlay,
  CloseBtn,
  CircleCountSimple2,
  MoreBtn2,
} from "components/parts";
import { MoreBtn2Container } from "components/parts/MoreBtn2";

import HeaderDots from "../../shared/HeaderDots";

import img1 from "./assets/img1.svg";
import overlay1_img1 from "./assets/overlay1_img1.jpg";
import overlay1_img2 from "./assets/overlay1_img2.jpg";
import blitz from "./assets/blitz.svg";
import light from "./assets/light.svg";

const breatheAnimation = keyframes`
    0% {
        transform: scale(1)
    }
    60% {
        transform: scale(1.2)
    }
    100% {  
        transform: scale(1)
    }
`;

const slideInFromBottom = keyframes`
    0% {
       
        transform: translateY(46px);
    }
    100% {  
        
        transform: translateY(0);
    }
`;

const Slide = styled.div`
  background: #0a7cc1;
  inset: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "AvenirNext", sans-serif;

  h1 {
    color: #fff !important;
    margin-top: 28px;
    font-size: 31.5px;
    line-height: 41px;
  }

  > div {
    > .box1 {
      display: flex;
      align-items: center;

      svg,
      img {
        position: absolute;
      }

      > .counter {
        position: relative;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;

        > span {
          display: block;
          font-size: 17.5px;
        }

        .value {
          margin-top: 15px;
          font-size: 51px;
          font-weight: 600;
          font-family: "AvenirNextMedium", sans-serif;
        }
      }
    }

    > .box1-2 {
      line-height: 22px;
    }

    > .box2 {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      padding-left: 217px;
      font-size: 16.5px;
      line-height: 21px;

      > span {
        position: absolute;
        font-weight: bold;
        width: 217px;
        left: 0;
        font-size: 39px;
        text-align: center;
      }
    }
  }

  /* */

  .overlay {
    &:before {
      background: #051f4a;
    }

    .bg1 {
      background: #fff;
      border-radius: 36px;
      overflow: hidden;
    }
  }

  .overlay1 {
    .box1 {
      font-size: 23px;
      line-height: 32px;
    }

    .box2 {
      font-size: 41px;
      line-height: 40px;
      font-weight: 600;
      font-family: "AvenirNextMedium", sans-serif;

      div {
        animation: ${breatheAnimation} 2s infinite;
      }
    }

    .box2_1 {
      font-size: 14px;
      line-height: 16.5px;
      color: #fff;
      font-family: "AvenirNextMedium", sans-serif;
    }

    .box3 {
      font-size: 14px;
      line-height: 18px;
    }

    .box4 {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      font-size: 18.5px;
      line-height: 24px;
    }

    .box5 {
      font-size: 16.5px;
      line-height: 22.5px;
      background: rgba(10, 124, 193, 0.1);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 113px;
      transform: translateY(46px);

      ${({ overlay }) =>
        overlay === "overlay1" &&
        css`
          animation: ${slideInFromBottom} 1s forwards ease-out;
          animation-delay: 0.6s;
        `}
    }
  }

  .copy {
    font-size: 10px;
    line-height: 11px;
  }

  /* */
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  let count1 = useCounter(0, 47, props.active, props.preview, 0.2);
  let count2 = useCounter(0, 31, props.active, props.preview, 1.3);
  let count3 = useCounter(1, 4, props.active, props.preview, 2.3);

  let count2_1 = useCounter(0, 17, state.overlay1, false, 0);
  let count2_2 = useCounter(0, 33, state.overlay1, false, 0);
  let count2_3 = useCounter(0, 50, state.overlay1, false, 0);

  return (
    <Slide step={props.step} overlay={state.overlay1}>
      <div className="centered">
        <h1 className="color2">
          Die Möglichkeit, Kunden <strong>mit Astigmatismus</strong> das zu
          bieten,
          <br />
          was sie wirklich brauchen
        </h1>

        <HeaderDots top={268} />

        <Container
          left={224}
          top={347}
          width={390}
          height={390}
          className="box1"
        >
          <img src={img1} />
          <div className="absolute counter color3">
            <span>Etwa</span>
            <span className="value">{count1}%</span>
          </div>
          <CircleCountSimple2 size={205} value={count1} />
        </Container>

        <Container
          left={124}
          top={781}
          width={578}
          className="box1-2 text-center small"
          as="p"
        >
          aller Kunden, die eine
          <br />
          Sehkorrektur benötigen,{" "}
          <strong>
            haben einen Astigmatismus ≥ 0,75 dpt auf mindestens einem Auge
            <sup>2</sup>
          </strong>
        </Container>

        <Container
          left={804}
          top={347}
          width={390}
          height={390}
          className="box1"
        >
          <img src={img1} />
          <div className="absolute counter color3">
            <span>Nur</span>
            <span className="value">{count2}%</span>
          </div>
          <CircleCountSimple2 size={205} value={count2} />
        </Container>

        <Container
          left={829}
          top={781}
          width={338}
          className="box1-2 text-center small"
          as="p"
        >
          tragen{" "}
          <strong>
            torische
            <br />
            Kontaktlinsen<sup>3</sup>
          </strong>
        </Container>

        <Container
          left={1384}
          top={347}
          width={390}
          height={390}
          className="box1"
        >
          <img src={img1} />
          <div className="absolute counter color3">
            <span>Etwa</span>
            <span className="value">1&thinsp;von&thinsp;{count3}</span>
          </div>
          <CircleCountSimple2 size={205} value={(count3 * 6, 25)} />
        </Container>

        <Container
          left={1385}
          top={781}
          width={388}
          className="box1-2 text-center small"
          as="p"
        >
          der Kunden, die torische Linsen tragen, brechen das Tragen im ersten
          Jahr ab<sup>*4</sup>
        </Container>

        <Container
          left={128}
          top={330}
          width={560}
          height={680}
          className="bg1 mask fadeOut"
        />

        <Container
          left={775}
          top={330}
          width={480}
          height={680}
          className="bg1 mask fadeOut animate2"
        />

        <Container
          left={1378}
          top={330}
          width={480}
          height={680}
          className="bg1 mask fadeOut animate3"
        />

        <MoreBtn2
          left={1888}
          top={595}
          onClick={() => toggleOverlay("overlay1")}
          className="btn1_1"
          exclamation
          style={{ opacity: state.overlay1 ? 0 : 1 }}
        />

        <Container
          left={494}
          top={1026}
          width={1554}
          height={206}
          className="box2 bg3 rounded-full flex flex-all-center relative fadeInFromRight animate4"
        >
          <span>Warum?</span>
          <p>
            <strong>Unscharfes Sehen, verschwommene Sicht</strong> durch
            Linsenverdrehung, sowie <strong>verringerter Tragekomfort</strong>{" "}
            sind die Hauptausstiegsgründe bei torischen Kontaktlinsen.
            <sup>4</sup>
          </p>
        </Container>

        <Container left={100} top={1236} width={1846} className="copy" as="p">
          * Actual rate = 27.2%.
        </Container>

        <Overlay
          active={state.overlay1 === "overlay1"}
          className="overlay with-bg overlay1"
        >
          <Container
            left={122}
            top={113}
            width={1802}
            height={1050}
            className="bg1"
          >
            <Image
              src={overlay1_img2}
              width={869}
              height={223}
              left={484}
              top={696}
              className="fadeOut animate2"
            />

            <Container
              left={0}
              top={840}
              width={1802}
              height={205}
              className="box5 color2 flex-all-center animate4"
            >
              <Image
                src={light}
                width={98}
                height={125}
                left={60}
                top={30}
                className="fadeIn animate4"
              />
              <div className="fadeIn animate4">
                <strong>
                  Bieten Sie Ihren Kunden daher ACUVUE® Kontaktlinsen mit
                  exzellentem Handling &<br />
                  hervorragendem Tragekomfort an.<sup>5</sup>
                </strong>{" "}
                Vor allem Neuträger und bestehende Träger (&gt; 12 Monate)
                <br />
                zeigen besonderes Interesse an Kontaktlinsen mit überlegenen
                Technologien*
              </div>
            </Container>

            <Container
              left={330}
              top={653}
              width={1473}
              height={157}
              className="box4 bg3 rounded-full flex flex-all-center relative text-center fadeInFromRight animate3"
            >
              <p>
                <strong>Das Handling</strong> der Kontaktlinsen{" "}
                <strong>und der Tragekomfort</strong> sind
                <br />
                die Hauptgründe für den Ausstieg.*
              </p>
            </Container>
          </Container>

          <Container
            left={265}
            top={168}
            width={1499}
            className="box1 color2 text-center"
          >
            <strong>
              Intensivbetrachtung der nationalen Ausstiegszeiten & -gründe
            </strong>
          </Container>

          <Image
            src={overlay1_img1}
            width={1458}
            height={336}
            left={294}
            top={286}
          />

          <Container
            left={312}
            top={382}
            width={300}
            className="box2 color3 text-center"
          >
            {count2_1}%
            <span className="fadeIn animate4">
              <Image src={blitz} width={29} height={64} left={55} top={120} />
            </span>
          </Container>

          <Container
            left={846}
            top={382}
            width={300}
            className="box2 color3 text-center"
          >
            {count2_2}%
            <span className="fadeIn animate4">
              <Image src={blitz} width={29} height={64} left={45} top={120} />
            </span>
          </Container>

          <Container
            left={1421}
            top={382}
            width={300}
            className="box2 color3 text-center"
          >
            {count2_3}%
            <span className="fadeIn animate4">
              <Image src={blitz} width={29} height={64} left={47} top={120} />
            </span>
          </Container>

          <Container
            left={349}
            top={510}
            width={236}
            className="box2_1 color2 text-center fadeIn animate4"
          >
            Handling
          </Container>

          <Container
            left={909}
            top={510}
            width={210}
            className="box2_1 color2 text-center fadeIn animate4"
          >
            Tragekomfort
          </Container>

          <Container
            left={1472}
            top={499}
            width={210}
            className="box2_1 color2 text-center fadeIn animate4"
          >
            Tagesend-
            <br />
            Komfort
          </Container>

          <Container
            left={216}
            top={647}
            width={465}
            className="box3 color2 text-center"
          >
            steigen{" "}
            <strong>
              in den
              <br />
              ersten 3 Monaten
            </strong>{" "}
            aus*
          </Container>

          <Container
            left={807}
            top={647}
            width={375}
            className="box3 color2 text-center"
          >
            steigen{" "}
            <strong>
              in den
              <br />
              ersten 12 Monaten
            </strong>{" "}
            aus*
          </Container>

          <Container
            left={1290}
            top={647}
            width={562}
            className="box3 color2 text-center"
          >
            steigen nach <strong>mehr als 12 Monaten</strong> regelmäßigen
            Tragens aus*
          </Container>

          <Container
            left={1580}
            top={1116}
            width={400}
            className="copy color2"
            as="p"
          >
            * Path to Purchase Study 2024
          </Container>

          <CloseBtn
            left={1888}
            top={598}
            onClick={() => toggleOverlay(null)}
            bgColor="#fff"
          />
        </Overlay>
      </div>
    </Slide>
  );
};
