import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';
import img2 from './assets/img1.png';

const Slide = styled.div`
   .box1 {
       padding-top: 26px;
       padding-left: 44px;
    }

    .box2 {
        padding-top: 5px;
        padding-left: 8px;

        .count {
            font-size: 46px;
            line-height: 58px;

            span {
                font-size: 25px;
            }
        }
    }

    .box3 {
        margin-top: -2px;

        .count {
            font-size: 41.5px;
            line-height: 52px;

            span {
                font-size: 22.5px;
            }
        }

        p {
            margin-top: -15px;
            font-size: 19px;
            line-height: 21.5px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 83, props.active, props.preview)
    const count2 = useCounter(0, 99, props.active, props.preview)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1><strong>ACUVUE® OASYS 1-Day for ASTIGMATISM –</strong><br />
Schnell, einfach und erfolgreich</h1>

                <Container left={102} top={454} width={1044} height={686} className="rounded box1 color1">
                    <div className="text">
                        <p><strong>96% der Kontaktlinsenspezialisten<br /> 
bestätigen:</strong> Eine einfache Anpassung der torischen Tageslinse.<sup>35</sup></p>
                        <p>So <strong>einfach anzupassen wie eine
sphärische</strong> Kontaktlinse.<sup>&yen;35</sup></p>
                        <p><strong>Ausgezeichnete Sehqualität für Menschen<br />
                        mit Astigmatismus:<sup>35</sup></strong></p>
                    </div>
                </Container>

                <CircleCount left={188} top={924} width={268} percent={count1} color={'#df9933'} />
                <CircleCount left={1286} top={570} width={315} percent={count2} color={'#df9933'} className="fadeInFromLeft" />

                <Container left={188} top={924} width={268} height={268} className="flex-all-center text-center box2">
                    <span className="count">{count1}<span>%</span></span>
                </Container>

                <Container left={1286} top={570} width={315} height={315} className="flex-all-center text-center box3 fadeInFromLeft">
                    <span className="count">{count2}<span>%</span></span>
                    <p className="color1"><strong>Anpass-<br />
erfolg<sup>35</sup></strong></p>
                </Container>

                <Image left={362} top={308} width={533} height={175} src={img2} />

                <Arrow left={1142} top={584} width={294} />

                <Container left={478} top={1007} width={619} className="color1">
                    <p>bewerteten die Sehqualität als<br />hervorragend/sehr gut<sup>35</sup></p>
                </Container>

            </Slide>
        </>
    );
};
