import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useCounter } from 'components/hooks'

import bg from '../slide10/assets/bg.jpg'
import img1 from './assets/img1.png'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 7.25px;
        line-height: 10px;
    }

    .text2 {
        font-size: 25px;
        line-height: 26px;
        text-transform: uppercase;
    }

    .text3 {
        line-height: 25px;
    }

    .text4 {
        margin: 0;
        padding: 0;
        counter-reset: custom-counter;
        list-style: none;
        line-height: 25px;

        li {
            position: relative;
            padding-left: 27px;
            counter-increment: custom-counter;
            
            &::before {
                position: absolute;
                left: 0;
                top: 0;
                font-weight: 700;
                content: counter(custom-counter)  ". ";
            }

            + li {
                margin-top: 20px;
            }
        }
    }

    .text5 {
        font-size: 25px;
        line-height: 30px;
    }
    
    .text6 {
        font-size: 15px;
        line-height: 16px;
    }

    .line {
        display: block;
        position: absolute;
        height: 1.2px;
        background: #fff;

        &.line1 {
            left: 0;
            top: 178px;
            width: 416px;
        }

        &.line2 {
            left: 0;
            top: 374px;
            width: 416px;
        }

        &.line3 {
            left: 483px;
            top: 178px;
            width: 541px;
        }
    }
`

const Folder2Slide1 = ({ ...props }) => {

    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>wer profitiert am meisten?</h1>
                <Image left={1062} top={979} width={593} height={252} src={img1} />
                <Container left={868} top={1190} width={695} as="p" className="text1 text-center">
                    Nur zu Illustrationszwecken
                </Container>

                <Container left={102} top={273} width={780} as="p" className="text2">
                    <strong>Neuträger</strong>
                </Container>

                <Container left={102} top={381} width={780} as="p" className="text3">
                    Empfehlen Sie allen Neuträgern<br />
                    <strong>ACUVUE® OASYS MAX 1-Day</strong>,<br />
                    damit diese von Anfang an ein MAX<br />
                    Kontaktlinsen-Erlebnis haben können.
                </Container>

                <Container left={102} top={662} width={780} as="p" className="text2">
                    <strong>Und bei Presbyopie</strong>
                </Container>

                <Container left={102} top={780} width={780} as="p" className="text3">
                    Haben Sie Probleme beim<br />
                    Lesen aus nächster Nähe oder bei<br />
                    schwachem Licht?
                </Container>

                <Container left={964} top={273} width={1020} as="p" className="text2">
                    <strong>Bestehende träger</strong>
                </Container>

                <Container left={964} top={381} width={1020} as="p" className="text3">
                    Mit folgenden Fragen finden Sie ganz schnell<br />
                    heraus, ob Ihre bestehenden Kunden von<br />
                    <strong>ACUVUE® OASYS MAX 1-Day</strong> profitieren würden
                </Container>

                <Container left={964} top={566} width={1020} as="ol" className="text4">
                    <li> Erleben Sie zeitweise ein unangenehmes
                        Tragegefühl mit Ihren aktuellen Kontaktlinsen? </li>
                    <li>Gehören Sie auch zu den vielen Menschen, die oft viel Zeit vor digitalen Bildschirmen verbringen?</li>
                    <li>Ist das Thema Blaulicht-Filter interessant für Sie?</li>
                </Container>

                <Container left={212} top={1039} width={822} as="p" className="text5 text-center">
                    Ist die Antwort ja: Empfehlen Sie<br />
                    <strong>ACUVUE® OASYS MAX 1-Day</strong>
                </Container>

                <Container left={1601} top={1050} width={400} as="p" className="text6 color3">
                    <strong>Auch in<br />
                        Multifokal<br />
                        erhältlich</strong>
                </Container>

                <span className="line line1" />
                <span className="line line2" />
                <span className="line line3" />

            </Slide>
        </>

    );
};

export default Folder2Slide1;