import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Arrow2,
  Arrow3,
  Container,
  Image,
  MoreBtn,
  Overlay,
} from "components/parts";

import bg from "./assets/bg.jpg";
import img1 from "./assets/img1.png";
import img2 from "./assets/img2.jpg";

const Slide = styled.div`
  > .box1 {
    padding-left: 178px;

    > img {
      position: absolute;
      left: 0;
      top: 0;
      width: auto;
      height: 100%;
    }

    .box2 {
      .count {
        margin-top: -20px;
        font-size: 54px;
        line-height: 66px;
      }

      p {
        position: absolute;
        left: 0;
        width: 100%;
        font-size: 18px;
        line-height: 20px;

        &.top {
          top: 30px;
        }

        &.bottom {
          top: 92px;
        }
      }

      &.box2-1 .count {
        letter-spacing: -0.25rem;
      }
    }
  }

  > .box2 {
    padding-top: 25px;
  }

  > .box3 {
    padding-top: 25px;
  }

  .overlay1 .box1 {
    padding: 60px 0 0 42px;

    p + p {
      margin-top: 16px;
    }
  }

  .overlay {
    > .box1 {
      border-radius: 15px;
      border-color: #051f4a;
      border-width: 1px;
      background: #fff;
    }

    &::before {
      background: #051f4a !important;
    }
  }

  .overlay2 .box1 {
    background-image: url(${img2});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;

    padding: 130px 0 0 42px;

    p + p {
      margin-top: 16px;
    }
  }
`;
const inititialState = {
  overlay1: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay } = useSlideState({
    inititialState,
    active: props.active,
  });

  let count = useCounter(0, 87, props.active, props.preview, 1);
  count = count / 10;
  count = count.toFixed(1).toString().replace(".", ",");

  return (
    <>
      <div className="bg right" style={{ backgroundImage: `url(${bg})` }}></div>
      <Slide className="centered" step={props.step}>
        <h1 className="color2">
          Warum diese Kampagne <strong>ein Erfolg</strong> wird
        </h1>

        <Container
          left={108}
          top={237}
          width={1106}
          height={307}
          className="box1 flex-all-center"
        >
          <img src={img1} />
          <Container
            left={0}
            top={0}
            width={307}
            height={307}
            className="box2 flex-all-center text-center "
          >
            <span className="count color2">{count}</span>
            <p className="bottom color2">Mio.</p>
          </Container>
          <p className="color2">
            … der über 45-Jährigen
            <br />
            sind offen und interessiert
            <br />
            an Kontaktlinsen –{" "}
            <strong>
              aber nur
              <br />
              0,6 Mio. tragen welche!
            </strong>{" "}
            <sup>1</sup>
          </p>
        </Container>

        <Container
          left={129}
          top={587}
          width={960}
          height={130}
          className="box2 bg1 text-center color2 fadeInFromTop animate2"
        >
          <Arrow width={800} className="container-top" />
          <p>Das ungenutzte Potenzial ist riesig!</p>
        </Container>

        <Container
          left={129}
          top={767}
          width={960}
          height={450}
          className="box3 bg1 text-center color2 fadeInFromTop animate3"
        >
          <Arrow width={800} className="container-top" />
          <p>
            Genau dieses Potenzial nutzt die
            <br />
            ACUVUE® MULTIFOCAL Kampagne
            <br />
            <strong>„Alterssichtigkeit geht auch anders”</strong>
            <br />
            <br />
            <strong>Ziel:</strong> Awareness-Steigerung für
            <br />
            das Thema Presbyopie verbunden
            <br />
            mit dem Call-to-Action:
            <br />
            <strong>„Jetzt testen“</strong>
          </p>
        </Container>

        <MoreBtn
          className="fadeInFromTop animate2"
          left={1035}
          top={613}
          active={state.overlay1 === "overlay1"}
          onClick={() => toggleOverlay("overlay1")}
        />

        <MoreBtn
          className="fadeInFromTop animate3"
          left={1035}
          top={839}
          active={state.overlay1 === "overlay2"}
          onClick={() => toggleOverlay("overlay2")}
        />

        <Overlay
          active={state.overlay1 === "overlay1"}
          className="with-bg overlay overlay1 overlay1-1"
        >
          <Container
            left={108}
            top={237}
            width={969}
            height={980}
            className="box1 bg2 color2 border"
          >
            <p>
              Besonders die “duale Verwendung” und die Drop-Out-Minimierung
              treiben die multifokale Profitabilität.
            </p>

            <p>
              <strong>Die presbyope Zielgruppe …</strong>
            </p>

            <p>
              … ist besonders attraktiv und profitiert
              <br />
              durch die Vorzüge von multifokalen
              <br />
              Kontaktlinsen
            </p>

            <p>
              … hat vielfältige Bedürnisse, von denen einige nicht
              ausschließlich durch eine Gleitsichtbrille befriedigt werden
              können
            </p>

            <p>
              … steht durch den häufigeren Linsen-
              <br />
              nachkauf in engerem Kontakt mit
              <br />
              seinem Optiker
            </p>
          </Container>

          <Arrow
            left={140}
            top={610}
            width={64}
            className=""
            arrowColor={"#ffb900"}
          />
          <Arrow
            left={140}
            top={786}
            width={64}
            className=""
            arrowColor={"#ffb900"}
          />
          <Arrow
            left={140}
            top={960}
            width={64}
            className=""
            arrowColor={"#ffb900"}
          />
        </Overlay>

        <Overlay
          active={state.overlay1 === "overlay2"}
          className="with-bg overlay overlay2 overlay2-1"
        >
          <Container
            left={108}
            top={237}
            width={969}
            height={980}
            className="box1 bg2 color2 border"
          >
            <p>
              <strong>Die Kampagne …</strong>
            </p>

            <p>
              … stellt die klassischen presbyopen
              <br />
              Probleme dar und schafft so
              <br />
              Aufmerksamkeit für Gleitsichtlinsen
            </p>

            <p>
              … visualisiert die Vorteile von
              <br />
              multifokalen Kontaktlinsen und
            </p>

            <p>
              … zeigt die Vorteile eines dualen
              <br />
              Trägers auf
            </p>

            <p>
              … unterstützt die Kundenbindung
              <br />
              an den Optiker über das ganze Jahr
            </p>
          </Container>

          <Arrow
            left={140}
            top={581}
            width={64}
            className=""
            arrowColor={"#ffb900"}
            bgColor={"transparent"}
          />
          <Arrow
            left={140}
            top={755}
            width={64}
            className=""
            arrowColor={"#ffb900"}
            bgColor={"transparent"}
          />
          <Arrow
            left={140}
            top={878}
            width={64}
            className=""
            arrowColor={"#ffb900"}
          />
          <Arrow
            left={140}
            top={1008}
            width={64}
            className=""
            arrowColor={"#ffb900"}
          />
        </Overlay>
      </Slide>
    </>
  );
};
