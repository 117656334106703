import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.png';
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';

import img9 from '../slide2/assets/img9.png';

const Slide = styled.div`
    .box1 {
        padding-left: 25px;
        border-radius: 70.5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: #00A5E3;
        color: #fff;

        p {
            font-size: 15px;
            line-height: 15px;
        }
    }

    .box2 a {
        font-style: italic;
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1><strong>ACUVUE® Home Delivery Service</strong> –<br />
unsere Unterstützung für Sie</h1>
                <h2 className="small">Wir versenden in Ihrem Namen ACUVUE® Kontaktlinsen. Ihr Kunde erhält seine<br />
Linsen und den Lieferschein, mit Ihnen als Absender – einmalig oder regelmäßig.</h2>

                <Image left={401} top={410} width={648} height={118} src={img1} />

               

                <Container left={104} top={473} width={266} height={141} className="box1 flex-all-center">
                    <p><strong>Was kann<br />
versendet<br />
werden?</strong></p>
                </Container>
                <Container left={104} top={660} width={266} height={141} className="box1 flex-all-center">
                    <p><strong>Was<br />
kostet die<br />
Lieferung?</strong></p>
                </Container>
                <Container left={104} top={844} width={266} height={141} className="box1 flex-all-center">
                    <p><strong>Was<br />
bekommen<br />
Sie?</strong></p>
                </Container>

                <Container as="p" left={405} top={538} width={300} className="small">
                    2x 90er oder<br />
6x 30er Boxen
                </Container>
                <Container as="p" left={677} top={538} width={300} className="small">
                    2x 12er oder<br />
4x 6er Boxen
                </Container>

                <Container as="p" left={916} top={538} width={300} className="small">
                    4x 6er Boxen
                </Container>

                <Container as="p" left={405} top={692} width={760} className="small">
                    Pro Bestellung berechnen wir Ihnen 3€.<br />
Die Lieferzeit beträgt ca. 5 Werktage.
                </Container>

                <Container as="p" left={405} top={854} width={760} className="small">
                    Sie erhalten die Rechnung für die bestellte<br />
Ware (Monatsrechnung). Hierfür erstellen<br />
Sie Ihrem Kunden dann eine Rechnung.
                </Container>

                <Arrow left={564} top={593} width={953} offset={215} className="rotate90" />

                <Container as="p" left={100} top={1116} className="color1 fadeInFromTop">
                    <strong>Bieten Sie Ihren Kunden einen<br />
bestmöglichen Service.</strong>
                </Container>

                <MoreBtn
                    left={1004} top={1010}
                    active={state.overlay1 === 'overlay1'}
                    onClick={() => toggleOverlay('overlay1')}
                />


                <MoreBtn
                    left={769} top={1128}
                    active={state.overlay1 === 'overlay2'}
                    onClick={() => toggleOverlay('overlay2')}
                    className="fadeInFromTop"
                />

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                    <Container left={77} top={406} width={1003} height={679} className="rounded">
                    </Container>
                    <Container as="p" left={163} top={454} width={894} className="color1">
                        <strong>Weitere Unterstützung für Sie</strong>
                    </Container>
                    <Container as="p" left={163} top={527} width={894} className="small">
                        Schalten Sie mit digitalen Assets auf Ihrer Website<br />
und in den sozialen Medien einen Hinweis, dass Sie<br />
den ACUVUE® Home Delivery Service anbieten.
                    </Container>
                    <Image left={266} top={664} width={658} height={413} src={img2} />
                </Overlay>

                <Overlay active={state.overlay1 === 'overlay2'} className="with-bg">
                    <Container left={399} top={385} width={822} height={782} className="rounded">
                    </Container>
                    <Image left={423} top={578} width={780} height={439} src={img3} />
                    <Container as="p" left={399} top={430} width={822} className="box2 color1 text-center">
                    Informieren Sie Ihre Kunden und<br /> 
begeistern Sie mit einem<br /> 
umfassenden Service
                    </Container>
                    <Container as="p" left={399} top={1016} width={822} className="box2 color1 text-center">
                        <a href="#" className="color1">Hier</a> den Home 
Delivery Service Guide<br /> 
<strong>per E-Mail versenden.</strong>
                    </Container>
                </Overlay>

            </Slide>
        </>
    );
};
