import React from 'react';
import styled, { css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'


const Container = styled.div`
    position: absolute;

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}

    ${({ width }) => width !== undefined && css`
        width: ${calculateDimensionProperty(width)};
        height: ${calculateDimensionProperty(width)};
    `}

    ${({ borderColor }) => borderColor !== undefined && css`
        .circle {
            stroke: ${borderColor};
        }
    `}
    
    border-radius: 50%;
    background: #fff;
`

const CircleCount = ({...props}) => {

    let percent = props.percent;

    let colored = Math.floor(percent/100*60)

    console.log(colored)

    const color = []

    for(var i=1; i<=60;i++) {
        color[i] = i <= colored ? props.color : '#a7a9ac'
    }

    return (
        <Container left={props.left} top={props.top} width={props.width} borderColor={props.borderColor} className={props.className}>
            <svg width="100%" viewBox="0 0 314.94 314.94">
                <path fill={color[1]} d="M152.67.76h3.86V47.42l-3.86.46Z" transform="translate(0 0)" />
                <path fill={color[2]} d="M168.17.68l5,.44c-.63,6.16-1.2,12.07-1.83,18-.92,8.63-2,17.24-2.78,25.88-.28,3-1.48,3.51-4.22,2.46,1.29-15.48,2.57-30.9,3.88-46.76" transform="translate(0 0)" />
                <path fill={color[3]} d="M179.34,49.73,175.6,49c2.91-15.35,5.81-30.62,8.79-46.29l5,.83c-3.37,15.51-6.68,30.79-10,46.16" transform="translate(0 0)" />
                <path fill={color[4]} d="M190.4,52.51l-3.7-1c4.57-15.15,9.06-30,13.65-45.23l4.82,1.45c-4.95,15-9.82,29.76-14.77,44.76" transform="translate(0 0)" />
                <path fill={color[5]} d="M201,56.66,197.57,55c6.08-14.49,12.08-28.76,18.24-43.42l4.62,2L201,56.66" transform="translate(0 0)" />
                <path fill={color[6]} d="M230.65,18.4,235,20.89c-8,13.64-15.81,27.07-23.79,40.73l-3.38-1.76L230.65,18.4" transform="translate(0 0)" />
                <path fill={color[7]} d="M220.81,67.82l-3.08-2.29,26.93-38.71,4.17,2.84-28,38.16" transform="translate(0 0)" />
                <path fill={color[8]} d="M229.78,74.81l-2.91-2.47,30.89-35.77,3.83,3.28-31.81,35" transform="translate(0 0)" />
                <path fill={color[9]} d="M269.71,47.7l3.47,3.64-35.6,31.75c-.64-1.1-1.23-2.11-2-3.38l34.11-32" transform="translate(0 0)" />
                <path fill={color[10]} d="M245.2,91.53l-2.45-2.93L280.44,60l3.11,4L245.2,91.53" transform="translate(0 0)" />
                <path fill={color[11]} d="M251.42,101.05c-.71-1.16-1.26-2.06-2-3.25l40.47-24.5,2.57,4.35-41,23.4" transform="translate(0 0)" />
                <path fill={color[12]} d="M256.65,111.17l-1.57-3.49,42.81-20.15L300,92.12l-43.3,19" transform="translate(0 0)" />
                <path fill={color[13]} d="M304.21,102.58c.59,1.63,1.08,3,1.72,4.72l-45,14.39c-.5-1.23-.91-2.26-1.43-3.56l44.68-15.55" transform="translate(0 0)" />
                <path fill={color[14]} d="M309.16,118.13c.36,1.83.64,3.22,1,4.94l-46.33,9.6c-.25-1.44-.42-2.46-.64-3.75l46-10.79" transform="translate(0 0)" />
                <path fill={color[15]} d="M265.41,140.07l46.87-5.9c.17,1.83.29,3.12.46,5l-47,4.75c-.12-1.28-.23-2.41-.36-3.82" transform="translate(0 0)" />
                <rect fill={color[16]} x="266.46" y="151.15" width="46.85" height="3.96" />
                <path fill={color[17]} d="M266.27,166.65V162.8l47.05,3.88c-.08,1.7-.14,3-.23,5l-46.82-5" transform="translate(0 0)" />
                <path fill={color[18]} d="M264.21,177.82l.8-3.74,46.32,8.78c-.24,1.59-.44,3-.74,5l-46.38-10" transform="translate(0 0)" />
                <path fill={color[19]} d="M306.36,203.61l-44.87-14.76c.33-1.28.6-2.29,1-3.68l45.31,13.6c-.49,1.67-.91,3.13-1.41,4.84" transform="translate(0 0)" />
                <path fill={color[20]} d="M302.6,214.23l-2.09,4.59L257.45,199.5l1.42-3.54,43.73,18.27" transform="translate(0 0)" />
                <path fill={color[21]} d="M293.16,233.39l-40.77-23.71,1.75-3.39L295.68,229l-2.52,4.37" transform="translate(0 0)" />
                <path fill={color[22]} d="M287.28,243l-2.88,4.14-38.14-27.87,2.08-3.22L287.28,243" transform="translate(0 0)" />
                <path fill={color[23]} d="M239.1,228.12l2.54-2.87,35.84,30.83c-1.17,1.31-2.12,2.39-3.35,3.76l-35-31.72" transform="translate(0 0)" />
                <path fill={color[24]} d="M266.36,268l-3.71,3.42L230.88,236l3.2-2.16L266.36,268" transform="translate(0 0)" />
                <path fill={color[25]} d="M222.27,243.36l3.17-2.14C235,253.7,244.35,266,254,278.7l-4,3.07-27.73-38.41" transform="translate(0 0)" />
                <path fill={color[26]} d="M212.82,249.7l3.26-2c8.15,13.39,16.26,26.7,24.56,40.35l-4.3,2.65-23.52-41" transform="translate(0 0)" />
                <path fill={color[27]} d="M226.36,296l-4.6,2.09c-6.38-14.47-12.64-28.64-19-43l3.38-1.79L226.36,296" transform="translate(0 0)" />
                <path fill={color[28]} d="M195.71,257.82c5.22,14.9,10.34,29.52,15.61,44.59L206.55,304l-14.41-44.8,3.57-1.41" transform="translate(0 0)" />
                <path fill={color[29]} d="M181.14,262.1l3.74-.69c3.6,15.23,7.18,30.35,10.83,45.8l-4.94,1.07c-3.23-15.46-6.38-30.6-9.63-46.18" transform="translate(0 0)" />
                <path fill={color[30]} d="M169.89,264l3.82-.31c2,15.53,3.95,30.85,6,46.58l-5,.71c-1.6-15.7-3.16-31.09-4.78-47" transform="translate(0 0)" />
                <rect fill={color[31]} x="158.57" y="264.79" width="4.12" height="46.74" />
                <path fill={color[32]} d="M147,311.65l-5-.51c1.71-15.67,3.4-31.12,5.12-46.84l3.81.28c-1.31,15.72-2.6,31.23-3.92,47.07" transform="translate(0 0)" />
                <path fill={color[33]} d="M130.81,309.66l-4.94-1c3.34-15.39,6.64-30.61,10-46.07l3.77.66-8.82,46.41" transform="translate(0 0)" />
                <path fill={color[34]} d="M114.87,306.09l-4.79-1.6c4.91-14.9,9.78-29.66,14.69-44.57l3.78.65-13.68,45.52" transform="translate(0 0)" />
                <path fill={color[35]} d="M99.39,300.79l-4.61-2.07c6.49-14.38,12.89-28.58,19.39-43l3.53,1.45c-6.08,14.5-12.1,28.84-18.31,43.62" transform="translate(0 0)" />
                <path fill={color[36]} d="M84.55,293.92l-4.37-2.52L104,250.64l3.34,1.84L84.55,293.92" transform="translate(0 0)" />
                <path fill={color[37]} d="M94.38,244.54l3.14,2.17c-9,12.94-17.89,25.74-27,38.83l-4.09-3,27.95-38" transform="translate(0 0)" />
                <path fill={color[38]} d="M85.54,237.37,88.33,240,57.47,275.71l-3.81-3.29,31.88-35.05" transform="translate(0 0)" />
                <path fill={color[39]} d="M77.33,229.49,80,232.26C68.44,243.08,57,253.81,45.46,264.64L42,261l35.29-31.46" transform="translate(0 0)" />
                <path fill={color[40]} d="M70.12,220.7l2.26,3.08L34.76,252.31l-3.08-4L70.12,220.7" transform="translate(0 0)" />
                <path fill={color[41]} d="M25.3,239l-2.57-4.35,41-23.37,2.24,3.11L25.3,239" transform="translate(0 0)" />
                <path fill={color[42]} d="M58.65,201.11c.55,1.38.94,2.36,1.41,3.56L17.39,224.73c-.75-1.6-1.36-2.89-2.13-4.55l43.39-19.07" transform="translate(0 0)" />
                <path fill={color[43]} d="M54.37,190.58c.47,1.32.79,2.21,1.29,3.58l-44.75,15.6c-.54-1.67-1-3-1.53-4.79l45-14.39" transform="translate(0 0)" />
                <path fill={color[44]} d="M51.33,179.64l.8,3.72L6.21,194.15c-.37-1.55-.72-3-1.16-4.9l46.28-9.61" transform="translate(0 0)" />
                <path fill={color[45]} d="M3,178.15l-.65-5,47-4.75c.22,1.2.42,2.22.71,3.76l-47,6" transform="translate(0 0)" />
                <path fill={color[46]} d="M48.85,161.26h-47v-4.14H48.58c.09,1.3.15,2.33.27,4.14" transform="translate(0 0)" />
                <path fill={color[47]} d="M1.62,145.6c.28-1.93.47-3.24.72-5l46.9,5.09c-.09,1.87.62,3.82-2.29,3.59L1.62,145.6" transform="translate(0 0)" />
                <path fill={color[48]} d="M50.21,138.23l-46.5-8.84,1.06-4.89,46.19,10c-.26,1.3-.48,2.4-.75,3.77" transform="translate(0 0)" />
                <path fill={color[49]} d="M7.4,113.51c.48-1.63.89-3,1.42-4.81l44.94,14.74c-.34,1.23-.65,2.31-1,3.68L7.4,113.51" transform="translate(0 0)" />
                <path fill={color[50]} d="M57.83,112.82c-.55,1.32-.91,2.2-1.47,3.53L12.69,98.09c.68-1.59,1.24-2.9,2-4.63l43.17,19.36" transform="translate(0 0)" />
                <path fill={color[51]} d="M19.54,83.28,22,78.9l40.73,23.66-1.53,3.52L19.54,83.28" transform="translate(0 0)" />
                <path fill={color[52]} d="M28,69.3l2.86-4.15L69,93l-2.15,3.17L28,69.3" transform="translate(0 0)" />
                <path fill={color[53]} d="M37.71,56.2l3.4-3.73,35,31.72-2.52,2.86L37.71,56.2" transform="translate(0 0)" />
                <path fill={color[54]} d="M81.28,78.65,48.91,44.31l3.64-3.48,31.71,35.4-3,2.42" transform="translate(0 0)" />
                <path fill={color[55]} d="M61.17,33.53l4.08-3L92.83,68.74l-2.9,2.51L61.17,33.53" transform="translate(0 0)" />
                <path fill={color[56]} d="M102.39,62.55l-3.26,2L74.57,24.17l4.32-2.59,23.5,41" transform="translate(0 0)" />
                <path fill={color[57]} d="M88.85,16.22l4.57-2.09,19,43.09L109.08,59,88.85,16.22" transform="translate(0 0)" />
                <path fill={color[58]} d="M103.88,9.84l4.79-1.6c4.83,15,9.59,29.85,14.41,44.86l-3.56,1.38c-5.2-14.85-10.35-29.56-15.64-44.64" transform="translate(0 0)" />
                <path fill={color[59]} d="M119.5,4.94l5-.94c3.21,15.37,6.39,30.61,9.63,46.1l-3.71.9c-3.63-15.36-7.2-30.5-10.87-46.06" transform="translate(0 0)" />
                <path fill={color[60]} d="M145.32,48.37l-3.81.18c-2-15.49-3.94-30.85-5.95-46.69l5-.48c1.58,15.72,3.14,31.14,4.74,47" transform="translate(0 0)" />

                <circle cx="157.47" cy="157.47" r="155.85" transform="translate(-0.4 314.54) rotate(-89.85)" fill="none" stroke="#003087" strokeWidth="3.24" className="circle" />
            </svg>
        </Container >
    );
};

export default CircleCount;