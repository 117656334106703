import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';

const Slide = styled.div`
    .box2 {
        padding-top: 28px;

        &.box2-1 img {
            margin-top: 10px;
            width: 51px;
        }

        &.box2-2 img {
            margin-top: 17px;
            width: 76px;
        }

        &.box2-3 img {
            margin-top: 12px;
            width: 71.5px;
        }

        .text2 {
            position: absolute;
            left: 0;
            top: 182px;
            width: 100%;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Johnson &amp; Johnson Vision:<br />
                <strong>Augengesundheit als Unternehmensmission</strong></h1>
            <Container left={170} top={277} width={459} height={113} className="rounded rounded-full color1 flex flex-all-center text-center">
                <p><strong>Von Prävention &amp;<br />
 Linderung …</strong></p>
            </Container>
            <Container left={794} top={277} width={459} height={113} className="rounded rounded-full color1 flex flex-all-center text-center">
                <p><strong>… über Korrektur &amp;<br /> Verbesserung …
</strong></p>
            </Container>
            <Container left={1418} top={277} width={459} height={113} className="rounded rounded-full color1 flex flex-all-center text-center">
                <p><strong>… bis hin zur<br />
Behandlung</strong></p>
            </Container>

            <Container left={100} top={434} width={600} height={640} className="bg1 color1 text-center box2 box2-1 fadeInFromTop">
                <Arrow width={424} className="container-top" />
                <p><strong>Augengesundheit:</strong><br />
                Angebot an Lösungen<br />
                und Tropfen</p>
                <img src={icon1} />
                <p className="text2">
                <strong>Trockene Augen:</strong><br />
Behandlung der<br /> 
Fehlfunktion der<br /> 
Meibom-Drüsen
                </p>
            </Container>
            <Container left={724} top={434} width={600} height={640} className="bg1 color1 text-center box2 box2-2 fadeInFromTop animate2">
                <Arrow width={424} className="container-top" />
                <p><strong>Fehlsichtigkeits-Korrektur:</strong><br />
Die weltweit meistverkaufte<br />
Kontaktlinse</p>
                <img src={icon2} />
                <p className="text2">
                <strong>Refraktive Chirurgie<br /> 
(LASIK):</strong><br />
Weltmarktführer im<br />
Bereich der refraktiven<br /> 
Chirurgie
                    </p>
            </Container>
            <Container left={1348} top={434} width={600} height={640} className="bg1 color1 text-center box2 box2-3 fadeInFromTop animate3">
                <Arrow width={424} className="container-top" />
                <p><strong>Grauer Star:</strong><br />
Führender Anbieter von<br />
Produkten zur Behandlung<br />
des Grauen Star und von<br />
Intraokularlinsen (IOL)</p>
                <img src={icon3} />
            </Container>

        </Slide>
    );
};
