import React from 'react';
import styled, { keyframes, css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

const breatheAnimation = keyframes`
    0% {
        transform: scale(1)
    }
    60% {
        transform: scale(1.2)
    }
    100% {  
        transform: scale(1)
    }
`

export const TouchBtnContainer = styled.svg`
    position: absolute;
    z-index: 100;
	width: 42px;
	height: 42px;
	cursor: pointer;

    &:not(.active) {
        animation: ${breatheAnimation} 2s infinite;
    }
 
    &.active {
        z-index: 300;
    }

    &.x-left {
        left: -21px
    }

    &.x-right {
        right: -21px
    }

    &.x-center {
        right: calc(50% - 21px)
    }

    &.y-bottom {
        bottom: -21px
    }

    &.y-center {
        top: calc(50% - 21px)
    }

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}
`

const MoreBtn = (props) => {
    const onClick = (event) => {
        event.stopPropagation();
        props.onClick();
    }

    return (  
        <TouchBtnContainer width="42" height="42" viewBox="0 0 82.79 82.79" className={props.className + (props.active ? ' active' : '')} style={props.style} left={props.left} top={props.top} onClick={onClick}>
           
           <g><path fill="#00a4e4" className="fill" d="M41.4,76.16A34.77,34.77,0,1,0,6.63,41.4,34.77,34.77,0,0,0,41.4,76.16" transform="translate(0 0)" />
                <path stroke="#00a4e4" className="stroke" d="M41.4,81.65A40.26,40.26,0,1,0,1.14,41.4,40.25,40.25,0,0,0,41.4,81.65Z" transform="translate(0 0)" fill="none" strokeWidth="2.28"/>
            </g>
            <g  transform="translate(16 16)">
    <path d="M33.36,60.93,50,54.23a1.1,1.1,0,0,0,.63-1.39l-.24-.64a.35.35,0,0,1,0-.3c3.34-4.37,1.06-10.19,1.06-10.19l-3.35-8.56a.08.08,0,0,0,0,0c-.06-.29-1-4.36-4-4.36a3.84,3.84,0,0,0-3.24,1.44.32.32,0,0,1-.58-.11c-.21-.87-.9-2-3-2.22a3.16,3.16,0,0,0-3.67,2,.32.32,0,0,1-.58,0c-.76-1.58-2.39-3.41-4.45-2.73a3.72,3.72,0,0,0-2.35,2.07.32.32,0,0,1-.6,0l-3.41-8.78s-1.65-5.27-5.07-4-1.58,5.82-1.58,5.82L23.4,43.14a.33.33,0,0,1-.47.4l-3.76-2.28,0,0c-.28-.23-4.26-3.62-6.77-1s.25,5.53.47,5.74l0,0L31.54,59.59l.1.12.32.67a1.09,1.09,0,0,0,1.4.55" transform="translate(0 0)" fill="#fff"/>
    <path d="M11.51,8.93A2.19,2.19,0,0,0,15.6,7.35L13.31,1.41A2.19,2.19,0,1,0,9.22,3Z" transform="translate(0 0)" fill="#fff"/>
    <path d="M1.59,12.58l5.82,2.59a2.16,2.16,0,0,0,.89.19,2.2,2.2,0,0,0,.89-4.2L3.37,8.57a2.19,2.19,0,1,0-1.78,4" transform="translate(0 0)" fill="#fff"/>
    <path d="M32.4,21.41l-5.82-2.59a2.19,2.19,0,0,0-1.78,4l5.82,2.59a2.37,2.37,0,0,0,.89.19,2.19,2.19,0,0,0,.89-4.2" transform="translate(0 0)" fill="#fff"/>
    <path d="M10.18,19.65a2.18,2.18,0,0,0-2.83-1.26L1.41,20.68A2.19,2.19,0,1,0,3,24.77l5.95-2.29a2.18,2.18,0,0,0,1.25-2.83" transform="translate(0 0)" fill="#fff"/>
    <path d="M23.81,14.34a2.18,2.18,0,0,0,2.83,1.26l6-2.29A2.19,2.19,0,1,0,31,9.22l-5.94,2.29a2.19,2.19,0,0,0-1.26,2.83" transform="translate(0 0)" fill="#fff"/>
    <path d="M12.58,32.4l2.59-5.82a2.19,2.19,0,0,0-4-1.78L8.58,30.62a2.19,2.19,0,0,0,1.11,2.9,2.33,2.33,0,0,0,.89.19,2.19,2.19,0,0,0,2-1.31" transform="translate(0 0)" fill="#fff"/>
    <path d="M19.94,10.3a2.19,2.19,0,0,0,2.89-1.11l2.59-5.82a2.19,2.19,0,1,0-4-1.78L18.82,7.41a2.19,2.19,0,0,0,1.12,2.89" transform="translate(0 0)" fill="#fff"/>
  </g>
        </TouchBtnContainer>
    );
};

export default MoreBtn;