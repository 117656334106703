import React from 'react';
import styled, { css } from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { CircleCountSimple } from 'components/parts';
import {  useCounter } from 'components/hooks'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.png'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .count {
        font-size: 66px;
        line-height: 1;
        color: #fff;
        font-weight: 400;
    }

    .container-animate-2 {
        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}
    }

    .container-animate-3 {
        animation-play-state: paused;

        ${({ step }) => step >= 3 && css`
            animation-play-state: running;
        `}
    }

    .text1 {
        line-height: 27.5px;
    }

`

const Folder2Slide1 = ({ ...props }) => {

    const count1 = useCounter(0, 30, props.active, props.preview, 0)
    const count2 = useCounter(0, 92, props.active && props.step >= 2, props.preview, 0)
    const count3 = useCounter(0, 70, props.active && props.step >= 3, props.preview, 0)

    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered" preview={props.preview} active={props.active} step={props.step}>
                <h1>Einbußen bei Tragekomfort und<br />
                    Sehqualität führen potenziell zum<br />
                    <strong>Abbruch der Kontaktlinsennutzung</strong></h1>

                <CircleCountSimple left={206} top={496} percent={30} active={props.active && props.step >= 1} preview={props.preview} />
                <Container left={206} top={496} width={398} height={398} className="count flex-all-center text-center">{count1} %</Container>

                <Container left={84} top={938} width={650} as="p" className="text1 text-center">
                    der aktuellen<br />
                    Kontaktlinsenträger<br />
                    sind 40 oder älter.<sup>#3</sup>
                </Container>

                <div className="container-animate container-animate-2 fadeIn">

                    <CircleCountSimple left={843} top={496} percent={92} active={props.active && props.step >= 2} preview={props.preview} />
                    <Container left={843} top={496} width={398} height={398} className="count flex-all-center text-center">{count2} %</Container>

                    <Container left={793} top={938} width={500} as="p" className="text1 text-center">
                        gehen davon aus,<br />
                        weiterhin Kontaktlinsen<br />
                        zu tragen.<sup>#3</sup>
                    </Container>

                </div>

                <div className="container-animate container-animate-3 fadeIn">

                    <CircleCountSimple left={1472} top={496} percent={70} active={props.active && props.step >= 3} preview={props.preview} />
                    <Container left={1472} top={496} width={398} height={398} className="count flex-all-center text-center">{count3} %</Container>

                    <Container left={1387} top={938} width={560} as="p" className="text1 text-center">
                        der Linsenträger ab 45<br />
                        geben das Tragen von<br />
                        Linsen mit beginnender<br />
                        Alterssichtigkeit auf.<sup>#3</sup>
                    </Container>

                </div>

            </Slide>
        </>

    );
};

export default Folder2Slide1;