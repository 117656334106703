import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import logo1 from './assets/logo1.svg';
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';

const Slide = styled.div`
    .circle1 {
        position: absolute;
        left: 143px;
        top: 172.5px;
        width: 163px;
        height: 163px;
        border-radius: 50%;
        background: #003087;
    }

    .logo1 {
        position: absolute;
        left: 152px;
        top: 228px;
        width: 146px;
    }

    .box1 .number {
        position: absolute;
        display: block;
        width: 70px;
        text-align: right;
        left: -56px;
        top: -23px;
        font-size: 65px;
        line-height: 65px;
        color: #E3000F;
        font-style: italic;
        text-align: right;
    }

    .box2 {
        padding-top: 25px;
    } 
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Warum Johnson &amp; Johnson Vision als Partner?</strong></h1>
            <Container as="p" left={100} top={196} width={800} className="small"> So unterstützen wir Sie:</Container>

            <div className="circle1"></div>
            <img src={logo1} className="logo1" />
            <Image left={860} top={345} width={326} height={326} src={img1} className="border rounded" />
            <Image left={1452} top={345} width={326} height={326} src={img2} className="border rounded" />

            <Container left={233} top={554} width={435} height={170} className="rounded rounded-full color1 flex flex-all-center text-center box1">
                <span className="number">1.</span>
                <p><strong>Kontaktlinsen<br />
Portfolio</strong></p>
            </Container>
            <Container left={806} top={554} width={435} height={170} className="rounded rounded-full color1 flex flex-all-center text-center box1">
                <span className="number">2.</span>
                <p><strong>Professionelle<br />
Unterstützung</strong></p>
            </Container>
            <Container left={1399} top={554} width={435} height={170} className="rounded rounded-full color1 flex flex-all-center text-center box1">
                <span className="number">3.</span>
                <p><strong>Zielgerichtete<br />
                Kampagnen</strong></p>
            </Container>

            <Container left={190} top={768} width={521} height={430} className="bg1 box2 color1 text-center fadeInFromTop">
                <Arrow width={447} className="container-top" />
                <p><strong>ACUVUE® –</strong><br /> 
ausgezeichnet als die<br /> 
<strong>vertrauenswürdigste<br />
Marke</strong> von Optikern<br /> 
in Deutschland.<sup>*13</sup></p>
            </Container>
            <Container left={763} top={768} width={521} height={430} className="bg1 box2 color1 text-center fadeInFromTop animate2">
                <Arrow width={447} className="container-top" />
                <p>Unsere ACUVUE®<br /> 
Account Manager &amp;<br /> 
unsere Training-Teams ar-<br />
beiten <strong>kundenorientiert<br />
und unterstützen Sie bei<br />
Ihrer Fortbildung und<br />
die Ihres Teams.</strong></p>
            </Container>
            <Container left={1355} top={768} width={521} height={430} className="bg1 box2 color1 text-center fadeInFromTop animate3">
                <Arrow width={447} className="container-top" />
                <p>Werben Sie mit<br />
<strong>starken und kreativen<br /> 
Kampagnen, um Ihre<br />
Kunden für<br />
Kontaktlinsen zu<br /> 
begeistern.</strong></p>
            </Container>
        </Slide>
    );
};
