import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';
import img6 from './assets/img6.jpg';

const Slide = styled.div`
    
    .box1 {
        padding: 40px 10px 10px 12px;
    }
    
    .box1 .number {
        position: absolute;
        display: block;
        left: -10px;
        top: -68px;
        font-size: 97.5px;
        line-height: 122px;
        font-style: italic;
        text-align: right;
    }
    
    .box2 {
        transform: rotate(7deg);
        letter-spacing: -0.02rem;
    }

    .overlay1-1 {
        > div {
            overflow: hidden;
            padding-top: 36px;
            padding-right: 315px;
        }

        img {
            position: absolute;
            right: 0px;
            top: 0;
            height: 100%;
        }
    }

    .overlay1-2 {
        > div {
            overflow: hidden;
        }

        img {
            position: absolute;
            right: 0px;
            top: 0;
            height: 100%;
        }

        .count {
            font-size: 53px;
            line-height: 53px;
        }
    }

    .overlay1-3 {
        .count {
            font-size: 53px;
            line-height: 53px;
        }
    }

    .overlay1-4 {
        .box2-1 {
            background-image: url(${img6});
            background-size: 703px 239px;
            background-repeat: no-repeat;
            background-position: center bottom;
        }

        .info {
            font-size: 10px;
            line-height: 11px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Warum Sie Ihren Kunden <strong>multifokale Linsen anbieten sollten</strong></h1>

            <Image left={1565} top={235} width={524} height={469} src={img3} />

            <Container left={1525} top={350} width={544} height={630} as="p" className="color1 text-center box2">
                <strong> Was hält Sie<br />
                    aktuell davon ab,<br />
                    mehr mit multifokalen Kontaktlinsen zu<br />
                    arbeiten?</strong>

            </Container>


            <Container left={1224} top={430} width={344} height={630} className="box1 bg1 color1 fadeInFromLeft animate3">
                <span className="number color3">4</span>
                <p>Multifokale Kontaktlinsen-
                    Träger sind wertvolle
                    Kunden für Ihr Geschäft.</p>

                <Image left={82} top={422} width={172} height={183} src={img2} />

            </Container>

            <Container left={850} top={430} width={344} height={630} className="box1 bg1 color1 fadeInFromLeft animate2">
                <span className="number color3">3</span>
                <p>Bestehende Träger in der Kategorie zu halten und
                    auf multifokale Kontaktlinsen umzustellen, bietet großes Wachstums-
                    potenzial</p>
                <Arrow left={340} top={60} width={520} className="" bgColor={'#d8f2ef'} arrowColor={'#00a98f'} />

            </Container>

            <Container left={476} top={430} width={344} height={630} className="box1 bg1 color1 fadeInFromLeft">
                <span className="number color3">2</span>
                <p>Es gibt mehr Menschen
                    mit Bedarf an Sehkorrektion als Kontakt-
                    linsenträger</p>
                <Arrow left={340} top={60} width={520} className="" bgColor={'#d8f2ef'} arrowColor={'#00a98f'} />

            </Container>

            <Container left={100} top={430} width={344} height={630} className="box1 bg1 color1">
                <span className="number color3">1</span>
                <p>Die presbyope Kundengruppe profitiert von den Vorzügen multifokaler Kontaktlinsen</p>
                <Arrow left={340} top={60} width={520} className="" bgColor={'#d8f2ef'} arrowColor={'#00a98f'} />
                <Image left={24} top={440} width={288} height={165} src={img1} />

            </Container>

            <MoreBtn
                left={230} top={1018}
                className=""
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')} />

            <MoreBtn
                left={606} top={1018}
                className="fadeInFromLeft"
                active={state.overlay1 === 'overlay2'}
                onClick={() => toggleOverlay('overlay2')} />

            <MoreBtn
                left={980} top={1018}
                className="fadeInFromLeft animate2"
                active={state.overlay1 === 'overlay3'}
                onClick={() => toggleOverlay('overlay3')} />

            <MoreBtn
                left={1357} top={1018}
                className="fadeInFromLeft animate3"
                active={state.overlay1 === 'overlay4'}
                onClick={() => toggleOverlay('overlay4')} />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg overlay1 overlay1-1">
                <Container left={100} top={432} width={1466} height={628} className="box2-1 bg2 color1 border text-center">
                    <p><strong>Die Menükarte einfach erkennen?<br />
                        Digitale Inhalte klar sehen?</strong><br /><br />

                        Viele Presbyope wünschen sich im<br />
                        Alltag auch mal ohne Brille scharf<br />
                        sehen zu können.<br /><br />

                        In vielen Bereichen des Lebens<br />
                        kann die Zielgruppe von den Vorteilen<br />
                        multifokaler Kontaktlinsen profitieren.</p>
                    <img src={img4} />
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="with-bg overlay1 overlay1-2">
                <Container left={100} top={432} width={1466} height={628} className="box2-1 bg2 color1 border text-center">
                    <img src={img5} />
                </Container>

                <Container left={107} top={494} width={1100} className="color1 text-center">
                    <p><strong>Insgesamt <span className="count">8,7</span>Mio. Menschen ​über 45 Jahre</strong><br />
                        sind offen für Kontaktlinsen und interessieren sich<br />
                        für diese Form der Sehkorrektion.</p>
                </Container>

                <Container left={107} top={785} width={1100} className="color1 text-center">
                    <p><strong>Doch nur <span className="count">1,2</span>% der Menschen ab 45 Jahren,</strong><br />
                        die eine Sehkorrektion benötigen, tragen<br />
                        multifokale Kontaktlinsen.<sup>1</sup></p>
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay3'} className="with-bg overlay1 overlay1-3">
                <Container left={100} top={432} width={1466} height={628} className="box2-1 bg2 color1 border text-center">

                </Container>

                <Container left={100} top={483} width={1466} className="color1 text-center">
                    <p><strong><span className="count">70</span>% der Träger über 45 Jahre steigen<br />
                        mit beginnender Alterssichtigkeit aus der Kategorie aus.<sup>1</sup></strong></p>
                </Container>

                <Container left={100} top={724} width={1466} className="color1 text-center color3">
                    <p><strong>Dies bedeutet ein offenes Potenzial von <span className="count color3">1,4</span> Mio. Träger*innen</strong></p>
                </Container>

                <Container left={100} top={864} width={1466} className="color1 text-center">
                    <p><strong>Verhindern Sie, dass bestehende Kontaktlinsenkunden ab Mitte 40<br />
                        mit dem Tragen von Linsen aufhören. </strong></p>
                </Container>

                <Arrow left={816} top={100} width={1214} className="rotate90" arrowColor={'#00a98f'} />

            </Overlay>

            <Overlay active={state.overlay1 === 'overlay4'} className="with-bg overlay1 overlay1-4">
                <Container left={100} top={432} width={1466} height={628} className="box2-1 bg2 color1 border text-center">

                </Container>

                <Container as="p" left={100} top={476} width={1466} className="color1 text-center">
                    <strong>Multifokale Linsenträger geben jährlich<br />
                        im Schnitt 2,6x mehr für ihre Sehkorrektion aus</strong><br />
                    als Presbyope, die nur Brille tragen.<sup>2</sup>

                </Container>




            </Overlay>


        </Slide>
    );
};
