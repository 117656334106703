import React from 'react';
import styled from 'styled-components'

import bg from './assets/bg.jpg'

const Slide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: -40px;
        font-size: 42px;
        line-height: 55px;
        color: #fff;

        strong {
            font-weight: 600;
            text-transform: uppercase;
        }
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <div>
                    <h1 style={{ color: '#fff' }}>
                        <strong>Unsere Zukunft</strong><br />
                        gemeinsam erfolgreich<br />
                        <strong>gestalten – 2024</strong>
                    </h1>
                </div>
            </Slide>
        </>

    );
};

export default Folder2Slide1;