import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useCounter } from 'components/hooks'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.png'

import img2_1 from './assets/img2_1.svg'
import img2_2 from './assets/img2_2.svg'
import img2_3 from './assets/img2_3.svg'
import img2_4 from './assets/img2_4.svg'


const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 9px;
        line-height: 12.5px;
    }

    .box1 {
        padding-left: 72px;
        padding-top: 4px;
        font-size: 22px;
        line-height: 22px;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 55px;
        }

    }

    .text2 {
        font-size: 25px;
        line-height: 32.5px;
    }

   
`

const Folder2Slide1 = ({ ...props }) => {

    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Die Augen ihrer Kunden brauchen mehr –<br />
                    <strong>MAX</strong> Tragekomfort. <strong>MAX</strong> Sehqualität.<sup>@13</sup></h1>
                <Image left={968} top={852} width={980} height={415} src={img1} />
                <Container left={1028} top={1203} width={858} as="p" className="text1 text-center">
                    Nur zu Illustrationszwecken
                </Container>

                <Container left={68} top={481} width={858} className="box1 fadeIn">
                    <img src={img2_1} />
                    <p>Klare Sehqualität bei<br />
                        allen Lichtverhältnissen<sup>13</sup></p>
                </Container>

                <Container left={744} top={481} width={600} className="box1 fadeIn animate2">
                    <img src={img2_2} />
                    <p>Tagesend-<br />
                        Komfort<sup>13</sup></p>
                </Container>

                <Container left={68} top={635} width={858} className="box1 fadeIn animate3">
                    <img src={img2_3} />
                    <p>UV-Schutz<sup>§10</sup><br />
                        100% UV-B, 99.9% UV-A</p>
                </Container>

                <Container left={744} top={635} width={600} className="box1 fadeIn animate4">
                    <img src={img2_4} />
                    <p>Hervorragende<br />
                        Handhabung<sup>13@</sup></p>
                </Container>

                <Container left={28} top={855} width={1164} as="p" className="text2 text-center fadeIn animate5">
                    Passen Sie Ihren Kunden<br />
                    <strong>ACUVUE® OASYS MAX 1-Day</strong><br />
                    an und bereiten Sie sie auf ein<br />
                    MAX-Seherlebnis vor
                </Container>


            </Slide>
        </>

    );
};

export default Folder2Slide1;