import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

import bg from './assets/bg.svg';

import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';
import icon5 from './assets/icon5.svg';
import icon6 from './assets/icon6.svg';


const Slide = styled.div`
    .box1 {
        padding-top: 22px;
    }

    .number {
        position: absolute;
        width: 100px;
        top: 10px;
        left: -70px;
        font-size: 58px;
        font-weight: 600;
        line-height: 72px;
        font-style: italic;
        text-align: right;
    }

    .box4 > div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        margin-left: 75px;
        width: 700px;

        > div {    
            position: relative;
            flex: 0 0 337px;
            margin-bottom: 26px;
            height: 66px;
            padding-left: 57px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            font-weight: 500;
            text-transform: uppercase;

            > img {
                position: absolute;
                height: 65.5px;
                left: 0;
                top: 0;
            }

            .icon4 {
                height: 75px;
                top: -10px;
            }

            h3 {
                font-weight: 500;
                font-size: 19.5px;
                line-height: 23.5px;
                color: #002C8B;     
            }

            p {
                font-size: 14px;
                line-height: 16px;
                color: #00A5E3;
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Warum <strong>ACUVUE® Kontaktlinsen?</strong></h1>
            <Image left={291} top={200} width={326} height={326} src={img1} className="border rounded" />
            <Image left={1071} top={200} width={326} height={326} src={img2} className="border rounded" />

            <Image left={673} top={702} width={326} height={326} src={img3} className="border rounded" />
            <Image left={1453} top={702} width={326} height={326} src={img4} className="border rounded" />

            <Image left={193} top={452} width={1684} height={773} src={bg} />

            <Container left={193} top={455} width={521} height={217} className="color1 box1 text-center">
                <span className="number">1.</span>
                <p><strong>EYE-INSPIRED™<br />
Innovationen<sup>14</sup></strong></p>
            </Container>

            <Container left={973} top={455} width={521} height={217} className="color1 box1 text-center">
                <span className="number">2.</span>
                <p><strong>Loyale Träger<br />
gegenüber dem<br />
Fachhandel</strong></p>
            </Container>

            <Container left={576} top={957} width={521} height={217} className="color1 box1 text-center">
                <span className="number">3.</span>
                <p><strong>Starker<br />
Werterhalt<br />
im Markt</strong></p>
            </Container>

            <Container left={1356} top={957} width={521} height={269} className="color1 box1 text-center">
                <span className="number">4.</span>
                <p><strong>Attraktive Marge<br />
durch ein<br />
anpasserzentriertes<br />
Konditionenmodell</strong></p>
            </Container>

            <MoreBtn
                left={413} top={630}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')}
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                <Container left={419} top={640} width={1494} height={612} className="rounded box4">
                    <div>
                        <div className>
                            <img src={icon1} />
                            <h3>Integrierter Benetzungswirkstoff</h3>
                            <p>Für mehr Feuchtigkeit</p>
                        </div>
                        <div>
                            <img src={icon4} className="icon4" />
                            <h3>UV Schutz</h3>
                            <p>Für Augengesundheit</p>
                        </div>
                        <div>
                            <img src={icon3} />
                            <h3>Superdünner Rand</h3>
                            <p>Für höchsten Tragekomfort</p>
                        </div>
                        <div>
                            <img src={icon5} />
                            <h3>Lidschlag-Stabilisations-deSign</h3>
                            <p>Für Astigmatismus</p>
                        </div>
                        <div>
                            <img src={icon2} />
                            <h3>Antioxidantien</h3>
                            <p>Für mehr Komfort</p>
                        </div>
                        <div>
                            <img src={icon6} />
                            <h3>Pupillen optimiert</h3>
                            <p>Für multifokale Sicht</p>
                        </div>
                       
                       
                    </div>
                </Container>
            </Overlay>


        </Slide>
    );
};
