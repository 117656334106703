import React from "react";
import styled from "styled-components";

import bg from "./assets/bg.jpg";

const Slide = styled.div`
  background: url(${bg});
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    margin-top: 112px;
    font-size: 62px;
    line-height: 73px;
    color: #fff;

    > strong,
    > span {
      display: block;
    }

    span {
      margin-top: 10px;
      font-size: 35px;
      line-height: 41px;
    }
  }
`;

const Folder2Slide1 = () => {
  return (
    <Slide className="bg full">
      <h1 style={{ color: "#fff" }}>
        <strong>
          Multifokal
          <br />
          Kampagne 2024
        </strong>
        <span>
          Alterssichtigkeit
          <br />
          geht auch anders.
        </span>
      </h1>
    </Slide>
  );
};

export default Folder2Slide1;
