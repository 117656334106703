import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'
import { useSlideState, useCounter } from 'components/hooks'
import { formatThousands } from 'components/helper'

import img1 from '../slide4/assets/img1.jpg';

const Slide = styled.div`
   .box1 {
        border-radius: 7.5px;
        align-items: center;
        justify-content: flex-start;
        
        .count,
        p {
            color: #fff;
        }

        .count {
            flex: 0 0 166px;
            font-size: 40px;
            line-height: 48px;
            text-align: right;
        }

        p {
            margin-left: 9px;
            font-size: 14px;
            line-height: 17px;
        }
    }

    .box1-3,
    .box1-4 {
        background: #003087 !important;

        .count {
            flex: 0 0 182px;
        }
    }

    .box2 .count { 
        font-size: 28.5px;
        line-height: 34px;
    }

    .box2 p {
        font-size: 14px;
        line-height: 20px;
        margin-top: -6px;
    }

    .box3 .count {
        font-size: 62px;
        line-height: 77.5px;
    }

    .box3,
    .box4 {
        animation-play-state: paused;

        ${({ step }) => step !== 1 && css`
            animation-play-state: running;
        `}

        ${({ preview }) => preview && css`
            display: none;
        `}
    }

    .container-animate1 {
        ${({ active, step }) => (active && step !== 1) && css`
            display: none;
        `}

       
    }
`

const inititialState = {
    overlay1: false
}

export default ({ ...props }) => {
    const { state } = useSlideState({ inititialState, active: props.active })

    const count1 = useCounter(0, 61000, props.active, props.preview)
    const count2 = useCounter(0, 526000, props.active, props.preview)
    const count3 = useCounter(0, 13000, props.active, props.preview)

    const count4 = useCounter(0, 432000, props.active, props.preview)
    const count5 = useCounter(0, 2228000, props.active, props.preview)
    const count6 = useCounter(0, 322000, props.active, props.preview)

    const count7 = useCounter(0, 2, (props.active && props.step==2), false, 0 , 0 , 400)
    const count8 = useCounter(0, 6, (props.active && props.step==2), false, 0 , 0 , 200);

    console.log(props.step)

    return (
        <Slide className="centered" step={props.step} preview={props.preview} active={props.active}>
            <h1>Welches System für <strong>mehr Kundenzufriedenheit?</strong></h1>
            <Container left={528} top={236} width={992} height={120} className="rounded flex-all-center text-center color1">
                <p><strong>Weniger Aussteiger = Kundenzufriedenheit</strong></p>
            </Container>


            <div className="container-animate container-animate1 fadeInFromTop">
                <Container left={100} top={418} width={874} height={577} className="bg1">
                    <Arrow width={770} className="container-top" />
                    <Image left={49} top={158} width={800} height={232} src={img1} />
                </Container>
                <Container left={125} top={463} width={737} height={128} className="bg2 flex box1">
                    <span className="count">{formatThousands(count1)}</span>
                    <p>jährliche Neuträger<br />
                im Tageslinsenbereich<sup>13</sup></p>
                </Container>

                <Container left={212} top={828} width={737} height={128} className="bg2 flex box1">
                    <span className="count" style={{ flexBasis: '155px' }}>{formatThousands(count3)}</span>
                    <p>jährliche Gesamt-Aussteiger<br />
im Tageslinsenbereich<sup>13</sup></p>
                </Container>
                <Container left={354} top={683} width={365} height={122} className="rounded text-center box2 flex-all-center">
                <span className="count">{formatThousands(count2)}</span>
                <p className="small">Tageslinsenträger</p>
            </Container>
            </div>

            <div className="container-animate container-animate1 fadeInFromTop">
                <Container left={1074} top={418} width={874} height={577} className="bg1">
                    <Arrow width={770} className="container-top" />
                    <Image left={49} top={158} width={800} height={232} src={img1} />
                </Container>

                <Container left={1109} top={463} width={737} height={128} className="bg2 flex box1 box1-3">
                    <span className="count">{formatThousands(count4)}</span>
                    <p>jährliche Neuträger im<br />
                Austauschlinsenbereich</p>
                </Container>

                <Container left={1146} top={828} width={778} height={128} className="bg2 flex box1 box1-4">
                    <span className="count">{formatThousands(count6)}</span>
                    <p>jährliche Gesamt-Aussteiger<br />
im Austauschlinsenbereich</p>
                </Container>
                <Container left={1327} top={683} width={365} height={122} className="rounded text-center box2 flex-all-center">
                <span className="count">{formatThousands(count5)}</span>
                <p className="small">Austauschlinsenträger</p>
            </Container>
            </div>

            <Container left={100} top={418} width={874} height={577} className="bg1 flex-all-center text-center box3 color1 fadeInFromTop animateNoDelay">
                <Arrow width={770} className="container-top" />
                <Arrow width={770} className="container-bottom" bgColor={'#CFF2FB'} />
                <span className="count">{count7}/10</span>
                <p>steigen aus<br />
- Segment Tageslinse -</p>
            </Container>

            <Container left={1074} top={418} width={874} height={577} className="bg1 flex-all-center text-center box3 color1 fadeInFromTop animateNoDelay">
                <Arrow width={770} className="container-top" />
                <span className="count">{count8}/10</span>
                <p>steigen aus<br />
- Segment Monatslinse -</p>
            </Container>

            <Container left={100} top={1057} width={1442} height={119} className="rounded text-center box4 flex-all-center color1 fadeIn animate2 animateNoDelay">
                <p><strong>Signifikant weniger Aussteiger bei Eintageslinsenträgern,<br />
höhere Kundenzufriedenheit und mehr Effizienz in der Anpassung.</strong></p>
            </Container>

        </Slide>
    );
};
