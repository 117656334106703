import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { logout } from "actions";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";

import { Image, MoreBtn } from "components/parts";
import { MoreBtnContainer } from "components/parts/MoreBtn";

import Slide from "components/pages/folders/Slide";

import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.jpg";
import img3 from "./assets/img3.jpg";
import img4 from "./assets/img4.jpg";
import img5 from "./assets/img5.jpg";
import img6 from "./assets/img6.jpg";
import img8 from "./assets/img8.jpg";
import Custom from "./assets/Custom.svg";

import img2_1 from "./assets/img2_1.jpg";
import img2_2 from "./assets/img2_2.jpg";
import img2_3 from "./assets/img2_3.jpg";
import img2_4 from "./assets/img2_4.jpg";
import img2_5 from "./assets/img2_5.jpg";
import img8_0 from "./assets/img8_0.jpg";
import img8_1 from "./assets/img8_1.jpg";
import img8_2 from "./assets/img8_2.jpg";

import img8_7 from "./assets/img8_7.jpg";
import img8_8 from "./assets/img8_8.jpg";

const teaser = [
  {
    title: "Über<br />Johnson &amp; Johnson<br />Vision",
    target: "/folders/wir-sind-johnson-%26-johnson",
    img: img1,
  },
  {
    title: "ACUVUE®<br />Kontaktlinsen",
    target: "/overview/kontaktlinsen",
    img: img2,
  },
  {
    title: "Zufriedene<br />Kunden",
    target: "/folders/zufriedene-kunden",
    img: img3,
  },
  {
    title: "Wirtschaftlichkeit",
    target: "/folders/wirtschaftlichkeit",
    img: img4,
  },
  {
    title: "Neue Trends",
    target: "/folders/trends",
    img: img5,
  },
  {
    title: "Unsere<br /> Unterstützung<br />für Sie",
    target: "/folders/unsere-unterstuetzung",
    img: img6,
  },
  {
    title: "Eigene<br />Folder",
    target: "/custom",
    img: Custom,
  },
];

/*

  {
    title: "Aktuelle<br />Kampagnen",
    target: "/overview/kampagnen",
    img: img8,
  },*/

const Overview = styled.div`
  overflow: hidden;

  .title {
    display: flex;
    height: 235px;
    margin-top: 57px;
    align-items: center;
    font-size: 42px;
    line-height: 48px;
    font-weight: 600;
    margin-left: 75px;
    color: #003087;

    &.temp {
      position: absolute;
      top: 282px;
      left: 252px;
      width: 400px;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    width: 1018px;
    margin-left: 75px;
    margin-top: 57px;

    > * {
      position: relative;
      flex: 0 0 176px;
      height: 292px;
      margin-right: 58px;

      &.deactive {
        opacity: 0.2;
      }

      > .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 176px;
        height: 176px;
        border-radius: 50%;
      }

      > a {
        position: absolute;
        z-index: 101;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      > p {
        position: absolute;
        top: 205px;
        left: -20%;
        width: 140%;
        text-align: center;
      }

      &.teaser1 {
        .bg {
          background: #c20016;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #c20016;
          }
          .stroke {
            stroke: #c20016;
          }
        }
      }

      &.teaser2 {
        .bg {
          background: #003087;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #003087;
          }
          .stroke {
            stroke: #003087;
          }
        }
      }

      &.teaser3 {
        .bg {
          background: #00a3e0;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #00a3e0;
          }
          .stroke {
            stroke: #00a3e0;
          }
        }
      }

      &.teaser4 {
        .bg {
          background: #a7a9ac;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #a7a9ac;
          }
          .stroke {
            stroke: #a7a9ac;
          }
        }
      }

      &.teaser5 {
        .bg {
          background: #df9933;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #df9933;
          }
          .stroke {
            stroke: #df9933;
          }
        }
      }

      &.teaser6 {
        .bg {
          background: #009b81;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #009b81;
          }
          .stroke {
            stroke: #009b81;
          }
        }
      }

      &.teaser7 {
        .bg {
          background: #c7e3f9;
        }

        .rounded {
          background-color: #fff;
          background-size: 90px 90px;
          background-position: center center;
          border-color: #c7e3f9;
        }

        ${MoreBtnContainer} {
          .fill {
            fill: #c7e3f9;
          }
          .stroke {
            stroke: #c7e3f9;
          }
        }
      }

      &.teaser8 {
        .bg {
          background: #003087;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #003087;
          }
          .stroke {
            stroke: #003087;
          }
        }
      }

      &.back {
        opacity: 0.5;

        .bg {
          background: #003087;
          width: 576px;
          border-radius: 88px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #003087;
          }
          .stroke {
            stroke: #003087;
          }
        }
      }

      /* */

      &.teaser2_1 {
        .bg {
          background: #008ee2;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #008ee2;
          }
          .stroke {
            stroke: #008ee2;
          }
        }
      }

      &.teaser2_2 {
        .bg {
          background: #fbe126;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #fbe126;
          }
          .stroke {
            stroke: #fbe126;
          }
        }
      }

      &.teaser2_3 {
        .bg {
          background: #009ca6;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #009ca6;
          }
          .stroke {
            stroke: #009ca6;
          }
        }
      }

      &.teaser2_4 {
        .bg {
          background: #2ca88f;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #2ca88f;
          }
          .stroke {
            stroke: #2ca88f;
          }
        }
      }

      &.teaser2_5 {
        .bg {
          background: #ff7641;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #ff7641;
          }
          .stroke {
            stroke: #ff7641;
          }
        }
      }

      /* */

      &.teaser8_0 {
        .bg {
          background: #009da8;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #009da8;
          }
          .stroke {
            stroke: #009da8;
          }
        }
      }

      &.teaser8_1 {
        .bg {
          background: #2ca88f;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #2ca88f;
          }
          .stroke {
            stroke: #2ca88f;
          }
        }
      }

      &.teaser8_2 {
        .bg {
          background: #003087;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #003087;
          }
          .stroke {
            stroke: #003087;
          }
        }
      }

      &.teaser8_7 {
        .bg {
          background: #3a8dde;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #3a8dde;
          }
          .stroke {
            stroke: #3a8dde;
          }
        }
      }

      &.teaser8_8 {
        .bg {
          background: #ffb900;
        }
        ${MoreBtnContainer} {
          .fill {
            fill: #ffb900;
          }
          .stroke {
            stroke: #ffb900;
          }
        }
      }
    }
  }
`;

const Header = styled.div`
  position: absolute;
  z-index: 1;
  width: 1024px;
  top: 0;
  left: 50%;
  margin-left: -512px;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;

  span {
    cursor: pointer;
  }
`;

const Index = ({ level, logout }) => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const { folder } = useParams();

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Slide dimensions={dimensions} folder={folder}>
      {level === "admin" && (
        <Header>
          <NavLink to="/users/">Benutzerverwaltung</NavLink>
          <span onClick={() => logout()}>Ausloggen</span>
        </Header>
      )}
      <Overview className="centered">
        {!folder && (
          <div className="list">
            {teaser.map((teaser, index) => {
              return (
                <div
                  className={
                    "teaser" + (index + 1) + (teaser.target ? "" : " deactive")
                  }
                  key={index}
                >
                  {teaser.target && (
                    <>
                      <Link to={teaser.target} />
                      <div className="bg" />
                      <Image
                        left={13.6}
                        top={13.6}
                        width={326}
                        height={326}
                        src={teaser.img}
                        className="rounded border"
                      />
                      <p className="color1">
                        <strong
                          dangerouslySetInnerHTML={{ __html: teaser.title }}
                        ></strong>
                      </p>
                      <MoreBtn left={132} top={298} />
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {folder === "kontaktlinsen" && (
          <>
            <div className="title temp">Wählen Sie eine Kategorie</div>
            <div className="list">
              <div className="teaser2_1">
                <Link to="/folders/max-family" />
                <div className="bg" />
                <Image
                  left={13.6}
                  top={13.6}
                  width={326}
                  height={326}
                  src={img2_1}
                  className="rounded border"
                />
                <p className="color1">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: "ACUVUE® OASYS MAX<br />1-Day Familie",
                    }}
                  ></strong>
                </p>
                <MoreBtn left={132} top={298} />
              </div>
              <div className="teaser2_2">
                <Link to="/folders/max-multifocal" />
                <div className="bg" />
                <Image
                  left={13.6}
                  top={13.6}
                  width={326}
                  height={326}
                  src={img2_2}
                  className="rounded border"
                />
                <p className="color1">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: "ACUVUE® OASYS MAX<br />1-Day MULTIFOCAL",
                    }}
                  ></strong>
                </p>
                <MoreBtn left={132} top={298} />
              </div>
              <div className="teaser2_3">
                <Link to="/folders/familie" />
                <div className="bg" />
                <Image
                  left={13.6}
                  top={13.6}
                  width={326}
                  height={326}
                  src={img2_3}
                  className="rounded border"
                />
                <p className="color1">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: "ACUVUE® OASYS<br />1-DAY Familie",
                    }}
                  ></strong>
                </p>
                <MoreBtn left={132} top={298} />
              </div>
              <div className="teaser2_4">
                <Link to="/folders/multifocal" />
                <div className="bg" />
                <Image
                  left={13.6}
                  top={13.6}
                  width={326}
                  height={326}
                  src={img2_4}
                  className="rounded border"
                />
                <p className="color1">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: "ACUVUE® MULTIFOCAL<br />Kontaktlinsen",
                    }}
                  ></strong>
                </p>
                <MoreBtn left={132} top={298} />
              </div>
              <div className="teaser2_5">
                <Link to="/folders/torische-linsen" />
                <div className="bg" />
                <Image
                  left={13.6}
                  top={13.6}
                  width={326}
                  height={326}
                  src={img2_5}
                  className="rounded border"
                />
                <p className="color1">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: "ACUVUE® for<br />ASTIGMATISM<br />Kontaktlinsen",
                    }}
                  ></strong>
                </p>
                <MoreBtn left={132} top={298} />
              </div>

              <div></div>
              <div></div>
              <div className="back">
                <Link to="/" />
                <div className="bg" />
                <Image
                  left={13.6}
                  top={13.6}
                  width={326}
                  height={326}
                  src={img2}
                  className="rounded border"
                />
                <p className="color1">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: "ACUVUE®<br />Kontaktlinsen",
                    }}
                  ></strong>
                </p>
              </div>
            </div>
          </>
        )}
        {folder === "kampagnen" && (
          <>
            <div className="title temp">Wählen Sie eine Kategorie</div>
            <div className="list">
              <div className="teaser8_8">
                <Link to="/folders/multifocal-2024" />
                <div className="bg" />
                <Image
                  left={13.6}
                  top={13.6}
                  width={326}
                  height={326}
                  src={img8_8}
                  className="rounded border"
                />
                <p className="color1" style={{ color: "#051f4a" }}>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: "Multifokal<br />Kampagne 2024",
                    }}
                  ></strong>
                </p>
                <MoreBtn left={132} top={298} />
              </div>
              <div className="teaser8_7">
                <Link to="/folders/konditionsvereinbarung" />
                <div className="bg" />
                <Image
                  left={13.6}
                  top={13.6}
                  width={326}
                  height={326}
                  src={img8_7}
                  className="rounded border"
                />
                <p className="color1" style={{ letterSpacing: "-1.2px" }}>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: "Konditionsvereinbarung<br />2024",
                    }}
                  ></strong>
                </p>
                <MoreBtn left={132} top={298} />
              </div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>

              <div className="back">
                <Link to="/" />
                <div className="bg" />
                <Image
                  left={13.6}
                  top={13.6}
                  width={326}
                  height={326}
                  src={img8}
                  className="rounded border"
                />
                <p className="color1">
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: "Aktuelle<br />Kampagnen",
                    }}
                  ></strong>
                </p>
              </div>
            </div>
          </>
        )}
      </Overview>
    </Slide>
  );
};

/*
<div className="teaser8_0">
                                <Link to="/folders/die-grosse-mach-es-jetzt-aktion" />
                                <div className="bg" />
                                <Image left={13.6} top={13.6} width={326} height={326} src={img8_0} className="rounded border" />
                                <p className="color1"><strong dangerouslySetInnerHTML={{ __html: 'Die große<br />Mach-es-jetzt-Aktion' }}></strong></p>
                                <MoreBtn left={132} top={298} />
                            </div>
 <div className="teaser8_1">
                                <Link to="/folders/multifocal2" />
                                <div className="bg" />
                                <Image left={13.6} top={13.6} width={326} height={326} src={img8_1} className="rounded border" />
                                <p className="color1"><strong dangerouslySetInnerHTML={{ __html: 'MULTIFOCAL – So geht<br />das neue Sehen' }}></strong></p>
                                <MoreBtn left={132} top={298} />
                            </div>
                            <div className="teaser8_2" style={{display: 'none',}}>
                                <Link to="/folders/kampagnen" />
                                <div className="bg" />
                                <Image left={13.6} top={13.6} width={326} height={326} src={img8_2} className="rounded border" />
                                <p className="color1"><strong dangerouslySetInnerHTML={{ __html: 'Mein Optiker-<br />Kampagne und Aktion' }}></strong></p>
                                <MoreBtn left={132} top={298} />
                            </div>

                            */

const mapStateToProps = (state, props) => {
  const { authentication } = state;

  return {
    level: authentication.level,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
