import { customFoldersConstants } from '../constants';

const initialState = {
	folders: {},
	foldersNames: {}
}


export function folders(state = initialState, action) {
	switch (action.type) {

		case customFoldersConstants.SAVEFOLDER_SUCCESS:

			let folders = {...state.folders}

			console.log(action)

			folders[action.folder] = action.slides

			return {...state, folders:folders }

			


		case customFoldersConstants.GETFOLDERS_SUCCESS:

			return {...state, folders: action.folders }

		/* */


		case customFoldersConstants.SAVEFOLDERNAMES_SUCCESS:

			let foldersNames = {...state.foldersNames}

			console.log(action)

			foldersNames[action.folder] = action.foldername

			return {...state, foldersNames:foldersNames }


		case customFoldersConstants.GETFOLDERSNAMES_SUCCESS:

			return {...state, foldersNames: action.folders }

		default:
			return state
	}
}