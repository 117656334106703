import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import table from './assets/table.svg';
import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import icon4 from './assets/icon4.svg';
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.png';

const Slide = styled.div`
    .img2 {
        position: absolute;
        left: 843px;
        top: 50px;
        width: 29px;
    }


    .legend {
        font-size: 13.75px;
        line-height: 16.5px;
        color: #009b81;
        text-align: right;

        p + p { 
            margin-top: 11px;
        } 

        p {
            &:nth-child(3) {
                margin-top: 5px;
            }
            
            &:nth-child(4) {
                margin-top: 8px;
            }

            &:nth-child(5) {
                margin-top: 10px;
            }

            &:nth-child(2),
            &:nth-child(5) {
                color: #000;
            }
        }
    }

    .box1 {
        z-index: 10;
        align-items: center;
        justify-content: flex-start;

        p {
            color: #000;
            opacity: 0.8;
        }
    }

    .box1-1 img {
       margin-left: 17px;
       margin-right: 7px;
    }

    .box1-2 img {
       margin-left: 12px;
       margin-right: 7px;
    }

    .box1-3 {
        img {
            margin-left: 12px;
            margin-right: 7px;
        }

        p {
            font-size: 15px;
            line-height: 18px;
        }
    }

    .box1-4 img {
       margin-left: 13px;
       margin-right: 7px;
    }

    .hide {
        background: rgba(255,255,255, 0.9);
    }

    .box2 {
        z-index: 10;
        padding-left: 28px;

        li {
            position: relative;
            padding-left: 22px;

            :before {
                position: absolute;
                left: 0;
                top: 0;
                content: "...";
            }
        }
    }

    .box2,
    .overlay1,
    .more1,
    .hide {
        ${({ step }) => step !== 1 && css`
            display: none;
        `}
    }

    .box3 {
        overflow: hidden;
        padding-left: 112px;

        ${Image} {
            background-position: 53px 0%;
            background-size: contain;
        }
    }

    .box4 {
        font-size: 8.5px;
        line-height: 10.2px;

        p + p {
            margin-top: 20px;
        }
    }

    .img3 {
        display: none;
    }

    .img3 {
        ${({ step }) => step === 2 && css`
            display: block;
        `}
    }
   
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    console.log(props.step)

    return (
        <Slide className="centered" step={props.step}>
            <h1>Attraktive Zielgruppe: <strong>die Kompetenz –<br />und Gesundheitsorientierten</strong></h1>
            <h2>Welche Merkmale sind in dieser Zielgruppe besonders ausgeprägt?<sup>3</sup></h2>
            <Image left={100} top={397} width={1848} height={709} src={table} />

            <img src={img2} className="img2" />
            <Container left={1765} top={109} width={200} className="box4">
                <p>Mit anderen Gruppen<br />
vergleichbar</p>
                <p>Überdurchschnittlich</p>
                <p>Unterdurchschnittlich<br />
zu anderen Gruppen</p>
            </Container>

            <Container left={100} top={545} width={228} height={544} className="legend">
                <p>Suche nach kompetentem Personal</p>
                <p>Preis Leistung<br />
                ist wichtig</p>
                <p>Treue zum
Geschäft</p>
                <p>ACUVUE®-<br />
Träger im<br />
Segment</p>
                <p>Online<br />
Affinität</p>
                <p>Ausgaben für<br />
                Kontaktlinsen </p>
            </Container>

            <Container left={376} top={397} width={421.63} height={119.22} className="rounded flex box1 box1-1">
                <img src={icon1} width="59" />
                <p className="small"><strong>Gesundheit/ Kompetenz</strong></p>
            </Container>

            <Container left={826.36} top={397} width={351.63} height={119.22} className="rounded flex box1 box1-2">
                <img src={icon2} width="63" />
                <p className="small"><strong>Angebot</strong></p>
            </Container>

            <Container left={1206.36} top={397} width={421.63} height={119.22} className="rounded flex box1 box1-3">
                <img src={icon3} width="62" />
                <p className="small"><strong>Bequemlichkeit</strong></p>
            </Container>

            <Container left={1656.36} top={397} width={291.63} height={119.22} className="rounded flex box1 box1-4">
                <img src={icon4} width="56" />
                <p className="small"><strong>Preis</strong></p>
            </Container>

            <Container left={826} top={437} width={1161} height={710} className="hide" />

            <Container left={872} top={667} width={1048} height={440} className="bg1 text flex-all-center color1 box2 fadeInFromLeft">
                <p>Kontaktlinsenträger im <strong>Kompetenz-Segment …</strong></p>
                <ul>
                    <li>legen Wert auf eine <strong>kompetente Beratung</strong></li>
                    <li><strong>sind dem Geschäft</strong>, in dem sie kaufen, <strong>gegenüber treuer</strong></li>
                    <li>kaufen eher <strong>ACUVUE® Markenprodukte</strong></li>
                    <li><strong>geben mehr</strong> für die Kontaktlinse <strong>aus</strong></li>
                </ul>
                
            </Container>

            <Image left={84} top={795} width={1838} height={176} src={img3} className="img3 fadeIn" />

            <MoreBtn
                left={1878} top={957}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')} 
                className="fadeInFromLeft more1" />

            <Overlay className="with-bg overlay1" active={state.overlay1 === 'overlay1'}>
                <Container left={872} top={592} width={1048} height={544} className="rounded flex-all-center text color1 box3">
                    <Image left={-247} width={444} height={444} src={img1} className="border rounded y-center" />
                    <p>Andere Studien &amp; Marktdaten zeigen:</p>
                    <p><strong>ACUVUE® Kontaktlinsenträger</strong><br /> 
zeigen eine <strong>hohe Loyalität</strong> sowohl<br /> 
zum Geschäft als auch zur Marke.</p>
                    <p>Tendieren somit weniger dazu 
Einkaufstätte oder Marke zu wechseln. 
</p>
                    <p><strong>Im  Vergleich zu Trägern von 
anderen Kontaktlinsenmarken.<sup>4</sup></strong></p>
                </Container> 
            </Overlay>

        </Slide>
    );
};
