import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';


const Slide = styled.div`
    
    .box1 {
        padding: 0;
        
        strong {
            font-weight: 600;
        }
        
    }
    
    .box1 .number {
        position: absolute;
        display: block;
        left: -10px;
        top: -40px;
        font-family: "AvenirNext";
        font-weight: 700;
        font-size: 64px;
        line-height: 80px;
        font-style: italic;
        text-align: right;
    }

    .box2 {
        font-size: 18px;
        line-height: 21px;

        .seperator {
            margin-top: 7px;
            margin-bottom: 7px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: 145px;
            height: 1.5px;
            background: #003087;
        }
    }

    .box3 {
        font-size: 10px;
        line-height: 11.5px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Unsere Unterstützung</strong> – Für Ihren Geschäftserfolg</h1>

            <Container left={90} top={0} className="">
                <Image left={100} top={244} width={252} height={321} src={img1} />
                <Container left={0} top={596} width={451} height={157} className="box1 bg1 flex-all-center text-center">
                    <span className="number color2">1</span>
                    <p className="color1"><strong>Kontaktlinsen
                        Portfolio</strong></p>
                </Container>
                <Container left={0} top={793} width={451} className="box2 text-center color1">
                    <p>ACUVUE® ist die weltweit meistverkaufte
                        Kontaktlinsenmarke.<sup>*</sup></p>
                </Container>
            </Container>

            <Container left={576} top={0} className=" fadeIn">
                <Image left={100} top={244} width={252} height={321} src={img2} />
                <Container left={0} top={596} width={451} height={157} className="box1 bg1 flex-all-center text-center">
                    <span className="number color2">2</span>
                    <p className="color1"><strong>Unterstützung in Strategie, Training
                        & Fortbildung</strong></p>
                </Container>
                <Container left={0} top={793} width={451} className="box2 text-center color1">
                    <p>Unsere ACUVUE®<br />
                        Account Manager<br />
                        unterstützen Sie bei der<br />
                        Strategie für Ihren
                        Geschäftserfolg.
                    </p>
                    <span className="seperator" />
                    <p>Unser Professional-Team
                        unterstützt Sie bei Ihrer
                        fachlichen Fortbildung.</p>
                </Container>
            </Container>

            <Container left={1057} top={0} className=" fadeIn animate2">
                <Image left={100} top={244} width={252} height={321} src={img3} />
                <Container left={0} top={596} width={451} height={157} className="box1 bg1 flex-all-center text-center">
                    <span className="number color2">3</span>
                    <p className="color1"><strong>Marketing
                        Unterstützung</strong></p>
                </Container>
                <Container left={0} top={793} width={451} className="box2 text-center color1">
                    <p>Werben Sie mit<br />
                        starken und kreativen<br />
                        Kampagnen, um Ihre<br />
                        Kunden für<br />
                        Kontaktlinsen zu
                        begeistern.</p>
                </Container>
            </Container>

            <Container left={1541} top={0} className=" fadeIn animate3">
                <Image left={100} top={244} width={252} height={321} src={img4} />
                <Container left={0} top={596} width={451} height={157} className="box1 bg1 flex-all-center text-center">
                    <span className="number color2">4</span>
                    <p className="color1"><strong>Attraktives
                        Konditionenmodell</strong></p>
                </Container>
                <Container left={0} top={793} width={451} className="box2 text-center color1">
                    <p>Lassen Sie uns unsere
                        Partnerschaft mit vielen
                        neuen Modulen in
                        unserem Konditionen-
                        modell auch in 2024
                        ausbauen.</p>
                </Container>
            </Container>

            <Container left={120} top={1200} width={1800} className="color1 box3" as="p">
                * Source Euromonitor International Limited; Eyewear 2024 edition; value sales at rsp, all retail channels, 2022 data; “Acuvue family of brands” represents aggregated sales of the following brands: 1-day Acuvue, Acuvue Oasys, Acuvue Advance, Acuvue, and Acuvue2
            </Container>

        </Slide>
    );
};
