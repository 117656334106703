import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';
import img1 from './assets/img1.png';


const Slide = styled.div`
   
   .box2 {
        padding-top: 40px;
   }
`
const inititialState = {
    overlay1: 'overlay3'
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 63, state.overlay1 === 'overlay2', props.preview)
    count1 = count1 / 10
    count1 = count1.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')

    const count2 = useCounter(0, 72, state.overlay1 === 'overlay2', props.preview)

    return (
        <>
            <div className="bg left" style={{ backgroundImage: `url(${bg})`, bottom: '85px' }}></div>
            <Slide className="centered">
                <h1>Das Leben verlangt <strong>mehr von unseren Augen.</strong></h1>

                <Container left={1051} top={376} width={850} className="color1 text-center fadeIn" box1 as="p">
                    <p>Unser Leben hat sich in den vergangenen Jahren verändert, die meisten haben einen anspruchsvollen Alltag, sind vielseitig aktiv und verbringen viele Stunden täglich an digitalen Geräten<sup>4</sup>. Dies kann zu trockenen und müden Augen führen, weil man
                        weniger blinzelt.<sup>4,5</sup></p>


                </Container>

                <div className="container-animate fadeInFromTop animate2">

                    <Container left={1033} top={772} width={886} height={301} className="bg1 color1 box2 text-center">
                        <Arrow width={704} className="container-top" />
                        <p>Deswegen ist es an der Zeit für die<br />
                            <strong>aktuell leistungsstärkste ACUVUE®<br />
                            Kontaktlinse</strong>, die diesen Anforderungen<br />
                            gerecht werden kann!</p>
                    </Container>

                    <Image left={922} top={798} width={136} height={283} src={img1} />

                   
                </div>

            </Slide>
        </>
    );
};
