import Slide1 from "./slides/slide1";
import Slide2 from "./slides/slide2";
import Slide3 from "./slides/slide3";
import Slide4 from "./slides/slide4";
import Slide5 from "./slides/slide5";
import Slide6 from "./slides/slide6";
import Slide7 from "./slides/slide7";
import Slide8 from "./slides/slide8";
import Slide9 from "./slides/slide9";
import Slide10 from "./slides/slide10";

const Theme = {
  color1: "#ffb900",
  color2: "#051f4a",
  color3: "#00a98f",
  bg1: "#fff4dd",
  bg2: "#d9dee5",
  bg3: "#ffffff",
};

export const Slides = {
  Folder18Slide1: { name: Slide1, theme: Theme, footer: "transparent white" },
  Folder18Slide2: { name: Slide2, theme: Theme },
  Folder18Slide3: { name: Slide3, theme: Theme },
  Folder18Slide4: { name: Slide4, theme: Theme },
  Folder18Slide5: { name: Slide5, theme: Theme },
  Folder18Slide6: { name: Slide6, theme: Theme },
  Folder18Slide7: { name: Slide7, theme: Theme },
  Folder18Slide8: { name: Slide8, theme: Theme },
  Folder18Slide9: { name: Slide9, theme: Theme },
  Folder18Slide10: { name: Slide10, theme: Theme },
};

export const Order = [
  { key: 0, component: "Folder18Slide1" },
  { key: 1, component: "Folder18Slide2" },
  { key: 2, component: "Folder18Slide3" },
  { key: 3, component: "Folder18Slide4" },
  { key: 4, component: "Folder18Slide5" },
  { key: 5, component: "Folder18Slide6" },
  { key: 6, component: "Folder18Slide7" },
  { key: 7, component: "Folder18Slide8" },
  { key: 8, component: "Folder18Slide9" },
  { key: 9, component: "Folder18Slide10" },
];
