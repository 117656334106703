import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';
import img1 from './assets/img1.png';

const Slide = styled.div`
   .box2 {
        font-family: "AvenirNext";
        font-size: 19px;
        line-height: 24px;
   }

   .box3 {
        font-family: "AvenirNext";
        font-size: 10px;
        line-height: 11.5px;
    }
`
const inititialState = {
    overlay1: 'overlay3'
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg left" style={{ backgroundImage: `url(${bg})`, bottom: '85px' }}></div>
            <Slide className="centered">
                <h1>Unser gemeinsames <strong>Ziel</strong></h1>

                <Container left={127} top={214} width={1794} className="color1 text-center" box1 as="p">
                    Gemeinsam mit Ihnen möchten wir das Kontaktlinsensegment weiter ausbauen und<br />
                    ein nachhaltiges Wachstum ermöglichen.
                </Container>

                <div className="container-animate fadeIn">
                    <Arrow left={896} top={440} width={112} arrowColor={'#007DC5'} />
                    <Container left={1003} top={473} width={1006} className="box2 color1" as="p">
                        <strong>Gewinnen neuer Träger</strong>* für Ihr Geschäft
                    </Container>
                </div>

                <div className="container-animate fadeIn animate2">
                    <Arrow left={896} top={528} width={112} arrowColor={'#007DC5'} />
                    <Container left={1003} top={561} width={1006} className="box2 color1" as="p">
                        <strong>Steigerung der Kundenzufriedenheit</strong>
                    </Container>
                </div>

                <div className="container-animate fadeIn animate3">
                    <Arrow left={896} top={616} width={112} arrowColor={'#007DC5'} />
                    <Container left={1003} top={649} width={1006} className="box2 color1" as="p">
                        <strong>Steigerung der Loyalität</strong> zu Ihrem Geschäft
                    </Container>
                </div>

                <div className="container-animate fadeIn animate4">
                    <Arrow left={756} top={567} width={432} offset={-140} arrowColor={'#007DC5'} />
                    <Container left={1003} top={737} width={1006} className="box2 color1" as="p">
                        <strong>Reduzierung der Aussteiger</strong> durch<br />
                        innovative Produkte und Beratung
                    </Container>

                    <Image left={1008} top={904} width={896} height={231} src={img1} />
                </div>

                <Container left={893} top={1184} width={900} className="color1 box3 fadeIn animate5" as="p">
                    * Aus Gründen der besseren Lesbarkeit wird im Folgenden auf die gleichzeitige Verwendung<br />
                    weiblicher und männlicher Sprachformen verzichtet und das generische Maskulinum verwendet.<br />
                    Sämtliche Personenbezeichnungen gelten gleichermaßen für alle Geschlechter.
                </Container>

            </Slide>
        </>
    );
};
