import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

import icon1 from '../slide12/assets/icon1.svg';
import icon2 from '../slide12/assets/icon2.svg';
import icon3 from '../slide12/assets/icon3.svg';
import icon4 from '../slide12/assets/icon4.svg';

const Slide = styled.div`
    h1 {
        position: relative;
    }

    > ${Container} {
       padding-top: 195px;

       p + p {
           margin-top: 10px;
       }

       p + p + p {
            margin-top: 23px;
       }
   }
`
const inititialState = {
    overlay1: false
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide>
            <h1><strong>Bedürfnis-Erfüllung in Bezug auf Shopper</strong> Typen:<br />
Typische Kontaktlinsenträger-Typen</h1>

            <Container left={57} top={280} width={466} className="text-center">
                <Image left={70} top={0} width={326} height={326} src={img1} className="border rounded">
                    <MoreBtn
                        className="x-center y-bottom"
                        active={state.overlay1 === 'overlay1'}
                        onClick={() => toggleOverlay('overlay1')} />
                     <Image left={248} top={0} width={121} height={121} src={icon1} imageWidth={82} className="rounded border image-center bg-white" />
                </Image>
                <p className="color1"><strong>Gesa Sund</strong></p>
                <p className="small">Kompetenz-liebende<br />Gesundheits-Typ</p>
                <p className="color1 small"><strong>“Mir ist meine Augen-<br />
gesundheit so wichtig, dass ich gerne etwas<br />
mehr bezahle</strong> - damit<br />
jemand kompetentes,<br />
dem ich trauen kann, mir die besten Produkte und Services empfiehlt und verkauft.”</p>
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg" onClick={() => toggleOverlay('overlay1')}>
                <Container left={144} top={606} width={540} height={285} className="rounded text-center flex-all-center">
                    <p className="color1"><strong><i>“Aus Unsicherheit<br />
                    frage ich aber<br />
                    trotzdem manchmal<br />
                    nach dem Preis.”</i></strong></p>
                </Container>
            </Overlay>

            <Container left={546} top={280} width={466} className="text-center">
                <Image left={70} top={0} width={326} height={326} src={img2} className="border rounded">
                    <MoreBtn
                        className="x-center y-bottom"
                        active={state.overlay1 === 'overlay2'}
                        onClick={() => toggleOverlay('overlay2')} />
                     <Image left={248} top={0} width={121} height={121} src={icon2} imageWidth={88} className="rounded border image-center bg-white" />
                </Image>
                <p className="color1"><strong>Dennis Deeling</strong></p>
                <p className="small">Ehrgeiziger Bestes<br />Angebots-Typ</p>
                <p className="color1 small">“Ich gehe gerne zum<br />
                besten Optiker<br />
in der Gegend, muss es<br />
mir aber leisten wollen –<br />
                    <strong>so achte ich auf das eine oder andere Angebot bei den besten Produkten<br />
und Services.”</strong></p>
            </Container>

            <Overlay active={state.overlay1 === 'overlay2'} className="with-bg" onClick={() => toggleOverlay('overlay2')}>
                <Container left={516} top={606} width={528} height={277} className="rounded text-center flex-all-center">
                    <p className="color1"><strong><i>“Was nicht immer<br />
                    bedeutet, dass ich<br />
                    mir es nicht leisten<br />
                    kann.”</i></strong></p>
                </Container>
            </Overlay>

            <Container left={1035} top={280} width={466} className="text-center">
                <Image left={70} top={0} width={326} height={326} src={img3} className="border rounded">
                    <MoreBtn
                        className="x-center y-bottom"
                        active={state.overlay1 === 'overlay3'}
                        onClick={() => toggleOverlay('overlay3')} />
                         <Image left={248} top={0} width={121} height={121} src={icon3} imageWidth={88} className="rounded border image-center bg-white" />
                </Image>
                <p className="color1"><strong>Sofie Sofar</strong></p>
                <p className="small">Pragmatisch-komfortable<br />Bequem-Typ</p>
                <p className="color1 small">“Ich glaube nicht,<br />
                dass es zwischen
                verschiedenen Optikern
                und Händlern wirklich<br />
                große Unterschiede gibt<br />
                – <strong>daher nehme ich für<br />
                mich die bequemste Lösung.”</strong></p>
            </Container>

            <Overlay active={state.overlay1 === 'overlay3'} className="with-bg" onClick={() => toggleOverlay('overlay3')}>
                <Container left={995} top={606} width={544} height={281} className="rounded text-center flex-all-center">
                    <p className="color1"><strong><i>“Lass mich aber<br />
                    gerne vom<br />
                    Gegenteil<br />
                    überzeugen.”</i></strong></p>
                </Container>
            </Overlay>

            <Container left={1524} top={280} width={466} className="text-center">
                <Image left={70} top={0} width={326} height={326} src={img4} className="border rounded">
                    <MoreBtn
                        className="x-center y-bottom"
                        active={state.overlay1 === 'overlay4'}
                        onClick={() => toggleOverlay('overlay4')} />
                         <Image left={248} top={0} width={121} height={121} src={icon4} imageWidth={88} className="rounded border image-center bg-white" />
                </Image>
                <p className="color1"><strong>Holden Pennie</strong></p>
                <p className="small">Preissensitive<br />Schnäppchen-Typ</p>
                <p className="color1 small">“Ich bin nur am Preis
interessiert – so kaufe<br />
ich immer dort, wo es<br />
am billigsten ist<br />
                    <strong> – mit gerade noch
akzeptabler Qualität.”</strong></p>
            </Container>

            <Overlay active={state.overlay1 === 'overlay4'} className="with-bg" onClick={() => toggleOverlay('overlay4')}>
                <Container left={1403} top={606} width={544} height={238} className="rounded text-center flex-all-center">
                    <p className="color1"><strong><i>“Ich bin nur dem<br />
Preis gegenüber<br />
loyal.”</i></strong></p>
                </Container>
            </Overlay>

        </Slide>
    );
};
