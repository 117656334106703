import React from 'react';
import styled from 'styled-components'

import bg from './assets/bg.jpg'

const Slide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: 110px;
        font-size: 43.5px;
        line-height: 51px;
        color: #fff;
        font-weight: 600;
        color: #fff;

        span {
            font-weight: 400;
            text-transform: uppercase;
        }
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <div>
                    <h1 style={{ color: '#fff' }}>ACUVUE<sup>®</sup> OASYS MAX 1-Day<br />
<span>Familie</span></h1>
                </div>
            </Slide>
        </>

    );
};

export default Folder2Slide1;