import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.svg';

const Slide = styled.div`
    .box1 {
        padding-left: 35px;
        font-size: 28px;
        line-height: 31.5px;

        &.box1-1 {
            letter-spacing: -0.03em;
        }
    }

    .box1 .number {
        position: absolute;
        display: block;
        left: -5px;
        top: -74px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .overlay1-1,
    .overlay1-2,
    .overlay1-3 {
        .box2 {
            padding: 41px 10px 10px 41px;
        }

        .box2-1,
        .box2-3 {
            padding-left: 33px;
            padding-right: 20;
            padding-top: 30px;
            
            p {
                line-height: 22.5px;
            }
        }

        .box2-1 {
            background-image: url(${img2});
            background-color: #fff;
            background-size: contain;
            background-repeat: no-repeat;
            padding-top: 290px;
        }

        .box2-3 {
            background-image: url(${img1});
            background-size: cover;
            padding-top: 25px;
        }
    }

    .overlay1-1 .img1,
    .overlay1-2 .img2 {
        position: absolute;
        
    }

    .overlay1-1 .img1 {
        width: 49px;
        left: 580px;
        top: 516px;
    }



    .overlay1-2 {
        .box4 {
            position: relative;
            font-size: 53.4px;
            line-height: 66.75px;
            font-weight: 600;

            span {
                    position: absolute;
                    left: 0;
                    top: 104px;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: 400;
                }
        }

        .box5 {
            .count {
                font-size: 53.4px;
                line-height: 66.75px;
                padding-top: 20px;

                .percent {
                    font-size: 28.8px;
                }
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 63, state.overlay1 === 'overlay2', props.preview)
    count1 = count1 / 10
    count1 = count1.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')

    const count2 = useCounter(0, 72, state.overlay1 === 'overlay2', props.preview)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})`, bottom: '85px' }}></div>
            <Slide className="centered">
                <h1>Warum die exklusive Testträger-Kampagne mit<br /> 
<strong>ACUVUE® OASYS MAX 1-Day</strong> ein Erfolg wird</h1>

                <div className="container-animate fadeInFromLeft">
                    <Container left={167} top={385} width={906} height={141} className="bg1 box1 box1-1 color1 flex-all-center">
                        <span className="number number1">1</span>
                        <p><strong>Aktuell beste ACUVUE® Linse</strong></p>

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay1'}
                            onClick={() => toggleOverlay('overlay1')} />

                    </Container>


                </div>

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg bg3 overlay1 overlay1-1">
                    <Container left={1074} top={56} width={899} height={1159} className="box2 box2-1 bg2 color1">
                        <p className="small">
                        Das Leben verlangt mehr von unseren Augen. Ihre Kunden können mehr von ihren Linsen 
verlangen. Die neue Kontaktlinsenfamilie<br /> 
<strong>ACUVUE® OASYS MAX 1-Day</strong> wird den vielfäl-<br />
tigen Herausforderungen eines turbulenten 
Alltags gerecht.<br /><br /> 

Eine noch nie dagewesene Kombination aus 
innovativen Technologien<sup>6</sup> ermöglicht nach-
gewiesene Bestleistung, wie außergewöhnlicher Tagesend-Komfort^ und Sehqualität bei allen 
Lichtverhältnissen<sup>±6</sup> 
                        </p>
                    </Container>

                    

                    <Container left={167} top={385} width={906} height={141} className="box1 box1-1 color1 flex-all-center text-center">
                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay1'}
                            onClick={() => toggleOverlay('overlay1')} />

                    </Container>

                </Overlay>

                <div className="container-animate fadeInFromLeft animate2">

                    <Container left={167} top={672} width={906} height={141} className="bg1 box1 color1 flex-all-center">
                        <span className="number number1">2</span>
                        <p><strong>Großes Umsatzpotenzial</strong></p>

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay2'}
                            onClick={() => toggleOverlay('overlay2')} />

                    </Container>



                </div>

                <Overlay active={state.overlay1 === 'overlay2'} className="with-bg bg3 overlay1 overlay1-2">
                    <Container left={1074} top={352} width={899} height={718} className="box2 bg2 color1">

                    </Container>

                    <Container left={1449} top={364} width={514} height={350} className="text1 color1 flex-all-center"><p>… sind laut aktueller<br /> 
Studie <strong>aktiv an Kontakt-<br />
linsen interessiert</strong> oder<br /> 
haben schon den ersten<br /> 
Kontakt gehabt.<sup><strong>1</strong></sup></p></Container>
                    <Container left={1449} top={695} width={514} height={372} className="text1 color1  flex-all-center"><p>… der Brillenträger<br /> 
sind weiterhin <strong>bereit<br /> 
für eine Kontaktlinsen-<br />
anpassung.<sup>1</sup></strong></p></Container>


                    <Image left={1117} top={385} width={306} height={306} src={img3} />
                    <Container left={1117} top={385} width={306} height={306} className="flex-all-center text-center box4 color1">
                        {count1}<br />
                        <span>Mio</span>
                    </Container>

                    <CircleCount left={1117} top={729} width={306} percent={count2} color={'#003087'} />
                    <Container left={1117} top={729} width={306} height={306} className="flex-all-center text-center box5">
                        <span className="count">{count2}<span className="percent">%</span></span>
                    </Container>

                    <Container left={167} top={672} width={906} height={141} className="box1 color1 flex-all-center text-center">

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay2'}
                            onClick={() => toggleOverlay('overlay2')} />

                    </Container>


                </Overlay>

                <div className="container-animate fadeInFromLeft animate3">

                    <Container left={167} top={961} width={906} height={141} className="bg1 box1 color1 flex-all-center">
                        <span className="number number1">3</span>
                        <p><strong>Geld-Zurück-Mechanik</strong></p>

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay3'}
                            onClick={() => toggleOverlay('overlay3')} />
                    </Container>


                </div>


                <Overlay active={state.overlay1 === 'overlay3'} className="overlay1 overlay1-3 with-bg bg3">
                    <Container left={1074} top={256} width={899} height={959} className="box2 box2-3 bg2 color1">
                    <p className="small"><strong>Produkttest-Kampagnen erzielen große<br /> 
Aufmerksamkeit bei Verbrauchern und<br />
sind damit ein besonders attraktives<br /> 
Kommunikationstool.<br /><br /> 

Die Möglichkeit, die beste ACUVUE® Linse<br /> 
ohne finanzielles Risiko zu testen, bestärkt<br />
zusätzlich und wirkt als vertrauensfördernde<br />
Maßnahme.</strong><br /><br />

Die <strong>MAX</strong> Tageslinsen-Innovation – Empfehlen<br /> 
Sie jetzt <strong>MAX</strong> Testträger zu werden.</p>
                    </Container>

                    <Container left={167} top={961} width={906} height={141} className="box1 color1 flex-all-center text-center">

                        <MoreBtn
                            className="x-right y-center"
                            active={state.overlay1 === 'overlay3'}
                            onClick={() => toggleOverlay('overlay3')} />
                    </Container>
                </Overlay>

            </Slide>
        </>
    );
};
