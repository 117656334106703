import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';
import img6 from './assets/img6.jpg';

const Slide = styled.div`
    .box1 {
        padding-top: 188px;
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Es gibt viele Anlässe für <strong>multifokale Kontaktlinsen</strong></h1>

            <Container left={334} top={235} width={454} className="box1 text-center color1">
                <Image left={50} top={0} width={354} height={354} src={img1} />
                <p>Besondere<br /> 
Anlässe</p>
            </Container>

            <Container left={812} top={235} width={454} className="box1 text-center color1">
                <Image left={50} top={0} width={354} height={354} src={img2} />
                <p>Sport</p>
            </Container>

            <Container left={1291} top={235} width={454} className="box1 text-center color1">
                <Image left={50} top={0} width={354} height={354} src={img3} />
                <p>Freizeitaktivitäten</p>
            </Container>

            <Container left={334} top={730} width={454} className="box1 text-center color1">
                <Image left={50} top={0} width={354} height={354} src={img4} />
                <p>Zeit mit Freunden 
genießen</p>
            </Container>

            <Container left={812} top={730} width={454} className="box1 text-center color1">
                <Image left={50} top={0} width={354} height={354} src={img5} />
                <p>Essen gehen</p>
            </Container>

            <Container left={1291} top={730} width={454} className="box1 text-center color1">
                <Image left={50} top={0} width={354} height={354} src={img6} />
                <p>Besondere Wetterverhältnisse</p>
            </Container>

        </Slide>
    );
};
