import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg';
import img1 from './assets/img1.png';


const Slide = styled.div`
   .box1 {
        padding-top: 40px;
   }

   .box3 {
        font-size: 10px;
    }
`
const inititialState = {
    overlay1: 'overlay3'
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 63, state.overlay1 === 'overlay2', props.preview)
    count1 = count1 / 10
    count1 = count1.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')

    const count2 = useCounter(0, 72, state.overlay1 === 'overlay2', props.preview)

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})`, bottom: '85px' }}></div>
            <Slide className="centered">
                <h1>Die exklusive <strong>MAX-Testträger-Aktion</strong><br />1. Juni – 31. August 2023</h1>

                <Container left={102} top={288} width={978} className="color1 text-center fadeIn" as="p">
                    Die ACUVUE® OASYS MAX 1-Day<br />
                    Kampagne erzielt mit der „Testträger-<br />
                    gesucht-Aktion“ und unterstützender<br />
                    POS-Ausstattung und Online Materialien<br />
                    eine <strong>große Reichweite und hohe<br />
                        Aufmerksamkeit.</strong>
                </Container>

                <div className="container-animate fadeInFromTop animate2">

                <Container left={148} top={642} width={886} height={376} className="bg1 color1 box1 text-center">
                    <Arrow width={704} className="container-top" />
                    <p>So bringen wir mit der aktuell<br />
                        besten Tageslinse von ACUVUE®<br />
                        neue Kontaktlinsenträger<br />
                        in Ihr Geschäft.</p>
                </Container>

                <Image left={153} top={949} width={865} height={205} src={img1} />

                </div>
                <div className="container-animate fadeIn animate2">

                <Container left={355} top={1228} width={476} className="label color1 box3" as="span">
                Produktabbildungen nur zu Illustrationszwecken
                </Container>
                </div>

            </Slide>
        </>
    );
};
