import { authenticationConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? { 
	isLoggedin: true,
	token: user.token,
	email: user.email,
	level: user.level,
	users: []
} : { 
	users: []
}

export function authentication(state = initialState, action) {

	let usersNew = []

	switch (action.type) {
		case authenticationConstants.LOGIN_REQUEST:
			return {
				...state,
				loggingIn: true,
				user: action.user
			}
		case authenticationConstants.LOGIN_SUCCESS:

			localStorage.setItem('user', JSON.stringify({email: action.email, token: action.token, level: action.level}));

			return {
				...state,
				isLoggedin: true,
				token: action.token,
				email: action.email,
				level: action.level
			}

		case authenticationConstants.LOGIN_FAILURE:
			return {error: action.error };
			
		
		case authenticationConstants.VALIDATELOGIN_FAILURE:
		case authenticationConstants.LOGOUT_SUCCESS:

			localStorage.removeItem('user');

			return {
				...state,
				isLoggedin: false,
				token: '',
				email: '',
				level: null
			}

		case authenticationConstants.REQUESTPASSWORD_SUCCESS:
			return {
				isRequested: true
			}

		case authenticationConstants.REQUESTPASSWORD_FAILURE:
			return {error: action.error };


		case authenticationConstants.CHANGEPASSWORD_SUCCESS:
			return {
				isChanged: true
			}

		case authenticationConstants.CHANGEPASSWORD_FAILURE:
			return {error: action.error };
			

		case authenticationConstants.RESETSTATE_REQUEST:

			return {
				...state,
				error: null,
				isChanged: false,
				isRequested: false
			}

		case authenticationConstants.GETUSERS_SUCCESS:

			return {
				...state,
				users: action.users
			}

		case authenticationConstants.SAVEUSER_SUCCESS:

			console.log(action)

			usersNew = [...state.users]

			const index = usersNew.findIndex(item => item.id === action.user.id)

			console.log(index)

			if(index>-1) {
				usersNew[index] = action.user;
			} else {
				console.log(usersNew)
				usersNew.push(action.user)
				console.log(usersNew)
			}


			return {
				...state,
				users: usersNew
			}

		case authenticationConstants.DELETEUSER_SUCCESS:

			console.log(action.id)
			
			usersNew = state.users.filter(item => item.id !== action.id)

			return {
				...state,
				users: usersNew
			}
		
		default:
			return state
	}
}