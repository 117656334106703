
import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'

const Theme = {
    color1: '#009ca6',
    color2: '#003087',
    bg1: '#CFF2FB',
    bg2: '#ffffff',

    bg3: '#003087',
}

export const Slides = {
    'Folder11Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder11Slide2': { name: Slide2, theme: Theme, steps: 2 },
    'Folder11Slide3': { name: Slide3, theme: Theme },
    'Folder11Slide4': { name: Slide4, theme: Theme },
    'Folder11Slide5': { name: Slide5, theme: Theme },
    'Folder11Slide6': { name: Slide6, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder11Slide1' },
    { key: 1, component: 'Folder11Slide2' },
    { key: 2, component: 'Folder11Slide3' },
    { key: 3, component: 'Folder11Slide4' },
    { key: 4, component: 'Folder11Slide5' },
    { key: 5, component: 'Folder11Slide6' },
]