import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

const Slide = styled.div`
    .box1 {
        p + p {
            margin-top: 17px;
        }
    }

    .box2 {
        padding-top: 39px;

        h3 {
            margin-bottom: 14px;
            font-size: 21px;
            line-height: 23.7px;
            font-weight: 600;
        }

        &.box2-1 h3 {
            color: #009ca6;
        }

        &.box2-2 h3 {
            color: #330072;
        }

        &.box2-3 h3 {
            color: #c20016;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Nachhaltigkeit</strong></h1>
            <Container left={100} top={196} width={1848} height={275} className="bg1 box1 flex-all-center text-center">
                <p className="small"><strong>Unsere Verantwortung gegenüber der Umwelt:</strong></p>
                <p className="small">Es ist unsere Verantwortung unseren ökologischen Fußabdruck auf der Erde zu verringern während<br /> 
wir gleichzeitig die Entwicklung der weltweiten Augengesundheit vorantreiben.</p>
            </Container>
            <Container left={90} top={470} width={569} className="box2 box2-1 color1 text-center fadeIn">
                <Arrow width={376} className="container-top" bgColor={'#FFDADD'} />
                <h3>Klima</h3>
                <p>Reduzierung unseres<br />
                CO2-Fußabdrucks und<br />
                Vorantreiben erneuerbarer<br />
                Energien.</p>
            </Container>
            <Container left={676} top={470} width={635} className="box2 box2-2 color1 text-center  fadeIn animate2">
                <Arrow width={376} className="container-top" bgColor={'#FFDADD'} />
                <h3>Abfall-Reduzierung</h3>
                <p>Begrenzung unseres<br /> 
Abfall-Anteils durch<br /> 
Recycling und<br /> 
Verpackungsoptimierung.</p>
            </Container>
            <Container left={1371} top={470} width={587} className="box2 box2-3 color1 text-center fadeIn animate3">
                <Arrow width={376} className="container-top" bgColor={'#FFDADD'} />
                <h3>Natürliche Ressourcen</h3>
                <p>Entwicklung von Lösungen,<br />
                die die Entwaldung<br /> 
bekämpfen und Wasser<br />
schützen und bewahren.</p>
            </Container>
        </Slide>
    );
};
