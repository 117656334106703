import React from "react";
import styled from "styled-components";

import { Container } from "components/parts";

import bg from "./assets/bg.jpg";

const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .bg {
    position: absolute;
    background: url(${bg});
    background-size: cover;
    background-position: center center;
    inset: 0 0 85px 0;
  }

  .disrupter {
    padding-top: 14px;
    background: #ff6a39;
    font-size: 13.5px;
    line-height: 18.5px;
    border: 1.4px solid #fff;
    transform: rotate(-16deg);
  }
`;

export default ({ ...props }) => {
  return (
    <Slide className="bg full">
      <div className="bg" />
      <div className="centered">
        <Container
          left={338}
          top={270}
          width={322}
          height={322}
          className="scaleIn"
        >
          <Container
            left={0}
            top={0}
            width={322}
            height={322}
            className="disrupter color2 flex flex-all-center text-center color3 rounded-full"
          >
            <div>
              <strong>Alle Paramter</strong> von
              <br />
              ACUVUE® OASYS for
              <br />
              ASTIGMATISM{" "}
              <strong>
                wieder vollumfänglich
                <br />
                erhältlich
              </strong>
            </div>
          </Container>
        </Container>
      </div>
    </Slide>
  );
};
