import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.png';

const Slide = styled.div`
    .img1 {
        position: absolute;
        width: 177.5px;
        left: 50px;
        top: 125px;
    }

    .img2 {
        position: absolute;
        width: 730.5px;
        left: 268.5px;
        top: 136.5px;
    }

    .img3 {
        position: absolute;
        width: 639px;
        left: 304px;
        top: 269px;
    }

    .box1 {
        padding-left: 25px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Wie denken und handeln Träger heute?</strong></h1>
            <img src={img1} className="img1" />
            <img src={img2} className="img2" />
            <img src={img3} className="img3 fadeIn" />
            <Container left={1586} top={796} width={387} height={222} className="bg2 box1 flex-all-center color1">
                <p><strong>Kontaktlinsen-<br />
                träger nutzen
informiert 
das Internet</strong></p>
                </Container> 
                <Arrow left={1586} top={830} width={158} />
        </Slide>
    );
};
