import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

const Slide = styled.div`
    .box1 {
        padding-top: 17px;

        p + p {
            margin-top: 13px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Was bewegt Kontaktlinsenträger im <strong>Internet zu kaufen?<sup>1</sup></strong></h1>
            
            <Image left={156} top={213} width={326} height={326} src={img1} className="rounded border" />
            <Image left={665} top={278} width={262} height={262} src={img2} className="rounded border" />
            <Image left={1148} top={313} width={226} height={226} src={img3} className="rounded border" />
            <Image left={1658} top={407} width={132} height={132} src={img4} className="rounded border" />

            <Container left={80} top={588} width={478} height={505} className="text-center color1">
                <p><strong>Bequemlichkeit</strong></p>
            </Container>
            <Container left={100} top={716} width={438} height={505} className="bg1 text text-center color1 box1 fadeInFromTop">
                <Arrow width={343} className="container-top" />
                <p><strong>Erreichbarkeit<br />24/7</strong></p>
                <p><strong>Nach Hause 
Lieferung / Abos
</strong></p>
                <p><strong>Zeitersparnis</strong></p>
                <p><strong>Unterschiedliche 
Zahlungs-<br />
methoden</strong></p>
            </Container>

            <Container left={557} top={588} width={478} height={505} className="text-center color1">
                <p><strong>Produktverfügbarkeit<br />&amp; Auswahl</strong></p>
            </Container>
            <Container left={577} top={716} width={438} height={505} className="bg1 text text-center color1 box1 fadeInFromTop">
                <Arrow width={325} className="container-top" />
                <p><strong>Menge &amp;<br /> 
Schnelligkeit der<br /> 
Lieferung

</strong></p>
                <p><strong>Neue, andere<br /> 
Produkte testen</strong></p>
            </Container>

            <Container left={1038} top={588} width={445} height={505} className="text-center color1">
                <p><strong>Preis</strong></p>
            </Container>
            <Container left={1058} top={716} width={405} height={505} className="bg1 text text-center color1 box1 fadeInFromTop">
                <Arrow width={305} className="container-top" />
                <p><strong>Niedriger Preis<br />
                und zusätzliche Angebote</strong></p>
                <p><strong>Vermeintliche
Preis-<br />
Transparenz</strong></p>
            </Container>

            <Container left={1485} top={588} width={487} height={505} className="text-center color1">
                <p><strong>Produkt-<br />
information</strong></p>
            </Container>
            <Container left={1505} top={716} width={447} height={505} className="bg1 text text-center color1 box1 fadeInFromTop">
                <Arrow width={346} className="container-top" />
                <p><strong>Breite<br />
Information</strong></p>
                <p><strong>Unverbindliches
Informieren ohne
Kaufdruck</strong></p>
                <p><strong>Rating &amp; Reviews</strong></p>
            </Container>


        </Slide>
    );
};
