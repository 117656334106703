import React from 'react';
import styled from 'styled-components'

import bg from './assets/bg.jpg'

const Slide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: 220px;
        font-size: 42px;
        line-height: 45px;
        color: #fff;

        strong {
            font-weight: 600;
            letter-spacing: -0.06rem;
        }

        > * {
            display: block;
        }

        span {
            margin-top: 10px;
        }
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <div>
                    <h1 style={{ color: '#fff' }}><strong>ACUVUE® OASYS MAX 1-Day<br />
                        Testträger</strong><span>exklusive Kampagne<br />1. Juni – 31. August 2023</span></h1>
                </div>
            </Slide>
        </>

    );
};

export default Folder2Slide1;