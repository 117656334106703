import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';

const Slide = styled.div`
    
    .box3 {
        padding-top: 15px;

        > p {
            position: relative;
            z-index: 201;
        }
    }

    .overlay1 {
        z-index: 200
    }

    .box3 .number {
        position: absolute;
        display: block;
        left: 0px;
        top: -48px;
        font-size: 64px;
        line-height: 80px;
        color: #003087;
        font-style: italic;
        text-align: left;
    } 
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Neue Kontaktlinsenkunden</strong> für<br />
                das Optiker Geschäft</h1>

            <Container left={433} top={317} width={1184} height={159} className="rounded color1 flex-all-center text-center" as="p">
                <strong>Attraktive Kommunikation der Kompetenz<br />
                    für den POS, CRM und Social Media Kanäle</strong>
            </Container>

            <Container left={433} top={551} width={1184} height={259} className="bg1 color1 flex-all-center text text-center fadeInFromTop">
                <Arrow width={1064} className="container-top" />
                <p><strong>Zielgruppe:</strong> Bestehende Träger<br />
                    aus anderen Kanälen<br />
                    und unzufriedene Träger</p>
            </Container>


            <Container left={113} top={978} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate2">
                <span className="number">1</span>
                <p><strong>POS</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'} />
            </Container>

            <Container left={577} top={978} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate3">
                <span className="number">2</span>
                <p><strong>Mailings/Newsletter</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay2')}
                    active={state.overlay1 === 'overlay2'}/>
            </Container>

            <Container left={1041} top={978} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate4">
                <span className="number">3</span>
                <p><strong>Online/SocialMedia</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay3')}
                    active={state.overlay1 === 'overlay3'} />
            </Container>

            <Container left={1505} top={978} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate5">
                <span className="number">4</span>
                <p><strong>30 € Cashback</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay4')}
                    active={state.overlay1 === 'overlay4'} />
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 overlay1-3 with-bg">
                <Container left={113} top={317} width={444} height={793} className="box2 bg2 color1">
                </Container>
                <Image left={140} top={365} width={387} height={592} src={img1} />

                <Container left={113} top={978} width={444} height={132} className="box3 box4 color1 text-center">
                <p><strong>POS</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'} />
                </Container>


            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="overlay1 overlay1-3 with-bg">
                <Container left={577} top={317} width={444} height={793} className="box2 bg2 color1">
                </Container>
                <Image left={620} top={363} width={346} height={588} src={img2} />

                <Container left={577} top={978} width={444} height={132} className="box3 box4 color1 text-center">
                <p><strong>Mailings/Newsletter</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay2')}
                    active={state.overlay1 === 'overlay2'} />
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay3'} className="overlay1 overlay1-3 with-bg">
                <Container left={1041} top={317} width={444} height={793} className="box2 bg2 color1">
                </Container>
                <Image left={1084} top={354} width={351} height={628} src={img3} />

                <Container left={1041} top={978} width={444} height={132} className="box3 box4 color1 text-center">
                <p><strong>Online/SocialMedia</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay3')}
                    active={state.overlay1 === 'overlay3'} />
                </Container>

            </Overlay>

            <Overlay active={state.overlay1 === 'overlay4'} className="overlay1 overlay1-3 with-bg">
                <Container left={1505} top={317} width={444} height={793} className="box2 bg2 color1">
                </Container>
                <Image left={1536} top={367} width={366} height={599} src={img4} />

                <Container left={1505} top={978} width={444} height={132} className="box3 box4 color1 text-center">
                <p><strong>30 € Cashback</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay4')}
                    active={state.overlay1 === 'overlay4'} />
                </Container>

            </Overlay>

        </Slide>
    );
};
