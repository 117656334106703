import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { connect } from 'react-redux'
import { logout } from 'actions'
import { Link } from 'react-router-dom';

import { Image, MoreBtn } from 'components/parts'
import { MoreBtnContainer } from 'components/parts/MoreBtn'

import Slide from 'components/pages/folders/Slide'

import Custom from 'components/pages/overview/assets/Custom.svg';
import Footer from 'components/pages/folders/Footer'

const teaser = [
    {
        title: 'Folder A',
        name: 'custom-a',
        img: Custom
    },
    {
        title: 'Folder B',
        name: 'custom-b',
        img: Custom
    },
    {
        title: 'Folder C',
        name: 'custom-c',
        img: Custom
    },
    {
        title: 'Folder D',
        name: 'custom-d',
        img: Custom
    },
    {
        title: 'Folder E',
        name: 'custom-e',
        img: Custom
    },
    {
        title: 'Folder F',
        name: 'custom-f',
        img: Custom
    },
]



const Overview = styled.div`
    overflow: hidden;

    .list {
        display: flex;
        flex-wrap: wrap;
        width: 1018px;
        margin-left: 75px;
        margin-top: 57px;

        > * {
            position: relative;
            flex: 0 0 176px;
            height: 292px;
            margin-right: 58px;

            > .bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 176px;
                height: 176px;
                border-radius: 50%;
                background: #002C8A;
            } 

            > .view {
                position: absolute;
                z-index: 101;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            > p {
                position: absolute;
                top: 205px;
                left: -20%;
                width: 140%;
                text-align: center; 
            }

            .rounded {
                background-color: #fff;
                background-size: 90px 90px;
                background-position: center center
            }

            .edit {
                position: absolute;
                z-index: 102;
                left: 50%;
                top: 260px;
                transform: translateX(-50%);
                background: #A8ABAE;
                text-decoration: none;
                font-size: 12px;
                line-height: 20px;
                border-radius: 10px;
                padding: 0 10px;
                color: #fff;
            }

            ${MoreBtnContainer} {
                .fill { 
                    fill: #002C8A;
                }
                .stroke {
                    stroke: #002C8A;
                }
            } 
        }
    }
`
const Index = ({ level, logout, folders }) => {
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
        function handleResize() {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    console.log('>>')
    console.log(folders)

    return (
        <>
            <Slide dimensions={dimensions}>
                <Overview className="centered">
                    <div className="list">
                        {teaser.map((teaser, index) => {

                            console.log(folders.foldersNames[teaser.name])

                            return (
                                <div className={'teaser' + (index + 1) + (teaser.target ? '' : ' deactive')}>
                                    {teaser.name &&
                                        <>
                                            <Link to={`/folders/${teaser.name}`} className="view" />
                                            <div className="bg" />
                                            <Image left={13.6} top={13.6} width={326} height={326} src={teaser.img} className="rounded border" />
                                            <p className="color1"><strong dangerouslySetInnerHTML={{ __html: folders.foldersNames[teaser.name] ? folders.foldersNames[teaser.name] : teaser.title }}></strong></p>
                                            <MoreBtn left={132} top={298} />
                                            <Link to={`/custom/${teaser.name}`} className="edit">editieren</Link>
                                        </>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </Overview>
            </Slide>
            <Footer />
        </>
    );
};

const mapStateToProps = (state, props) => {
    const { authentication, folders } = state

    console.log(authentication)

    return {
        level: authentication.level,
        folders
    }
}

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)