import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.png';
import img4 from './assets/img4.svg';
import img5 from './assets/img5.png';

import img61 from './assets/img61.svg';
import img6 from './assets/img6.svg';
import img7 from './assets/img7.svg';

import img8 from './assets/img8.png';

const Slide = styled.div`
    
    .box1 {
        padding-top: 110px;

        &.box1-1,
        &.box1-4 {
            padding-top: 148px;
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        p {
            position: relative;
            font-size: 53px;
            line-height: 50px;

            > * {
                display: block;
            }


            span {
                

                &.top {
                    margin-bottom: 5px;
                    font-size: 21px;
                    line-height: 24px;
                    font-weight: 600;
                }

                &.bottom {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
        }   
    }

    .box2 {
        padding-left: 75px;

        .exclamation {
            position: absolute;
            left: 25px;
            top: -13px;
            font-size: 97.5px;
            line-height: 122px;
            font-style: italic;
            text-align: right;
        }
    }

    .box3 {
        font-size: 53px;
        line-height: 66px;
    }

    .box4 {
        font-size: 18px;
        line-height: 20px;
    }

    .img6 {
        position: absolute;
        left: 396px;
        top: 378px;
        width: 50px;
    }

    .img8 {
        position: absolute;
        left: 426px;
        top: 176px;
        width: 42px;
        opacity: 0.2;
        transform: rotate(20deg)
    }

`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 203, props.active, props.preview)
    let count2 = useCounter(0, 319, props.active, props.preview, 1)
    let count3 = useCounter(0, 522, props.active, props.preview, 2)
    let count4 = useCounter(0, 203, props.active, props.preview, 3)
    let count5 = useCounter(0, 203, props.active, props.preview, 4)

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Duale Verwendung</strong> – die Zukunft der multifokalen Profitabilität</h1>

            <Container as="p" left={103} top={190} width={1540} className="color1 color1">
                <strong>Eine aktuelle Analyse zeigt:</strong>
            </Container>

            <Container left={254} top={1042} width={1540} height={172} className="box2 bg1 color1 flex-all-center color1">
                <span class="exclamation color3">!</span>
                <p>Multifokale Kontaktlinsenträger geben <strong>jährlich 2,6x mehr</strong> für<br />
                    die Sehkorrektion aus als Presbyope, die nur eine Brille tragen.<sup>+2</sup></p>
            </Container>

            <Container left={292} top={458} width={600} height={600} className="box1 box1-1 text-center color1 fadeIn animate2">
                <img src={img3} />
                <p>
                    <span className="top">Jahresausgaben<br />
                        duale Verwender</span>
                    <strong>{count3}</strong>
                    <span className="bottom">Euro</span>
                </p>
            </Container>

            <Container left={151} top={327} width={416} height={416} className="box1 box1-2 text-center color1">
                <img src={img1} />
                <p>
                    <strong>{count1}</strong>
                    <span className="bottom">Euro</span>
                </p>
            </Container>

            <Container left={616} top={327} width={416} height={416} className="box1 box1-3 text-center color1 fadeIn">
                <img src={img2} />
                <p>
                    <strong>{count2}</strong>
                    <span className="bottom">Euro</span>
                </p>
            </Container>

            <Container left={1266} top={458} width={600} height={600} className="box1 box1-4 text-center color1  fadeIn animate4">
                <img src={img5} />
                <p>
                    <span className="top">Jahresausgaben<br />
                        Brillenträger</span>
                    <strong>{count5}</strong>
                    <span className="bottom">Euro</span>
                </p>
            </Container>

            <Container left={1358} top={327} width={416} height={416} className="box1 box1-5 text-center color1 fadeIn animate3">
                <img src={img4} />
                <p>
                    <strong>{count4}</strong>
                    <span className="bottom">Euro</span>
                </p>
            </Container>

            <Image left={1029} top={433} width={199} height={510} src={img61} />

            <MoreBtn
                left={783}
                top={267}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')} widthHeart />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg overlay1 overlay1-1">
                <Container left={199} top={308} width={1250} height={707} className="box3 bg2 color1 border">

                </Container>

                <Image left={694} top={378} width={260} height={149} src={img7} />

                <img src={img8} className="img8" />

                <Container as="p" left={199} top={584} width={1250} className="text-center color1">
                    Multifokale Kontaktlinsenträger <strong>sind loyaler</strong><br />
                    gegenüber ihrem Optiker als reine Brillenträger.<sup>*3</sup>

                </Container>

                <img src={img6} className="img6" />

                <Container as="p" left={507} top={782} width={270} className="box3 color1 text-center">
                <strong>7/10</strong>
                </Container>
                

                <Container as="p" left={882} top={782} width={270} className="box3 color1 text-center">
                <strong>5/10</strong>
                </Container>

                <Container as="p" left={507} top={913} width={270} className="box4 color1 text-center">
                Dual-Träger
                </Container>
                

                <Container as="p" left={882} top={913} width={270} className="box4 color1 text-center">
                Brillenträger
                </Container>


            </Overlay>

        </Slide>
    );
};
