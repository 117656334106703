import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
                <ol>
                    <li>https://www.thebalance.com/u-s-companies-rated-aaa-higher-than-u-s-government-bonds-417105</li>

                    <li>http://www.jjmii.com.br/en/artigos/fact-sheet-da-companhia</li>

                    <li>https://www.investopedia.com/articles/investing/082316/top-6-pharmaceutical-companies-globally-jnj-nvs.asp</li>

                    <li>Bei Johnson &amp; Johnson Vision 2020 hinterlegte Daten. Luft-zu-Meer-Projekt</li>

                    <li>Bei Johnson &amp; Johnson Vision 2021 hinterlegte Daten. Angaben zur Energie- und Treibhausgasreduzierung für ACUVUE® OASYS 1-Day (90 Stück)</li>

                    <li>Besuchen Sie: www.environmentalleader.com/products_of_year/johnson-johnson/</li>

                    <li>Bei Johnson &amp; Johnson Vision 2021 hinterlegte Daten. Datennachweis zu Angaben über die ACUVUE®-Produktion aus recycelten Materialien</li>

                    <li>Besuchen Sie: www.terracycle.com/en-GB/brigades/acuvue.</li>

                    <li>Besuchen Sie www.environmentalleader.com/environmental-leader-product-project-awards-2017/</li>

                    <li>Bei Johnson &amp; Johnson Vision 2021 hinterlegte Daten. Datennachweis zu Angaben über dieZertifizierung von Papierverpackungen</li>

                    <li>Bei Johnson &amp; Johnson Vision 2021 hinterlegte Daten. Datennachweis zu Energieeffizienz- und Erneuerbare-Energie-Projekten in den ACUVU® E-Kontaktlinsen-Produktionsstätten
                        in den USA und Irland</li>

                    <li>Bei Johnson &amp; Johnson Vision 2020 hinterlegte Daten. Nachhaltigkeitsstatistiken der ACUVUE®-Kontaktlinsenherstellung</li>

                    <li>JJV Data on File 2021. Data Substantiation for Germany ACUVUE® Claims.</li>

                    <li>JJV Data on File 2020. ACUVUE® Brand EYE-INSPIRED™ INNOVATIONS</li>
                </ol>

                <p>* Survey among 150 Eye Care Professionals from Germany conducted from May 2021 to June 2021</p>

                <p>Wichtige Sicherheitsinformationen: ACUVUE® Kontaktlinsen sind für eine Sehkorrektion indiziert. Wie bei jeder Kontaktlinse können Augenprobleme, einschließlich Hornhautgeschwüre, auftreten. Bei einigen Trägern kann es zu leichten Irritationen, Juckreiz oder Unbehagen kommen. Kontaktlinsen sollten nicht bei Augeninfektionen oder anderen Augenkrankheiten verwendet werden.
                    Vollständige Informationen, einschließlich Kontraindikationen und Vorsichtsmaßnahmen, entnehmen Sie bitte der Gebrauchsanweisung oder besuchen Sie unsere J&amp;J-Website jnjvisioncare.de/at</p>

                <p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2 UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.<br />
                    UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken.<br />
                    UV- Transmission mit –1,00 Dioptrien Linse gemessen.</p>

                <p>ACUVUE®, ACUVUE® OASYS 1-Day, HydraLuxe® und EYE-INSPIRED™ sind eingetragene Marken von Johnson &amp; Johnson.<br />
                    © 2022 Johnson &amp; Johnson Medical GmbH / Johnson &amp; Johnson Medical Products GmbH</p>
            </TextBox>
        </Slide>
    );
};
