import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, StarBtn } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';

import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.svg';

import img6 from './assets/img6.jpg';
import img7 from './assets/img7.jpg';
import img8 from './assets/img8.jpg';


const Slide = styled.div`

    .box2 {
        ul {
            list-style: disc;
            margin: 0;
            margin-left: 20px;
            
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
                width: fit-content;
            }
        }
        
    }
    
    .box3 {
        padding-top: 15px;

        > p {
            position: relative;
            z-index: 201;
        }
    }

    .overlay1 {
        z-index: 200
    }

    .box3 {
        .number {
            position: absolute;
            display: block;
            left: 0px;
            top: -48px;
            font-size: 64px;
            line-height: 80px;
            color: #003087;
            font-style: italic;
            text-align: left;
        }

        p {
            font-size: 18px;
            line-height: 25px;
        }
    } 

    .img1 {
        position: absolute;
        left: 150px;
        top: 126px;
        width: 723px;
    }

    .img2 {
        position: absolute;
        left: 144px;
        top: 120px;
        width: 736px;
    }

    > .box4 {
        font-size: 28px;
        line-height: 31.5px;
        color: #9ce2e5;
    }

    .overlay1-1 {
       .box1 {
            background: #D2D3D8;

       }

       .box5 {
            color: #007DC5;
            font-size: 30px;
            line-height: 42px;
        }

       .box4 {
            padding-left: 66px;

            .number {
                position: absolute;
                left: 0px;
                width: 46px;
                height: 46px;
                border-radius: 99999px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "AvenirNext";
                font-weight: 700;
                background: #007DC5;
                color: #fff;
                font-size: 21px;

            }
        }

        .box6 {
            font-size: 10px;
        }
    }

    .overlay1-2 {
        .box2 {
            background-image: url(${img6});
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .overlay1-3 {
        .box2 {
            background-image: url(${img7});
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    .overlay1-4 {
        .box2 {
            background-image: url(${img8});
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Neue Kontaktlinsenkunden</strong> für das Optiker Geschäft</h1>

            <img src={img1} className="img1" />

            <img src={img2} className="img2" />

            <Container left={554} top={288} width={940} className="color1 flex-all-center text-center" as="p">
                <strong>Attraktive Kommunikation für Neuträger<br /> 
für den POS, CRM und Social Media Kanäle</strong>
            </Container>

            <Container left={315} top={452} width={1184} className="box4" as="p">
                <strong>INKLUSIVE</strong>
            </Container>

            <Container left={641} top={458} width={1184} className="color1 flex-all-center" as="p">
                <strong>Testträger-gesucht-Aktion mit Geld-Zurück-Mechanik</strong>
            </Container>

            <Container left={570} top={606} width={908} height={270} className="bg1 box2 color1 flex-all-center text text-center fadeInFromTop">
                <Arrow width={804} className="container-top" />
                <p><strong>Zielgruppe:</strong></p>
                <ul>
                    <li>Bestehende Träger aus anderen Kanälen</li>
                    <li>Unzufriedene Träger</li>
                    <li>an Kontaktlinsen interessierte Kunden</li>
                </ul>
            </Container>


            <Container left={113} top={1018} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate2">
                <span className="number">1</span>
                <p><strong>Testträger gesucht</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'} />
            </Container>

            <Container left={577} top={1018} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate3">
                <span className="number">2</span>
                <p><strong>POS</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay2')}
                    active={state.overlay1 === 'overlay2'} />
            </Container>

            <Container left={1041} top={1018} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate4">
                <span className="number">3</span>
                <p><strong>Newsletter</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay3')}
                    active={state.overlay1 === 'overlay3'} />
            </Container>

            <Container left={1505} top={1018} width={444} height={132} className="box3 bg1 color1 text-center fadeInFromTop animate5">
                <span className="number">4</span>
                <p><strong>Social Media</strong></p>
                <MoreBtn
                    className="x-center y-bottom"
                    onClick={() => toggleOverlay('overlay4')}
                    active={state.overlay1 === 'overlay4'} />
            </Container>

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 overlay1-1 with-bg bg3">
                <Container left={114} top={240} width={1835} height={914} className="box1 bg2 color1">
                   
                </Container>

                <Image left={133} top={449} width={822} height={612} src={img3} />
                <Image left={1222} top={354} width={674} height={258} src={img4} />
                <Image left={1240} top={930} width={131} height={165} src={img5} />

                <Container left={171} top={278} width={1200} className="box5" as="p">
                1.111 Testträger gesucht<br />
<strong>so einfach geht´s:</strong>
                </Container>

                <Container left={762} top={410} width={890} height={96} className="color1 box4 flex-all-center" as="p">
                    <span className="number number1">1</span>
                    Ihr Kunde kauft<br /> 
2 Aktionsprodukte 
                </Container>

                <Container left={840} top={547} width={950} height={96} className="color1 box4 flex-all-center" as="p">
                    <span className="number number1">2</span>
                    Er scannt den QR-Code, meldet sich an 
lädt den Kassenbeleg + Produktfoto hoch
                </Container>

                <Container left={918} top={682} width={890} height={96} className="color1 box4 flex-all-center" as="p">
                    <span className="number number1">3</span>
                    30 Tage testen
                </Container>

                <Container left={840} top={822} width={1150} height={96} className="color1 box4 flex-all-center">
                    <span className="number number1">4</span>
                    <p>Ihr Kunde beantwortet Fragen zu seinem <strong>MAX</strong><br />
                    Seherlebnis und erhält Rückerstattungs-Code</p>
                </Container>

                <Container left={762} top={958} width={890} height={96} className="color1 box4 flex-all-center" as="p">
                    <span className="number number1">5</span>
                    Geld zurückerhalten
                </Container>

                <Container left={1458} top={1108} width={470} className="label text-center color1 box6" as="span">
                Produktabbildungen nur zu Illustrationszwecken
                </Container>


                <Container left={113} top={1018} width={444} height={132} className="box3 color1 text-center">
                    <p><strong>Testträger gesucht</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'} />
                </Container>



            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="overlay1 overlay1-2 with-bg bg3">
                <Container left={577} top={240} width={1371} height={914} className="box2 bg2 color1">
                </Container>
                
                <Container left={1464} top={340} width={500} className="color1" as="p">
                Poster<br />
Fensterkleber<br />
Aktionsflyer<br />
Tresenaufsteller<br />
Maxi-Pack
                </Container>
                

                <Container left={577} top={1018} width={444} height={132} className="box3 color1 text-center">
                    <p><strong>POS</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay2')}
                        active={state.overlay1 === 'overlay2'} />
                </Container>
            </Overlay>

         


            <Overlay active={state.overlay1 === 'overlay3'} className="overlay1 overlay1-3 with-bg bg3">
                <Container left={1041} top={240} width={444} height={914} className="box2 bg2 color1">
                </Container>
                
              

                <Container left={1041} top={1018} width={444} height={132} className="box3 color1 text-center">
                    <p><strong>Newsletter</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay3')}
                        active={state.overlay1 === 'overlay3'} />
                </Container>

            </Overlay>
            
            <Overlay active={state.overlay1 === 'overlay4'} className="overlay1 overlay1-4 with-bg bg3">
                <Container left={1505} top={240} width={444} height={914} className="box2 bg2 color1">
                </Container>
                
               

                <Container left={1505} top={1018} width={444} height={132} className="box3 color1 text-center">
                    <p><strong>Social Media</strong></p>
                    <MoreBtn
                        className="x-center y-bottom"
                        onClick={() => toggleOverlay('overlay4')}
                        active={state.overlay1 === 'overlay4'} />
                </Container>

            </Overlay>
            

        </Slide>
    );
};
