import React from "react";
import styled from "styled-components";

import { Container, Image } from "components/parts";

import bg from "./assets/bg.jpg";
import img1 from "./assets/img1.png";

const Slide = styled.div`
  background: url(${bg});
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;

  h1 {
    font-size: 40px;
    line-height: 43px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #fff;
    margin: 0;
    padding-bottom: 20px;
  }

  .box1 {
    font-size: 27.5px;
    line-height: 32.5px;
  }

  .box2 {
    font-size: 9.5px;
    line-height: 1;
  }
`;

const Folder19Slide1 = ({ ...props }) => {
  return (
    <Slide className="bg full">
      <div className="centered">
        <Container left={501} top={182} width={1044} as="h1">
          <strong>
            Torische Linsen
            <br />
            wie keine anderen
          </strong>
        </Container>

        <Container
          left={387}
          top={452}
          width={1274}
          className="box1 text-center"
        >
          mit dem einzigartigen
          <br />
          Lidschlag-Stabilisations-Design von ACUVUE®<sup>1</sup>
        </Container>

        <Image src={img1} width={1498} height={519} left={290} top={659} />

        <Container
          left={581}
          top={1206}
          width={885}
          className="box2 text-center"
        >
          Produktabbildungen nur zu Illustrationszwecken
        </Container>
      </div>
    </Slide>
  );
};

export default Folder19Slide1;
