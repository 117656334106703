import React from 'react';
import styled, { css } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

export const Arrow2Container = styled.svg`
    position: absolute;

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}
`

export const Arrow2 = (props) => {
    return (
        <Arrow2Container viewBox="0 0 150.28 147.17" width="75" height="73.5" left={props.left} top={props.top} className={props.className}>
 
                
                    <path d="M145.71,67.57,59.9,2.87c-3.67-2.76-8.31.6-8.31,6V28H8.76a6.9,6.9,0,0,0-6.88,6.87v77.53a6.9,6.9,0,0,0,6.88,6.87H51.59v19.07c0,5.41,4.64,8.77,8.31,6l85.81-64.7c3.59-2.71,3.59-9.32,0-12" transform="translate(0 0)" fill="#fff" />
                    <path d="M145.71,67.57,59.9,2.87c-3.67-2.76-8.31.6-8.31,6V28H8.76a6.9,6.9,0,0,0-6.88,6.87v77.53a6.9,6.9,0,0,0,6.88,6.87H51.59v19.07c0,5.41,4.64,8.77,8.31,6l85.81-64.7C149.3,76.89,149.3,70.28,145.71,67.57Z" transform="translate(0 0)" fill="none" stroke="#f49922" className="stroke" strokeMiterlimit="10" strokeWidth="3.76" />
        </Arrow2Container>
    );
};

export default Arrow2;