import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';

const Slide = styled.div`
    
    .box1 {
        padding: 50px 10px 10px 12px;
    }
    
    .box1 .number {
        position: absolute;
        display: block;
        left: 2px;
        top: -68px;
        font-size: 97.5px;
        line-height: 122px;
        color: #003087;
        font-style: italic;
        text-align: right;
    } 

    .box1-2 .number {
        left: 16px;
    }

    .box1-3 .number {
        left: 16px;
    }

    .box1-4 .number {
        left: 16px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Warum die <strong>„Mein Optiker-Kampagne“</strong> so wichtig ist</h1>



            <Container left={1465} top={370} width={390} height={763} className="box1 box1-4 bg1 color1 fadeInFromLeft animate3">
                <span className="number">4</span>
                <p><strong>Die Erwartung</strong> zeitnah <strong>einen
                    Termin</strong> zur Bera-<br />
                    tung <strong>zu erhalten</strong> – nämlich inner-<br />
                    halb von<br />
                    2 Wochen.<sup>3</sup>

                </p>
                <Image left={140} top={559} width={110} height={113} src={img3} />
            </Container>

            <Container left={1041} top={370} width={390} height={763} className="box1 box1-3 bg1 color1 fadeInFromLeft animate2">
                <span className="number">3</span>
                <p><strong>Das Bedürfnis</strong> der Mehrheit<br />
                    der neuen Kontaktlinsen-
                    Träger <strong>das<br />
                    beste Produkt</strong><br />
                    für die Augen<br />
                    <strong>zu erhalten</strong>.<sup>3</sup>

                </p>
                <Arrow left={387} top={85} width={600} className="" bgColor={'#CFF2FB'} />
                <Image left={105} top={573} width={172} height={99} src={img2} />
            </Container>

            <Container left={616} top={370} width={390} height={763} className="box1 box1-2 bg1 color1 fadeInFromLeft">
                <span className="number">2</span>
                <p><strong>Die Suche</strong> des potenziellen
                    Kontaktlinsen-
                    Trägers <strong>nach Kompetenz</strong><br />
                    beim Optiker.<sup>1</sup>
                </p>
                <Arrow left={387} top={85} width={600} className="" bgColor={'#CFF2FB'} />
                <Image left={147} top={566} width={99} height={106} src={img1} />
            </Container>

            <Container left={191} top={370} width={390} height={763} className="box1 box1-1 bg1 color1">
                <span className="number">1</span>
                <p><strong>Die weiterhin
                    hohe Anzahl an Menschen,</strong> die sich eine Linse, einen Check-Up, einen Wechsel des Produktes
                    wünschen und
                    keine Beratung
                    erhalten.<sup>1, 2</sup></p>
                <Arrow left={387} top={85} width={600} className="" bgColor={'#CFF2FB'} />
            </Container>

        </Slide>
    );
};
