import React from 'react';
import img1 from './diagram.svg'

const Diagram = () => {
    return (
        <div className="img1">
            <img src={img1} />
            <div className="bars">
                <div className="bar bar1 animate1" />
                <div className="bar bar2 animate1" />

                <div className="bar bar3 animate2" />
                <div className="bar bar4 animate2" />

                <div className="bar bar5 animate3" />
                <div className="bar bar6 animate3" />

                <div className="bar bar7 animate4" />
                <div className="bar bar8 animate4" />

                <div className="bar bar9 animate5" />
                <div className="bar bar10 animate5" />

                <div className="bar bar11 animate6" />
                <div className="bar bar12 animate6" />

                <div className="bar bar13 animate7" />
                <div className="bar bar14 animate7" />

                <div className="bar bar15 animate8" />
                <div className="bar bar16 animate8" />
            </div>
        </div>
    )
};

export default Diagram;