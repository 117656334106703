import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';

const Slide = styled.div`
    .box1 {
        font-size: 37px;
        line-height: 42.5px;
    }

    .box2 {
        border-radius: 15px;
        border: 1px solid #003087;
        background: #fff;
    }

    p sub {
        vertical-align: sub;
        font-size: 10px;
    }

    .box3 a {
        text-decoration: none;
    }
   
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Nachhaltigkeit</strong></h1>
            <Image src={img1} left={287} top={70} width={1316} height={1176} />

            <Container as="p" left={474} top={398} width={1123} className="color1 box1 text-center">
                <strong> Eine<br />
                    Sehenswerte<br />
                    Welt</strong>
            </Container>

            <Container as="p" left={474} top={677} width={1123} className="color1 text-center box3">
                Mehr über die Nachhaltigkeit<br />
                bei ACUVUE®: <a href="https://www.acuvue.de/warum-acuvue/nachhaltige-kontaktlinsen" target="_blank" rel="noreferrer" className="color1"><strong>www.acuvue.de/<br />
                    nachhaltige-kontaktlinsen</strong></a>
            </Container>

            <MoreBtn
                left={351} top={721}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')}
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                <Container left={124} top={313} width={1516} height={449} className="box2">
                </Container>

                <Image src={img2} left={333} top={334} width={1088} height={130} />

                <Container as="p" left={77} top={471} width={631} className="color1 small text-center">
                    13% geringerer CO<sub>2</sub>-<br />
                    Ausstoß durch Umstellung<br />
                    von Luft- auf Seefracht<br />
                    beim Transport von<br />
                    Kontaktlinsen<sup>4</sup>
                </Container>

                <Container as="p" left={546} top={471} width={631} className="color1 small text-center">
                    Reduzierung des<br />
                    Energiebedarfs bei der<br />
                    Herstellung von<br />
                    ACUVUE® OASYS 1-Day<br />
                    (90er-Packung) um 12%<sup>5</sup>
                </Container>

                <Container as="p" left={1035} top={471} width={631} className="color1 small text-center">
                    60% weniger Papier-<br />
                    verpackungen für<br />
                    ACUVUE® OASYS 1-Day<br />
                    Kontaktlinsen (90er Pack)<br />
                    (in USA hergestellt)<sup>6</sup>
                </Container>
            </Overlay>

            <MoreBtn
                left={1557} top={138}
                active={state.overlay1 === 'overlay2'}
                onClick={() => toggleOverlay('overlay2')}
            />

            <Overlay active={state.overlay1 === 'overlay2'} className="with-bg">
                <Container left={1283} top={180} width={631} height={1020} className="box2">
                </Container>

                <Image src={img3} left={1539} top={231} width={130} height={765} />

                <Container as="p" left={1283} top={362} width={631} className="color1 small text-center">
                    100% FSC-, PEFC- oder SFI-<br />
                    zertifizierte Papier- und<br />
                    Zellstoffverpackungen<br />
                    (Schachtel und Packungsbeilage)<br />
                    für alle ACUVUE®-Kontaktlinsen<sup>10</sup>
                </Container>

                <Container as="p" left={1283} top={681} width={631} className="color1 small text-center">
                    38.000 Tonnen CO<sub>2</sub> werden<br />
                    jährlich durch Programme für<br />
                    erneuerbare Energien und höhere<br />
                    Energieeffizienz eingespart<sup>11</sup>
                </Container>

                <Container as="p" left={1283} top={1004} width={631} className="color1 small text-center">
                    100% erneuerbarer Strom<br />
                    aus Windkraft an unserem<br />
                    ACUVUE®-Produktionsstandort<br />
                    in Limerick<sup>12</sup>
                </Container>

            </Overlay>

            <MoreBtn
                left={1588} top={1038}
                active={state.overlay1 === 'overlay3'}
                onClick={() => toggleOverlay('overlay3')}
            />

            <Overlay active={state.overlay1 === 'overlay3'} className="with-bg">
                <Container left={124} top={824} width={1505} height={411} className="box2">
                </Container>

                <Image src={img4} left={333} top={854} width={1065} height={130} />

                <Container as="p" left={77} top={991} width={631} className="color1 small text-center">
                    90% der nicht<br />
                    verwendeten Materialien<br />
                    in den ACUVUE®<br />
                    Produktionsstätten<br />
                    recycelt<sup>7</sup>
                </Container>

                <Container as="p" left={540} top={991} width={631} className="color1 small text-center">
                    5 Millionen recycelte<br />
                    Kontaktlinsen und<br />
                    Blisterverpackungen in<br />
                    Großbritannien<sup>8</sup>
                </Container>

                <Container as="p" left={1013} top={991} width={631} className="color1 small text-center">
                    ACUVUE® OASYS 1-Day<br />
                    von Environmental Leader<br />
                    als Produkt des Jahres 2017<br />
                    für nachhaltige Verpackung<br />
                    ausgezeichnet<sup>9</sup>
                </Container>

            </Overlay>

        </Slide>
    );
};
