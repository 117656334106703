import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.png';



const Slide = styled.div`
    .box1 {
        font-weight: 600;

        span {
            font-size: 43.5px;
            line-height: 43.5px;
        }
    }

    .box2 {
        font-weight: 600;
        color: #fff;

        > * {
            display: block;
        }

        span {
            font-size: 10px;
            line-height: 12px;
        }

        strong {
            font-size: 32.8px;
            line-height: 29.8px;
            font-weight: 600;
        }
    }

    .box3 {
        padding-top: 36px;
        color: #fff;

        p + p {
            margin-top: 6px;
            font-size: 14px;
            line-height: 17.5px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Wir treten in Vorleistung, Sie profitieren doppelt –<br /> 
<strong>Unser attrakives Konditionenmodell für 2022</strong></h1>
            <h2 className="small">Lassen Sie uns unsere partnerschaftliche Zusammenarbeit auch in 2022 weiter ausbauen.</h2>

            <Image left={452} top={349} width={1185} height={382} src={img2} contain />

            <Container as="p" left={452} top={528} width={309} className="box1 small color1 text-center">
                <span>0-11%</span><br />Umsatz-<br />
rabatt
            </Container>

            <Container as="p" left={743} top={528} width={309} className="box1 small color1 text-center">
                <span>9%</span><br />Anpass-<br />
rabatt
            </Container>

            <Container as="p" left={1034} top={528} width={309} className="box1 small color1 text-center">
                <span>5%</span><br />Partner-<br />
rabatt
            </Container>

            <Container as="p" left={1324} top={528} width={309} className="box1 small color1 text-center">
                <span>5%</span><br />Wachstums-<br />
rabatt
            </Container>

            <Container as="p" left={100} top={766} width={1848} className="color1 text-center">
                <strong>Die Vorraussetzung zum Erhalt des Partnerrabatts ist das Erfüllen<br />
der folgenden drei Bausteine in 2021</strong>
            </Container>

            <div className="container-animate fadeInFromTop">
                <Arrow left={1002} top={179} width={1489} className="rotate90" />

                <Image left={164} top={986} width={551} height={260} src={img1} />
                <Image left={746} top={986} width={551} height={260} src={img1} />
                <Image left={1330} top={986} width={551} height={260} src={img1} />

                <Container left={164} top={986} width={551} height={260} className="box3 text-center">
                    <p><strong>Beziehung</strong></p>
                    <p>Vier Quartalsgespräche mit dem<br />
Entscheider der Kontaktlinsenabteilung</p>
                </Container>
                <Container left={746} top={986} width={551} height={260} className="box3 text-center">
                    <p><strong>Weiterbildung</strong></p>
                    <p>Individuelles Produkt-Training mit Ihrem<br />
ACUVUE® Account Manager oder<br />
ein J&amp;J Institute Online-Seminar</p>
                </Container>
                <Container left={1330} top={986} width={551} height={260} className="box3 text-center">
                    <p><strong>Marketing</strong></p>
                    <p>Durchführung einer ausgewählten<br />
Marketing-Kampagne</p>
                </Container>
            </div>
        </Slide>
    );
};
