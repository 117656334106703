import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.png';

import img9 from '../slide2/assets/img9.png';

const Slide = styled.div`
    .img1 {
        position: absolute;
        left: 100px;
        top: 193px;
        width: 820px;
    }

    .box1 {
        border-radius: 50%;
        color: #fff;
    }
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Videos, On-Demand und Live-Seminare,<br /> 
<strong>mit denen Sie Ihre Expertise ausbauen können</strong></h1>
            <h2 className="small">Wir ermöglichen lebenslanges Lernen für alle, für die die Gesundheit anderer<br /> 
an erster Stelle steht.</h2>
            <img src={img1} className="img1" />

           

            <Image left={582} top={532} width={893} height={126} src={img2} />
            <Container left={291} top={749} width={344} height={344} className="bg2 box1 flex flex-all-center text-center">
                <p><strong>Generisch</strong></p>
            </Container>
            <Container left={665} top={749} width={344} height={344} className="bg2 box1 flex flex-all-center text-center">
                <p><strong>Evidenz<br />
basiert</strong></p>
            </Container>
            <Container left={1039} top={749} width={344} height={344} className="bg2 box1 flex flex-all-center text-center">
                <p><strong>Offen für alle</strong></p>
            </Container>
            <Container left={1413} top={749} width={344} height={344} className="bg2 box1 flex flex-all-center text-center">
                <p><strong>CREDO</strong></p>
            </Container>
        </Slide>
    );
};
