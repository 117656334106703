import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState, useCounter } from "components/hooks";

import {
  Arrow,
  Container,
  CircleCount,
  Image,
  MoreBtn,
  Overlay,
  CloseBtn,
} from "components/parts";

import HeaderDots from "../../shared/HeaderDots";

import img1 from "./assets/img1.png";
import img2 from "./assets/img2.png";

const Slide = styled.div`
  inset: 0;
  width: 100%;
  height: 100%;
  font-family: "AvenirNext", sans-serif;

  .box1 {
    line-height: 22px;
  }

  .copy {
    font-size: 9.5px;
  }

  .bg {
    background: #0a7cc1;
    z-index: -1;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 43%;
  }

  .disrupter {
    background: #ff6a39;
    font-size: 12.3px;
    line-height: 18px;
    border: 1.4px solid #fff;
    transform: rotate(-16deg);
  }

  .copy-2 {
    font-size: 8px;
    line-height: 10px;
  }
`;
const inititialState = {
  overlay1: null,
  video: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay, updateState } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide step={props.step}>
      <div className="centered">
        <h1 className="color2">
          ACUVUE® ist die einzige torische Kontaktlinsenmarke mit{" "}
          <strong>Lidschlag-Stabilisations-Design</strong>
          <sup>1</sup>
        </h1>

        <HeaderDots top={268} color={"#051f4a"} />

        <Container
          left={100}
          top={298}
          width={1900}
          className="box1 small color2 fadeIn"
        >
          ACUVUE® Kontaktlinsen für Astigmatismus mit
          Lidschlag-Stabilisations-Design bieten
          <br />
          <strong>
            hervorragenden Tragekomfort und klare, stabile Sicht den ganzen Tag
            über.<sup>1</sup>
          </strong>
        </Container>

        <Container
          left={100}
          top={448}
          width={1900}
          className="box1 small color2 fadeIn animate2"
        >
          ACUVUE® Kontaktlinsen für Astigmatismus haben{" "}
          <strong>
            eine durchschnittliche Erfolgsquote
            <br />
            bei der ersten Anpassung von 95 %.<sup>*1</sup>
          </strong>
        </Container>

        <Image src={img1} width={866} height={412} left={134} top={700} />
        <Image src={img2} width={904} height={395} left={1000} top={727} />

        <Container
          left={586}
          top={588}
          width={224}
          height={224}
          className="disrupter color2 flex flex-all-center text-center color3 rounded-full"
        >
          wieder <strong>vollumfänglich erhältlich</strong>
        </Container>

        <Container
          left={575}
          top={1119}
          width={885}
          className="copy color3 text-center"
        >
          Produktabbildungen nur zu Illustrationszwecken
        </Container>

        <Container left={100} top={1240} width={1290} className="copy-2 color3">
          * Criteria for first fit success: rotation ≤ 20 degrees, lens
          stability ≤ 5 degrees movement on blink; acceptable general fitting
          characteristics, no need for power or axis adjustment.
        </Container>
      </div>
      <div className="bg bg1" />
    </Slide>
  );
};
