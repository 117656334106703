import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useCounter } from 'components/hooks'

import bg from '../slide10/assets/bg.jpg'
import img1 from './assets/img1.png'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 9px;
        line-height: 12.5px;
    }

    .text2 {
        font-size: 25px;
        line-height: 30px;
        text-transform: uppercase;
    }

    .text3 {
        line-height: 27.5px;

        .count {
            font-size: 35px;
            color: #fff;
            font-weight: 700;
        } 
    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }
`

const Folder2Slide1 = ({ ...props }) => {

    let count1 = useCounter(0, 93, props.active, props.preview, 0)
    let count2 = useCounter(0, 84, props.active, props.preview, 0.5)

    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Die wichtigsten Vorteile<br />
                    <strong>für Neuträger</strong></h1>
                <Image left={138} top={560} width={1064} height={696} src={img1} />
                <Container left={288} top={978} width={858} as="p" className="text1 text-center">
                    Nur zu Illustrationszwecken
                </Container>

                <Container left={1205} top={440} width={800} as="p" className="text2">
                    <strong>Hervorragende
                        Handhabung</strong>'<sup>13</sup>
                </Container>

                <div className="container-animate fadeInFromTop">

                    <Arrow left={1434} top={406} width={488} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={1205} top={689} width={800} as="p" className="text3">
                        <span className="count">{count1}%</span> finden das<br />
                        Einsetzen einfach"<sup>13</sup><br /><br />
                        <span className="count">{count2}%</span> finden das<br />
                        Herausnehmen einfach<sup>$13</sup>
                    </Container>

                </div>


            </Slide>
        </>

    );
};

export default Folder2Slide1;