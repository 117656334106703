import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, PlayBtn } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.svg';

const handAnimation = keyframes`
    0% {
        transform: translate(-10px, 10px);
    }
    100% {  
        transform: translate(0, 0);
    }
`

const Slide = styled.div`
    .hand {
        position: absolute;
        width: 41px;

        ${({ active, preview }) => (active && !preview) && css`
                animation: ${handAnimation} 1.3s infinite;
                animation-delay: 0.6s;
            `}

        &.hand1 {
            left: 722px;
            top: 318px;
        }

        &.hand2 {
            left: 256px;
            top: 544px;
        }

        &.hand3 {
            left: 578px;
            top: 549px;
        }     
    }

    .box1 {
        z-index: 100;
    }

    .box2 {
        z-index: 101;

        a {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1>Weitere <strong>Unterstützung</strong> für Sie durch unsere<br />
                ACUVUE® Account Manager und Training-Teams</h1>

            <Image left={156} top={508} width={1734} height={673} src={img1} />

            <Container as="p" left={42} top={354} width={650} className="color1 text-center">
                <strong>9 kurze How-To-Videos</strong><br />
                für die erfolgreiche<br />
                multifokale Anpassung
            </Container>

            <PlayBtn left={1016} top={583} className="play" />

            <Container as="p" left={739} top={354} width={650} className="color1 text-center">
                <strong>Online-Seminare On-Demand</strong><br />
                zum Thema Multifokal und<br />
                ACUVUE® OASYS MULTIFOCAL
            </Container>

            <PlayBtn left={323} top={583} className="play" />

            <Container as="p" left={1347} top={354} width={650} className="color1 text-center">
                <strong>Live-Seminare</strong><br />
                zum Thema<br />
                Multifokal
            </Container>

            <img src={img2} className="hand hand1" />

            <Container as="p" left={418} top={801} width={650} className="color1 text-center">
                <strong>MULTIFOKAL-Rechner</strong><br />
                für die Unterstützung beim<br />
                Anpassprozess
            </Container>

            <img src={img2} className="hand hand2" />

            <Container as="p" left={1048} top={801} width={650} className="color1 text-center">
                <strong>ePED-Team </strong><br />
                für technische Produkt-<br />
                Fragen und Soforthilfe
            </Container>

            <img src={img2} className="hand hand3" />

            <Container left={42} top={354} width={650} height={400} className="box1 bg2 fadeOut" />
            <Container left={739} top={354} width={650} height={400} className="box1 bg2 fadeOut animate2" />
            <Container left={1347} top={354} width={650} height={400} className="box1 bg2 fadeOut animate3" />
            <Container left={418} top={801} width={650} height={400} className="box1 bg2 fadeOut animate4" />
            <Container left={1048} top={801} width={650} height={400} className="box1 bg2 fadeOut animate5" />

            <Container left={42} top={354} width={650} height={400} className="box2"><a href="https://www.jnjvisioncare.de/9-kurzvideos-fuer-ihren-erfolg" target="_blank"></a></Container>
            <Container left={739} top={354} width={650} height={400} className="box2"><a href="https://www.jnjvisioncare.de/on-demand-seminare" target="_blank"></a></Container>
            <Container left={1347} top={354} width={650} height={400} className="box2"><a href="https://www.jnjvisioncare.de/online-seminare" target="_blank"></a></Container>
            <Container left={418} top={801} width={650} height={400} className="box2"><a href="https://www.jnjvisioncare.de/5minfit" target="_blank"></a></Container>
            <Container left={1048} top={801} width={650} height={400} className="box2"><a href="https://forms.office.com/r/3W7r8apk9r" target="_blank"></a></Container>

        </Slide>
    );
};
