import React from "react";
import styled from "styled-components";

import { Image, Container } from "components/parts";

import bg from "./../slide12/assets/bg.jpg";
import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.svg";
import img3 from "./assets/img3.png";

const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "AvenirNext", sans-serif;

  h1 {
    color: #0a7cc1 !important;
    margin-top: 35px;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
  }

  .box1 {
    font-size: 18px;
    line-height: 25px;
  }

  .btn1 {
    text-decoration: none;
    font-size: 16.5px;
    line-height: 20px;
    font-weight: bold;
    border-radius: 25px;
    text-transform: uppercase;
    text-align: center;
  }

  .box2 {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 18px;
  }

  .bg {
    position: absolute;
    background: url(${bg});
    background-size: cover;
    inset: 0 0 85px 0;
  }
`;

export default ({ ...props }) => {
  return (
    <Slide className="bg full">
      <div className="bg" />
      <div className="centered">
        <h1>
          So erreichen Sie uns
          <br />
          <strong>Direkt. Persönlich. Schnell.</strong>
        </h1>

        <Image src={img2} width={258} height={595} left={242} top={382} />

        <Container left={566} top={406} width={332} className="box1 color2">
          Welche Fragen
          <br />
          haben Sie bei
          <br />
          der Kontaktlinsen-
          <br />
          anpassung?
        </Container>

        <Container left={566} top={754} width={410} className="box1 color2">
          <strong>Sofort-Hilfe:</strong> Unsere Experten melden sich
          schnellstmöglich bei Ihnen.
        </Container>

        <Container left={566} top={754} width={410} className="box1 color2">
          <strong>Sofort-Hilfe:</strong> Unsere Experten melden sich
          schnellstmöglich bei Ihnen.
        </Container>

        <Image src={img1} width={485} height={747} left={1361} top={312} />

        <Container
          left={1075}
          top={487}
          width={372}
          height={372}
          className="box2 color3 bg3 rounded-full flex flex-all-center text-center scaleIn"
        >
          <div>
            Das
            <br />
            professionelle
            <br />
            ACUVUE® Team
            <br />
            <strong>hilft Ihnen bei Anpassfragen</strong>
          </div>
        </Container>

        <Image src={img3} width={206} height={206} left={1240} top={809} />

        <Container
          as="a"
          href="https://forms.office.com/pages/responsepage.aspx?id=M0vJOjWRIUiVAur9plkqNSYuTcSC1zxGq0M7FhJLP3lUMUc2QlBHVEE4VE1WTEpIR1NKTTM4WTY4MC4u&route=shorturl"
          target="_blank"
          width={600}
          height={110}
          left={724}
          top={1098}
          className="btn1 color3 bg1 flex flex-all-center"
        >
          ACUVUE®
          <br />
          Kontaktformular&nbsp;&nbsp;&nbsp;&gt;
        </Container>
      </div>
    </Slide>
  );
};
