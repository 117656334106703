import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./Folder.module.sass";
import Slide from "./Slide";
import Footer from "./Footer";
import * as Folder1 from "./folder1";
import * as Folder2 from "./folder2";
import * as Folder3 from "./folder3";
import * as Folder4 from "./folder4";
import * as Folder5 from "./folder5";
import * as Folder6 from "./folder6";
import * as Folder7 from "./folder7";
import * as Folder8 from "./folder8";
import * as Folder9 from "./folder9";
import * as Folder10 from "./folder10";
import * as Folder11 from "./folder11";
import * as Folder12 from "./folder12";
import * as Folder13 from "./folder13";
import * as Folder14 from "./folder14";
import * as Folder15 from "./folder15";
import * as Folder16 from "./folder16";
import * as Folder17 from "./folder17";
import * as Folder18 from "./folder18";
import * as Folder19 from "./folder19";

/*
const Themes = {
    theme1: {
        color1: '#009DA8',
        bg1: '#D0F1F2',
        bg2: '#BFECEE',
    },
    theme2: {
        color1: '#00a3e0',
        bg1: '#CFF2FB',
        bg2: '#00a3e0',
    },
    theme3: {
        color1: '#df9933',
        bg1: '#FDE1C2',
        bg2: '#FEF0E0',
    }
}
*/

const components = {
  ...Folder1.Slides,
  ...Folder2.Slides,
  ...Folder3.Slides,
  ...Folder4.Slides,
  ...Folder5.Slides,
  ...Folder6.Slides,
  ...Folder7.Slides,
  ...Folder8.Slides,
  ...Folder9.Slides,
  ...Folder10.Slides,
  ...Folder11.Slides,
  ...Folder12.Slides,
  ...Folder13.Slides,
  ...Folder14.Slides,
  ...Folder15.Slides,
  ...Folder16.Slides,
  ...Folder17.Slides,
  ...Folder18.Slides,
  ...Folder19.Slides,
};

const getSlides = (folder, customFolders) => {
  let slides = [];

  switch (folder) {
    case "wir-sind-johnson-%26-johnson":
      slides = Folder1.Order;
      break;
    case "familie":
      slides = Folder2.Order;
      break;
    case "zufriedene-kunden":
      slides = Folder3.Order;
      break;
    case "wirtschaftlichkeit":
      slides = Folder4.Order;
      break;
    case "trends":
      slides = Folder5.Order;
      break;
    case "unsere-unterstuetzung":
      slides = Folder6.Order;
      break;
    case "covid-19":
      slides = Folder7.Order;
      break;
    case "kampagnen":
      slides = Folder8.Order;
      break;

    case "multifocal":
      slides = Folder9.Order;
      break;

    case "multifocal2":
      slides = Folder10.Order;
      break;

    case "die-grosse-mach-es-jetzt-aktion":
      slides = Folder11.Order;
      break;

    case "max-family":
      slides = Folder12.Order;
      break;

    case "max-multifocal":
      slides = Folder13.Order;
      break;

    case "max-kampagne":
      slides = Folder14.Order;
      break;

    case "mein-optiker-2023":
      slides = Folder15.Order;
      break;

    case "max-testtraeger":
      slides = Folder16.Order;
      break;

    case "konditionsvereinbarung":
      slides = Folder17.Order;
      break;

    case "multifocal-2024":
      slides = Folder18.Order;
      break;

    case "torische-linsen":
      slides = Folder19.Order;
      break;

    case "custom-a":
    case "custom-b":
    case "custom-c":
    case "custom-d":
    case "custom-e":
    case "custom-f":
      if (customFolders.folders[folder]) {
        slides = customFolders.folders[folder];
      }

      break;

    default:
  }

  return slides.map((slide, index) => {
    const component = components[slide.component];

    return {
      ...slide,
      index,
      currentStep: 1,
      steps: component.steps,
      footer: component.footer,
      theme: component.theme,
    };
  });
};

const getCurrentSlide = (slideOrder, key) => {
  if (!slideOrder.length) return null;

  let index = slideOrder.findIndex((item) => item.key === parseInt(key));
  console.log(index);
  if (index === -1) index = 0;

  let slide = slideOrder[index];

  const component = components[slide.component];

  return {
    ...slide,
    index,
    currentStep: 1,
    steps: component.steps,
    footer: component.footer,
  };
};

const Index = ({ customFolders }) => {
  const { folder, slideKey } = useParams();
  const history = useHistory();

  const slideOrder = useMemo(
    () => getSlides(folder, customFolders),
    [folder, customFolders]
  );
  console.log(slideOrder);

  const [touching, setTouching] = useState({
    isTouching: false,
    touchXstart: null,
    touchYstart: null,
    touchX: null,
  });

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [currentSlide, setCurrentSlide] = useState(
    getCurrentSlide(slideOrder, slideKey)
  );

  useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentSlide(getCurrentSlide(slideOrder, slideKey));
  }, [slideKey]);

  const gotoSlide = (direction) => {
    const nextSlide = slideOrder[currentSlide.index + direction];
    console.log(currentSlide);
    console.log(direction);

    if (nextSlide) {
      history.push(`/folders/${folder}/${nextSlide.key}`);
    }
  };

  const handleTouchStart = (e) => {
    console.log("handleTouchStart");
    console.log(e);

    const prevent =
      typeof e.target.className === "string" &&
      e.target.className.indexOf("dragable") > -1;

    if (prevent) return;

    setTouching({
      ...touching,
      touchXstart: e.touches[0].clientX,
      touchYstart: e.touches[0].clientY,
    });
  };

  const handleTouchMove = (e) => {
    let touchX = e.touches[0].clientX - touching.touchXstart;
    let touchY = e.touches[0].clientY - touching.touchYstart;

    //console.log(e.touches[0].clientX, touching.touchXstart);

    console.log(e);
    const prevent =
      typeof e.target.className === "string" &&
      e.target.className.indexOf("dragable") > -1;

    if (prevent) return;

    if (Math.abs(touchX) > 10 && Math.abs(touchX) > Math.abs(touchY)) {
      setTouching({
        ...touching,
        isTouching: true,
        touchX,
      });
    }
  };

  const handleTouchEnd = (e) => {
    console.log(e.changedTouches[0].clientX);
    console.log("handleTouchEnd");
    console.log(touching);

    console.log(e);
    const prevent =
      typeof e.target.className === "string" &&
      e.target.className.indexOf("dragable") > -1;

    if (prevent || !touching.isTouching) return;

    setTouching({
      ...touching,
      isTouching: false,
    });

    if (
      touching.touchX < 0 &&
      currentSlide.steps &&
      currentSlide.steps > currentSlide.currentStep
    ) {
      increaseStep();
      return;
    }

    if (Math.abs(touching.touchX) < 100) return;

    gotoSlide(touching.touchX > 0 ? -1 : 1);
  };

  const increaseStep = () => {
    console.log(currentSlide);

    if (currentSlide.steps && currentSlide.steps > currentSlide.currentStep) {
      let step = parseInt(currentSlide.currentStep) + 1;

      setCurrentSlide({
        ...currentSlide,
        currentStep: step,
      });
    }
  };

  const classes = [styles.folder];
  touching.isTouching && classes.push("noanimate");

  if (!currentSlide) {
    return (
      <>
        <Footer></Footer>
      </>
    );
  }

  const slides = [];
  const navigation = [];

  slideOrder.forEach((slide, i) => {
    const Component = components[slide.component];
    const active = i === currentSlide.index;
    const step = active ? currentSlide.currentStep : 1;

    navigation.push(
      <div
        key={i}
        onClick={() => {
          history.push(`/folders/${folder}/${slide.key}`);
        }}
      >
        <Slide theme={slide.theme} preview>
          <Component.name index={i} step={1} preview />
        </Slide>
        <Footer variant={slide.footer} preview />
      </div>
    );

    slides.push(
      <Slide
        key={i}
        theme={slide.theme}
        dimensions={dimensions}
        style={{ left: `${i * 100}%` }}
        active={active}
        step={step}
      >
        <Component.name index={i} active={active} step={step} />
      </Slide>
    );
  });

  console.log(slideKey);

  let translateX = -currentSlide.index * 100;

  if (
    touching.isTouching &&
    (touching.touchX > 0 ||
      !currentSlide.steps ||
      currentSlide.steps === currentSlide.currentStep)
  ) {
    translateX += (touching.touchX / window.innerWidth) * 100;
  }

  return (
    <>
      <div
        className={classes.join(" ")}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className="slides"
          style={{ transform: `translateX(${translateX}%)` }}
        >
          {slides}
        </div>
      </div>
      <Footer
        variant={currentSlide.footer}
        goBack={() => history.goBack()}
        goForward={() => {
          if (
            currentSlide.steps &&
            currentSlide.steps > currentSlide.currentStep
          ) {
            increaseStep();
          } else {
            gotoSlide(1);
          }
        }}
        currentSlide={currentSlide}
      >
        {navigation}
      </Footer>
    </>
  );
};

const mapStateToProps = (state, props) => {
  const { folders } = state;

  console.log(state);
  console.log(folders);

  return {
    customFolders: folders,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, null)(Index);
