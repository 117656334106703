import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, PlayBtn } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';

const Slide = styled.div`
    > .box2 {
        padding-top: 35px;

        .number {
            position: absolute;
            display: block;
            left: 12px;
            top: -51px;
            font-size: 64px;
            line-height: 80px;
            font-style: italic;
        } 

        &.box2-1 .number {
            left: 2px;
        }
    }

    .overlay1 {
        .number {
            font-size: 64px;
            line-height: 80px;
            font-style: italic;
        }

        .info {
            font-weight: 600;
            font-size: 15px;
            line-height: 17px;
        }

        .play { 
            width: 76px;
            height: 76px;
            
            .stroke {
                fill: #fff;
            }
        }
    }

    .link1 {
        position: absolute;
        z-index: 110;
        display: block;
        left: 550px;
        top: 350px;
        width: 350px;
        height: 200px;
    }
`
const inititialState = {
    overlay1: 'overlay3'
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>So gewinnen Sie die <strong>presbyopen Kunden als<br />
                duale Verwender</strong> für sich</h1>

            <Container left={390} top={372} width={1270} height={259} className="bg1 box1 color1 flex-all-center text-center">
            <Arrow width={1065} className="container-bottom" bgColor={'#d8f2ef'} />
                <p>Erklären Sie Ihren Kunden, wie eine Kombination<br />
                    aus <strong>Brille und Kontaktlinse (duale Verwendung)</strong><br />
                    ihre Bedürfnisse noch besser erfüllen kann</p>
            </Container>

            <Container left={113} top={778} width={580} height={360} className="bg1 box2 box2-1 color1 text-center fadeInFromTop">
                <span className="number color3">1</span>
                <p><strong>Schaffen Sie<br />
                    Aufmerksamkeit für das<br />
                    Thema multifokale<br />
                    Kontaktlinsen</strong></p>
            </Container>

            <Container left={732} top={778} width={580} height={360} className="bg1 box2 box2-2 color1 text-center fadeInFromTop animate2">
                <span className="number color3">2</span>
                <p><strong>Vermitteln Sie die<br />
                    Vorteile von multifokalen<br />
                    Kontaktlinsen</strong></p>
            </Container>

            <Container left={1352} top={778} width={580} height={360} className="bg1 box2 box2-3 color1 text-center fadeInFromTop animate3">
                <span className="number color3">3</span>
                <p><strong>Begleiten Sie Ihre<br />
                    Kunden bei den ersten<br />
                    Schritten mit multifokalen<br />
                    Kontaktlinsen</strong></p>
            </Container>

            <MoreBtn
                className="fadeInFromTop"
                left={371} top={1098}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')} />

            <MoreBtn
                className="fadeInFromTop animate2"
                left={990} top={1098}
                active={state.overlay1 === 'overlay2'}
                onClick={() => toggleOverlay('overlay2')} />

            <MoreBtn
                className="fadeInFromTop animate3"
                left={1610} top={1098}
                active={state.overlay1 === 'overlay3'}
                onClick={() => toggleOverlay('overlay3')} />

            <Overlay active={state.overlay1 === 'overlay1'} className="overlay1 overlay1-1 with-bg">
                <Container left={113} top={372} width={1819} height={767} className="bg2 color1">
                </Container>

                <Image left={222} top={575} width={1672} height={517} src={img1} className="" />

                <Container as="p" left={144} top={380} width={100} className="number color3">
                    1
                </Container>

                <Container as="p" left={230} top={411} width={1850} className="color1">
                    Finden Sie den <strong>optimalen Moment bei der Kundenberatung</strong> um auf multifokale<br />
                    Kontaktlinsen und ein mögliches <strong>Probetragen</strong> aufmerksam zu machen.
                </Container>

                <Container as="p" left={230} top={546} width={1400} className="info color1">
                    Nutzen Sie unsere Kampagnen-Werbemittel um Aufmerksamkeit<br />
                    für multifokale Kontaktlinsen zu schaffen
                </Container>
                <Container as="p" left={628} top={779} width={1400} className="info color1">Poster</Container>
                <Container as="p" left={114} top={1022} width={413} className="info color1 text-right">Brillenregal Wobbler</Container>
                <Container as="p" left={1110} top={779} width={1400} className="info color1">POS Video</Container>
                <Container as="p" left={832} top={1022} width={128} className="info color1 text-right">Banner</Container>
                <Container as="p" left={1294} top={654} width={219} className="info color1 text-right">Digitale und
                    postalische
                    Mailings</Container>
                <Container as="p" left={1499} top={953} width={318} className="info color1">Social Media<br />
                    Posts und<br />
                    Stories</Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay2'} className="overlay1 overlay1-2 with-bg">
                <Container left={113} top={372} width={1819} height={767} className="bg2 color1">
                </Container>

                <Image left={153} top={553} width={1668} height={585} src={img2} className="" />

                <Container as="p" left={136} top={380} width={100} className="number color3">
                    2
                </Container>

                <Container as="p" left={230} top={411} width={1850} className="color1">
                    Um die Vorteile von multifokalen Kontaktlinsen gut aufzeigen zu können,<br />
                    sprechen Sie am besten konkret die individuellen Wünsche und Bedürfnisse des<br />
                    einzelnen Kunden an.
                </Container>

                <Container as="p" left={982} top={680} width={384} className="info color1">
                    Wichtig ist Ihre
                    persönliche Beratung
                </Container>

                <Container as="p" left={1044} top={888} width={360} className="info color1 text-right">
                    Nutzen Sie den<br />
                    20€-Gutschein um<br />
                    zum Probetragen und<br />
                    Erstkauf zu animieren
                </Container>
            </Overlay>

            <Overlay active={state.overlay1 === 'overlay3'} className="overlay1 overlay1-3 with-bg">
                <Container left={113} top={372} width={1819} height={767} className="bg2 color1">
                </Container>

                <Image left={505} top={661} width={1296} height={424} src={img3} className="" />

                <Container as="p" left={136} top={380} width={100} className="number color3">
                    3
                </Container>

                <Container as="p" left={230} top={411} width={1850} className="color1">
                    Zeigen Sie Ihrem Kunden wie multifokale Kontaktlinsen funktionieren und<br />
                    begleiten Sie ihn bei den ersten Schritten (z. B. mit Tipps zur Handhabung)
                </Container>

                <Container as="p" left={128} top={575} width={980} className="info color1 text-center">
                    Eine gute Unterstützung ist unsere<br />
                    Endverbraucherbroschüre
                </Container>

                <Container as="p" left={1120} top={575} width={650} className="info color1 text-center">
                    Weisen Sie auf die Handhabungsvideos<br />
                    auf der ACUVUE®-Webseite hin
                </Container>

                <PlayBtn left={1364} top={817} className="play" />

                <a href="https://www.acuvue.de/kontaktlinsen-pflege/kontaktlinsen-aufsetzen" target="_blank" className="link1"></a>

            </Overlay>

        </Slide>
    );
};
