import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    h1 {
       color: #0a7cc1; 
    }

    h2 {
        margin: 20px 0 10px 0;
        font-size: 20px;
        line-height: 24px;
        color: #0a7cc1;
    }

    .list {
        display: flex;

        .symbol {
            flex: 0 0 18px;
        }
    }
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>


                <p>Kontaktlinsen der Marke ACUVUE® dienen zur Korrektion des Sehvermögens. Eine detaillierte Produktbeschreibung und Informationen zur Sicherheit entnehmen Sie der Gebrauchsanweisung
                    oder besuchen Sie die Johnson & Johnson-Website www.e-ifu.com.</p>

                <p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse-1- oder Klasse 2-UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.</p>

                <p>UV-absorbierende Kontaktlinsen sind KEIN Ersatz für UV-absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken. UV-Transmission
                    mit -1,00-dpt-Linse gemessen.</p>

                <p>ACUVUE®, ACUVUE® OASYS MAX 1-Day, 1-DAY ACUVUE® MOIST, ACUVUE® OASYS, ACUVUE® OASYS 1-Day, ACUVUE® VITA® und Connected by SightTM sind eingetragene Marken von
                    Johnson & Johnson. © Johnson & Johnson Medical GmbH / Johnson & Johnson Medical Products GmbH 2023 | PP2023MLT6938</p>

            </TextBox>
        </Slide>
    );
};
