import React from 'react';
import styled from 'styled-components'
import { Image, Container, Overlay, MoreBtn, CloseBtn } from 'components/parts';
import { useSlideState } from 'components/hooks'

import bg from './assets/bg.jpg'

import img1 from './assets/img1.jpg'
import img2 from './assets/img2.jpg'
import img3 from './assets/img3.jpg'

import img2_1 from './assets/img2_1.jpg'
import img2_2 from './assets/img2_2.jpg'
import img2_3 from './assets/img2_3.jpg'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    > * > .box2 {
        line-height: 27.5px;
    }

    .img1 {
        border-radius: 50%;
        border: 1.3px solid #4779bb;

        &::after {
            position: absolute;
            left: 100%;
            top: 50%;
            height: 1.3px;
            width: 15px;
            background: #4779bb;
            content: '';
        }
    }



    .overlay1 {
        > .box1 {
            border: 1.5px solid #3a8dde;
            border-radius: 15px;
            background: #fff;
        }

        > .box2 {
            position: relative;
            font-size: 15px;
            line-height: 18px;

            > img {
                position: absolute;
                z-index: 1;
                border: 2.3px solid #2b2747;
                border-radius: 50%;
                width: 110px;
                width: 110px;
                left: 87px;
            }

            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .box1 {
                background: #2b2747;
                padding-left: 40px;

                span {
                    margin-top: 5px;
                    font-size: 7.5px;
                    line-height: 8.5px;
                }
            }

            .box2 {
                background: #4779bb;
            }

            .box3 {
                background: #a7a9ac;
            }
        }

        > .box3,
        > .box4 {
            color: #13174a;
        }

        > .box4 {
            font-size: 18px;
            line-height: 22px;
        }
    }
`

const inititialState = {
    overlay1: null
}

const Folder2Slide1 = ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1><strong>Die aktuell beste Linse von ACUVUE®</strong></h1>

                <Container left={450} top={213} width={1140} as="p" className="text-center">
                    Nachgewiesene Leistung <strong>im Vergleich zum Wettbewerb</strong>
                </Container>

                <Image src={img1} left={480} top={384} width={244} height={244} className="img1" />

                <Container left={816} top={383} width={1019} height={243} className="box2 flex-all-center">
                    <p>Überlegenes Gefühl<br />
                        <strong>von frischen Augen am Ende des Tages</strong><br />
                        im Vergleich zu DAILIES TOTAL1®.<sup>¢19</sup></p>
                </Container>

                <div class="container-animate fadeIn">

                    <Image src={img2} left={526} top={672} width={244} height={244} className="img1" />

                    <Container left={816} top={672} width={1019} height={243} className="box2 flex-all-center">
                        <p>Überlegener Sehkomfort<br />
                            <strong>während des Autofahrens bei Nacht</strong><br />
                            im Vergleich zu DAILIES TOTAL1®. <sup>≈19</sup></p>
                    </Container>

                </div>

                <div class="container-animate fadeIn animate2">

                    <Image src={img3} left={426} top={954} width={244} height={244} className="img1" />

                    <Container left={816} top={954} width={1019} height={243} className="box2 flex-all-center">
                        <p>Überlegene Sehqualität<br />
                            <strong>bei schwachem Licht</strong><br />
                            im Vergleich zu DAILIES TOTAL1®.<sup>£19</sup></p>
                    </Container>

                </div>

                <div class="container-animate fadeIn animate3">

                    <Container as="p" left={160} top={654} width={390} className="box2">
                        <strong>Eine noch nie<br />
                            da gewesene<br />
                            Kombination</strong><br />
                            aus 2 neuen<br />
                            Technologien<sup>8</sup>
                    </Container>

                    <MoreBtn
                        left={57} top={838}
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'}
                    />


                    <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                        <Container left={100} top={350} width={1840} height={830} className="box1" />

                        <Container left={656} top={382} width={727} as="p" className="box4 text-center">
                            <strong>ACUVUE® OASYS MAX 1-Day</strong>
                        </Container>

                        <Container left={1383} top={382} width={481} as="p" className="box4 text-center">
                            <strong>DAILIES TOTAL1®</strong>
                        </Container>


                        <Container left={0} top={450} className="box2 box2_1">
                            <img src={img2_1} />
                            <Container left={284} top={0} width={372} height={220} className="box1">
                                <strong>Kontaktlinsen<br />
                                    Benetzungs-<br />
                                    Technologie</strong>
                            </Container>
                            <Container left={656} top={0} width={727} height={220} className="box2">
                                <p><strong>NEU:</strong> TearStable™ Technology – Optimiert PVP,<br />
                                    ein tränenähnlicher Benetzungswirkstoff in der<br />
                                    gesamten Linse und an der Oberfläche, um die<br />
                                    Stabilität des Tränenfilms zu verlängern und eine<br />
                                    <strong>2x reduzierte Verdunstung zu gewährleisten</strong><sup>×8-10</sup></p>
                            </Container>

                            <Container left={1383} top={0} width={481} height={220} className="box3">
                                <p>Benetzungswirkstoff und<br />
                                    SmarTears – Bietet ein Feuchtig-<br />
                                    keitspolster und eine stabile<br />
                                    Lipidschicht<sup>@</sup></p>
                            </Container>

                        </Container>

                        <Container left={0} top={690} className="box2 box2_2 fadeIn">
                            <img src={img2_2} />
                            <Container left={284} top={0} width={372} height={220} className="box1">
                                <strong>HEV Filter</strong>
                            </Container>
                            <Container left={656} top={0} width={727} height={220} className="box2">
                                <p><strong>NEU:</strong> OptiBlue™ Light Filter –<br />
                                    <strong>Höchste Stufe des<br />
                                        Blau-Violett-Filters bei 60%</strong><sup>**≠8</sup></p>
                            </Container>

                            <Container left={1383} top={0} width={481} height={220} className="box3">
                                <p>Nicht vorhanden<br />
                                    in Dailies Total1® oder<br />
                                    Dailies Total1® Multifocal<sup>****</sup></p>
                            </Container>

                        </Container>

                        <Container left={0} top={928} className="box2 box2_3 fadeIn animate2">
                            <img src={img2_3} />
                            <Container left={284} top={0} width={372} height={220} className="box1">
                                <strong>UV Filter</strong>

                            </Container>
                            <Container left={656} top={0} width={727} height={220} className="box2">
                                <p><strong>Klasse 1 UV-Schutz</strong>:<sup>§10</sup><br />
                                    99.9% UV-A, 100% UV-B</p>
                            </Container>

                            <Container left={1383} top={0} width={481} height={220} className="box3">
                                <p>Kein UV-Schutz<br />
                                    in Dailies Total1® oder<br />
                                    Dailies Total1® Multifocal<sup>*****</sup></p>
                            </Container>

                        </Container>


                    </Overlay>

                </div>

            </Slide>
        </>

    );
};

export default Folder2Slide1;