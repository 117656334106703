import React from 'react';
import styled, { css, keyframes } from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'

import bg from './assets/bg.jpg'

import img1 from './assets/img1.jpg'
import img2 from './assets/img2.jpg'
import img3 from './assets/img3.jpg'
import img4 from './assets/img4.jpg'
import img5 from './assets/img5.jpg'
import img6 from './assets/img6.jpg'
import img7 from './assets/img7.jpg'

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 71px;
        text-align: center;
        color: #fff;
    }

    .img {
        border-color: #3a8dde !important;
    }

    .text1 {
        line-height: 27.5px;
    }

    .text2 {
        font-size: 20px;
        line-height: 25px;
    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

    .fadeOutSpecial {
        ${({ preview }) => preview && css`
            opacity: 0.3;
        `}
        
        ${({ active, preview }) => (active && !preview) && css`   
            animation: ${fadeOutSpecial} 1s forwards ease-out;
            animation-delay: 0.6s;
        `}


        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}
    
    }

    .fadeIn {
        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}
    }

    .fadeInFromTop {
        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}
    }
`

const fadeOutSpecial = keyframes`
    0% {
        opacity: 1;
    }
    100% {  
        opacity: 0.3;
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered" preview={props.preview} active={props.active} step={props.step}>
                <h1>Das Leben verlangt <strong>mehr von unseren Augen</strong></h1>

                <Image left={983} top={702} width={284} height={284} src={img5} className="img rounded border fadeOutSpecial" />

                <Image left={465} top={407} width={590} height={590} src={img1} className="img rounded border" />

                <Image left={140} top={565} width={284} height={284} src={img2} className="img rounded border fadeOutSpecial" />
                <Image left={365} top={249} width={284} height={284} src={img3} className="img rounded border fadeOutSpecial" />
                <Image left={942} top={235} width={284} height={284} src={img4} className="img rounded border fadeOutSpecial" />

                <Image left={733} top={931} width={284} height={284} src={img6} className="img rounded border fadeOutSpecial" />

                <Image left={233} top={842} width={390} height={390} src={img7} className="img rounded border fadeIn" />

                <Container left={1389} top={411} width={560} as="p" className="text1">
                    Unser Leben hat sich in<br />
                    den vergangenen Jahren<br />
                    verändert, die meisten<br />
                    Menschen haben einen<br />
                    anspruchsvollen Alltag<br />
                    und sind vielseitig aktiv.
                </Container>

                <div className="container-animate fadeInFromTop">

                <Arrow left={1614} top={606} width={486} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                <Container left={1389} top={931} width={560} as="p" className="text1">
                    Zudem verbringen viele<br />
                    täglich oft einige Stunden<br />
                    an digitalen Geräten.
                </Container>

                </div>

                <Container left={597} top={809} width={244} as="p" className="text2">
                    Christin,<br />
                    34 Jahre
                </Container>

            </Slide>
        </>

    );
};

export default Folder2Slide1;