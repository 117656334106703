import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.png';

const Slide = styled.div`
    .box1 {
        padding-right: 90px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>ACUVUE® OASYS 1-Day with HydraLuxe®</strong> sorgt für
einen langanhaltenden Tragekomfort</h1>
            <Container left={186} top={373} width={1210} height={417} className="bg1 color1 box1 flex-all-center text-center fadeInFromTop">
            <Arrow width={879} className="container-top" offset={90} />
                <p><strong>HydraLuxe®</strong> ahmt die natürlichen<br /> 
Eigenschaften von Tränen nach und trägt<br /> 
so zur gleichmäßigen Verteilung aller<br /> 
Schichten des Tränenfilms bei, wodurch<br /> 
eine <strong>gleichmäßige glatte, schmierende<br /> 
und stabile Oberfläche bzw. Brechungs-<br />
fläche entsteht.<sup>12,13</sup></strong></p>
            </Container>

            <Container left={1005} top={922} width={1030} className="color1 fadeIn animate2">
                <p>Die vom Tränenfilm inspirierte Technologie<br /> 
sorgt für einen Reibungskoeffizienten, der dem<br />
des natürlichen Auges ähnelt<sup>16+</sup> und <strong>sorgt für<br /> 
einen lang anhaltenden Tragekomfort<sup>12,13</sup></strong></p>
            </Container>

            <Image left={1140} top={339} width={909} height={487} src={img2} />

            <Image left={398} top={925} width={534} height={175} src={img1} className="fadeIn animate2" />

        </Slide>
    );
};
