import React from 'react';
import styled, { css, keyframes } from 'styled-components'
import { calculateDimensionProperty } from 'components/helper'

const clockAnimation = (percent) => keyframes`
      0% {
      stroke-dashoffset: 1200;
    }
    100% {
      stroke-dashoffset: ${(1200 - (percent / 100 * 1200))};
    }
`

const CircleCountSimpleContainer = styled.div`
    position: absolute;
    width: 199px;
    height: 199px;

    ${({ left }) => left !== undefined && css`
        left: ${calculateDimensionProperty(left)};
    `}

    ${({ top }) => top !== undefined && css`
        top: ${calculateDimensionProperty(top)};
    `}

    svg {
        transform: rotate(-90deg);
    }

    .progress {
        stroke-dasharray: 1200;
        stroke-dashoffset: 1200;
        
        ${({ active, preview }) => (active && !preview) && css`   
            
            animation: ${props => clockAnimation(props.percent)} 1.8s forwards;
        `}

        ${({ preview }) => preview && css`   
            stroke-dashoffset: ${props => (1200 - (props.percent / 100 * 1200))};
        `}
    }   
`

const CircleCountSimple = ({...props}) => {
    return (
        <CircleCountSimpleContainer left={props.left} top={props.top} percent={props.percent} active={props.active} preview={props.preview}>
            <svg viewBox="0 0 398.51 398.51">
                <circle cx="198.97" cy="199.5" r="189.75" fill="none" stroke="#fff" strokeWidth="2.13" />
                <circle cx="199.25" cy="199.25" r="189.75" fill="none" stroke="#008de1" strokeWidth="19" className="progress" />
            </svg>
        </CircleCountSimpleContainer>
    );
};

export default CircleCountSimple;