
import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide5 from './slides/slide5'
import Slide4 from './slides/slide4'
import Slide6 from './slides/slide6'
import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'
import Slide9 from './slides/slide9'
import Slide10 from './slides/slide10'
import Slide11 from './slides/slide11'

const Theme = {
    color1: '#003087',
    color2: '#0a7cc1',
    color3: '#d4ecf7',
    bg1: '#d4ecf7',
    bg2: '#ffffff',

    bg3: '#003087',
}

export const Slides = {
    'Folder17Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder17Slide2': { name: Slide2, theme: Theme },
    'Folder17Slide3': { name: Slide3, theme: Theme },
    'Folder17Slide4': { name: Slide4, theme: Theme },
    'Folder17Slide5': { name: Slide5, theme: Theme },
    'Folder17Slide6': { name: Slide6, theme: Theme },
    'Folder17Slide7': { name: Slide7, theme: Theme },
    'Folder17Slide8': { name: Slide8, theme: Theme },
    'Folder17Slide9': { name: Slide9, theme: Theme },
    'Folder17Slide10': { name: Slide10, theme: Theme },
    'Folder17Slide11': { name: Slide11, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder17Slide1' },
    { key: 1, component: 'Folder17Slide2' },
    { key: 2, component: 'Folder17Slide3' },
    { key: 3, component: 'Folder17Slide4' },
    { key: 4, component: 'Folder17Slide5' },
    { key: 5, component: 'Folder17Slide6' },
    { key: 6, component: 'Folder17Slide7' },
    { key: 7, component: 'Folder17Slide8' },
    { key: 8, component: 'Folder17Slide9' },
    { key: 9, component: 'Folder17Slide10' },
    { key: 10, component: 'Folder17Slide11' },
]