import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from '../slide17/assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.jpg';

const Slide = styled.div`
    .box1 {
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .count {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 53px;
            line-height: 66px;
            letter-spacing: -0.05em;

            span {
                font-size: 35px;
            }
        }
    }

    .box2 {
        background-image: url(${img4});
        background-size: contain;
        background-repeat: no-repeat;
        padding-left: 270px;
    }   
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 88, props.active, props.preview)
    let count2 = useCounter(0, 33, props.active, props.preview)
    let count3 = useCounter(0, 32, props.active, props.preview)

    return (
        <Slide className="centered" step={props.step}>
            <h1>„Lens on Eye“ – <strong>Änderung des Brillenverkaufsprozesses<sup>10</sup></strong></h1>

            <Container as="p" left={100} top={211} width={1900} className="small">
                So steigern Sie sowohl die <strong>Kontaktlinsen-Test-Zahlen</strong>, den <strong>Kontaktlinsenabsatz</strong> als auch<br />
                den <strong>Wert des Brillenabsatzes</strong>.
            </Container>

            <Container left={103} top={362} width={1844} height={226} className="bg1">
            </Container>

            <MoreBtn
                left={391} top={547}
                onClick={() => toggleOverlay('overlay1')}
                active={state.overlay1 === 'overlay1'}
                className="more1" />

            <Container as="p" left={143} top={402} width={160} height={139} className="color1 flex-all-center">
                Sehtest
            </Container>

            <Arrow left={325} top={407} width={138} bgColor={'#CFF2FB'} />

            <Container as="p" left={386} top={402} width={392} height={139} className="color1 flex-all-center">
                <strong>Ausprobieren von<br />
                    Kontaktlinsen</strong>
            </Container>

            <Arrow left={793} top={407} width={138} bgColor={'#CFF2FB'} />

            <Container as="p" left={851} top={402} width={392} height={139} className="color1 flex-all-center">
                Auswahl einer<br />
                Brillenfassung
            </Container>

            <Arrow left={1177} top={407} width={138} bgColor={'#CFF2FB'} />

            <Container as="p" left={1240} top={402} width={420} height={139} className="color1 flex-all-center">
                Bewertung der<br />
                Erfahrung mit<br />
                Kontaktlinsen
            </Container>

            <Arrow left={1571} top={407} width={138} bgColor={'#CFF2FB'} />

            <Container as="p" left={1636} top={402} width={420} height={139} className="color1 flex-all-center">
                Fassung und<br />
                Kontaktlinsen<br />
                kaufen
            </Container>

            <Arrow left={544} top={447} width={472} offset={131} className="rotate90" />

            <Container left={278} top={720} width={306} height={306} className="box1 flex-all-center text-center ">
                <img src={img1} />
                <span className="count">{count1}%</span>
            </Container>

            <Container left={278} top={933} width={306} height={306} className="box1 flex-all-center text-center ">
                <img src={img1} />
                <span className="count">{count2}%</span>
            </Container>

            <Container as="p" left={624} top={808} width={500} className="color1">
                … wollten<br />
                Kontaktlinsen<br />
                ausprobieren
            </Container>

            <Container as="p" left={624} top={1022} width={500} className="color1">
                … haben<br />
                Kontaktlinsen<br />
                erworben
            </Container>

            <Container left={1029} top={720} width={306} height={306} className="box1 flex-all-center text-center ">
                <img src={img1} />
                <span className="count"><span>+</span>{count3}%</span>
            </Container>

            <Container as="p" left={1373} top={808} width={500} className="color1">
                … ist der durchschnitt-<br />
                liche Transaktionswert<br />
                für Brillen
            </Container>

            <Image src={img2} left={382} top={764} width={99} height={57} contain />

            <Image src={img3} left={1131} top={786} width={110} height={41} contain />

            <Overlay className="with-bg" active={state.overlay1 === 'overlay1'}>
            <Container left={253} top={589} width={1575} height={398} className="box2 rounded flex-all-center color1 text text-center">
<p><strong>Den Kunden die Möglichkeit<br /> 
anbieten Kontaktlinsen<br /> 
auszuprobieren, wenn sie gerade<br /> 
ihre Brillenfassung auswählen.</strong></p>
                </Container>

            </Overlay>

        </Slide>
    );
};
