import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import li from './assets/li.svg';
import bg from './assets/bg.jpg'

const Slide = styled.div`
    .box1 .number {
        position: absolute;
        display: block;
        width: 70px;
        text-align: right;
        left: -56px;
        top: -23px;
        font-size: 65px;
        line-height: 65px;
        color: #390075;
        font-style: italic;
        text-align: right;
    } 

    .box2 ul {
        margin-left: 20px;
        margin-top: 40px;

        li {
            float: left;
            font-weight: 600;
            padding-left: 40px;
            background: url(${li});
            background-repeat: no-repeat;
            background-size: 31px 25px;
            background-position: 0px 0px;
            line-height: 30px;

            + li {
                margin-left: 12px;
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${bg})` }} />
            <Slide className="centered" step={props.step}>
                <h1>ACUVUE® ist Vorreiter für neues und stärkeres<br />
Wachstum <strong>in der Kategorie Kontaktlinsen</strong></h1>
                <h2 className="small">Mit unserem umfassenden ACUVUE® Produktsortiment sind wir<br />
bestens aufgestellt, <strong>um Sie zu unterstützen, das Wachstum in<br />
Ihrer Kategorie sowie Ihren durchschnittlichen Bestellwert in<br />
einem anspruchsvollen Markt zu steigern.</strong></h2>
                <Container left={233} top={554} width={435} height={170} className="rounded rounded-full color1 flex-all-center text-center box1">
                    <span className="number">1.</span>
                    <p><strong>Kontaktlinsen<br />
Portfolio</strong></p>
                </Container>
                <div className="container-animate fadeInFromTop">
                    <Container left={190} top={768} width={1697} height={214} className="bg1 color1 box2">
                        <ul>
                            <li>Vertrauen</li>
                            <li>Wert</li>
                            <li>Produktangebot</li>
                            <li>Innovation</li>
                            <li>Partnerschaft</li>
                        </ul>
                    </Container>

                    <Arrow left={429} top={559} width={447} className="rotate90" />
                </div>
            </Slide>
        </>
    );
};
