import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'
import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'

const Theme = {
    color1: '#003087',
    bg1: '#CFF2FB',
    bg2: '#ffffff',
}

export const Slides = {
    'Folder8Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder8Slide2': { name: Slide2, theme: Theme },
    'Folder8Slide3': { name: Slide3, theme: Theme },
    'Folder8Slide4': { name: Slide4, theme: Theme },
    'Folder8Slide5': { name: Slide5, theme: Theme },
    'Folder8Slide6': { name: Slide6, theme: Theme },
    'Folder8Slide7': { name: Slide7, theme: Theme },
    'Folder8Slide8': { name: Slide8, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder8Slide1' },
    { key: 1, component: 'Folder8Slide2' },
    { key: 2, component: 'Folder8Slide3' },
    { key: 3, component: 'Folder8Slide4' },
    { key: 4, component: 'Folder8Slide5' },
    { key: 5, component: 'Folder8Slide6' },
    { key: 6, component: 'Folder8Slide7' },
    { key: 7, component: 'Folder8Slide8' },
]