import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow4, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';

const Slide = styled.div`
    
    .box1 {
        color: #a7a9ac;
    }

    .box2 {
        color: #fff;
    }

    .box6 {
        font-size: 10px;
        line-height: 12px;
    }

   
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1><strong>Kompetenz in der Anpassung fördert Loyalität</strong></h1>

            <Container as="p" left={100} top={196} width={1900} className="small">
                Vergleich der Verkäufe von sphärischen, torischen und multifokalen Kontaktlinsen in den Kanälen
            </Container>

            <Container as="p" left={623} top={315} width={820} className="box1 small">
                <strong>Anteiliger Verkauf  von Januar - Dezember 2021</strong>
            </Container>

            <Image src={img1} left={744} top={391} width={1017} height={391} />

            <Container as="p" left={305} top={380} width={466} height={130} className="rounded rounded-full text-center flex-all-center small">
                Multifokale<br />
                Kontaktlinse
            </Container>

            <Container as="p" left={305} top={523} width={466} height={130} className="rounded rounded-full text-center flex-all-center small">
                Torische<br />
                Kontaktlinse
            </Container>

            <Container as="p" left={305} top={666} width={466} height={130} className="rounded rounded-full text-center flex-all-center small">
                Sphärische<br />
                Kontaktlinse
            </Container>

            <Container as="p" left={1018} top={427} width={100} className="box2 small">
                <strong>61%</strong>
            </Container>

            <Container as="p" left={1520} top={427} width={100} className="box2 small">
                <strong>39%</strong>
            </Container>

            <Container as="p" left={1003} top={576} width={100} className="box2 small">
                <strong>58%</strong>
            </Container>

            <Container as="p" left={1500} top={576} width={100} className="box2 small">
                <strong>42%</strong>
            </Container>

            <Container as="p" left={914} top={715} width={100} className="box2 small">
                <strong>41%</strong>
            </Container>

            <Container as="p" left={1412} top={715} width={100} className="box2 small">
                <strong>59%</strong>
            </Container>

            <Image src={img2} left={349} top={836} width={384} height={49} />

            <Container left={418} top={845} width={300} className="color2">
                <p className="small"><strong>FACHHANDEL</strong></p>
            </Container>

            <Container left={747} top={845} width={300} className="color2">
                <p className="small"><strong>ONLINE</strong></p>
            </Container>


            <Container left={305} top={960} width={1453} height={211} className="bg1 text flex-all-center text-center color1 box5 fadeInFromTop animate2">
                <Arrow width={1356} className="container-top" />
                <p><strong>Je erfolgreicher die Anpassung</strong> ist, desto <strong>mehr Kompetenz</strong> kann<br />
                    vermittelt werden. Mit der Folge der <strong>stärkeren sichtbaren Loyalität<br />
                        <Arrow4 className="inline" /> keine Abwanderung in den Online Kanal</strong></p>
            </Container>

            <Container left={305} top={1224} width={1453} className="text-center box6">* Interne Analyse Marktdaten Dritter</Container>




        </Slide>
    );
};
