import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';


const Slide = styled.div`

    h1 {
       position: relative;
       
       .number {
            position: absolute;
            font-family: "AvenirNext";
            font-weight: 700;
            font-style: italic;
            font-size: 125px;
            line-height: 156px;
            top: -60px;
            left: -48px;
            z-index: -1;
       }
    }
    
    .box1 {
       
        
    }
    
    
    .box2 {
        font-family: "AvenirNext";
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;

        span {
            font-size: 36px;
            line-height: 40px;
        }
    }

    .box3 {
        font-size: 18px;
        line-height: 21px;
    }

    .box4 {
        font-family: "AvenirNext";
        font-size: 10px;
        line-height: 11.5px;
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><span className="number color3">4</span><strong>Attraktives Konditionenmodell</strong></h1>

            <Container left={131} top={214} width={1786} className="box1 color1 text-center" as="p">
                Wir treten in Vorleistung – lassen Sie uns unsere partnerschaftliche<br />
                Zusammenarbeit auch in 2024 weiter ausbauen:
            </Container>

            <Container left={159} top={0} className="">
                <Image left={55} top={387} width={250} height={320} src={img1} />
                <Container left={0} top={742} width={360} height={150} className="box2 bg1 flex-all-center text-center">

                    <p className="color1"><span>3-11%</span><br />Umsatzrabatt</p>
                </Container>
                <Container left={-100} top={935} width={560} className="box3 text-center color1">
                    <p>fördert nochmals<br />
                        Ihren Gewinn</p>
                </Container>
            </Container>

            <Container left={597} top={0} className="fadeIn">
                <Image left={55} top={387} width={250} height={320} src={img2} />
                <Container left={0} top={742} width={360} height={150} className="box2 bg1 flex-all-center text-center">

                    <p className="color1"><span>7,5%</span><br />Anpassrabatt<sup>*</sup></p>
                </Container>
                <Container left={-100} top={935} width={560} className="box3 text-center color1">
                    <p>stellt Sie als<br />
                        anpassenden Partner<br />
                        in den Fokus</p>
                </Container>
            </Container>

            <Container left={1030} top={0} className="fadeIn animate2">
                <Image left={55} top={387} width={250} height={320} src={img3} />
                <Container left={0} top={742} width={360} height={150} className="box2 bg1 flex-all-center text-center">

                    <p className="color1"><span>5%</span><br />Partnerrabatt<sup>**</sup></p>
                </Container>
                <Container left={-100} top={935} width={560} className="box3 text-center color1">
                    <p>verstärkt unsere Partner-<br />
                        schaft auf Basis von<br />
                        gemeinsamen Aktivitäten<br />
                        zur Trägergewinnung und<br />
                        -bindung</p>
                </Container>
            </Container>

            <Container left={1464} top={0} className="fadeIn animate3">
                <Image left={55} top={387} width={250} height={320} src={img4} />
                <Container left={0} top={742} width={360} height={150} className="box2 bg1 flex-all-center text-center">

                    <p className="color1">bis zu <span>6,5%</span><br />Wachstumsrabatt<sup>***</sup></p>
                </Container>
                <Container left={-100} top={935} width={560} className="box3 text-center color1">
                    <p>fördert und honoriert<br />
                        Ihr Wachstum</p>
                </Container>
            </Container>



            <Container left={120} top={1184} width={1880} className="color1 box4 fadeIn animate4" as="p">
                * Bedingung zum Anpassrabatt ist das aktive Anpassen von Kontaktlinsen – Nachzuweisen über das Vorhalten von Anpasseinheiten  ** Bedingungen zum Erhalt des Partnerrabattes: siehe Abschnitt „Bedingungen zum Erhalt des Partnerrabattes“ in diesem Dokument   *** Bedingungen für den Wachstumsrabatt: der Wachstumsrabatt ist abhängig von dem jährlichen, vereinbarten und tatsächlichen Wachstum – der genau Wachstumsrabatt ergibt sich aus der Tabelle der Folgeseiten  – unterjährig wird das tatsächliche Wachstum nachgehalten und JJV behält sich entsprechende Änderungen vor.
            </Container>

        </Slide>
    );
};
