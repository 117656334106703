import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.jpg';




const Slide = styled.div`

  

`


export default ({ ...props }) => {

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1>Die <strong>„Mein Optiker 2023 Kampagne“</strong> mit<br />
                <strong>Testträger-Mechanik</strong></h1>

            <Container as="p" left={104} top={320} width={1840} className="box1 color1 text-center">
                Ein sich ergänzendes, unschlagbares Duo am POS und in den sozialen Medien:<br />
                <strong>Kompetenz-Vermittlung und Geld-Zurück-Mechanik</strong>
            </Container>



            <div className="container-animate fadeInFromTop">

                <Arrow left={1002} top={-94} width={1138} className="rotate90" arrowColor={'#002C8B'} />
                
                <Image left={506} top={512} width={1029} height={723} src={img1} />

            </div>



        </Slide>
    );
};
