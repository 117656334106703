import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow, MoreBtn, PlayBtn, Overlay, InfoBtn } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useSlideState, useCounter } from 'components/hooks'

import bg from './assets/bg.jpg'
import overlay1Bg from './assets/overlay1-bg.jpg'

import img1 from './assets/img1.jpg'
import img2 from './assets/img2.svg'
import img3 from './assets/img3.svg'


const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 45px;
        text-align: center;
        color: #fff;
    }

    > .text1 {
        font-size: 25px;
        line-height: 26.5px;
        text-transform: uppercase;
    }

    > .line {
        display: block;
        position: absolute;
        height: 1.2px;
        background: #fff;

        &.line1 {
            left: 87px;
            top: 277px;
            width: 358px;
        }

        &.line2 {
            left: 650px;
            top: 358px;
            width: 278px;
        }

        &.line3 {
            left: 560px;
            top: 513px;
            width: 369px;
        }
    }


    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

    .overlay1,
    .overlay1-1 {
        > .box1 {
            border: 1.5px solid #3a8dde;
            border-radius: 15px;
        }
    }

    .overlay1 {
        > .box1 {
            background: url(${overlay1Bg});
            background-size: cover;
        }

        > .text1 {
            line-height: 27.5px;
        }
    }

    .overlay1-1 {
        > .box1 {
            overflow: hidden;
            background: #fff;

            > .box2 {
                background: #CAE8F4;
            }
        }

        > .img1 {
            position: absolute;
            width: 606px;
            left: 104px;
            top: 216px;
        }

        > .text1 {
            font-size: 25px;
            line-height: 30px;
        }

        > .text2 {
            font-size:  11.5px;
            line-height: 14px;
            color: #0a7cc1;
        }

        > .text3 {
            font-size:  11.5px;
            line-height: 13px;
            color: #0a7cc1;

            .count {
                display: block;
                font-size: 36px;
                line-height: 43px;
                color: #0a7cc1;
            }
        }

        > .text4 {
            font-size:  10.5px;
            line-height: 12.5px;
            font-family: "Graphik", sans-serif;
            font-weight: 500;
            color: #000000;
        }

        > .text5 {
            font-size: 21px;
            line-height: 25px;
            color: #3a8dde;

            strong {
                color: #00306F;
            }
        }

        > .text6 {
            font-size: 10.5px;
            line-height: 12.5px;
            color: #000000;

            img {
                position: absolute;
                width: 23px;
                left: -28px;
                top: -5px;
            }
        }
    }


    
`
const inititialState = {
    overlay1: null,
    overlay2: null
}

const Folder2Slide1 = ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })

    return (
        <>
            <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Der <strong>MAX</strong> Unterschied</h1>

                <Container left={174} top={420} width={684} as="p" className="text1">
                    <strong>TEARSTABLE<sup>™</sup><br />
                        TECHNOLOGY</strong>
                </Container>

                <Container left={1242} top={583} width={617} as="p" className="text1 text-right">
                    <strong>OPTIBLUE™<br />
                        LIGHT FILTER<sup>**</sup></strong>
                </Container>

                <Container left={1242} top={893} width={617} as="p" className="text1 text-right">
                    <strong>Pupillenoptimiertes<br />
                        Design</strong>
                </Container>

                <span className="line line1" />
                <span className="line line2" />
                <span className="line line3" />

                <MoreBtn
                    left={1900} top={986}
                    onClick={() => toggleOverlay('overlay1')}
                    active={state.overlay1 === 'overlay1'}
                />



                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                    <Container left={100} top={100} width={1840} height={1080} className="box1" />
                    <Container left={680} top={190} width={1108} as="p" className="text1 text-right">
                        <strong>PUPILLENOPTIMIERTES DESIGN</strong><br />
                        Für das Sehen in <strong>jeder Entfernung</strong><sup>15</sup>
                    </Container>

                    <Arrow left={1452} top={34} width={648} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={905} top={397} width={887} as="p" className="text1 text-right">
                        Nur ACUVUE® bietet die<br />
                        Optimierung von <strong>100 %</strong> der<br />
                        Parameter für variierende<br />
                        Pupillengrößen je nach Alter<br />
                        und Fehlsichtigkeit<sup>ff15</sup><br /><br />

                        <strong>Dasselbe einzigartige<br />
                            optische Design</strong> bei allen<br />
                        ACUVUE® Multifocal<br />
                        Kontaktlinsen<sup>15</sup><br /><br />

                        <strong>92 %</strong> aller Träger passte <strong>das<br />
                            erste Paar beim ersten Termin</strong><sup>16</sup><br />
                    </Container>


                    <InfoBtn
                        left={1808} top={497}
                        onClick={() => updateState('overlay2', state.overlay2 !== 'overlay1-1' ? 'overlay1-1' : null)}
                        active={state.overlay2 === 'overlay1-1'}
                    />

                    <Overlay active={state.overlay2 === 'overlay1-1'} className="overlay1-1">

                        <Container left={137} top={140} width={1712} height={1000} className="box1">

                            <Container left={0} top={726} width={1712} height={314} className="box2" />


                        </Container>

                        <img src={img1} className="img1" />


                        <Container left={198} top={203} width={1590} as="p" className="text1 text-center color2">
                            Was ist das <strong>pupillenoptimierte Design?</strong>
                        </Container>

                        <Container left={230} top={359} width={148} as="p" className="text2 text-center">
                            Jünger/<br />
                            Myop
                        </Container>

                        <Container left={1258} top={359} width={148} as="p" className="text2 text-center">
                            Älter/<br />
                            Hyperop
                        </Container>

                        <Container left={1443} top={450} width={305} as="p" className="text3">
                            <span className="count">100 %</span>
                            ACUVUE® MULTIFOCAL<br />
                            183 Parameter optimiert<sup>ff17</sup>
                        </Container>

                        <Container left={1443} top={639} width={405} as="p" className="text3">
                            <span className="count">&lt;2 %</span>
                            optimierte Parameter<br />
                            beim führenden Wettbewerber<sup>ff17</sup>
                        </Container>

                        <Container left={207} top={820} width={1412} as="p" className="text4">
                            Iris-/Pupillen-Diagramme nur zu Illustrationszwecken
                        </Container>

                        <Container left={272} top={907} width={1424} as="p" className="text5 text-center">
                            Ausgelegt auf <strong>klare, scharfe, zuverlässige visuelle Leistung<sup>16</sup></strong><br />
                            im ganzen Spektrum der Fehlsichtigkeit und in jedem Alter
                        </Container>

                        <Container left={1303} top={1074} width={300} as="p" className="text6">
                        <img src={img2} />
                        Pupillenoptimiert
                        </Container> 

                        <Container left={1563} top={1074} width={300} as="p" className="text6">
                        <img src={img3} />
                        Nicht pupillenoptimiert
                        </Container> 


                    </Overlay>

                </Overlay>




            </Slide>
        </>

    );
};

export default Folder2Slide1;