import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';

const Slide = styled.div`
    .box1 {
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        span,
        p {
            position: relative;
        }

        .count {
            font-size: 53px;
            line-height: 66px;
            letter-spacing: -0.05em;
        }

        p {
            position: absolute;
            left: 0;
            width: 100%;
            font-size: 18px;
            line-height: 20px;

            
            &.bottom {
                top: 102px;
            }
        }
          
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 50, props.active, props.preview)

    let count2 = useCounter(0, 196, props.active, props.preview, 1)
    count2 = count2 / 10
    count2 = count2.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')

    let count3 = useCounter(0, 72, props.active, props.preview, 2)


    return (
        <Slide className="centered" step={props.step}>
            <h1>Warum sich das <strong>Geschäft mit Kontaktlinsen</strong> lohnt? </h1>


            <Container left={0} left={120} top={203} width={306} height={306} className="box1 flex-all-center text-center ">
                <img src={img1} />
                <span className="count">{count1}%</span>
            </Container>

            <Image src={img2} left={423} top={317} width={222} height={84} contain />

            <Container as="p" left={648} top={325} width={1300} className="color1">… der Brillenträger <strong>wünschen sich Beratung zu Kontaktlinsen.</strong><sup>1</sup></Container>

            <Container left={664} top={444} width={721} height={120} className="rounded flex-all-center text-center color1">
                <p><strong>Potenzielle Kontaktlinsenträger</strong></p>
            </Container>


            <Container left={89} top={594} width={1870} height={406} className="bg1 flex-all-center text-center box3 color1">
                <Arrow width={1615} className="container-top" />
                <Arrow width={1615} className="container-bottom" bgColor={'#CFF2FB'} />
            </Container>

            <Container left={0} left={120} top={648} width={306} height={306} className="box1 flex-all-center text-center ">
                <img src={img1} />
                <span className="count">{count2}</span>
                <p className="bottom color1">Mio.</p>
            </Container>

            <Container left={0} left={1055} top={648} width={306} height={306} className="box1 flex-all-center text-center ">
                <img src={img1} />
                <span className="count">{count3}%</span>

            </Container>



            <Container as="p" left={470} top={688} width={675} className="color1">… Brillenträger, die <strong>offen<br />
                für Kontaktlinsen</strong> sind<br />
                und ein enormes Potenzial<br />
                haben Ein-Tageskontakt-<br />
                linsen Kunden zu werden.<sup>2</sup>
            </Container>

            <Container as="p" left={1411} top={704} width={814} className="color1">
                … der befragten<br />
                Brillenträgern wollte sich<br />
                sogar <strong>in nächster Zeit<br />
                    Kontaktlinsen zulegen</strong>/<br />
                anschaffen.<sup>3</sup>
            </Container>

            <Container left={634} top={1057} width={781} height={120} className="rounded flex-all-center text-center color1">
                <p><strong>Hohes Kunden(bindungs)potenzial</strong></p>
            </Container>


        </Slide>
    );
};
