import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useSlideState } from "components/hooks";

import {
  Arrow,
  Container,
  CloseBtn,
  Image,
  MoreBtn,
  Overlay,
} from "components/parts";

import img1 from "./assets/img1.jpg";
import img2 from "./assets/img2.svg";

const Slide = styled.div`
  > .box1 {
    padding-left: 15px;
  }

  > .box2 .number {
    position: absolute;
    display: block;
    left: 2px;
    top: -32px;
    font-size: 97.5px;
    line-height: 122px;
    font-style: italic;
    text-align: right;
    color: #ffb900;
  }

  > .box2-1 {
    p.small {
      font-size: 12.5px;
      line-height: 1;
      margin-top: 5px;
    }
  }

  > .box2-2 .number {
    left: -6px;
  }

  .box2-2-2 {
    font-size: 18.5px;
    line-height: 23.5px;

    > .exclamation {
      position: absolute;
      left: 55px;
      top: -14px;
      color: #ffb900;
      font-style: italic;
      font-weight: 600;
      font-size: 64px;
      line-height: 80px;
    }
  }

  > .box2-3 .number {
    left: -6px;
  }

  .overlay1 {
    > .box1 {
      border-radius: 15px;
      border-color: #051f4a;
    }

    &::before {
      background: #051f4a !important;
    }
  }

  .overlay1-1 {
    .box1 {
      overflow: hidden;
    }

    .box2 {
      padding-right: 40px;

      .large {
        font-size: 17.5px;
        line-height: 19.5px;
      }

      .small {
        margin-top: 10px;
        font-size: 13px;
        line-height: 15px;
      }
    }
  }

  .overlay1-2 {
    > .box2 {
      font-size: 20.5px;
      line-height: 22px;
      letter-spacing: -0.01rem;
    }

    .box3 {
      font-size: 10.5px;
      line-height: 11.5px;

      .large {
        font-size: 16.5px;
      }

      p + p {
        margin-top: 10px;
      }
    }
  }
`;
const inititialState = {
  overlay1: null,
};

export default ({ ...props }) => {
  const { state, toggleOverlay } = useSlideState({
    inititialState,
    active: props.active,
  });

  return (
    <Slide className="centered">
      <h1 className="color2">
        2024 ist das <strong>Gleitsichtjahr</strong>
      </h1>

      <Container
        left={138}
        top={215}
        width={1740}
        height={193}
        className="bg1 box1 color2 flex-all-center"
      >
        <Arrow width={1065} className="container-bottom" bgColor={"#fff4dd"} />
        <p className="text-center">
          <strong>Cashback-Aktion über das gesamte Jahr:</strong>
          <br />
          Ihre Kunden sparen das gesamte Jahr auf jedes zweite Pack bis zu 30 €.
        </p>
        <MoreBtn
          className="x-right y-center"
          onClick={() => toggleOverlay("overlay1")}
          active={state.overlay1 === "overlay1"}
        />
      </Container>

      <Container
        left={138}
        top={486}
        width={1740}
        height={170}
        className="bg2 box2 box2-1 color2 flex-all-center fadeInFromLeft"
      >
        <span className="number number1 color2">1.</span>
        <p className="text-center">
          Bei jedem Kauf muss Ihr Kunde mindestens zwei Gleitsichtlinsenpacks
          <br />
          kaufen und die Rückerstattung auf das zweite Pack beantragen.
        </p>
        <p className="text-center small">
          Ausgeschlossen 1-DAY ACUVUE® MOIST MULTIFOCAL.
        </p>
      </Container>

      <Container
        left={138}
        top={710}
        width={1740}
        height={290}
        className="bg2 box2 box2-2 color2 flex-all-center fadeInFromLeft animate2"
      >
        <span className="number number1 color2">2.</span>
      </Container>

      <Container
        as="p"
        left={148}
        top={744}
        width={1725}
        className="color2 box2-2-1 text-center fadeInFromLeft animate2"
      >
        Für die Rückerstattung scannt Ihr Kunde den QR-Code auf dem Aktions-
        <br />
        flyer und lädt nach der Anmeldung Kassenbeleg und Produktfoto hoch.
      </Container>

      <Container
        left={177}
        top={868}
        width={1725}
        className="color2 box2-2-2 text-center fadeInFromLeft animate2"
      >
        <span className="exclamation">!</span>
        <strong>
          Wir prüfen, dass es sich um multifokale Kontaktlinsen handelt und dass
          diese
          <br />
          in IHREM Geschäft gekauft wurde. Die Kundenbindung an Sie ist uns
          wichtig.{" "}
        </strong>
      </Container>

      <Container
        left={138}
        top={1056}
        width={1740}
        height={170}
        className="bg2 box2 box2-3 color2 flex-all-center fadeInFromLeft animate3"
      >
        <span className="number number1 color2">3.</span>
        <p className="text-center">
          Ihr Kunde erhält die Rückerstattung.
          <br />
          Der Betrag ist abhängig vom Produkt und Packungsgröße.
        </p>
      </Container>

      <MoreBtn
        left={1836}
        top={1095}
        onClick={() => toggleOverlay("overlay2")}
        active={state.overlay1 === "overlay2"}
        exclamation
        className=" fadeInFromLeft animate3"
      />

      <Overlay
        active={state.overlay1 === "overlay1"}
        className="overlay1 overlay1-1 with-bg"
      >
        <Container
          left={100}
          top={201}
          width={1778}
          height={1025}
          className="box1 rounded border"
        >
          <Container
            left={133}
            top={628}
            width={822}
            height={303}
            className="box2 bg2 color2 flex-all-center"
          >
            <p className="text-center large">
              <strong> Multichannel-Kampagne</strong>
              <br />
              Aufmerksamkeit am Point of Sale
              <br />
              und in den sozialen Medien
            </p>
            <p className="text-center small">
              Die Kampagne läuft von Februar bis zum Jahresende und
              <br />
              wird mit aufmerksamkeitsstarken Materialien am POS
              <br />
              sowie mit digitalen Materialien unterstützt.{" "}
            </p>
          </Container>

          <Image left={882} top={530} width={892} height={492} src={img1} />
        </Container>

        <Container
          as="p"
          left={138}
          top={263}
          width={1740}
          className="text-center color2"
        >
          <strong>
            Wir möchten Sie das ganze Jahr über unterstützen,
            <br />
            reine Brillenträger in umsatzstarke Dual-Träger zu verwandeln!
          </strong>
        </Container>

        <Container as="p" left={296} top={451} width={1740} className="color2">
          Daher bieten wir Ihren Kunden die Möglichkeit, auf jede zweite Packung
          <br />
          (beim Kauf von mindestens 2 Produkten) von ACUVUE® OASYS MULTIFOCAL
          <br />
          und ACUVUE® OASYS MAX 1-Day MULTIFOCAL einen Rabatt zu erhalten.
          <br />
          Nicht nur einmalig, sondern das ganze Jahr über.
          <br />
          <br />
          <strong>
            Wiederholtes Rabatteinlösen erfordert Kauf im gleichen Geschäft!
          </strong>
        </Container>

        <Arrow
          left={236}
          top={440}
          width={64}
          className=""
          arrowColor={"#ffb900"}
        />

        <Arrow
          left={236}
          top={680}
          width={64}
          className=""
          arrowColor={"#ffb900"}
        />
      </Overlay>

      <Overlay
        active={state.overlay1 === "overlay2"}
        className="overlay1 overlay1-2 with-bg"
      >
        <Container
          left={100}
          top={201}
          width={1778}
          height={1025}
          className="box1 rounded border"
        />

        <Image left={154} top={406} width={1676} height={298} src={img2} />

        <Container
          as="p"
          left={100}
          top={263}
          width={1778}
          className="text-center color2"
        >
          Ihr Kunde erhält die Rückerstattung.
          <br />
          Der Betrag ist abhängig vom Produkt und Packungsgröße.{" "}
        </Container>

        <Container
          as="p"
          left={458}
          top={445}
          width={700}
          className="box2 color2"
        >
          <strong>
            ACUVUE® OASYS
            <br />
            MULTIFOCAL
          </strong>
        </Container>

        <Container
          as="p"
          left={908}
          top={445}
          width={700}
          className="box2 color2"
        >
          <strong>
            ACUVUE® OASYS MAX 1-Day
            <br />
            MULTIFOCAL
          </strong>
        </Container>

        <Container
          as="p"
          left={458}
          top={555}
          width={700}
          className="box2 color2"
        >
          <strong>6er Pack</strong>
        </Container>

        <Container
          as="p"
          left={908}
          top={555}
          width={700}
          className="box2 color2"
        >
          <strong>30er Pack</strong>
        </Container>

        <Container
          as="p"
          left={1358}
          top={555}
          width={700}
          className="box2 color2"
        >
          <strong>90er Pack</strong>
        </Container>

        <Container
          as="p"
          left={458}
          top={617}
          width={700}
          className="box2 color2"
        >
          <strong>15,- € Rückerstattung</strong>
        </Container>

        <Container
          as="p"
          left={908}
          top={617}
          width={700}
          className="box2 color2"
        >
          <strong>15,- € Rückerstattung</strong>
        </Container>

        <Container
          as="p"
          left={1358}
          top={617}
          width={700}
          className="box2 color2"
        >
          <strong>30,- € Rückerstattung</strong>
        </Container>

        <Container as="p" left={154} top={754} width={1680} className="box3">
          <p className="large">
            <strong>
              Teilnahmebedingungen: Aktionszeitraum vom 01.02.–31.12.2024
            </strong>
          </p>
          <p>
            {" "}
            Aktions-Teilnahmeberechtigt ist jede natürliche Person, deren
            Wohnort sich in Deutschland oder Österreich befindet und die bei
            Teilnahme an der Aktion 18 Jahre alt ist und unbeschränkt
            geschäftsfähig ist. Jeder Teilnehmer kann nur im eigenen Namen und
            nur einmal an der Aktion teilnehmen. Pro Person sowie IBAN darf
            lediglich ein Kassenbon pro Kauf eingereicht werden. Jeder Kassenbon
            darf nur einmal verwendet werden. Der Teilnahme- und Kaufzeitraum
            ist vom 01.02.2024 bis zum 31.12.2024 23:59 Uhr. Die Aktion gilt für
            nur für die Produkte ACUVUE® OASYS MULTIFOCAL und ACUVUE® OASYS MAX
            1-Day MULTIFOCAL , die im jeweiligen Kaufzeitraum in einem
            teilnehmenden Fachhandel in Deutschland oder Österreich gekauft
            wurden.
          </p>
          <p>
            Eine Teil-Erstattung erfolgt für jeden Kauf von mind. 2 Packungen
            eines Produktes. Für jeden Kauf von zwei ACUVUE® OASYS MULTIFOCAL
            Packungen oder von zwei 30er ACUVUE® OASYS MAX 1-Day Multifocal
            werden 15,- € erstattet. Für den Kauf von zwei 90er Packungen
            ACUVUE® OASYS MAX 1-Day Multifocal werden 30,- € erstattet. Im
            Aktionszeitraum kann nach einer ersten Teil-Erstattung beliebig oft
            weitere Teil-Erstattungen in dem gleichen Geschäft erfolgen. Der
            zweite und jeder folgende Kauf muss unter den gleichen Bedingungen
            bei dem <strong>gleichen</strong> Geschäft erfolgen.
          </p>
          <p>
            Die Teilnahme ist ausschließlich durch Hochladen des jeweiligen
            Bons, eines Fotos des gekauften Produktes und der vollständigen
            Eingabe der Daten auf der Aktionsseite möglich. Auf dem Scan oder
            Foto des Original-Kaufbelegs müssen das Produkt, der Fachhandel, der
            Preis, die Bonnummer sowie das Kaufdatum gut lesbar sein.
            Post-Einsendungen werden nicht berücksichtigt. Die Teil-Erstattung
            des Kaufpreises erfolgt auf das angegebene Bankkonto bei korrekter
            Angabe der IBAN. Jeder Teilnehmer ist für die Richtigkeit seiner
            Daten verantwortlich. Eine Kombination mit anderen Aktionen ist
            nicht möglich. Der Original-Kassenbon ist als Kaufnachweis bis zur
            Erstattung aufzubewahren und auf Anforderung vorzulegen. Die
            vollständigen Teilnahmebedingungen finden Sie für Deutschland auf
            <strong> acuvue.de/alterssichtigkeit-geht-auch-anders</strong> und
            für Österreich auf
            <strong> acuvue.at/alterssichtigkeit-geht-auch-anders</strong>.
          </p>
        </Container>
      </Overlay>
    </Slide>
  );
};
