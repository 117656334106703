import Slide1 from './slides/slide1'
import Slide2 from './slides/slide2'
import Slide3 from './slides/slide3'
import Slide4 from './slides/slide4'
import Slide5 from './slides/slide5'
import Slide6 from './slides/slide6'
import Slide7 from './slides/slide7'
import Slide8 from './slides/slide8'
import Slide9 from './slides/slide9'
import Slide10 from './slides/slide10'
import Slide11 from './slides/slide11'

const Theme = {
    color1: '#390075',
    bg1: '#E4DAEB',
    bg2: '#9166AE',
}

export const Slides = {
    'Folder7Slide1': { name: Slide1, theme: Theme, footer: 'transparent white' },
    'Folder7Slide2': { name: Slide2, theme: Theme },
    'Folder7Slide3': { name: Slide3, theme: Theme },
    'Folder7Slide4': { name: Slide4, theme: Theme },
    'Folder7Slide5': { name: Slide5, theme: Theme },
    'Folder7Slide6': { name: Slide6, theme: Theme },
    'Folder7Slide7': { name: Slide7, theme: Theme },
    'Folder7Slide8': { name: Slide8, theme: Theme },
    'Folder7Slide9': { name: Slide9, theme: Theme },
    'Folder7Slide10': { name: Slide10, theme: Theme },
    'Folder7Slide11': { name: Slide11, theme: Theme },
}

export const Order = [
    { key: 0, component: 'Folder7Slide1' },
    { key: 1, component: 'Folder7Slide2' },
    { key: 2, component: 'Folder7Slide3' },
    { key: 3, component: 'Folder7Slide4' },
    { key: 4, component: 'Folder7Slide5' },
    { key: 5, component: 'Folder7Slide6' },
    { key: 6, component: 'Folder7Slide7' },
    { key: 7, component: 'Folder7Slide8' },
    { key: 8, component: 'Folder7Slide9' },
    { key: 9, component: 'Folder7Slide10' },
    { key: 10, component: 'Folder7Slide11' },
]