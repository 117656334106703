import { useState } from "react";

const initialDragging = {
    isDragging: false,
    xStart: null,
    x: null,
    yStart: null,
    y: null
}

const useDrag = ({onStartCallback, onEndCallback}) => {
    const [dragging, setDragging] = useState({...initialDragging})

    const onDragStart = (event) => {
        setDragging({
            ...dragging,
            isDragging: true,
            xStart: event.clientX,
            yStart: event.clientY
        })

        onStartCallback && onStartCallback();
    }

    const onDragMove = (event) => {
        const x = event.clientX - dragging.xStart;
        const y = event.clientY - dragging.yStart;

        setDragging({
            ...dragging,
            isDragging: true,
            x,
            y
        })
    }

    const onDragEnd = () => { 
        onEndCallback(dragging); 

        setDragging({...initialDragging})
    }

    return {
        dragging,
        onDragStart,
        onDragMove,
        onDragEnd
    }
}

export default useDrag