import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow2, Arrow3, Container, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from './assets/bg.jpg';

const Slide = styled.div`
   .box1 {
        align-items: center;
        justify-content: flex-start;

        p:nth-child(1) {
            margin-left: 59px;
            flex-basis: 176px;
        }

        p:nth-child(2) {
            flex-basis: 164px;
        }
   }

   .box2 {
       p + p {
           margin-top: 8px;
       }
   }
`
const inititialState = {
    overlay1: false
}


export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <>
            <div className="bg right" style={{ backgroundImage: `url(${img1})` }}></div>
            <Slide className="centered" step={props.step}>
                <h1><strong>Kunden suchen nach Kompetenz</strong></h1>
                <Container left={100} top={250} width={1427} height={198} className="bg1 flex color1 box1">
                    <p><strong>Kompetenz</strong></p>
                    <p><strong>Vertrauen</strong></p>
                    <p><strong>Wird Kompetenz und<br />
    Vertrauen über das<br /> 
    Produkt kommuniziert?</strong></p>
                </Container>

                <Arrow3 left={500} top={318} />
                <Arrow3 left={823} top={318} />

                <Container left={100} top={500} width={666} className="text-center color1">
                    <p><strong>Marke</strong></p>
                </Container>

                <Container left={100} top={584} width={666} height={462} className="bg1 text-center flex-all-center color1 box2">
                    <p><strong>Weltweite Verfügbarkeit</strong></p>
                    <p><strong>Transparenz in Bezug auf<br />den Inhalt</strong></p>
                    <p><strong>Neueste Technologie<br /> 
    = Produktqualität<br /> 
    = Produktzufriedenheit</strong></p>
                    <p><strong>Sicherheit</strong></p>
                    <Arrow width={556} className="container-bottom"  bgColor={'#FDE1C2'} />
                </Container>

                <Container left={100} top={1100} width={666} className="text-center color1 fadeInFromTop">
                    <p><strong>Vertrauen</strong></p>
                </Container>
                

                <Container left={861} top={500} width={666} className="text-center color1">
                    <p><strong>Eigenmarke</strong></p>
                </Container>

                <Container left={861} top={584} width={666} height={401} className="bg1 text-center flex-all-center color1 box2">
                    <p><strong>Lokal begrenzt</strong></p>
                    <p><strong>Wechselnder Inhalt</strong></p>
                    <p><strong>Alte Technologie</strong></p>
                    <p><strong>Eigenmarke als<br />
    „Image“-Botschafter?</strong></p>
                    <Arrow width={556} className="container-bottom" bgColor={'#FDE1C2'} />
                </Container>

                <Container left={861} top={1038} width={666} className="text-center color1 fadeInFromTop">
                    <p><strong>Vertrauen</strong></p>
                </Container>

                <MoreBtn
                    left={1152} top={1105}
                    active={state.overlay1 === 'overlay1'}
                    onClick={() => toggleOverlay('overlay1')} 
                    className="fadeIn"
                     />

                <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                    <Container left={752} top={875} width={884} height={260} className="rounded text-center color1 flex-all-center">
                        <p><strong>Es ist eine Frage der Zeit, wann der<br />
                        Kunde herausfindet, welche Marke<br />
                        hinter der Eigenmarke steht.</strong></p>
                    </Container>
                </Overlay>
            
            </Slide>
        </>
    );
};
