import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay, TextBox } from 'components/parts'

const Slide = styled.div`
    
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1>Referenzen und Fußnoten in diesem Kapitel:</h1>
            <TextBox>
                <ol>
                    <li>JJV Data on file 2019 – Studie zum Beratungsansatz im deutschen Augenoptiker-Fachgeschäft (Quantitative Konsumentenbefragung durchgeführt vom Rheingold Institut)</li>

                    <li>Path to Purchase Studie, 2020 2. EASE-Studie, von Atkins und Morgan (UK 2009, D 2011), 3. London Business School Studie, 2001</li>

                    <li>JJV Considerer-Studie 2020</li>

                    <li>EASE-Studie von Atkins und Morgan (UK 2009, D 2011)</li>

                    <li>London Business School Studie, 2001</li>

                    <li>Review von Aslam, A. in „Der Augenoptiker“, 2014</li>

                    <li>Rheingold-Studie Qualitative und Quantitative Studie zu Dualen Trägern, 2018/2019 für JJVC</li>

                    <li>LBS (2001)</li>

                    <li>London Business School unabhängige Studie</li>

                    <li>Verbesserung der Herangehensweise zur Auswahl von Sehhilfen (EASE): eine multizentrische, praxisbasierte Studie zur Wirkung des Einsetzens von Kontaktlinsen
                    vor dem Verkauf von Brillen; Sarah I. Morgan, Nick P. Atkins und Philip B. Morgan</li>

                    <li>JJV Data on file 2017: Internal analysis based on third party data.</li>

                    <li>In market assessment among 142 Ophthalmologists in France (referenced as contact lens fitters) fitting 1453 patients (n=804 Neophytes, n=186 Lapsed Wearers, n=463 Existing CL Wearers).</li>

                    <li>Interne Analyse basierend auf Daten und Studien unabhängiger Dritter, Panelmarkt Retail/Internet, Aussteiger Tageslinsen-Segment Gesamtzeitraum 2016.</li>

                    <li>Berechnungsgrundlage: Durchschnittpreise nach interner Analyse: Tageslinse Premium Box 32€, Tageslinse Normal Box 27€, 14-Tageslinse Box 30€, Monatslinse Box 43€, Pflege 13€ / Monat</li>

                    <li>Janelle Routhier et al: Contact Lens &amp; Anterior Eye; Volume 35, supplement 1, E2, December 01, 2012.</li>

                    <li>Shopper Segmentation Study Germany by MillwardBrown Healthcare 2018 mit &gt; 2000 Studienteilnehmern</li>

                    <li>Survey conducted from 4/19 – 5/19. Sample comprised of 853 US Eye Doctors (686 optometrists &amp; 167 ophthalmologists)</li>

                    <li>JJV Growth Levers 2019 Studie, Präferenzen bei KL-Neuträgern, 3135 Studien-Teilnehmer</li>
                    
                    <li>P2P Studie 2018/2019, Zahlen für DE</li>
                </ol>

                <p><strong>Wichtige Sicherheitsinformationen:</strong> ACUVUE® Kontaktlinsen sind für eine Sehkorrektion indiziert. Wie bei jeder Kontaktlinse können Augenprobleme, einschließlich Hornhautgeschwüre, auftreten. Bei einigen Trägern kann es zu leichten Irritationen, Juckreiz oder Unbehagen kommen. Kontaktlinsen sollten nicht bei Augeninfektionen oder anderen Augenkrankheiten verwendet werden.
                Vollständige Informationen, einschließlich Kontraindikationen und Vorsichtsmaßnahmen, entnehmen Sie bitte der Gebrauchsanweisung oder besuchen Sie unsere J&amp;J-Website jnjvisioncare.de/at</p>

                <p>Alle ACUVUE® Kontaktlinsen weisen einen Klasse 1 oder Klasse 2 UV-Schutz auf, der dazu beiträgt, die Hornhaut und das Augeninnere vor schädlicher UV-Strahlung zu schützen.<br />
                UV absorbierende Kontaktlinsen sind KEIN Ersatz für UV absorbierende und schützende Sonnenbrillen, da sie das Auge und seine Umgebung nicht vollständig abdecken.<br />
                UV- Transmission mit –1,00 Dioptrien Linse gemessen.</p>

                <p>ACUVUE®, ACUVUE® OASYS 1-Day und HydraLuxe® sind eingetragene Marken von Johnson &amp; Johnson.<br />
                © 2022 Johnson &amp; Johnson Medical GmbH / Johnson &amp; Johnson Medical Products GmbH</p>

            </TextBox>
        </Slide>
    );
};
