import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'
import { authenticationConstants } from '../constants';
import { fetchData } from '../api';

const getState = state => state.authentication;

export function* handleLogin(payload) {

    try {

        let payloadNew = {
            action: 'login',
            email: payload.email,
            password: payload.password 
        }

        let { data } = yield call(fetchData, payloadNew);
        
        if(data.success) {
            localStorage.setItem('user', JSON.stringify({email: payload.email, token: data.success.token}));
            yield put({ type: authenticationConstants.LOGIN_SUCCESS, email: payload.email, token: data.success.token, level: data.success.level === 10 ? 'admin' : null })
           
        } else {
            yield put({ type: authenticationConstants.LOGIN_FAILURE, error: data.error })
        }
        
    } catch (error) {
        console.log('>>')
    }
}

export function* handleLogout(payload) {

    yield put({ type: authenticationConstants.LOGOUT_SUCCESS })
}

export function* handleValidateLogin(payload) {

    try {

        const items = yield select(getState);

        let payloadNew = {
            action: 'validateLogin',
            email: items.email,
            token: items.token,
        }

        let { data } = yield call(fetchData, payloadNew);

        if(data.error) {
            yield put({ type: authenticationConstants.VALIDATELOGIN_FAILURE })
        }

        

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }

}

export function* handleRequestPassword(payload) {

    try {

        const items = yield select(getState);

        let payloadNew = {
            action: 'requestPassword',
            email: payload.email
        }
    

        let { data } = yield call(fetchData, payloadNew);

        if(data.success) {
            yield put({ type: authenticationConstants.REQUESTPASSWORD_SUCCESS })
        
        } else {
            yield put({ type: authenticationConstants.REQUESTPASSWORD_FAILURE, error: data.error })
        }

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }

}


export function* handleGetUsers(payload) {

    console.log('>>')

    try {

        const items = yield select(getState);

        let payloadNew = {
            action: 'getUsers',
            email: items.email,
            token: items.token,
        }

        let { data } = yield call(fetchData, payloadNew);

        yield put({ type: authenticationConstants.GETUSERS_SUCCESS, users: data.success.data })

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }

}

export function* handleSaveUser(payload) {

    console.log('>>')

    try {

        const items = yield select(getState);

        let payloadNew = {
            action: 'saveUser',
            email: items.email,
            token: items.token,
            user: payload.user
        }

        let { data } = yield call(fetchData, payloadNew);

        yield put({ type: authenticationConstants.SAVEUSER_SUCCESS, user: data.success.data })

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }

}

export function* handleDeleteUser(payload) {

    try {

        const items = yield select(getState);

        let payloadNew = {
            action: 'deleteUser',
            email: items.email,
            token: items.token,
            id: payload.id
        }

        let { data } = yield call(fetchData, payloadNew);

        yield put({ type: authenticationConstants.DELETEUSER_SUCCESS, id: data.success.data })

    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }

}

export function* handleChangePassword(payload) {

    if(payload.password.length < 6) {
        yield put({ type: authenticationConstants.CHANGEPASSWORD_FAILURE, error: 'Bitte Passwort mit mindestens 6 Zeichen angeben. <span class="fr">Veuillez saisir un mot de passe avec au moins 6 caractères.</span>' })
    } else {

        let payloadNew = {
            action: 'changePassword',
            email: payload.email,
            password: payload.password,
            hash: payload.hash 
        }

        let { data } = yield call(fetchData, payloadNew);

        if(data.success) {
            yield put({ type: authenticationConstants.CHANGEPASSWORD_SUCCESS })
        
        } else {
            yield put({ type: authenticationConstants.CHANGEPASSWORD_FAILURE, error: data.error })
        }
    }

}


export default function* watchConfigLoad() {
    yield takeEvery(authenticationConstants.LOGIN_REQUEST, handleLogin);
    yield takeEvery(authenticationConstants.LOGOUT_REQUEST, handleLogout);
    yield takeEvery(authenticationConstants.VALIDATELOGIN_REQUEST, handleValidateLogin);

    yield takeEvery(authenticationConstants.REQUESTPASSWORD_REQUEST, handleRequestPassword);
    yield takeEvery(authenticationConstants.CHANGEPASSWORD_REQUEST, handleChangePassword);

    yield takeEvery(authenticationConstants.GETUSERS_REQUEST, handleGetUsers);
    yield takeEvery(authenticationConstants.SAVEUSER_REQUEST, handleSaveUser);
    yield takeEvery(authenticationConstants.DELETEUSER_REQUEST, handleDeleteUser);
}