import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Arrow2, Arrow3, Container, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.svg'
import bg2 from './assets/bg2.svg'
import img1 from './assets/img1.svg'
import img2 from './assets/img2.jpg'

const pathAnimation = keyframes`
    0% {
        width: 0;
    }
    100% {  
        width: 863.75px; 
    }
`

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {  
        opacity: 1;
    }
`

const Slide = styled.div`
    .path {
        transform-origin: left top;
        overflow: hidden;

        ${({ preview }) => !preview && css`
            width: 0; 
        `}

        ${({ active, preview }) => (active && !preview) && css`   
            animation: ${pathAnimation} 1s forwards linear;
            animation-delay: 3s
        `}
    }

   .points {
        >.label {
            position: absolute;
            width: 170px;
            font-weight: 600;
            font-size: 13.16px;
            line-height: 15.8px;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
        }
        
        >.label1 {
            top: 140px;
            left: 12px;
        }
        
        >.label2 {
            top: 140px;
            left: 194px;
        }
        
        >.label3 {
            top: 134px;
            left: 539px;
        }
        
        >.label4 {
            top: 134px;
            left: 700px;
        }
        
        >.label5 {
            top: 201px;
            left: 369px;
            color: #000000;
        }
        
        >.label6,
        >.label7 {
            left: 1px;
            width: 280px;
            color: #000000;
            text-align: left;
            font-size: 14.3px;
            line-height: 17.2px;
        }
        
        >.label6 {
            top: -20px;
        }
        
        >.label7 {
            top: 298px;
        }
        
        .step {
            position: absolute;

            ${({ preview }) => !preview && css`
                opacity: 0;
            `}
            
            ${({ active, preview }) => (active && !preview) && css`   
                animation: ${fadeIn} 1s forwards ease-out;
                animation-delay: 0.6s
            `}
        }
  
        .step .circle {
            position: absolute;
            left: 0;
            top: 0;
            width: 18.18px;
            height: 18.17px;
            border-radius: 50%;
            background: #00a3e0;
        }
        
        .step .text {
            position: absolute;
            width: 200px;
            font-size: 10.3px;
            line-height: 12.4px;
        }
        
        .step1 {
            left: 2px;
            top: 227px;
        }
        
        .step1 .text {
            left: 10px;
            top: 21px;
        }
        
        .step2 {
            left: 53px;
            top: 221px;
            animation-delay: 0.15s;
        }
        
        .step2 .text {
            text-align: center;
            left: -59px;
            top: 6px;
        }
        
        .step3 {
            left: 76px;
            top: 190px;
            animation-delay: 0.3s;
        }
        
        .step3 .text {
            text-align: center;
            left: -59px;
            top: 6px;
        }
        
        .step4 {
            left: 109px;
            top: 84px;
            animation-delay: 0.45s;
        }
        
        .step4 .text {
            text-align: right;
            left: -205px;
            top: 3px;
        }
        
        .step5 {
            left: 128px;
            top: 52px;
            animation-delay: 0.6s;
        }
        
        .step5 .text {
            text-align: right;
            left: -205px;
            top: 3px;
        }
        
        .step6 {
            left: 158px;
            top: 30px;
            animation-delay: 0.75s;
        }
        
        .step6 .text {
            text-align: right;
            left: -201px;
            top: -5px;
        }
        
        .step7 {
            left: 178px;
            top: 29px;
            animation-delay: 0.9s;
        }
        
        .step7 .text {
            left: -91px;
            top: -17px;
            text-align: center;
        }
        
        .step8 {
            left: 200px;
            top: 29px;
            animation-delay: 1.05s;
        }
        
        .step8 .text {
            left: 24px;
            top: -3px;
        }
        
        .step9 {
            left: 226px;
            top: 51px;
            animation-delay: 1.2s;
        }
        
        .step9 .text {
            left: 26px;
            top: 4px;
        }
        
        .step10 {
            left: 242px;
            top: 84px;
            animation-delay: 1.35s;
        }
        
        .step10 .text {
            left: 24px;
            top: 3px;
        }
        
        .step11 {
            left: 267px;
            top: 191px;
            animation-delay: 1.5s;
        }
        
        .step11 .text {
            left: -205px;
            top: 4px;
            text-align: right;
        }
        
        .step12 {
            left: 336px;
            top: 220px;
            animation-delay: 1.65s;
        }
        
        .step12 .text {
            left: 3px;
            top: 24px;
        }
        
        .step13 {
            left: 359px;
            top: 190px;
            animation-delay: 1.8s;
        }
        
        .step13 .text {
            left: -79px;
            top: 20px;
            text-align: center;
        }
        
        .step14 {
            left: 385px;
            top: 53px;
            animation-delay: 1.95s;
        }
        
        .step14 .text {
            left: -204px;
            top: 5px;
            text-align: right;
        }
        
        .step15 {
            left: 532px;
            top: 38px;
            animation-delay: 2.05s;
        }
        
        .step15 .text {
            left: 25px;
            top: -2px;
        }
        
        .step16 {
            left: 553px;
            top: 63px;
            animation-delay: 2.2s;
        }
        
        .step16 .text {
            left: 26px;
            top: 3px;
        }
        
        .step17 {
            left: 620px;
            top: 190px;
            animation-delay: 2.35s;
        }
        
        .step17 .text {
            left: -107px;
            top: 22px;
            text-align: center;
        }
        
        .step18 {
            left: 727px;
            top: 199px;
            animation-delay: 2.5s;
        }
        
        .step18 .text {
            left: -47px;
            top: 10px;
            text-align: center;
        }
        
        .step19 {
            left: 793px;
            top: 69px;
            animation-delay: 2.65s;
        }
        
        .step19 .text {
            left: -205px;
            top: 4px;
            text-align: right;
        }
        
        .step20 {
            left: 816px;
            top: 38px;
            animation-delay: 2.8s;
        }
        
        .step20 .text {
            left: -204px;
            top: 0px;
            text-align: right;
        }
        
        .step21 {
            left: 864px;
            top: 48px;
            animation-delay: 2.95s;
        }
        
        .step21 .text {
            left: -99px;
            top: 23px;
            text-align: center;
        }
        
        .step7 .circle,
        .step9 .circle,
        .step10 .circle,
        .step11 .circle {
            background: #003087;
        }
        
        .step12 .circle,
        .step13 .circle,
        .step14 .circle {
            background: #df9933;
        }
        
        .step15 .circle,
        .step16 .circle,
        .step17 .circle {
            background: #009ca6;
        }
        
        .step18 .circle,
        .step19 .circle,
        .step20 .circle,
        .step21 .circle {
            background: #330072;
        }
   }

   .box1 {
        background: #fff;
        font-size: 12.8px;
        line-height: 14.4px;
    }

   .box2 {
       padding-top: 30px;
       padding-left: 37px;
   }
`
const inititialState = {
    overlay1: null
}

//  

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1>Die <strong>Customer Journey</strong> eines Kontaktlinsenträgers ist <strong>nicht<br />
mehr linear</strong></h1>
            <Container left={100} top={271} width={1850} height={113}>
                <p className="small">Um seine Bedürfnisse zu erfüllen müssen Sie da sein, wo er/sie ist. <strong>Richtiger Ort, richtige Zeit,<br />
richtige Kontaktlinse und richtiges Kommunikations-Bedürfnis.</strong></p>
            </Container>

            <Image left={125} top={490} width={1800} height={581} src={bg} />
            
            <Container left={146.7} top={500} width={1727.5} height={478} className="path">
                <Image left={0} top={0} width={1727.5} height={478} src={img1} />
            </Container>
            
            <Image left={125} top={718.5} width={1767} height={128} src={bg2} />

            <Container left={125} top={490} width={1800} height={581} className="points">
                <p className="label label1">Bewusstsein</p>
                <p className="label label2">Erwägen</p>
                <p className="label label3">PROBETRAGEN<br />
							1. KAUF</p>
                <p className="label label4">WEITERE KÄUFE<br />
							LOYALITÄT</p>
                <p className="label label6">ONLINE TOUCHPOINTS</p>
                <p className="label label7">OFFLINE TOUCHPOINTS</p>
                <span className="step step1 show1">
                    <span className="circle"></span>
                    <span className="text">PR</span>
                </span>
                <span className="step step2 show2">
                    <span className="circle"></span>
                    <span className="text">RADIO,<br />
								TV, PRINT,<br />
                            OUTDOOR</span>
                </span>
                <span className="step step3 show3">
                    <span className="circle"></span>
                    <span className="text">WORD<br />
								OF MOUTH</span>
                </span>
                <span className="step step4 show4">
                    <span className="circle"></span>
                    <span className="text">ONLINE ANZEIGEN</span>
                </span>

                <span className="step step5 show5">
                    <span className="circle"></span>
                    <span className="text">VIRALE EMAIL</span>
                </span>
                <span className="step step6 show6">
                    <span className="circle"></span>
                    <span className="text">DIGITALE GROßFLÄCHEN</span>
                </span>
                <span className="step step7 show8">
                    <span className="circle"></span>
                    <span className="text">SUCHE</span>
                </span>
                <span className="step step8 show7">
                    <span className="circle"></span>
                    <span className="text">LANDING PAGE</span>
                </span>

                <span className="step step9 show9">
                    <span className="circle"></span>
                    <span className="text">BLOG</span>
                </span>
                <span className="step step10 show10">
                    <span className="circle"></span>
                    <span className="text">HÄNDLERSEITEN</span>
                </span>
                <span className="step step11 show11">
                    <span className="circle"></span>
                    <span className="text">DIREKT<br />
								MAILING</span>
                </span>
                <span className="step step12 show12">
                    <span className="circle"></span>
                    <span className="text">GESCHÄFT</span>
                </span>

                <span className="step step13 show13">
                    <span className="circle"></span>
                    <span className="text">CALL<br />
								CENTER</span>
                </span>
                <span className="step step14 show14">
                    <span className="circle"></span>
                    <span className="text">WEBSEITE</span>
                </span>
                <span className="step step15 show15">
                    <span className="circle"></span>
                    <span className="text">MOBIL</span>
                </span>
                <span className="step step16 show16">
                    <span className="circle"></span>
                    <span className="text">INSTANT MESSENGER/<br />CHAT</span>
                </span>

                <span className="step step17 show17">
                    <span className="circle"></span>
                    <span className="text">CALL<br />
								CENTER</span>
                </span>
                <span className="step step18 show18">
                    <span className="circle"></span>
                    <span className="text">PROMOTION<br />
								AUF KAUFBELEG</span>
                </span>
                <span className="step step19 show19">
                    <span className="circle"></span>
                    <span className="text">BLOG</span>
                </span>
                <span className="step step20 show20">
                    <span className="circle"></span>
                    <span className="text">EMAIL</span>
                </span>
                <span className="step step21 show21">
                    <span className="circle"></span>
                    <span className="text">NEWSLETTER</span>
                </span>

            </Container>

            <Image left={883} top={640} width={286} height={286} src={img2} className="rounded" />

            <Container left={927.8} top={898} width={194.5} height={64.5} className="box1 text-center flex-all-center color1">
                <p><strong>Kontaktlinsen-<br/>spezialist </strong></p>
            </Container> 

            <MoreBtn
                left={1711} top={265}
                active={state.overlay1 === 'overlay1'}
                onClick={() => toggleOverlay('overlay1')}
            />

            <Overlay active={state.overlay1 === 'overlay1'} className="with-bg">
                <Container left={878} top={306} width={976} height={463} className="rounded box2 text color1">
                    <p><strong>Was will der Kontaktlinsenträger zu dem<br />
                    entsprechenden Zeitpunkt wissen?</strong></p>

                    <p>z. B. Infos über die Kontaktlinse an sich,<br />
                    konkrete Produktinformationen,<br /> 
Handhabungs-Tipps, oder weitergehende<br />
Tipps zu Wasser und Kontaktlinsen, UV-<br />
Schutz, etc..</p>
                </Container>
            </Overlay>

        </Slide>
    );
};
