import React from 'react';
import styled, { css } from 'styled-components'
import { Image, Container, Arrow, Overlay, MoreBtn } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useCounter } from 'components/hooks'
import { useSlideState } from 'components/hooks'

import bg from './assets/bg.jpg'
import bg2 from './assets/bg2.jpg'
import img1 from './assets/img1.png'

import img2_1 from './assets/img2_1.jpg'
import img2_2 from './assets/img2_2.jpg'
import img2_3 from './assets/img2_3.jpg'
import img2_4 from './assets/img2_4.jpg'

const Background = styled.div`
    ${({ active }) => active === false && css`
        opacity: 0;
    `}

    transition: all 1s ease-out;
`

const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 45px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 9px;
        line-height: 12.5px;
    }

    .text2 {
        line-height: 27.5px;
    }

    .text3 {
        font-size: 27.5px;
        line-height: 30px;

        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}
    }

    .text4 {
        line-height: 27.5px;

        .count {
            font-size: 35px;
            color: #fff;
        }
    }

    .container-animate {
        animation-play-state: paused;

        ${({ step }) => step >= 2 && css`
            animation-play-state: running;
        `}


    }

    ${ArrowContainer} {
        span {
            background: #ffffff;
        }

        .bg {
            display: none;
        }
    }

    .overlay1 {
        > .box1 {
            border: 1.5px solid #3a8dde;
            border-radius: 15px;
            background: #fff;
        }

        > .box2 {
            position: relative;
            font-size: 12.5px;
            line-height: 15px;

            > img {
                position: absolute;
                z-index: 1;
                border: 1.35px solid #2b2747;
                border-radius: 50%;
                width: 78px;
                width: 78px;
                left: 83px;
            }

            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .box1 {
                background: #2b2747;
                padding-left: 30px;

                span {
                    margin-top: 5px;
                    font-size: 7.5px;
                    line-height: 8.5px;
                }
            }

            .box2 {
                background: #4779bb;
            }

            .box3 {
                background: #a7a9ac;
            }
        }

        > .box3,
        > .box4 {
            color: #13174a;
        }

        > .box4 {
            font-size: 18px;
            line-height: 22px;
        }
    }

    
`
const inititialState = {
    overlay1: null
}



const Folder2Slide1 = ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })


    const count1_1 = useCounter(0, 94, props.active && props.step >= 2, props.preview, 1)
    const count1_2 = useCounter(0, 80, props.active && props.step >= 2, props.preview, 1)

    const count2_1 = useCounter(0, 90, props.active && props.step >= 2, props.preview, 1)

    return (
        <>
            <Background className="bg" style={{ backgroundImage: `url(${bg})` }}></Background>
            <Background className="bg" style={{ backgroundImage: `url(${bg2})` }} active={props.step >= 2}></Background>
            <Slide className="centered" step={props.step}>
                <h1><strong>Unsere aktuell leistungsstärkste<br />
                    Multifokallinse</strong></h1>
                <Image left={636} top={440} width={768} height={456} src={img1} />
                <Container left={744} top={700} width={544} as="p" className="text1 text-center">
                    Nur zu Illustrationszwecken
                </Container>

                <Container left={475} top={302} width={1090} as="p" className="text2 text-center">
                    Nachgewiesene Leistung <strong>in klinischen Studien</strong>°<sup>5</sup>
                </Container>

                <Container left={22} top={441} width={593} as="p" className="text3 text-right fadeIn">
                    <strong>Scharfe, klare<br />
                        Sicht</strong><sup>±5</sup>
                </Container>

                <div className="container-animate fadeIn animate2">

                    <Arrow left={320} top={370} width={508} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={0} top={667} width={593} as="p" className="text4 text-right">
                        <span className="count">{count1_1} %</span> erleben klare<br />
                        Sehqualität an digitalen<br />
                        Geräten°<sup>5</sup><br /><br />

                        <span className="count">{count1_2} %</span> erleben klare<br />
                        Sehqualität im Nah-, Fern-<br />
                        und Zwischenbereich°<sup>5</sup>
                    </Container>

                </div>

                <Container left={1450} top={420} width={593} as="p" className="text3 fadeIn">
                    Für ganztägigen<br />
                    <strong>Tragekomfort</strong>°<sup>18</sup>
                </Container>

                <div className="container-animate fadeIn animate2">

                    <Arrow left={1690} top={370} width={508} className="rotate90" arrowColor={'#3a8dde'} lineColor={'#ffffff'} />

                    <Container left={1450} top={667} width={593} as="p" className="text4">
                        <span className="count">{count2_1} %</span> erleben<br />
                        Tagesend-Komfort°<sup>5</sup>
                    </Container>

                </div>

                <div className="container-animate fadeIn animate3">

                    <Container left={590} top={1011} width={870} as="p" className="text4 text-center">
                        <strong>Eine leistungsstarke<br />
                            Kombination</strong>
                    </Container>

                    <MoreBtn
                        left={974} top={1142}
                        onClick={() => toggleOverlay('overlay1')}
                        active={state.overlay1 === 'overlay1'}
                    />

                    <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                        <Container left={100} top={298} width={1840} height={882} className="box1" />

                        <Container left={173} top={325} width={1000} as="p" className="box3">
                            Nachgewiesene Leistung <strong>zum Wettbewerb</strong>
                        </Container>

                        <Container left={569} top={397} width={822} as="p" className="box4 text-center">
                            <strong>ACUVUE® OASYS MAX 1-Day MULTIFOCAL</strong>
                        </Container>

                        <Container left={1392} top={397} width={473} as="p" className="box4 text-center">
                            <strong>DAILIES TOTAL1®</strong>
                        </Container>


                        <Container left={0} top={460} className="box2 box2_1">
                            <img src={img2_1} />
                            <Container left={245} top={0} width={324} height={156} className="box1">
                                <strong> Kontaktlinsen<br />
                                    Benetzungs-<br />Technologie</strong>
                            </Container>
                            <Container left={569} top={0} width={823} height={156} className="box2">
                                <p><strong>NEU:</strong> TearStable™ Technology – Optimiert PVP, ein tränenähnlicher<br />
                                    Benetzungswirkstoff in der gesamten Linse und an der Oberfläche,<br />
                                    um die Stabilität des Tränenfilms zu verlängern und eine<br />
                                    <strong>2x reduzierte Verdunstung zu gewährleisten</strong><sup>×6-8</sup></p>
                            </Container>

                            <Container left={1392} top={0} width={473} height={156} className="box3">
                                <p>Benetzungswirkstoff und<br />
                                    SmarTears – Bietet ein Feuchtig-<br />
                                    keitspolster und eine stabile<br />
                                    Lipidschicht<sup>@</sup></p>
                            </Container>

                        </Container>

                        <Container left={0} top={625} className="box2 box2_2 fadeIn animate">
                            <img src={img2_2} />
                            <Container left={245} top={0} width={324} height={156} className="box1">
                                <strong>HEV Filter</strong>
                            </Container>
                            <Container left={569} top={0} width={823} height={156} className="box2">
                                <p><strong>NEU:</strong> OptiBlue™ Light Filter –<br />
                                    <strong>Höchste Stufe des Blau-Violett-Filters bei 60%</strong><sup>**≠6</sup></p>
                            </Container>

                            <Container left={1392} top={0} width={473} height={156} className="box3">
                                <p>Nicht vorhanden<br />
                                    in Dailies Total1® oder<br />
                                    Dailies Total1® Multifocal<sup>****</sup></p>
                            </Container>

                        </Container>

                        <Container left={0} top={791} className="box2 box2_3 fadeIn animate2">
                            <img src={img2_3} />
                            <Container left={245} top={0} width={324} height={156} className="box1">
                                <strong>Optisches<br />
                                    Multifokal<br />
                                    Design</strong>
                                <span>Nur in Multifokal<br />
                                    Kontaktlinsen</span>
                            </Container>
                            <Container left={569} top={0} width={823} height={156} className="box2">
                                <p>Pupillenoptimiertes Design –<br />
                                    <strong>100% der Parameter</strong><br />
                                    sind nach Alter und Fehlsichtigkeit optimiert ′11</p>
                            </Container>

                            <Container left={1392} top={0} width={473} height={156} className="box3">
                                <p>Precision Profile Design -<br />
                                    3 Additionsstufen, unterschiedliche<br />
                                    Design für jede Stufe (unter<br />
                                    Berücksichtigung des Alters)<sup>§11</sup></p>
                            </Container>

                        </Container>

                        <Container left={0} top={958} className="box2 box2_4 fadeIn animate3">
                            <img src={img2_4} />
                            <Container left={245} top={0} width={324} height={156} className="box1">
                                <strong>UV Filter</strong>
                            </Container>
                            <Container left={569} top={0} width={823} height={156} className="box2">
                                <p><strong>Klasse 1 UV-Schutz</strong>:<sup>§8</sup><br />
                                    99.9% UV-A, 100% UV-B</p>
                            </Container>

                            <Container left={1392} top={0} width={473} height={156} className="box3">
                                <p>Kein UV-Schutz<br />
                                    in Dailies Total1® oder<br />
                                    Dailies Total1® Multifocal<sup>*****</sup></p>
                            </Container>

                        </Container>

                    </Overlay>

                </div>



            </Slide>
        </>

    );
};

export default Folder2Slide1;