export const customFoldersConstants = {
    SAVEFOLDER_REQUEST: 'CUSTOMFOLDER_SAVEFOLDER_REQUEST',
    SAVEFOLDER_SUCCESS: 'CUSTOMFOLDER_SAVEFOLDER_SUCCESS',
    SAVEFOLDER_FAILURE: 'CUSTOMFOLDER_SAVEFOLDER_FAILURE',

    ADDSLIDE_REQUEST: 'CUSTOMFOLDER_ADDSLIDE_REQUEST',
    ADDSLIDE_SUCCESS: 'CUSTOMFOLDER_ADDSLIDE_SUCCESS',
    ADDSLIDE_FAILURE: 'CUSTOMFOLDER_ADDSLIDE_FAILURE',

    GETFOLDERS_REQUEST: 'CUSTOMFOLDER_GETFOLDERS_REQUEST',
    GETFOLDERS_SUCCESS: 'CUSTOMFOLDER_GETFOLDERS_SUCCESS',
    GETFOLDERS_FAILURE: 'CUSTOMFOLDER_GETFOLDERS_FAILURE',

    SAVEFOLDERNAMES_REQUEST: 'CUSTOMFOLDER_SAVEFOLDERNAMES_REQUEST',
    SAVEFOLDERNAMES_SUCCESS: 'CUSTOMFOLDER_SAVEFOLDERNAMES_SUCCESS',
    SAVEFOLDERNAMES_FAILURE: 'CUSTOMFOLDER_SAVEFOLDERNAMES_FAILURE',

    GETFOLDERSNAMES_REQUEST: 'CUSTOMFOLDER_GETFOLDERSNAMES_REQUEST',
    GETFOLDERSNAMES_SUCCESS: 'CUSTOMFOLDER_GETFOLDERSNAMES_SUCCESS',
    GETFOLDERSNAMES_FAILURE: 'CUSTOMFOLDER_GETFOLDERSNAMES_FAILURE',
};
