import React from 'react';
import styled from 'styled-components'
import { Image, Container, Arrow } from 'components/parts';
import { ArrowContainer } from 'components/parts/Arrow'
import { useCounter } from 'components/hooks'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.png'

import img2_1 from './assets/img2_1.svg'
import img2_2 from './assets/img2_2.svg'
import img2_3 from './assets/img2_3.svg'
import img2_4 from './assets/img2_4.svg'


const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 47.5px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 9px;
        line-height: 12.5px;
    }

    .box1 {
        padding-left: 72px;
        padding-top: 4px;
        font-size: 22px;
        line-height: 22px;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 55px;
        }

    }

    .text2 {
        font-size: 21px;
        line-height: 25px;
    }

   
`

const Folder2Slide1 = ({ ...props }) => {

    return (
        <>
            <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Presbyope Augen <strong>brauchen mehr</strong> –<br /> 
Schenken Sie Ihnen <strong>MAX</strong></h1>
                <Image left={1009} top={856} width={931} height={367} src={img1} />
                <Container left={1530} top={1203} width={458} as="p" className="text1 text-center">
                    Nur zu Illustrationszwecken
                </Container>

                <Container left={68} top={381} width={858} className="box1 fadeIn">
                    <img src={img2_1} />
                    <p>Klare und stabile<br />  
Sehqualität<sup>±°5</sup></p>
                </Container>

                <Container left={744} top={381} width={600} className="box1 fadeIn animate2">
                    <img src={img2_2} />
                    <p>Tragekomfort über<br />  
den gesamten Tag<sup>°5</sup></p>
                </Container>

                <Container left={68} top={535} width={858} className="box1 fadeIn animate3">
                    <img src={img2_3} />
                    <p>UV-Schutz<sup>§8</sup><br /> 
100% UV-B, 99.9% UV-A</p>
                </Container>

                <Container left={744} top={535} width={600} className="box1 fadeIn animate4">
                    <img src={img2_4} />
                    <p>Einfache<br /> 
                    Handhabung<sup>°5</sup></p>
                </Container>

                <Container left={0} top={840} width={980} as="p" className="text2 text-center fadeIn animate5">
                <strong>Scharfe, klare Sicht <span className="color3">in jeder Entfernung</span><br /> 
und bei allen Lichtverhältnissen – plus<br /> 
hohen Tagesend-Komfort</strong><sup>±°5</sup><br /><br />

Die multifokale Kontaktlinse, auf die<br /> 
Menschen mit <strong>Presbyopie</strong> gewartet haben.
                </Container>


            </Slide>
        </>

    );
};

export default Folder2Slide1;