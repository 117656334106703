import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, CloseBtn, Image, MoreBtn, Overlay } from 'components/parts'

import img1 from '../slide3/assets/img1.jpg';
import img2 from '../slide3/assets/img2.jpg';
import img3 from '../slide3/assets/img3.jpg';
import img4 from '../slide3/assets/img4.jpg';

import img5 from './assets/img1.svg';

const Slide = styled.div`
    .box1,
    .box2 {
        p + p {
            margin-top: 11px;
        }
    }

    .box2 {
        padding-left: 22px;    
    }

    .step2,
    .step3,
    .step4 {
        display: none;
    }

    .step1 {
        ${({ step }) => step !== 1 && css`
            display: none;
        `}
    }

    .step2 {
        ${({ step }) => step === 2 && css`
            display: block;
        `}
    }

    .step3 {
        ${({ step }) => step === 3 && css`
            display: block;
        `}
    }

    .step4 {
        ${({ step }) => step === 4 && css`
            display: block;
        `}
    }

    .overlay1 {

        .img5 {
            position: absolute;
            left: 108.5px;
            top: 190px;
            width: 811px;
        }

        .box4 {
            font-weight: 600;
            color: #fff;
        }

        h2 {
            position: absolute;
            margin: 0;
            width: 850px;
            left: 86px;
            top: 80.5px;
            font-size: 30px;
            line-height: 37.5px;
            color: #003087;

            strong {
                font-weight: 600;
            }
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay } = useSlideState({ inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step} active={props.active} preview={props.preview}>
            <h1>Was lerne ich <strong>vom Internet?</strong></h1>

            <div className="step1">
                <Image left={145} top={398} width={326} height={326} src={img1} className="rounded border" />

                <Container left={95} top={751} width={426} className="text-center color1">
                    <p><strong>Bequemlichkeit</strong></p>
                </Container>

                <Container left={617} top={424} width={438} className="text box1 color1 fadeInFromLeft">
                    <p><strong>Erreichbarkeit 24/7</strong></p>
                    <p><strong>Nach Hause<br />
Lieferung / Abos</strong></p>
                    <p><strong>Zeitersparnis</strong></p>
                    <p><strong>Unterschiedliche
Zahlungsmethoden</strong></p>
                </Container>

                <Container left={1184} top={292} width={669} height={658} className="bg1 text box2 flex-all-center color1 fadeInFromLeft animate2">
                    <p>Wie kann der Fachhandel<br />
                    dies umsetzen?</p>
                    <p><strong>Home Delivery System</strong></p>
                    <p><strong>Abo-System</strong></p>
                    <p><strong>Erreichbarkeit über<br />
                    WhatsApp und andere<br />
soziale Medien</strong></p>
                    <p><strong>Moderne Zahlungs-<br />
systeme vorhalten</strong></p>
                    <p><strong>Service aktiv anbieten</strong></p>
                </Container>

                <Arrow left={562} top={443} width={303} className="fadeInFromLeft" />

                <Arrow left={1180} top={368} width={432} className="fadeInFromLeft animate2" />
            </div>

            <div className="step2">
                <Image left={192} top={433} width={262} height={262} src={img2} className="rounded border" />

                <Container left={75} top={721} width={495} className="text-center color1">
                    <p><strong>Produktverfügbarkeit<br />&amp; Auswahl</strong></p>
                </Container>

                <Container left={617} top={454} width={438} className="text box1 color1 fadeInFromLeft fadeInFromLeft">
                    <p><strong>Menge &amp;<br />
Schnelligkeit der<br />
Lieferung</strong></p>
                    <p><strong>Neue, andere<br />
Produkte testen</strong></p>
                </Container>
                <Container left={1184} top={249} width={669} height={632} className="bg1 text box2 flex-all-center color1 fadeInFromLeft animate2">
                    <p>Wie kann der Fachhandel<br />
                    dies umsetzen?</p>
                    <p><strong>Relevante Produkte<br />
auf Lager</strong></p>
                    <p><strong>Eigene Online<br />
Bestellplattform</strong></p>
                    <p><strong>Bestehende Träger über<br />
                    neue Produkte informieren<br />
                    und testen lassen</strong></p>
                    <p><strong>Kompetenz vor Ort betonen</strong></p>
                </Container>

                <Arrow left={562} top={420} width={303} className="fadeInFromLeft" />

                <Arrow left={1180} top={334} width={462} className="fadeInFromLeft animate2" />

            </div>

            <div className="step3">
                <Image left={210} top={544} width={226} height={226} src={img3} className="rounded border" />

                <Container left={210} top={796} width={226} className="text-center color1">
                    <p><strong>Preis</strong></p>
                </Container>

                <Container left={617} top={579} width={438} className="text box1 color1 fadeInFromLeft">
                    <p><strong>Niedriger Preis<br />
und zusätzliche<br />
Angebote</strong></p>
                    <p><strong>Vermeintliche<br />
Preis-Transparenz</strong></p>
                </Container>

                <Container left={1184} top={292} width={702} height={807} className="bg1 text box2 flex-all-center color1 fadeInFromLeft animate2">
                    <p>Wie kann der Fachhandel dies<br />umsetzen?</p>
                    <p><strong>Trennung von Produkt<br />
und Service</strong></p>
                    <p><strong>Service klar definieren und<br />
„Verkaufen“</strong></p>
                    <p><strong>Nutzung von Bonussystemen,<br />
                    Treuekarten oder Voucher-<br />
Systemen</strong></p>
                    <p><strong>ACUVUE® Aktionen und<br />
Kampagnen einsetzen</strong></p>
                    <p><strong>Verkauf von<br />
Großpackungen (90er)</strong></p>
                </Container>

                <Arrow left={562} top={545} width={303} className="fadeInFromLeft" />

                <Arrow left={1180} top={334} width={723} className="fadeInFromLeft animate2" />

                <MoreBtn
                    left={1718} top={450}
                    onClick={() => toggleOverlay('overlay1')}
                    className="fadeInFromLeft animate2"
                />

                <Overlay active={state.overlay1 === 'overlay1'} className="overlay1">
                    <Container left={100} top={100} width={1848} height={1120} className="bg1 box3">
                    </Container>

                    <img src={img5} className="img5" />

                    <h2>Exkurs: <strong>Überbrückung der Diskrepanz von<br /> 
Online und Offline Preis</strong>
                    </h2>

            

                    <Container left={217} top={804} width={306} className="text-center box4">
                        Produkt
                    </Container>
                    <Container left={870} top={804} width={306} className="text-center box4">
                        Produkt
                    </Container>
                    <Container left={1533} top={804} width={306} className="text-center box4">
                        Produkt
                    </Container>
                    <Container left={1533} top={635} width={306} className="text-center box4">
                        Handling
                    </Container>
                    <Container left={1533} top={461} width={306} className="text-center box4">
                    Dienst-<br />
leistung
                    </Container>

                    <Container left={117} top={998} width={506} className="text-center color1">
                        <p><strong>Internet-Preise</strong></p>
                    </Container>
                    <Container left={770} top={998} width={506} className="text-center color1">
                        <p><strong>Fachhandels-Preis</strong></p>
                    </Container>
                    <Container left={1433} top={998} width={506} className="text-center color1">
                        <p><strong>Zukunft?</strong></p>
                    </Container>

                    <Container left={542} top={728} width={350}>
                        <p className="small">In den Augen des<br />
                        preissensiblen<br />
                        Konsumenten ist<br />
                        dies der Maßstab</p>
                    </Container>

                    <CloseBtn
                        left={1855} top={127}

                        onClick={() => toggleOverlay('overlay1')}
                    />
                </Overlay>

            </div>

            <div className="step4">
                <Image left={256} top={520} width={132} height={132} src={img4} className="rounded border" />

                <Container left={158} top={678} width={328} className="text-center color1">
                    <p><strong>Produkt-<br />
information</strong></p>
                </Container>

                <Container left={617} top={486} width={438} className="text box1 color1 fadeInFromLeft">
                    <p><strong>Breite<br />
Information</strong></p>
                    <p><strong>Unverbindliches<br />
Informieren ohne<br />
Kaufdruck</strong></p>
                    <p><strong>Rating &amp; Reviews</strong></p>
                </Container>

                <Container left={1184} top={227} width={702} height={824} className="bg1 text box2 flex-all-center color1 fadeInFromLeft animate2">
                    <p>Wie kann der Fachhandel dies<br />umsetzen?</p>
                    <p><strong>Konkrete Vorabinformationen<br />
                    zur Verfügung stellen<br />
über Webseiten und Social Media Kanäle</strong></p>
                    <p><strong>E-Broschüren mit Termin-<br />
findungstool verbinden</strong></p>
                    <p><strong>Kunden verbreiten „Ratings“  z.B. Freunde werben Freunde über Social Media
</strong></p>
                    <p><strong>Proaktiv Internet-Such-<br />
                    maschine für Bewertungen<br />
                    von Ihren Kunden nutzen</strong></p>
                </Container>

                <Arrow left={562} top={493} width={303} className="fadeInFromLeft" />

                <Arrow left={1180} top={316} width={593} className="fadeInFromLeft animate2" />

            </div>

        </Slide>
    );
};
