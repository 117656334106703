import React from 'react';
import styled from 'styled-components'

import bg from './assets/bg.jpg'

const Slide = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: 148px;
        font-size: 56px;
        line-height: 57px;
        color: #fff;
        color: #fff;
    }

    h2 {
        border-top: 1px solid #fff;
        color: #fff;
        font-size: 27px;
        line-height: 30px;
        padding-top: 18px;
    }
`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <div>
                    <h1 style={{ color: '#fff' }}><strong>MAX</strong> Tragekomfort<br />
<strong>MAX</strong> Sehqualität </h1>
                    <h2>Bieten Sie all Ihren neuen Kontaktlinsen-<br />
Trägern <strong>ACUVUE® OASYS MAX 1-Day</strong> an</h2>
                </div>
            </Slide>
        </>

    );
};

export default Folder2Slide1;