import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState, useCounter } from 'components/hooks'

import { Arrow, Container, CircleCount, Image, MoreBtn, Overlay, CloseBtn } from 'components/parts'

import img1 from './assets/img1.svg';


const Slide = styled.div`
    
    .step2 {
        display: none;
    }

    .step1 {
        ${({ step }) => step !== 1 && css`
            display: none;
        `}
    }

    .step2 {
        ${({ step }) => step === 2 && css`
            display: block;
        `}
    }


    .box1 {
        font-size: 47.5px;
        line-height: 60px;
        font-weight: 600;

        span {
                position: absolute;
                left: 0;
                top: 94px;
                width: 100%;
                text-align: center;
                font-size: 16px;
                line-height: 18px;
                font-weight: 400;
            }
    }

    .box2 {
        .count {
            font-size: 47.5px;
            line-height: 60px;
            padding-top: 10px;

            .percent {
                font-size: 26px;
            }
        }
    }

    .box3 {
        padding-top: 24px;
    }

  
`
const inititialState = {
    overlay1: null,
    video: null
}

export default ({ ...props }) => {
    const { state, toggleOverlay, updateState } = useSlideState({ inititialState, active: props.active })

    let count1 = useCounter(0, 63, props.active, props.preview)
    count1 = count1 / 10
    count1 = count1.toFixed(1).toLocaleString('de-DE').toString().replace(/\./g, ',')

    const count2 = useCounter(0, 35, props.active, props.preview, 1)
    const count3 = useCounter(0, 75, props.active, props.preview, 1)

    return (
        <Slide className="centered" step={props.step}>
            <h1>Warum die Kampagne für <strong>ACUVUE® OASYS MAX 1-Day<br />
                ein Erfolg</strong> wird</h1>
            <div className="step1">
                <Image left={288} top={296} width={276} height={276} src={img1} />
                <Container left={288} top={296} width={276} height={266} className="flex-all-center text-center box1 color1">
                    {count1}<br />
                    <span>Mio</span>
                </Container>

                <Container left={629} top={296} width={682} height={276} className="color1 flex-all-center" as="p">
                    <strong>… sind aktiv an Linsen<br />
                        interessiert<sup>1</sup></strong>
                </Container>

                <div className="container-animate fadeIn">

                    <CircleCount left={288} top={611} width={276} percent={count2} color={'#003087'} />
                    <Container left={288} top={611} width={276} height={276} className="flex-all-center text-center box2">
                        <span className="count">{count2}<span className="percent">%</span></span>
                    </Container>

                    <Container left={629} top={660} width={640} className="color1" as="p">
                        <strong>… mehr Zeit</strong> im Vergleich zu<br />
                        2019 verbringen Erwachsene<br />
                        täglich <strong>an digitalen Geräten</strong><br />
                        (mehr als 13 Std.)<sup>2</sup>
                    </Container>

                </div>

                <div className="container-animate fadeIn animate2">

                <CircleCount left={288} top={921} width={276} percent={count3} color={'#003087'} />
                <Container left={288} top={921} width={276} height={276} className="flex-all-center text-center box2">
                    <span className="count">{count3}<span className="percent">%</span></span>
                </Container>

                <Container left={629} top={921} width={640} height={276} className="color1 flex-all-center" as="p">
                    <strong>… erwarten mehr von<br /> 
ihren Kontaktlinsen<sup>3</sup></strong>
                </Container>

                </div>


                <Container left={1334} top={308} width={392} height={860} className="bg1 color1 text text-center flex-all-center fadeInFromLeft animate3">
                    <Arrow width={732} arrowColor={'#3a8dde'} />
                    <p><strong>Nutzen Sie<br />
                        das riesige<br />
                        Potential</strong></p>
                </Container>

            </div>

        </Slide>
    );
};
