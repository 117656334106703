import React from 'react';
import styled from 'styled-components'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import bg from './assets/bg.jpg'
import img1 from './assets/img1.png'

const Slide = styled.div`
    background: url(${bg});
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    h1 {
        margin-top: 10px;
        position: relative;
        z-index: 10;
        font-size: 47px;
        line-height: 57px;
        color: #fff;
        
        color: #fff;

        strong {
            font-weight: 600;
        }
    }
`

const Folder2Slide1 = () => {
    return (
        <Slide className="bg full">
            <span className="color-overlay" />
            <h1 style={{ color: '#fff' }}><strong>ACUVUE® OASYS 1-DAY</strong><br />
for ASTIGMATISM</h1>
            <div className="centered">
                <Image left={706} top={916} width={644} height={211} src={img1} />
            </div>
        </Slide>
    );
};

export default Folder2Slide1;