import React from 'react';
import styled from 'styled-components'
import { Container } from 'components/parts';

import bg from './assets/bg.jpg'


const Slide = styled.div`
    font-family: "AvenirNext", sans-serif;
    color: #fff;

    h1 {
        width: 100%;
        margin-left: 0;
        margin-top: 40px;
        font-size: 42px;
        line-height: 71px;
        text-align: center;
        color: #fff;
    }

    .text1 {
        font-size: 50px;
        line-height: 55px;
    }

`

const Folder2Slide1 = ({ ...props }) => {
    return (
        <>
            <div className="bg full" style={{ backgroundImage: `url(${bg})` }}></div>
            <Slide className="centered">
                <h1>Das Leben verlangt <strong>mehr von unseren Augen</strong></h1>
                <Container left={1197} top={607} width={720} as="p" className="text1">
                ... umso mehr von <strong>alternden Augen</strong>
                </Container>
            </Slide>
        </>

    );
};

export default Folder2Slide1;