import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useSlideState } from 'components/hooks'

import { Arrow, Container, Image, MoreBtn, Overlay } from 'components/parts'

import icon1 from './assets/icon1.svg';

const Slide = styled.div`
    .box1 {
        align-items: center;
        justify-content: flex-start;

        img {
            margin-left: 28px;
            width: 86.25px;
        }

        p {
            margin-left: 17px;
        }
    }
`
const inititialState = {
    overlay1: null
}

export default ({...props}) => {
    const { state, toggleOverlay } = useSlideState({inititialState, active: props.active })

    return (
        <Slide className="centered" step={props.step}>
            <h1><strong>Kontaktlinsen beeinflussen<br />
den Tränenfilm</strong></h1>
            <Container left={433} top={383} width={1184} height={246} className="rounded rounded-full flex box1 color1">
                <img src={icon1} />
                <p>Die größte Änderung des Brechungsindex<br /> 
im optischen System erfolgt an der<br /> 
Grenzfläche Luft-Tränenfilm.<sup>16</sup></p>
            </Container>
            <Container left={433} top={681} width={1184} height={344} className="bg1 flex-all-center text-center color1 fadeInFromTop">
                <Arrow width={1065} className="container-top" />
                <p><strong>Jede Unregelmäßigkeit oder<br />
Beeinträchtigung des Tränenfilms kann<br />
zu einer erheblichen Verschlechterung<br />
der Sehqualität führen.</strong></p>
            </Container>
        </Slide>
    );
};
